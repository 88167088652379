import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_ALL_USER_ACTIVITIES_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import Activity from "./Activity";
import ActivitiesFilter from "./Filter/ActivitiesFilter";
import narfo from "./NImages/nfLogo.jpg";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  }
};
const UserStatus = Object.freeze({ 1: "Approved", 2: "Pending", 3: "Rejected", 4: "Renewing" })
class MainActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activities: [],
      showFilter: false,
      filterActive: false,
      narfoLogo: "",
      _dss: "",
      _dhs: ""
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.toPDF = React.createRef();
  }
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    let memStatus = [];
    axios
      .get(BASE + GET_ALL_USER_ACTIVITIES_URL + this.props.memNumber, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(res => {
        const activities = res.data.filter(element =>
          element.status !== "Rejected"
        );
        this.setState({
          activities: activities ? activities : []
        });
        this.props.onUserActivitiesChange(activities);
      });
    axios.get(BASE + "api/Activities/memNo/" + this.props.memNumber, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(res =>
      res.data.forEach(e => {
        if (e.status === "Approved") {
          memStatus.push(e.activityType);
        }
      })
    );
    axios({
      url: `${BASE}api/DedicatedStatus/approvedDS/${this.props.memNumber}`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      res.data.forEach(element =>{
        if(element.dstype === "Dedicated Sports Person"){
          this.setState({
            _dss: element.dsno
          })
        }
        if(element.dstype === "Dedicated Hunter"){
          this.setState({
            _dhs: element.dsno
          })
        }
      })
    })
    .catch(function (error) { });
    this.props.approvedMemberStatus(memStatus);
  }
  toggleFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }
  applyFilter() {
    let filteredActivities = this.props.userActivities;
    if (this.props.activityFilterState.filterCategory === "Hunting") {
      filteredActivities = filteredActivities.filter(
        activity =>
          activity.activityType === "Dedicated Hunter" ||
          activity.activityType === "Occasional Hunter" ||
          activity.activityType === "Dedicated Hunter and Sport Shooter"
      );
    }
    if (this.props.activityFilterState.filterCategory === "Sport Shooting") {
      filteredActivities = filteredActivities.filter(
        activity =>
          activity.activityType === "Dedicated Sports Shooter" ||
          activity.activityType === "Occasional Sport Shooter" ||
          activity.activityType === "Dedicated Hunter and Sport Shooter"
      );
    }
    if (
      this.props.activityFilterState.startDate &&
      this.props.activityFilterState.endDate
    ) {
      let startDate = new Date(this.props.activityFilterState.startDate);
      let endDate = new Date(this.props.activityFilterState.endDate);
      filteredActivities = filteredActivities.filter(
        activity =>
          new Date(activity.activityDate.substring(0, 10)) >= startDate &&
          new Date(activity.activityDate.substring(0, 10)) <= endDate
      );
    } else {
      if (this.props.activityFilterState.startDate) {
        let startDate = new Date(this.props.activityFilterState.startDate);
        filteredActivities = filteredActivities.filter(
          activity =>
            new Date(activity.activityDate.substring(0, 10)) >= startDate
        );
      }
      if (this.props.activityFilterState.endDate) {
        const endDate = new Date(this.props.activityFilterState.endDate);
        filteredActivities = filteredActivities.filter(
          activity =>
            new Date(activity.activityDate.substring(0, 10)) <= endDate
        );
      }
    }
    this.setState({ activities: filteredActivities });
  }
  resetFilter() {
    this.setState({ activities: this.props.userActivities });
  }
  pdfDownloader() {
    this.toPDF.current.click();
  }
  render() {
    const activities = this.state.activities;
    const tableArray = numIterator => [
      !activities[numIterator].activityType
        ? "null"
        : activities[numIterator].activityType.toString(),
      !activities[numIterator].activityDescription
        ? "null"
        : activities[numIterator].activityDescription.toString(),
      !activities[numIterator].firearmType
        ? "null"
        : activities[numIterator].firearmType.toString(),
      !activities[numIterator].activityDate
        ? "null"
        : activities[numIterator].activityDate.toString().substring(0, 10),
      !activities[numIterator].score || activities[numIterator].score.toString() === "0"
        ? "N/A"
        : activities[numIterator].score.toString(),
      !activities[numIterator].status
        ? "null"
        : activities[numIterator].status.toString()
    ];
    const print = () => {
      const pdf = new jsPDF("l", "mm", [310, 210]);
      pdf.autoTable({ html: ".table" });
      let finalY = pdf.previousAutoTable.finalY;
      var arr = [];
      let count = 0;
      let sum = activities.length;
      while (sum !== 0) {
        arr.push(tableArray(count));
        count++;
        sum--;
      }
      pdf.addImage(this.props.base64Images.nnfo, "JPEG", 13, 15);
      pdf.setTextColor(126, 126, 126);
      pdf.setFont("times");
      pdf.setFontSize(10);
      pdf.text(15, 60, "NARFO Pty (Ltd) 2012/086864/07");
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("times");
      pdf.setFontSize(14);
      pdf.setFontStyle("bold");
      pdf.text(100, 18, "National Association of Responsible Firearm Owners");
      pdf.text(140, 25, "of South Africa");
      pdf.setFontType("normal");
      pdf.line(70, 30, 295, 30);
      pdf.setTextColor(126, 126, 126);
      pdf.setFontSize(10);
      pdf.setFontType("italic");
      pdf.text(90, 37, "SAPS Accredited Association");
      pdf.setFontType("normal");
      pdf.text(75, 45, "Hunting Association");
      pdf.setTextColor(0, 0, 0);
      pdf.text(115, 45, "FAR-1300135");
      pdf.setTextColor(126, 126, 126);
      pdf.text(75, 51, "Sport Shooting");
      pdf.setTextColor(0, 0, 0);
      pdf.text(115, 51, "FAR-1300134");
      pdf.text(75, 57, "http://www.narfo.co.za");
      pdf.setTextColor(126, 126, 126);
      pdf.text(115, 57, "Email:");
      pdf.setTextColor(0, 0, 0);
      pdf.text(125, 57, "info@narfo.co.za");
      pdf.line(70, 62, 295, 62);
      pdf.setFontSize(8);
      pdf.setTextColor(126, 126, 126);
      pdf.text(164, 37, "14 Phesante Close, Graanendal");
      pdf.text(164, 41, "Durbanville");
      pdf.text(164, 45, "7551");
      pdf.setTextColor(0, 0, 0);
      pdf.text(220, 37, `${this.props.memberDetails.firstname.toUpperCase()} ${this.props.memberDetails.surname.toUpperCase()}`);
      pdf.text(220, 41, `IDENTITY NUMBER: ${this.props.memberDetails.idno.toUpperCase()}`);
      pdf.text(220, 45, `MEMBER NUMBER: ${this.props.memberDetails.memberNumber.toUpperCase()}`);
      pdf.text(220, 49, `MEMBER STATUS: ${UserStatus[this.props.memberDetails.userStatus].toUpperCase()}`);
      if(this.state._dhs !== "" && this.state._dss !== "") {
        pdf.text(220, 53, `DEDICATED HUNTER NUMBER: ${this.state._dhs}`);
        pdf.text(220, 57, `DEDICATED SPORTS PERSON NUMBER: ${this.state._dss}`);
      }
      if(this.state._dhs !== "" && this.state._dss === "") {
        pdf.text(220, 53, `DEDICATED HUNTER NUMBER: ${this.state._dhs}`);
      }
      if(this.state._dhs === "" && this.state._dss !== "") {
        pdf.text(220, 53, `DEDICATED SPORTS PERSON NUMBER: ${this.state._dss}`);
      }
      pdf.setFontStyle("bold");
      pdf.setFontSize(20);
      pdf.text(130, 70, "Member Activities");
      pdf.setFontStyle("normal");
      pdf.autoTable({
        startY: finalY + 60,
        head: [
          [
            "Activity Type",
            "Description",
            "Firearm Type",
            "Activity Date",
            "Score",
            "Status"
          ]
        ],
        body: arr.filter(a => {
          return a;
        })
      });
      pdf.save("Activities.pdf");
    };
    return (
      <div className="container">
        <div className="activity-header-container">
          <GenericHeader
            title="Activity"
            showDownloadButton
            downloadFunction={() => this.pdfDownloader()}
            showFilledAddButton
            text="Activities PDF"
            addFunction={() => {
              this.props.onMembersAreaChange("1", "currentActivityTab");
            }}
            showFilterButton
            onFilterClick={this.toggleFilter}
          />
          <button onClick={print} ref={this.toPDF} hidden="hidden">
            activityPDF
          </button>
          {this.state.showFilter ? (
            <ActivitiesFilter
              resetFilter={this.resetFilter}
              applyFilter={this.applyFilter}
            />
          ) : null}
        </div>
        <div className="container">
          <div className="row pl-3 pr-3">
            Use the activity section to records and keep track of your activities. Click the plus button to add a new activity. 
            You can add NARFO activities or you can add activities from other associations. 
            You can also print an activity report to supplement your firearm license application as proof of being an active shooter. 
            You can also use the filter button to filter only specific activities.
          </div>
          <div className="row pl-3 pr-3 text-danger font-weight-bold">
            No activity will be approved if the uploaded document does not have a date on it. Please add a date on every document before uploading.
          </div>
          <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
            <h6>Activity List</h6>
          </div>
        </div>
        <div className = "container">
          {activities.length > 0 ? (
            activities.map((activity, index) => (
              <Activity key={index} activity={activity} />
            ))
          ) : (
              <p className="pl-1 pr-1 text-warning font-weight-bold">Activities not yet taken...</p>
            )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    memNumber: state.loginReducer.userProfileDetails.memberNumber,
    userActivities: state.activitiesReducer.userActivities,
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    activityFilterState: state.activityFilterState,
    memberDetails: state.loginReducer.memberObject
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({
        type: actionTypes.UPDATE_LOYALTY_DAYS_TAB,
        currentLoyaltyDaysTab: pageNum
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onUserActivitiesChange: newUserActivitiesArray =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_USER_ACTIVITIES,
        payload: newUserActivitiesArray
      }),
    onMembersAreaChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    approvedMemberStatus: newApprovedMemberStatus =>
      dispatch({
        type: actionTypes.APPROVED_MEMBER_ACTIVITY,
        approvedMemActivities: newApprovedMemberStatus
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MainActivities));
