import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import * as actionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  BASE,
  GET_NATIONALRANKING_URL,
  FILTER_NATIONAL_RANKING_URL
} from "../../API/APIendpoint";
import axios from "axios";
import GeneriHeader from "../../utils/GenericHeader";
import Ranking from "./Ranking";
import { Form } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { styles } from "./styles/styles";
import "./styles.css";
import { IconButton } from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";

class DedicatesHunter extends Component {
  state = {
    show: true
  }
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      filteredPosts: [],
      ID: 2,
      clicked: false,
      resetClicked: false,
      applyClicked: false,
      filter: {
        firearmType: null,
        ActivityType: null,
        Association: null
      },
      filterOpen: false
    };
  }

  showFilterButton = show => {
    return show ? (
      <IconButton onClick={() => this.filterFunction()}>
        <FilterListIcon />
      </IconButton>
    ) : null;
  };

  componentDidMount() {
    axios.get(BASE + GET_NATIONALRANKING_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.setState({
        Posts: res.data
      });
    });
  }

  handleAssociationChange = event => {
    var filter = this.state.filter
    filter.Association = event.target.value
    this.setState({ filter: filter });
  };

  handleFirearmChange = event => {
    var filter = this.state.filter
    filter.firearmType = event.target.value
    this.setState({ filter: filter });
  };

  handlerRanking = () => {
    const data = this.state.filter
    fetch(BASE + FILTER_NATIONAL_RANKING_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          filteredPosts: res
        })
      })
      .catch()
  }

  toggleFilter = () => {
    this.setState({
      filterOpen: !this.state.filterOpen
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div ClassName="container" style={{marginTop: "60px"}}>
        <div className={classes.Static}>
          
          <GeneriHeader title="NARFO Ranking" showFilterButton onFilterClick={this.toggleFilter} />
          {this.state.filterOpen ? (
            <Fragment>

              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}>
                <Container className={classes.container} >
                  <div className={classes.filterBy}>
                    Filter By:
                </div>
                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group controlId="exampleForm.ControlSelect1" >
                        <Form.Label>Firearm</Form.Label>
                        <Form.Control
                          as="select"
                          placeholder="Select FirearmType"
                          value={this.state.filter.firearmType}
                          onChange={(e) => {
                            const _filter = this.state.filter;
                            _filter.firearmType = e.target.value;
                            this.setState({
                              filter: _filter
                            })
                          }}>
                          <option></option>
                          <option>Center Fire Bolt Action - CFBA </option>
                          <option>Rim Fire Bolt Action - RFBA</option>
                          <option>Center Fire Rifle Semi Auto - CFRSA</option>
                          <option>Rim Fire Rifle Semi Auto - RFRSA</option>
                          <option>Center Fire Handgun - CFHG</option>
                          <option>Rim Fire Hand Gun - RFHG</option>
                          <option>Big Bore Rifle Bolt Action - BBRBA</option>
                          <option>Big Bore Rifle Break Action - BBRKA</option>
                          <option>Shot Gun 12 Guage - SG12</option>
                          <option>Shot Gun 16 Gauge - SG16</option>
                          <option>Muzzle Loader Rifle - MLRBP</option>
                          <option>Muzzle Loader Hand Gun - MLHBP</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Form.Row>

                  <Form.Row>
                    <Col xs={6}>
                      <Form.Group controlId="exampleForm.ControlSelect1" >
                        <Form.Label>Activity</Form.Label>
                        <Form.Control
                          as="select"
                          placeholder="Select ActivityType"
                          value={this.state.filter.ActivityType}
                          onChange={(e) => {
                            const _filter = this.state.filter;
                            _filter.ActivityType = e.target.value;
                            this.setState({
                              filter: _filter
                            })
                          }}>
                          <option></option>
                          <option>NARFO</option>
                          <option>Benchrest</option>
                          <option>Bisley</option>
                          <option>Bianchi</option>
                          <option>CHASA Range Shoot</option>
                          <option>Cowboy Action</option>
                          <option>Double Trap</option>
                          <option>F-Class</option>
                          <option>Ghong Challenge</option>
                          <option>Grouping</option>
                          <option>IDPA</option>
                          <option>IPSC</option>
                          <option>Mastershot</option>
                          <option>Pin Shooting</option>
                          <option>PRS</option>
                          <option>Skeet</option>
                          <option>Sport Shotgun</option>
                          <option>Silhouette</option>
                          <option>Steel Challenge</option>
                          <option>SADPA</option>
                          <option>SACRA</option>
                          <option>Trap</option>
                          <option>Veldskiet</option>
                          <option>XSSSA</option>
                          <option>3-GUN</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Form.Row>
                  <Row>
                    <Col><button
                      onClick={() => {
                        const _filter = {
                          firearmType: null,
                          ActivityType: null,
                          Association: null
                        }
                        this.setState({
                          filter: _filter,
                          applyClicked: false
                        })
                        this.toggleFilter();
                      }}
                      className={`${this.state.resetClicked ? "button-active" : ""}`}
                    >Reset All</button></Col>

                    <Col xs={7}>
                      <button
                        onClick={() => {
                          this.setState({ applyClicked: true });
                          this.handlerRanking();
                          this.toggleFilter();
                        }}
                        className={`${this.state.applyClicked ? "button-active" : ""}`}
                      >
                        Apply Filter
                      </button>
                    </Col>
                  </Row>
                </Container>
              </Form>
              <br></br>
              <hr className={classes.hr}></hr>
            </Fragment>
          ) : null}
          <br></br>

          <div className={classes.root}>
            {this.state.applyClicked ?
              this.state.filteredPosts.map((ranking, index) => (
                <Ranking key={index} RankingArray={ranking} />
              )) :
              this.state.Posts.map((ranking, index) => (
                <Ranking key={index} RankingArray={ranking} />
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}
DedicatesHunter.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    newActDetails: state.nationalRankingReducer.nationalRanking,
    nationalRankingList: state.nationalRankingReducer.nationalRankingList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddNationalRankingDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_NATIONAL_RANK_LIST,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DedicatesHunter));
