import React, { Component } from 'react';
import {
  EditableGridLabel,
  IconLabelButton,
  Loader,
  MappedInput,
  SubtitleBar,
} from '../index';
import {
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  checkValidity,
  updateObject, 
} from '../../shared/utility';

import {
  editableTextInputConfig
} from '../../shared/constants';

const REP_DETAILS = 'repDetails';
const PARTNER_DETAILS = 'partnerDetails';

class BankDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRadio: REP_DETAILS,
      selectedAccountDetails: props.bankAccountDetails[REP_DETAILS],
      editDetails: false,
      controls: this.initControls(),
      radioGroupConfig : {
        elementType: 'radioGroup',
        elementConfig: {
          direction: 'row',
          radios: [
            { value: REP_DETAILS, label: 'Rep Banking Details'},
            { value: PARTNER_DETAILS, label: 'Partner Banking Details'},
          ],
        },
      },
      loading: false,
      formHasErrors: true,
    };
  }

  initControls = () => ({
    bankName: {...editableTextInputConfig},
    accountType: {...editableTextInputConfig},
    accountNo: {...editableTextInputConfig},
    branchCode: {...editableTextInputConfig},
  });

  handleRadioChange = (event) => {
    this.setState({
      selectedRadio: event.target.value,
      selectedAccountDetails: this.props.bankAccountDetails[event.target.value],
      controls: this.initControls(),
    });
  };

  handleLabelChange = controlName => event => {
    const validity = checkValidity (event.target.value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });
    this.setState({
      controls,
      selectedAccountDetails: {
        ...this.state.selectedAccountDetails,
        [controlName]: event.target.value,
      },
      formHasErrors,
    });
  };

  label = (title, name, isMobile) => (
    <EditableGridLabel title={title}
      value={this.state.selectedAccountDetails[name]}
      options={this.state.controls[name]}
      edit={this.state.editDetails}
      onChange={this.handleLabelChange(name)}
      isMobile={isMobile}
    />
  );

  cancelForm = () => {
    this.setState({
      editDetails: false,
      formHasErrors: true,
      controls: this.initControls(),
      selectedAccountDetails: this.props.bankAccountDetails[this.state.selectedRadio],
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true, editDetails: false, });
    this.props.updateAccountDetails(this.state.selectedAccountDetails)
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        <SubtitleBar title="Bank Details" edit={!this.state.editDetails} callBack={() => this.setState({editDetails: true})}/>
        <div className="inner-shadow border-radius-2 py-5 px-10 bg-white-2">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              {this.state.editDetails ?
                <Typography variant="h4">
                  {`Edit ${this.state.radioGroupConfig.elementConfig.radios.
                    find(rad => rad.value === this.state.selectedRadio).label
                  }`}
                </Typography>:
                <div className="pl-3">
                  <MappedInput
                    {...this.state.radioGroupConfig} 
                    value={this.state.selectedRadio}
                    changed={this.handleRadioChange}
                  />
                </div>
              }
            </Grid>
            <Grid item xs={12} container spacing={2}>
              {this.label('Bank name', 'bankName', isMobile)}
              {this.label('Account Type', 'accountType', isMobile)}
              {this.label('Account Number', 'accountNo', isMobile)}
              {this.label('Branch Code', 'branchCode', isMobile)}
            </Grid>
          </Grid>
        </div>
        {this.state.editDetails && !this.state.loading && <div className="d-flex py-2">
          <IconLabelButton full disabled={this.state.formHasErrors} actionText="Save Changes" color="primary" callBack={this.handleSubmit}/>
          <IconLabelButton full actionText="cancel" callBack={this.cancelForm}/>
        </div>}
        { this.state.loading && <Loader />}
      </React.Fragment>
    );
  }
}

BankDetails.defaultProps = {
  bankAccountDetails: {
    repDetails: {},
    partnerDetails: {},
  },
  updateAccountDetails: (data) => Promise.resolve(data),
};

BankDetails.propTypes = {
  bankAccountDetails: PropTypes.shape({
    repDetails: PropTypes.object,
    partnerDetails: PropTypes.object,
  }).isRequired,
  updateAccountDetails: PropTypes.func.isRequired,
};

export default BankDetails;
