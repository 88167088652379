import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { formatDate, status } from "../../loggeddInUser/activitiesPage/ActivityDetails";
function MainAcivityDetailsApproval(props) {
  return (
    <div
      className="p-2 border-bottom font-weight-bold"
      onClick={() => {
        props.onActivityParametersChange(
          props.activity.memNo,
          props.activity.activityId,
          true
        );
      }}
      style={{ cursor: "pointer" }}
    >
      <span>{props.activity.activityDescription}</span> <br />
      <span>{formatDate(props.activity.activityDate)}</span> <br />
      <span>{status(props.activity.status)}</span>
    </div>
  );
}
const mapDispatchToProps = dispatch => {
  return {
    onActivityParametersChange: (memberNumber, activityId, renderPracticalActivityDetails) => {
      return dispatch({
        type: actionTypes.UPDATE_ACTIVITY_PARAMETERS,
        activityMemberNumber: memberNumber,
        activityID: activityId,
        renderPracticalActivityDetails: renderPracticalActivityDetails
      });
    }
  };
};
export default connect(
    null,
    mapDispatchToProps
)(MainAcivityDetailsApproval);
