import * as Types from "./ApproveNewMemberActionTypes";

const approveNewMemberState = {
  approvalStatus: {
    loading: null,
    response: null,
    error: null
  },
  accountUpdateStatus: {
    loading: null,
    response: null,
    error: null
  },
  receiptNo: null,
  invoiceNo: null,
  paymentDate: null,
  year: null,
  paymentMethod: null,
  amount: null,
  costCode: null,
  paid: null
};

const reducer = (state = approveNewMemberState, action) => {
  switch (action.type) {
    case Types.APPROVE_MEMBER_BEGIN:
    case Types.APPROVE_MEMBER_ERROR:
    case Types.APPROVE_MEMBER_SUCCESS:
    case Types.UPDATE_ACCOUNT_BEGIN:
    case Types.UPDATE_ACCOUNT_ERROR:
    case Types.UPDATE_ACCOUNT_SUCCESS:
    case Types.SET_RECEIPT_NO:
    case Types.SET_INVOICE_NO:
    case Types.SET_PAYMENT_DATE:
    case Types.SET_YEAR:
    case Types.SET_PAYMENT_METHOD:
    case Types.SET_AMOUNT:
      return {
        ...state,
        ...action.approveNewMemberState
      };
    default:
      return state;
  }
};

export default reducer;
