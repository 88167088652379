import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SubtitleBar,
  Overview,
  IconLabelButton
} from '../index';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import Loader from '../Loader/Loader';
import { UPDATE_DEDICATED_STATUS_RECORD } from '../../store/DedicatedStatus/types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LicenceActions from '../../store/Licence/actions';
import LicenseRemindersServices from '../../services/licence.service';
import { printLicenseReminders } from '../../shared/pdfHelpers';
import DedicatedService from '../../services/dedicatedstatus.service';
import MemberService from '../../services/member.service';
import { MEMBER_STATUS } from '../../shared/constants';
import { getMemberStatusFromInt } from '../../shared/helpers';
import { CloudDownload } from '@material-ui/icons';

const columns = [
  { field: 'applicationDate', headerName: 'Date' },
  { field: 'dstype', headerName: localStorage.getItem('isMobile') === 'true' ? 'Type' : 'Application Type' },
  { field: 'status', headerName: localStorage.getItem('isMobile') === 'true' ? 'Status' : 'Application Status' }
];

class LicenseReports extends Component {
  constructor(props) {
    super(props);
    this.licenseRemindersService = LicenseRemindersServices(this.props.token);
    this.dedicatedService = DedicatedService(this.props.token);
    this.memberService = MemberService(this.props.token);
  }
  
  downloadLicenseReminders = (licenseReminders, identifier) => this.dedicatedService
  .getApprovedDedicatedStatus(this.props.memNo)
  .then((res) => {
    let dsHunter = res.data
      .find(ds => ds.dstype.toLowerCase().includes('hunter') &&
        ds.status === MEMBER_STATUS.APPROVED) || {};
    let dsSports = res.data
      .find(ds => ds.dstype.toLowerCase().includes('sport') &&
        ds.status === MEMBER_STATUS.APPROVED) || {};
    return this.memberService.getAllImages()
      .then((images) => printLicenseReminders(
        licenseReminders,
        dsHunter.dsno,
        dsSports.dsno,
        identifier,
        images.data.narfo,
        { 
          ...this.props.memberDetails,
          status: getMemberStatusFromInt(this.props.memberDetails.status)
        },
      ));
  });

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
          <div className="border-radius-2 py-2 px-4 bg-white-2 mt-4">
            <SubtitleBar
              variant="subtitle2"
              title={'License Reports'}
            />

        <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}>License</Typography>
                </Grid>
                {
                  this.props.firearmLicenceList.length > 0 ?
                <Grid item xs={12} md={8}>
                <IconLabelButton
                  actionText="Download"
                  icon={<CloudDownload />}
                  full
                  callBack={() => this.downloadLicenseReminders(this.props.firearmLicenceList, '#auto-table')}
                />
                </Grid>
                :
                <Grid item xs={12} md={8}>
                <Typography style={{marginTop:'10px'}}><strong style={{fontStyle:'italic'}}>Report not found</strong></Typography>
                </Grid>
                }
              </Grid>

          </div>
      </React.Fragment>
    );
  }
}

const initialiseLicenceActions = dispatch => (licenceService) => {
    const action = LicenceActions(licenceService);
    return {
      fetchMemberLicenseReminderRecord: (id) => dispatch(action.fetchMemberLicenseReminderRecord(id))
    };
  };
    
  const mapDispatchToProps = dispatch => ({
    licenceActions: initialiseLicenceActions(dispatch),
  });
  
  const mapStateToProps = state => ({
      token: state.AuthReducer.token,
      firearmLicenceList: state.LicenceReducer.firearmLicence,
      sectionLookup: state.nLookupReduder.sectionLookup,
      memNo: state.AuthReducer.memNo,
      memberDetails: state.ProfileReducer.memberData,
  });

export default connect(mapStateToProps, mapDispatchToProps)(LicenseReports);