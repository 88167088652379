import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import { 
  Typography,
  Grid,
  Divider
} from '@material-ui/core';
import {
  ArrowBack,
} from '@material-ui/icons';
import {
  UPDATE_SECTION_TITLE,
  UPDATE_CURRENT_LMS_TAB
} from '../../store/LearningManagement/actionTypes';
import ProgressBar from '@ramonak/react-progress-bar';
import DisplayCourseMaterialOrQuiz from './DisplayCourseMaterialOrQuiz';
import DisplayCourseMaterialSelect from './DisplayCourseMaterialSelect';
import MemberService from '../../services/member.service';
import { printCourseCertificate } from '../../shared/pdfHelpers';
import Loader from '../Loader/Loader';

class LearningMaterial extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);
  }

  componentDidMount() {
    this.props.onSectionTitleChange('Course Learning Material')
	this.props.onCurrentLMSTabChange(0);
  }

    displayCourseProgress = (dsType, isMobile) => {
      let divs = [];
      let assComp = this.props.assesmentCompletion;
      Object.keys(assComp).forEach((_key, index) => {
        const assesment = Array.from(assComp[_key]);
        let total = assesment.length - 1;
        total = total ? total : 1;
        const progress = assesment.reduce((a, b) => {
          if (typeof b !== 'string') {
            return a + b;
          }
          return a;
        }, 0);
        let assesmentName;
        assesmentName = assesment[0];
        divs.push(
          <div className={isMobile ? "mt-8 outer-shadow border-radius-2 py-2 bg-white-1" : "mt-8 outer-shadow border-radius-2 py-2 px-4 bg-white-1"}>
            <div className={isMobile ? "pt-2 pb-2" : "pt-2 pb-2 pr-10"}>
              <div key={index} className="col-12 pb-2">
                <Grid container justify='space-between'>
                  <div>{isMobile ? assesmentName.split('Assessment Modules') : assesmentName.split('Modules')}</div>
                  <Typography color='primary'>{`${progress / total} %`}</Typography>
                </Grid>
                {this.Bar(progress / total)}
              </div>
            </div>
          </div>
        );
      });
      if(dsType === 'dh') {
        return divs[0];
      } else {
        return divs[1]; 
      }
    };

  Bar = (percent) => 
    <ProgressBar 
      completed={percent} 
      bgColor='#4F36A6'
      baseBgColor='#E3DFF0' 
      isLabelVisible={false}
    />;

  downloadCourseCertificate = courseTitle => {
    this.memberService.getAllImages()
      .then((images) => {
        printCourseCertificate(
          this.props.memberData, courseTitle, images.data.nnfo, images.data.pdfSign, images.data.pdflogo
        );
      });
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
        <SubtitleBar
          variant="subtitle1"
          title={isMobile ? 'Learning material' : 'Course learning material'} 
        />
        {!this.props.progressArr === true && <Loader /> ||
				!this.props.progressArr === false &&
          <div className={isMobile ? "inner-shadow border-radius-2 py-2 px-3 bg-white-2" : "inner-shadow border-radius-2 py-2 px-4 bg-white-2"}>
            {this.props.currentLMSpage === 0 &&
            <React.Fragment>
              <SubtitleBar 
                variant='h4'
                title={isMobile ? 'Progress' : 'Progress and certificates'}
                showDownloadDSSCourseCertificate={this.props.progressArr[1] >= 70}
                downloadDSSCourseCertificateCallback={() => this.downloadCourseCertificate('Dedicated Sport Shooter Assessment Modules')}
                showDownloadDHCourseCertificate={this.props.progressArr[0] >= 70}
                downloadDHCourseCertificateCallBack={() => this.downloadCourseCertificate('Dedicated Hunter Assessment Modules')}
              />
              <div className="mb-3">
                {this.displayCourseProgress('dh', isMobile)}
                {this.displayCourseProgress('dss', isMobile)}
              </div>
              <DisplayCourseMaterialOrQuiz/> 
            </React.Fragment> || this.props.currentLMSpage === 1 &&
            <React.Fragment>
              <Grid item xs={1} container alignContent="center">
                <ArrowBack 
                  color="primary" 
                  className="pointer" 
                  onClick={() => this.props.onCurrentLMSTabChange(0)}/>
              </Grid>
              <Divider/>
              <DisplayCourseMaterialSelect />
            </React.Fragment>}
          </div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  assesmentCompletion: state.nLearningManagementReducer.assesmentCompletion,
  currentLMSpage: state.nLearningManagementReducer.currLmsTab,
  memberData: state.ProfileReducer.memberData,
  memNo: state.AuthReducer.memNo,
  progressArr: state.nLearningManagementReducer.progressArr,
});

const mapDispatchToProps = dispatch => ({
  onSectionTitleChange: newSectionTitle =>
    dispatch({
      type: UPDATE_SECTION_TITLE,
      sectionTitle: newSectionTitle
    }),
  onCurrentLMSTabChange: newCurrentLmsTab =>
    dispatch({
      type: UPDATE_CURRENT_LMS_TAB,
      currLmsTab: newCurrentLmsTab
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(LearningMaterial);