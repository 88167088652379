import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import GenericHeader from "../../../utils/GenericHeader";
import Activity from "./Activity";
import FilterBar from "./FilterBar";
import { BASE, GET_SPECIFIC_ACTIVITY } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import { mainActivitiesStyles as styles } from "./styles/styles";

class MainActivities extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {};
  }

  refreshPage() {
    axios
      .get(
        BASE +
          GET_SPECIFIC_ACTIVITY +
          (this.props.memberState.isAdmin
            ? this.props.MemberV.memNo
            : this.props.userDetails.memberNumber),{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .catch(function(error) {});
    this.props.onDedicatedChange("Home", "Page");
  }

  getMemberActivities = () => {
    axios
      .get(
        BASE +
          GET_SPECIFIC_ACTIVITY +
          (this.props.memberState.isAdmin
            ? this.props.MemberV.memNo
            : this.props.userDetails.memberNumber),{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res => {
        const activities = res.data;
        this.props.onAdminActivitiesChange(activities);
      });
  };
  componentDidMount() {
    this.getMemberActivities();
  }

  getFilteredActivities = activities => {
    activities =
      this.props.actTypeFilter !== "Activity Type"
        ? (activities = activities.filter(act => {
            return act.activityType === this.props.actTypeFilter;
          }))
        : activities;
    return activities;
  };
  resetFilters = () => {
    this.props.onAdminActivityTypeFilterChange("Activity Type");
    this.props.onAdminActivityStatusFilterChange("Status");
  };

  showFilterBar = () =>
    this.props.showActFilterBar ? <FilterBar /> : this.resetFilters();

  render() {
    const { classes } = this.props;
    let acts =
      this.props.adminActivities === null
        ? []
        : this.props.adminActivities.slice();

    return (
      <div className="container">
        <div className="admin-activities-root">
          <GenericHeader
            title="Activities"
            showDownloadButton
            text="Activities"
            downloadFunction={() => this.DownloadActivity()}
          />
          <div className={classes.root}>
            {this.getFilteredActivities(acts).map((activity, index) => (
              <Activity
                key={index}
                activity={activity}
                updateActivities={this.getMemberActivities}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

MainActivities.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  return {
    MemberDetails: state.MemberViewReducer.MemberView,
    adminActivities: state.activitiesReducer.adminActivities,
    actTypeFilter: state.activitiesReducer.activityAdminTypeFilter,
    actStatusFilter: state.activitiesReducer.activityAdminStatusFilter,
    showActFilterBar: state.activitiesReducer.showAdminActivityFilterBar,
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    memberState: state.loginReducer.memberState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAdminActivitiesChange: newAdminActivitiesArray =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITIES,
        payload: newAdminActivitiesArray
      }),
    onAdminActivityTypeFilterChange: newAdminActivityTypeFilter =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_TYPE_FILTER,
        payload: newAdminActivityTypeFilter
      }),
    onAdminActivityStatusFilterChange: newAdminActivityStatusFilter =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_STATUS_FILTER,
        payload: newAdminActivityStatusFilter
      }),
    onShowAdminActivityFilterBarToggle: () =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.TOGGLE_SHOW_ADMIN_ACTIVITY_FILTER_BAR
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MainActivities));
