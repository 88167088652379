import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as FilterActions from "./FilterActions";
class ActivitiesFilter extends Component {
    constructor(props){
        super(props);
        this.resetFilter = this.resetFilter.bind(this);
    }
    resetFilter(){
        this.props.resetFilter();
        this.props.FilterActions.resetFilter();
    }
    render() {
        return (
            <div className="container-fluid border-bottom mb-2 pb-3">
                <div className="row m-1">
                    <div className="col-6 d-flex justify-content-center">
                        <button 
                            onClick={()=>this.props.FilterActions.setCategory("Hunting")}
                            className="btn btn-outline-dark w-75">Hunting</button>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                        <button 
                            onClick={()=>this.props.FilterActions.setCategory("Sport Shooting")}
                            className="btn btn-outline-dark w-75">Sport Shooting</button>
                    </div>
                </div>
                <div className="row m-1">
                    <div className="col-6 d-flex justify-content-center">
                        <input 
                            onChange={(e)=>this.props.FilterActions.setStartDate(e.target.value)}
                            className="btn btn-outline-dark w-75" type="date" />
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                        <input 
                            onChange={(e)=>this.props.FilterActions.setEndDate(e.target.value)}
                            className="btn btn-outline-dark w-75" type="date" />
                    </div>
                </div>
                <div className="row m-1">
                    <div className="col-6 d-flex justify-content-center">
                        <button 
                            onClick={this.resetFilter}
                            className="btn btn-outline-dark w-75">Reset All</button>
                    </div>
                    <div className="col-6 d-flex justify-content-center">
                        <button 
                            onClick={this.props.applyFilter}
                            className="btn btn-outline-dark w-75">Appy Filter</button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        activityFilterState: state.activityFilterState
    };
};

const mapActionsToProps = dispatch => ({
    FilterActions: bindActionCreators(FilterActions, dispatch)
});

export default connect(
    mapStateToProps,
    mapActionsToProps
)(ActivitiesFilter);
