import React, { Component } from "react";
import AdminNewsCard from "./AdminNewsCard";
import { ARTICLE_URL } from "../../API/APIendpoint";
import Axios from "axios";
import { BASE } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80%",
    overflow: "auto"
  }
};
class AdminHome extends Component {
  componentDidMount() {
    Axios.get(BASE + ARTICLE_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const article = res.data;
      this.props.onArticlesChange(article);
    });
  }

  render() {
    return (
      <div>
        {this.props.articleList.map((article, index) => (
          <AdminNewsCard key={index} article={article} />
        ))}
      </div>
    );
  }
}
AdminHome.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.article,
    articleList: state.articlesReducer.articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      }),
    onArticlesChange: newArticles =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLES,
        articles: newArticles
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminHome));
