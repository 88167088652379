import { BASE } from "../../API/APIendpoint";

export const GET_COURSE_MODULES_ONLY = BASE + 'api/CourseModule',
  GET_COURSE_MODULES_WITH_CHILDREN = BASE + 'api/CourseModule/withChildren',
  COURSE_MODULE = BASE + 'api/CourseModule',
  COURSE_MODULE_MOVE = COURSE_MODULE + '/moveTo',
  COURSE_MATERIAL = BASE + 'api/CourseMaterial/put',
  COURSE_MATERIAL_MOVE = COURSE_MATERIAL + '/moveTo',
  COURSE_MATERIAL_CHANGE_NAME = COURSE_MATERIAL + '/changeName',
  COURSE_MODULE_NAME_UPDATE = COURSE_MODULE + '/changeName',
  QUESTION = BASE + 'api/question',
  QUESTION_SWAP_INDEX = QUESTION + '/swapIndex',
  QUESTION_UPDATE_INDEX = QUESTION + '/updateIndex',
  MEMBER_ASSESSMENT = BASE + 'api/MemberAssesments',
  WEEK_STATISTICS = BASE + 'api/CourseStatistics/week',
  MONTH_STATISTICS = BASE + 'api/CourseStatistics/month',
  DELETE_MODULE = BASE + 'api/CourseAssessment/',
  YEAR_STATISTICS = BASE + 'api/CourseStatistics/year'