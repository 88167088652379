import React, { Component } from "react";
import { connect } from "react-redux";
import MainLogin from "./MainLogin";

class LoginHandler extends Component {
  render() {
    return (
      <div>
        <MainLogin />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails
  };
};

export default connect(
  mapStateToProps,
  null
)(LoginHandler);
