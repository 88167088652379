import React, { Component } from "react";
import Dashboard from "./Dashboard";
import AdminMembers from "./AdminMembers";
import { connect } from "react-redux";

class MainAdmin extends Component {
  componentDidMount() {
    if (sessionStorage.getItem("reload") === "true") {
      sessionStorage.setItem("reload", "false");
      caches.delete();
      window.location.reload(false);
    }
  }
  render() {
    return (
      <div className="container" style={{marginTop: "60px"}}>
        <Dashboard history={this.props.history}/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails
  };
};
export default connect(mapStateToProps)(MainAdmin);
