import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
  } from "@material-ui/core";

export default function ListItemWithIcon(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <ListItem style={{padding:'0px', marginLeft:'15px'}}>
        <ListItemIcon style={{fontSize:"25px", color:'#525A64'}}>
           <strong>•</strong>
        </ListItemIcon>

        <ListItemText
        style={{marginLeft:'-25px',color:'#525A64'}}
          primary={props.text}
        />
    </ListItem>
    );
}