import API_URL from './apiUrl';
import axios from 'axios';

const getMemberEndorsements = (headers) => (id) => axios.
  get(`${API_URL}/EndorsementApplications/getEndorsment/${id}`, {headers});

const postMemberEndorsement = (headers) => (data, params) => axios.
  post(`${API_URL}/EndorsementApplications/PostEndorsementApplication`, data,
    {
      headers:
      {
        ...headers,
      },
      params:
      {
        ...params
      }
    }
  );

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getMemberEndorsements: getMemberEndorsements(headers),
    postMemberEndorsement: postMemberEndorsement(headers)
  };
}

  