import API_URL from './apiUrl';
import axios from 'axios';

const GetllSubscription = headers => () => axios({
	url: `${API_URL}/Subscription/GetllSubscription`,
	method: "get",
	headers: headers
  });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    GetllSubscription: GetllSubscription(headers)
  };
}
