import { makeStyles } from '@material-ui/styles';

import huntingInDesertBackgroundImg from "../assetsNew/huntingInDesert.jpg";
import desertBackgroundImg from "../assetsNew/hunterDesert.png"
import huntingInDeserLightBackgroundImg from "../assetsNew/huntingInDesert1.jpg";
import huntingAssessmentBackgroundImg from "../assetsNew/sportShootingImg1_.jpg";
import protectSectionBackgroundImg from "../assetsNew/Protect_section.jpg";

export const useStylesFirearmsGuardians = makeStyles(theme => ({
    container:{
        // marginTop:'-22px'
    },
    huntingContainer: {
        paddingLeft: '3.5em', 
        paddingRight: '3.5em',
        marginTop: '3em',
        marginBottom: '3em',
        width: '100%',
        height: '100%'
    },
    background: {
        backgroundImage: `url(${huntingInDesertBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
    },
    backgroundDesert: {
        backgroundImage: `url(${desertBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
    },
    dedicatedHunterBackground: {
        backgroundImage: `url(${huntingInDeserLightBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
    },
    huntingAssessmentBackground: {
        backgroundImage: `url(${huntingAssessmentBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
    },
    overviewContainer: {
        height: '500px'
    },
    overviewContainerMobile: {
        height: '400px'
    },
    overviewText: {
        color: 'white',
        textShadow: '1px 2px black',
        marginLeft: '13em',
        marginTop: '9em'
    },
    overviewTextMobile: {
        color: 'white',
        textShadow: '1px 2px black',
        marginLeft: '0.5em',
        marginTop: '3em'
    },
    headerSpacing: {
        marginBottom: '0.5em'
    },
    listContainer: {
        marginLeft: '2.5em'
    },
    anchorPoint: {
        position: 'absolute'
    },
    buttonSize: {
        width: '200px'
    },
    todayContainer: {
        marginBottom: '2em'
    },
    imgMobile :{
        margin: 'auto',
        width:"45%",
    },
    wrapMobile:{
        marginLeft:'-5px'
    },
    img :{
        width:"30%"
    },
    wrap:{
        margin: 'auto',
        width:"40%",
    },
    header:{
        marginTop:'50px',
        boxSizing: "border-box",
        color: '#525A64',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "50px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
    },
    subHeader:{
        boxSizing: "border-box",
        color: '#525A64',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "40px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
    },
    headerMobile:{
        boxSizing: "border-box",
        color: '#525A64',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "25px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
    },
    videoSection:{
        backgroundColor:'#D8D8D8',
        padding:'50px'
    },
    videoSectionMobile:{
        backgroundColor:'#D8D8D8',
        paddingTop:'30px',
        paddingBottom:'30px'
    },
    centerContent:{
        textAlign:'center',
        display:"block",
        margin: "auto",
        padding:"0px"
    },
    offersAndLegal:{
        backgroundColor:"#1b2530",
        color:'white',
        fontFamily: "Sans-serif, museo-sans",
        fontWeight:400, 
        fontSize:"70px",
        padding:"20px"
    },
    offersAndLegalMobile:{
        backgroundColor:"#1b2530",
        color:'white',
        fontFamily: "Sans-serif,museo-sans",
        fontWeight:400,
        fontSize:"70px",
        padding:"20px"
    },
    lisItem:{
        padding:'0px'
    },
    columnSapcing:{
        paddingRight:'30px'
    },
    protectHeader:{
        marginTop:"50px",
        boxSizing: "border-box",
        color: '#FFFFFF',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "50px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
    },
    protectHeaderMobile:{
        boxSizing: "border-box",
        color: '#FFFFFF',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "25px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",

    },
    protectSection:{
        padding:'50px',
        backgroundImage: `url(${protectSectionBackgroundImg})`,        
        height: "300px",
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: `rgba(0,0,0,0.5)`,
        backgroundBlendMode: 'multiply'
    },
    protectSectionMobile:{
        backgroundColor:'#D8D8D8',
        paddingTop:'30px',
        paddingBottom:'30px',
        backgroundImage: `url(${protectSectionBackgroundImg})`,
        overflow: "hidden",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: `rgba(0,0,0,0.5)`,
        backgroundBlendMode: 'multiply'
    },
    coverHeader:{
        boxSizing: "border-box",
        color: '#64724F',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "35px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
        padding:'50px'
    },
    coverText:{
        color:'#525A64',
        fontFamily: "museo-sans Sans-serif",
        fontSize: "20px",
        fontWeight: "normal",
        boxSizing: "border-box",
        color: `rgb(82, 90, 100)`,
        display: `inline`,
        fontStyle: "italic",
        height: "auto",
        lineHeight: "24px",
        width: "auto",
    },
    coverHeaderMobile:{
        boxSizing: "border-box",
        color: '#64724F',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "25px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        textAlign: "center",
        padding:'30px'
    },
    accordionAfter:{
        textAlign:'center',
        paddingTop: '50px',
        fontSize:"18px"
    },
    outlinedButton:{
        backgroundColor: "#64724F",
    },
    coverPremiumOptions: {
        backgroundColor:'#2F3945',
        height: "500px",
        color:'white',
        "&:hover": {
            background: "#1b2530"
          }
    },
    coverImageContainer:{
        height: "500px",
        objectFit:'cover'
    },
    coverImageContainer: {
        color: 'rgb(82, 90, 100)',
        display: 'block',
        height: "500px",
        width: "464px",
        marginTop:'-8px',
    },
    coverPremiumMobile:{
        marginBottom:'-10px'
    },
    joinSectionImage:{
        margin: 'auto',
        width:"250px",
    },
    joinSectionTitle:{
        boxSizing: "border-box",
        color: '#64724F',
        display: "block",
        fontFamily: "museo-sans sans-serif",
        fontSize: "35px",
        fontWeight: 600,
        height: "70px",
        lineHeight: "35px",
        // textAlign: "center",
    },
    joinSectionContent:{
        margin: 'auto',
        textAlign:'center',
        width:"100%",
    },
    joinText:{
        color:'#525A64',
        fontFamily: "museo-sans",
        fontWeight:400,
        boxSizing: "border-box",
        color: `rgb(82, 90, 100)`,
        fontSize:"16px",
        display: `inline`,
        fontStyle: "italic",
        height: "auto",
        lineHeight: "24px",
        width: "auto",
    },
    setp1Section:{
        color:'#525A64',
    },
    formSection:{
        paddingTop:"30px"
    },
    textColor:{
        color:'#525A64',
    },
    insuresense:{
        backgroundColor:'#D7D7D7',
        color:'#525A64',
        fontFamily:"museo-sans Sans-serif",
        fontWeight:500
    },
    insuresenseMobile:{
        backgroundColor:'#D7D7D7',
        color:'#525A64',
        fontFamily:"museo-sans Sans-serif",
        fontWeight:500,
        padding:'20px',
        marginTop:'20px',
    },
    genericSection:{
        backgroundColor:'#E1E1E1',
        color:'#525A64',
        fontFamily:"museo-sans Sans-serif",
        fontWeight:100,
        fontSize:'16px',
        padding:'20px'
    },
    genericSectionMobile:{
        backgroundColor:'#E1E1E1',
        color:'#525A64',
        fontFamily:"museo-sans Sans-serif",
        fontWeight:100,
        fontSize:'16px',
        padding:'20px',
        paddingBottom:'60px'
    }
}))