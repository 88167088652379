import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar
} from '../index';
import { Grid, Typography, Button } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';

const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  }
});

const columns = [
  { field: 'date', headerName: 'Date' },
  { field: 'title', headerName: 'Title'}
];

class Article extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLetterList: props.newsLetterList,
    };

  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;

    return(
      <React.Fragment>
          {/* <Typography align="justify">
            </Typography> */}
      <SubtitleBar variant="subtitle2" title={this.props.article?.title} 
      back
      onBack={this.props.goBack}
      />
      <Interweave content={this.props.article?.content}/>
      </React.Fragment>
    );
  }
}


export default connect(null, null)(withStyles(styles)(Article));