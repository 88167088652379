import * as actionTypes from "../actions/actionTypes";
const initialState = {
    loyaltyDaysObject: {},
    currentLoyaltyDaysTab: "0",
    loyaltyRecord: {},
    loyaltyRecordArray: []
  };
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.GET_LOYALTY_DAYS:
        return {
          ...state,
          loyaltyDaysObject: action.loyaltyDaysObject
        }
      case actionTypes.UPDATE_LOYALTY_DAYS_TAB:
        return {
          ...state,
          currentLoyaltyDaysTab: action.currentLoyaltyDaysTab
        }
      case actionTypes.GET_MEM_LOYALTY_RECORD:
        return {
          ...state,
          loyaltyRecord: action.loyaltyRecord
        }
      case actionTypes.GET_LOYALTY_DAYS_ARRAY:
        return {
          ...state,
          loyaltyRecordArray: action.loyaltyRecordArray
        }
      default:
        return state;
    }
  };
  export default reducer;