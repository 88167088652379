import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  GenericTable,
  TabPanel,
  IconLabelButton,
  Loader,
  ViewActivityRanking,
  EditableGridLabel
} from '..';

import { valid } from '../../shared/helpers';
import { checkValidity, updateObject, initSelect } from '../../shared/utility';
import PropTypes from 'prop-types';
import {
  Paper, 
  Tab, 
  Tabs, 
  Typography,
  Grid,
  
} from '@material-ui/core';
import ActivityService from '../../services/activities.service';

const formatScore = score => <bold>{score}</bold>;

const currentYear = new Date().getFullYear();

function generateYearList(startYear, endYear) {
  const yearList = [];
  
  for (let year = startYear; year <= endYear; year++) {
    yearList.push({ value: String(year), label: String(year) });
  }

  return yearList.reverse();
}

const years = generateYearList(2018, currentYear);

const columns = [
  { field: 'activityDescription', headerName: 'Discipline'},
  { field: 'association', headerName: 'Association'},
  {
    field: 'score',
    headerName: 'Score',
    format: (item) => formatScore(item)
  },
  {
    field: 'rank',
    headerName: 'Rank',
    format: (item) => `#${item}`,   
  }
];

const narfoColumns = [
  { field: 'firstname', headerName: 'Firstname'},
  { field: 'surname', headerName: 'Surname'},
  { field: 'score', headerName: 'Top 3 AVG'},
  {
    field: 'rank',
    headerName: 'Rank',
    format: (item) => `#${item}`,   
  }
];

const otherDisciplineColumns = [
  { field: 'firstname', headerName: 'Firstname'},
  { field: 'surname', headerName: 'Surname'},
  { field: 'score', headerName: 'Score'},
  {
    field: 'rank',
    headerName: 'Rank',
    format: (item) => `#${item}`,   
  }
];


class ActivityRanking extends Component {
  constructor(props) {
    super(props);
    this.activityService = ActivityService(this.props.token);
    this.currentYear = new Date().getFullYear();
    this.state = {
      controls: this.initControls(),
      activityRankings: this.props.activityRanking,
      narfoTopRankings:[],
      otherDisciplineRankings: [],
      viewRanking: false,
      ranking:{},
      tabValue: 0,
      activity: {
        activityEvent: '-',
        activityYear:'-',
        activityDiscipline: '-'
      },

      filterValue: {
        start: new Date(),
        end: new Date(),
        discipline: '-',
      }
    };
  }
  initControls = () => ({
    activityEvent: initSelect(this.props.activityEvent.filter(c => c.ranking == 1).sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))),
    activityDiscipline: initSelect(this.props.activityDiscipline.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))),
    activityYear: initSelect(years)
  });

  handleTabChange = (event, newValue) => {
    this.setState({tabValue: newValue});
  }

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }
    value = typeof value === 'number' ? value.toString() : value;
    const validity = checkValidity(value === '-' ? '' : value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });

    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });

    this.setState({
      controls,
      activity: {
        ...this.state.activity,
        [controlName]: value,
      },
      formHasErrors,
    });
  };

  handleSearch =(association)=>{
    if(association === 'Narfo')
    {
      const discipline = this.state.activity.activityEvent;
      const year =  this.state.activity.activityYear;
  
      this.activityService.getNarfoRankings(discipline,year)
      .then(respos =>{
        this.setState({narfoTopRankings:respos.data});
      });
    }
    else
    {
      const discipline = this.state.activity.activityDiscipline;
      const year =  this.state.activity.activityYear;
  
      this.activityService.getOtherDisciplineRankings(discipline,year)
      .then(respos =>{
        this.setState({otherDisciplineRankings:respos.data});
      });
    }
  }

  label = (title, name, fullWidth, isMobile) => (
    <Grid item xs={12} md={fullWidth ? 12: 6} lg={fullWidth ? 12: 4} container>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.activity[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)}
        isMobile={isMobile}
      />
    </Grid>
  );

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        {/* <SubtitleBar
          title="Activity Ranking"
          filter
          filterOptions={this.filterOptions(
            this.state.filterValue.start,
            this.state.filterValue.end,
            this.state.filterValue.discipline,
            this.props.disciplineLookup,
          )}
        /> */}
        <Paper square>
          <Tabs
            value={this.state.tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
          >
            <Tab label={isMobile ? "My Rank - " + this.currentYear : "My Ranking - " + this.currentYear} value={0} />
            <Tab label={ isMobile ? "Narfo" : "Narfo Activity Ranking" } value={1}/>
            <Tab label={ isMobile ? "Other" : "Other Ranking"} value={2}/>
          </Tabs>
        </Paper>
        <TabPanel index={0} value={this.state.tabValue}>
        <Typography>
          </Typography>
          { 
          !this.state.viewRanking ?
          <GenericTable 
            narfo
            view
            columns={columns}
            rows={this.props.activityRanking.myRankings}

             onViewCallBack={(item) => {
             this.props.getRankingCallback(item.activityDescription)
              .then(res => this.setState({ ranking: item.activityDescription}))
              .catch(() => this.setState({ranking: item}))
              .finally(() => this.setState({
                viewRanking: true,
                loading: false
              }));
          }}
        /> 
          :
          <ViewActivityRanking 
            backCallback={() => this.setState({ranking: {}, viewRanking: false})}
             myActivitiesRanking = {this.props.activityRanking.myActivitiesRanking}
             ranking = {this.state.ranking}
             memNo = {this.props.memNo}
          />}
        </TabPanel>
        <TabPanel index={1} value={this.state.tabValue}>
          <Typography>
          The NARFO ranking takes the average of the top three scores per event. 
            You may shoot more than three but only the three highest scores of 
            the same event will be used
          </Typography>
          <Grid item xs={12} container spacing={4}>

          {this.label('Activity Event', 'activityEvent', false, isMobile)}
          {this.label('Year', 'activityYear', false, isMobile)}

          <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled ={this.state.activity.activityEvent == '-' || this.state.activity.activityYear == '-'? true:false}
            actionText="Search" 
            color="primary" 
            callBack={() =>this.handleSearch('Narfo')}
          />}
          { this.state.loading && <Loader />}
        </div>
          </Grid>
          <br/>
          <GenericTable 
            columns={narfoColumns}
            rows= {this.state.narfoTopRankings}
          />
        </TabPanel>
        <TabPanel index={2} value={this.state.tabValue}>
          <Typography>
            We encourage members to participate in other 
            sport shooting events. For this ranking, we 
            take the top score for each of the events 
            entered by a member
          </Typography>
          <Grid item xs={12} container spacing={4}>
          {this.label('Activity discipline', 'activityDiscipline', false, isMobile)}
          {this.label('Year', 'activityYear', false, isMobile)}

          <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled ={this.state.activity.activityDiscipline == '-' || this.state.activity.activityYear == '-'? true:false}
            actionText="Search" 
            color="primary" 
            callBack={ ()=> this.handleSearch('other')}
          />}
          { this.state.loading && <Loader />}
        </div>
          </Grid>
          <br/>
          <GenericTable 
            columns={otherDisciplineColumns}
            rows= {this.state.otherDisciplineRankings}
          />
        </TabPanel>
      </React.Fragment>
    );
  }
}

ActivityRanking.defaultProps = {
  activityRanking: [],
  disciplineLookup :[],
  getRankingCallback: (activityDescription) => Promise.resolve({activityDescription}),
};

ActivityRanking.propTypes = {
  disciplineLookup: PropTypes.array.isRequired,
  getRankingCallback: PropTypes.func.isRequired
};


export default ActivityRanking;
