import API_URL from './apiUrl';
import axios from 'axios';

const getMemberNotifications = header => (memNo) => axios.
  get(`${API_URL}/Notifications/GetMemberNotifications?memNo=${memNo}`, {header});

const updateMemberNotification = header => (id, memNo) => axios.
  post(`${API_URL}/Notifications/updateMemberNotification?id=${id}&memberNo=${memNo}` , {header});

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getMemberNotifications: getMemberNotifications(headers),
    updateMemberNotification: updateMemberNotification(headers)
  };
}