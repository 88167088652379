import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import axios from "axios";
import "../styles/styles.css";
import {
  BASE,
  APPROVE_NEW_MEMBER_RENEWAL_URL
} from "../../../API/APIendpoint";
import defaultImg from "../images/dProfile.jpg";
import GenericHeader from "../../../utils/GenericHeader";
class ApproveMembershipRenewal extends Component {
  state = {
    showReasonInputBox: false,
    isApproved: false,
    isRejected: false,
    reason: "",
    Posts: [],
    userEndorsementList: [],
    image: null,
    _newRenewal: this.props.newRenewal.newRenewals
  };
  componentDidMount() {
    axios
      .get(BASE + "api/MemberProfilePictures/getMemId/" + this.props.currentMember.memNo,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        const res = response.data;
        this.setState({
          image:
            res[0] === null ? null : res[0].profilePicture.toString()
        });
      })
  }
  constructor(props) {
    super(props);
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }

  handleSubmit = e => {
    this.props.history.push("/PaymentRenewal");
    this.props.onAdminPortalChange("0");
  };

  updateApproveStatus = e => {
   
    fetch(BASE +
      APPROVE_NEW_MEMBER_RENEWAL_URL +
      this.props.currentMember.memNo +
      "/" +
      1, {
      method: 'POST',
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }
    )
    this.props.history.push("/PaymentRenewal");
  };

  updateRejectStatus = e => {
    axios.post(
      BASE +
      APPROVE_NEW_MEMBER_RENEWAL_URL +
      this.props.currentMember.memNo +
      "/" +
      3, {}, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }
    );
    this.props.history.push("/admin/MainAdmin");
  };

  updatePendingToReject = () => {
    this.state._newRenewal.forEach(element => {
      if(element.memNo === this.props.currentMember.memNo) {
        element.status = 3 
      }      
    })
    this.props.onMemberRenewalChange(
      this.props.pendingNRCount,
      this.state._newRenewal
    )
  }

  render() {
    return (
      <div className="container" style={{ marginTop: "60px", marginBottom: "80px" }}>
        <div>
          <GenericHeader
            title="Membership Renewal"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/MembershipRenewal");
            }}
          />
          <div className="media border-bottom ml-3">
            <div className="my-auto">
              <img
                src={this.state.image?this.state.image:defaultImg}
                alt="profile"
                className="border border-solid rounded-circle"
                style={{
                  width:"70px",
                  height:"70px"
                }}
              />
            </div>
            <div className="media-body">
              <div className="admin-dashboard-details-status">
                <span>
                  {this.props.currentMember.username
                    ? this.props.currentMember.username
                    : "-"}
                </span>{" "}
                <br />
                <span>
                  {" "}
                  {this.props.currentMember.sex
                    ? this.props.currentMember.sex
                    : "-"}
                </span>
                <br />
                <span>
                  {this.props.currentMember.inceptionDate
                    ? this.props.currentMember.inceptionDate.substring(0, 10)
                    : "-"}
                </span>
                <br />
                <span className="text-warning">Pending</span>
              </div>
            </div>
          </div>
          <div
            className={`admin-dashboard-details-dashboard-info ${
              this.state.reject ? "on-reject" : ""
              } `}
          >
            <div>
              <span>Member No:</span> <br />
              <span className="information">
                {this.props.currentMember.memNo
                  ? this.props.currentMember.memNo
                  : "-"}
              </span>
            </div>
            <div>
              <span>ID Number:</span> <br />
              <span className="information">
                {this.props.currentMember.idno
                  ? this.props.currentMember.idno
                  : "-"}
              </span>
            </div>
            <div>
              <span>Cell Number:</span> <br />
              <span className="information">
                {this.props.currentMember.cellNo
                  ? this.props.currentMember.cellNo
                  : "-"}
              </span>
            </div>
            <div>
              <span>Address:</span> <br />
              <span className="information">
                {this.props.currentMember.physicalAddress
                  ? this.props.currentMember.physicalAddress
                  : "-"}
              </span>
            </div>
            <div>
              <span>Ethnicity:</span> <br />
              <span className="information">
                {" "}
                {this.props.currentMember.ethinicity
                  ? this.props.currentMember.ethinicity
                  : "-"}
              </span>
            </div>
            <div>
              <span>Role:</span> <br />
              <span className="information">
                {this.props.currentMember.memType
                  ? this.props.currentMember.memType
                  : "-"}
              </span>
            </div>
            <div>
              <span>Inception Date:</span> <br />
              <span className="information">
                {this.props.currentMember.inceptionDate
                  ? this.props.currentMember.inceptionDate.substring(0, 10)
                  : "-"}
              </span>
            </div>
            <div>
              <span>Expiry Date:</span> <br />
              <span className="information">
                {this.props.currentMember.expiryDate
                  ? this.props.currentMember.expiryDate.substring(0, 10)
                  : "-"}
              </span>
            </div>
            <div>
              <span>Payment:</span> <br />
              <span className="information">
                {this.props.currentMember.association
                  ? this.props.currentMember.association
                  : "Manual"}
              </span>
            </div>
          </div>

          <div className="row p-3 border mt-2 rounded-top fixed-bottom bg-light">
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-outline-success border-dark text-dark w-75"
                onClick={() => {
                  this.props.history.push("/PaymentRenewal");
                }}
              >
                Approve
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-outline-danger border-dark text-dark w-75"
                onClick={() => {
                  this.updateRejectStatus();
                  this.updatePendingToReject();
                  this.props.history.push("/admin/MembershipRenewal");
                }}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    names: state.membersAreaReducer.membersArea.memberName,
    members: state.MemberViewReducer.MemberList,
    currentMember: state.MemberViewReducer.MemberView,
    userProfileDetails: state.loginReducer.userProfileDetails,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    memberList: state.dashBoardReducer.totalMembers.members,
    newRenewal: state.dashBoardReducer.newRenewals,
    pendingNRCount: state.dashBoardReducer.pendingNRCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      }),
    onDataChange: (data, name) => {
      return dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: data,
        varName: name
      });
    },
    onMemberRenewalChange: (pendingCount, memArray) =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.GET_ALL_RENEWAL_MEMEBRS_URL,
        newRenewals: {
          pendingNRCount: pendingCount,
          newRenewals: memArray
        },
      }),
    onResetViewMembers: () => {
      return dispatch({
        type: actionTypes.RESET_MEMBERS_VIEWED_ACTIVITY
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveMembershipRenewal);
