import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import SITE_URL from "../../services/siteURL";
import Axios from "axios";
import API_URL from "../../services/apiUrl";
import dataCountries from "../Activities/countries.json";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin:'3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function HuntingGallery() {
  const [index, setIndex] = useState(-1);
  const [Images, setImages] = useState([]);
  const [defaultImages, setDefaultImages] = useState([]);
  const [species, setSpecies] = useState([]);
  const [defaultFarms, setDefaultFarms] = useState([]);
  const [defaultSpecies, setDefaultSpecies] = useState([]);
  const [tempFarmImages, setTempFarmImages] = useState([]);
  const [tempSpecieImages, setTempSpecieImages] = useState([]);
  const [farms, setFarms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const currentImage = Images[index];
  const nextIndex = (index + 1) % Images.length;
  const nextImage = Images[nextIndex] || currentImage;
  const prevIndex = (index + Images.length - 1) % Images.length;
  const prevImage = Images[prevIndex] || currentImage;
  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    // call the function
    setIsLoading(true);
    fetchData()
    .then(_ =>{
      // console.log("Fetch DATA", data)  NB!!!!! bring this back if images are not loading
      setIsLoading(false);
    })
      // make sure to catch any error
    .catch(console.error);

  }, []);


  const fetchData = async () => {
    await getGalleryActivityItem().then(data =>{
    })
    .catch(error =>{
      console.log("Error", error)
    });
    }
  
  const getHeightAndWidthFromDataUrl = async (dataURL) => {
    const img = new Image();
    img.src = dataURL;
    try   
    {
      await img.decode();
    }
    catch(ex){
      console.log("Cannot decode Image", ex)
      console.log("Image " + dataURL)
      return {
        height: 0,
        width: 0,
      };
    }

    return {
      height: img.height,
      width: img.width,
    };
  };
  
  const objectExistsInArray = (array, obj)=> {
    for (let i = 0; i < array.length; i++) {
      if (JSON.stringify(array[i]) === JSON.stringify(obj)) {
        return true;
      }
    }
    return false;
  }
  
  const getGalleryActivityItem = async () => {
    const response = await Axios.get(`${API_URL}/Gallery/GetGalleryData`);
    const farmsData = [];
    const speciesResponse = await Axios.get(`${API_URL}/SpecieLookups`);
    const speciesData = speciesResponse.data;
    const mappedImages = await Promise.all(response.data.map(async (item) => {

      let _farm = { name: item.farmName, id: item.farmName };

      if(!objectExistsInArray(farmsData,_farm))
        farmsData.push(_farm);
  
      const nickName = item.nickName;
  
      //Build image url
      let imageUrl = SITE_URL.includes("portal.narfo.co.za")
        ? SITE_URL + item.url
        : "https://qaportal.narfo.co.za/" + item.url;
  
      const { height, width } = await getHeightAndWidthFromDataUrl(imageUrl);
  
      if(height == 0 && width == 0)
      {
        console.log("Error decoding image", item);
      }

      return {
        src: imageUrl,
        original: imageUrl,
        width,
        height,
        tags: [
          { title: "NickName", value: nickName },
          { title: "Location", value: item.country + ", " + item.state },
          { title: "farmName ", value: "Farm name - " + item.farmName },
          { title: "specie", value: item.specie },
        ],
      };
      
    }));
  
    setImages(mappedImages);
    setDefaultImages(mappedImages);
    setFarms(farmsData);
    setDefaultFarms(farmsData);
    setSpecies(speciesData);
    setDefaultSpecies(speciesData);
  };
  
  const handleSearch = (tags, option) => {
    let results = [];

    switch (option) {
      case 0:
        results = searchByTags(tags);
        let _farms = defaultFarms;

        if (results.length > 0 && tags.length > 0) {
          //Filter farms based on country
          _farms = filterFarmsDropDownlist(results);
          setFarms(_farms);
          setTempFarmImages(results);
        }
        else if(tags.length > 0) setFarms([]);
        else setFarms(_farms);

        break;

      case 1:

        if(tags.length === 0){
          //Set species dropdown to default
          results = tempFarmImages;

          //We all the results and base of the country 
          setSpecies(defaultSpecies);

          break;
        }
        results = searchByFarmTags(tags);

        let _species = defaultSpecies;

        if (results.length > 0 && tags.length > 0) {
          //Filter species based on farms
          _species = filterSpecieDropDownlist(results); //Also filer based on country 
          setSpecies(_species);
          setTempSpecieImages(results);
        }
        else if(tags.length > 0) 
          setSpecies([]);

        break;

      case 2:
        if(tags.length) 
           results = searchBySpecieTags(tags);
        else 
           results = tempSpecieImages;
        break;
    }

    setImages(results || []);
  };

  const searchByTags = (tagValues) => {
    return defaultImages.filter((item) => {
      return tagValues.every((tagValue) => {
        return item.tags.some((tag) => {
          return tag.value.split(",")[0] === tagValue.country;
        });
      });
    });
  };

  const searchByFarmTags = (tagValues) => {
    return defaultImages.filter((item) => {
      return tagValues.every((tagValue) => {
        return item.tags.some((tag) => {
          return tag.value.replace("Farm name - ", "") === tagValue.name;
        });
      });
    });
  };

  const searchBySpecieTags = (tagValues) => {
    return defaultImages.filter((item) => {
      return tagValues.every((tagValue) => {
        return item.tags.some((tag) => {
          return tag.value === tagValue.specie;
        });
      });
    });
  };

  // Filter Dropdown based on country selected
  const filterFarmsDropDownlist = (imageList) => {
    let _farmList = [];

    imageList.map((image) => {
      _farmList.push({ name: image.tags[2].value.replace("Farm name - ", ""), id: image.tags[2].value});
    });

    return _farmList;
  };

  const filterSpecieDropDownlist = (imageList) => {
    let _species = [];

    imageList.map((image) => {
      _species.push({ specie: image.tags[3].value, id: image.tags[3].value});
    });

    return _species;
  };

  return (
    
    <div>
      <Grid className={classes.root}>
        {/* Search Bar for countries*/}
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <Autocomplete
            multiple
            limitTags={3}
            id="countries"
            options={dataCountries.countries}
            getOptionLabel={(option) => option.country}
            onChange={(e, value) => handleSearch(value, 0)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Search Country"
                placeholder="Add country"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item md={4} xs>
        {/* Search Bar for Farm name*/}
        <Autocomplete
          limitTags={3}
          multiple
          id="farms"
          options={farms}
          getOptionLabel={(option) => option.name}
          onChange={(e, value) => handleSearch(value, 1)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Search Farm"
              placeholder="Add farm"
              variant="outlined"
            />
          )}
        />
        </Grid>
        <Grid item md={4} xs>
        {/* Search Bar for species name*/}
        <Autocomplete
          limitTags={3}
          multiple
          id="species"
          options={species}
          getOptionLabel={(option) => option.specie}
          onChange={(e, value) => handleSearch(value, 2)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Search Specie"
              placeholder="Add specie"
              variant="outlined"
            />
          )}
        />
        </Grid>
      </Grid>
  
      </Grid>

     {!isLoading && <Gallery
        images={Images}
        onClick={handleClick}
        enableImageSelection={false}
      />}

    <div style={{margin:"50%", marginTop:"5%"}}>
      {isLoading && <CircularProgress size="5rem" />}
    </div>

      {!!currentImage  && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </div>
  );
}
