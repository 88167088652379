import React, { Component } from 'react'
import PropTypes from "prop-types";
import { TextValidator } from "react-material-ui-form-validator";
import { ValidatorForm } from "react-form-validator-core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Button from '@material-ui/core/Button';
import {BASE,GET_OPTICS_LIST,POST_OPTICS,DELETE_OPTICS} from "../../../API/APIendpoint";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";

const styles = theme => ({
    root: {
      height: "100%"
    },
    center: {
      margin: "auto",
      width: 50,
      padding: 10
    },
    aligner: {
      textAlign: "center"
    },
  
    container: {
      display: "flex",
      flexDirection: "column",
      align:"center"
    },
  
    footer: {
      display: "comtent",
      flexDirection: "row"
    },
  
    TextValidator: {
      marginLeft: "20%",
      marginRight: "20%",
      marginTop: "1%",
      fullWidth: true,
      flex: "1",
      margin: "5",
      instantValidate: true
    },
  
    textField: {
        marginTop: "1%",
        marginLeft: "30%",
        marginRight: "30%",
        flex: 1,
        margin: 5,
        width:"40%"
      },

      button:{
        margin: theme.spacing.unit,
        backgroundColor: "#FFFFFF",
        borderColor:"#000000",
        '&:hover': {
            backgroundColor: "#F5A623",
            color:'White',
            borderColor:'Black'
          },
        width: "20%",
        color:'Black',
        align:"Left",
        marginLeft: "10%",
        marginRight: "30%",
      },
 
    select: {
      marginLeft: "5%",
      marginRight: "5%",
      marginTop: "1%",
      flex: "1",
      margin: "5"
    }
  });

class DropDownOptics extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Optics_List: [],
          DelKey:"",
          isValid:false,
          showValid:true            
        };
      }
    handleChange = name => event =>{
        this.props.onSectionChange(event.target.value,[name])
        this.setState({showValid:true})
        this.checkNotEmpty()
    };
    checkNotEmpty = () => {
      let V = true;
        if (this.props.Selections.Optics === "" ||
          this.props.Selections.Optics === null || 
          this.props.Selections.Optics === undefined) 
          {V = false;}
      this.setState({
        isValid: V
      });
    }
    submitSection()
    {
      if(this.state.isValid && !(this.props.Selections.Optics === "")){
        fetch(BASE + POST_OPTICS, {
            method: "POST",
            body: JSON.stringify({
                Optics:this.props.Selections.Optics
            }),
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          }).then(()=>{
            fetch(BASE + GET_OPTICS_LIST,{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(res => res.json())
            .then(json => { 
              this.setState({
                  Optics_List: json
              });
          });

          });}
          this.setState({isValid:false})  
          this.componentDidMount()
     
    };
    DeleteSection()
    {
        fetch(BASE + DELETE_OPTICS +this.props.Selections.DeleteOpticsKey, {
            method: "POST",
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
          }).then(()=>{

            fetch(BASE + GET_OPTICS_LIST,{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(res => res.json())
            .then(json => { 
              this.setState({
                  Optics_List: json
              });
          });
          }); 
          this.componentDidMount()      
    };

    DeleteKey = (e) =>
    {
         
        this.setState({
            DelKey : [e.target.value]
        })
        this.props.onSectionChange(e.target.value,"DeleteOpticsKey")
    }

    componentDidMount()
    {
      this.props.onSectionChange("","Optics")
      this.setState({showValid:false})
      fetch(BASE + GET_OPTICS_LIST,
        {
          headers: {
            'Content-Type': 'application/json'
            ,'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(res => res.json())
            .then(data => { 
        this.setState({
            Optics_List: data
        });
    });

    ValidatorForm.addValidationRule("isEmpty", value => {
      if(this.state.showValid){
      if (!value) {
        return false;
      }
      if (value.length < 0) {
        return false;
      }}
      return true;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.container} noValidate autoComplete="off">
        <h1 className="row d-none d-md-block" align="Middle">Optics</h1>
        <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
          <TextValidator
            id="OutlinedSection"
            className={classes.textField}
            value={this.props.Selections.Optics}
            label="Optics"
            onChange={this.handleChange("Optics")}
            name="Optics"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter the Optics Used",
              "This field is required"
            ]}
            margin="normal"
            variant="outlined"
          />  

          <Button variant="contained" 
          className={classes.button}
          onClick={()=>{this.submitSection()}}
          >
            Add to Drop Downs
            </Button>
        <Select
            className={classes.textField}
            native
            value={this.props.Selections.DeleteOpticsKey}
            onChange={this.DeleteKey}
            input={
              <OutlinedInput labelWidth={0} name="Optics_List" id="Optics" />
            }
          >
            {this.state.Optics_List.map((Optic) => (
              <option 
                    key={Optic.ópticsId} 
                    value={Optic.ópticsId}
                    >
                    {Optic.optics}
              </option>
            ))}
          </Select>
          <Button variant="contained" 
          className={classes.button}
          onClick={()=>{this.DeleteSection()}}
          >
            Remove Optics 
            </Button>
            </ValidatorForm>
          </div>
      </div>
    )
  }
}

DropDownOptics.propTypes = {
    classes: PropTypes.object.isRequired
  };

const mapStateToProps = state => {
    return {
      memDetails: state.registrationReducer.signupDetails,
      Selections: state.DropDownReducer.Options,
      newlicenceDetails: state.licensesReducer.licence
    };
  };
  

  
  const mapDispatchToProps = dispatch => {
    return {
      onSectionChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_OPTIONS,
          varValue: value,
          varName: vname
        })
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DropDownOptics));