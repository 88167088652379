import React, { Component, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { QUESTION } from "../LMSAPIEndPoints";
import "./styles/styles.css";
import Check from "@material-ui/icons/Check";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { getInstruction, getTotalScore, isValid } from "./utils";
import QuestionOption from "./QuestionOption";
import { randomBytes } from "crypto";
import FileUpload from "./fileUploaderComponent";

const stateForUpdate = {
  options: [],
  question: "",
  questionType: -1,
  instructions: "instructions",
  totalScore: 0,
  onSaveMessage: <button className="btn btn-primary">Update</button>,
  inputText: "",
  render: "red"
};

const stateForCreate = {
  options: [],
  question: "Untitled Question",
  questionType: -1,
  instructions: "instructions",
  totalScore: 0,
  onSaveMessage: <button className="btn btn-primary">Save</button>,
  inputText: "",
  render: "red"
};
export class Question extends Component {
  constructor(props) {
    super(props);
    this.state = this.props.update
      ? { ...stateForUpdate, options: [] }
      : { ...stateForCreate, options: [] };
    this.onAddOption = false;
    this.index = 0;
    this.isOption = true;
    this.isQuestion = true;
    this.picture = null;
    this.picUrl = null;
  }

  addTotalScore = () => {
    return getTotalScore(this.state.options, this.state.questionType);
  };

  populateFiles = files => {
    this.picture = files;
    this.picUrl = null;
    this.setState({
      render: randomBytes(5)
    });
  };

  saveQuestion = () => {
    this.props.modalCloseButton(true);
    this.props.modalShow(
      this.props.update?"Updating...":"Saving..",
      "Performing an operation on the Question",
      false
    );
    const formData = new FormData();
    const data = {
      questionTitle: this.state.question,
      totalScore: this.addTotalScore(),
      type: this.state.questionType,
      courseMaterialId: this.props.update
        ? this.props.question.courseMaterialId
        : this.props.materialId,
      questionOptions: this.state.options.map(value => {
        return {
          description: value.description.props.children[0].props.children,
          score: value.score
        };
      })
    };

    Object.keys(data).forEach(key => {
      let value = data[key];
      if (key === "questionOptions") {
        value = JSON.stringify(value);
      }
      formData.append(key, value);
    });
    if (this.picUrl && this.props.update) {
      const pic = this.props.question.questionPictures;
      if (pic) {
        formData.append("picture", "rename");
        formData.append("url", pic.url);
      }
    } else {
      formData.append("picture", this.picture);
    }
    this.connect(formData);
  };

  connect = formData => {
    const url = this.props.update
      ? QUESTION + "/" + this.props.question.questionId
      : QUESTION;
    fetch(url, {
      method: this.props.update ? "POST" : "POST",
      body: formData
    })
      .then(res => {
        return {
          status: res.status,
          data: res.json()
        };
      })
      .then(res => {
        res.data
          .then(res => {
            this.props.modalCloseButton(false);
            this.props.modalShow(
              res.message,
              "Performing an operation on the Question",
              false
            );
          })
          .finally(() => {
            this.props.refresh();
          });
      })
      .catch(error => {
        this.onAddOption = false;
        this.setState({
          onSaveMessage: (
            <button className="btn btn-primary">
              {this.props.update ? "Update" : "Save"}
            </button>
          )
        });
        this.props.modalCloseButton(false);
        this.props.modalShow(
          error.message,
          "Performing an operation on the Question",
          false
        );
      });
  };

  showImage = () => {
    if (!this.picture && this.props.update && this.picUrl) {
      return (
        <div className="p-2 text-center">
          <img width="200px" height="200px" src={this.picUrl} alt="Sorry" />
        </div>
      );
    }
    return null;
  };

  componentDidMount() {
    if (this.props.update) {
      const questionPic = this.props.question.questionPictures;
      this.picUrl = questionPic ? questionPic.url : null;
      const question = this.props.question;
      this.onChangeInstruction(question.type);
      this.setState({
        question: question.questionTitle,
        totalScore: question.totalScore,
        questionType: parseInt(question.type),
        options: Array.from(question.questionOptions).map((option, index) => {
          this.index = this.index + 1;
          return {
            description: this.textOption(option.description, index),
            score: option.score,
            index: index
          };
        })
      });
    } else {
      this.onChangeInstruction(this.state.questionType);
    }
  }

  onChangeInstruction = typeOfQuestion => {
    this.setState({
      instructions: getInstruction(typeOfQuestion)
    });
  };

  radioFunction = (event, index) => {
    const options = this.state.options;
    if (event.target.checked) {
      options.some((opt, ind) => {
        if (ind === index) {
         return opt.score = 1;
        } else {
         return opt.score = 0;
        }
      });
    }
    this.setState({
      options: options
    });
  };

  checkFunction = (event, index) => {
    const options = this.state.options;
    if (event.target.checked) {
      options[index].score = 1;
    } else {
      options[index].score = 0;
    }
    this.setState({
      options: [...options]
    });
  };

  numbersSortOptionFunction = (event, index) => {
    const _options = this.state.options;
    function updateScore(num) {
      let key = 0;
      for (let i = 0; i < _options.length; i++) {
        let _option = _options[i];
        if (num <= 0) {
          return 0;
        }
        if (_option.score === num) {
          return updateScore(num - 1);
        }
        key = key + 1;
      }
      return num;
    }
    _options[index].score = updateScore(parseInt(event.target.value));
    this.setState({
      options: _options
    });
  };

  showOption = (description, score, index) => {
    return (
      <QuestionOption
        questionType={this.state.questionType}
        description={description}
        score={score}
        index={index}
        options={this.state.options}
        numberOfOptions={this.state.options.length}
        radioFunction={this.radioFunction}
        checkFunction={this.checkFunction}
        numbersSortOptionFunction={this.numbersSortOptionFunction}
      />
    );
  };

  onValidation = () => {
    this.setState({
      render: randomBytes(5)
    });
    if (!this.state.question || this.state.question === "Untitled Question") {
      this.isQuestion = false;
      return false;
    }
    this.isQuestion = true;
    return (this.isOption = isValid(
      this.state.questionType,
      this.state.options
    ));
  };

  validationMessage = () => {
    let message = "";
    if (!this.isQuestion) {
      message += "The Question is untitled";
    }

    if (!this.isOption) {
      if (this.state.options.length === 0) {
        message += "\n There are no options";
      } else {
        message += "\n Please select an option as an answer";
      }
    }

    return (
      <div className="text-danger font-weight-bold text-center w-100">
        {" "}
        {message}{" "}
      </div>
    );
  };

  onCheck = index => {
    const options = this.state.options;
    const realIndex = options.findIndex(value => value.index === index);
    let option = options[realIndex];
    option.description = this.textOption(this.state.inputText, index);
    options[realIndex] = { ...option };
    this.setState({
      options: options
    });
    this.onAddOption = false;
  };

  onEdit = index => {
    this.onAddOption = true;
    const options = this.state.options;
    const realIndex = options.findIndex(value => value.index === index);
    let option = options[realIndex];
    const span = option.description.props.children[0];
    option.description = this.inputOption(span.props.children, index);
    options[realIndex] = { ...option };
    this.setState({
      options: options,
      inputText: span.props.children
    });
  };

  onDelete = index => {
    let options = this.state.options;
    const realIndex = options.findIndex(value => value.index === index);
    options.splice(realIndex, 1);
    this.setState({
      options: [...options]
    });
  };

  inputOption = (defaultText, index) => {
    return (
      <Fragment>
        <input
          className="w-75"
          onChange={event => {
            this.setState({
              inputText: event.target.value
            });
          }}
          type="text"
          defaultValue={defaultText}
        />
        <Check
          onClick={() => {
            this.onCheck(index);
          }}
        />
        <Delete
          onClick={() => {
            this.onDelete(index);
          }}
        />
      </Fragment>
    );
  };

  textOption = (text, index) => {
    return (
      <Fragment>
        <span className="w-75">{text}</span>
        <Edit
          onClick={() => {
            this.onEdit(index);
          }}
        />
        <Delete
          onClick={() => {
            this.onDelete(index);
          }}
        />
      </Fragment>
    );
  };

  addOption = () => {
    let options = this.state.options;
    options.push({
      description: this.inputOption("", this.index),
      score: 0,
      index: this.index
    });
    this.index = this.index + 1;
    this.setState({
      options: options
    });
  };

  input = () => {
    if (this.props.update) {
      return (
        <input
          className="d-block mb-2 w-100"
          label="Question"
          defaultValue={this.state.question}
          onChange={e => {
            this.setState({
              question: e.target.value
            });
          }}
        />
      );
    } else {
      return (
        <input
          className="d-block mb-2 w-100"
          label="Question"
          placeholder={this.state.question}
          onChange={e => {
            this.setState({
              question: e.target.value
            });
          }}
        />
      );
    }
  };

  selectedOption = (value, index) => {
    if (this.state.questionType === index) {
      return (
        <option key={index} selected>
          {value}
        </option>
      );
    } else {
      return <option key={index}>{value}</option>;
    }
  };

  render() {
    const questionTypes = [
      "Single Choice",
      "Multiple Choice",
      "Match Sorting (Numbers)",
      "Match Sorting (Letters)"
    ];
    return (
      <div className="question-body">
        <Card>
          <div
            onClick={() => {
              this.props.close();
            }}
            className="font-weight-bold p-0 pl-2"
          >
            X
          </div>
          <form
            onSubmit={e => {
              e.preventDefault();
              this.onAddOption = true;
              if (this.onValidation()) {
                this.saveQuestion();
              } else {
                this.onAddOption = false;
              }
            }}
          >
            <CardContent>
              {this.input()}
              <FileUpload fileParse={this.populateFiles} />
              {this.showImage()}
              <select
                className="d-block h-2 w-100 mb-2"
                name="questionType"
                onChange={e => {
                  const key = e.target.options.selectedIndex - 1;
                  this.setState({
                    questionType: key
                  });
                  this.onChangeInstruction(key);
                }}
              >
                <option key={-1}>Choose question type</option>
                {questionTypes.map((value, index) =>
                  this.selectedOption(value, index)
                )}
              </select>
              <div className="d-block font-italic text-center mb-3">
                {this.state.instructions}
              </div>
              <div className="d-block w-100 mb-2">
                {this.state.options.map((value, index) =>
                  this.showOption(value.description, value.score, index)
                )}
              </div>
            </CardContent>
            {this.validationMessage()}
            <div className="create-question-buttons">
              {this.onAddOption ? null : (
                <Fragment>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.onAddOption = true;
                      this.addOption();
                    }}
                  >
                    Add option
                  </button>
                  {this.state.onSaveMessage}
                </Fragment>
              )}
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

export default Question;
