import {
  UPDATE_DEDICATED_STATUS_LIST,
  UPDATE_DEDICATED_STATUS_LIST_ERROR
} from './types';

const fetchDedicatedStatusList = service => data => dispatch => service.getAllDeducatedStatuses(data)
  .then(response => dispatch({ type: UPDATE_DEDICATED_STATUS_LIST, payload: response.data.dedicated }))
  .catch(() => dispatch({ type: UPDATE_DEDICATED_STATUS_LIST_ERROR, payload: 'Dedicated Status Not Found' }));

export default function(dedicatedStatusService) {
  return {
    fetchDedicatedStatusList: fetchDedicatedStatusList(dedicatedStatusService)
  };
}