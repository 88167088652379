export const BASE = process.env.NODE_ENV == 'development'?'https://apiqa.narfo.co.za/' : "https://apiqa.narfo.co.za/";
export const FRONT = process.env.NODE_ENV == 'development'? 'http://localhost:3000/': "http://qaportal.narfo.co.za/";
export const DELETE_LICENCE = "api/LicensedFirearms/delete/"
export const PAYFAST_NOTIFY = "http://payfasttestnarfo.quickscorepro.co.za/payfastreturn.php";
export const GITHUB_TEST_URL = "https://api.github.com/users/";
export const ACTIVITY_APPROVAL = "api/Activities/Approval/";
export const ACTIVITY_APPROVAL_WITH_BODY = "api/Activities/ApprovalWithBody/";
export const ACTIVITY_EMAIL = "api/Activities/sendEmail";
export const GET_ALL_MEMBERS = "api/Member/all";
export const GET_MEMBER = "api/Member/";
export const GET_LOGGED_IN_MEMBER = "api/Member/getLoggedInMember/";
export const GET_MEMBER_BY_EMAIL = "api/Member/getMemByEmail/";
export const GET_ALL_MEMBERS_DEDICATED = "api/Member/all/MemberView";
export const GET_USER_BY_FILFTER = "api/Member/all/filter";
export const GET_USER_BY_STATUS = "api/Member/all/filterbystatus";
export const GET_DOWNLOADS_URL = "api/downloads/all";
export const GET_BRANCHES_URL = "api/Branches/all";
export const GET_SPEC_LIST = "api/LicensedFirearms/getlicense/";
export const POST_NEW_ACTIVITY_URL = "api/Activities/post";
export const LOGIN_URL = "api/Member/post/login";
export const RESET_URL = "api/Member/post/reset";
export const NEW_PASSWORD_URL = "api/Member/post/new";
export const GET_SALES_URL = "api/salesReps/get/all/sales";
export const GET_ALL_ENDORSEMENTS_URL = "api/EndorsementApplications/all/";
export const POST_ALL_ENDORSEMENTS_URL = "api/EndorsementApplications/";
export const GET_SPECIFIC_USER_ENDORSEMENTS_URL =
  "api/EndorsementApplications/getEndorsment/";
export const GET_ADDLICENCE_URL = "api/LicensedFirearms/post";
export const GET_UPDATELICENCE_URL = "api/LicensedFirearms/update/";
export const GET_LIST_URL = "api/LicensedFirearms/list";
export const CONTACTUS_URL = "api/Member/post/email";
export const GET_ALL_ACTIVITIES_URL = "api/Activities/"; 
export const GET_ALL_MEMBER_ACTIVITIES = "api/Activities/GetMemberActivities";
export const GET_SPECIFIC_ACTIVITY = "api/Activities/memNo/";
export const GET_ALL_SALES = "api/salesReps/get/all/sales";
export const POST_MEMBER = "api/Member/post/set";
export const GET_LAST_MEMBERNUM = "api/Member/get/Last/MemNum";
export const GET_MEMBER_STATS = "api/Statistics";
export const POST_DEDICATED_STATUS_VIEW =
  "api/DedicatedStatus/post/Dedicatedfind";
export const POST_ENDORSEMENTS_URL =
  "api/EndorsementApplications/PostEndorsementApplication/";
export const ASSESMENTS_URL = "api/assesments/";
export const COURSES_BY_ASSESMENT_URL = "api/courses/assesment/";
export const MEMBERCOURSE_URL = "api/membercourses/";
export const COURSES_URL = "api/courses/";
export const QUIZZES_URL = "api/quiz/";
export const QUIZZES_BY_COURSE_URL = "api/quiz/course/";
export const MEMBERCOURSE_BY_MEMBER_URL = "api/membercourses/member/";
export const GET_ENDORSEMENTS_URL = "api/EndorsementApplications/all/";
export const GET_COMPLETION_STATUS_URL = "api/MemberAssesments/results/";
export const VIEW_SELECTED_ENDORSEMENTS_URL = "api/EndorsementApplications/";

export const GET_NOTIFICATION_URL = "api/Notifications/GetAllNotifications";
export const POST_NOTIFICATION_URL = "api/Notifications";
export const DELETE_NOTIFICATION_URL = "api/Notifications/DeleteNotification";

export const GET_BULK_EMAILS_URL = "api/AutomatedEmail/GetBulkEmails";
export const POST_BULK_EMAIL_URL = "api/AutomatedEmail/SendBulkEmail";
export const SAVE_BULK_EMAIL_URL = "api/AutomatedEmail/SaveBulkEmail";
export const UPDATE_BULK_EMAIL_URL = "api/AutomatedEmail/UpdateBulkEmail";
export const DELETE_BULK_EMAIL_URL = "api/AutomatedEmail/DeleteBulkEmail";

export const ARTICLE_URL = "api/NewsArticles";
export const ARTICLE_LATEST = "api/NewsArticles/GetAllNewsByTheLatest";
export const ARTICLE_LatestFiveNews_URL = "api/NewsArticles/GetFiveLatest";

export const NEWS_BY_ARTICLE_URL = "api/NewsArticles/";
export const FIREARMS_GUARDIAN_NEW_APPLICATION = "api/Initiatives/SendFirearmsGuardianApplication";

export const GET_MEMBER_CITY = "api/Member/city/";
export const UPLOAD_NEW_FILE = "api/Downloads/newFile";
export const GET_ACTIVITIESFILES_UPLOAD = "api/ActivitesUploads/UploadFiles";
export const GET_ACTIVITIESFILES = "api/ActivitesUploads/";
export const GET_ACCOUNT_LIST = "api/account/member/";
export const CHANGE_MEMBER_EXPIRY_DATE_URL = "api/member/changeExpiryDate/";
export const ACCOUNT_URL = "api/account/";
export const GET_DOWNLOADS_DELETE = "api/downloads/";
export const GET_UPLOAD_NEW_DOWNLOADS = "api/downloads";
export const GET_ALL_USER_ACTIVITIES_URL = "api/Activities/memNo/";
export const GET_NATIONALRANKING_URL = "api/NationalRankings/";
export const PENDING_ENDORSEMENT_STATUS_URL =
  "api/EndorsementApplications/PostPending/";
export const REJECT_ENDORSEMENT_STATUS_URL =
  "api/EndorsementApplications/PostRejected/";
export const APPROVE_ENDORSEMENT_STATUS_URL =
  "api/EndorsementApplications/PostApproval/";
export const UPDATE_ACTIVITY_PENDING_STATUS = "api/Activities/PostPending/";
export const UPDATE_ACTIVITY_REJECT_STATUS = "api/Activities/PostRejected/";
export const UPDATE_ACTIVITY_APPROVE_STATUS = "api/Activities/PostApproval/";
export const UPDATE_PENDING_STATUS = "api/EndorsementApplications/PostPending/";
export const UPDATE_REJECT_STATUS = "api/EndorsementApplications/PostRejected/";
export const UPDATE_APPROVE_STATUS =
  "api/EndorsementApplications/PostApproval/";
export const COUNT_PENDING_ENDORSEMENT_APPLICATION_URL =
  "api/EndorsementApplications/countEAList";
export const COUNT_PENDING_ENDORSEMENT_APPLICATION_MEMBERS_URL =
  "api/Member/all/PEndorsement";
export const COUNT_PENDING_DEDICATED_SPORT_SHOOTER_MEMBERS_URL =
  "api/Member/all/PDSSMembers";
export const COUNT_PENDING_ACTIVITY_MEMBERS_URL = "api/Member/all/PActivity";
export const COUNT_PENDING_ACTIVITY_URL = "api/Activities/countA";
export const COUNT_PENDING_DEDICATED_STATUS_URL = "api/DedicatedStatus/countDS";
export const COUNT_APPROVED_DEDICATED_HUNTER_URL =
  "api/DedicatedStatus/countDHMembers";
export const COUNT_APPROVED_DEDICATED_SPORT_SHOOTER_URL =
  "api/DedicatedStatus/countDSSMembers";
export const FILTER_BY_YEAR_URL = "api/NationalRankings/FilterByYear/";
export const FILTER_BY_ASSOCIATION_URL =
  "api/NationalRankings/FilterByAssociation/";
export const FILTER_BY_ACTIVITYTYPE_URL =
  "api/NationalRankings/FilterByActivityType/";
export const FILTER_BY_FIREARM_URL =
  "api/NationalRankings/FilterByFirearmType/";
export const FILTER_NATIONAL_RANKING_URL =
  "api/NationalRankings/FilterRankings/";
export const COUNT_DSS_MEMNBERS = "api/DedicatedStatus/countDSSMembers";
export const COUNT_TOTAL_MEMBERS_URL = "api/Member/all/MemberNumber";
export const GET_ENDORSEMENT_CERTIFICATE_URL =
  "api/EndorsementApplications/GetCertificateDetails/";
export const UPLOAD_PROFILE_PICTURE =
  "api/MemberProfilePictures/addProfilePicture";
export const EDIT_PROFILE_PICTURE =
  "api/MemberProfilePictures/editProfilePicture/";
export const GET_MEMBER_PP_ID_URL = "api/MemberProfilePictures/getMemId/";
export const GOSA_HEADER = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1OGUzMTY1OS0zNjk2LTRhMDUtOWRjMS0wYzhjMTdiNjIzOWYiLCJlbWFpbCI6ImluZm9AbmFyZm8uY28uemEiLCJqdGkiOiIzZWRhZjcwYS00YzIzLTRmMDktODFiNy1iYTZmYmM3NzQzZjciLCJnaXZlbl9uYW1lIjoiR09TQSIsImV4cCI6MjAxNTkzNTAyNSwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MjAyNi8iLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUyMDI2LyJ9.K_ubEsfdERrAiwYyHXsHJOa48-YmqOLTV766hRuJTR8";
export const PAYFAST_URL =
  "https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=12911829&item_name=NARFO&item_description=NARFO+Renewal+Of+Membership&amount=490.00&return_url=http%3A%2F%2Fgoogle.com&cancel_url=http%3A%2F%2Fwikipedia.org";
export const UPLOAD_DEDICATED_STATUS_FILES = "api/dedicatedstatus/uploadfiles/";
export const POST_STATUS_BY_EMAIL_URL =
  "api/EndorsementApplications/post/SendEmail/";
export const GET_DEDICATEDTYPE = "api/DedicatedStatus/DedicatedType/";
export const GET_BRANCHES = "api/Branches/all";
export const GET_SECTION_LIST = "api/SectionLookups";
export const GET_ACTIVITY_TYPE_LIST = "api/ActivityLookups";
export const GET_FIREARMTYPE_LIST = "api/FireArmTypeLookups";
export const GET_OPTICS_LIST = "api/OpticsLookups/get/";
export const GET_ADMINS_LIST = "api/admin/all";
export const GET_SALESREP_LIST = "api/admin/sales";
export const GET_STORE_LIST = "api/Stores";
export const GET_ACTIVITYEVENT_LIST = "api/ActivityEventLookups";
export const GET_ACTIVITYDISC_LIST = "api/ActivityDisciplines";
export const GET_ALL_ADMIN_LIST = "api/admin/all";
export const POST_SECTION = "api/SectionLookups";
export const POST_ACTIVITYTYPE = "api/ActivityLookups";
export const POST_FIREARMTYPE = "api/FireArmTypeLookups";
export const POST_OPTICS = "api/OpticsLookups/post";
export const POST_ACTIVITYEVENT = "api/ActivityEventLookups";
export const POST_STORE = "api/Stores";
export const POST_ADMIN = "api/admin/post/set";
export const POST_SALES = "api/admin/post/set/sales";
export const POST_ACTDISCIPLINE = "api/ActivityDisciplines";
export const DELETE_SECTION = "api/SectionLookups/delete/";
export const DELETE_ACTIVITYTYPE = "api/ActivityLookups/";
export const DELETE_FIREARMTYPE = "api/FireArmTypeLookups/";
export const DELETE_OPTICS = "api/OpticsLookups/delete/";
export const DELETE_ADMINS = "api/admin/";
export const DELETE_ACTIVITYEVENT = "api/ActivityEventLookups/";
export const DELETE_STORE = "api/Stores/delete/";
export const DELETE_ACTDISCIPLINE = "api/ActivityDisciplines/";
export const ASSOCIATION_LIST = "api/AssociationLookups";
export const ACTIVITY_EVENT_LIST = "api/ActivityEventLookups";
export const ACT_UPLOAD = "api/Activities";
export const ChangePassword_URL = "api/Member/reset/";
export const GET_ALL_PENDING_MEMBERS_URL = "api/Member/all";
export const GET_ALL_RENEWAL_MEMEBRS_URL = "api/Member/all";
export const APPROVE_NEW_MEMBER_RENEWAL_URL = "api/Member/ApproveNewMember/";
export const APPROVE_ENDORSEMENT_APPLICATION =
  "api/EndorsementApplications/Approval/";
export const APPROVE_DS_APPLICATION = "api/DedicatedStatus/approval/";
export const GET_PENDING_DS = "api/DedicatedStatus/pending/";
export const NEW_DS_APPLICATION = "api/DedicatedStatus/new/";
export const SEND_EMAIL_NEW_DS_HUNTER_URL =
  "api/AutomatedEmail/post/SendNewDSHunterEmail";
export const SEND_EMAIL_NEW_DSSPORT_SHOOTER_URL =
  "api/AutomatedEmail/post/SendNewDSSSEmail";
export const SEND_EMAIL_RENEWAL_URL =
  "api/AutomatedEmail/post/SendRenewalEmail";
export const SEND_EMAIL_WELCOME_URL =
  "api/AutomatedEmail/post/SendWelcomeEmail";
export const SEND_EMAIL_ENDORSEMENT_APPROVAL_URL =
  "api/AutomatedEmail/post/SendEndorsementEmail";
export const SEND_EMAIL_LICENCE_REMINDER_URL =
  "api/AutomatedEmail/post/SendLicenseReminderEmail";
export const SEND_EMAIL_RESET_PASSWORD_URL = "api/AutomatedEmail/post/new";
export const SEND_EMAIL_REGISTRATION =
  "api/AutomatedEmail/post/SendRegisterEmail";
export const SEND_DEDICATED_REJ = "api/AutomatedEmail/post/SendRejectDSEmail";

export const GET_RANKING = "api/member/rank/";
