import * as actionTypes from "../actions/actionTypes";

const initialState = {
    commCategory: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMMISSION_CATEGORY:
        return {
            ...state,
            commCategory: action.commCategory
        };
    default:
      return state;
  }
};
export default reducer;
