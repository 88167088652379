import * as actionTypes from "../actions/actionTypes";

const initialState = {
  contactUs: {
    name: "",
    email: "",
    subject: "",
    message: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTACT_US_DETAILS:
      const ContactUsDetails = {
        ...state.contactUs,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        contactUs: ContactUsDetails
      };
    default:
      return state;
  }
};

export default reducer;
