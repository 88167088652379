import React from 'react';
import {
  Grid,
  Typography,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingLeft: '0',
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bold'
  },
  subHeading: {
    paddingLeft: '0',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 'bold'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  }
}));

function SignupHeader() {
  const classes = useStyles();
  
  const headerContent = (
    <React.Fragment>
      <Grid item>
        <Typography variant="h4" className={classes.heading}>
          Join the National Association of Responsible Firearm Owners
        </Typography>
      </Grid>
      <Grid item>
        <p className={classes.paragraph}>
          Welcome to the NARFO Members Application Page. Please complete all the details in the next pages. 
          If you joined through a partner or representative, please click &quot;YES&quot; and select either a partner or 
          representative or both. If no one assisted you, please keep both selects as &quot;NO&quot;.
        </p>
      </Grid>

      <Grid item>
        <Typography variant="h6" className={classes.subHeading}>
          Payment
        </Typography>
      </Grid>

      <Grid item>
        <p className={classes.paragraph}>
          Once all of the fields have been completed, you will be directed to the PayFast payment gateway.
          This is a secure South Africa payment gateway and you can either pay by credit, debit or EFT.
          Our membership fees are fixed at R490.00 which subscribes you to our service for 365&nbsp;days.
        </p>
      </Grid>

      <Grid item>
        <Typography variant="h6" className={classes.subHeading}>
          Profile activation
        </Typography>
      </Grid>

      <Grid item>
        <p className={classes.paragraph}>
          Once your application has been submitted and payment is received, your profile will be 
          automatically activated and you can access your profile. Please check your profile once 
          logged in and ensure that all details are correct. 

          <br/><br/>If you have a dedicated status with another association, we can transfer your status to this platform. <br/>
          Your journey is about to begin.<br/>

        </p>
      </Grid>
    </React.Fragment>
  );
  

 
  return (
    <React.Fragment>
      <Grid container direction="column">
        {headerContent}
      </Grid>
      
    </React.Fragment>
  );
}

export default SignupHeader;