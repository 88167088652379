import * as Types from "./ApproveNewDSActionTypes";

const approveNewDSState = {
  approvalStatus: {
    loading: null,
    response: null,
    error: null
  },
  emailStatus: {
    loading: null,
    response: null,
    error: null
  },
  reason: "",
  application: null,
  user: null
};

const reducer = (state = approveNewDSState, action) => {
  switch (action.type) {
    case Types.APPROVE_DS_BEGIN:
    case Types.APPROVE_DS_ERROR:
    case Types.APPROVE_DS_SUCCESS:
    case Types.EMAIL_BEGIN:
    case Types.EMAIL_ERROR:
    case Types.EMAIL_SUCCESS:
    case Types.SET_CURRENT_DS_APPLICATION:
      return {
        ...state,
        ...action.approveNewDSState
      };
    default:
      return state;
  }
};

export default reducer;
