import axios from "axios";
import React, { Component } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { BASE } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import Progress from "./memberApplicationProgress";
import { PreviousPageButton, SubmitButton } from "./navigatorButtons";
import "./styles/memberDetailsStyles.css";
import { FormatAlignJustifyTwoTone } from "@material-ui/icons";

class LoginDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextPage: false,
      children: ["", "", "", ""],
      Email: "",
      Username: "",
      Password: "",
      PasswordConfirm: "",
      isChecked: false,
      showUnfilledError: false,
      redirect: false,
      userLocation: "",
      errorStatus: false,
    };
  }

  handleChange2 = ({ target }) => {
    this.setState({ [target.name]: target.value });
  }

  checkNotEmpty = () => {
    let isNextPage = true;
    this.state.children.map(child => {
      if (child === "" || child === null || child === undefined) {
        isNextPage = false;
      }
      return null;
    });
    this.setState({
      nextPage: isNextPage
    });
  };

  componentWillUnmount() {
    this.props.onMemberDetailsChange(this.state.children, "loginChildren");
  }

  componentDidMount() {
    this.getAddress()
    this.checkNotEmpty();
  }

  getAddress = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        axios({
          url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyC_WhGsGoYjzZiuOCyy4R4nYGNziKWrD68`,
          method: "get"
        }).then(res => {
          localStorage.setItem("memLocation", res.data.plus_code.compound_code)
        })
          .catch(localStorage.setItem("memLocation", "No"))
      });
    } else {
      localStorage.setItem("memLocation", "No")
    }
  }

  checkUnfilled = registrationDetails => {
    for (var obj in registrationDetails) {
      if (registrationDetails[obj] === "") {
        
        return false;
      }
    }
    return true;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.checkNotEmpty();
    var formData = new FormData()
    const memDetails = {
      cellNo: this.props.memDetails.CellNo,
      city: this.props.memDetails.City,
      email: this.state.Email.toString().toLowerCase(),
      ethinicity: this.props.memDetails.Ethinicity,
      firstname: this.props.memDetails.Firstname,
      idNo: this.props.memDetails.IDNo,
      occupation: this.props.memDetails.Occupation,
      password: this.state.Password,
      physicalAddress: this.props.memDetails.PhysicalAddress,
      postalCode: this.props.memDetails.PostalCode,
      province: this.props.memDetails.Province,
      salesRep: this.props.memDetails.SalesRep,
      sex: this.props.memDetails.Sex,
      store: this.props.memDetails.Store,
      suburb: this.props.memDetails.Suburb,
      surname: this.props.memDetails.Surname,
      username: this.state.Username,

    }

    if (this.checkUnfilled(memDetails)) {
      this.props.onSubmitError("Registering...");
      formData.append("cellNo", this.props.memDetails.CellNo);
      formData.append("city", this.props.memDetails.City);
      formData.append("email", this.state.Email.toString().toLowerCase());
      formData.append("ethinicity", this.props.memDetails.Ethinicity);

      formData.append("firstname", this.props.memDetails.Firstname);
      formData.append("idNo", this.props.memDetails.IDNo);
      formData.append("occupation", this.props.memDetails.Occupation);
      formData.append("password", this.state.Password);

      formData.append("physicalAddress", this.props.memDetails.PhysicalAddress);
      formData.append("postalCode", this.props.memDetails.PostalCode);
      formData.append("province", this.props.memDetails.Province);
      formData.append("salesRep", this.props.memDetails.SalesRep);

      formData.append("sex", this.props.memDetails.Sex);
      formData.append("store", this.props.memDetails.Store);
      formData.append("suburb", this.props.memDetails.Suburb);
      formData.append("surname", this.props.memDetails.Surname);

      formData.append("username", this.state.Username);
      formData.append("Criminal", this.props.memDetails.CriminalRecord === 1 ? true : false);
      formData.append("DeclarationAcceptance", this.props.memDetails.declaractionAcceptance);
      formData.append("CorrectInfo", this.props.memDetails.DocTrue);

      formData.append("ElectronicComms", this.props.memDetails.ElectronicComms);
      formData.append("FireArmsStorage", this.props.memDetails.FireArmStorage === 1 ? false : true);
      formData.append("FirearmControl", this.props.memDetails.FireArms_Control === 1 ? false : true);
      formData.append("CodeOfConduct", this.props.memDetails.CoC);
      formData.append("DisciplinaryConduct", this.props.memDetails.DC);
      formData.append("Location", localStorage.getItem("memLocation"));

      await axios({
        url: BASE + "api/Member/post/set",
        method: "post",
        data: formData
      })
        .then(res => {
          if (parseInt(res.status) !== 400) {
            debugger
            this.props.onPostedMemberDetails(res.data);
            sessionStorage.setItem("X123P", this.state.Password);
            sessionStorage.setItem("XUVY-113", res.data.message.memNo); //member number
            sessionStorage.setItem("XUVY-114", res.data.message.email); //member email
            sessionStorage.setItem("FROM", "REG"); // register page
            this.props.history.push("/done");
          } else {
            this.setState({ errorStatus: true })
            this.props.onSubmitError("email or username already exist");
          }
        })
        .catch(() => {
          this.setState({ errorStatus: true })
          this.props.onSubmitError("email or username already exist");
        });
    } else {
      this.setState({
        showUnfilledError: true
      });
    }
  };

  componentWillMount() {
    this.setState({
      children: this.props.memDetails.loginChildren
        ? this.props.memDetails.loginChildren
        : this.state.children,
      isChecked: this.props.memDetails.declaractionAcceptance
    });
    this.setState({
      showUnfilledError: false
    });
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isPasswordMatch", value => {
      if (!value) {
        return false;
      }
      if (value !== this.props.memDetails.Password) {
        return false;
      }
      return true;
    });
    // custom rule will have name at least 4 characters required
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (!value) {
        return false;
      }
      if (value.length < 4) {
        return false;
      }
      return true;
    });
    // custom rule password length at least 8 characters
    ValidatorForm.addValidationRule("password", value => {
      if (!value) {
        return false;
      }
      if (value.length < 8) {
        return false;
      }
      return true;
    });
  }

  render() {
    return (
      <div className="member-details-root" style={{ marginTop: "80px" }}>
        <Progress progress={5} />
        <div className="member-details-form ">

          <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
            <p>
              In order for your registration to be sucessfull and use the online platform, we require a unique
              email address and username. Also provide a password that you will use to log in once your profile is activated.
              NEVER give your login details and or password to anyone. This will comppromise the sucurity of your personal information.

              After completing this step, you will be redirected to the secure online payment platform.
              This is a third party service provider. They will store your email address and username to identify the transaction.
              If the payment is sucessfull, your profile will be activated.
              If you do not complete the online transaction, youor profile will only be activated once payment has been verified.
            </p>
            <div className="member-details-text-input-parent">
              <label> E-mail Address </label>
              <div className="member-details-text-input">
                <TextValidator
                  id="OutlinedEmail"
                  type="email"
                  value={this.state.Email}
                  onChange={this.handleChange2}
                  name="Email"
                  validators={["required", "isEmail"]}

                  errormessages={[
                    "This field is required",
                    "Invalid email address"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Username </label>
              <div className="member-details-text-input">
                <TextValidator
                  id="OutlinedUsername"
                  value={this.state.Username}
                  onChange={this.handleChange2}
                  name="Username"
                  validators={["isEmpty", "required"]}
                  errormessages={[
                    "username too short",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Password </label>
              <div className="member-details-text-input">
                <TextValidator
                  id="OutlinedPassword"
                  type="Password"
                  value={this.state.Password}
                  onChange={this.handleChange2}
                  name="Password"
                  validators={["password", "required"]}
                  errormessages={[
                    "password minimun 8 character",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Password Confirmation </label>
              <div className="member-details-text-input">
                <input
                  id="OutlinedPasswordConfirm"
                  type="Password"
                  value={this.state.PasswordConfirm}
                  onChange={this.handleChange2}
                  name="PasswordConfirm"
                  validators={["isPasswordMatch", "required"]}
                  errormessages={[
                    "password mismatch",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <br />
            {this.state.showUnfilledError ? (
              <p class="text-danger text-center font-weight-bold">
                Go back and fill empty fields
              </p>
            ) : null}
            {
              <p class="text-danger text-center font-weight-bold">
                {this.state.errorStatus === true ? this.props.errorMessage : null}

              </p>
            }
            <br />
            <div className="nav-button-parent">
              <Link to="/SalesDetails">
                <PreviousPageButton />
              </Link>
              <SubmitButton />
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails,
    errorMessage: state.registrationReducer.onSumbitError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      }),
    onAssCompletionChange: assesmentCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_ASSESMENT_COMPLETION,
        assesmentCompletion: assesmentCompletion
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),

    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_DETAILS_2 || actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      }),
    onCertificatesUpdate: certificates =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_CERTIFICATES,
        certificates: certificates
      }),

    onPageChange: newPage =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        payload: newPage
      }),
    onPostedMemberDetails: value =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS_FROM_DB,
        payload: value
      }),
    onSubmitError: value =>
      dispatch({
        type: actionTypes.UPDATE_REG_SUBMIT_ERROR_MESSAGE,
        value: value
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginDetails));
