import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_QUIZZES,
  UPDATE_SCORE,
  UPDATE_RESULTS_RECORD,
  UPDATE_QUIZ_COMPLETION,
  UPDATE_CURRENT_LMS_TAB
} from '../../store/LearningManagement/actionTypes';
import { calculateScore, showOption } from '../../shared/utility';
import { HighlightOff, PlayCircleOutline } from '@material-ui/icons';
import {
  IconLabelButton
} from '../index';
import { Grid, Typography } from '@material-ui/core';
import ProgressBar from '@ramonak/react-progress-bar';

export class TakeQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      takeCourse: true,
      time: {},
      seconds: 599,
      next: 'Next',
      previous: 'Previous',
      isQuizExist: false,
      start: false,
      numberOfQuestions: 0,
      currentQuestion: 0,
      questions: [],
      emailParam: {
        toEmail: this.props.altMemberDetails.email,
        Subject: '',
        Body: null,
        Firstname: this.props.altMemberDetails.firstname,
        Surname:this.props.altMemberDetails.surname
      }
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.score = {
      scoreIndex: -1,
      scoreArrayIndex: [],
      scoreArrayValues: []
    };
    this.scoring = [];
    this.submit = false;
  }

  componentDidMount() {
    const questions = this.props.questions;
    if (questions.length > 0) {
      this.setState({
        isQuizExist: true,
        numberOfQuestions: questions.length,
        questions: questions,
        next: questions.length <= 1 ? 'Submit' : 'Next'
      });
      if (questions.length <= 1) {
        this.submit = true;
      }
    } else {
      this.setState({
        isQuizExist: false,
        questions: []
      });
    }
  }

  UNSAFE_componentWillMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      time: timeLeftVar
    });
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  titleTimer = (isMobile) => (
    <React.Fragment>
      <HighlightOff
        style={{
          width: '45px',
          height: '30px',
          color: 'red',
          cursor: 'pointer'
        }}
        onMouseDown={() => this.props.onCurrentLMSTabChange(0)} 
      />
	  <Grid container direction='row' justifyContent={isMobile ? 'space-evenly' : null}>
		<Typography className={isMobile ? null : 'pl-3'}>{`${this.props.oneLesson.title.split(', Quiz')}`}</Typography>
		<Typography className={isMobile ? null : 'pl-2'}>{`0${this.state.time.m} : ${this.state.time.s}`}</Typography>
	  </Grid>
    </React.Fragment>
  );

  isBeginQuiz = () => (
    <div className="row" align="center">
      <div className="col-12 pt-5">
        {this.state.isQuizExist ? (
          <div className="d-flex justify-center">
            <IconLabelButton
              full
              actionText="Start Quiz"
              icon={<PlayCircleOutline />}
              callBack={() => {
                this.setState({ start: true });
                this.startTimer();
              }}
            />
          </div>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12" align="center">
                <h5>There is currently no questions for this quiz.</h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  progressBar = () => (
    <div className='pb-3'>
      <ProgressBar 
        completed={((this.state.currentQuestion + 1) /
          this.state.numberOfQuestions) *
          100} 
        bgColor='#4F36A6'
        baseBgColor='#E3DFF0' 
        isLabelVisible={false}
      />
      <Grid container justify="flex-end">
        <Typography>
          {`Question ${this.state.currentQuestion + 1} / ${this.state.numberOfQuestions}`}
        </Typography>
      </Grid>
    </div>
  )

  postResults = () => {
    const results = {
      completion: this.props.score,
      courseMaterialId: this.props.oneLesson.pkCourseId,
      memNo: this.props.memNo
    };
    this.props.postMemberAssessmentCallBack(JSON.stringify(results))
      .then(() => {
        this.props.getMemberAssessmentsByMemberNo(this.props.memNo)
          .then(res => {
            this.props.onQuizCompletionChange(res.data.data);
          });
      });
  };
            
  navigateButtons = () => (
    <div className="row position-static">
      <Grid container justify="space-between">
        <React.Fragment>
          {this.state.currentQuestion > 0 ? (
            <IconLabelButton
              quizProgress
              full
              actionText={this.state.previous}
              callBack={() => {
                const current = this.state.currentQuestion - 1;
                this.setState({
                  currentQuestion: current,
                  next:
                current + 1 >= this.state.numberOfQuestions
                  ? 'Submit'
                  : 'Next'
                });
              }}
            />
          ) : null}
        </React.Fragment>
        <React.Fragment>
          <IconLabelButton
            quizProgress
            full
            actionText={this.state.next}
            callBack={() => {
              const question = this.state.questions[this.state.currentQuestion];
              const options = question.questionOptions;
              this.scoring[
                this.state.currentQuestion
              ] = this.calculatePercentage(
                question.type,
                question.totalScore,
                options
              );
              this.score = {
                scoreIndex: -1,
                scoreArrayIndex: [],
                scoreArrayValues: []
              };
              if (this.submit) {
                this.props
                  .onScoreChange(
                    parseInt(
                      (this.scoring.reduce((a, b) => a + b, 0) * 100) /
                        this.state.numberOfQuestions
                    )
                  )
                  .then(() => {
                    this.postResults();
					this.props.onCurrentLMSTabChange(3)
                  });
                this.props.onResultsRecordChange(
                  this.scoring.map((percentage, index) => ({
                    question: index + 1,
                    score: percentage * 100
                  }))
                );
              }
              const current =
              (this.state.currentQuestion + 1) % this.state.numberOfQuestions;
              this.setState({
                currentQuestion: current,
                next:
                current + 1 >= this.state.numberOfQuestions
                  ? 'Submit'
                  : 'Next'
              });
              if (current + 1 >= this.state.numberOfQuestions) {
                this.submit = true;
              }
            }}
          />
        </React.Fragment>
      </Grid>
    </div>
  );

  calculatePercentage(questionType, actualScore, options) {
    switch (questionType) {
    case 0:
      return (
        calculateScore(
          questionType,
          options.map(opt => opt.score),
          this.score.scoreIndex
        ) / actualScore
      );
    case 1:
      return (
        calculateScore(
          questionType,
          options.map(opt => opt.score),
          this.score.scoreArrayIndex
        ) / actualScore
      );
    case 2:
    case 3:
      return calculateScore(
        questionType,
        options.map(opt => opt.score),
        this.score.scoreArrayValues
      ) === actualScore
        ? 1
        : 0;
    default:
      return 0;
    }
  }

  showQuestion = (isMobile) => {
    const question = this.state.questions[this.state.currentQuestion];
    const options = question.questionOptions;
    const questionPic = question.questionPictures;
    return (
      <div className={isMobile ? "row pl-3 pr-3" : "row p-0"}>
        <Typography className={isMobile ? 'pb-3' : 'pl-3 pb-3'}>{question.questionTitle}</Typography>
        {questionPic ? (
          <div className="text-center w-100 text-center mb-2 mt-2">
            <img src={questionPic.url} width="200px" height="200px" alt="Sorry" />
          </div>
        ) : null}
        <div className={isMobile ? "p-0 text-justify col-sm-12" : "p-0 text-justify col-sm-12 pl-4"}>
          {options.map((opt, index) =>
            showOption(
              question.type,
              opt.description,
              index,
              question.questionOptions.length,
              question.questionId,
              this.score,
              question.totalScore
            )
          )}
        </div>
      </div>
    );
  };

  isTimeUp = (isMobile) => {
    if (this.state.seconds === 0) {
      return (
        <div
          className="container"
          style={{
            fontSize: '26px',
            color: 'red',
            fontWeight: '600',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100px',
			paddingLeft: '0.5em'
          }}
        >
          Time is up
        </div>
      );
    }
    if (!this.state.start) {
      return this.isBeginQuiz();
    } else {
      return (
        <div className="container">
          {this.progressBar()}
          {this.showQuestion(isMobile)}
          {this.navigateButtons()}
        </div>
      );
    }
  };

  render() {
	const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <div className={isMobile ? null : "container"} style={{ height: '100%' }}>
        {this.state.takeCourse &&
          <React.Fragment>
            {this.titleTimer(isMobile)}
            {this.isTimeUp(isMobile)}
          </React.Fragment>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  memNo: state.AuthReducer.memNo,
  oneLesson: state.nLearningManagementReducer.lesson,
  questions: state.nLearningManagementReducer.quizzes,
  score: state.nLearningManagementReducer.score,
  courseDedicatedStatusType: state.nLearningManagementReducer.courseModuleName,
  altMemberDetails: state.ProfileReducer.memberData
});

const mapDispatchToProps = dispatch => ({
  onQuizzesChange: newQuiz =>
    dispatch({
      type: UPDATE_QUIZZES,
      quizzes: newQuiz
    }),
  onScoreChange: newScore => {
    dispatch({
      type: UPDATE_SCORE,
      score: newScore
    });
    return Promise.resolve();
  },
  onResultsRecordChange: newResultsRecord =>
    dispatch({
      type: UPDATE_RESULTS_RECORD,
      resultsRecord: newResultsRecord
    }),
  onQuizCompletionChange: newQuizCompletion =>
    dispatch({
      type: UPDATE_QUIZ_COMPLETION,
      quizCompletion: newQuizCompletion
    }),
  onCurrentLMSTabChange: newCurrentLmsTab =>
    dispatch({
      type: UPDATE_CURRENT_LMS_TAB,
      currLmsTab: newCurrentLmsTab
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakeQuiz);
