import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
export class Notify extends Component {
  constructor(props) {
    super(props);
    this.state = { return: [], account: null };
  }

  componentDidMount() {
    axios.get("sandbox.payfast.co.za").then(res => {
      this.setState({account: res.data});
    });
  }

  render() {
    return <div>Whats happening</div>;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notify);
