import { withStyles } from "@material-ui/core/styles";
import DateRange from "@material-ui/icons/DateRange";
import Axios from "axios";
import React, { Component } from "react";
import { Form, InputGroup, Row, Col, Container,Button } from "react-bootstrap";
import { connect } from "react-redux";
import { BASE } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import { toast } from 'react-toastify';
import { getCurrentDate } from "../adminPortal/Approvals/ApproveDashboardEndors"
toast.configure();
const styles = {};
const UserStatus = Object.freeze({ 1: "Approved", 2: "Pending", 3: "Rejected", 4: "Renewing" })
class EditUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ExpiryDate: "",
      memberStatus: "",

      //new fields
      idNumber: this.props.MemberV.idno,
      firstname: this.props.MemberV.firstname,
      surname: this.props.MemberV.surname,
      email: this.props.MemberV.email,
      cellNo: this.props.MemberV.cellNo,
      inceptionDate: this.props.MemberV.inceptionDate,
      isResetPassword: false,
      newPassword: '',
      _memStatus: UserStatus[this.props.MemberV.status]
    };
  }

  updateMemberDetails = e => {
    
    let userInfo = {
        idno: this.state.idNumber,
        firstname: this.state.firstname,
        surname: this.state.surname,
        email: this.state.email,
        cellNo: this.state.cellNo,
        inceptionDate: this.state.inceptionDate,
        memNo: this.props.MemberV.memNo
    }

    this.props.updateMemberDetails(userInfo);
  }

  resetMemberPassword = e => {
    
    let ResetPassword = {
        memberNumber: this.props.MemberV.memNo,
        newPassword: this.state.newPassword,
        currentPassword: localStorage.getItem("memNo")
    }

    Axios({
        url: BASE + "api/Member/ResetMemberPassword" ,
        data: ResetPassword,
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
        .then(res => {
          toast.success(res.data.message)
              //Reset state
          this.setState({newPassword:'',isResetPassword: false});
        });
  }

  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <Container className="container mb-3">

    <Row>
        <Col>{info("First Names", 
              <input
                type="text"
                className="form-control"
                defaultValue={this.state.firstname}
                onInput={a => {
                  this.setState({
                    firstname: a.target.value
                  });
                }}
              />
            )}</Col>

        <Col>{info("Last Name", 
              <input
                type="text"
                className="form-control"
                defaultValue={this.state.surname}
                onInput={a => {
                  this.setState({
                    surname: a.target.value
                  });
                }}
              />
            )}</Col>
      </Row>

      <Row>
        <Col>{info("ID Number", 
              <input
                type="text"
                className="form-control"
                defaultValue={this.state.idNumber}
                onInput={a => {
                  this.setState({
                    idNumber: a.target.value
                  });
                }}
              />
            )}</Col>
        <Col>{info("Email", 
              <input
                type="text"
                className="form-control"
                defaultValue={this.state.email}
                onInput={a => {
                  this.setState({
                    email: a.target.value
                  });
                }}
              />
            )}</Col>
      </Row>

      <Row>
        <Col>        
        {info("Cell Number", 
              <input
                type="text"
                className="form-control"
                defaultValue={this.state.cellNo}
                onInput={a => {
                  this.setState({
                    cellNo: a.target.value
                  });
                }}
              />
            )}</Col>

      </Row>



            <div className="mt-2">Inceptive Date <i>({this.state.inceptionDate})</i></div>
            <InputGroup className="mb-3 pl-0">
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <DateRange />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="input"
                type="date"
                onInput={a => {
                  this.setState({
                    inceptionDate: a.target.value
                  });
                }}
              />
            </InputGroup>

                {this.state.isResetPassword && <Row>
                    <Col>{info("New password", 
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={this.state.newPassword}
                            onInput={a => {
                            this.setState({
                                newPassword: a.target.value
                            });
                            }}
                        />
                        )}</Col>
                    <Col>{info(<br/>, 
                          <Button align="right" variant="warning" onClick={()=> this.resetMemberPassword()}>Update password</Button>
                        )}</Col>
                </Row>}


            {!this.state.isResetPassword && <Row>
        
                <Col>
                    <Button variant="warning" onClick={()=> this.setState({isResetPassword:true})}>Reset password</Button>
                </Col>
                <Col md={{span: -2 }}>
                <Button align="right" variant="success" onClick={()=> this.updateMemberDetails()}>Save changes</Button>
                </Col>
            </Row>}

      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),

    onMemberViewChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditUserDetails));
