import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { BASE, GET_SECTION_LIST, GET_UPDATELICENCE_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as constFile from "../../../store/constantFile/ConstFile";
import LicensedFireArmHeader from "./LicensedFireArmHeader";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    width: "60%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    width: "60%",
    flex: 1,
    margin: 5
  },
  button: {
    margin: 30,
    padding: 10,
    borderRadius: "5px",
    backgroundColor: "white",
    color: "black",
    borderColor: "#000000",
    display: "inline-block",
    flex: 1,
    width: 90,
    height: 50,
    "&:hover": {
      backgroundColor: "#F5A623",
      color: "White",
      border: "none"
    }
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class UpdateLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Section_List: [],
      isValid: false,
      showValid: true
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    this.checkNotEmpty();
    if (
      this.state.isValid &&
      !(this.props.newlicenceDetails.Firearm === "") &&
      !(this.props.newlicenceDetails.Make === "") &&
      !(this.props.newlicenceDetails.Model === "") &&
      !(this.props.newlicenceDetails.Caliber === "") &&
      !(this.props.newlicenceDetails.SerialNumber === "") &&
      !(this.props.newlicenceDetails.Section === "") &&
      !(this.props.newlicenceDetails.LicenseNo === "") &&
      !(this.props.newlicenceDetails.IssueDate === "") &&
      !(this.props.newlicenceDetails.ExpiryDate === "")
    ) {
      fetch(
        BASE + GET_UPDATELICENCE_URL,
        {
          method: "POST",
          body: JSON.stringify({
            LicenseNo: this.props.newlicenceDetails.LicenseNo,
            Firearm: this.props.newlicenceDetails.Firearm,
            Make: this.props.newlicenceDetails.Make,
            Model: this.props.newlicenceDetails.Model,
            Caliber: this.props.newlicenceDetails.Caliber,
            SerialNumber: this.props.newlicenceDetails.SerialNumber,
            LicenseType: this.props.newlicenceDetails.LicenseType,
            Section: this.props.newlicenceDetails.Section,
            ExpiryDate: this.props.newlicenceDetails.ExpiryDate,
            IssueDate: this.props.newlicenceDetails.IssueDate,
            MemNo: this.props.memberState.isAdmin
              ? this.props.MemberV.memNo
              : this.props.userDetails.memberNumber,
            Custom: constFile.CFALSE
          }),
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        }
      );
      this.setState({ isValid: false });
      this.componentDidMount();
      this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
    }
  };

  handleChange = name => event => {
    this.props.onAddLicenseDetailsChange(event.target.value, [name]);
    this.setState({ showValid: true });
    this.setState({ isValid: false });
    this.checkNotEmpty();
  };

  checkNotEmpty = () => {
    let V = true;
    if (
      this.props.newlicenceDetails.Firearm === "" ||
      this.props.newlicenceDetails.Firearm === null ||
      this.props.newlicenceDetails.Firearm === undefined ||
      this.props.newlicenceDetails.Make === "" ||
      this.props.newlicenceDetails.Make === null ||
      this.props.newlicenceDetails.Make === undefined ||
      this.props.newlicenceDetails.Model === "" ||
      this.props.newlicenceDetails.Model === null ||
      this.props.newlicenceDetails.Model === undefined ||
      this.props.newlicenceDetails.Caliber === "" ||
      this.props.newlicenceDetails.Caliber === null ||
      this.props.newlicenceDetails.Caliber === undefined ||
      this.props.newlicenceDetails.SerialNumber === "" ||
      this.props.newlicenceDetails.SerialNumber === null ||
      this.props.newlicenceDetails.SerialNumber === undefined ||
      this.props.newlicenceDetails.Section === "" ||
      this.props.newlicenceDetails.Section === null ||
      this.props.newlicenceDetails.Section === undefined ||
      this.props.newlicenceDetails.IssueDate === "" ||
      this.props.newlicenceDetails.IssueDate === null ||
      this.props.newlicenceDetails.IssueDate === undefined ||
      this.props.newlicenceDetails.ExpiryDate === "" ||
      this.props.newlicenceDetails.ExpiryDate === null ||
      this.props.newlicenceDetails.ExpiryDate === undefined
    ) {
      V = false;
    }
    this.setState({
      isValid: V
    });
  };
  componentDidMount() {
    this.setState({ showValid: false });

    fetch(BASE + GET_SECTION_LIST, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    })
      .then(res => res.json())
      .then(json => {
        this.setState({
          isLoaded: true,
          Section_List: json,
          isValid: false,
          showValid: true
        });
      });
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (this.state.showValid) {
        if (!value) {
          return false;
        }
        if (value.length < 0) {
          return false;
        }
      }
      return true;
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div className="activity-header-container">
          <LicensedFireArmHeader />
        </div>
        <div className={classes.overall}>
          <form className={classes.container} noValidate autoComplete="off">
            <ValidatorForm
              ref="form"
              align="center"
              autoComplete="off"
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                id="OutlinedSection"
                className={classes.textField}
                value={this.props.newlicenceDetails.Firearm}
                onChange={this.handleChange("Firearm")}
                name="Firearm"
                label="Fire arm"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the FireArm Type",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                className={classes.textField}
                value={this.props.newlicenceDetails.Make}
                onChange={this.handleChange("Make")}
                name="Firearm"
                label="Make"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Make",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                className={classes.textField}
                value={this.props.newlicenceDetails.Model}
                onChange={this.handleChange("Model")}
                name="Model"
                label="Model"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Model",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                className={classes.textField}
                value={this.props.newlicenceDetails.Caliber}
                onChange={this.handleChange("Caliber")}
                name="Caliber"
                label="Caliber"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Caliber",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                className={classes.textField}
                label="Serial Number"
                value={this.props.newlicenceDetails.SerialNumber}
                onChange={this.handleChange("SerialNumber")}
                name="SerialNumber"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Seriel Number",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                label="License Number"
                className={classes.textField}
                value={this.props.newlicenceDetails.LicenseNo}
                onChange={this.handleChange("LicenseNo")}
                name="LicenseNo"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the License Number",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />

              <Select
                className={classes.select}
                native
                value={this.props.newlicenceDetails.Section}
                onChange={this.handleChange("Section")}
                input={
                  <OutlinedInput
                    name="section"
                    id="Section"
                  />
                }
              >
                {this.state.Section_List.map((activityType, index) => (
                  <option key={index} value={activityType.section}>
                    {activityType.section}
                  </option>
                ))}
              </Select>

              <TextValidator
                id="OutlinedSection"
                label="IssueDate"
                className={classes.textField}
                type="date"
                value={this.props.newlicenceDetails.IssueDate.substring(0, 10)}
                onChange={this.handleChange("IssueDate")}
                name="LicenseNo"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Date Issued",
                  "This field is required"
                ]}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                label="Expiry Date"
                className={classes.textField}
                type="date"
                value={this.props.newlicenceDetails.ExpiryDate.substring(0, 10)}
                onChange={this.handleChange("ExpiryDate")}
                name="LicenseNo"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Expiry Issued",
                  "This field is required"
                ]}
                InputLabelProps={{
                  shrink: true
                }}
                margin="normal"
                variant="outlined"
              />

              <div className={classes.footer}>
                <button
                  variant="contained"
                  className={classes.button}
                  onClick={() =>
                    this.props.onCurrentLicenseTabChange(
                      "0",
                      "currentLicenseTab"
                    )
                  }
                >
                  Previous
                </button>
                <div className={classes.grow} />
                <button
                  variant="contained"
                  className={classes.button}
                  onClick={e => this.handleSubmit(e)}
                >
                  Update
                </button>
              </div>
            </ValidatorForm>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.currentActivityTab,
    userDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onAddLicenseDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdateLicense));
