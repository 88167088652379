import * as Types from './NewActivityActionTypes';
import {UPDATE_MEMBERS_AREA} from "../../../store/actions/actionTypes";
import * as APICalls from '../../../API/APICalls';

export const goBackAC = (value, vname) => {
    return {
        type: UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
    };
};

export const goBack = (value, vname) => (dispatch) => dispatch(goBackAC(value, vname));

export const resetAC = (value, vname) => {
    return {
        type: Types.NEW_ACT_RESET,
    };
};

export const reset = () => (dispatch) => dispatch(resetAC());

export const toggleIsNarfoEventAC = (value) => {
    return {
        type: Types.NEW_ACT_TOGGLE_NARFO_EVENT,
        newActivityState: {
            isNarfoEvent: value
        }
    };
};

export const toggleIsNarfoEvent = (value) => (dispatch) => {
    return dispatch(toggleIsNarfoEventAC(value));
};

export const toggleFileInputAC = () => {
    return {
        type: Types.NEW_ACT_TOGGLE_FILE_INPUT,
    };
};

export const toggleFileInput = () => (dispatch) => {
    return dispatch(toggleFileInputAC());
};

export const addFileAC = (file) => {
    return {
        type: Types.NEW_ACT_ADD_FILE,
        newActivityState: {
            documents: file
        }
    }
}

export const addFile = (file) => (dispatch) => dispatch(addFileAC(file));

export const removeFileAC = (file) => {
    return {
        type: Types.NEW_ACT_REMOVE_FILE,
        newActivityState: {
            documents: file
        }
    }
}

export const removeFile = (file) => (dispatch) => dispatch(removeFileAC(file));

export const setDateAC = (date) => {
    return {
        type: Types.NEW_ACT_SET_DATE,
        newActivityState: {
            date
        }
    }
}
export const setDate = (date) => (dispatch) => dispatch(setDateAC(date));

export const setAssociationAC = (association) => {
    return {
        type: Types.NEW_ACT_SET_ASSOCIATION,
        newActivityState: {
            association
        }
    }
}
export const setAssociation = (association) => (dispatch) => dispatch(setAssociationAC(association));

export const setMemberNumberAC = (memberNumber) => {
    return {
        type: Types.NEW_ACT_SET_MEMBER_NUMBER,
        newActivityState: {
            memberNumber
        }
    }
}
export const setMemberNumber = (memberNumber) => (dispatch) => dispatch(setMemberNumberAC(memberNumber));

export const setActivityEventAC = (activityEvent) => {
    return {
        type: Types.NEW_ACT_SET_ACTIVITY_EVENT,
        newActivityState: {
            activityEvent
        }
    }
}
export const setActivityEvent = (activityEvent) => (dispatch) => dispatch(setActivityEventAC(activityEvent));

export const setEventDescAC = (desc) => {
    return {
        type: Types.NEW_ACT_SET_EVENT_DESCRIPTION,
        newActivityState: {
            eventDescription: desc
        }
    }
}
export const setEventDesc = (desc) => (dispatch) => dispatch(setEventDescAC(desc));

export const setActivityTypeAC = (activity) => {
    return {
        type: Types.NEW_ACT_SET_ACTIVITY_TYPE,
        newActivityState: {
            activityType: activity
        }
    }
}
export const setActivityType = (activity) => (dispatch) => dispatch(setActivityTypeAC(activity));


export const setFirearmTypeAC = (firearm) => {
    return {
        type: Types.NEW_ACT_SET_FIREARM_TYPE,
        newActivityState: {
            firearm
        }
    }
}
export const setFirearmType = (firearm) => (dispatch) => dispatch(setFirearmTypeAC(firearm));

export const setOpticsAC = (optics) => {
    return {
        type: Types.NEW_ACT_SET_OPTICS,
        newActivityState: {
            optics
        }
    }
}
export const setOptics = (optics) => (dispatch) => dispatch(setOpticsAC(optics));

export const setCaliberAC = (caliber) => {
    return {
        type: Types.NEW_ACT_SET_CALIBER,
        newActivityState: {
            caliber
        }
    }
}
export const setCaliber = (caliber) => (dispatch) => dispatch(setCaliberAC(caliber));

export const setScoreAC = (score) => {
    return {
        type: Types.NEW_ACT_SET_SCORE,
        newActivityState: {
            score
        }
    }
}
export const setScore = (score) => (dispatch) => dispatch(setScoreAC(score));

export const uploadActivityBeginAC = () => {
    return {
        type: Types.NEW_ACT_UPLOAD_BEGIN,
        newActivityState: {
            uploadStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const uploadActivityFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_UPLOAD_FAILURE,
        newActivityState: {
            uploadStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const uploadActivitySuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_UPLOAD_SUCCESS,
        newActivityState: {
            uploadStatus: {
                loading: false,
                response
            }
        }
    }
}

export const uploadActivity = (data) => async (dispatch) => {
    dispatch(uploadActivityBeginAC());
    try {
        const response = await APICalls.uploadActivityAPI(data);
        dispatch(uploadActivitySuccessAC(response))
        return response;
    } catch (error) {
        dispatch(uploadActivityFailureAC(error));
    }
}

export const getActivityTypesBeginAC = () => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITIES_BEGIN,
        newActivityState: {
            getActivityStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const getActivityTypesFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITIES_FAILURE,
        newActivityState: {
            getActivityStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const getActivityTypesSuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITIES_SUCCESS,
        newActivityState: {
            getActivityStatus: {
                loading: false,
                response,
            },
            activityTypes: response.data
        }
    }
}

export const getActivityTypes = () => async (dispatch) => {
    dispatch(getActivityTypesBeginAC());
    try {
        const response = await APICalls.getActivitiesAPI();
        dispatch(getActivityTypesSuccessAC(response))
        return response;
    } catch (error) {
        dispatch(getActivityTypesFailureAC(error));
    }
}

export const getOpticsBeginAC = () => {
    return {
        type: Types.NEW_ACT_GET_OPTICS_BEGIN,
        newActivityState: {
            getOpticsStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const getOpticsFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_GET_OPTICS_FAILURE,
        newActivityState: {
            getOpticsStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const getOpticsSuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_GET_OPTICS_SUCCESS,
        newActivityState: {
            getOpticsStatus: {
                loading: false,
                response,
            },
            opticsList: response.data
        }
    }
}

export const getOptics = () => async (dispatch) => {
    dispatch(getOpticsBeginAC());
    try {
        const response = await APICalls.getOpticsAPI();
        dispatch(getOpticsSuccessAC(response))
        return response;
    } catch (error) {
        dispatch(getOpticsFailureAC(error));
    }
}

export const getFirearmTypesBeginAC = () => {
    return {
        type: Types.NEW_ACT_GET_FIREARM_TYPES_BEGIN,
        newActivityState: {
            getFirearmTypesStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const getFirearmTypesFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_GET_FIREARM_TYPES_FAILURE,
        newActivityState: {
            getFirearmTypesStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const getFirearmTypesSuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_GET_FIREARM_TYPES_SUCCESS,
        newActivityState: {
            getFirearmTypesStatus: {
                loading: false,
                response,
            },
            firearmTypes: response.data
        }
    }
}

export const getFirearmtypes = () => async (dispatch) => {
    dispatch(getFirearmTypesBeginAC());
    try {
        const response = await APICalls.getFirearmAPI();
        dispatch(getFirearmTypesSuccessAC(response))
        return response;
    } catch (error) {
        dispatch(getFirearmTypesFailureAC(error));
    }
}

export const getAssociationsBeginAC = () => {
    return {
        type: Types.NEW_ACT_GET_ASSOCIATIONS_BEGIN,
        newActivityState: {
            getAssociationsStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const getAssociationsFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_GET_ASSOCIATIONS_FAILURE,
        newActivityState: {
            getAssociationsStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const getAssociationsSuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_GET_ASSOCIATIONS_SUCCESS,
        newActivityState: {
            getAssociationsStatus: {
                loading: false,
                response,
            },
            associationsList: response.data
        }
    }
}

export const getAssociations = () => async (dispatch) => {
    dispatch(getAssociationsBeginAC());
    try {
        const response = await APICalls.getAssociationsAPI();
        dispatch(getAssociationsSuccessAC(response))
        return response;
    } catch (error) {
        dispatch(getAssociationsFailureAC(error));
    }
}

export const getActivityEventsBeginAC = () => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITY_EVENT_BEGIN,
        newActivityState: {
            getActivityEventsStatus: {
                loading: true,
                error: null
            }
        }
    }
}

export const getActivityEventsFailureAC = (error) => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITY_EVENT_FAILURE,
        newActivityState: {
            getActivityEventsStatus: {
                loading: false,
                error: error
            }
        }
    }
}

export const getActivityEventsSuccessAC = (response) => {
    return {
        type: Types.NEW_ACT_GET_ACTIVITY_EVENT_SUCCESS,
        newActivityState: {
            getActivityEventsStatus: {
                loading: false,
                response,
            },
            activityEventList: response.data
        }
    }
}

export const getActivityEvents = () => async (dispatch) => {
    dispatch(getActivityEventsBeginAC());
    try {
        const response = await APICalls.getActivityEventsAPI();
        dispatch(getActivityEventsSuccessAC(response))
        return response;
    } catch (error) {
        dispatch(getActivityEventsFailureAC(error));
    }
}