import React, { Component } from "react";
import { connect } from "react-redux";
import "./chartStyles.css";
import BarGraph from "./BarGraph";
import GraphButtons from "./GraphButtons";

export class CourseReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reRenderGraph: false,
      type: 1
    }

    this.weekLabel = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    this.yearLabel = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.monthLabel = this.generateMonthLabel;
  }

  generateMonthLabel = (length) => {
    return Array.from(new Array(length), (v, k) => k + 1);
  }

  loadData = (type, loading) => {
    this.setState({
      type: type,
      reRenderGraph: true
    })
    if (!loading) {
      setTimeout(() => {
        this.setState({
          reRenderGraph: false
        })
      }, 15);
    }
  }

  tempDataLoader = () => {
    function findData(array, id){
      const data = array.find(element => element.key === id);
      return data?data.data:[];
    }
    switch (this.state.type) {
      case 1:
        return findData(this.props.weekData, this.props.id);
      case 2:
        return findData(this.props.monthData, this.props.id);
      case 3:
        return findData(this.props.yearData, this.props.id);
      default:
        return [];
    }
  }

  tempLabelLoader = () => {
    switch (this.state.type) {
      case 1:
        return this.weekLabel;
      case 2:
        return this.monthLabel;
      case 3:
        return this.yearLabel;
      default:
        return [];
    }
  }

  render() {
    return (
      <div className="chart-container-root">
        <div className="chart-container-title">
          {this.props.title + ' Review'}
        </div>
        <GraphButtons loadData={this.loadData} id={this.props.id} />
        <BarGraph load={this.state.reRenderGraph} id={this.props.id} data={this.tempDataLoader()} label={this.tempLabelLoader()} />
      </div>
    )
  }
}

CourseReview.propTypes = {

}

const mapStateToProps = (state) => ({
  weekData: state.lmsStatistics.weekData,
  monthData: state.lmsStatistics.monthData,
  yearData: state.lmsStatistics.yearData
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CourseReview)
