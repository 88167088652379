import axios from 'axios';
import API_URL from './apiUrl';

const getFirearmLicence = headers => id => axios
  .get(`${API_URL}/LicensedFirearms/getlicense/${id}`, { headers });

  const getLicenseApplicationsByMemNo = headers => memNo => axios
  .get(`${API_URL}/LicensedFirearms/GetLicenseApplicationsByMemNo?memNo=${memNo}`, { headers });

const getFirearmLicenceByID = headers => id => axios
  .get(`${API_URL}/LicensedFirearms/get/${id}`, { headers });

const updateLicenseReminderCallback = headers => data => axios
  .post(`${API_URL}/LicensedFirearms/update`, data, { headers });

const deleteLicenseReminderCallback = headers => id => axios({
  url: `${API_URL}/LicensedFirearms/delete/${id}`,
  method: 'post',
  headers: headers
});

const deleteLicenseApplicationCallback = headers => id => axios({
  url: `${API_URL}/LicensedFirearms/RemoveLicenseApplication?referenceNo=${id}`,
  method: 'post',
  headers: headers
});

const addLicenseReminderCallback = headers => data => axios
  .post(`${API_URL}/LicensedFirearms/post`, data, { headers });

const postLicenseApplication = headers => data => axios
  .post(`${API_URL}/LicensedFirearms/PostLicenseApplication`, data, { headers });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getFirearmLicence: getFirearmLicence(headers),
    getFirearmLicenceByID: getFirearmLicenceByID(headers),
    updateLicenseReminderCallback: updateLicenseReminderCallback(headers),
    deleteLicenseReminderCallback: deleteLicenseReminderCallback(headers),
    deleteLicenseApplicationCallback: deleteLicenseApplicationCallback(headers),
    addLicenseReminderCallback: addLicenseReminderCallback(headers),
    getLicenseApplicationsByMemNo: getLicenseApplicationsByMemNo(headers),
    postLicenseApplication: postLicenseApplication(headers)
  };
}
