import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Typography,
  Grid,
  Divider
} from '@material-ui/core';
import {
  UPDATE_SECTION_TITLE,
  UPDATE_ASSESMENT,
  UPDATE_QUIZZES,
  UPDATE_LESSON,
  TRACK_CURRENT_MODULE_ID,
  UPDATE_COURSE_MODULE_NAME,
  UPDATE_CURRENT_LMS_TAB
} from '../../store/LearningManagement/actionTypes';

class DisplayCourseMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toMaterialSelect: false 
    };
  }

  getMemberResultForThisCourse = id => {
    const quiz = this.props.quizCompletion.find(q => q.courseMaterialId === id);
    return quiz ? quiz.completion : 0;
  };

  handleAssementClick = assesment => {
    this.props.onAssesmentChange(assesment);
    this.props.onCurrentLMSTabChange(1);
  };

  handleLessonClick = lesson => {
    this.props.getQuestionByIdCallBack(lesson.pkCourseId)
      .then(res => {
        const questions = Array.from(res.data.data);
        this.props.onQuizzesChange(questions);
        this.props.onLessonChange(lesson);
        this.props.onCurrentLMSTabChange(2);
        this.props.getCourseMaterialByIdCallBack(lesson.pkCourseId)
          .then(res => {
            this.props.onModuleIdTrack(res.data.moduleId);
            this.props.getCourseModuleNameByIdCallBack(res.data.moduleId)
              .then(resp => {
                this.props.onCourseModeleNameUpdate(resp.data.moduleName);
              });
          });
      })
      .catch(() => {
        this.props.onQuizzesChange([]);
      });
  }
  displayLessons = (assesmnetId, isMobile) => {
    const _sectionTitle = this.props.sectionTitle;
    if (_sectionTitle === 'Course Learning Material') {
      var list = this.props.lessonList.filter(lesson => lesson.assesmnetId === assesmnetId);
      return (
        <div >
          {list.map((assesment, index) => (
            <React.Fragment key={index}>
              <div        
                onClick={() => {
                  this.handleAssementClick(assesment);
                }}
                style={{ cursor: 'pointer' }}
              >
                <div className="mb-4">
                  <div className={isMobile ? "outer-shadow border-radius-2 py-2 px-3 bg-white-1" : "outer-shadow border-radius-2 py-2 px-4 bg-white-1"}>
                    <div className={isMobile ? "pt-6 pb-6" : "pt-6 pb-6 pr-4"}>
                      <Grid container justify='space-between'>
                        <div>{isMobile ? assesment.title : `${assesment.title} Course Material`}</div>
                        <Typography variant='h6' color='primary'>View</Typography>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>))}
        </div>
      );
    } else if (this.props.sectionTitle === 'Course Assessment Quizzes') {
      var secondList = this.props.lessonList.filter(lesson => lesson.assesmnetId === assesmnetId);
      return (
        <React.Fragment>
          {secondList.map((lesson, index) => (
            <React.Fragment key={index}>
              <div  
                onMouseDown={() => this.getMemberResultForThisCourse(lesson.pkCourseId) < 60 ? this.handleLessonClick(lesson) : ""}
                style={{ cursor: this.getMemberResultForThisCourse(lesson.pkCourseId) < 60 ? 'pointer': 'not-allowed' }}
              >
              
                <div className="mb-4">
                  <div className={isMobile ? "outer-shadow border-radius-2 py-2 px-3 bg-white-1" : "outer-shadow border-radius-2 py-2 px-4 bg-white-1"}>
                    <div className={isMobile ? "pt-6 pb-6" : "pt-6 pb-6 pr-4"}>
                      <Grid
                        container
                        direction="row"
                      >
                        <Grid item xs={isMobile ? 2 : 1}>
                          <div>{`${this.getMemberResultForThisCourse(lesson.pkCourseId)} %`}</div>
                        </Grid>
                        <Grid item xs={isMobile ? 6 :3}>
                          <div>{isMobile ? lesson.title.split('Quiz') : `${lesson.title.split('Quiz')} Quiz`}</div>
                        </Grid>
                        <Grid item xs={isMobile ? 4 :8}>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {this.getMemberResultForThisCourse(lesson.pkCourseId) === 0 ?
                              <Typography variant='h6' color='primary'>Take quiz</Typography> :
                              this.getMemberResultForThisCourse(lesson.pkCourseId) >= 60 ?
                                <Typography variant='h6' style={{ color:'#00FF00' }}>Completed</Typography> :
                                <Typography variant='h6' style={{ color:'#FFA500' }}>Resume</Typography>}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>))}
        </React.Fragment>
      );
    }
  };
  typedList = () => {
    let list = this.props.assesmentList;
    if (this.props.sectionTitle === 'Course Learning Material') {
      list = list.filter(l => l.type === 2);
    } else {
      list = list.filter(l => l.type === 1);
    }
    list = list.filter(l => l.hasChildren);
    return list;
  }
  gridCourseMaterial = (isMobile) => (
    <Grid item>
      {this.typedList().map((assesment, index) => (
        <Grid item key={index}>
          <Grid container direction='column' className="mb-8">
            {this.props.sectionTitle === 'Course Learning Material' ? 
              <Grid item >
                <Typography variant='h4' color='primary'>{isMobile ? assesment.title.split('Course Modules')[0] : assesment.title}</Typography>
              </Grid>
              : <Typography variant='h4' color='primary'>{isMobile ? assesment.title.split('Assessment Modules')[0] : `${assesment.title.replace('Course', ' ')} Quizzes`}</Typography>}
            <Divider />
          </Grid>
          <Grid item>
            {this.displayLessons(assesment.assesmentId, isMobile)}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
        {this.gridCourseMaterial(isMobile)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lessonList: state.nLearningManagementReducer.lessons,
  assesmentList: state.nLearningManagementReducer.assesments,
  sectionTitle: state.nLearningManagementReducer.sectionTitle,
  assesmentCompletion: state.nLearningManagementReducer.assesmentCompletion,
  quizCompletion: state.nLearningManagementReducer.quizCompletion
});

const mapDispatchToProps = dispatch => ({
  onSectionTitleChange: newSectionTitle =>
    dispatch({
      type: UPDATE_SECTION_TITLE,
      sectionTitle: newSectionTitle
    }),
  onAssesmentChange: newAssesment =>
    dispatch({
      type: UPDATE_ASSESMENT,
      assesment: newAssesment
    }),
  onQuizzesChange: newQuizzes =>
    dispatch({
      type: UPDATE_QUIZZES,
      quizzes: newQuizzes
    }),
  onLessonChange: newLesson =>
    dispatch({
      type: UPDATE_LESSON,
      lesson: newLesson
    }),
  onModuleIdTrack: newModuleId =>
    dispatch({
      type:TRACK_CURRENT_MODULE_ID,
      moduleId: newModuleId
    }),
  onCourseModeleNameUpdate: newCourseName =>
    dispatch({
      type:UPDATE_COURSE_MODULE_NAME,
      courseModuleName: newCourseName
    }),
  onCurrentLMSTabChange: newCurrentLmsTab =>
    dispatch({
      type: UPDATE_CURRENT_LMS_TAB,
      currLmsTab: newCurrentLmsTab
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(DisplayCourseMaterial);