import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import EmailsHome from "../adminEmails/EmailsHome"

class Emails extends Component {
  render() {
    return (
        <div className="mainHome" style={{marginTop: "60px"}}>
        <Paper elevation={0}>
          <EmailsHome/>
        </Paper>
      </div>
    );
  }
}

export default Emails;
