import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";

const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontWeight: "bold",
    marginLeft: 12,
    marginRight: 20
  },
  icon: {
    color: "#800000"
  },

  iconHover: {
    margin: theme.spacing.unit / 2,
    "&:hover": {
      color: "#000000"
    }
  },

  fab: {
    backgroundColor: "#000000",
    width: 35,
    height: "1vh",
    margin: theme.spacing.unit / 4,
    color: "#FFFFFF",
    "&:hover": {
      color: "#6BC20A",
      backgroundColor: "#000000"
    }
  }
});

function LicensedFireArmHeader(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      {props.currentLicenseTab === "0" || props.currentLicenseTab === "3" ? (
        <GenericHeader
          title="License Reminder"
          showBackButton
          backFunction={() => {
            props.onCurrentLicenseTabChange("0", "currentLicenseTab");
          }}
        />
      ) : (
        <GenericHeader
          title="View License"
          showBackButton
          backFunction={() => {
            props.onCurrentLicenseTabChange("0", "currentLicenseTab");
          }}
          showFilledAddButton
          addFunction={() => {
            props.onCurrentLicenseTabChange("4", "currentLicenseTab");
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onLicenceChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LicensedFireArmHeader));
