import React, { Component } from "react";
import Folders from "./Folders";
import LMSMenu from "../LMSMenu/LMSMenu";
import {
  COURSE_MATERIAL_MOVE,
  COURSE_MODULE_MOVE
} from "../LMSAPIEndPoints";

export default class Modules extends Component {

  constructor(props) {
    super(props);
    this.state = {
      numOfSelectedChildren: 0,
      numOfSelectedParent: 0,
      menu: 0,
      moduleId: 0,
      name: "",
      addModuleType: 0,
      idArray: []
    }
  }

  swapChildren = (from, to) => {
    fetch(COURSE_MATERIAL_MOVE + '/' + from + '/' + to, {
      method: 'POST'
    })
  }

  swapFolders = (from, to) => {
    fetch(COURSE_MODULE_MOVE + '/' + from + '/' + to, {
      method: 'POST'
    })
  }

  transferChild = (materialId, newParentId) => {
    fetch(COURSE_MATERIAL_MOVE + 'Module/' + materialId + '/' + newParentId, {
      method: 'POST'
    })
  }

  checkBoxCallBack = (numParent, numChild, idArray) => {
    this.setState({
      numOfSelectedChildren: numChild,
      numOfSelectedParent: numParent,
      idArray: idArray
    })
  }

  changeMenu = (value, id, name, type) => {
    this.setState({
      menu: value,
      moduleId: id,
      name: name,
      addModuleType: type
    })
  }

  render() {
    return (
      <div className="modules-root">
        <div className="modules-buttons">
          <LMSMenu
            moduleTypeName={this.props.moduleTypeName}
            type={this.props.type}
            numChildren={this.state.numOfSelectedChildren}
            numParent={this.state.numOfSelectedParent}
            outSideMenu={this.state.menu}
            moduleId={this.state.moduleId}
            changeMenu={this.changeMenu}
            name={this.state.name}
            addModuleType={this.state.addModuleType}
            idArray={this.state.idArray} 
            modules={this.props.modules}/>
        </div>
        <div className="modules-folder-container">
          <Folders
            modules={this.props.modules}
            apiDragChildCallBack={this.swapChildren}
            apiTransferChildCallback={this.transferChild}
            apiDragParentCallBack={this.swapFolders}
            checkBoxCallBack={this.checkBoxCallBack}
            onClick={this.changeMenu}
            openEditor={this.props.openEditor} />
        </div>
      </div>
    )
  }
}
