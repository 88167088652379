import Axios from 'axios';
import * as EndPoints from "../../../../API/APIendpoint";

export const activityApproval = async (id,status) => {
    const config = {
        url: `${EndPoints.BASE}${EndPoints.ACTIVITY_APPROVAL}${id}/${status}`,
        method: 'post',
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
    }
    return await Axios(config);
}