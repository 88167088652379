import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, FRONT } from "../../../API/APIendpoint";
import { CHECKOUT_DETAILS_ACTIONS } from "../../../store/actions/actionTypes";
export class CheckoutPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ItemName: "",
      MerchantID: "",
      MerchantKey: "",
      Amount: 0,
      FeeYear: new Date().getFullYear() + 1
    };
  }
  componentDidMount() {
    const Cryptr = require("cryptr");
    const cryptr = new Cryptr("myTotalySecretKey");
    Axios.get(BASE + "api/PayFastDetails").then(res => {
      this.setState({
        ItemName: res.data[0].itemName,
        Amount: res.data[0].amount,
        MerchantID: cryptr.decrypt(res.data[0].merchantId),
        MerchantKey: cryptr.decrypt(res.data[0].merchantKey) 
      })
    });
    sessionStorage.setItem("XUVY-113", this.props.memberDetails.memberNumber);
    sessionStorage.setItem("XUVY-114", this.props.memberDetails.email); 
  }
  render() {
    const catchedMemDetails = `${sessionStorage.getItem("CA-NM")} ${sessionStorage.getItem("CA-SU")} ${this.state.FeeYear} Membership Fee`;
    const loginMemDetails = `${this.props._memberDetails.firstname} ${this.props._memberDetails.surname} ${this.state.FeeYear} Membership Fee`;
    const _itemName = !sessionStorage.getItem("CA-NM") ? loginMemDetails : catchedMemDetails;
    return (
      <div className="container p-5">
        <div />
        <div className="row pt-5">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>
                  <h4>Service</h4>
                </th>
                <th>
                  <h4>Description</h4>
                </th>

                <th>
                  <h4>Amount</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> Membership</td>
                <td>Narfo 12-month membership subscription.</td>
                <td className="text-right">{`R ${this.state.Amount}`}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row text-right" align="right">
          <div className="col-xs-2 col-xs-offset-8">
            <p>
              <strong>
                Total : <br />
              </strong>
            </p>
          </div>
          <div className="col-xs-2">
            <strong>
            {this.state.Amount} <br />
            </strong>
          </div>
        </div>
        <div className="row pt-2">
          <form 
            action={this.state.ItemName === "PayFast Sandbox" ? 
            "https://sandbox.payfast.co.za/eng/process" :
            "https://www.payfast.co.za/eng/process"} 
            method="POST"
          >
            <input
              type="hidden"
              name="merchant_id"
              value={this.state.MerchantID.toString()}
            />
            <input
              type="hidden"
              name="merchant_key"
              value={this.state.MerchantKey.toString()}
            />
            <input type="hidden" name="return_url" value={`${FRONT}Return`} />
            <input type="hidden" name="cancel_url" value={`${FRONT}Cancel`} />
            <input type="hidden" name="notify_url" value={`${BASE}api/account/memPayfast`} />
            <input
              type="hidden"
              name="name_first"
              value={this.props.memberDetails.username}
            />
            <input
              type="hidden"
              name="email_address"
              value={this.props.memberDetails.email}
            />
            <input
              type="hidden"
              name="custom_str2"
              value={this.props.memberDetails.memberNumber}
            />
            <input type="hidden" name="m_payment_id" value="01AB" />
            <input type="hidden" name="amount" value={this.state.Amount} />
            <input 
              type="hidden" 
              name="item_name" 
              value= {_itemName}
            />
            <input
              type="hidden"
              name="item_description"
              value="Narfo Membership"
            />
            <input type="hidden" name="custom_int1" value="2" />

            <input type="hidden" name="email_confirmation" value="1" />
            <input
              type="hidden"
              name="confirmation_address"
              value="info@narfo.co.za"
            />
            <button
              type="submit"
              className="btn btn-warning form-control text-light"
              onMouseDown={() => {}}
            >
              Proceed to Payfast {sessionStorage.getItem("XUVY-113")}
            </button>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    details: state.checkoutDefaultsReducer.payfastObj,
    payFastDetails: state.checkoutDefaultsReducer.payFastDetails,
    memberDetails: state.loginReducer.userProfileDetails,
    _memberDetails: state.loginReducer.memberObject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPayFastDetailsChange: obj =>
      dispatch({
        type: CHECKOUT_DETAILS_ACTIONS.UPDATE_PAY_FAST_DETAILS,
        payfastObj: obj
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutPage);
