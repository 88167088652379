import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  btnContainer: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    flex: 1
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: 20,
    backgroundColor: "black",
    color: "white",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class PaymentNewMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _paymentMethod: "",
      _amountPaid: 0,
      _members: this.props.newMembers
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  pendingMemberUpdater = () => {
    this.state._members.forEach(element => {
      if(element.memNo === this.props.pendingMember.memNo) {
        element.status = 1
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.pendingMemberUpdater();
    let yearInt = parseInt(new Date().getFullYear(), 10) + 1;
    const accountInfo = {
      PaymentType: "Membership Fees " + yearInt,
      PaymentMethod: this.state._paymentMethod,
      AmountPaid: this.state._amountPaid,
      Email: this.props.pendingMember.email,
      CostCode: 1001,
      Paid: true,
      MemNo: this.props.pendingMember.memNo
    };
    Axios({
      url: BASE + "api/Member/ApproveNewMember/" + this.props.pendingMember.memNo + "/1",
      data: accountInfo,
      method: "post",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    this.props.onNewMemberChange(this.state._members);
    this.props.history.push("/PendingNewMembers");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div>
          <GenericHeader
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/ApproveNewMembers");
            }}
            title="Membership Payment"
          />
        </div>

        <form
          className={classes.container}
          autoComplete="off"
          onSubmit={e => this.handleSubmit(e)}
        >
          <TextField
            id="outlinedpaymentMethod"
            label="Payment Method"
            className={classes.textField}
            variant="outlined"
            onChange={e => this.setState({_paymentMethod: e.target.value})}
            required
          />

          <TextField
            id="outlinedamountPaid"
            label="Amount Paid"
            className={classes.textField}
            variant="outlined"
            onChange={e => this.setState({_amountPaid: e.target.value})}
            required
          />
          <div className={classes.btnContainer}>
            <button
              type="submit"
              className="btn btn-warning form-control text-light"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PaymentNewMember.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    approveNewMemberState: state.approveNewMemberState,
    pendingMember: state.dashBoardReducer.membersPending,
    newMembers: state.dashBoardReducer.newMembers.newMembers
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onNewMemberChange: membersPending =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS,
        membersPending: membersPending
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentNewMember));
