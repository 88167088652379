import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import axios from "axios";
import { BASE } from "../../../API/APIendpoint";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { isMobile } from "react-device-detect";
import { DateFind } from "../membershipCard/MainMembershipCard";
const UserStatus = Object.freeze({ 1: "Approved", 2: "Pending", 3: "Rejected", 4: "Renewing" })
class MainSalesCommission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            _monthlyRepCommission: this.props.monthlyCommission,
            narfoLogo: "",
            _dss: "",
            _dhs: "",
            _repDetails: {}
        };
        this.commissionDoc = React.createRef();
    }
    componentDidMount() {
      if(!this.props.isAdmin){
        axios({
          url: `${BASE}api/DedicatedStatus/approvedDS/${this.props.memberDetails.memberNumber}`,
          method: 'get',
          headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        }).then(res => {
            res.data.forEach(element =>{
                if(element.dstype === "Dedicated Sports Person"){
                    this.setState({
                        _dss: element.dsno
                    })
                }
                if(element.dstype === "Dedicated Hunter"){
                    this.setState({
                        _dhs: element.dsno
                    })
                }
            })
        })
        .catch(function (error) { });
      }
    }
    commRecByComNo = commNo => {
        const filteredComRec = this.state._monthlyRepCommission.filter(element =>
                                element.paymentTransNo === commNo);
        this.generatePDF(filteredComRec);
    }
    generatePDF = monthlyRepCommission => {
      const repMemNo = monthlyRepCommission[0].repNo;
        const tableArray = numIterator => [
            !monthlyRepCommission[numIterator].inceptionDate
              ? "null"
              : monthlyRepCommission[numIterator].inceptionDate.split("T")[0].toString(),
            !monthlyRepCommission[numIterator].memNo
              ? "null"
              : monthlyRepCommission[numIterator].memNo.toString(),
            !monthlyRepCommission[numIterator].firstname
              ? "null"
              : monthlyRepCommission[numIterator].firstname.toString(),
            !monthlyRepCommission[numIterator].surname
              ? "null"
              : monthlyRepCommission[numIterator].surname.toString(),
            !monthlyRepCommission[numIterator].comission
              ? "null"
              : `R${monthlyRepCommission[numIterator].comission.toString()}`
          ];
          const sum = monthlyRepCommission.reduce((totalCommission, _commission) => totalCommission + _commission.comission, 0);
          let _totalCommission = Math.round((sum + Number.EPSILON) * 100) / 100;
          
          axios({
            url: `${BASE}api/Member/getMemByMemNo/${repMemNo}`,
            method: 'get',
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          }).then(repRes => {
            this.print(tableArray, monthlyRepCommission, _totalCommission, repRes.data) 
          })
          .catch(function (error) { });
    }
    print = (tableArray, monthlyRepCommission, _totalCommission, repDetails) => {
        const pdf = new jsPDF("l", "mm", [310, 210]);
        pdf.autoTable({ html: ".table" });
        let finalY = pdf.previousAutoTable.finalY;
        var arr = [];
        let count = 0;
        let sum = monthlyRepCommission.length;
        while (sum !== 0) {
          arr.push(tableArray(count));
          count++;
          sum--;
        }
        pdf.addImage(this.props.narfo, "JPEG", 20, 22);
        pdf.setTextColor(126, 126, 126);
        pdf.setFont("times");
        pdf.setFontSize(10);
        pdf.text(15, 60, "NARFO Pty (Ltd) 2012/086864/07");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.setFontSize(12);
        pdf.text(20, 70, `Bank Name`);
         pdf.setFont("style","bold");
        pdf.text(70, 70, `${monthlyRepCommission[0].bankName}`);
        pdf.setFont("style","normal");
        pdf.text(20, 75, `Account Number`);
         pdf.setFont("style","bold");
        pdf.text(70, 75, `${monthlyRepCommission[0].accountNo}`);
        pdf.setFont("style","normal");
        pdf.text(20, 80, `Payment Method`);
         pdf.setFont("style","bold");
        pdf.text(70, 80, `${monthlyRepCommission[0].paymentMethod}`);
        pdf.setFont("style","normal");
        pdf.text(20, 85, `Account Type`);
         pdf.setFont("style","bold");
        pdf.text(70, 85, `${monthlyRepCommission[0].accountType}`);
        pdf.setFont("style","normal");
        pdf.text(20, 90, `Transaction Number`);
         pdf.setFont("style","bold");
        pdf.text(70, 90, `${monthlyRepCommission[0].paymentTransNo}`);
        pdf.setFont("style","normal");
        pdf.setFontSize(14);
        pdf.text(235, 90, `Payment Date`);
         pdf.setFont("style","bold");
        pdf.text(268, 90, `${DateFind(monthlyRepCommission[0].paymentDate.split("T")[0]).substring(2)}`);
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("times");
        pdf.setFontSize(14);
        pdf.text(100, 18, "National Association of Responsible Firearm Owners");
        pdf.text(140, 25, "of South Africa");
        pdf.line(70, 30, 295, 30);
        pdf.setTextColor(126, 126, 126);
        pdf.setFontSize(10);
        pdf.setFont("italic","italic");
        pdf.text(90, 37, "SAPS Accredited Association");
        pdf.setFont("normal","normal");;
        pdf.text(75, 45, "Hunting Association");
        pdf.setTextColor(0, 0, 0);
        pdf.text(115, 45, "FAR-1300135");
        pdf.setTextColor(126, 126, 126);
        pdf.text(75, 51, "Sport Shooting");
        pdf.setTextColor(0, 0, 0);
        pdf.text(115, 51, "FAR-1300134");
        pdf.text(75, 57, "http://www.narfo.co.za");
        pdf.setTextColor(126, 126, 126);
        pdf.text(115, 57, "Email:");
        pdf.setTextColor(0, 0, 0);
        pdf.text(125, 57, "info@narfo.co.za");
        pdf.setFontSize(8);
        pdf.setTextColor(126, 126, 126);
        pdf.text(164, 37, "14 Phesante Close, Graanendal");
        pdf.text(164, 41, "Durbanville");
        pdf.text(164, 45, "7551");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(11);
        pdf.text(220, 37, `${this.props.isAdmin?repDetails.firstname.toUpperCase():this.props.memberDetails.firstname.toUpperCase()} ${this.props.isAdmin?repDetails.surname.toUpperCase():this.props.memberDetails.surname.toUpperCase()}`);
        pdf.text(220, 41, `IDENTITY NUMBER: ${this.props.isAdmin?repDetails.idno.toUpperCase():this.props.memberDetails.idno.toUpperCase()}`);
        pdf.text(220, 45, `MEMBER NUMBER: ${this.props.isAdmin?repDetails.memNo.toUpperCase():this.props.memberDetails.memberNumber.toUpperCase()}`);
        pdf.text(220, 49, `MEMBER STATUS: ${this.props.isAdmin?UserStatus[repDetails.status].toUpperCase():UserStatus[this.props.memberDetails.userStatus].toUpperCase()}`);

        pdf.line(70, 62, 295, 62);
        pdf.setFont("style","normal");
        pdf.setFont("times");
        pdf.setFontSize(14);
        pdf.text(120, 70, `Branch`);
         pdf.setFont("style","bold");
        pdf.text(140, 70, `${monthlyRepCommission[0].branch}`);
        pdf.setFont("style","normal");
        pdf.text(225, 70, `${monthlyRepCommission[0].comType}`);
         pdf.setFont("style","bold");
        pdf.text(275, 70, `R${_totalCommission}`);
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(20);
        pdf.text(120, 90, "Commission Remmitance");
        pdf.autoTable({
          startY: finalY + 80,
          head: [
            [
              "Date Joined",
              "Member Number",
              "Firstname",
              "Surname",
              "Commission"
            ]
          ],
          body: arr.filter(a => {
            return a;
          }),
          footStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
          showFoot: 'lastPage',
          foot: [
              [
                  "",
                  "",
                  "",
                  "",
                  `R${_totalCommission}`
              ]
          ]
        });
        pdf.save("Commission.pdf");
      };
    addCommissionsDownloader = () => {
        this.commissionDoc.current.click();
    };
    render() {
        const monthlyRepCommission = this.state._monthlyRepCommission; 
        let _commissionNumber = "";
        const tableArray = numIterator => [
            !monthlyRepCommission[numIterator].paymentTransNo
              ? "null"
              : monthlyRepCommission[numIterator].paymentTransNo.toString(),
            !monthlyRepCommission[numIterator].comType
              ? "null"
              : monthlyRepCommission[numIterator].comType.toString(),
            !monthlyRepCommission[numIterator].repNo
              ? "null"
              : monthlyRepCommission[numIterator].repNo.toString(),
            !monthlyRepCommission[numIterator].repBranch
              ? "null"
              : monthlyRepCommission[numIterator].repBranch.toString(),
            !monthlyRepCommission[numIterator].repFirstname
              ? "null"
              : monthlyRepCommission[numIterator].repFirstname.toString(),
            !monthlyRepCommission[numIterator].repSurname
              ? "null"
              : monthlyRepCommission[numIterator].repSurname.toString(),
            !monthlyRepCommission[numIterator].bankName
              ? "null"
              : monthlyRepCommission[numIterator].bankName.toString(),
            !monthlyRepCommission[numIterator].accountNo
              ? "null"
              : monthlyRepCommission[numIterator].accountNo.toString(),
            !monthlyRepCommission[numIterator].accountType
              ? "null"
              : monthlyRepCommission[numIterator].accountType.toString(),
            !monthlyRepCommission[numIterator].commissionTotal
              ? "null"
              : `R${monthlyRepCommission[numIterator].commissionTotal.toString()}`
          ];
        const sum = monthlyRepCommission.reduce((totalCommission, _commission) => totalCommission + _commission.comission, 0);
        let _totalCommission = Math.round((sum + Number.EPSILON) * 100) / 100;
        const printAll = () => {
            const pdf = new jsPDF("l", "mm", [310, 210]);
            pdf.autoTable({ html: ".table" });
            let finalY = pdf.previousAutoTable.finalY;
            var arr = [];
            let count = 0;
            let sum = monthlyRepCommission.length;
            let commNoTemp = ""
            while (sum !== 0) {
              if(commNoTemp !== tableArray(count)[0])
                arr.push(tableArray(count));
              commNoTemp = tableArray(count)[0];
              count++;
              sum--;
            }
            pdf.addImage(this.props.narfo, "JPEG", 20, 22);
            pdf.setTextColor(126, 126, 126);
            pdf.setFont("times");
            pdf.setFontSize(10);
            pdf.text(15, 60, "NARFO Pty (Ltd) 2012/086864/07");
            pdf.setTextColor(0, 0, 0);
             pdf.setFont("style","bold");
            pdf.setFont("times");
            pdf.setFontSize(14);
            pdf.text(100, 18, "National Association of Responsible Firearm Owners");
            pdf.text(140, 25, "of South Africa");
            pdf.setFont("style","normal");
            pdf.line(70, 30, 295, 30);
            pdf.setTextColor(126, 126, 126);
            pdf.setFontSize(10);
            pdf.setFont("italic","italic");
            pdf.text(90, 37, "SAPS Accredited Association");
            pdf.setFont("normal","normal");;
            pdf.text(75, 45, "Hunting Association");
            pdf.setTextColor(0, 0, 0);
            pdf.text(115, 45, "FAR-1300135");
            pdf.setTextColor(126, 126, 126);
            pdf.text(75, 51, "Sport Shooting");
            pdf.setTextColor(0, 0, 0);
            pdf.text(115, 51, "FAR-1300134");
            pdf.text(75, 57, "http://www.narfo.co.za");
            pdf.setTextColor(126, 126, 126);
            pdf.text(115, 57, "Email:");
            pdf.setTextColor(0, 0, 0);
            pdf.text(125, 57, "info@narfo.co.za");
            pdf.setFontSize(8);
            pdf.setTextColor(126, 126, 126);
            pdf.text(164, 37, "14 Phesante Close, Graanendal");
            pdf.text(164, 41, "Durbanville");
            pdf.text(164, 45, "7551");
            pdf.setTextColor(0, 0, 0);
            pdf.setFontSize(14);
            pdf.text(210, 37, `Payment Date`);
             pdf.setFont("style","bold");
            pdf.text(250, 37, `${DateFind(monthlyRepCommission[0].paymentDate.split("T")[0]).substring(2)}`);
            pdf.line(70, 62, 295, 62);
            pdf.setFont("style","normal");
            pdf.setFont("times");
            pdf.autoTable({
              startY: finalY + 50,
              head: [
                [
                  "Com No",
                  "Com Type",
                  "Member No",
                  "Narfo Branch",
                  "Name",
                  "Surname",
                  "Bank",
                  "Account No",
                  "Account Type",
                  "Commission"
                ]
              ],
              body: arr.filter(a => {
                return a;
              }),
              footStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
              showFoot: 'lastPage',
              foot: [
                  [
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      `R${_totalCommission}`
                  ]
              ]
            });
            pdf.save("Commission.pdf");
          };
        return(
            <div className="container" style={{ marginTop: this.props.isAdmin && !this.props.isAdminMemView ?"60px":"0" }}>
                {monthlyRepCommission.length !== 0 && !this.props.isAdmin && !this.props.isAdminMemView ?
                    <GenericHeader 
                        title="Commission Remmitance"
                        showBackButton
                        backFunction={() => {
                            this.props.onCommissionTabChange("0");
                            this.props.onMothlyCommissionGet([]);
                        }}
                    /> : monthlyRepCommission.length !== 0 && this.props.isAdmin && !this.props.isAdminMemView  ?
                    <GenericHeader 
                        title="Commission Remmitance"
                        showBackButton
                        backFunction={() => {
                            this.props.history.push("/ProcessCommissionPayment");
                            this.props.onMothlyCommissionGet([]);
                        }}
                        showDownloadButton
                        text="PRINT ALL"
                        downloadFunction={() => this.addCommissionsDownloader()}
                    /> : 
                    <GenericHeader 
                        title="Commission Remmitance"
                        showBackButton
                        backFunction={() => {
                            this.props.onCommissionTabChange("0");
                            this.props.onMothlyCommissionGet([]);
                        }}
                    />}
                {monthlyRepCommission.length === 0 ? <p className="text-warning font-weight-bold">{`No Commission Payment Records Found`}</p> :
                <div>
                    <table className="mt-1 ml-3" style={{ width: "100%" }}>
                        <tbody>
                            <tr >
                                {isMobile ? null : <td style={{ width: "10%" }}><h5>Date</h5></td>}
                                {isMobile ? <td style={{ width: "15%" }}><h5>Type</h5></td> : <td style={{ width: "10%" }}><h5>Commission Type</h5></td>}
                                {isMobile ? <td style={{ width: "15%" }}><h5>Num</h5></td>: <td style={{ width: "10%" }}><h5>Commission No</h5></td>}
                                {isMobile ? null : <td style={{ width: "10%" }}><h5>Name</h5></td>}
                                <td style={{ width: "10%" }}><h5>Amount</h5></td>
                            </tr>
                            {monthlyRepCommission.map((com, index) =>
                                _commissionNumber !== com.paymentTransNo ?
                                <tr index={index}>
                                    {isMobile ? null : <td style={{ width: "10%" }}>{com.paymentDate.split("T")[0]}</td>}
                                    {isMobile ? <td style={{ width: "12%" }}>{com.comType.split(" ")[0]}</td>: <td style={{ width: "13%" }}>{com.comType}</td>}
                                    {isMobile ? <td style={{ width: "20%" }}>{_commissionNumber = com.paymentTransNo}</td>: <td style={{ width: "13%" }}>{_commissionNumber = com.paymentTransNo}</td>}
                                    {isMobile ? null : <td style={{ width: "13%" }}>{com.repFirstname}</td>}
                                    <td style={{ width: "4%" }}>{`R${com.commissionTotal}`}</td>
                                    <td style={{ width: "5%" }}>
                                        <button className="btn btn-warning"
                                            onClick={() => {
                                                this.commRecByComNo(com.paymentTransNo);
                                            }}
                                        >
                                            PRINT
                                        </button>
                                    </td>
                                    
                                </tr> : null
                            )}
                        </tbody>
                    </table>
                </div>}
                <button
                    onClick={printAll}
                    ref={this.commissionDoc}
                    style={{ display: "none" }}
                >
                    down
                </button>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        narfo: state.Base64ImagesReducer.base64Images.narfo,
        isAdminMemView: state.loginReducer.memberState.isAdminMemView,
        isAdmin: state.loginReducer.memberState.isAdmin,
        monthlyCommission: state.CommissionRecordReducer.monthlyCommission,
        memberDetails: state.loginReducer.memberObject,
        memLoyaltyRecord: state.LoyaltyDaysReducer.loyaltyRecord,
        MemberV: state.MemberViewReducer.MemberView,
        userDetails: state.loginReducer.userProfileDetails
    };
};
const mapDispatchToProps = dispatch => {
return {
    onMothlyCommissionGet: mCom =>
        dispatch({ 
            type: actionTypes.MONTHLY_COMMISSION, 
            monthlyCommission: mCom 
        }),
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onLoyaltyDaysTabChange: pageNum =>
        dispatch({ 
            type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
            currentLoyaltyDaysTab: pageNum 
        }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
        dispatch({
          type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
          currLmsTab: newCurrentLmsTab
        }),
    onCurrentEndorsementTabChange: newTabName =>
        dispatch({
          type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
          currTab: newTabName
        }),
    onCurrActivityTabChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: vname
        }),
    onCurrentLicenseTabChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: variableName
        }),
    onAddAccountDetailsChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          varValue: value,
          varName: variableName
        }),
    };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(MainSalesCommission);