import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {
    ListItem,
    ListItemIcon,
    ListItemText,
  } from "@material-ui/core";

export default function ListItemWithLists(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    return (
      <ListItem style={{padding:'3px', marginLeft:'55px'}}>
        <ListItemIcon style={{fontSize:"10px",fontWeight:'10', color:'#525A64'}}>
           <strong>o</strong>
        </ListItemIcon>

        <ListItemText
        style={matches ? {marginLeft:'-25px',color:'#525A64', paddingRight:'30px'} : {marginLeft:'-25px',color:'#525A64'} }
          primary={props.text}
        />
    </ListItem>
    );
}