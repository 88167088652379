import Axios from 'axios';
import * as EndPoints from "../../../../API/APIendpoint";

export const activityApproval = async (id, status, reason) => {
  const config = {
    url: `${EndPoints.BASE}${EndPoints.ACTIVITY_APPROVAL_WITH_BODY}${id}/${status}`,
    method: 'post',
    headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,},
    data: { reason }
  }
  return await Axios(config);
}

export const activityEmail = (emailParams) => {
  fetch(`${EndPoints.BASE}${EndPoints.ACTIVITY_EMAIL}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      },
      body: JSON.stringify(emailParams)
    }
  );
}