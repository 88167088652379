export const FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN = "FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN",
  FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS = "FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS",
  FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE = "FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE",
  TOGGLE_SHOW_CHILDREN = "TOGGLE_SHOW_CHILDREN"

export const POPULATION_WEEK_DATA = "POPULATION_WEEK_DATA",
  POPULATION_MONTH_DATA = "POPULATION_MONTH_DATA",
  POPULATION_YEAR_DATA = "POPULATION_YEAR_DATA",
  TOGGLE_LOADING = "TOGGLE_LOADING",
  UPDATE_WEEK_ERROR = "UPDATE_WEEK_ERROR",
  UPDATE_MONTH_ERROR = "UPDATE_MONTH_ERROR",
  UPDATE_YEAR_ERROR = "UPDATE_YEAR_ERROR"
