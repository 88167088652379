import Clear from "@material-ui/icons/Clear";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import { MEMBER_ASSESSMENT } from "../LMSAPIEndPoints";
import { calculateScore, showOption } from "./Utils";
export class TakeQuiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: {},
      seconds: 599,
      next: "Next",
      previous: "Previous",
      isQuizExist: false,
      start: false,
      numberOfQuestions: 0,
      currentQuestion: 0,
      questions: [],
      emailParam: {
        toEmail: this.props.userDetails.email,
        Subject: "",
        Body: null,
        Firstname: this.props.altMemberDetails.firstname,
        Surname:this.props.altMemberDetails.surname
      }
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.score = {
      scoreIndex: -1,
      scoreArrayIndex: [],
      scoreArrayValues: []
    };
    this.scoring = [];
    this.submit = false;
  }

  componentDidMount() {
    const questions = this.props.questions;
    if (questions.length > 0) {
      this.setState({
        isQuizExist: true,
        numberOfQuestions: questions.length,
        questions: questions,
        next: questions.length <= 1 ? "Submit" : "Next"
      });
      if (questions.length <= 1) {
        this.submit = true;
      }
    } else {
      this.setState({
        isQuizExist: false,
        questions: []
      });
    }
  }

  componentWillMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      time: timeLeftVar
    });
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    if (seconds === 0) {
      clearInterval(this.timer);
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  titleTimer = () => {
    return (
      <div className="row bg-light border-bottom ml-0 mr-0 mt-3 mb-2">
        <div className="d-flex justify-content-center align-items-center col-1 p-0">
          <button
            className="btn btn-black"
            onMouseDown={() => this.props.onCurrentLMSTabChange(1)}
          >
            <Clear />
          </button>
        </div>
        <div className="col-9 h6 p-0 m-0 d-flex align-items-center">
          {this.props.oneLesson.title} Quiz
        </div>
        <div className="col-2 h6  p-0 m-0 d-flex justify-content-center align-items-center">
          {`0${this.state.time.m} : ${this.state.time.s}`}
        </div>
      </div>
    );
  };

  isBeginQuiz = () => {
    return (
      <div className="row" align="center">
        <div className="col-12 pt-5">
          {this.state.isQuizExist ? (
            <button
              className="btn btn-warning font-weight-bold"
              onMouseDown={() => {
                this.setState({ start: true });
                this.startTimer();
              }}
            >
              Start Quiz
            </button>
          ) : (
            <div className="container">
              <div className="row">
                <div className="col-12" align="center">
                  <h5>There is currently no questions for this quiz.</h5>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  progressBar = () => {
    return (
      <Fragment>
        <div className="row">
          <div className="col-12 p-0">
            <div
              className="progress"
              style={{ borderRadius: `25px`, height: "10px" }}
            >
              <div
                className="progress-bar text-right text-light pb-2 pr-2"
                style={{
                  width: `${((this.state.currentQuestion + 1) /
                    this.state.numberOfQuestions) *
                    100}%`,
                  background: `#79c44a`
                }}
              />
            </div>
          </div>
        </div>

        <div className="row pb-4">
          <div className="col-12 p-0" align="right">
            Question {this.state.currentQuestion + 1} /{" "}
            {this.state.numberOfQuestions}
          </div>
        </div>
      </Fragment>
    );
  };

  postResults = () => {
    const results = {
      completion: this.props.score,
      courseMaterialId: this.props.oneLesson.pkCourseId,
      memNo: this.props.memberNumber
    };
    fetch(MEMBER_ASSESSMENT, {
      method: "POST",
      body: JSON.stringify(results),
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        "Content-Type": "application/json"
      },
    })
      .then()
      .then(() => {
        fetch(MEMBER_ASSESSMENT + "/" + this.props.memberNumber, {
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(res => res.json())
          .then(res => {
            this.props.onQuizCompletionChange(res.data);
          });
      });
  };
  navigateButtons = () => {
    return (
      <div className="row position-static">
        <div className="col-6 p-4" align="left">
          {this.state.currentQuestion > 0 ? (
            <button
              className="btn btn-outline-warning "
              style={{ width: "100px" }}
              onClick={() => {
                const current = this.state.currentQuestion - 1;
                this.setState({
                  currentQuestion: current,
                  next:
                    current + 1 >= this.state.numberOfQuestions
                      ? "Submit"
                      : "Next"
                });
              }}
            >
              {this.state.previous}
            </button>
          ) : null}
        </div>
        <div className="col-6 p-4" align="right">
          <button
            className="btn btn-outline-warning"
            style={{ width: "100px" }}
            onClick={() => {
              const question = this.state.questions[this.state.currentQuestion];
              const options = question.questionOptions;
              this.scoring[
                this.state.currentQuestion
              ] = this.calculatePercentage(
                question.type,
                question.totalScore,
                options
              );
              this.score = {
                scoreIndex: -1,
                scoreArrayIndex: [],
                scoreArrayValues: []
              };
              if (this.submit) {
                this.props
                  .onScoreChange(
                    parseInt(
                      (this.scoring.reduce((a, b) => a + b, 0) * 100) /
                        this.state.numberOfQuestions
                    )
                  )
                  .then(() => {
                    this.postResults();
                    this.props.onCurrentLMSTabChange(4);
                  })
                this.props.onResultsRecordChange(
                  this.scoring.map((percentage, index) => {
                    return {
                      question: index + 1,
                      score: percentage * 100
                    };
                  })
                );
              }
              const current =
                (this.state.currentQuestion + 1) % this.state.numberOfQuestions;
              this.setState({
                currentQuestion: current,
                next:
                  current + 1 >= this.state.numberOfQuestions
                    ? "Submit"
                    : "Next"
              });
              if (current + 1 >= this.state.numberOfQuestions) {
                this.submit = true;
              }
            }}
          >
            {this.state.next}
          </button>
        </div>
      </div>
    );
  };

  calculatePercentage(questionType, actualScore, options) {
    switch (questionType) {
      case 0:
        return (
          calculateScore(
            questionType,
            options.map(opt => opt.score),
            this.score.scoreIndex
          ) / actualScore
        );
      case 1:
        return (
          calculateScore(
            questionType,
            options.map(opt => opt.score),
            this.score.scoreArrayIndex
          ) / actualScore
        );
      case 2:
      case 3:
        return calculateScore(
          questionType,
          options.map(opt => opt.score),
          this.score.scoreArrayValues
        ) === actualScore
          ? 1
          : 0;
      default:
        return 0;
    }
  }

  showQuestion = () => {
    const question = this.state.questions[this.state.currentQuestion];
    const options = question.questionOptions;
    const questionPic = question.questionPictures;
    return (
      <div className="row p-0">
        <div className=" pl-2 p-0 text-justify col-sm-12 h6">
          {question.questionTitle}
        </div>
        {questionPic ? (
          <div className="text-center w-100 text-center mb-2 mt-2">
            <img src={questionPic.url} width="200px" height="200px" alt="Sorry" />
          </div>
        ) : null}
        <div className="p-0 text-justify col-sm-12 pl-4">
          {options.map((opt, index) =>
            showOption(
              question.type,
              opt.description,
              index,
              question.questionOptions.length,
              question.questionId,
              this.score,
              question.totalScore
            )
          )}
        </div>
      </div>
    );
  };

  isTimeUp = () => {
    if (this.state.seconds === 0) {
      return (
        <div
          className="container"
          style={{
            fontSize: "26px",
            color: "red",
            fontWeight: "600",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px"
          }}
        >
          Time is up
        </div>
      );
    }
    if (!this.state.start) {
      return this.isBeginQuiz();
    } else {
      return (
        <div className="container">
          {this.progressBar()}
          {this.showQuestion()}
          {this.navigateButtons()}
        </div>
      );
    }
  };

  render() {
    return (
      <div className="container" style={{ height: "100%" }}>
        {this.titleTimer()}
        {this.isTimeUp()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    oneLesson: state.lmsReducer.lesson,
    currentLMSpage: state.lmsReducer.currLmsTab,
    questions: state.lmsReducer.quizzes,
    score: state.lmsReducer.score,
    userDetails: state.loginReducer.userProfileDetails,
    courseDedicatedStatusType: state.lmsReducer.courseModuleName,
    altMemberDetails: state.loginReducer.memberObject,
    moduleId: state.lmsReducer.moduleId,
    loyaltyDaysObject: state.LoyaltyDaysReducer.loyaltyDaysObject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onQuizzesChange: newQuiz =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZZES,
        quizzes: newQuiz
      }),
    onScoreChange: newScore => {
      dispatch({
        type: LMS_ACTIONS.UPDATE_SCORE,
        score: newScore
      });
      return Promise.resolve();
    },
    onResultsRecordChange: newResultsRecord =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_RESULTS_RECORD,
        resultsRecord: newResultsRecord
      }),
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakeQuiz);
