import React from 'react';
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index } = props;

  return ( value === index &&
    <div id={`wrapped-tabpanel-${index}`} className="pt-6">
      {children}
    </div>
  );
}

TabPanel.defaultProps = {
  value: 0,
  index: 0,
};

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default TabPanel;
