import * as Types from "./DedicatedStatusApplicationActionTypes";
import { submitApplication } from "./DedicatedStatusApplicationAPI";

export const submitDSApplicationBeginAC = () => {
    return {
        type: Types.APPLICATION_BEGIN,
        DSApplicationState: {
            applicationStatus: {
                loading: true,
                error: null
            }
        }
    };
};

export const submitDSApplicationSuccessAC = (response) => {
    return {
        type: Types.APPLICATION_SUCCESS,
        DSApplicationState: {
            applicationStatus: {
                loading: false,
                response
            },
        }
    };
};

export const submitDSApplicationErrorAC = (error) => {
    return {
        type: Types.APPLICATION_ERROR,
        DSApplicationState: {
            applicationStatus: {
                loading: false,
                error
            }
        }
    };
};

export const submitDSApplication = (id, status, reason) => async (dispatch) => {
    dispatch(submitDSApplicationBeginAC());
    try {
        const response = await submitApplication(id, status, reason);
        dispatch(submitDSApplicationSuccessAC(response));
        return response;
    } catch (error) {
        dispatch(submitDSApplicationErrorAC(error));
    }
}

export const changeStep = (step) => (dispatch) => {
    dispatch({
        type: Types.CHANGE_STEP,
        DSApplicationState: {
            currentStep: step
        }
    });
}

export const toggleDSType = (dsType) => (dispatch) => {
    dispatch({
        type: Types.TOGGLE_DS_TYPE,
        DSApplicationState: { dsType }
    });
}

export const setValidationError = (canSubmit) => (dispatch) => {
    dispatch({
        type: Types.SET_VALIDATION_ERRORS,
        DSApplicationState: { canSubmit }
    });
}

export const setDSVariables = (key, value) => (dispatch) => {
    dispatch({
        type: Types.SET_DS_VARIABLES,
        DSApplicationState: {
            [key]: value
        }
    });
}


export const clearState = () => (dispatch) => {
    dispatch({
        type: Types.CLEAR_STATE
    });
}

export const redirectToDSList = () => (dispatch) => {
    dispatch({
        type: "REDIRECT_TO_DS_LIST",
        dedictedStatusReducer: {
            dedicatedStatus: {
                Pages: "Home"
            }
        }
    })
}