import * as actionTypes from "../actions/actionTypes";

const initialState = {
    commInfo: {},
    currentCommissionTab: "0",
    commissionRecord: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_REP_NUM:
        return {
            ...state,
            commInfo: action.commInfo
        };
    case actionTypes.ON_COMMISSION_REC_UPDATE:
        return {
            ...state,
            commissionRecord: action.commissionRecord
        };
    case actionTypes.UPDATE_COMMISSION_TAB:
        return {
            ...state,
            currentCommissionTab: action.currentCommissionTab
        }
    default:
      return state;
  }
};
export default reducer;
