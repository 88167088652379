export const CTRUE = "true";
export const CFALSE = "false";
export const STATUS = {
  Approved: "Approved",
  Pending: "Pending",
  Declined: "Declined",
  Terminated: "Terminated"
};

export const memSTATUS = {
  Approved: 1,
  Pending: 2,
  Rejected: 3,
  Renewing: 4
};

export const PAGE = {
  home: "Home",
  edit: "Edit",
  delete:"Delete"
};
export const LINK =
  "https://www.payfast.co.za/eng/process?cmd=_paynow&receiver=12911829&item_name=NARFO&item_description=NARFO+Renewal+Of+Membership&amount=490.00&return_url=http%3A%2F%2Fgoogle.com&cancel_url=http%3A%2F%2Fwikipedia.org";
