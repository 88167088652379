import React from "react";
import { Link } from "react-router-dom";
function DashBoardRowItems(props) {
    return(
        <Link to={props.dItemLinkSection}>
            <div className="row border p-2 mt-3">
                <div className="col-12">
                    <div className="row">
                    <div className="col-4 pt-1">
                        <img src={props.dItemImage} alt="Star" className="pendingsIcons" />
                    </div>
                    <div className="col-8">
                        <div className="row">
                        <div className="col-12" align="right">
                            {props.dItemName}
                        </div>
                        <div
                            className="col-12 text-warning font-weight-bold"
                            align="right"
                        >
                            {props.dItemCount}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}
export default DashBoardRowItems;