import React, { Component } from "react";
import "./styles/styles.css";

class memberApplicationProgress extends Component {

  numberCircle = (number, text, activity, noLine) => {
    let condition = "";
    switch (activity) {
      case -1:
        condition = "";
        break;
      case 0:
        condition = "active";
        break;
      case 1:
        condition = "was-active";
        break;
      default:
        condition = "";
    }
    return (
      <>
        {noLine ? null : <hr className={condition} />}
        <div className="circle-parent">
          <div className={`number-circle ${condition}`}>{number}</div>
          <label className="number-circle-text">{text}</label>
        </div>
      </>
    );
  }

  evaluate = (num) => {
    if(!num && num !== 0){
      return null;
    }
    if (num < 0) {
      return -1;
    } else if (num > 0) {
      return 1;
    } else {
      return 0;
    }
  }

  render() {
    return (
      <div className="member-application-progress-root">
        <h4>Membership Application</h4>
        <div className="circle-container">
          {this.numberCircle(1, "Details", this.evaluate(parseInt(this.props.progress) - 1), true)}
          {this.numberCircle(2, "Address", this.evaluate(parseInt(this.props.progress) - 2))}
          {this.numberCircle(3, "Declaration", this.evaluate(parseInt(this.props.progress) - 3))}
          {this.numberCircle(4, "Sales Details", this.evaluate(parseInt(this.props.progress) - 4))}
          {this.numberCircle(5, "Login Details", this.evaluate(parseInt(this.props.progress) - 5))}
        </div>
      </div>
    );
  }
}


export default memberApplicationProgress;