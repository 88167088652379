import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { BASE, GET_ADDLICENCE_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as constFile from "../../../store/constantFile/ConstFile";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginTop: "1%",
    marginLeft: "20%",
    marginRight: "20%",
    width:"60%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginTop: "1%",
    marginLeft: "20%",
    marginRight: "20%",
    width:"60%",
    flex: 1,
    margin: 5
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: "5px",
    backgroundColor: "white",
    color: "black",
    borderColor:"#000000",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50,
    '&:hover': {
      backgroundColor: "#F5A623",
      color:'White',
      border:"none"
    }, 
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class AddLicenseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid:false,
      showValid:true   
    };
  }

  handleSubmit = () => {
    this.checkNotEmpty()
    if(this.state.isValid 
      && !(this.props.newlicenceDetails.LicenseName ==="")
      && !(this.props.newlicenceDetails.LicenseNo === "")
      && !(this.props.newlicenceDetails.IssueDate === "")
      && !(this.props.newlicenceDetails.ExpiryDate === "")){

    const JSONbody = {
        LicenseName: this.props.newlicenceDetails.LicenseName,
        LicenseNo: this.props.newlicenceDetails.LicenseNo,
        IssueDate: this.props.newlicenceDetails.IssueDate,
        ExpiryDate: this.props.newlicenceDetails.ExpiryDate,
        MemNo: (this.props.memberState.isAdmin
          ? this.props.MemberV.memNo
          : this.props.userDetails.memberNumber),
        Custom:constFile.CTRUE   
    } 
    Axios.post(BASE + GET_ADDLICENCE_URL,JSONbody,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
  }

  };

  handleChange = name => event => {
    this.props.onLicenceChange(event.target.value, [name]);
    this.setState({showValid:true})
    this.setState({isValid:false})
    this.checkNotEmpty()
  };

  checkNotEmpty = () => {
    let V = true;
      if (this.props.newlicenceDetails.LicenseName === "" ||
      this.props.newlicenceDetails.LicenseName === null || 
      this.props.newlicenceDetails.LicenseName === undefined || 
      this.props.newlicenceDetails.LicenseNo === "" ||
      this.props.newlicenceDetails.LicenseNo === null || 
      this.props.newlicenceDetails.LicenseNo === undefined ||
      this.props.newlicenceDetails.IssueDate === "" ||
      this.props.newlicenceDetails.IssueDate === null || 
      this.props.newlicenceDetails.IssueDate === undefined ||
      this.props.newlicenceDetails.ExpiryDate === "" ||
      this.props.newlicenceDetails.ExpiryDate === null || 
      this.props.newlicenceDetails.ExpiryDate === undefined )
        {V = false;}
    this.setState({
      isValid: V
    });
  }

  componentDidMount()
  {
    this.props.onLicenceChange("","Firearm")
    this.props.onLicenceChange("","Make")
    this.props.onLicenceChange("","Model")
    this.props.onLicenceChange("","Caliber")
    this.props.onLicenceChange("","SerialNumber")
    this.props.onLicenceChange("","LicenseNo")
    
    this.setState({showValid:false})

  ValidatorForm.addValidationRule("isEmpty", value => {
    if(this.state.showValid){
    if (!value) {
      return false;
    }
    if (value.length < 0) {
      return false;
    }}
    return true;
  });

}

  render() {
    const { classes } = this.props;

    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off" onSubmit = {(e)=>{e.preventDefault()}} >
        <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit = {this.handleSubmit} 
          >
        <TextValidator
            id="OutlinedSection"
            label="Enter License Name"
            className={classes.textField}
            value={this.props.newlicenceDetails.LicenseName}
            onChange={this.handleChange("LicenseName")}
            name="LicenseName"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter the License Name",
              "This field is required"
            ]}
            margin="normal"
            variant="outlined"
          />  
          <TextValidator
            id="OutlinedSection"
            label="License Number"
            className={classes.textField}
            value={this.props.newlicenceDetails.LicenseNo}
            onChange={this.handleChange("LicenseNo")}
            name="LicenseNo"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter the License Number",
              "This field is required"
            ]}
            margin="normal"
            variant="outlined"
          />  
          <TextValidator
            id="OutlinedSection"
            label="Issue Date"
            className={classes.textField}
            value={this.props.newlicenceDetails.IssueDate}
            onChange={this.handleChange("IssueDate")}
            name="IssueDate"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter the Issue Date",
              "This field is required"
            ]}
            InputLabelProps={{
              shrink: true
            }}
            type="date"
            margin="normal"
            variant="outlined"
          />  

          <TextValidator
            id="OutlinedSection"
            label="Expiry Date"
            className={classes.textField}
            value={this.props.newlicenceDetails.ExpiryDate}
            onChange={this.handleChange("ExpiryDate")}
            name="ExpiryDate"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter the Expiry Date",
              "This field is required"
            ]}
            InputLabelProps={{
              shrink: true
            }}
            type="date"
            margin="normal"
            variant="outlined"
          />  
          <div className={classes.footer}>
            <button
              variant="contained"
              className={classes.button}
              onClick={() => this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")}
            >
              Previous
            </button>
            <div className={classes.grow} />
            <button
              variant="contained"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Add
            </button>
          </div>
          </ValidatorForm>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currLicenseTab: state.membersAreaReducer.currentLicenseTab,
    userDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {

    onCurrentLicenseTabChange: (value, variableName) =>
    dispatch({
      type: actionTypes.UPDATE_MEMBERS_AREA,
      varValue: value,
      varName: variableName
    }),

    onLicenceChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddLicenseDetails));
