import * as Types from "./ApproveNewDSActionTypes";
import { approveDSApplication, sendApprovalEmailDH, sendApprovalEmailDS, sendRejectDSEmail } from "./ApproveNewDSAPI";

export const approvePendingDSBeginAC = () => {
  return {
    type: Types.APPROVE_DS_BEGIN,
    approveNewDSState: {
      approvalStatus: {
        loading: true,
        error: null
      }
    }
  };
};

export const approvePendingDSSuccessAC = response => {
  return {
    type: Types.APPROVE_DS_SUCCESS,
    approveNewDSState: {
      approvalStatus: {
        loading: false,
        response
      }
    }
  };
};

export const approvePendingDSErrorAC = error => {
  return {
    type: Types.APPROVE_DS_ERROR,
    approveNewDSState: {
      approvalStatus: {
        loading: false,
        error
      }
    }
  };
};

export const sendEmailBeginAC = () => {
  return {
    type: Types.EMAIL_BEGIN,
    approveNewDSState: {
      emailStatus: {
        loading: true,
        error: null
      }
    }
  };
};

export const sendEmailSuccessAC = response => {
  return {
    type: Types.EMAIL_SUCCESS,
    approveNewDSState: {
      emailStatus: {
        loading: false,
        response
      }
    }
  };
};

export const sendEmailErrorAC = error => {
  return {
    type: Types.EMAIL_ERROR,
    approveNewDSState: {
      emailStatus: {
        loading: false,
        error
      }
    }
  };
};

export const sendEmailDS = emailParam => async dispatch => {
  dispatch(sendEmailBeginAC());
  try {
    const response = await sendApprovalEmailDS(emailParam);
    dispatch(sendEmailSuccessAC(response));
    return await response;
  } catch (error) {
    dispatch(sendEmailErrorAC(error));
  }
};

export const sendEmailDH = emailParam => async dispatch => {
  dispatch(sendEmailBeginAC());
  try {
    const response = await sendApprovalEmailDH(emailParam);
    dispatch(sendEmailSuccessAC(response));
    return await response;
  } catch (error) {
    dispatch(sendEmailErrorAC(error));
  }
};

export const sendDedRej = emailParam => async dispatch => {
  dispatch(sendEmailBeginAC());
  try {
    const response = await sendRejectDSEmail(emailParam);
    dispatch(sendEmailSuccessAC(response));
    return await response;
  } catch (error) {
    dispatch(sendEmailErrorAC(error));
  }
};

export const approvePendingDS = (id, status, reason) => async dispatch => {
  dispatch(approvePendingDSBeginAC());
  try {
    const response = await approveDSApplication(id, status, reason);
    dispatch(approvePendingDSSuccessAC(response));
    return response;
  } catch (error) {
    dispatch(approvePendingDSErrorAC(error));
  }
};

export const setCurrentDSAC = (application, user) => {
  return {
    type: Types.SET_CURRENT_DS_APPLICATION,
    approveNewDSState: { application, user }
  };
};
export const setCurrentDS = (application, user) => dispatch =>
  dispatch(setCurrentDSAC(application, user));
