import React, { Fragment } from "react";
import "./styles/navigatorButtonStyles.css";

export function NextPageButton(props) {

  return (
    <button 
      onClick={props.onClick} 
      className="navigate-next-button"
    >
      Next
    </button>
  );
}

export function SubmitButton(props) {

  return (
    <button 
      onClick={props.onClick} 
      className="navigate-next-button"
    >
      Submit
    </button>
  );
}

export function PreviousPageButton(props){

  return (
    <button className="navigate-prev-button">
      Previous
    </button>
  );
}

export function POPIActInfo(){
  return (
    <Fragment>
      <p>
        You are about to join the National Association of Responsible Firearm Owners.
        In order to become a member, we need to store certain personal information as supplied by yourself.
        The information we store is used to maintain your profile, used on reports and certificates you 
        generate from the system, for our use in doing some statistical analysis and if you opt to become a 
        dedicated hunter or sports person, NARFO must comply with the stipulations of Regulation 2(7), 2(8), 
        and 4(2)(a) of the Firearms Control Act, 2000 (Act 60 of 2000 as amended).
      </p>
      <p>
        NARFO embraces the digital age and therefore our only presence is online and we only communicate via 
        electronic means. Our platform is designed that members have the facility to administer their 
        own profile online. If you do not have access to any electronic form of communication, we urge you not 
        to join as you will not have access to any of the online content like you profile, new letters, 
        general information and account payment details.
      </p>
      <p>
        By continuing, you automatically provide NARFO consent to store the information you supply on our 
        system. 
      </p>
    </Fragment>
  );
}
