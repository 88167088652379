import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as StoreLocatorActions from "./storeLocatorActions";
import FadeLoader from "react-spinners/FadeLoader";

class StoreLocator extends Component {
  constructor(props) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount() {
    this.props.StoreLocatorActions.getBranches();
  }

  handleSelectChange = e => {
    let city = e.target.value;
    let url = this.props.storeLocatorState.url.replace(
      this.props.storeLocatorState.city,
      city
    );
    this.props.StoreLocatorActions.updateSearchString(url, city);
  };

  render() {
    if (this.props.storeLocatorState.loading)
      return (
        <div className="d-flex justify-content-center mt-5">
          <FadeLoader color="orange" margin="auto" />
        </div>
      );
    return (
      <div
        className="container pr-5 pl-5"
        style={{
          marginBottom: "10px",
          height: "100%"
        }}
      >
        <h3 align="center" className="font-weight-bold p-3 mb-0">
          Partners
        </h3>
        <div className="row" align="center">
          <p>
            Find a partners near you that offers competency training, shooting range, gunsmith, rifle sure, finance, loyalty progrmas, motivations,
            sports shooting activities, hunting opertunities and more.
          </p>
        </div>
        <p align="center" className="p-3 mb-0">
          Choose City
        </p>
        <div className="container" align="center">
          <div className="col-md-4">
            <select
              className="form-control input-sm m-0"
              name="branches"
              label="Number of questions"
              onChange={e => this.handleSelectChange(e)}
            >
              <option value="Select Store">Select Store</option>
              {this.props.storeLocatorState.branches.map((branch, index) => (
                <option id="numberOptions" key={index} value={branch.adress}>
                  {branch.store}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3" styles={{ height: "100%" }}>
          <iframe
            title="Store Locator"
            width="100%"
            height="700px"
            frameBorder="0"
            styles={{ border: 0 }}
            src={this.props.storeLocatorState.url}
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    storeLocatorState: state.storeLocatorState
  };
};

const mapActionsToProps = dispatch => ({
  StoreLocatorActions: bindActionCreators(StoreLocatorActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(StoreLocator);
