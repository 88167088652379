// MapComponent.js
import React from 'react';
// @ts-ignore
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Import marker images
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Fix marker icon issues with Webpack
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const LeafMaps = (props) => {
  // const position = [-33.8143223,18.6748262]; // Coordinates for the map center
  return (
    <MapContainer center={props.position} zoom={12.4} style={props.containerStyleMobile}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={props.position}>
        <Popup>
          {props.partner.adress} <br/>
          <a href={props.partner.website} target="_blank">{props.partner.website}</a> 
        </Popup>
      </Marker>
    </MapContainer>
  );
};

export default LeafMaps;
