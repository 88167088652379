
import "./chartStyles.css";
import React, { PureComponent, Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import SkipPrevious from "@material-ui/icons/SkipPrevious";
import SkipNext from "@material-ui/icons/SkipNext";
import Loader from "react-spinners/FadeLoader";

export default class BarGraph extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      length: 0,
      index: 0,
      dataRange: {},
      dataRangeText: ""
    }
    this.gridColor = "#d5d3d3";
    this.textColor = "#2c2c2c";
    this.data = [];

    this.options = {
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            fontColor: this.textColor,
            major: {
              fontStyle: 'bold',
            }
          },
          gridLines: {
            display: false
          }
        }],
        yAxes: [{
          display: true,
          ticks: {
            fontColor: this.textColor,
            beginAtZero: true,
            min: 0,
            max: 100,
            stepSize: 25,
            callback: function (value) {
              return (value + '%  ');
            }
          },
          gridLines: {
            drawBorder: false,
            color: this.gridColor
          }
        }],
        maintainAspectRatio: false
      }
    }
  }

  initialData = () => {
    const dataArray = this.props.data ? this.props.data : [];
    const data = dataArray[0] ? dataArray[0] : {};
    this.data = dataArray;
    this.setState({
      length: dataArray.length,
      dataRange: this.formatGraphData(this.props.label, data.completion, data.success),
      dataRangeText: data.dateRange,
      index: 0
    })
  }


  componentDidMount() {
    this.initialData();
  }

  isEqual = (current, next) => {
    let currCompletion;
    let nextCompletion;
    try {
      currCompletion = current.datasets[0].data;
      nextCompletion = next.datasets[0].data;
    }
    catch {
      return true;
    }
    if (JSON.stringify(currCompletion) === JSON.stringify(nextCompletion)) {
       return this.props.load;
    }
    return true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextProps.load){
      return true;
    }
    const currentDataRange = this.state.dataRange;
    const nextDataRange = nextState.dataRange;

    if(this.state.index !== nextState.index){
      return true;
    }

    return this.isEqual(currentDataRange, nextDataRange);
  }

  changeData = (sign) => {
    const dataArray = this.data;
    const index = (this.state.index + sign) % dataArray.length;
    const data = dataArray[index] ? dataArray[index] : {};

    setTimeout(() => {
      this.forceUpdate(() => {
        this.setState({
          dataRange: this.formatGraphData(this.props.label, data.completion, data.success),
          dataRangeText: data.dateRange,
          index: index,
          length: dataArray.length
        })
      })
    }, 2);
  }

  formatGraphData = (label, completion, success) => {
    let lbl = [];
    if (typeof label === "function") {
      try {
        lbl = label(completion.length === 0 ? 30 : completion.length)
      }
      catch {
        lbl = []
      }
    } else {
      lbl = label
    }
    const data = {
      labels: lbl,
      datasets: [
        {
          data: completion,
          fill: true,
          borderColor: '#fab22d',
          backgroundColor: '#fab22d'
        },
        {
          data: success,
          fill: true,
          borderColor: '#ffe365',
          backgroundColor: '#ffe365'
        }
      ]
    }
    return data;
  }

  displayDataRangeButtons = () => {
    return (
      <div className="chart-date-range-buttons">
        <SkipPrevious
          style={{ visibility: `${this.state.index > 0 ? "" : "hidden"}` }}
          onClick={() => { this.changeData(-1) }} />
        <SkipNext
          style={{ visibility: `${(this.state.index < (this.state.length - 1)) ? "" : "hidden"}` }}
          onClick={() => { this.changeData(1) }} />
      </div>
    );
  }

  display = () => {
    if (this.props.load) {
      setTimeout(() => {
        this.initialData()
      }, 20);
      return <Loader color={'#fab22d'} />;
    }
    return <Bar options={this.options} data={this.state.dataRange} redraw />;
  }

  render() {
    return (
      <Fragment>
        <div className="chart-date-range">
          <div className="chart-date-range-text">{this.state.dataRangeText}</div>
          {this.displayDataRangeButtons()}
        </div>
        <div className="chart-container">
          {this.display()}
        </div>
        <div className="chart-legend">
          <div className="chart-legend-property">
            <label className="circle" style={{ backgroundColor: "#fab22d" }} /><label>Completion</label>
          </div>
          <div className="chart-legend-property">
            <label className="circle" style={{ backgroundColor: "#ffe365" }} /><label>Success</label>
          </div>
        </div>
      </Fragment>
    );
  }
}
