import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { BASE } from '../../../API/APIendpoint';
import Grid from '@material-ui/core/Grid';
import { isMobile } from "react-device-detect";
import MapsContainer from "../MapsContainer/MapsContainer"

function ShootingRangeLocations() {
    const [shootingRanges, setShootingRanges] = useState([]);
    useEffect(() => {
        axios({
            url: `${BASE}api/ShootingRanges`,
            method: 'get'
        }).then(res => {
            setShootingRanges(res.data)
        })
    }, [])
    return (

        <React.Fragment>
        <Grid style={{height:"770px"}}>
            
            <MapsContainer partners = {shootingRanges} zoom = {isMobile ? 5.0 : null}/>
        </Grid>
        </React.Fragment>
    );
}
export default connect(
    null,
    null
)(withRouter(ShootingRangeLocations));