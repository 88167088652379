import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_SPEC_LIST } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import License from "./License";
import jsPDF from "jspdf";
import "jspdf-autotable";
const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  }
};
const UserStatus = Object.freeze({ 1: "Approved", 2: "Pending", 3: "Rejected", 4: "Renewing" })
class LicensedFireArms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: null,
      narfoLogo: "",
      _dss: "",
      _dhs: ""
    };
    this.toPDF = React.createRef();
  }
  componentDidMount() {
    axios({
      url: `${BASE}api/DedicatedStatus/approvedDS/${this.props.memberDetails.memberNumber}`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      res.data.forEach(element =>{
        if(element.dstype === "Dedicated Sports Person"){
          this.setState({
            _dss: element.dsno
          })
        }
        if(element.dstype === "Dedicated Hunter"){
          this.setState({
            _dhs: element.dsno
          })
        }
      })
    })
    .catch(function (error) { });
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    axios
      .get(
        BASE +
        GET_SPEC_LIST +
        (this.props.memberState.isAdmin
          ? this.props.MemberV.memNo
          : this.props.userDetails.memberNumber)
        , {
          headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
        }
      )
      .then(res => {
        var licenses = res.data;
        this.props.onUserLicenseChange(licenses);
      });
  }
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }
  pdfDownloader() {
    this.toPDF.current.click();
  }
  render() {
    const licences = this.props.LicenceList;
    const tableArray = numIterator => [
      !licences[numIterator].licenseNo
        ? "null"
        : licences[numIterator].licenseNo.toString(),
      !licences[numIterator].serialNumber || licences[numIterator].serialNumber.toString().substring(0, 3) === "CSN"
        ? "null"
        : licences[numIterator].serialNumber.toString(),
      !licences[numIterator].firearm
        ? "null"
        : licences[numIterator].firearm.toString(),
      !licences[numIterator].caliber
        ? "null"
        : licences[numIterator].caliber.toString(),
      !licences[numIterator].make
        ? "null"
        : licences[numIterator].make.toString(),
      !licences[numIterator].model || licences[numIterator].model.toString() === "000000"
        ? "null"
        : licences[numIterator].model.toString(),
      !licences[numIterator].section
        ? "null"
        : licences[numIterator].section.toString(),
      !licences[numIterator].issueDate
        ? "null"
        : licences[numIterator].issueDate.toString().substring(0, 10),
      !licences[numIterator].expiryDate
        ? "null"
        : licences[numIterator].expiryDate.toString().substring(0, 10)
    ];
    const print = () => {
      const pdf = new jsPDF("l", "mm", [310, 210]);
      pdf.autoTable({ html: ".table" });
      let finalY = pdf.previousAutoTable.finalY;
      var arr = [];
      let count = 0;
      let sum = licences.length;
      while (sum !== 0) {
        arr.push(tableArray(count));
        count++;
        sum--;
      }
      pdf.addImage(this.props.base64Images.nnfo, "JPEG", 13, 15);
      pdf.setTextColor(126, 126, 126);
      pdf.setFont("times");
      pdf.setFontSize(10);
      pdf.text(15, 60, "NARFO Pty (Ltd) 2012/086864/07");
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("times");
      pdf.setFontSize(14);
      pdf.setFontStyle("bold");
      pdf.text(100, 18, "National Association of Responsible Firearm Owners");
      pdf.text(140, 25, "of South Africa");
      pdf.setFontStyle("normal");
      pdf.line(70, 30, 295, 30);
      pdf.setTextColor(126, 126, 126);
      pdf.setFontSize(10);
      pdf.setFontType("italic");
      pdf.text(90, 37, "SAPS Accredited Association");
      pdf.setFontType("normal");
      pdf.text(75, 45, "Hunting Association");
      pdf.setTextColor(0, 0, 0);
      pdf.text(115, 45, "FAR-1300135");
      pdf.setTextColor(126, 126, 126);
      pdf.text(75, 51, "Sport Shooting");
      pdf.setTextColor(0, 0, 0);
      pdf.text(115, 51, "FAR-1300134");
      pdf.text(75, 57, "http://www.narfo.co.za");
      pdf.setTextColor(126, 126, 126);
      pdf.text(115, 57, "Email:");
      pdf.setTextColor(0, 0, 0);
      pdf.text(125, 57, "info@narfo.co.za");
      pdf.line(70, 62, 295, 62);
      pdf.setFontSize(8);
      pdf.setTextColor(126, 126, 126);
      pdf.text(164, 37, "14 Phesante Close, Graanendal");
      pdf.text(164, 41, "Durbanville");
      pdf.text(164, 45, "7551");
      pdf.setTextColor(0, 0, 0);
      pdf.text(220, 37, `${this.props.memberDetails.firstname.toUpperCase()} ${this.props.memberDetails.surname.toUpperCase()}`);
      pdf.text(220, 41, `IDENTITY NUMBER: ${this.props.memberDetails.idno.toUpperCase()}`);
      pdf.text(220, 45, `MEMBER NUMBER: ${this.props.memberDetails.memberNumber.toUpperCase()}`);
      pdf.text(220, 49, `MEMBER STATUS: ${UserStatus[this.props.memberDetails.userStatus].toUpperCase()}`);
      if(this.state._dhs !== "" && this.state._dss !== "") {
        pdf.text(220, 53, `DEDICATED HUNTER NUMBER: ${this.state._dhs}`);
        pdf.text(220, 57, `DEDICATED SPORTS PERSON NUMBER: ${this.state._dss}`);
      }
      if(this.state._dhs !== "" && this.state._dss === "") {
        pdf.text(220, 53, `DEDICATED HUNTER NUMBER: ${this.state._dhs}`);
      }
      if(this.state._dhs === "" && this.state._dss !== "") {
        pdf.text(220, 53, `DEDICATED SPORTS PERSON NUMBER: ${this.state._dss}`);
      }
      pdf.setFontStyle("bold");
      pdf.setFontSize(20);
      pdf.text(115, 70, "Member Firearm Licenses");
      pdf.setFontStyle("normal");
      pdf.autoTable({
        startY: finalY + 60,
        head: [
          [
            "License Number",
            "Serial Number",
            "Firearm",
            "Caliber",
            "Make",
            "Model",
            "Section",
            "Issue Date",
            "Expiry Date"
          ]
        ],
        body: arr.filter(a => {
          return a;
        })
      });
      pdf.save("Licenses.pdf");
    };
    return (
      <div className="container">
        <div className="activity-header-container">
          <GenericHeader
            title="License Reminder"
            showDownloadButton
            downloadFunction={() => this.pdfDownloader()}
            showFilledAddButton
            text="Licenses PDF"
            showFilledAddButton
            addFunction={() => {
              this.props.onCurrentLicenseTabChange("4", "currentLicenseTab");
            }}
          />
          <button onClick={print} ref={this.toPDF} hidden="hidden">
            licensePDF
          </button>
        </div>
        <div className="container">
          <div className="row pl-3 pr-3">
              Please note that it remains the responsibility of each member to timeously 
              renew their firearm license in accordance with the Firearms Control Act 60 of 2000
              and the Regulations. The system was designed to send you a reminder email at 120 days 
              before the expiry of the specific license. NARFO does not take any responsibility for 
              any reminder that the member failed to receive or a failure of the system.
          </div>
          <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
            <h6>Firearm License Reminder List</h6>
          </div>
        </div>
        <div>
          {this.props.LicenceList.length > 0 ? (
            this.props.LicenceList.map((licence, index) => (
              <License key={index} licenceArray={licence} />
            ))
          ) : (
              <p className="text-warning font-weight-bold">Licenses not yet logged...</p>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    licenceDetails: state.licensesReducer.licence,
    LicenceList: state.licensesReducer.LicenceList,
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    memberState: state.loginReducer.memberState,
    memberDetails: state.loginReducer.memberObject
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({
        type: actionTypes.UPDATE_LOYALTY_DAYS_TAB,
        currentLoyaltyDaysTab: pageNum
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onUserLicenseChange: newUserLicensesArray =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE_LIST,
        payload: newUserLicensesArray
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LicensedFireArms));
