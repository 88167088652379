import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { BASE, GET_ALL_MEMBER_ACTIVITIES } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import { DASHBOARD_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { styles } from "../../../utils/styles/styles";
import * as PendingActivityActions from "./PendingActivitySubComponents/PendingActivityActions";
import Axios from "axios";

class PendingActivityApplication extends Component {
  constructor(props) {
    super(props);
    // this.findMember = this.findMember.bind(this);
    this.state = {
      member : null
    }
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }

  }
  componentWillMount() {
    axios.get(BASE + GET_ALL_MEMBER_ACTIVITIES + `/${this.props.memberV.memNo}`,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(response => {
        this.props.onStatusAChange(response.data);
      });

      // Axios({
      //   url: BASE + `api/Member/FindMemberByMemNo/${this.props.memberV.memNo}`,
      //   method: "get",
      //   headers: {
      //     "Content-type": "application/json",
      //     'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      //   }
      // }).then(res => {
      //   res.data
      //       this.setState({member : res.data});
      //   });
            
  }

  handleSubmit = (member, activity) => {
    this.props.PendingActivityActions.setMemberActivity(member, activity);
    this.props.onCurrActivityTabChange(1);
  };

  //TODO: will come back to the sorting
  sortByFirstName = array => {
    return array.sort((a, b) =>
      a.firstname > b.firstname ? 1 : b.firstname > a.firstname ? -1 : 0
    );
  };

  render() {
    return (
      <div className={this.props.isPartner? "" :"container"}>
        <div className="row mb-2  mt-2">
          <GenericHeader
            title="Main Activities"
            //showBackButton
            
            headerColor={this.props.headerColor}
            backFunction={() => this.props.onCurrActivityTabChange(0)}
          />
        </div>

        {this.props.activities.length === 0 ? (
          <div>There are currently no pending activities ...</div>
        ) : (
          <ul className="ml-0 pl-1">
            {this.props.activities.map((activity, index) => {
              if(this.state.member)
              return null
              return (
                <div
                  key={index}
                  className="row h-4 mb-2 border-bottom pb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => this.handleSubmit(this.state.member, activity)}
                >
                  <div className="row pl-2" style={{ fontWeight: "bold" }}>
                    <div className="col-12 pl-4 pt-3 " align="left">
                      <span>{activity.activityDescription}</span>
                      <div>{activity.activityType}</div>
                      <div>{`Activity Date: ${activity.activityDate.split('T', 1)}`}</div>
                      <div>{`Firearm Type: ${activity.firearmType}`}</div>
                      {activity.status === "Rejected" ? (
                        <div className="text-danger">Rejected</div>
                      ) : activity.status === "Pending" ? (
                        <div className="text-warning">Pending</div>
                      ) : (
                        <div className="text-success">Approved</div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    memberV: state.MemberViewReducer.MemberView,
    activities: state.dashBoardReducer.activities.activities,
    memberList: state.dashBoardReducer.totalMembers.members,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn
  };
};

const bindActionsToProps = dispatch => {
  return {
    onStatusAChange: _activities =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_ACTIVITY_OBJECT,
        activities: {
          pendingCount: _activities.length,
          activities: _activities
        }
      }),
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      }),
    PendingActivityActions: bindActionCreators(PendingActivityActions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  bindActionsToProps
)(withStyles(styles)(withRouter(PendingActivityApplication)));
