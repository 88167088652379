import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import {
  Avatar,
  Button,
  Grid,
  Typography,
} from '@material-ui/core/';

const useStyles = makeStyles((theme) => ({
  avatarLabel: {
    fontSize: theme.typography.nav.fontSize,
    
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.primary.contrastText,
    }
  },
  buttonText: {
    color: theme.palette.buttonPrimary,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.buttonContrast.main,
    }
  },
}));

function AuthAvatar(props) {
  const location = useLocation();
  const classes = useStyles();

  const authContainer = (
    <Grid item container  alignItems="center" justify="flex-end" spacing={1} xs={9} sm={9} md={3}>
      <Grid item>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={classes.buttonText}
          disableElevation
          component={Link}
          to="/login"
        >
          Log in
        </Button>
      </Grid>
      <Grid item>
        <Button
          size="medium"
          variant="contained"
          color="default"
          disableElevation
          component={Link}
          to="/sign-up"
        >
          Sign up
        </Button>
      </Grid>
    </Grid>
  );

  const avatarContainer = (
    <Grid item container alignItems="center" justifyContent="flex-end" spacing={1} xs={9} sm={9} md={3}>
      <Grid item>
        <Typography className={classes.avatarLabel}>{`${props.memberData.firstname} ${props.memberData.surname}`}</Typography>
      </Grid>
      <Grid item>
        <Avatar alt={`${props.memberData.firstname}${props.memberData.surname}`} src={props.profilePicture} />
      </Grid>
    </Grid>
  );

  const emptyContainer = (
    <Grid item container alignItems="center" justify="flex-end" spacing={1} xs={9} sm={9} md={3}>
    </Grid>
  );

  return (
    <React.Fragment>
      {location.pathname === '/login' ? emptyContainer :
        location.pathname === '/sign-up' ? emptyContainer :
          props.isAuth ? avatarContainer : authContainer}
    </React.Fragment>
  );
}

export default AuthAvatar;