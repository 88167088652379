import React from "react";
import PropTypes from "prop-types";
import "./styles/dstyle.css";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import GenericHeader from "../../utils/GenericHeader";
import * as actionTypes from "../../store/actions/actionTypes";
import { adminPortalHeaderStyles as styles } from "./styles/styles";
import axios from "axios";
import {
  BASE,
  UPLOAD_PROFILE_PICTURE,
  EDIT_PROFILE_PICTURE
} from "../../API/APIendpoint";
class UploadProfilePicture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "srclogo.svg",
      imagePreviewUrl: "",
      imageStateTrack: "0"
    };
  }

  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = t => {
      this.setState({
        file: file,
        imagePreviewUrl: t.target.result,
        imageStateTrack: "1"
      });
    };
    reader.readAsDataURL(file);
  }
  _handleSubmit(e) {
    e.preventDefault();
    const { name } = e.target;
    const memPPId = this.props.memberProfilePicture.memberID;
    const memNo = this.props.userDetails.memberNumber;

    name === "create"
      ? axios
          .post(BASE + UPLOAD_PROFILE_PICTURE, {
            ProfilePicture: this.state.imagePreviewUrl,
            MemNo: memNo
          },{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(function(response) {})
          .catch(function(error) {})
      : axios
          .post(BASE + EDIT_PROFILE_PICTURE + memPPId, {
            ProfilePicture: this.state.imagePreviewUrl
          },{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(function(response) {})
          .catch(function(error) {});
    this.props.onMemberProfilePictureUpdate(
      this.state.imagePreviewUrl.toString()
    );
  }
  render() {
    const { classes } = this.props;
    const imageString = this.props.memberProfilePicture.profilePicture.toString();
    let editButton = true;
    let uploadButton = true;
    this.props.pic.toString() === "NotExists"
      ? (uploadButton = false)
      : (editButton = false);
    let currentImage = "";
    this.state.imageStateTrack === "1"
      ? (currentImage = this.state.imagePreviewUrl)
      : (currentImage = imageString);
    var $imagePreview = (<div className={classes.previewText}>Please select an Image for Preview</div>);
    if (currentImage) {
    } else {
      $imagePreview()
    }
    return (
      <div className={classes.previewComponent} align="center">
        <GenericHeader title="Member Profile Picture Update" />
        <form onSubmit={e => this._handleSubmit(e)}>
          <input
            className={classes.fileInput}
            type="file"
            onChange={e => this._handleImageChange(e)}
          />
          <div>Does it exists? {this.props.pic.toString()}</div>
          <button
            className={classes.submitButton}
            disabled={uploadButton}
            type="submit"
            name="create"
            onClick={e => this._handleSubmit(e)}
          >
            Upload Image
          </button>
          <button
            className={classes.submitButton}
            disabled={editButton}
            type="submit"
            name="update"
            onClick={e => this._handleSubmit(e)}
          >
            Edit Image
          </button>
        </form>
        <img src={currentImage} alt="PIC" className="uploadImage" />
      </div>
    );
  }
}
UploadProfilePicture.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    memberProfilePicture: state.memberProfilePictureReducer,
    pic: state.memberProfilePictureReducer.isExist
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onMemberProfilePictureUpdate: updatedMPicture => {
      dispatch({
        type: actionTypes.UPDATE_MEMBER_PROFILE_PICTURE,
        profilePicture: updatedMPicture
      });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UploadProfilePicture));
