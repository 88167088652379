import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenericTable from '../GenericTable/GenericTable';
import { Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';


const columns = [
  { field: 'memNo', headerName: 'Member No' },
  { field: 'inceptionDate', headerName: 'Inception Date'},
  { field: 'firstname', headerName: 'Name'},
  { field: 'surname', headerName: 'Surname'},
  { field: 'paymentDate', headerName: 'PaymentDate'},
  { field: 'comission', headerName: 'Amount(R)'},
  { field: 'frequency', headerName: 'Frequency'},
  { field: 'comType', headerName: 'Type'},

];

class CardCommission extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <React.Fragment>
         {
             <Grid item xs={12} style={{marginTop:"20px"}} >
                           
                    {this.props.back &&
                        <Grid item xs={12} container alignContent="center">
                        <ArrowBack color="primary" className="pointer" onClick={() => this.props.back()} />
                        <Typography variant={'subtitle2'} color={'primary'} align="left">Commission Due</Typography>
                        </Grid>
                    }

                 <GenericTable 
                   columns={columns}
                   rows={this.props.commission}
                 />
             </Grid>
         }

      </React.Fragment>
    );
  }
}


export default connect(null, null)(CardCommission);