import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Paper,
  Button,
  CardActions,
  Input
} from "@material-ui/core";
import GenericHeader from "../../../utils/GenericHeader";
import * as actionTypes from "../../../store/actions/actionTypes";
import { activityDetailsStyles as styles } from "../../adminUser/activitiesTab/styles/styles";
import PropTypes from "prop-types";
import axios from "axios";
import {
  BASE,
  GET_SPECIFIC_USER_ENDORSEMENTS_URL,
  APPROVE_ENDORSEMENT_APPLICATION,
  SEND_EMAIL_ENDORSEMENT_APPROVAL_URL
} from "../../../API/APIendpoint";

class EndorsementDetails extends Component {
  state = {
    showReasonInputBox: false,
    isApproved: false,
    isRejected: false,
    RejectionReason: "",
    status: {
      Approved: 1,
      Rejected: 2,
      Pending: 3
    },
    userEndorsementList: [],
    emailParam: {
      toEmail: this.props.MemberDetails.email,
      Subject: "Status Updated",
      Body: null,
      Firstname: this.props.MemberDetails.firstname,
      Surname:this.props.MemberDetails.surname,
      ApplicationStatus: this.props.MemberDetails.Appoved
    }
  };

  refreshPage() {
    axios
      .get(
        BASE +
          GET_SPECIFIC_USER_ENDORSEMENTS_URL +
          this.props.MemberDetails.memNo,{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res => {
        this.props.onApplicationsChange(res.data);
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      RejectionReason: "",
      userEndorsementList: [],
      emailParam: {
        toEmail: this.props.MemberDetails.email,
        Subject: "Status Updated",
        Body: null,
        Firstname: this.props.MemberDetails.firstname,
        Surname:this.props.MemberDetails.surname,
        ApplicationStatus: this.props.MemberDetails.Appoved
      }
    };
  }
  updateApproveStatus = e => {
    axios({
      url: `${BASE}api/EndorsementApplications/Approval/${this.props.application.endorsAppID}/1/Not Appicable`,
      method: "post",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    this.refreshPage();
    this.props.onCurrentAdminEndorsementTabChange("0");
  };

  updateRejectStatus = e => {
    axios({
      url: `${BASE}api/EndorsementApplications/Approval/${this.props.application.endorsAppID}/2/${this.state.RejectionReason}`,
      method: "post",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    this.refreshPage();
    this.props.onCurrentAdminEndorsementTabChange("0");
  };

  handleSubmit = e => {
    this.props.onCurrentAdminEndorsementTabChange("0");
  };

  handleApproveStatusEmail = e => {
    var emailParam = this.state.emailParam;
    emailParam.ApplicationStatus = "Approve";
    this.setState({ emailParam: emailParam });
  };

  handleRejectStatusEmail = e => {
    var emailParam = this.state.emailParam;
    emailParam.ApplicationStatus = "Reject";
    this.setState({ emailParam: emailParam });
  };

  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    const info2 = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold text-danger">{row2}</div>
      </div>
    );
    return (
      <div className={this.props.isPartner? "" :"container"}>
        <div className="row">
          <GenericHeader
            headerColor={this.props.headerColor}
            title="Endorsement Applications"
            showBackButton
            backFunction={() => {
              this.props.onCurrentAdminEndorsementTabChange("0");
            }}
          />
        </div>
        <div className="row" onClick={this.handleSubmit}>
          <div className="col-12">
            {info(
              "Member No: ",
              this.props.MemberDetails.memNo
                ? this.props.MemberDetails.memNo
                : "-"
            )}
            {info(
              "Endorsement No:  ",
              this.props.application.endorsementNo
                ? this.props.application.endorsementNo
                : "-"
            )}
            {info(
              "Discipline:  ",
              this.props.application.applicationType
                ? this.props.application.applicationType
                : "-"
            )}
            {info(
              "Date:  ",
              this.props.application.approvalDate.substring(0, 10)
                ? this.props.application.approvalDate.substring(0, 10)
                : "-"
            )}
            {info(
              "Other Statuses: ",
              this.props.application.status
                ? this.props.application.status
                : "-"
            )}
            {info(
              "Action Type: ",
              this.props.application.actionType
                ? this.props.application.actionType
                : "-"
            )}
            {info("Document: ", "-")}
            {info(
              "Theoretical Assessment:  ",
              this.props.application.model ? this.props.application.model : "-"
            )}
            {info(
              "Practical Assessment: ",
              this.props.application.section
                ? this.props.application.section
                : "-"
            )}
            {info(
              "Motivation: ",
              this.props.application.motivation
                ? this.props.application.motivation
                : "-"
            )}
            {this.props.application.status === "Rejected" ?
              info2(
                "Rejection Reason: ",
                this.props.application.reason
                  ? this.props.application.reason
                  : "-"
              ) :
              null
            }
          </div>
        </div>
        {!this.props.isPartner && <div className="row">
          <Paper anchor="bottom" className="col-12  " elevation={0}>
            <div className="col-12 border-bottom-0 border-warning rounded">
              <div className="row">
                <div className="col-6 pl-0 p-1">
                  <button
                    className="btn btn-outline-success form-control"
                    onClick={() => {
                      axios.post(
                        BASE + SEND_EMAIL_ENDORSEMENT_APPROVAL_URL,
                        this.state.emailParam,{
                          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
                      );
                      this.setState({
                        showReasonInputBox: false,
                        isApproved: true,
                        isRejected: false
                      });
                      this.handleApproveStatusEmail();
                      this.updateApproveStatus();
                      this.refreshPage();
                    }}
                  >
                    Approve
                  </button>
                </div>
                {this.props.application.status === "Rejected" ?
                <div className="col-6 pr-0 p-1">
                  <button
                    className="btn btn-outline-danger form-control"
                    disabled
                    onClick={() => {
                      this.setState({
                        showReasonInputBox: true,
                        isRejected: true,
                        isApproved: false
                      });
                    }}
                  >
                    Reject
                  </button>
                </div> :
                <div className="col-6 pr-0 p-1">
                  <button
                    className="btn btn-outline-danger form-control"
                    onClick={() => {
                      this.setState({
                        showReasonInputBox: true,
                        isRejected: true,
                        isApproved: false
                      });
                    }}
                  >
                    Reject
                  </button>
                </div>}
              </div>
            </div>
            {this.state.showReasonInputBox ? (
              <Card>
                <CardContent>
                  <Input
                    id="filled-multiline-flexible"
                    placeholder="Please provide a reason"
                    multiline
                    value={this.state.reason}
                    onChange={event =>
                      this.setState({ RejectionReason: event.target.value })
                    }
                    fullWidth
                    disableUnderline
                  />
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      this.props.onCurrentAdminEndorsementTabChange("0");
                      this.handleRejectStatusEmail();
                      axios.post(
                        BASE + SEND_EMAIL_ENDORSEMENT_APPROVAL_URL,
                        this.state.emailParam,{
                          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
                      );
                      this.updateRejectStatus();
                      this.refreshPage();
                    }}
                  >
                    Apply
                  </Button>
                </CardActions>
              </Card>
            ) : null}
          </Paper>
        </div> }
      </div>
    );
  }
}

EndorsementDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    application: state.endorsementReducer.application,
    MemberDetails: state.MemberViewReducer.MemberView,
    applications: state.endorsementReducer.applications,
    addEndorsementDetails:
      state.endorsementReducer.addEndorsementDetails.addEndorsementDetails,
    currentAdminEndorsementTab:
      state.adminPortalReducer.adminPortal.currentAdminEndorsementTab,
    userProfileDetails: state.loginReducer.userProfileDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION,
        application: newApplication
      }),
    onApplicationsChange: newApplications =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATIONS,
        applications: newApplications
      }),
    onCurrentAdminEndorsementTabChange: newTabName =>
      dispatch({
        type:
          actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onadminAddEndorsementChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADMIN_ENDORSEMENT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EndorsementDetails));
