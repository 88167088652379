import * as actionTypes from "../actions/actionTypes";
const initialState = {
  files: [],
  name: "",
  documentType: "General"
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_DOCUMENT_TYPE:
      return {
          ...state.documentType,
          [action.varName]: action.varValue
        }
    case actionTypes.UPDATE_FILES_TO_UPLOAD:
      return {
        ...state,
        files: action.payload,
        name: action.varName
      }
    case actionTypes.UPDATE_FILES_TO_UPLOAD_RESET:
      return {
        ...state,
        files: [],
        name: ""
      }
    default:
      return state;
  };
};
export default reducer;