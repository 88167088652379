import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionTypes from "../../store/actions/actionTypes";
import "./styles.css";
import { withStyles,ThemeProvider,createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { BASE, FRONT } from '../../API/APIendpoint';
import { 
	Button, Grid, Typography, Avatar, FormControl, TextField
  } from '@material-ui/core';
import { toast } from 'react-toastify';
import LockOpenSharpIcon from '@material-ui/icons/LockOpenSharp';

import UpdateSharpIcon from '@material-ui/icons/UpdateSharp';

toast.configure();

let theme = createTheme();
theme = responsiveFontSizes(theme);

const styles = (theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.primary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(4, 0, 4),
    },
    linkFont: {
      fontSize: '0.8rem',
      marginTop:'1.0em'
    },
    errorPadding: {
      padding: theme.spacing(2)
    },
  });

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            _invalidEmail: "",
        };
    }
    checkEmailAndReset = e => {
        this.setState({isActive: !this.state.isActive, _invalidEmail:""})
        
        e.preventDefault();
            axios({
                url: `${BASE}api/Member/post/reset?_url=${FRONT}`,
                method: "post",
                data: {
                    Email: this.props.resetPassDetails.email
                  }
            }).then(res => {
                if(res.data.status === "success"){
                    this.setState({isActive: !this.state.isActive})
                    window.location = '/Login/Response';
                } else {
                    // toast.error(res.data.field)

                    this.setState({_invalidEmail:res.data.field,isActive: !this.state.isActive})
                }
            })
      }
    activateButton = () => {
        this.setState({isActive: true})
    }
    deactivateButton = () => {
        this.setState({isActive: false})
    }
    render() {
        const {classes} = this.props;
        return (
            // <div className="container text-center" style={{ marginTop: "80px" }}> original 
            <div className="container text-center" style={{ marginTop: "80px" }}>
                {/* New look  */}
                <Grid container alignItems="center" justify="center">
                <Grid item  xs={9} sm={6} md={5} lg={4} xl={3}>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    <LockOpenSharpIcon />
                    </Avatar>
                    
                    <Typography variant="h4" style={{marginBottom:'0.3em'}}>
                    Reset Password
                    </Typography>

                    <ThemeProvider theme={this.props.theme}>
                    <Typography variant="h6">Enter the email address associated with your account and we'll send you a link to reset your password</Typography>
                    </ThemeProvider>

                    <form /*onSubmit={this.submitHandler}*/ className={classes.form}>


                    <TextField
                    fullWidth
                    label="Email"
                    id="outlined-margin-normal"
                    className={classes.textField}
                    defaultValue=""
                    placeholder ="Enter your email here"
                    margin="normal"
                    error = {this.state._invalidEmail.length > 0}
                    variant="outlined"
                    InputLabelProps={{
                        shrink: true,
                      }}
                    helperText={this.state._invalidEmail}

                    onInput={a => {
                        this.props.onResetPassDetailsChange(a.target.value, "email")
                    }}
                    />
                    {/* </FormControl> */}
                    
                    <div className="placeAtCenter">
                        <ReCAPTCHA
                            sitekey={FRONT === "http://localhost:3000/" ? 
                                this.props.recatchaKey.localHostKey : FRONT === "http://qaportal.narfo.co.za/" ?
                                    this.props.recatchaKey.narfoQAKey : this.props.recatchaKey.narfoProductionKey}
                            onChange={this.activateButton}
                            onExpired={this.deactivateButton}
                        />
                    </div>

                    <Button 
                        type="submit"
                        fullWidth
						className={classes.submit}
						variant="contained" 
						color="primary"
						disabled={!this.state.isActive}
						onClick={e => this.checkEmailAndReset(e)}>
						SUBMIT
					</Button>

                    </form>
                    <Grid container>
                    <Grid item xs>
                        <Button component={Link} to="/Login/ForgotEmail" className={classes.linkFont} variant="contained" color="primary">
                        Forgot your email?
                        </Button>
                    </Grid>
                    </Grid>

                </div>
                </Grid>
            </Grid>
            </div>
        );
    }
}

ResetPassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        resetPassDetails: state.loginReducer.resetPassDetails,
        recatchaKey: state.RecaptchaKeyReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPassDetailsChange: (value, vname) => dispatch({ type: actionTypes.UPDATE_RESET_DETAILS, varValue: value, varName: vname })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPassword));