import * as actionTypes from "../actions/actionTypes";

const initialState = {
  article: {
    currentNewsTab: "",
    id: "",
    title: "",
    content: ""
  },
  articles: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ARTICLE:
      return {
        ...state,
        article: action.article
      };
    case actionTypes.UPDATE_ARTICLES:
      return {
        ...state,
        articles: action.articles
      };
    default:
      return state;
  }
};

export default reducer;
