import React, {useEffect, useState} from 'react';
import {
  Grid,
  Typography
} from '@material-ui/core/';
import { connect } from 'react-redux';
import axios from 'axios';
import { ArrowBackIos } from '@material-ui/icons/';
import { makeStyles } from '@material-ui/core/styles';
import { BASE} from "../../../API/APIendpoint";
const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom:'10px'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    paddingBottom: '8px',
    textAlign: 'justify'
  }
}));

function ManualPayment(props) {
const [paymentAmount,] = useState(props.totalDue);
  useEffect(()=>{

    //make API call to get the payment amount 
    // axios.get(BASE + "api/PayFastDetails")
    // .then((response) => {
    //   let storeId = props?.memberData?.storeId;

    //   if(storeId > 0)
    //   {
    //     let payfastD = response.data.find(s => s.store?.storeId == storeId);
        
    //     if(payfastD != undefined || payfastD != null)
    //       {
    //         if(props.membershipType)
    //            setPaymentAmount(props.membershipType.fee);
    //         else 
    //            setPaymentAmount(payfastD.amount);
    //       }
    //     else
    //     setPaymentAmount(response.data[0].amount)
    //   }
    //   else{
    //     setPaymentAmount(response.data[0].amount)
    //   }
      
    // })
    // .catch((error)=>{
    //   console.log("Payfast Error",error);
    // })
  },[]);
  
  const classes = useStyles();
  const ManualPaymentInstruction = (
    <React.Fragment>
      <Typography
        variant='h5'
        color="primary"
        className={classes.heading}
      >
        <Grid item container direction="row" alignItems="center">
          <ArrowBackIos style={{"cursor": "pointer"}} onClick={() => props.back()}/>
          Manual Payment
        </Grid>
      </Typography>
      <Grid item container direction="column" alignItems="center" justifyContent="center">
        <Grid item container direction="column" alignItems="left" justifyContent="flex-start" xs={11}>
          <Typography className={classes.paragraph}>
              You have chosen to do a manual payment for your membership.
          </Typography>
          <Typography className={classes.paragraph}>
              Once you have made a payment of <strong>R{paymentAmount}</strong>, please email the proof of payment to us at info@narfo.co.za.
              Once we receive your proof of payment we will
              activate your account.
          </Typography>
          <Typography className={classes.paragraph}>
            Bank Details:
            <br/>
            <ul>
              <li>Bank: First National Bank (FNB)</li>
              <li>Account Number: 62833054117</li>
              <li>Branch Code: 250655</li>
            </ul>
          </Typography>
          <Typography className={classes.paragraph}>
              When paying please ensure you use <strong>{props.memberNumber}</strong> as reference
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
        
  return (
    <React.Fragment>
      {ManualPaymentInstruction}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberData: state.ProfileReducer.memberData,
});

export default connect(mapStateToProps, null)(ManualPayment);