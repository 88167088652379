import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import {
  UPDATE_SECTION_TITLE
} from '../../store/LearningManagement/actionTypes';
import { 
  Typography
} from '@material-ui/core';
import DisplayCourseMaterialOrQuiz from './DisplayCourseMaterialOrQuiz';
import { TakeQuiz } from '..';
import { QuizResults } from '..';
import ViewResults from './ViewResults';

class Assessments extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.onSectionTitleChange('Course Assessment Quizzes');
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
        <SubtitleBar
          variant="subtitle1"
          title={isMobile ? 'Disclaimer' : 'General Course Disclaimer'} 
        />
        <div className="mb-8">
          <Typography align="justify">
            By completing this selected quizzes, I declare the following:I confirm that I as the registered member, 
            am completing the online assessment in my personal capacity with no assistance from any third party. I acknowledge 
            that it is an offence to provide false information and accept that should this be the case, my results could be 
            revoked and I stand to be disqualified from taking this course again. I further acknowledge that this course is an 
            integral part of obtaining my dedicated status and as a result of my assessment being revoked, I also stand the chance of 
            losing my dedicated status.
          </Typography>
        </div>
        <div className={isMobile ? 
		  this.props.currentLMSpage === 0 ? 
		  "inner-shadow border-radius-2 py-2 px-3 bg-white-2 mt-4" : 
		  "inner-shadow border-radius-2 py-2 bg-white-2 mt-4" : 
		  "inner-shadow border-radius-2 py-2 px-4 bg-white-2 mt-4"
	    }>
          {this.props.currentLMSpage === 0 &&
          <React.Fragment>
            <DisplayCourseMaterialOrQuiz
              getQuestionByIdCallBack={this.props.getQuestionByIdCallBack}
              getCourseMaterialByIdCallBack={this.props.getCourseMaterialByIdCallBack}
              getCourseModuleNameByIdCallBack={this.props.getCourseModuleNameByIdCallBack}
              postMemberAssessmentCallBack={this.props.postMemberAssessmentCallBack}
              getMemberAssessmentsByMemberNo={this.props.getMemberAssessmentsByMemberNo} 
            />
          </React.Fragment> || this.props.currentLMSpage === 2 &&
          <React.Fragment>
            <TakeQuiz
              postMemberAssessmentCallBack={this.props.postMemberAssessmentCallBack}
              getMemberAssessmentsByMemberNo={this.props.getMemberAssessmentsByMemberNo} 
            />
          </React.Fragment> || this.props.currentLMSpage === 3 &&
		  <QuizResults />  || this.props.currentLMSpage === 4 &&
		  <ViewResults />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLMSpage: state.nLearningManagementReducer.currLmsTab
});

const mapDispatchToProps = dispatch => ({
  onSectionTitleChange: newSectionTitle =>
    dispatch({
      type: UPDATE_SECTION_TITLE,
      sectionTitle: newSectionTitle
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Assessments);