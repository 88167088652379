import * as Types from "./DedicatedStatusApplicationActionTypes";

const DSApplicationState = {
  applicationStatus: {
    loading: null,
    response: null,
    error: null,
    DS: false,
    DH: false,
    DS_DH: false
  },
  currentStep: 1,
  dsType: "Dedicated Sports Person",
  DS: false,
  DH: false,
  DS_DH: false,
  validationError: true,
  canSubmit: false,
  PhRegNo: "",
  PhAssociation: "",
  PhProvince: "",
  PhExpiryDate: "",
  PhAttachment: null,
  OdsAttachment: null,
  OdsExpiryDate: "",
  OdsDsNo: "",
  OdsFarNo: "",
  OdsAssociationName:"",
  NonParticipationReason: "",
  Motivation: "",
  Participation: "",
  Assessments: "",
  Ods: "",
  Ph: "",
  detailsError: null,
  motivationError: null,
  declarationError: null,
  showModal: false
};

const reducer = (state = DSApplicationState, action) => {
  switch (action.type) {
    case Types.APPLICATION_BEGIN:
    case Types.APPLICATION_ERROR:
    case Types.APPLICATION_SUCCESS:
    case Types.CHANGE_STEP:
    case Types.TOGGLE_DS_TYPE:
    case Types.SET_DS_VARIABLES:
    case Types.SET_VALIDATION_ERRORS:
      return {
        ...state,
        ...action.DSApplicationState
      };
    case Types.CLEAR_STATE:
      return {
        ...state,
        Ods: "",
        Ph: "",
        NonParticipationReason: "",
        Motivation: null,
        Participation: null,
        Assessments: null,
        currentStep: 1,
        PhRegNo: "",
        PhAssociation: "",
        PhProvince: "",
        PhExpiryDate: "",
        PhAttachment: null,
        OdsAttachment: null,
        OdsExpiryDate: "",
        OdsDsNo: "",
        OdsFarNo: "",
        OdsAssociationName: ""
      };
    default:
      return state;
  }
};

export default reducer;
