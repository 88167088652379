import React from "react";
import { isMobile } from "react-device-detect"
function LoyaltyDaysItem(props) {
    let textPosition = "";
    if(isMobile) {
        textPosition = "text-centre"
    } else {
        textPosition = "text-right"
    }
    return(
    <tbody>
        <tr>
            <td>{props.loyaltyTitle}</td>
            <td className="d-none d-sm-block">
                <div className="nextTo pr-2 pt-2">Current :</div>
                <div className="nextTo">{props.loyaltyDays}</div>
            </td>
            <td className={textPosition}>
            <div className="nextTo">New</div>
            {" "}
                <select
                    className="form-control nextTo col-5 col-lg-3"
                    onInput={props.setDays}
                >
                    {props.daysList.map((item,index) => {
                        return(
                            <option key={index} >{item}</option>
                    )
                })}
                </select> 
            </td>
        </tr>
    </tbody>
    )
}
export default LoyaltyDaysItem;