import React, { Component } from "react";

class Policy extends Component {
  render() {
    return (
      <div className="container pr-5 pl-5">
        <div>
          <h3 align="center" className="font-weight-bold p-3 mb-0">
            Privacy Policy
          </h3>
          <p align="center">
            National Association or Responsible Firearm Owners (NARFO) Privacy Policy.
          </p>
          <br />
          <p className="font-weight-bold">
            1.Context
          </p>
          <p className="pl-4">1.1.This Privacy Policy describes how your personal information is collected, used, stored and shared when you visit Www.narfo.co.za (the “Site”) and your personal online profile.</p>
          <p className="pl-4">1.2.In order for an association to obtain and maintain their accreditation with theSouth African Police Services (SAPS), more specific with the Central Firearms Registry (CFR),  the association, National Association or Responsible Firearm Owners ("NARFO"), must comply with the stipulations of Regulation 2(7), 2(8), and 4(2)(a) of the Regulations (2004) of the Firearms Control Act, 2000 (Act 60 of 2000 as amended).</p>
          <p className="pl-4">1.3.These regulations require that NARFO must collect and maintain information about a certain category of members throughout the lifespan of such a member.</p>
          <p className="pl-4">1.4.NARFO membership is done through an electronic online platform with no paper-based record keeping mechanism. Information stored on the NARFO system is supplied by the member.</p>
          <p className="font-weight-bold">2.Security of your personal data</p>
          <p className="pl-4">2.1.We will take reasonable technical and organizational precautions to prevent the loss, misuse or alteration of your personal information.</p>
          <p className="pl-4">2.2.We will store all the personal information you provide on servers provided by our webhosting provider.</p>
          <p className="pl-4">2.3.Data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
          <p className="pl-4">2.4.Data that is submitted by members is encrypted using secure socket layer technology (SSL).</p>
          <p className="pl-4">2.5.NARFO constantly evaluates the security and protection of personal information that is located in a secure members area.</p>
          <p className="pl-4">2.6.It is know that any website on the world wide web can be subject to cyber attack andNARFO has no control over such attacks.</p>
          <p className="pl-4">2.7.NARFO will never unintentionally disclose or expose any personal informationof it’s members.</p>
          <p className="pl-4">2.8.Members are responsible for keeping passwords and user login details confidential and NARFO cannot be held liable if the member allows this and provide access to personal information to third parties.</p>
          <p className="pl-4">2.9.NARFO will not ask you for your password.</p><br />
          <p className="font-weight-bold">3.PERSONAL INFORMATION WE COLLECT, STORE AND MAINTAIN</p>
          <p className="pl-4">3.1.When you visit the Site, we may collect certain information about your device, including information about your web browser, IP address, time zone and location.</p>
          <p className="pl-4">3.2.Additionally, when you join and make use of the payment gateway, we collect certain information from you, including your name and surname, address, email address, ID number, ethnicity and gender, username and password.  Your password is stored as an encrypted string.</p>
          <p className="pl-4">3.3.We do not store any of your payment method details like credit card or bank details. This is done through a South African Secure Payment Gateway.</p>
          <p className="pl-4">3.4.When we talk about “Personal Information” in this Privacy Policy, we are talking about Information you would be required to supply depending on
your requirements in terms of applying for membership, dedicated status, submitting an activity, making use of the license reminder system and applying for an endorsement.</p>
          <p className="font-weight-bold">4.HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
          <p className="pl-4">4.1.Your personal information is used to communicate with you, Name and surname and email address.</p>
          <p className="pl-4">4.2.Personal information is also used on the certificates and documents you generate from the system.</p>
          <p className="pl-4">4.3.Documents include but not limited to: Proof of membership, Membership Card, Dedicated Person Certificate, Endorsements, Activity Register, License Reminder Report, Payment Receipts and various Emails used for communication purposes.</p>
          <p className="font-weight-bold">5.SHARING YOUR PERSONAL INFORMATION</p>
          <p className="pl-4">5.1.Personal Information that is shared with third parties are Name, Surname and Email address, for the sole purpose of making use of bulk mail systems to communicate NARFO related information.</p>
          <p className="pl-4">5.2.Your personal information will never be sold, distributed, traded or otherwise made available to any other third party for whatever other reason.</p>
          <p className="pl-4">5.3.We may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights and comply with related laws, bi laws, acts and regulations related to your membership.</p>
          <p className="font-weight-bold">6.DATA RETENTION</p><br />
          <p className="pl-4">6.1.All information you supply as a result of your membership will be stored in our databases even if you terminate your membership.</p>
          <p className="pl-4">6.2.Members can change some personal information linked to their profile when personal circumstances change.</p>
          <p className="pl-4">6.3.NARFO may make use of data centers or servers that reside out side the borders of South Africa for data storage or backups of data.</p>
          <br /><p className="font-weight-bold">7.CHANGES</p>
          <p>7.1.We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
        </div>
      </div>
    );
  }
}
export default Policy;
