import * as actionTypes from "../actions/actionTypes";

const initialState = {
  applications: [],
  appication: {},
  appication_post: {},
  certificate: {},
  membersArea: {
    memberName: "",
    dedicatedHunterStatus: "",
    dedicatedSportShooterStatus: "",
    currentActivityTab: "0",
    currentLicenseTab: "0",
    currLmsTab: "0",
    currentEndorsementTab: "0",
    currentAdminEndorsementTab: "0"
  },

  addEndorsementDetails: {
    userEndorsementList: [],
    ApplicationType: "",
    ApplicationTitle: "",
    ApplicationDate: "",
    Status: "",
    ApprovalDate: "",
    EndorsementNo: "",
    FirearmType: "",
    ActionType: "",
    Make: "",
    Model: "",
    Section: "",
    SerialNumber: "",
    CertificateUrl: "",
    Caliber: "",
    FirearmPurpose: "",
    AccurateDeclaration: "",
    EndorsementTYpe: "",
    Motivation: ""
  },

  Caliber: "",
  SerialNo: "",
  FirearmPurpose: "",
  EndorsementTYpe: "",
  title: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CERTIFICATE:
      return {
        ...state,
        certificate: action.certificate
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION:
      return {
        ...state,
        application: action.application
      };
    case actionTypes.UPDATE_APPLICATION_POST:
      return {
        ...state,
        application_post: action.application_post
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATIONS:
      return {
        ...state,
        applications: action.applications
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADD_ENDORSEMENT_DETAILS:
      const newEndorsementDetails = {
        ...state.addEndorsementDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        addEndorsementDetails: newEndorsementDetails
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currPage
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB:
      return {
        ...state,
        currentEndorsementTab: action.currTab
      };

    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB:
      return {
        ...state,
        currentAdminEndorsementTab: action.currTab
      };

    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_USER_ENDORSEMENT:
      return {
        ...state,
        endorsements: action.payload
      };
    case actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADMIN_ENDORSEMENT:
      return {
        ...state,
        updateAdminEndorsement: action.payload
      };
    case actionTypes.ENDORSEMENT_ACTIONS.RESET_APPLICATION:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
