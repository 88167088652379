import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { BASE, GET_ADDLICENCE_URL, GET_SECTION_LIST } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as constFile from "../../../store/constantFile/ConstFile";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginTop: "1%",
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5,
    width: "60%"
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginTop: "1%",
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: "5px",
    backgroundColor: "white",
    borderColor: "#000000",
    color: "black",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50,
    "&:hover": {
      backgroundColor: "#F5A623",
      color: "White",
      border: "none"
    }
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class AddFirearmDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Section_List: [],
      isValid: false,
      showValid: true
    };
  }

  handleSubmit = () => {
    this.checkNotEmpty();
    if ( this.state.isValid 
        &&  !(this.props.newlicenceDetails.Firearm === "") 
        &&  !(this.props.newlicenceDetails.Make === "") 
        &&  !(this.props.newlicenceDetails.Model === "") 
        &&  !(this.props.newlicenceDetails.Caliber === "") 
        &&  !(this.props.newlicenceDetails.SerialNumber === "") 
        &&  !(this.props.newlicenceDetails.LicenseNo === "") 
        &&  !(this.props.newlicenceDetails.IssueDate === "") 
        &&  !(this.props.newlicenceDetails.ExpiryDate === "")
    ) {
      this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");

      const JSONbody = {
        licenseNo: this.props.newlicenceDetails.LicenseNo,
        firearm: this.props.newlicenceDetails.Firearm,
        make: this.props.newlicenceDetails.Make,
        model: this.props.newlicenceDetails.Model,
        caliber: this.props.newlicenceDetails.Caliber,
        serialNumber: this.props.newlicenceDetails.SerialNumber,
        licenseType: this.props.newlicenceDetails.LicenseType,
        section: this.props.newlicenceDetails.Section,
        expiryDate: this.props.newlicenceDetails.ExpiryDate,
        issueDate: this.props.newlicenceDetails.IssueDate,
        memNo: this.props.memberState.isAdmin
          ? this.props.MemberV.memNo
          : this.props.userDetails.memberNumber, 
        Custom:constFile.CFALSE 
    }
    axios.post(BASE + GET_ADDLICENCE_URL,JSONbody,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
    .then(this.setState({ isValid: false }))
    .then(this.componentDidMount());
    }
  };

  handleChange = name => event => {
    this.props.onLicenceChange(event.target.value, [name]);
    this.setState({ showValid: true });
    this.setState({ isValid: false });
    this.checkNotEmpty();
  };

  checkNotEmpty = () => {
    let V = true;
    if (
      this.props.newlicenceDetails.Firearm === "" ||
      this.props.newlicenceDetails.Firearm === null ||
      this.props.newlicenceDetails.Firearm === undefined ||
      this.props.newlicenceDetails.Make === "" ||
      this.props.newlicenceDetails.Make === null ||
      this.props.newlicenceDetails.Make === undefined ||
      this.props.newlicenceDetails.Model === "" ||
      this.props.newlicenceDetails.Model === null ||
      this.props.newlicenceDetails.Model === undefined ||
      this.props.newlicenceDetails.Caliber === "" ||
      this.props.newlicenceDetails.Caliber === null ||
      this.props.newlicenceDetails.Caliber === undefined ||
      this.props.newlicenceDetails.SerialNumber === "" ||
      this.props.newlicenceDetails.SerialNumber === null ||
      this.props.newlicenceDetails.SerialNumber === undefined ||
      this.props.newlicenceDetails.IssueDate === "" ||
      this.props.newlicenceDetails.IssueDate === null ||
      this.props.newlicenceDetails.IssueDate === undefined ||
      this.props.newlicenceDetails.ExpiryDate === "" ||
      this.props.newlicenceDetails.ExpiryDate === null ||
      this.props.newlicenceDetails.ExpiryDate === undefined
    ) {
      V = false;
    }
    this.setState({
      isValid: V
    });
  };

  componentDidMount() {
    this.props.onLicenceChange("", "Firearm");
    this.props.onLicenceChange("", "Make");
    this.props.onLicenceChange("", "Model");
    this.props.onLicenceChange("", "Caliber");
    this.props.onLicenceChange("", "SerialNumber");
    this.props.onLicenceChange("", "LicenseNo");

    this.setState({ showValid: false });
    fetch(BASE + GET_SECTION_LIST,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(data => {
        this.setState({
          isLoaded: true,
          Section_List: data
        });
      });

    ValidatorForm.addValidationRule("isEmpty", value => {
      if (this.state.showValid) {
        if (!value) {
          return false;
        }
        if (value.length < 0) {
          return false;
        }
      }
      return true;
    });
  }


  render() {
    const { classes } = this.props;
    return (
      <div>
        <form className={classes.container} noValidate autoComplete="off" onSubmit = {(e)=>{e.preventDefault()}}>
          <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit = {this.handleSubmit}
          >
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.newlicenceDetails.Firearm}
              onChange={this.handleChange("Firearm")}
              name="Firearm"
              label="Firearm"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the FireArm Type",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.newlicenceDetails.Make}
              onChange={this.handleChange("Make")}
              name="Make"
              label="Make"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Make",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              label="Model"
              className={classes.textField}
              value={this.props.newlicenceDetails.Model}
              onChange={this.handleChange("Model")}
              name="Model"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Model Date",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.newlicenceDetails.Caliber}
              onChange={this.handleChange("Caliber")}
              name="Caliber"
              label="Caliber"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Caliber",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              label="Serial Number"
              value={this.props.newlicenceDetails.SerialNumber}
              onChange={this.handleChange("SerialNumber")}
              name="SerialNumber"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Seriel Number",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              label="License Number"
              className={classes.textField}
              value={this.props.newlicenceDetails.LicenseNo}
              onChange={this.handleChange("LicenseNo")}
              name="LicenseNo"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the License Number",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <Select
              className={classes.textField}
              native
              value={this.props.newlicenceDetails.Section}
              onChange={this.handleChange("Section")}
              validators={["isEmpty", "required"]}
              input={
                <OutlinedInput
                  name="Section_List"
                  id="Section"
                />
              }
            >
              {this.state.Section_List.map((activityType, index) => (
                <option key={index} value={activityType.section}>
                  {activityType.section}
                </option>
              ))}
            </Select>
            <TextValidator
              id="OutlinedSection"
              label="IssueDate"
              className={classes.textField}
              type="date"
              value={this.props.newlicenceDetails.IssueDate}
              onChange={this.handleChange("IssueDate")}
              name="LicenseNo"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Date Issued",
                "This field is required"
              ]}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              label="Expiry Date"
              className={classes.textField}
              type="date"
              value={this.props.newlicenceDetails.ExpiryDate}
              onChange={this.handleChange("ExpiryDate")}
              name="LicenseNo"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Expiry Issued",
                "This field is required"
              ]}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              variant="outlined"
            />
            <div className={classes.footer}>
              <button
                variant="contained"
                className={classes.button}
                onClick={() =>
                  this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
                }
              >
                Previous
              </button>
              <div className={classes.grow} />
              <button
                variant="contained"
                className={classes.button}
                onClick={this.handleSubmit}
              >
                Save
              </button>
            </div>
          </ValidatorForm>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    userDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserLicenseChange: newUserLicensesArray =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE_LIST,
        payload: newUserLicensesArray
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onLicenceChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AddFirearmDetails));
