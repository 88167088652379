import * as actionTypes from "../actions/actionTypes";

const initialState = {
    selectedActivityDescription: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_ACTIVITY_DESCRIPTION:
      if(!action.selectedActivityDescription) {
        return state;
      } else {
        return {
          ...state,
          selectedActivityDescription: action.selectedActivityDescription
        };
      }
    default:
      return state;
  }
};
export default reducer;
