import React, { Component } from "react";
import "../styles/dstyle.css";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { styles } from "../../../utils/styles/styles";
import { withRouter } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import defaultImg from "../images/dProfile.jpg";
import defaultImgGl from "../images/gl.jpg";
class PendingNewMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _allNewMembers: true,
      _newMembersForTheMonth: false,
      _currentMonth: new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`
    }
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }
  findMember = MemNo => {
    return this.props.memberList.filter(member => member.memNo === MemNo);
  };

  handleSubmit = member => {
    this.props.onMemberViewChange(member);
    this.props.onNewMemberChange(member);
    this.props.history.push("/admin/ApproveNewMembers");
  };

  render() {
    const pendingNewMembers = this.props.newMembers.filter(
      member => member.status === 2
    );
    const pendingFilteredMembers = pendingNewMembers;
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="mb-4">
          <GenericHeader
            title="New Members"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/MainAdmin");
            }}
          />
        </div>
        {pendingFilteredMembers.length=== 0 ? (
          <div className="text-warning font-weight-bold">There are no pending New Members applications currently...</div>
        ) : (
          <ul className="ml-0 pl-1">
            {pendingFilteredMembers.map((newMember, index) => {
              const member = this.findMember(newMember.memNo)[0];
              return (
                <div
                  key={index}
                  className="row h-4 mb-2 border-bottom pb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.handleSubmit(member);
                  }}
                >
                  <div className="row pl-2">
                    <div className="col-4 my-auto">
                      <div className="h6 mb-0 pr-2">
                        {member
                          ? `${member.firstname.toString().substring(0, 1)}`
                          : null}
                      </div>
                      <img src={member.sex === "Male" ? defaultImg : defaultImgGl} alt="Gun" className="avatar" style={{ width: "100px", height: "100px" }} />
                    </div>
                    <div className="col-8 pl-1 pt-3 " align="left">
                      <div className="h6">
                        {member ? `${member.firstname} ${member.surname}` : null}
                      </div>
                      <div className="h6">
                        {member ? `${member.idno}` : null}
                      </div>
                      <div className="h6">
                        {member ? `${member.email}` : null}
                      </div>
                      <div className="h6">
                        {member ? `Expiry Date: ${member.expiryDate.substring(0, 10)}` : null}
                      </div>
                      <div className="text-warning">
                        Pending New Members Approval
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    memberList: state.dashBoardReducer.totalMembers.members,
    newMembers: state.dashBoardReducer.newMembers.newMembers
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onNewMemberChange: membersPending =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS,
        membersPending: membersPending
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PendingNewMembers)));
