import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import HamburgerMenu from "./HamburgerMenu";
import theme from "../../materialUI/NarfoTheme";
import * as actionTypes from "../../store/actions/actionTypes";
import JWT from "jwt-decode";
import narfoLogo from "../qrLinkToMember/qrImages/narfoLogo.jpg";
import "./customList.css";
const styles = {
  root: {
    flexGrow: 1,
    backgroundColor: "#FFF",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit,
      width: "100%"
    }
  },
  searchIcon: {
    width: 30,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  linkLeft: {
    textDecoration: "none",
    color: "#6BC20A",
    fontWeight: theme.typography.fontWeightRegular
  },
  inputRoot: {
    color: "inherit",
    width: "10%"
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: 30,
    transition: theme.transitions.create("width"),
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      "&:focus": {
        width: "25%"
      }
    }
  }
};

function ButtonAppBar(props) {
  const { classes } = props;
  function loginCheck() {
    if (!props.isLoggedIn) {
      let TokenStored = localStorage.getItem("tokenKey");
      if (!TokenStored) {
        TokenStored = sessionStorage.getItem("tokenKey");
      }
      if (TokenStored) {
        var decode = JWT(TokenStored);
        props.onUserDetailsChange(decode.email, "email");
        props.onUserDetailsChange(decode.sub, "username");
        props.onUserDetailsChange(decode.azp, "cellNo");
        props.onUserDetailsChange(decode.acr, "physicalAddress");
        props.onUserDetailsChange(decode.amr, "memberNumber");
        props.onUserDetailsChange(decode.nbf, "occupation");
        props.onUserDetailsChange(decode.prn, "expiryDate");
        props.onMainDetailsChange(false, "isAdmin");
        props.onUserDetailsChange(decode.nameid === "True", "adminLevel");
        props.onUserDetailsChange(true, "isLoggedIn");

        if (sessionStorage.getItem("member") !== "undefined") {
          props.onGetLoggedInMemberChange(
            JSON.parse(sessionStorage.getItem("member"))
          );
          if (sessionStorage.getItem("activeStatus") !== "undefined") {
            props.onMemberActiveStatusChange(
              JSON.parse(sessionStorage.getItem("activeStatus"))
            );
          }
        }
      }
    }
  }

  function showLoggedInButtons() {
    return props.isLoggedIn ? (
      <div>
        <Button
          component={Link}
          onClick={() => {
            props.onMemberStateChange(false, "isAdmin");
            props.onToggleHamburgerChange(false);
          }}
          to="/admin/MembersArea"
          color="inherit"
        >
          <div className={` ${props.isAdminPortal ? "" : "text-success"}`}>
            {" "}
            {props.username}{" "}
          </div>
        </Button>
        {props.adminLevel ? (
          <Button
            component={Link}
            to="/MainAdmin"
            onClick={() => {
              props.onMemberStateChange(true, "isAdmin");
              props.onToggleHamburgerChange(true);
            }}
            color="inherit"
          >
            <div
              className={`d-none d-md-block ${
                props.isAdminPortal ? "text-success" : ""
              }`}
            >
              Admin Portal
            </div>

            <div
              className={`d-block d-md-none ${
                props.isAdminPortal ? "text-success" : ""
              }`}
            >
              Admin
            </div>
          </Button>
        ) : null}
      </div>
    ) : (
      <div className="container d-flex flex-direction-row">
        <Button
          component={Link}
          to="/Login"
          onClick={() => props.onMemberStateChange(false, "isAdmin")}
          color="inherit"
        >
          Login
        </Button>
        <Button
          onClick={loginCheck()}
          component={Link}
          to="/join"
          color="inherit"
        >
          Join
        </Button>
      </div>
    );
  }

  function showLoggedInButtonsMobile() {
    var size = `8px`;

    return props.isLoggedIn ? (
      <div style={{ fontSize: { size } }}>
        <Button
          component={Link}
          onClick={() => {
            props.onMemberStateChange(false, "isAdmin");
            props.onToggleHamburgerChange(false);
          }}
          to="/admin/MembersArea"
          color="inherit"
          style={{ fontSize: { size } }}
        >
          <div className={` ${props.isAdminPortal ? "" : "text-success"}`}>
            {" "}
            {props.username}{" "}
          </div>
        </Button>
        {props.adminLevel ? (
          <Button
            component={Link}
            to="/MainAdmin"
            onClick={() => {
              props.onMemberStateChange(true, "isAdmin");
              props.onToggleHamburgerChange(true);
            }}
            color="inherit"
            style={{ fontSize: { size } }}
          >
            <div
              className={`d-none d-md-block ${
                props.isAdminPortal ? "text-success" : ""
              }`}
            >
              Admin Portal
            </div>

            <div
              className={`d-block d-md-none ${
                props.isAdminPortal ? "text-success" : ""
              }`}
              style={{ fontSize: { size } }}
            >
              Admin
            </div>
          </Button>
        ) : null}
      </div>
    ) : (
      <div style={{ fontSize: "14px" }}>
        <Button
          component={Link}
          to="/Login"
          onClick={() => props.onMemberStateChange(false, "isAdmin")}
          color="inherit"
          style={{ fontSize: { size } }}
        >
          Login
        </Button>
        <Button
          onClick={loginCheck()}
          component={Link}
          to="/join"
          color="inherit"
          style={{ fontSize: { size } }}
        >
          Join
        </Button>
      </div>
    );
  }
  function logoRender() {
    return (
      <img
        style={{ marginLeft: "30%"}}
        src={narfoLogo}
        alt="Smiley face"
        height="80"
        width="150"
      />
    );
  }
  if (window.location.href.includes("?") && !window.location.href.includes("?=")) {
    return logoRender();
  } 
   else {
    return (
      <AppBar
        position="static"
        className={`${classes.root} p-0`}
        style={{
          boxShadow:
            "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
          borderBottom: "2px solid #e5e2e8"
        }}
      >
        <Toolbar className="">
          <HamburgerMenu />
          <Typography
            align="left"
            variant="h6"
            color="secondary"
            className={classes.grow}
          >
            <Link className={classes.linkLeft} to="/">
              <button
                className="btn text-success"
                onMouseDown={() => {
                  props.onToggleHamburgerChange(false);
                }}
              >
                NARFO
              </button>
            </Link>
          </Typography>
          <div className={classes.grow} />
          <div className="d-block d-sm-none">
            {showLoggedInButtonsMobile()}{" "}
          </div>
          <div className="d-none d-sm-block"> {showLoggedInButtons()} </div>
        </Toolbar>
      </AppBar>
    );
  }
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    adminLevel: state.loginReducer.userProfileDetails.adminLevel,
    partnerLevel: state.loginReducer.userProfileDetails.partnerLevel,
    salesRepLevel: state.loginReducer.userProfileDetails.salesRepLevel,
    isLoggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    username: state.loginReducer.userProfileDetails.username,
    isAdminPortal: state.loginReducer.memberState.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetLoggedInMemberChange: newGetLoggedInMember =>
      dispatch({
        type: actionTypes.UPDATE_GET_LOGGED_IN_MEMBER,
        getLoggedInMember: newGetLoggedInMember
      }),

    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),

    onToggleHamburgerChange: toggle =>
      dispatch({
        type: actionTypes.TOGGLE_HAMBURGER,
        toggleHamburger: toggle
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onLoginDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        varValue: value,
        varName: vname
      }),
    onMainDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MAIN_DETAILS,
        varName: vname,
        varValue: value
      }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ButtonAppBar));
