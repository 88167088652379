import * as actionTypes from "../actions/actionTypes";

const initialState = {
    endorsementSection: '',
    endorsementApplicationType: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VALIDATE_SECTION:
        return {
            ...state,
            endorsementSection: action.endorsementSection
        };
    case actionTypes.UPDATE_VALIDATE__APPLICATION_TYPE:
        return {
            ...state,
            endorsementApplicationType: action.endorsementApplicationType
        };
    default:
      return state;
  }
};
export default reducer;
