import * as Types from './FilterActionTypes';

export const resetFilterAC = () => {
    return {
        type: Types.RESET_ACTIVITY_FILTER,
    };
};

export const resetFilter = () => (dispatch) => dispatch(resetFilterAC());

export const setCategoryAC = (category) => {
    return {
        type: Types.SET_FILTER_CATEGOTY,
        activityFilterState: {
            filterCategory: category
        }
    };
};

export const setCategory = (category) => (dispatch) => dispatch(setCategoryAC(category));

export const setStartDateAC = (date) => {
    return {
        type: Types.SET_FILTER_START_DATE,
        activityFilterState: {
            startDate: date
        }
    };
};

export const setStartDate = (date) => (dispatch) => dispatch(setStartDateAC(date));

export const setEndDateAC = (date) => {
    return {
        type: Types.SET_FILTER_END_DATE,
        activityFilterState: {
            endDate: date
        }
    };
};

export const setEndDate = (date) => (dispatch) => dispatch(setEndDateAC(date));
