import React, { Component } from 'react';
import ScrollableTabsButtonAuto from "../staticPages/aboutPage/AboutTabbedPane";
import {connect} from "react-redux";
import StoreLocator from "./StoreLocator";

class StoreLocatorContent extends Component {
  render() {
    return (
      <div className="mt-5">
         <ScrollableTabsButtonAuto isLoggedIn={this.props.userDetails.isLoggedIn} />
        <StoreLocator/>
      </div>
    )
  }
}
const mapStateToProps = state => {
    return {
      userDetails: state.loginReducer.userProfileDetails
    };
  };
  
  export default connect(
    mapStateToProps,
    null
  )(StoreLocatorContent);