import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import Interweave from 'interweave';

class EmailItem extends Component {
    constructor(props){
        super(props);
    }

    render() {
      return (
        <div className="container">
          
            <div className="row">
              <GenericHeader 
                subHeader
                title={this.props.email.subject} 
                showBackButton
                backFunction={() => this.props.backFunction()}
              />
            </div>

            <div className="row">
              <div className="col-12" style={{marginLeft:'30px',marginBottom:'10px'}}>
                  <span style={{font:'caption',fontWeight: 900}}> Email Subject : </span> {this.props.email.subject}
              </div>

              <div className="col-12" style={{marginLeft:'30px',marginBottom:'10px'}}>
                  <span style={{font:'caption',fontWeight: 900}}> Date Sent : </span> {new Date(this.props.email.dateSent).toLocaleString()} 
              </div>

              <div className="col-12" style={{marginLeft:'30px',marginBottom:'10px'}}>
                  <span style={{font:'caption',fontWeight: 900}}> Recipients : </span> {this.props.email.recipient}
              </div>

              <div className="col-12" style={{marginLeft:'30px',marginBottom:'10px',whiteSpace: 'break-spaces'}}>
                  <span style={{font:'caption',fontWeight: 900}}> Email Body : </span><br/><br/>
                  <Interweave content={this.props.email.body} />
              </div>

              <div className="col-12" align="right" style={{marginTop:'15px',marginBottom:'20px'}}>
                  <button onClick={()=>{this.props.handleDelete(this.props.email)}} className="btn-danger" style={{marginRight:"5px"}}>delete</button>
              </div>
              
            </div>
        </div>
      );
    }
}


export default connect(
  null,
  null
)(EmailItem);