import React, { Component } from "react";
import CourseReview from "./CourseReview";
import { connect } from "react-redux";
import Loader from "react-spinners/FadeLoader";

class Statistics extends Component {

  render() {
    if (this.props.courses.length > 0) {
      return (
        this.props.courses.map(course =>
          <CourseReview title={course.name} id={course.key} />)
      );
    }

    return (
      <div className="chart-container">
        <Loader color={'#fab22d'} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  courses: state.lmsCourseModuleReducer.payload
  .filter(element => element.courseMaterial.length > 0 && element.type === 1)
  .map(element => {
    
    return {
      key: element.courseModuleId,
      name: element.name
    }
  })
})

export default connect(mapStateToProps, null)(Statistics);
