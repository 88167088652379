import { makeStyles } from '@material-ui/styles';
import firearmOwnerBackgroundImg from "../assetsNew/riffle1Dark.png";
import firearmCategoryBackgroundImg from "../assetsNew/licenseGun.jpg";
import firearmCompetencyBackgroundImg from "../assetsNew/competencyImg.png";
import firearmLicenseBackgroundImg from "../assetsNew/licenseRifleImg.png";
import selfDefenseBackgroundImg from "../assetsNew/selfDefenseImg.png";
import motivationBackgroundImg from "../assetsNew/reviewTargetMobile.jpg";

export const useStylesFirearmOwners = makeStyles(theme => ({
    container:{
        marginTop:'-22px'
    },
    aboutUsContentContainer: {
        paddingLeft: '9em', 
        paddingRight: '9em', 
        marginTop: '3em', 
        marginBottom: '3em'
    },
    firearmOwnersContainer: {
        paddingLeft: '15em', 
        paddingRight: '25em',
        marginBottom: '3em'
    },
    firearmOwnersContainerMobile: {
        paddingLeft: '1em',
        paddingRight: '1em' 
    },
    background: {
        backgroundImage: `url(${firearmOwnerBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '200px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '300px'
        },
    },
    CategoriesBackground: {
        backgroundImage: `url(${firearmCategoryBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '300px'
        }
    },
        LicenseApplicationBackground: {
            backgroundImage: `url(${firearmLicenseBackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '70%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                height: '400px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '300px'
            }
        },
        motivationApplicationBackground: {
            backgroundImage: `url(${motivationBackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '70%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                height: '400px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '300px'
            },
        },
        SelfDefenseApplicationBackground: {
            backgroundImage: `url(${selfDefenseBackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '70%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                height: '400px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '300px'
            },
        },
        competencyBackground: {
            backgroundImage: `url(${firearmCompetencyBackgroundImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '70%',
            width: '100%',
            [theme.breakpoints.down('md')]: {
                height: '400px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '300px'
            },
        },
        overviewContainer: {
            height: '500px'
        },
        overviewContainerMobile: {
            height: '300px'
        },
        overviewText: {
            color: 'white',
            textShadow: '1px 2px black',
            marginLeft: '24em',
            marginTop: '9em',
            marginBottom: '0.5em'
        },
        overviewTextMobile: {
            color: 'white',
            textShadow: '1px 2px black',
            marginLeft: '0.5em',
            marginTop: '5em',
            paddingBottom: '2em'
        },
        headerSpacing: {
            marginBottom: '0.5em'
        },
        subheaderSpacing: {
            marginBottom: '1em',
            marginTop:'0.5em'
        },
        listContainer: {
            marginLeft: '2.5em',
            marginBottom: '1em'
        },
        guidelinesContainer: {
            width: "520px",
            height: "315px",
            marginTop:'0.5em'
        },
        anchorPoint: {
            position: 'absolute'
        }
}))