import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './index.css';
import Loader from '../Loader/Loader';

const CustomTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    display: 'none',
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: theme.typography.h4.lineHeight,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h5.fontSize,
      lineHeight: theme.typography.h5.lineHeight,
    },    
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
class TabGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemIndex: props.status ? 1 : 0
    };
  }

  handleChange = (e, v) => this.setState({selectedItemIndex: v});

  renderItem = ({ label }, index) => {
    const activeStyle = index===this.state.selectedItemIndex ? 'active' : '';
    return (
      <CustomTab className={`item ${activeStyle}`} label={label} key={index}/>
    );
  }
  
  render() {
    return (
      <div className="tab-group">
        { this.props.items.length > 0 ? <CustomTabs 
          className="items" 
          value={this.state.selectedItemIndex}
          onChange={this.handleChange}
          variant="fullWidth"
        >
          {this.props.items.map((item, i) => this.renderItem(item, i))}
        </CustomTabs> : 
          <div className="d-flex py-4"></div>
        }
        <div className="tab-content">
          { this.props.loading ? <Loader /> :
            this.props.items.map((item, i) =>
              <TabPanel 
                value={this.state.selectedItemIndex}
                index={i}
                key={i}
              >{item.view}</TabPanel>)
          }
        </div>
      </div>
    );
  }
}

function TabPanel(props) {
  const { children, value, index } = props;
  return value === index && children;
}

TabGroup.defaultProps = {
  items: [],
};

TabGroup.propTypes = {
  items : PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      view: PropTypes.element.isRequired,
    }),
  ),
  loading: PropTypes.bool,
};

export default TabGroup;
