import API_URL from './apiUrl';
import axios from 'axios';

const getFireArmTypes = () => () => axios.
  get(`${API_URL}/fireArmTypes/FireArmTypes`);

const getFireArmTypesLookUpById = () => (id) => axios.
  get(`${API_URL}/fireArmTypes/FireArmTypeLookupByFireArmID/${id}`);

const getActivityLookupByFireArmTypeID = () => (id) => axios.
  get(`${API_URL}/fireArmTypes/ActivityLookupByFireArmTypeID/${id}`);

const getEventByActivityID = () => (id) => axios.
  get(`${API_URL}/fireArmTypes/EventByActivityID/${id}`);

const getDownloadByEventID = () => (id) => axios.
  get(`${API_URL}/fireArmTypes/getDownloadByEventID/${id}`);

export default function () {
  return {
    getFireArmTypes: getFireArmTypes(),
    getFireArmTypesLookUpById: getFireArmTypesLookUpById(),
    getActivityLookupByFireArmTypeID: getActivityLookupByFireArmTypeID(),
    getEventByActivityID: getEventByActivityID(),
    getDownloadByEventID: getDownloadByEventID()
  };
}