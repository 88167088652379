import axios from "axios";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { BASE, GET_DEDICATEDTYPE, GET_MEMBER_BY_EMAIL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import * as DSApplicationActions from "../../dedicatedStatusApplication/DedicatedStatusApplicationActions";
import ChangePassword from "../../editMemberDetails/ChangePassword";
import EditMemberDetails from "../../editMemberDetails/EditMemberDetails";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import "../../editMemberDetails/profile.css";
import "./userDetailStyles.css";

const UserStatus = Object.freeze({ 1: "Approved", 2: "Pending", 3: "Rejected", 4: "Renewing" })
class UserDetails extends Component {
  state = {
    _renewalDaysLeft: 0,
    componentChanger: 1
  }
  componentWillMount() {
    localStorage.removeItem("state");
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    axios.get(BASE + GET_DEDICATEDTYPE + this.props.memNo, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(res => {
      this.props.DSApplicationActions.setDSVariables("DH", res.data.hunting);
      this.props.DSApplicationActions.setDSVariables("DS", res.data.sport);
      this.props.DSApplicationActions.setDSVariables("DS_DH", res.data.both);
    });
    axios
      .get(BASE + GET_MEMBER_BY_EMAIL + this.props.emailFromToken.email, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(response => {
        this.setState({
          _renewalDaysLeft: response.data.daysLeft
        })
        this.props.onMemberDetailsChange(
          response.data.members.firstname,
          "firstname"
        );
        this.props.onMemberDetailsChange(
          response.data.members.surname,
          "surname"
        );

        this.props.onMemberDetailsChange(
          response.data.members.username,
          "username"
        );
        this.props.onMemberDetailsChange(response.data.members.email, "email");

        this.props.onMemberDetailsChange(response.data.members.idno, "idno");
        this.props.onMemberDetailsChange(
          response.data.members.cellNo,
          "cellNo"
        );
        this.props.onMemberDetailsChange(
          response.data.members.physicalAddress,
          "physicalAddress"
        );
        this.props.onMemberDetailsChange(response.data.members.city, "city");
        this.props.onMemberDetailsChange(response.data.members.suburb, "suburb");
        this.props.onMemberDetailsChange(
          response.data.members.province,
          "province"
        );
        this.props.onMemberDetailsChange(
          response.data.members.postalCode,
          "postalCode"
        );
        this.props.onMemberDetailsChange(
          response.data.members.memNo,
          "memberNumber"
        );
        this.props.onMemberDetailsChange(
          response.data.members.status,
          "userStatus"
        );
        this.props.onMemberDetailsChange(
          response.data.members.occupation,
          "occupation"
        );
        this.props.onMemberDetailsChange(
          response.data.members.inceptionDate,
          "inceptionDate"
        );
        this.props.onMemberDetailsChange(
          response.data.members.expiryDate,
          "expiryDate"
        );
      });
  }
  render() {
    var width1 = "";
    var width2 = "";
    if (isMobile) {
      width1 = "30%"
      width2 = "20%"
    } else {
      width1 = "80%"
      width2 = "35%"
    }
    const info = (row1, row2) => (
      <tbody>
        <tr>
          <td style={{ width: width1 }}>{row1}</td>
          <td className="font-weight-bold" style={{ width: width2 }}>: {row2}</td>
        </tr>
      </tbody>
    );
    const info2 = (row1, row2) => (
      <tbody>
        <tr className="font-weight-bold">
          <td style={{ width: width1, color: "red" }}>{row1}</td>
          <td style={{ width: width2, color: "red" }}>: {row2}</td>
        </tr>
      </tbody>
    );
    if (this.state.componentChanger === 1) {
      return (
        <div className="container mb-2">
          <div className="row ml-1 mr-1">
            <GenericHeader
              title="My Details"
              showEditButton
              editFunction={() => {
                this.setState({
                  componentChanger: 3
                })
              }}
              showChangePassword
              changePasswordFunction={() => {
                this.setState({
                  componentChanger: 2
                })
              }}
            />
          </div>
          <table className="ml-4">
            {info("Member No", this.props.memberDetails.memberNumber)}
            {info("Identity No", this.props.memberDetails.idno)}
            {info("Username", this.props.memberDetails.username)}
            {info("Firstname", this.props.memberDetails.firstname)}
            {info("Surname", this.props.memberDetails.surname)}

            {info("Cell Number", this.props.memberDetails.cellNo)}
            {info("Address", this.props.memberDetails.physicalAddress)}
            {info("Suburb", this.props.memberDetails.suburb)}
            {info("City", this.props.memberDetails.city)}
            {info("Province", this.props.memberDetails.province)}
            {info("Email", this.props.memberDetails.email)}
            {info("Occupation", this.props.memberDetails.occupation)}
            {info("Inception date", this.props.memberDetails.inceptionDate.toString().split("T")[0])}
            {info("Status", UserStatus[this.props.memberDetails.userStatus])}
            {info(
              "Inception Date",
              this.props.memberDetails.inceptionDate === null
                ? "-"
                : this.props.memberDetails.inceptionDate.substring(0, 10)
            )}
            {info(
              "Expiry Details",
              this.props.memberDetails.expiryDate === null
                ? "-"
                : this.props.memberDetails.expiryDate.substring(0, 10)
            )}
            {this.state._renewalDaysLeft <= 30 && this.state._renewalDaysLeft !== 0 ?
              info2("Membership Renewal Days Left", this.state._renewalDaysLeft) : null}
          </table>
        </div>
      );
    } else if (this.state.componentChanger === 2) {
      return (
        <ChangePassword />
      )
    } else if (this.state.componentChanger === 3) {
      return (
        <EditMemberDetails />
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    emailFromToken: state.loginReducer.userProfileDetails,
    loginDetails: state.loginReducer.loginDetails,
    memberDetails: state.loginReducer.memberObject,
    quizCompletion: state.lmsReducer.quizCompletion,
    assesmentList: state.lmsReducer.assesments,
    memberCity: state.loginReducer.city,
    lessonList: state.lmsReducer.lessons,
    DSApplicationState: state.DSApplicationState,
    memNo: state.loginReducer.userProfileDetails.memberNumber
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      }),
    onAssCompletionChange: assesmentCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_ASSESMENT_COMPLETION,
        assesmentCompletion: assesmentCompletion
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_DETAILS_2,
        varValue: value,
        varName: vname
      }),
    onCertificatesUpdate: certificates =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_CERTIFICATES,
        certificates: certificates
      }),
    onDedicatedChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DEDICATED_STATUS,
        varValue: value,
        varName: vname
      }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({
        type: actionTypes.UPDATE_LOYALTY_DAYS_TAB,
        currentLoyaltyDaysTab: pageNum
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    DSApplicationActions: bindActionCreators(DSApplicationActions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDetails));
