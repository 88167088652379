import React, { Component, Fragment } from "react";
import Question from "./Question";
import "bootstrap/dist/css/bootstrap.min.css";
import AddCircle from "@material-ui/icons/AddCircle";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import Image from "@material-ui/icons/Image";
import { QUESTION } from "../LMSAPIEndPoints";
import "./styles/styles.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Drag from "@material-ui/icons/DragIndicator";
import "../CourseComponents/styles/materialEditor.css";
import CustomModal from "../../../utils/CustomModal";
import {
  COURSE_MATERIAL_CHANGE_NAME,
  QUESTION_UPDATE_INDEX
} from "../LMSAPIEndPoints";

class QuizEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existingQuestion: [],
      openQuestionEditor: false,
      isUpdatingQuestionId: 0,
      updateQuestion: false,
      deleteQuestion: false,
      hoveredQuestion: null,
      modalMessage: "",
      showModal: false,
      title: null,
      save: false,
      updatedName: false
    };
    this.modalTitle = "";
    this.ModalError = null;
    this.disableCloseButton = true;
  }

  getExistingQuestions = () => {
    fetch(QUESTION + "/" + this.props.material.courseMaterialId)
      .then(res => res.json())
      .then(res => {
        this.setState({
          existingQuestion: res.data
        });
      })
      .catch(error => {
        this.setState({
          existingQuestion: []
        });
      });
  };

  refresh = () => {
    this.getExistingQuestions();
    this.setState({
      openQuestionEditor: false,
      updateQuestion: false,
      isUpdatingQuestionId: 0,
      deleteQuestion: false
    });
  };

  modalShow = (message, title, error) => {
    this.setState({
      showModal: true,
      modalMessage: message
    });
    this.modalTitle = title;
    if (error) {
      this.ModalError = message;
    } else {
      this.ModalError = null;
    }
  };

  deleteQuestion = id => {
    this.disableCloseButton = false;
    this.modalShow("Deleting", "Performing an operation on the Question");
    fetch(QUESTION + "/" + id, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => {
        res.json().then(data => {
          if (res.status !== 200) {
            this.modalShow(
              data.message,
              "Performing an operation on the Question",
              true
            );
          } else {
            this.modalShow(
              data.message,
              "Performing an operation on the Question",
              false
            );
          }
          this.refresh();
          this.disableCloseButton = false;
        });
      })
      .catch(() => {
        this.modalShow(
          "A network error occured",
          "Performing an operation on the Question",
          true
        );
        this.refresh();
        this.disableCloseButton = false;
      });
  };

  componentDidMount() {
    this.getExistingQuestions();
  }

  closeCreateQuestion = () => {
    this.setState({ openQuestionEditor: false });
  };

  closeUpdateQuestion = () => {
    this.setState({ updateQuestion: false, isUpdatingQuestionId: 0 });
  };

  addNewQuestion = () => {
    return (
      <div className="quiz-editor-new-question">
        {this.state.openQuestionEditor ? (
          <div className="w-100 text-center"> Create </div>
        ) : this.state.updateQuestion ? (
          <div className="w-100 text-center"> Update </div>
        ) : (
          <div
            className=""
            onMouseDown={() => {
              this.setState({ openQuestionEditor: true });
            }}
          >
            <AddCircle style={{ width: "30px", height: "30px" }} /> New Question
          </div>
        )}
      </div>
    );
  };

  questionHasImage = question => {
    return question.questionPictures ? (
      <div
        className="question-has-image"
        onClick={() => {
          this.setState({
            hoveredQuestion: question
          });
        }}
        onBlur={() => {
          this.setState({
            hoveredQuestion: null
          });
        }}
        tabIndex="0"
      >
        <Image />
      </div>
    ) : (
      <div className="has-no-image" />
    );
  };

  revert = (sourceIndex, destinationIndex) => {
    const newOrder = Array.from(this.state.existingQuestion);
    const [removed] = newOrder.splice(destinationIndex, 1);
    newOrder.splice(sourceIndex, 0, removed);

    this.setState({
      existingQuestion: newOrder
    });
  };

  onDragEnd = result => {
    // dropped outside the list
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.index === destination.index) {
      return;
    }

    const newOrder = Array.from(this.state.existingQuestion);
    const [removed] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, removed);

    this.setState({
      existingQuestion: newOrder
    });

    newOrder.forEach((q, index )=> {
      fetch(QUESTION_UPDATE_INDEX + `/${q.questionId}/${index + 1}`, {
        method: "POST"
      })
        .then(res => {
          if (res.status !== 200) {
            this.revert(source.index, destination.index);
          }
        })
        .catch(err => {
          this.revert(source.index, destination.index);
        });
    });
  };

  existingQuestions = () => {
    return this.state.existingQuestion.length > 0 ? (
      <div className="quiz-editor-existing-question">
        <div className="w-100 text-center h6">Existing Questions</div>
        {this.showHoveredPicture()}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={"questionDropable"} type="question">
            {(provided, snapshot) => (
              <div
                className="quiz-editor-existing-question-body"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {this.state.existingQuestion.map((question, index) => {
                  if (question.questionId !== this.state.isUpdatingQuestionId) {
                    return (
                      <Draggable
                        key={index}
                        draggableId={question.questionId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="d-flex question p-2 mb-1 bg-white"
                            ref={provided.innerRef}
                            
                            {...provided.draggableProps}
                          >
                            <div
                              {...provided.dragHandleProps}
                              id="folder-child"
                            >
                              <Drag />
                              {this.questionHasImage(question)}
                            </div>
                            <span
                              className="w-75"
                              onClick={() => {
                                this.setState({
                                  isUpdatingQuestionId: question.questionId,
                                  updateQuestion: true
                                });
                              }}
                            >
                              {question.questionTitle}
                            </span>
                            {this.state.deleteQuestion ? null : (
                              <Fragment>
                                <Edit
                                  onClick={() => {
                                    this.setState({
                                      isUpdatingQuestionId: question.questionId,
                                      updateQuestion: true
                                    });
                                  }}
                                />
                                <Delete
                                  onClick={() => {
                                    this.setState({
                                      deleteQuestion: true
                                    });
                                    this.deleteQuestion(question.questionId);
                                  }}
                                />
                              </Fragment>
                            )}
                          </div>
                        )}
                      </Draggable>
                    );
                  } else {
                    return (
                      <div key={index} className="col-md-12">
                        <Question
                          update={true}
                          question={question}
                          refresh={this.refresh}
                          close={this.closeUpdateQuestion}
                          modalShow={this.modalShow}
                          modalCloseButton={this.closeButton}
                        />
                      </div>
                    );
                  }
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    ) : null;
  };

  showHoveredPicture = () => {
    return this.state.hoveredQuestion ? (
      <div className="picture-tool-tip">
        <div>
          <img
            alt="Sorry"
            src={this.state.hoveredQuestion.questionPictures.url}
            width={"200px"}
            height={"200px"}
          />
        </div>
      </div>
    ) : null;
  };

  modalClose = () => {
    this.modalTitle = "";
    this.ModalError = null;
    this.setState({
      showModal: false,
      modalMessage: ""
    });
  };

  closeButton = value => {
    this.disableCloseButton = value;
  };

  onUpdateCourseMaterial = id => {
    this.setState({
      saveMessageStatus: (
        <div className="text-warning d-inline-block flex-grow-1">Saving...</div>
      ),
      save: true
    });

    fetch(COURSE_MATERIAL_CHANGE_NAME + `/${id}/${this.state.title}`, {
      method: "POST"
    })
      .then(res => res.json())
      .then(res => {
        this.props.refresh();
        this.setState({
          saveMessageStatus: (
            <div className="text-success d-inline-block flex-grow-1">
              {res.message}
            </div>
          )
        });
        setTimeout(() => {
          this.setState({
            save: false,
            updatedName: true
          });
        }, 2000);
      })
      .catch(error => {
        this.setState({
          saveMessageStatus: (
            <div className="text-danger d-inline-block flex-grow-1">
              Failed to save! {error.message ? error.message : "Try again"}
            </div>
          )
        });
        setTimeout(() => {
          this.setState({
            save: false
          });
        }, 2000);
      });
  };

  header = () => {
    return (
      <div className="material-editor-header">
        <div
          className="material-editor-header-back-button"
          onClick={() => {
            this.props.openEditor(false);
          }}
        >
          <KeyboardArrowLeft style={{ width: "50px", height: "35px" }} />
        </div>
        {this.state.save ? (
          this.state.saveMessageStatus
        ) : (
          <div className="input-container">
            <div className="material-editor-header-input">
              <input
                className="form-control"
                type="text"
                placeholder="Add title"
                defaultValue={
                  this.state.updatedName
                    ? this.state.title
                    : this.props.material.name
                }
                onChange={e => {
                  this.setState({
                    title: e.target.value
                  });
                }}
              />
            </div>
            <div className="material-editor-header-save-button">
              <button
                className="btn btn-outline-success form-control"
                onClick={() => {
                  this.onUpdateCourseMaterial(
                    this.props.material.courseMaterialId
                  );
                }}
              >
                Update Name
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    return (
      <div
        className="quiz-editor-container"
        onClick={() => {
          if (this.state.hoveredQuestion !== null) {
            this.setState({
              hoveredQuestion: null
            });
          }
        }}
      >
        <div className="course-mananagement-body">
          {this.header()}
          <div className="quiz-editor-body">
            <div>
              {this.addNewQuestion()}
              {this.state.openQuestionEditor ? (
                <div className="col-md-12">
                  <Question
                    materialId={this.props.material.courseMaterialId}
                    refresh={this.refresh}
                    close={this.closeCreateQuestion}
                    modalShow={this.modalShow}
                    modalCloseButton={this.closeButton}
                  />
                </div>
              ) : null}
            </div>
            {this.existingQuestions()}
          </div>
        </div>
        <CustomModal
          show={this.state.showModal}
          disableCloseButton={this.disableCloseButton}
          onHide={this.modalClose}
          error={this.ModalError}
          title={this.modalTitle}
          message={this.state.modalMessage}
        />
      </div>
    );
  }
}

export default QuizEditor;
