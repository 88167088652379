import React, { Component } from 'react';
import { CloudDownload } from '@material-ui/icons';
import PropType from 'prop-types';

import {
  EditableGridLabel,
  IconLabelButton,
  SubtitleBar,
  Loader,
} from '../index';
import {
  checkValidity,
  updateObject
} from '../../shared/utility';
import {labelHtml} from '../../shared/helpers';
import {
  editableTextInputConfig,
  editableSelectConfig,
  saProvinces
} from '../../shared/constants';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import ProfilePicture from './ProfilePicture';

class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myDetails: {...props.myDetails},
      editDetails: false,
      loading: false,
      controls: this.initControls(),
      formHasErrors: true,
      personalAssisstant : props.myDetails.personalAssisstant
    };

  }

  initSelect = (items) => ({
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
    }
  });

  initControls = () => ({
    nickName: {...editableTextInputConfig},
    firstName: {...editableTextInputConfig, editable: false},
    surname: {...editableTextInputConfig, editable: false},
    occupation: {...editableTextInputConfig},
    identityNumber: {...editableTextInputConfig, editable: false},
    cellNumber: {...editableTextInputConfig},
    emailAddress: {...editableTextInputConfig},
    streetAddress: {...editableTextInputConfig},
    postalCode: {...editableTextInputConfig},
    city: {...editableTextInputConfig},
    suburb: {...editableTextInputConfig},
    province: this.initSelect(saProvinces),
  });

  gridLabel = (title, value, danger, isMobile) => (
    <Grid item container xs={12} sm={6} md={4} direction="column">
      <Grid item className="inner-shadow border-radius-2 py-2 bg-white-2 word-break">
        <Typography 
          variant={isMobile ? 'h6' : 'body2'} 
          align="left"
          style={danger ? { color: '#FF0000' } : null}
        >
          {typeof value === 'number' ? value || 0 : value || '-'}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="left">{title || '-'}</Typography>
      </Grid>
    </Grid>
  );

  editableGridLabel = (title, name, isMobile) => (
    <EditableGridLabel
      title={title}
      value={this.state.myDetails[name]}
      options={this.state.controls[name]}
      edit={this.state.editDetails}
      onChange={this.handleLabelChange(name)}
      isMobile={isMobile}
    />
  );

  handleLabelChange = controlName => event => {
    const newValue = event.target.value;
    const validity = checkValidity (newValue, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });
    this.setState({
      controls,
      myDetails: {
        ...this.state.myDetails,
        [controlName]: newValue
      },
      formHasErrors,
    });
  };

  handleSubmit = () => {
    const data = {
      ...this.state.myDetails,
      memNo: this.props.memNo,
      cellNo: this.state.myDetails.cellNumber,
      email: this.state.myDetails.emailAddress,
      suburb: this.state.myDetails.suburb,
      physicalAddress: this.state.myDetails.streetAddress,
    };
    this.setState({ loading: true, editDetails: false });
    this.props.updateMemberDetailsCallback(data)
      .then(() => this.props.fetchMemberData(this.props.memNo))
      .finally(() => this.setState({loading: false}));
  }

  cancelForm = () => {
    this.setState({
      myDetails: {...this.props.myDetails},
      editDetails: false,
      formHasErrors: true,
      controls: this.initControls(),
    });
  }

  render() {
    const myStatus = this.props.myStatus;
    const isMobile = localStorage.getItem('isMobile') === 'true';
	const _remainingDays = this.props.daysLeft
    return (
      <div>
        <SubtitleBar 
          variant="subtitle1" 
          title="My status"
        />
        <Grid container spacing={1}>
          <Grid item xs={12} md={4} lg={2} container alignContent="center" justify="center">
            <ProfilePicture large src={this.props.profilePicture} callBack={this.props.uploadProfilePictureCallback}/>
          </Grid>
          <Grid item container xs={12} md={8} lg={10} spacing={4}>
            {this.gridLabel('Membership Number', this.props.memNo, false, isMobile)}
            {this.gridLabel('Membership Inception', myStatus.inceptionDate, false, isMobile)}
            {this.gridLabel('Membership Expiry', myStatus.expiryDate, false, isMobile)}
            
            {/* Hide this if member doesn't belong to any partner */}
            {this.props.partnerInformation.showPartnerMembership && <>
            {this.gridLabel(this.props.partnerInformation.PartnerName + ' Membership', this.props.partnerInformation.membershipType, false, isMobile)}
            {this.gridLabel(this.props.partnerInformation.PartnerName + ' Expiry Date', this.props.partnerInformation.PartnerExpiryDate, false, isMobile)}
            </>}
            {this.gridLabel('Narfo Membership', myStatus.membership, false, isMobile)}
            {this.gridLabel('Current Year', myStatus.currentYear, false, isMobile)}
            {this.gridLabel('Membership Status', myStatus.status, false, isMobile)}
            {this.gridLabel('Approval Date', myStatus.approvalDate, false, isMobile)}
            {this.gridLabel('Dedicated Sports Shooter', myStatus.dedicatedSportsStatus, false, isMobile)}
            {this.gridLabel('Dedicated Hunter', myStatus.dedicatedHunterStatus, false, isMobile)}
            {this.gridLabel('Loyalty days', myStatus.loyaltyDays, false, isMobile)}
            {this.gridLabel('Expired firearms', myStatus.expiredFirearms, false, isMobile)}
            {this.gridLabel('Firearms to be renewed', myStatus.firearmsToBeRenewed, false, isMobile)}
            {this.gridLabel('Firearms', myStatus.firearms, false, isMobile)}
            {_remainingDays <= 30 && this.gridLabel('Remaining days', _remainingDays, true, isMobile)}
          </Grid>
        </Grid>

        <SubtitleBar
          variant="subtitle1" title="My details" edit={!this.state.editDetails} 
          callBack={() => this.setState({editDetails:true})}
        />
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
          <SubtitleBar variant="subtitle2" title="Personal Details"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Nick Name', 'nickName', isMobile)}
            {this.editableGridLabel('First Name', 'firstName', isMobile)}
            {this.editableGridLabel('Surname', 'surname', isMobile)}
            {this.editableGridLabel('Occupation', 'occupation', isMobile)}
            {this.editableGridLabel('Identification Number', 'identityNumber', isMobile)}
          </Grid>
          <SubtitleBar variant="subtitle2" title="Contact Details"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Cell Number', 'cellNumber', isMobile)}
            {this.editableGridLabel('Email Address', 'emailAddress', isMobile)}
          </Grid>
          <SubtitleBar variant="subtitle2" title="Residential Address"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Street Address', 'streetAddress', isMobile)}
            {this.editableGridLabel('suburb', 'suburb', isMobile)}
            {this.editableGridLabel('Postal Code', 'postalCode', isMobile)}
            {this.editableGridLabel('City', 'city', isMobile)}
            {/* {this.editableGridLabel('Province', 'province', isMobile)} */}
            {this.editableGridLabel('Province', 'province', isMobile)}
        
          </Grid>
        </div>
        {this.state.editDetails && <div className="d-flex py-2">
          <IconLabelButton disabled={this.state.formHasErrors} full actionText= {isMobile ? "Save" :"Save Changes"} color="primary" callBack={this.handleSubmit}/>
          <IconLabelButton actionText="cancel" full callBack={this.cancelForm}/>
        </div>}
        {this.state.loading && <Loader color="primary" />}

        <SubtitleBar variant="subtitle1" title={isMobile ? 'Card and Certificate' : 'My Membership Card and Certificate'}/>
        <div className="pb-3">
          <IconLabelButton
            disabled={this.props.isPartnerMember}
            helpText="Download your membership card"
            actionText="Download"
            icon={<CloudDownload />}
            full
            callBack={() => this.props.downloadMemberCardCallback()}
          />
        </div>
        <div className="pb-3">
          <IconLabelButton
            disabled={this.props.isPartnerMember}
            helpText="Download your membership certificate"
            actionText="Download"
            icon={<CloudDownload />}
            full
            callBack={() => this.props.downloadMemberCertificateCallback()}
          />
        </div>

      </div>
    );
  }
}

ProfileDetails.defaultProps = {
  myDetails: {},
  myStatus: {},
  fetchMemberData: () => null,
  downloadMemberCardCallback: () => null,
  downloadMemberCertificateCallback: () => null,
  uploadProfilePictureCallback: (result, file) => Promise.resolve({result, file}),
  updateMemberDetailsCallback: (data) => Promise.resolve(data),
};

ProfileDetails.propTypes = {
  myDetails: PropType.shape({
    nickName: PropType.string,
    firstName: PropType.string,
    surname: PropType.string,
    occupation: PropType.string,
    identityNumber: PropType.string,
    cellNumber: PropType.string,
    emailAddress: PropType.string,
    streetAddress: PropType.string,
    suburb: PropType.string,
    postalCode: PropType.string,
    city: PropType.string,
    province: PropType.string,
  }).isRequired,
  myStatus: PropType.shape({
    currentYear: PropType.number,
    approvalDate: PropType.string,
    membership: PropType.string,
    status: PropType.string,
    inceptionDate: PropType.string,
    expiryDate: PropType.string,
    dedicatedSportsStatus: PropType.string,
    dedicatedHunterStatus: PropType.string,
    loyaltyDays: PropType.number,
    expiredFirearms: PropType.number,
    firearmsToBeRenewed: PropType.number,
    firearms: PropType.number,
    memberNumber: PropType.string
  }).isRequired,
  profilePicture: PropType.string,
  memNo: PropType.string,
  fetchMemberData: PropType.func.isRequired,
  uploadProfilePictureCallback: PropType.func.isRequired,
  updateMemberDetailsCallback: PropType.func.isRequired,
};

export default ProfileDetails;
