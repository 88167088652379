import React,{useEffect,useState} from 'react';
import { useStylesSportShooting } from "../../../stylesNew/SportShootingStyles";
import Grid from '@material-ui/core/Grid';
import WPFireArmTypes from './WPFireArmTypes';
import FirearmTypeService from '../../services/firearmType.service'; 

//For wordpress 
function TargetsDownload(props) {
    const classesSportShooting = useStylesSportShooting();
    const firearmTypeService = FirearmTypeService();
    const [backgroundImage,] = useState(useStylesSportShooting().targetsDownloadBackground); 
    const [firearmTypesList,setFirearmTypesList] = useState(null); 

    useEffect(() => {
        //Load firearm types 
        firearmTypeService.getFireArmTypes().then(response =>{
            setFirearmTypesList( response.data);
        })
        .catch(error => {
           alert("an error occured")
        });

        if(props.changeBacgroundImage)
            props.changeBacgroundImage(null)
      }, []);

    return (
        <Grid container direction="column">
        <Grid container style={{height:'320px', width:'90%'}} className={backgroundImage}>
        </Grid>

        <React.Fragment>
            <Grid
                container
                direction='column'
                className={classesSportShooting.targetDownloadsContainer}>
                
               
                {firearmTypesList &&<WPFireArmTypes fireArmTypes={firearmTypesList}/>}
            </Grid>
        </React.Fragment>
        </Grid>
    
    );
}

export default TargetsDownload