import React, { Component } from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";

export class ViewResults extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="row m-2 h4 position-sticky"
          style={{ borderBottom: "2px Solid" }}
        >
          <div className="col-1 d-flex align-items-center justify-content-center pl-0 mw-25">
            <button
              className="btn btn-black pl-0 "
              onMouseDown={() => this.props.onCurrentLMSTabChange(4)}
            >
              <KeyboardArrowLeft style={{ width: "45px", height: "30px" }} />
            </button>
          </div>
          <div className="col-10 d-flex pt-2 align-items-center h6 pt2">
            {this.props.oneLesson.title} Quiz
          </div>
        </div>
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            {this.props.resultsRecord.map((value, index) => (
              <div key={index} className="row  m-1 border-bottom">
                <div className="col-6 h6" align="left">Question {value.question} </div>
                <div className="col-6" align="right">
                  {value.score < 75 ? (
                    <HighlightOff
                      style={{
                        width: "45px",
                        height: "30px",
                        color: "red"
                      }}
                    />
                  ) : (
                    <CheckCircleOutline
                      style={{
                        width: "45px",
                        height: "30px",
                        color: "green"
                      }}
                    />
                  )}
                </div>
                
              </div>
            ))}
          </div>
          <div className="col-1" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    oneLesson: state.lmsReducer.lesson,
    resultsRecord: state.lmsReducer.resultsRecord
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewResults);
