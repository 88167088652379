export const SET_EVENT_LOOKUP = 'SET_EVENT_LOOKUP';
export const SET_DISCIPLINES = 'SET_DISCIPLINES';
export const SET_FIREARM_TYPES = 'SET_FIREARM_TYPES';
export const SET_FIREARM_TYPES_LOOKUP = 'SET_FIREARM_TYPES_LOOKUP';
export const SET_OPTICS_LOOKUP = 'SET_OPTICS_LOOKUP';
export const SET_ACTIVITY_TYPE = 'SET_ACTIVITY_TYPE';
export const SET_SPECIE_TYPE = 'SET_SPECIE_TYPE';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';
export const SET_ACTION_TYPE = 'SET_ACTION_TYPE';
export const SET_ENDORSEMENT_APPLICATION_TYPE = 'SET_ENDORSEMENT_APPLICATION_TYPE';
export const SET_SECTION_LOOKUP = 'SET_SECTION_LOOKUP';
export const SET_PURPOSE_LOOKUP = 'SET_PURPOSE_LOOKUP';