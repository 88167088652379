import React, { Component } from "react";

class DiciplinaryCode extends Component {
  render() {
    return (
      <div className="container text-center pr-4 pl-4">
        <h3 align="center" className="font-weight-bold p-3 mb-0">
          Diciplinary Code
        </h3>
        <p className="p-3">
          Every member of the association shall be bound to the code of conduct,
          subscribe to the policies and objectives and or any other by-laws of
          the association.
        </p>
        <ol align="left">
          <li>
            Any contravention of the codes or policies shall lead to the
            immediate termination of membership without compensation or refund
            of membership fees or any other fees that have been paid.{" "}
          </li>
          <li>
            On termination of membership, all support, negotiations,
            applications and endorsement processes shall be ceased.
          </li>
          <li>
            On termination of membership, the association has the right and duty
            to inform any of the authorities of the change in membership and
            provide all necessary proof and documentation in support of the
            termination.
          </li>
          <li>
            The following actions are seen in a serious light and will
            subsequently also lead to the immediate termination of membership
          </li>
        </ol>
        <ul>
          <li>
            No member shall act in any way that could discredit the association
          </li>
          <li>
            No member shall breach and/or contravene any provision of The
            Constitution of the Republic of South Africa Act 108 of 1996, a
            National Act or Provincial Act of the Republic of South Africa,
            FIREARMS CONTROL ACT, 2000 or any Regulations promulgated there
            under, or any Provincial Ordinance, where such breach and/or
            contravention is relevant to any of the policies and objectives of
            the Association
          </li>
          <li>
            No member shall provide any false information relating to any
            relevant information required to obtain, apply for or use any fire
            arm or provide false information during the application process of
            becoming a member
          </li>
          <li>No member shall falsify any document of the association</li>
          <li>
            A member shall at all times disclose any change in material facts
            during the period of membership Any criminal record
          </li>
          <li>
            The decision to terminate the membership of any member is at the
            sole discretion of the board of directors of the association and is
            deemed to be final.
          </li>
        </ul>
      </div>
    );
  }
}
export default DiciplinaryCode;
