import axios from 'axios';
import API_URL from './apiUrl';

const getCourseModules = headers => () => axios
  .get(`${API_URL}/CourseModule/withChildren`, { headers });

// const getCourseModules = headers => () => axios
//   .get(`${API_URL}/CourseModule/GetCourseModules`, { headers });


const getMemberAssessments = headers => memNo => axios
  .get(`${API_URL}/MemberAssesments/${memNo}`, { headers });

const getQuestionById = headers => id => axios
  .get(`${API_URL}/question/${id}`, { headers });

const getCourseMaterialById = headers => id => axios
  .get(`${API_URL}/CourseMaterial/getModuleID/${id}`, { headers });

const getCourseModuleNameById = headers => id => axios
  .get(`${API_URL}/CourseModule/getModuleName/${id}`, { headers });

const postMemberAssessment = headers => data => axios({
  method: 'post',
  url: `${API_URL}/MemberAssesments`,
  data: data,
  headers: headers
});

const getMemberAssessmentsByMemberNo = headers => memNo => axios
  .get(`${API_URL}/MemberAssesments/${memNo}`, { headers });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const typedHeaders = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };

  return {
    getCourseModules: getCourseModules(headers),
    getMemberAssessments: getMemberAssessments(headers),
    getQuestionById: getQuestionById(headers),
    getCourseMaterialById: getCourseMaterialById(headers),
    getCourseModuleNameById: getCourseModuleNameById(headers),
    postMemberAssessment: postMemberAssessment(typedHeaders),
    getMemberAssessmentsByMemberNo: getMemberAssessmentsByMemberNo(headers)
  };
}