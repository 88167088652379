import API_URL from './apiUrl';
import axios from 'axios';

//Commissions for the current financial year
const getSalesCommissions = header => (id, year, type) => axios.
  get(`${API_URL}/commission/GetCommissionGrByComNo/${id}/${year}${type ? `?commissionType=${type}` : ''}`, {header});

//Commissions fo
const getAllSalesCommissions = header => (id, type) => axios.
  get(`${API_URL}/commission/GetAllCommissionsByComNo/${id}/${type ? `?commissionType=${type}` : ''}`, {header});

const getCommissionDue = header => (memNo) => axios.
  get(`${API_URL}/commission/GetAllPartnerCommissionDue/${memNo}`,{header});

const getAllSalesRepCommission = header => (memNo) => axios.
  get(`${API_URL}/commission/GetAllSalesRepCommissionDue/${memNo}`,{header});

const getUserRoles = header => id => axios.
  get(`${API_URL}/admin/getuserrole/${id}`, {header});

const updateBankingDetails = header => payload => axios.
  post(`${API_URL}/admin/updateRepBankingDetails/`, payload , {header});

const getSalesReps = headers => () => axios({
	url: `${API_URL}/admin/sales`,
	method: "get",
	headers: headers
  });

const getSalesRepsByPartnerId = headers => (id) => axios({
  url: `${API_URL}/salesReps/GetSalesRepsByPartnerId/${id}`,
  method: "get",
  headers: headers
  });

const getMemberRoles = headers => (id) => axios({
  url: `${API_URL}/Member/GetMemberRoles?memNo=${id}`,
  method: "get",
  headers: headers
  });
  

const getMembersByPartnerId = headers => (id) => axios({
  url: `${API_URL}/salesReps/GetMembersByPartnerId/${id}`,
  method: "get",
  headers: headers
  });

const getMembersBySalesRepMemNo = headers => (memNo) => axios({
  url: `${API_URL}/salesReps/GetSalesRepMembers?memNo=${memNo}`,
  method: "get",
  headers: headers
  });

const getPartners = headers => () => axios({
	url: `${API_URL}/Stores`,
	method: "get",
	headers: headers
  });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getSalesCommissions: getSalesCommissions(headers),
    getAllSalesCommissions: getAllSalesCommissions(headers),
    getUserRoles: getUserRoles(headers),
    updateBankingDetails: updateBankingDetails(headers),
	  getSalesReps: getSalesReps(headers),
	  getPartners: getPartners(headers),
    getCommissionDue: getCommissionDue(headers),
    getSalesRepsByPartnerId:getSalesRepsByPartnerId(headers),
    getMembersByPartnerId:getMembersByPartnerId(headers),
    getMemberRoles:getMemberRoles(headers),
    getMembersBySalesRepMemNo: getMembersBySalesRepMemNo(headers),
    getAllSalesRepCommission: getAllSalesRepCommission(headers)
  };
}
