import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  MyDocuments,
  Loader,
} from '../../components';
import Container from '../../hoc/Container';
import DocumentsActions from '../../store/Documents/actions';
import DocumentsService from '../../services/documents.service';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.documentsService = DocumentsService(this.props.token);
    this.documentsActions = this.props.documentsActions(this.documentsService);

    this.state = {
      loading: false,
    };
  }
  componentDidMount() {
    this.setState({loading: true});
    this.documentsActions.fetchDocuments()
      .finally(() => this.setState({loading:false}));
  }
  render() {
    return (
      <Container title="Documents">
        {this.state.loading && <Loader/> ||
        <MyDocuments
          documents={this.props.documents}
          loading={this.state.loading}
          documentFilterItems={{
            documentTypes: this.props.documentTypeLookup.map(e => ({
              value: e.documentType,
              label: e.documentType,
            })),
          }}
        />}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  documents: state.nDocumentsReducer.documents,
  documentTypeLookup: state.nLookupReduder.documentTypes
});

const initDocumentsActions = dispatch => documentsService => {
  const actions = DocumentsActions(documentsService);
  return {
    fetchDocuments: (id) => dispatch(actions.fetchDocuments(id)),
  };
};

const mapDispatchToProps = dispatch => ({
  documentsActions: initDocumentsActions(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);