import * as actionTypes from "../actions/actionTypes";

const initialState = {
    huntingTab: 0,
    sportShooting: 0
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_HUNTING_CONTENT:
      return {
          ...state,
          huntingTab: action.huntingTab
      };
    case actionTypes.UPDATE_SPORT_SHOOTING_CONTENT:
      return {
          ...state,
          sportShooting: action.sportShooting
      };
    default:
      return state;
  }
};
export default reducer;