import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  AllCommissions,
  BankDetails,
  TabGroup
} from '../../components';
import Container from '../../hoc/Container';
import CommissionsService from '../../services/commissions.service';
import CommissionsActions from '../../store/SalesCommissions/actions';
import { printSalesCommission } from '../../shared/pdfHelpers';
import { getMemberStatusFromInt } from '../../shared/helpers';
import { financialYear} from '../../shared/helpers';
import MemberService from '../../services/member.service';

export class SalesCommission extends Component {
  constructor(props) {
    super(props);
    this.commissionsService = CommissionsService(this.props.token);
    this.memberService = MemberService(this.props.token);
    this.commissionsActions = this.props.commissionsActions(this.commissionsService);
  }
  componentDidMount() {

    // let financialYear = new Date().getFullYear(); //Used current year instead of finacial year
    // this.commissionsActions.fetchSalesCommissions(this.props.memNo, financialYear)
    //   .then(() => this.commissionsActions.fetchBankingDetails(this.props.memNo));
    this.commissionsActions.fetchAllSalesCommissions(this.props.memNo)
    .then(() => this.commissionsActions.fetchBankingDetails(this.props.memNo));
  }

  downloadCommission = (comm, identifier) => {
    this.memberService.getAllImages()
      .then((images) => printSalesCommission(
        comm, { 
          ...this.props.memberDetails,
          status: getMemberStatusFromInt(this.props.memberDetails.status),
        },
        images.data.narfo,
        identifier,
      ));
  };

  updateAccountDetails = (data) => this.commissionsService
    .updateBankingDetails(data)
    .then(() => this.commissionsActions.fetchBankingDetails(this.props.memNo));

  filterCommissions = (result) => this.commissionsActions
    .fetchSalesCommissions(
      this.props.memNo,
      result['year'] || financialYear,
      result['commissionType']
    );
  
  tabViews = (isMobile)  => [
    {
      label: isMobile ? 'Commissions' : 'All commission',
      view: <AllCommissions 
        commissions={this.props.commissions}
        downloadCommission={this.downloadCommission}
        filterCommissions={this.filterCommissions}
      />
    },
    {
      label: 'Bank Details',
      view: <BankDetails 
        bankAccountDetails={this.props.bankingDetails}
        updateAccountDetails={this.updateAccountDetails}
      />
    }
  ];

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <Container title="Sales Commissions">
        <TabGroup items={this.tabViews(isMobile)} />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  commissions: state.nCommissionReducer.commissions,
  bankingDetails: state.nCommissionReducer.bankingDetails,
  memberDetails: state.ProfileReducer.memberData,
});

const initCommissionsActions = dispatch => salesCommissionsService => {
  const actions = CommissionsActions(salesCommissionsService);
  return {
    fetchSalesCommissions: (id, year, type) => dispatch(actions.fetchSalesCommissions(id, year, type)),
    fetchAllSalesCommissions: (id, type) => dispatch(actions.fetchAllSalesCommissions(id, type)),
    fetchBankingDetails: (id) => dispatch(actions.fetchBankingDetails(id)),
  };
};

const mapDispatchToProps = dispatch => ({
  commissionsActions: initCommissionsActions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesCommission);
