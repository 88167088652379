import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import * as NewActActions from "../../loggeddInUser/activitiesPage/NewActivityActions";
import ActivityInfo from "./PendingActivitySubComponents/ActivityInfo";
import ActivitySummary from "./PendingActivitySubComponents/ActivitySummary";
import ApprovalSection from "./PendingActivitySubComponents/ApprovalSection";
import * as PendingActivityActions from "./PendingActivitySubComponents/PendingActivityActions";

class ApprovePendingActivities extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <div className={this.props.isPartner? "" :"container"}>
        <GenericHeader
          showBackButton={true}
          backFunction={() => this.props.onCurrActivityTabChange(0)}
          title="Activity Approval"
        />
        <ActivitySummary activity={this.props.pendingActivityState.activity} />
        <ActivityInfo activity={this.props.pendingActivityState.activity} isPartner={this.props.isPartner}/>
        {!this.props.isPartner && <ApprovalSection
          activity={this.props.pendingActivityState.activity}
          approvalStatus={this.props.pendingActivityState.approvalStatus}
          approveActivity={this.props.PendingActivityActions.approvePending}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    newActivityState: state.newActivityState,
    pendingActivityState: state.pendingActivityState
  };
};

const styles = theme => ({});

const mapActionsToProps = dispatch => ({
  onCurrActivityTabChange: newCurrActivityTab =>
    dispatch({
      type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
      currActivityTab: newCurrActivityTab
    }),
  PendingActivityActions: bindActionCreators(PendingActivityActions, dispatch),
  NewActActions: bindActionCreators(NewActActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ApprovePendingActivities));
