import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import * as actionTypes from "../../store/actions/actionTypes";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import Progress from "./memberApplicationProgress";
import "./styles/memberDetailsStyles.css";
import { NextPageButton, POPIActInfo } from "./navigatorButtons";

function isNumeric(value) {
  return /^\d+$/.test(value);
}

class MemberApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextPage: false,
      children: ["", "", "", "0000", "not provided", "", ""],
      ethinicitySelected: false,
      genderSelected: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (name, index) => event => {
    let array = this.state.children;
    array[index] = event.target.value;
    this.setState({
      children: array
    })
    this.props.onMemberDetailsChange(event.target.value, name);
    this.checkNotEmpty();
  };

  handleOptionChange = changeEvent => {
    let array = this.state.children;
    array[6] = changeEvent.target.value;
    this.setState({
      children: array
    })
    this.props.onMemberDetailsChange(changeEvent.target.value, "Sex");
    this.checkNotEmpty();
  };

  checkNotEmpty = () => {
    let isNextPage = true;
    this.state.children.map((child, index) => {
      if (child === "" || child === null || child === undefined || child === "Ethnicity") {
        isNextPage = false;
      }
      if (index === 5 && (child === "Ethnicity" || child === "")) {
        this.setState({
          ethinicitySelected: false
        })
      } else if (index === 5 && child !== "Ethnicity") {
        this.setState({
          ethinicitySelected: true
        })
      }
      if (index === 6 && !(child === "Male" || child === "Female")) {
        this.setState({
          genderSelected: false
        })
      } else if (index === 6 && (child === "Male" || child === "Female")) {
        this.setState({
          genderSelected: true
        })
      }
      return null;
    })
    this.setState({
      nextPage: isNextPage
    });
  }

  componentWillUnmount() {
    if (!this.props.memDetails.CellNo) {
      this.props.onMemberDetailsChange("0000", "CellNo");
    }

    if (!this.props.memDetails.Occupation) {
      this.props.onMemberDetailsChange("not Provided", "Occupation");
    }
    this.props.onMemberDetailsChange(this.state.children, "children");
    sessionStorage.setItem("CA-NM", this.state.children[0])
    sessionStorage.setItem("CA-SU", this.state.children[1])
  }

  componentDidMount() {
    this.checkNotEmpty();
  }

  componentWillMount() {
    this.setState({
      children: this.props.memDetails.children ? this.props.memDetails.children : this.state.children
    })
    // custom rule will have name ateast 1 characters required
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (!value) {
        return false;
      }
      if (value.length <= 1) {
        return false;
      }
      return true;
    });
    //Exactly 13 didgits South African ID Number
    ValidatorForm.addValidationRule("isIDNo", value => {

      if (!value || value.length > 13) {
        return false;
      }
      return true;
    });
    //Exactly 10 digits number - South African number
    ValidatorForm.addValidationRule("isCellNo", value => {
      if (!value) {
        return false;
      }
      if (value.length !== 10) {
        return false;
      }
      return isNumeric(value);
    });

    //Select ethnicity
    ValidatorForm.addValidationRule("ethnicitySelected", value => {
      if (!value) {
        return false;
      }
      return value === "Ethnicity";
    })
  }

  render() {
    const Ethnicity = [
      "Ethnicity",
      "White",
      "Black",
      "Coloured",
      "Indian",
      "Other"
    ];
    return (
      <div className="member-details-root">
        <Progress progress={1} />
        <div className="member-details-form">
          {POPIActInfo()}
          <br />
          <ValidatorForm
            align="center"
            autoComplete="off"
            onSubmit={() => { this.checkNotEmpty() }}
          >
            <div className="member-details-text-input-parent">
              <label> Full Name </label>
              <div className="member-details-text-input">
                <TextValidator
                  FirstName
                  name="FirstName"
                  type="text"
                  validate
                  id="OutlinedName"
                  value={this.props.memDetails.Firstname}
                  onChange={this.handleChange("Firstname", 0)}
                  validators={["required", "isEmpty"]}
                  errormessages={[
                    "Full Name can not be left empty",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Surname </label>
              <div className="member-details-text-input">
                <TextValidator
                  Surname
                  name="Surname"
                  id="OutlinedSurame"
                  type="text"
                  value={this.props.memDetails.Surname}
                  onChange={this.handleChange("Surname", 1)}
                  validators={["isEmpty", "required"]}
                  errormessages={[
                    "Surname can not be left empty",
                    "This field is required",
                    "Only numbers required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> ID Number </label>
              <div className="member-details-text-input">
                <TextValidator
                  IDNumber
                  name="IDNo"
                  id="OutlinedID"
                  type="text"
                  value={this.props.memDetails.IDNo}
                  onChange={this.handleChange("IDNo", 2)}
                  validators={["isIDNo", "isEmpty", "required"]}
                  errormessages={[
                    "Invalid ID Number",
                    "This field is required",
                    "Only numbers required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent" style={{display: "none"}}>
              <label> Cell Number </label>
              <div className="member-details-text-input">
                <TextValidator
                  Cellphome
                  Number
                  name="CellNo"
                  id="OutlinedCell"
                  type="text"
                  value={!this.props.memDetails.CellNo?this.props.memDetails.CellNo:null}
                  onChange={this.handleChange("CellNo", 3)}
                  margin="normal"
                  variant="outlined"
                  placeholder="Optional"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Occupation </label>
              <div className="member-details-text-input">
                <TextValidator
                  Occupation
                  name="Occupation"
                  type="text"
                  id="OutlinedOccupation"
                  value={this.props.memDetails.Occupation}
                  onChange={this.handleChange("Occupation", 4)}
                  margin="normal"
                  variant="outlined"
                  placeholder="Optional"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent ethnicity">
              <label> Ethnicity </label>
              <div className="member-details-text-input">
                <FormControl variant="Outlined">
                  <Select
                    Ethinicity
                    native
                    type="text"
                    label="Ethnicity"
                    value={this.props.memDetails.Ethinicity}
                    onChange={this.handleChange("Ethinicity", 5)}
                    input={
                      <OutlinedInput
                        name="Ethinicity"
                        labelWidth={0}
                        id="OutlinedEthinicityNativeSimple"
                      />
                    }
                  >
                    {Ethnicity.map((endorsementType, index) => (
                      <option key={index} value={endorsementType}>
                        {endorsementType}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {this.state.ethinicitySelected ? null :
                <label class="text-danger">Please Select</label>
              }
            </div>
            <div className="member-details-radio-input">
              <label> Gender </label>
              <span>
                <Radio
                  id="RadioButtonMale"
                  checked={this.props.memDetails.Sex === "Male"}
                  onChange={this.handleOptionChange}
                  value="Male"
                  name="radio-button-male"
                  aria-label="Male"
                />
                <label>Male</label>
              </span>
              <span>
                <Radio
                  id="RadioButtonFemale"
                  checked={this.props.memDetails.Sex === "Female"}
                  onChange={this.handleOptionChange}
                  value="Female"
                  name="radio-button-female"
                  aria-label="Female"
                />
                <label>Female</label>
              </span>
              {this.state.genderSelected ? null :
                <label class="text-danger">Please Select</label>
              }
            </div>
            <div className="nav-button-parent">
              {this.state.nextPage ?
                <Link to="/MemberAddress">
                  <NextPageButton />
                </Link> :
                <NextPageButton />
              }
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      }),

    onPageChange: newPage =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        payload: newPage
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberApplication);
