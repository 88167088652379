import { LMS_ACTIONS } from "../actions/actionTypes";

const initialState = {
  courses: [],
  lessons: [],
  lesson: {},
  assesments: [],
  assesment: {},
  course: {},
  quizzes: [],
  resultsRecord: [],
  isAddNewCourse: false,
  currLmsTab: 0,
  lmsAdminPageIterator: 0,
  //take quiz
  currentQuestion: [],
  quiz: {},
  options: [],
  questionType: "singleChoice",
  selectedAnswer: "",
  currentQuizIndex: 0,
  score: 0,
  next: "Next",
  previous: "Previous",
  sectionTitle: "No title",
  memberCourses: [],
  quizCompletion: [],
  currAssessmentTab: 0,
  certificates: [],
  assesmentCompletion: {},
  courseModuleName: "",
  moduleId: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LMS_ACTIONS.UPDATE_CURRENT_ASSESSMENT_TAB:
      return {
        ...state,
        currAssessmentTab: action.currAssessmentTab
      };
    case LMS_ACTIONS.UPDATE_QUIZ_COMPLETION:
      return {
        ...state,
        quizCompletion: action.quizCompletion
      };
    case LMS_ACTIONS.UPDATE_MEMBER_COURSES:
      return {
        ...state,
        memberCourses: action.memberCourses
      };
    case LMS_ACTIONS.UPDATE_RESULTS_RECORD:
      return {
        ...state,
        resultsRecord: action.resultsRecord
      };
    case LMS_ACTIONS.UPDATE_SECTION_TITLE:
      return {
        ...state,
        sectionTitle: action.sectionTitle
      };
    case LMS_ACTIONS.UPDATE_ADMIN_PAGE_ITERATOR:
      return {
        ...state,
        lmsAdminPageIterator: action.lmsAdminPageIterator
      };
    case LMS_ACTIONS.UPDATE_NEXT:
      return {
        ...state,
        next: action.next
      };
    case LMS_ACTIONS.UPDATE_PREVIOUS:
      return {
        ...state,
        previous: action.previous
      };
    case LMS_ACTIONS.UPDATE_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.currentQuestion
      };

    case LMS_ACTIONS.UPDATE_QUIZ:
      return {
        ...state,
        quiz: action.quiz
      };
    case LMS_ACTIONS.UPDATE_OPTIONS:
      return {
        ...state,
        options: action.options
      };
    case LMS_ACTIONS.UPDATE_QUESTION_TYPE:
      return {
        ...state,
        questionType: action.questionType
      };
    case LMS_ACTIONS.UPDATE_SELECTED_ANSWER:
      return {
        ...state,
        selectedAnswer: action.selectedAnswer
      };
    case LMS_ACTIONS.UPDATE_CURRENT_QUIZ_INDEX:
      return {
        ...state,
        currentQuizIndex: action.currentQuizIndex
      };
    case LMS_ACTIONS.UPDATE_SCORE:
      return {
        ...state,
        score: action.score
      };
    //--
    case LMS_ACTIONS.UPDATE_COURSE:
      return {
        ...state,
        course: action.course
      };
    case LMS_ACTIONS.UPDATE_COURSES:
      return {
        ...state,
        courses: action.courses
      };
    case LMS_ACTIONS.UPDATE_QUIZZES:
      return {
        ...state,
        quizzes: action.quizzes
      };
    case LMS_ACTIONS.UPDATE_IS_ADD_NEW_COURSE:
      return {
        ...state,
        isAddNewCourse: action.isAddNewCourse
      };
    case LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB:
      return {
        ...state,
        currLmsTab: action.currLmsTab
      };
    case LMS_ACTIONS.UPDATE_LESSONS:
      return {
        ...state,
        lessons: action.lessons
      };
    case LMS_ACTIONS.UPDATE_LESSON:
      return {
        ...state,
        lesson: action.lesson
      };

    case LMS_ACTIONS.UPDATE_ASSESMENTS:
      return {
        ...state,
        assesments: action.assesments
      };

    case LMS_ACTIONS.UPDATE_ASSESMENT:
      return {
        ...state,
        assesment: action.assesment
      };

    case LMS_ACTIONS.UPDATE_ASSESMENT_COMPLETION:
      return {
        ...state,
        assesmentCompletion: action.assesmentCompletion
      };

    case LMS_ACTIONS.UPDATE_CERTIFICATES:
      return {
        ...state,
        certificates: action.certificates
      }

    case LMS_ACTIONS.UPDATE_COURSE_MODULE_NAME:
      return {
        ...state,
        courseModuleName: action.courseModuleName
      }

    case LMS_ACTIONS.TRACK_CURRENT_MODULE_ID:
      return {
        ...state,
        moduleId: action.moduleId
      }

    default:
      return state;
  }
};

export default reducer;
