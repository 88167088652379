import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../../API/APIendpoint";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { toast } from 'react-toastify';
import StoreOwnerCommission from './StoreOwnerCommission';

class CommissionTemplate extends Component {
    constructor(props){
        super(props);
        this.state = {
          Commission: [],
          showStoreOwnerCommission:false
        }
    }
    loadCommissions =()=> {
      if(this.props.commCategory.frequency === "M" && this.props.commCategory.commType === "Owner Commission") {
          Axios({
              url: `${BASE}api/commission/GetMontlyDueCommissionOC`,
              method: "get",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            }).then(res => {
              this.setState({
                Commission: res.data
              })
              this.props.onCommissionGet(res.data);
          })
      } else if(this.props.commCategory.frequency === "A" && this.props.commCategory.commType === "Owner Commission") {
          Axios({
              url: `${BASE}api/commission/GetYearlyDueCommissionOC`,
              method: "get",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            }).then(res => {
              this.setState({
                Commission: res.data
              })
              this.props.onCommissionGet(res.data);
          })
      } else if(this.props.commCategory.frequency === "M" && this.props.commCategory.commType === "Sales Rep Commission") {
          Axios({
              url: `${BASE}api/commission/GetMontlyDueCommissionSR`,
              method: "get",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            }).then(res => {
              this.setState({
                Commission: res.data
              })
              this.props.onCommissionGet(res.data);
          })
      } else if(this.props.commCategory.frequency === "A" && this.props.commCategory.commType === "Sales Rep Commission") {
          Axios({
              url: `${BASE}api/commission/GetYearlyDueCommissionSR`,
              method: "get",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            }).then(res => {
              this.setState({
                Commission: res.data
              })
              this.props.onCommissionGet(res.data);
          })
      }
    }

    componentDidMount() {
      this.loadCommissions();
    }

    backFunction = () =>{
      this.loadCommissions();
      this.setState({showStoreOwnerCommission:false});
    }
    
    render() {
      const _commRecord = this.state.Commission;
      const _freq = this.props.commCategory.frequency === "M" ? "Monthly" : "Yearly"
      return (
        !this.state.showStoreOwnerCommission ? <div className="container">
            <div className="row">
              <GenericHeader 
                subHeader
                title="Process Commission Payment" 
                showBackButton
                backFunction={() => this.props.backFunction()}
                
              />
            </div>
            {_commRecord.length === 0 ? <p className="text-warning font-weight-bold">{`No Outstanding ${_freq} ${this.props.commCategory.commType}s`}</p> :
            <div>
              <h4>{`${_freq} ${this.props.commCategory.commType}`}</h4>
              <table className="mt-2" style={{ width: "100%" }}>
                <tbody>
                  <tr >
                    <td style={{ width: "10%" }}><h5>RepNo</h5></td>
                    <td style={{ width: "10%" }}><h5>Firstname</h5></td>
                    <td style={{ width: "10%" }}><h5>Surname</h5></td>
                    <td style={{ width: "10%" }}><h5>Branch</h5></td>
                    <td style={{ width: "10%" }}><h5>ComType</h5></td>
                    <td style={{ width: "10%" }}><h5>Commission</h5></td>
                    <td style={{ width: "10%" }}></td>
                  </tr>
                  {_commRecord.map((ocom, index) =>
                    <tr index={index}>
                      <td style={{ width: "10%" }}>{ocom.repNo}</td>
                      <td style={{ width: "10%" }}>{ocom.firstName}</td>
                      <td style={{ width: "10%" }}>{ocom.surname}</td>
                      <td style={{ width: "10%" }}>{ocom.branch}</td>
                      <td style={{ width: "10%" }}>{ocom.comType}</td>
                      <td style={{ width: "10%" }}>{ocom.comission}</td>
                      <td className="text-right" style={{ width: "10%" }}>
                        <button 
                          className="btn btn-warning"
                          onClick={() => {
                            this.props.onCommissionPay(ocom.repNo, ocom.branch, ocom.comission, ocom.commissionID);
                            // this.props.history.push("/StoreOwnerCommission")
                            this.setState({showStoreOwnerCommission:true})
                          }}
                        >
                          Pay Comm
                        </button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>}

        </div>
        : 
        this.state.showStoreOwnerCommission && <StoreOwnerCommission backFunction = {this.backFunction}/>
      );
    }
}
const mapStateToProps = state => {
  return {
    commissionRecord: state.CommissionReducer.commissionRecord,
    commCategory: state.CommissionCategoryReducer.commCategory
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionPay: (repNum, branchName, comission, commissionID) =>
      dispatch({
        type: actionTypes.UPDATE_REP_NUM,
        commInfo: {
          repNum: repNum,
          branchName: branchName,
          comission: comission,
          commissionID: commissionID
        }
      }),
    onCommissionGet: commRecord => 
      dispatch({
        type: actionTypes.ON_COMMISSION_REC_UPDATE,
        commissionRecord: commRecord
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionTemplate);