import React, { Component } from "react";
import { connect } from "react-redux";
import Modules from "../Modules";

class CourseMaterialModules extends Component {

  render() {
    return (
      <Modules
        modules={this.props.modules.filter(m => m.type === 2)} 
        moduleTypeName="material"
        type={2}
        openEditor={this.props.openEditor}
      />
    )
  }
}

const mapStateToProps = state => {
  return {
    modules: state.lmsCourseModuleReducer.payload,
    loading: state.lmsCourseModuleReducer.loading
  };
};

export default connect(
  mapStateToProps,
  null
)(CourseMaterialModules);
