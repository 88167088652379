export const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    borderBottom: "1px solid #8c8b8a"
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontWeight: "bold",
    marginLeft: "5px",
    marginRight: "5px"
  }
});

export const genericImageHeaderStyles = {
  root: {
    display: "flex",
    flexDirection: "row",
    height: 120,
    margin: 10
  },
  button: {
    margin: 10,
    padding: 10,
    width: 100,
    height: 100,
    backgroundColor: "white",
    border: "2px solid black",
    borderRadius: 50
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    paddingLeft: 30
  },
  grow: {
    flex: 1
  },
  text: {
    paddingLeft: 20,
    paddingBottom: 5
  },
  title: {
    padding: 10
  }
};
