import * as actionTypes from "../actions/actionTypes";

const initialState = {
  documents: {
    ListState: [],
    name: "",
    link: "",
    downloadId: "",
    discription: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOWNLOADS:
      const newDownloads = {
        ...state.documents,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        documents: newDownloads
      };
    default:
      return state;
  }
};

export default reducer;
