import React from 'react';
import { Link } from "react-router-dom";
// import { useStyles } from '../../../stylesNew/StaticPageStyles';

import { useStyles } from '../../stylesNew/StaticPageStyles';


import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function StaticPageHeader(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const classes = useStyles();
    return (
        <Grid item className={matches ? classes.staticHeaderContainerMobile : classes.staticHeaderContainer}>
            {matches || window.location.pathname.split('/')[1] === 'LandingPage' ? null :
            <Grid container direction="column"  >
                <Grid item style={{marginBottom: '1em'}}>
                    <Grid container>
                        <Typography>{props.currPage}</Typography>
                    </Grid>
                </Grid>
            </Grid>}
            <Grid container justify={matches ? (props.align ? 'left': 'center') : null}>
                {matches ? 
                <Link to="/LandingPage">
                    <ArrowBackIcon />
                </Link> : null}
                <Typography 
					variant={matches ? 'h5' : 'h4'}
					style={{marginLeft: '0.5em'}}>{props.tile}</Typography>
            </Grid>
        </Grid>
    );
}