import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Grid
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';

class DisplayCourseMaterialSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toDisplayMaterial: false
    };

    if(isMobile){
      let newContent = this.props.assesment.content; 
      this.props.assesment.content = newContent.replaceAll('width="', 'width="100%" originalWidth="')
                                               .replaceAll('height="', 'height="100%" originalHeight="');
    }
  }

  render() {
    return(
      <React.Fragment>
        <Grid container direction='column'>
          <Grid 
            item  
            dangerouslySetInnerHTML={{__html: this.props.assesment.content}} 
            style={{paddingLeft: '1em', paddingRight: '1em', paddingBottom: '1em', width: '100%'}}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  assesment: state.nLearningManagementReducer.assesment
});

export default connect(mapStateToProps)(DisplayCourseMaterialSelect);