import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/Delete";
import * as actionTypes from "../../store/actions/actionTypes";
import axios from "axios";

import {
  BASE,
  GET_DOWNLOADS_DELETE,
  GET_DOWNLOADS_URL
} from "../../API/APIendpoint";
import request from "superagent";

class AdminDownloads extends Component {
  state = {
    dense: false,
    secondary: true,
    id: this.props.id
  };
  DeleteRecord = () => {
    request
      .delete(BASE + GET_DOWNLOADS_DELETE + this.state.id)
      .then(res => {
        if (res.statusCode === 200) {
          this.fetchdownloadData();
        }
      });
  };

  fetchdownloadData = () => {
    axios.get(BASE + GET_DOWNLOADS_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.props.onDownloadsChange(res.data, "ListState");
    });
  };
  render() {
    return (
      <ListItem divider>
        <ListItemText
          primary={this.props.title}
        />
        <IconButton onClick={() => this.DeleteRecord()}>
          <DownloadIcon />
        </IconButton>
      </ListItem>
    );
  }
}

AdminDownloads.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    downloads: state.downloadsReducer.downloads,
    viewPdf: state.downloadsReducer.viewPdf
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDownloadsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DOWNLOADS,
        varValue: value,
        varName: vname
      }),
    onViewPdfChange: payload =>
      dispatch({ type: actionTypes.UPDATE_DOWNLOADS, payload: payload })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDownloads);
