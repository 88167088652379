import React from 'react';
import { SubtitleBar } from '../index';

export default function Overview(props) {
  return (
    <React.Fragment>
      <SubtitleBar title="Overview"/>
      {props.children}
    </React.Fragment>
  );
}
