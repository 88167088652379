import React, { Component } from "react";
import {
  fetchCourseModules,
  toggleShowChildren
} from "../Material/CourseMaterialActions";
import "../Material/styles/styles.css";
import PropType from "prop-types";
import { connect } from "react-redux";
import Modules from "../Modules";

class CourseAssessmentModules extends Component {
  componentDidMount = () => {
    if (this.props.modules.len < 0) {
      this.props.fetchCourseModules();
    }
  }

  render() {
    return (
      <Modules
        modules={this.props.modules.filter(m => m.type === 1)}
        moduleTypeName="assessment"
        type={1}
        openEditor={this.props.openEditor}
      />
    )
  }
}

CourseAssessmentModules.propTypes = {
  fetchCourseModules: PropType.func.isRequired,
  toggleShowChildren: PropType.func.isRequired
};

const mapStateToProps = state => {
  return {
    modules: state.lmsCourseModuleReducer.payload,
    loading: state.lmsCourseModuleReducer.loading
  };
};

export default connect(
  mapStateToProps,
  { fetchCourseModules, toggleShowChildren }
)(CourseAssessmentModules);
