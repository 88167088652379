import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {Button,CircularProgress,FormControl,FormHelperText,Grid,Step,StepLabel,Stepper,Typography} from '@material-ui/core/';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as actions from '../../../store/Auth/Signup/actions';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import { checkValidity, updateObject,initSignupSelect} from '../../../shared/utility';
import {MappedInput, SignupHeader } from '../../../components';
import {salesDetailMessage,loginDetailMessage} from '../../../components/Auth/Signup/SignUpText';
import CommissionsService from '../../../services/commissions.service'; 
import EmailVerificationService from '../../../services/emailVerification.service'; 
import {genderConfig,firstNameConfig,surnameConfig,nicknameConfig,idNumberConfig,cellphoneNumberConfig,occupationConfig,ethnicityConfig,physicalAddressConfig,
  suburbConfig,cityConfig,provinceConfig,postalCodeConfig,fireArmsControlActConfig,fireArmsUseStorageConfig,criminalOffenseConfig,
  declareCodeOfConductConfig,declareDisciplinaryCodeConfig,declareValidInformationConfig,declareElectronicPolicyConfig,declareValidElectronicTrueConfig,
  salesReferralConfig,partnerReferralConfig,associationAffiliationConfig,emailConfig,usernameConfig,passwordConfig,confirmPasswordConfig,salesRepresentativesConfig,
partnersConfig} from '../../../components/Auth/Signup/controls';
import DialogNotification from '../../../components/DialogNotification/DialogNotification'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ContactSupport } from '@material-ui/icons';

const styles = (theme) =>({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(2),
  },
  stepperRoot: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  stepperLabel: {
    '& .MuiStepLabel-alternativeLabel':{
      fontSize: theme.typography.nav.fontSize,
      marginTop: '0',
    }
  },
  formHeading: {
    ...theme.typography.h6,
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    borderBottom: '1px solid',
    marginTop: theme.spacing(4)
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  errorPadding: {
    padding: theme.spacing(4)
  },
  submitErrorPadding: {
    padding: theme.spacing(4)
  },
  mobileMargin: {
    marginTop: '25px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  }
});
toast.configure();
class Signup extends Component {
  constructor(props){
    super(props);
    this.commissionService = CommissionsService(this.props.token);
    this.emailVerificationService = EmailVerificationService(this.props.token);
    this.state = {
      activeStep: 0,
      personalControls: this.initPersonalControls(),
      residentialControls: this.initResidentialControls(),
      declarationControls: this.initDeclarationControls(),
      electronicControls: this.initElectronicControls(),
      salesControls: this.initSalesControls(),
      loginControls: this.initLoginControls(),
      submitError: '',
      nextError: '',
      salesReps: [],
      partners: [],
      partnersDropdown: [],
      hasSalesRep :false,
      open:false,
      openErrorDialog:false,
      errorMessage:"",
      memberWaitingVerification:null,
      code:null,
      signupSuccess:true,
      isLoading: false,
    };
    
    //allow binding of "this" inside the state handler
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);

  }

  componentDidMount() {
    this.commissionService.getSalesReps().then(res => {
      
          this.setState({salesReps:res.data})
        });
    
    this.commissionService.getPartners().then(res =>{
          const partners = res.data.map(item => {
            return {value: item.store, label: item.store}
          });
          this.setState({partners:res.data, partnersDropdown: this.initSalesControls2(partners)})
        });
  }

    handleCloseErrorDialog = () => {
    this.setState({openErrorDialog:false,errorMessage:''});
  };


  initPersonalControls = () => ({
    gender: {...genderConfig},
    firstName: {...firstNameConfig},
    surname: {...surnameConfig},
    nickname: {...nicknameConfig},
    idNumber: {...idNumberConfig},
    cellphoneNumber: {...cellphoneNumberConfig},
    occupation: {...occupationConfig},
    ethnicity: {...ethnicityConfig}
  });

  initResidentialControls = () => ({
    physicalAddress: {...physicalAddressConfig},
    suburb: {...suburbConfig},
    city: {...cityConfig},
    province: {...provinceConfig},
    postalCode: {...postalCodeConfig}
  });

  initDeclarationControls = () => ({
    fireArmsControlAct: {...fireArmsControlActConfig},
    fireArmsUseStorage: {...fireArmsUseStorageConfig},
    criminalOffense: {...criminalOffenseConfig},
    declareCodeOfConduct: {...declareCodeOfConductConfig},
    declareDisciplinaryCode: {...declareDisciplinaryCodeConfig},
    declareValidInfo: {...declareValidInformationConfig},
  });

  initElectronicControls = () => ({
    declareElectronicPolicy: {...declareElectronicPolicyConfig},
    declareValidElectronicTrue: {...declareValidElectronicTrueConfig},
  });

  initSalesControls = () => ({
    partnerReferral: {...partnerReferralConfig},
    partnersSelect: initSignupSelect(this.props.partners, partnersConfig),
    // salesReferral: {...salesReferralConfig},//Referral
    salesRepSelect: initSignupSelect([], salesRepresentativesConfig),//Drop down
    associationAffiliation: {...associationAffiliationConfig}
  })


  // Currently using this one 
  initSalesControls2 = (partners) => ({
    partnerReferral: {...partnerReferralConfig},
    partnersSelect: initSignupSelect(partners, partnersConfig),
    // salesReferral: {...salesReferralConfig},//Referral
    salesRepSelect: initSignupSelect([], salesRepresentativesConfig),//Drop down
    associationAffiliation: {...associationAffiliationConfig}
  })

  
  initLoginControls = () => ({
    email: {...emailConfig},
    username: {...usernameConfig},
    password: {...passwordConfig},
    confirmPassword: {...confirmPasswordConfig}
  })

  async handleNext() {
    const newMember = {
      gender: this.state.personalControls.gender.value,
      firstName: this.state.personalControls.firstName.value,
      surname: this.state.personalControls.surname.value,
      nickname: this.state.personalControls.nickname.value,
      cellphoneNumber: this.state.personalControls.cellphoneNumber.value,
      idNumber: this.state.personalControls.idNumber.value,
      occupation: this.state.personalControls.occupation.value,
      ethnicity: this.state.personalControls.ethnicity.value,

      physicalAddress: this.state.residentialControls.physicalAddress.value,
      suburb: this.state.residentialControls.suburb.value,
      city: this.state.residentialControls.city.value,
      province: this.state.residentialControls.province.value,
      postalCode: this.state.residentialControls.postalCode.value,

      fireArmsControlAct: this.state.declarationControls.fireArmsControlAct.value,
      fireArmsUseStorage: this.state.declarationControls.fireArmsUseStorage.value,
      criminalOffense: this.state.declarationControls.criminalOffense.value,
      declareCodeOfConduct: this.state.declarationControls.declareCodeOfConduct.value,
      declareDisciplinaryCode: this.state.declarationControls.declareDisciplinaryCode.value,
      declareValidInfo: this.state.declarationControls.declareValidInfo.value,

    };
    
    const errors = [];
    for (var obj in newMember) {
      if (newMember[obj] === '' && obj !== 'suburb') {
        obj = obj + " field is required  "
        errors.push(obj)
      }
    }

    if (errors.length != 0) {
      this.setState({ nextError: errors.toString() });
    }


    if (errors.length === 0) {
        this.setState({isLoading:true});
        
        await this.emailVerificationService.checkIDNo(newMember.idNumber).then(res => {
          if(res.data.valid){
              this.setState({ nextError: "" });
              this.setState((prevState) => ({activeStep: prevState.activeStep + 1}));
              this.setState({isLoading: false});
            }
            else{
              let errorMessage = `A profile with the ID number already exists on our system.
              We have sent an email to the email address associated with this profile and ID.
              Please check your emails for further instructions.
              If you did not receive a mail, check your spam folders or email support: webadmin@narfo.co.za`;
              // Trigger popupn
              this.setState({errorMessage:errorMessage,openErrorDialog:true, isLoading: false});
            }
        })
        .catch(err =>{
          console.log("Error verifying ID number", err);
          this.setState({isLoading: false});
        })
        // .finally( this.setState({isLoading: false}));
   }
  }

  handleBack() {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1
    }));
  }

  handleReset() {
    this.setState({
      activeStep: 0,
    });
  }

  inputChangePersonalHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.personalControls[controlName].config.validate,
      this.state.personalControls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.personalControls, {
      [controlName]: updateObject(this.state.personalControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.personalControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({personalControls:updatedControls});
  }

  inputChangeResidentialHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.residentialControls[controlName].config.validate,
      this.state.residentialControls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.residentialControls, {
      [controlName]: updateObject(this.state.residentialControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.residentialControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({residentialControls:updatedControls});
  }

  inputChangeDeclarationHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.declarationControls[controlName].config.validate,
      this.state.declarationControls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.declarationControls, {
      [controlName]: updateObject(this.state.declarationControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.declarationControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({declarationControls:updatedControls});
  }

  inputChangeElectronicHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.electronicControls[controlName].config.validate,
      this.state.electronicControls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.electronicControls, {
      [controlName]: updateObject(this.state.electronicControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.electronicControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({electronicControls:updatedControls});
  }

  inputChangeSalesHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.salesControls[controlName].config.validate,
      this.state.salesControls[controlName].config.elementConfig.type
    );

    const updatedControls = updateObject(this.state.salesControls, {
      [controlName]: updateObject(this.state.salesControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.salesControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });

    if(controlName == 'partnersSelect'){
      //Find the Store 
      let partner = this.findObjectByValue(event.target.value,this.state.partners);

      let _salesReps = this.state.salesReps.filter((rep) => rep.storeID == partner.storeId)
      let salesRepsOptions = [];
      
      _salesReps.map(item => {
        salesRepsOptions.push({value: item.memNo, label: item.memNoNavigation.firstname + " " + item.memNoNavigation.surname});
      })
      
      updatedControls.salesRepSelect =  initSignupSelect(salesRepsOptions, salesRepresentativesConfig);
      this.setState({salesControls:updatedControls,hasSalesRep: salesRepsOptions.length > 0 ? true:false});
    }
    else
      this.setState({salesControls:updatedControls});
  }

  findObjectByValue = (value,array) => {
    for (let i = 0; i < array?.length; i++) 
        if (array[i]?.store === value) 
            return array[i];
    return {};
  }

  inputChangeLoginHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.loginControls[controlName].config.validate,
      this.state.loginControls[controlName].config.elementConfig.type
    );
    const updatedControls = updateObject(this.state.loginControls, {
      [controlName]: updateObject(this.state.loginControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.loginControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });
    this.setState({loginControls:updatedControls});
  }

  inputConfirmPasswordHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.loginControls[controlName].config.validate, 
      this.state.loginControls[controlName].config.elementConfig.type
    );
    const matchPassword = (event.target.value === this.state.loginControls.password.value);
    const updatedControls = updateObject(this.state.loginControls, {
      [controlName]: updateObject(this.state.loginControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.loginControls[controlName].config, {
          valid: (matchPassword ? validity.isValid : false),
          errors: (matchPassword ? validity.errors : ['Passwords do not match']),
          touched: true
        })
      })
    });
    this.setState({loginControls:updatedControls});
  }

  checkRequired = regInfo => {
    for (var obj in regInfo) {
      if (regInfo[obj] === '' && obj !== 'suburb') {
        return false;
      }
    }

    if (regInfo.idNumber.length !== 13) 
        return false;

    return true;
  };

  submitHandler = (event) => {
    
    event.preventDefault();
    this.setState({submitError:''});

    //check sales rep 
    let salesRep = this.state.salesControls.salesRepSelect.value === "" || this.state.salesControls.salesRepSelect.value === "-" ? 'NM-0011' : this.state.salesControls.salesRepSelect.value;

    const newMember = {
      gender: this.state.personalControls.gender.value,
      firstName: this.state.personalControls.firstName.value,
      surname: this.state.personalControls.surname.value,
      nickname: this.state.personalControls.nickname.value,
      cellphoneNumber: this.state.personalControls.cellphoneNumber.value,
      idNumber: this.state.personalControls.idNumber.value,
      occupation: this.state.personalControls.occupation.value,
      ethnicity: this.state.personalControls.ethnicity.value,
      
      physicalAddress: this.state.residentialControls.physicalAddress.value,
      suburb: this.state.residentialControls.suburb.value,
      city: this.state.residentialControls.city.value,
      province: this.state.residentialControls.province.value,
      postalCode: this.state.residentialControls.postalCode.value,

      fireArmsControlAct: this.state.declarationControls.fireArmsControlAct.value,
      fireArmsUseStorage: this.state.declarationControls.fireArmsUseStorage.value,
      criminalOffense: this.state.declarationControls.criminalOffense.value,
      declareCodeOfConduct: this.state.declarationControls.declareCodeOfConduct.value,
      declareDisciplinaryCode: this.state.declarationControls.declareDisciplinaryCode.value,
      declareValidInfo: this.state.declarationControls.declareValidInfo.value,

      declareElectronicPolicy: this.state.electronicControls.declareElectronicPolicy.value,
      declareValidElectronicTrue: this.state.electronicControls.declareValidElectronicTrue.value,

      partnerReferral: this.state.salesControls.partnerReferral.value == 'Narfo Head Office' ? this.state.salesControls.partnerReferral.value: this.state.salesControls.partnersSelect.value,
      salesReferral: this.state.salesControls.partnerReferral.value != 'Narfo Head Office' ? salesRep : 'NM-0011',
        
      associationAffiliation: this.state.salesControls.associationAffiliation.value,
      
      email: this.state.loginControls.email.value,
      username: this.state.loginControls.username.value,
      password: this.state.loginControls.password.value,
      confirmPassword: this.state.loginControls.confirmPassword.value
    };

    const requiredFields = this.checkRequired(newMember);
    
    if(!requiredFields) {
      const message = 'Please complete all of the required fields before submitting the application';
      this.setState({submitError:message});
    
    } else if(
      newMember.fireArmsControlAct === 'false' ||
      newMember.fireArmsUseStorage === 'false' ||
      newMember.declareCodeOfConduct === 'false' ||
      newMember.declareDisciplinaryCode === '' ||
      newMember.declareValidInfo === '' ||
      newMember.declareElectronicPolicy === '' ||
      newMember.declareValidElectronicTrue === '' ){

      const message = 'All declarations need to be accepted before you can sign up with NARFO';
      this.setState({submitError:message});

    } else if(newMember.criminalOffense === 'true'){

      const message = 'You can not become a member of NARFO if you have been found criminal of any firearm related offense';
      this.setState({submitError:message});
    } else {

      //Save user data 
     this.emailVerificationService.registerNewUser(newMember)
      .then(response =>{

        if(response.data.status !== 200){
         
          this.setState({errorMessage:response.data.error,openErrorDialog:true});
        }
        else{
            this.emailVerificationService.isValidMember(newMember.email,newMember.username).then(res => {
              this.sendVerificationCode(this.state.loginControls.email.value);
              // this.setState({open:true,memberWaitingVerification:newMember});
            })
            .catch(error =>{
              console.log("inside ", error);
              this.setState({errorMessage:'Email already exists',openErrorDialog:true});
            });
    
            this.setState({shouldRedirect:true});
        }
      })
      .catch(err=>{
          console.log("main ", err)
          
          this.setState({errorMessage:err.message,openErrorDialog:true});
      });

    // try {
    //   const response = await this.emailVerificationService.registerNewUser(newMember);
      
    //   console.log("Approved ", response);
    //   if(response.data.status !== 200){

    //   }

    //   this.setState({ shouldRedirect: true });
    // } catch (err) {
    //   console.log("main ", err);
    //   this.setState({ errorMessage: err.message, openErrorDialog: true });
    // }
    
    }
  };

  onCodeChange(e) {
    e.preventDefault();
    this.setState({ code: e.target.value });
  }

  sendVerificationCode =(email,resend)=>{
    this.emailVerificationService.sendVerificationCode(email).then(res => {
      if(resend)
        toast.success("Verification code for email "+ email + " has been sent successfully");
    });
  }

  checkIDNo = async (idNo)=>{
    await this.emailVerificationService.checkIDNo(idNo).then(res => {
      if(!res.data.valid){
          return false;
        }
        return res.data.valid;
    })
    .catch(err =>{
      console.log("Error verifying ID number", err);
      return false;
    });
  }

  Signup =(code)=>{
    let newMember = this.state.memberWaitingVerification; 

    if(!code)
       newMember.code = this.state.code;
    else
       newMember.code = code;

    //check if code is valid 
    this.emailVerificationService.isValidCode(newMember.email,newMember.code).then(res => {
      // this.props.onSignup(newMember);
      //Signup member
      this.emailVerificationService.registerNewUser(newMember).then(response =>{
        this.setState({shouldRedirect:true});
      })
      .catch((err)=>{
          console.log(err)
          console.log("TDDDDD 549", err.message);

          this.setState({errorMessage:'Error saving member',openErrorDialog:true});
      });
    })
    .catch(error =>{
      console.log("TDDDDD 549", error.message);
      this.setState({errorMessage:'Invalid code',openErrorDialog:true});
    });
  }
  
  render() {
   
    function getStepContent(stepIndex) {
      switch (stepIndex) {
      case 0:
        return personalDetailsPage ;

      case 1:
        return accountInformationPage;
        
      default:
        return 'Unknown stepIndex';
      }
    }

    if(this.state.partners == 0)
    {
      this.commissionService.getPartners().then(res =>{
        const partners = res.data.map(item => {
          return {value: item.store, label: item.store}
        });
        console.log(res.data)
        this.setState({salesControls: this.initSalesControls2(partners)});
        // this.setState({salesControls: this.initSalesControls()});
      })
    }

    const {classes} = this.props;
    const personalElementArray = [];
    const residentialElementArray = [];
    const declarationElementArray = [];
    const electronicElementArray = [];
    const salesElementArray = [];
    const loginElementArray = [];
    const salesRepElemtArray = [];

    const stepperLabels = [
      { name: 'Personal Details'},
      { name: 'Account Information'}
    ];

    let signupRedirect = null;
    let postError = null;
    let loadingBlock = null;

    for (let key in this.state.personalControls) {
      personalElementArray.push( {
        id: key,
        config: this.state.personalControls[key].config,
        value: this.state.personalControls[key].value,
      });
    }

    for (let key in this.state.residentialControls) {
      residentialElementArray.push( {
        id: key,
        config: this.state.residentialControls[key].config,
        value: this.state.residentialControls[key].value
      });
    }

    for (let key in this.state.declarationControls) {
      declarationElementArray.push( {
        id: key,
        config: this.state.declarationControls[key].config,
        value: this.state.declarationControls[key].value
      });
    }

    for (let key in this.state.electronicControls) {
      electronicElementArray.push( {
        id: key,
        config: this.state.electronicControls[key].config,
        value: this.state.electronicControls[key].value,
        message: this.state.electronicControls[key].message
      });
    }

    for (let key in this.state.salesControls) {
      salesElementArray.push( {
        id: key,
        config: this.state.salesControls[key].config,
        value: this.state.salesControls[key].value
      });
    }

    for (let key in this.state.saleRepControl) {
      salesRepElemtArray.push( {
        id: key,
        config: this.state.saleRepControl[key].config,
        value: this.state.saleRepControl[key].value
      });
    }

    for (let key in this.state.loginControls) {
      loginElementArray.push( {
        id: key,
        config: this.state.loginControls[key].config,
        value: this.state.loginControls[key].value
      });
    }

    let personalForm = personalElementArray.map( formElement => (
      <Grid
        key={formElement.id}
        item
        xs={10}
        sm={8}
        md={formElement.config.width === 'full' ? 8 : 4}
      >
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangePersonalHandler(event, formElement.id)} 
            showHide={formElement.id !== 'password' ? null : this.showHide}
            showPassword={formElement.id !== 'password' ? null : this.state.showPassword}
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      </Grid>
      
    ));

    let residentialForm = residentialElementArray.map( formElement => (
      <Grid
        key={formElement.id}
        item
        xs={10}
        sm={8}
        md={formElement.config.width === 'full' ? 8 : 4}
      >
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangeResidentialHandler(event, formElement.id)} 
            showHide={formElement.id !== 'password' ? null : this.showHide}
            showPassword={formElement.id !== 'password' ? null : this.state.showPassword}
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      </Grid>
    ));

    let declarationForm = declarationElementArray.map(formElement => (
      <Grid
        key={formElement.id}
        item
        xs={12}
        sm={10}
        md={formElement.config.width === 'full' ? 10 : 4}
      >
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangeDeclarationHandler(event, formElement.id)} 
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      </Grid>
    ));

    let electronicForm = electronicElementArray.map(formElement => (
      <React.Fragment key={formElement.id}>
        <Grid item>
          <p className={classes.paragraph}>
            {formElement.message}
          </p>
        </Grid>
        <Grid
          key={formElement.id}
          item
          xs={12}
          sm={10}
          md={formElement.config.width === 'full' ? 10 : 4}
        >
          <FormControl
            key={formElement.id}
            variant="standard"
            margin="normal"
            fullWidth
          >
            <MappedInput
              elementConfig={formElement.config.elementConfig}
              elementType={formElement.config.elementType}
              value={formElement.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validate}
              touched={formElement.config.touched}
              changed={(event) => this.inputChangeElectronicHandler(event, formElement.id)} 
            />
            <FormHelperText
              error={!formElement.config.value}
            >
              {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
            </FormHelperText>
          </FormControl>
        </Grid>
      </React.Fragment>
    ));
    
    let salesForm = salesElementArray.map(formElement => (
      <Grid
        key={formElement.id}
        item
        xs={12}
        sm={10}
        md={formElement.config.width === 'full' ? 10 : 4}
      >
      {
        formElement.id === 'salesRepSelect' ?
        this.state.salesControls.partnerReferral.value === 'Yes' && this.state.salesControls.partnersSelect.value !== '' && this.state.hasSalesRep?
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangeSalesHandler(event, formElement.id)} 
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl> : null
          
        :
        formElement.id === 'partnersSelect' ?
          this.state.salesControls.partnerReferral.value === 'Yes' ?
            <FormControl
            key={formElement.id}
            variant="standard"
            margin="normal"
            fullWidth
            >
              <MappedInput
                elementConfig={formElement.config.elementConfig}
                elementType={formElement.config.elementType}
                value={formElement.value}
                invalid={!formElement.config.valid}
                shouldValidate={formElement.config.validate}
                touched={formElement.config.touched}
                changed={(event) => this.inputChangeSalesHandler(event, formElement.id)} 
              />
              <FormHelperText
                error={!formElement.config.value}
              >
                {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
              </FormHelperText>
            </FormControl>
          : null
        :
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangeSalesHandler(event, formElement.id)} 
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      }
      </Grid>
    ));


    let loginForm = loginElementArray.map(formElement => (
      <Grid
        key={formElement.id}
        item
        xs={10}
        sm={8}
        md={formElement.config.width === 'full' ? 8 : 4}
      >
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={formElement.id !== 'confirmPassword' ? 
              (event) => this.inputChangeLoginHandler(event, formElement.id) :
              (event) => this.inputConfirmPasswordHandler(event, formElement.id)
            } 
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      </Grid>
    ));

    
    if (this.props.loading) {
      loadingBlock = 
      <Grid container justify="center">
        <CircularProgress />
      </Grid>;
    } 

    const submitError = (
      <Typography
        variant="h6"
        color="error"
        className={classes.submitErrorPadding}
      >
        {this.state.submitError}
      </Typography>
    );
    
    const nextError = (
      <Typography
        variant="h6"
        color="error"
        className={classes.submitErrorPadding}
      >
        {this.state.nextError}
      </Typography>
    );

    if (this.state.signupSuccess && this.state.shouldRedirect) {
      signupRedirect = <Redirect to={this.props.signupRedirectPath} />;
    }

    const personalDetailsPage = (
      <Grid item container direction="row">
        <SignupHeader/>
        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Personal Details
          </Typography>
        </Grid>
        {personalForm}
        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Residential
          </Typography>
        </Grid>
        {residentialForm}
        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Declaration
          </Typography>
        </Grid>
        {declarationForm}
      </Grid>
    );

    const accountInformationPage = (
      <Grid item container direction="row">
        <Grid item>
          <Typography variant="h4" className={classes.heading}>
            Join the National Association of Responsible Firearm Owners
          </Typography>
          <Button display={false} style={{opacity:-1,cursor:"default"}} autoFocus/>
        </Grid>

        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Electronic communication policy
          </Typography>
        </Grid>
        {electronicForm}

        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Sales details
          </Typography>
        </Grid>
        <Grid item>
          <p className={classes.paragraph}>
            {salesDetailMessage}
          </p>
        </Grid>
        {salesForm}
        <Grid item xs={12}>
          <Typography 
            className={classes.formHeading} 
            variant="h6"
          >
            Login details
          </Typography>
        </Grid>
        <Grid item>
          <p className={classes.paragraph}>
            {loginDetailMessage}
          </p>
        </Grid>
        {loginForm}
      </Grid>
    );
    
    return (
      <Grid container alignItems="center" justify="center">
        <Grid item xs={12} sm={10} xl={8}>
          {loadingBlock}
          <Stepper 
            className={classes.stepperRoot}
            activeStep={this.state.activeStep}
            alternativeLabel
          >
            {stepperLabels.map((stepItem) => (
              <Step key={stepItem.name}>
                <StepLabel className={classes.stepperLabel}>
                  {stepItem.name}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Grid container justify="center" className={classes.stepContent}>
            <Grid item xs={10} md={7} lg={8}>
              {/* content goes here */}
              {getStepContent(this.state.activeStep)}
            </Grid>
            <Grid item container direction="column" alignItems="center" justify="flex-start" xs={11} md={5} lg={4}>
              {/* buttons go here */}
              <Grid item container className={classes.mobileMargin} direction="row" justify="center">
                <Grid item>
                  <Button 
                    size="medium"
                    variant="contained"
                    disableElevation
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.backButton}
                  >
                    Previous Step
                  </Button>
                </Grid>
              </Grid>
              <Grid item container>
                {postError}
                {signupRedirect}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            {submitError}
            {nextError}

            <Button
              disabled={this.state.isLoading}
              size="medium"
              disableElevation
              variant="contained"
              color="primary" 
              onClick={this.state.activeStep === stepperLabels.length - 1 ? this.submitHandler : this.handleNext}
              className={classes.nextButton}
            >
                
              {this.state.activeStep === stepperLabels.length - 1 ? 'Go to LogIn' : 'Proceed to Account Details'}
              
              {this.state.isLoading && <CircularProgress style={{"margin-left":"5px"}} size="1.6rem" disableShrink />}
            </Button> 


                {/* Verifiy Email before continuing  */}
                <Dialog open={this.state.open} maxWidth={'xs'} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title" align="right">
                <IconButton aria-label="close" align="right" className={classes.closeButton} onClick={_=> this.setState({open:false})}>
                  <CloseIcon />
                </IconButton>

                </DialogTitle>
                <strong align="center" style={{marginTop:'-50px'}}> Verify email</strong>
                <DialogContent>
                  <DialogContentText>
                    <Alert severity="success" style={{fontSize:"small"}}>We've sent verification code to your email <br/>
                    {this.state.memberWaitingVerification?.email}
                    </Alert>
                  </DialogContentText>

                  <form className={classes.form} noValidate>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Code"
                      type="text"
                      fullWidth
                      onChange={e => this.onCodeChange(e)}
                  />

                  </form>
                  
                 <Button size="small" fullWidth onClick={()=>this.sendVerificationCode(this.state.loginControls.email.value,true)} variant="outlined" color="primary">
                    Resend code
                 </Button>

                </DialogContent>
                <DialogActions>
                      <Grid container>
                          <Grid item xs={12}>

                  <Button fullWidth style={{color:'white'}} disabled ={this.state.code != null ? null : true} onClick={()=>this.Signup()} variant="contained" color="primary">
                    Login & Proceed to payment
                    </Button>
                          </Grid>

                          <Grid item xs={12} >
                            <h4 align="center" justify="center">OR</h4>
                          </Grid>
                          <Grid item xs={12}>
                            
                            <Button style={{backgroundColor:"orange"}} size="small" fullWidth onClick={()=>this.Signup("2023")} variant="contained" color="primary">
                              Verify later
                            </Button>
                            </Grid>
                      </Grid>
                 
                </DialogActions>
              </Dialog>
          </Grid>

        </Grid>

        <Grid item>
            {/* <DialogNotification handleCloseErrorDialog={this.handleCloseErrorDialog} show={this.state.openErrorDialog} message={this.state.errorMessage}/> */}
            <DialogNotification handleCloseErrorDialog={this.handleCloseErrorDialog} show={this.state.openErrorDialog} message={this.state.errorMessage}/>
        </Grid>
      </Grid>
    );
  }
}

const mapStatetoProps = state => ({
  loading: state.nSignupReducer.loading,
  // error: state.nSignupReducer.error,
  signupSuccess: state.nSignupReducer.signupSuccess,
  signupRedirectPath: state.nSignupReducer.signupRedirectPath,
  isAuthenticated: state.AuthReducer.token !== null,
  salesReps: state.nSignupReducer.salesReps,
  // partners: state.nSignupReducer.partners
});

export default connect(mapStatetoProps, null)(withStyles(styles)(Signup));