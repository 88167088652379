import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from '@material-ui/core/';
import {
  ExpandMore
} from '@material-ui/icons/';
import PropTypes from 'prop-types';

import {
  SubtitleBar
} from '../index';

const styles = (theme) =>({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    marginBottom: '12px',
    '& .MuiAccordion-root.Mui-expanded:last-child': {
      marginBottom: '16px'
    },
    '& .MuiCollapse-container.MuiCollapse-entered': {
      marginBottom: '16px'
    }
  },
  summarySpacing: {
    marginBottom: '16px'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  matrixItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.tableBackgroundColor.main
  },
  circleHeight: {
    height: '25px'
  },
  baseConnector: {
    width: '0px',
    top: '25px',
    border: '1px solid',
    position: 'relative',
    borderColor: theme.palette.primary.main,
    '&:before': {
      content: '""',
      top: '-25px',
      left: '-12.5px',
      height: '25px',
      width: '25px',
      borderRadius: '50%',
      border: '4px solid',
      position: 'absolute',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white2.main
    },
  },
  lineConnector: {
    height: 'calc(100% - 24px)',
  },
  lastConnector: {
    height: '0',
  },
  lineActiveConnector: {
    height: 'calc(100% - 24px)',
    '&:before': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  activeConnector: {
    '&:before': {
      backgroundColor: theme.palette.primary.main,
    }
  },
  lastActiveConnector: {
    height: '0',
    '&:before': {
      backgroundColor: theme.palette.primary.main,
    }
  },
});

class ActivityMatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      matrix: props.activityMatrix,
      expanded: false,
    };
  }

  expandHandler = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false
    });
  }

  render() {
    const {classes} = this.props;
    
    const lastActiveConnector = `${classes.baseConnector} ${classes.lastActiveConnector}`;
    const activeConnector = `${classes.baseConnector} ${classes.lineActiveConnector}`;
    const lastConnector =`${classes.baseConnector} ${classes.lastConnector}`;
    const connector = `${classes.baseConnector} ${classes.lineConnector}`;

    const noDataSection = (
      <Typography variant="h4" color="error">
        You currently do not have any approved activities registered
      </Typography>
    );

    return (
      <React.Fragment>
        <SubtitleBar title="Activity Matrix"></SubtitleBar>
        <Grid container justify="center">
          <Grid item container xs={12} md={11} alignItems="center">
            {this.state.matrix.length === 0 ? <Grid item>{noDataSection}</Grid> : null}
            {this.state.matrix.sort((a, b) => new Date(b.participationYear) - new Date(a.participationYear)).map((item, index) => (
              <Grid key={index} container>
                <Grid item container xs={1} justify="center">
                  <Grid
                    item 
                    className={
                      this.state.expanded === index+1 ? 
                        index+1 === this.state.matrix.length ? lastActiveConnector : activeConnector : 
                        index+1 === this.state.matrix.length ? lastConnector : connector
                    }
                  />
                </Grid>
                <Grid item xs={11}>
                  {/* Index+1 is used to prevent the index of 0 from being checked, remove truthy dependency */}
                  <Accordion 
                    className={classes.root}
                    expanded={this.state.expanded === index+1}
                    onChange={this.expandHandler(index+1)}
                  >
                    <AccordionSummary
                      className={classes.summarySpacing}
                      expandIcon={<ExpandMore/>}
                      aria-controls={`panel-${index}-content`}
                      id={`panel-${index}-header`}
                    >
                      <Grid container alignItems="center">
                        <Grid item xs={3} sm={2}>
                          <Typography variant="h6" color="primary">
                            {item.participationYear}
                          </Typography>
                        </Grid>
                        <Grid item xs={9} sm={10}>
                          <Typography>
                            Compliance:&nbsp;{item.comply}  
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid container direction="row" justify="space-between">
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Not Shooting Related
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.notShooting}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Dedicated Sport Shooter
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.dedicatedShooter}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Occasional Hunter
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.occasionalHunter}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Dedicated Sport Shooter and Hunter
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.dedicatedHunterAndshooter}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Dedicated Hunter
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.dedicatedHunter}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Remaining Days to Comply
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.daysToComply}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Occasional Sport Shooter
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.occassionalSportShooter}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.matrixItem}>
                          <Grid item xs={9}>
                            <Typography>
                              Status Compliance
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography>
                              {item.comply}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ActivityMatrix.propTypes = {
  activityMatrix: PropTypes.array.isRequired,
};

ActivityMatrix.defaultProps = {
  activityMatrix: []
};

export default withStyles(styles)(ActivityMatrix);