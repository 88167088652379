import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
toast.configure();
export class EditPaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
        AdminId: this.props.bankEditObject.AdminId,
        BankName: this.props.bankEditObject.BankName,
        BranchCode: this.props.bankEditObject.BranchCode,
        AccountNo: this.props.bankEditObject.AccountNo,
        AccountType: this.props.bankEditObject.AccountType,
    };
    this._submitForm = React.createRef();
  }
  _clickSubmitButton = e => {
    e.preventDefault();
    this._submitForm.current.click();
  }
  onChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();
    const {
        AdminId,
        BankName,
        BranchCode,
        AccountNo,
        AccountType,
    } = this.state;
    axios({
        url: `${BASE}api/admin/updateRepBankingDetails`,
        method: "post",
        data: {
            AdminId,
            BankName,
            BranchCode,
            AccountNo,
            AccountType,
        },
        headers: { 
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` 
        }
    }).then(response => {
        if(!response.data.isFound){
          toast.error(response.data.message)
        } else {
          this.newBankingDetailsUpdater(response.data.newBankDetails);
          toast.success(response.data.message);
        }
      });
    this.setState({
      userDetails: true
    });
  };
  newBankingDetailsUpdater = newDetails => {
    let bankingDetailsObject = {};
    let bankingDetailsObjectPartner = {};
    newDetails.filter(element => {
      if(element.memType === "Sales"){
          bankingDetailsObject = {
              AdminId: element.adminId,
              MemType: element.memType,
              BankName: element.bankName,
              BranchCode: element.branchCode,
              AccountNo: element.accountNo,
              AccountType: element.accountType,
          }
      }
      if(element.memType === "Partner"){
          this.props.onPartnerStatusUpdate(true);
          bankingDetailsObjectPartner = {
              AdminId: element.adminId,
              MemType: element.memType,
              BankName: element.bankName,
              BranchCode: element.branchCode,
              AccountNo: element.accountNo,
              AccountType: element.accountType,
          }
      }
    })
    this.props.onBankingDetailsget(bankingDetailsObject, bankingDetailsObjectPartner);
    this.props.onCommissionTabChange("2");
  }
  render() {
    var width1 = "";
    var width2 = "";
    if (isMobile) {
      width1 = "20%"
      width2 = "20%"
    } else {
      width1 = "29%"
      width2 = "40%"
    }
    const info = (row1, row2) => (
      <tbody>
        <tr >
          <td style={{ width: width1 }}>{row1}</td>
          {isMobile ? null : <td style={{ width: "48%" }} />}
          <td className="font-weight-bold" style={{ width: width2 }}>{row2}</td>
        </tr>
      </tbody>
    );
      return (
        <div>
          <form className="container mb-2">
            <div className="row ml-1 mr-1">
              <GenericHeader
                title={this.props.bankDetailsViewObj._viewPartnerBankingDetails?"Edit Partner Bank Details":"Edit Rep Bank Details"}
                showBackButton
                backFunction={() => this.props.onCommissionTabChange("2")}
              />
            </div>
            <table className="ml-4">
              {info("Bank Name",
                <input
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                        BankName: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.BankName}
                />
              )}
              {info("Account Number",
                <input
                  type="text"
                  className="form-control "
                  id="code"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                        AccountNo: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.AccountNo}
                />
              )}
              {info("Branch Code",
                <input
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                        BranchCode: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.BranchCode}
                />
              )}
              {info("Account Type",
                <input
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                        AccountType: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.AccountType}
                />
              )}
              <button
                style={{ display: "none" }}
                onClick={e => this.onSubmit(e)}
                ref={this._submitForm}
              />
            </table>
          </form>
          <div className="container">
            <div className="container placeAtEnd mr-4">
              <button class="btn btn-warning mt-3" onClick={e => this._clickSubmitButton(e)}>
                SAVE
              </button>
            </div>
          </div>
        </div>
      );
  }
}
const mapStateToProps = state => {
  return {
    bankDetailsViewObj: state.CommissionRecordReducer.bankDetailsViewObj,
    bankEditObject: state.CommissionRecordReducer.bankEditObject
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onBankingDetailsget: (BankDetailsObject, bankingDetailsObjectPartner) =>
        dispatch({ 
            type: actionTypes.BANKING_DETAILS_UPDATE, 
            bankingDetails: [
                BankDetailsObject,
                bankingDetailsObjectPartner
            ]
        }),
    onPartnerStatusUpdate: status =>
        dispatch({ 
            type: actionTypes.IS_PARTNER_UPDATE, 
            isPartner: status
        }),
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPaymentDetails);
