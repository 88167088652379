import React, { Component } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE, DELETE_NOTIFICATION_URL, GET_NOTIFICATION_URL,POST_NOTIFICATION_URL } from "../../API/APIendpoint";
import NotificationItem from "./NotificationItem";
import "w3-css/w3.css";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from '@material-ui/core/FormControlLabel';

toast.configure();

class NotificationHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationList:[],
      loading: false,
      selectedNotification: null,
      open:false,
      title:"",
      content:'',
      isImportant:false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    Axios.get(BASE + GET_NOTIFICATION_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.setState({ loading: false,notificationList: res.data.reverse()});
    });
  }
  
  onTitleChange(e) {
    e.preventDefault();
    this.setState({ title: e.target.value });
  }

  onContentChange(e) {
    e.preventDefault();
    this.setState({ content: e.target.value });
  }

  handleClose = () => {
    this.setState({open:false});
  };

  handleSubmit = () => {
    //Submit 
    let newNotification = {
      "notificationsId": 0,
      "title": this.state.title,
      "content": this.state.content,
      "isForNewMembers":this.state.isImportant,
    }

    Axios.post(BASE + POST_NOTIFICATION_URL,newNotification,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => {
        let notifications = [res.data,...this.state.notificationList]
        this.setState({open:false,notificationList: notifications});
      })
  };

  selectedNotification = (notification) =>{
    this.setState({selectedNotification:notification});
  };

  backFunction = () =>{
    this.setState({selectedNotification:null});
  };
  
  updateCheckBox = () =>{
    this.setState({isImportant:!this.state.isImportant});
  };

  handleDelete = (notification) => {
    
    Axios.post(BASE + DELETE_NOTIFICATION_URL + "?id="+ notification.notificationsId,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        let tempList = this.state.notificationList;
        const index = tempList.findIndex(key => key.notificationsId === notification.notificationsId)
        tempList.splice(index,1);
        this.setState({notificationList:tempList,selectedNotification:null});
      })
      .catch(error =>{
        console.log("Error deleting notification",error);
        this.setState({ loading: false});
        alert("Error deleting notification.")
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading notifications. Please wait ...</p>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        <div className="row pt-4">
          <div className="col-12 h2 pl-0"> Notifications </div>
        </div>
        <div className="row">
          <div className="col-12 pt-1" align="right">
            <button onClick={()=>{this.setState({open:true})}} className="btn-success">Add Notification</button>
            <Dialog
              open={this.state.open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
          <DialogTitle id="alert-dialog-title">Add new notification</DialogTitle>
          <DialogContent style={{height:'35ch'}}>
            <div className="row">
              <div className="col-12" style={{width: '75ch',marginBottom:'20px'}}>
        
                <FormControlLabel
                style={{marginLeft:'-2px'}}
                  value="Important Notification"
                  control={
                    <Checkbox
                    autoFocus
                    value={this.state.isImportant}
                    onChange={e => this.updateCheckBox(e)}
                  />
                  }
                  label="Important Notification"
                  labelPlacement="start"
                />
                <TextField
                  className="form-control"
                  id="notificationTitle"
                  label="Notification title"
                  defaultValue=" "
                  variant="outlined"
                  onChange={e => this.onTitleChange(e)}
                  />
              </div>
              
              <div className="col-12 pt-3" style={{width: '75ch'}}>
                <TextField
                  className="form-control"
                  id="notificationContent"
                  label="Notification Message"
                  multiline
                  rows={4}
                  defaultValue=" "
                  variant="outlined"
                  onChange={e => this.onContentChange(e)}
                />
              </div>
            </div>

          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="success">
             Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary" disabled = {this.state.title.length > 0 && this.state.content.length > 0 ? false : true}>
             Save & send
            </Button>
          </DialogActions>
        </Dialog>
          </div>
        </div>
        { !this.state.selectedNotification && this.state.notificationList?.map((Notification, index) => {
          return (
            <div  key={index}>

                <div className="row border p-2 mt-3">
                <div className="col-12">
                    <div className="row">
                    <div className="col-8">
                        <div className="row">
                        <div className="col-12" align="left">
                            {Notification.title}
                        </div>

                        </div>
                    </div>
                    <div className="col-4 pt-1" align="right">
                        {/* <button onClick={()=>{this.setState({selectedNotification:Notification})}} className="btn-danger" style={{marginRight:"5px"}}>delete</button> */}
                        <button onClick={()=>{this.handleDelete(Notification)}} className="btn-danger" style={{marginRight:"5px"}}>delete</button>
                        <button onClick={()=>{this.setState({selectedNotification:Notification})}} className="btn-info">view</button>
                    </div>
                    </div>
                </div>
                </div>

            </div>
          );
        })}

        {this.state.selectedNotification && <NotificationItem backFunction={this.backFunction} handleDelete={this.handleDelete} notification = {this.state.selectedNotification}/>}
      </div>
    );
  }
}

export default connect(
  null,
  null
)(NotificationHome);