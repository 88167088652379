import React from 'react'

export default class Stepper extends React.Component {
    numberCircle = (number, text, activity, noLine) => {
        let condition = "";
        switch (activity) {
            case -1:
                condition = "";
                break;
            case 0:
                condition = "active";
                break;
            case 1:
                condition = "was-active";
                break;
            default:
                condition = "";
        }
        return (
            <>
                {noLine ? null : <hr className={condition} />}
                <div className="circle-parent">
                    <div className={`number-circle ${condition}`}>{number}</div>
                    <label className="number-circle-text">{text}</label>
                </div>
            </>
        );
    }

    evaluate = (num) => {
        if (!num && num !== 0) {
            return null;
        }
        if (num < 0) {
            return -1;
        } else if (num > 0) {
            return 1;
        } else {
            return 0;
        }
    }
    render() {
        return (
            <div className="member-application-progress-root">
                <div className="circle-container">
                    {this.numberCircle(1, "Details", this.evaluate(parseInt(this.props.progress) - 1), true)}
                    {this.numberCircle(2, "Motivation", this.evaluate(parseInt(this.props.progress) - 2))}
                    {this.numberCircle(3, "Declaration", this.evaluate(parseInt(this.props.progress) - 3))}
                </div>
            </div>
        )
    }
}
