import React, { Component } from "react";
import { approvalStatus } from "./PendingActivityConstants";
import { connect } from "react-redux";
import Axios from "axios";
import { BASE } from "../../../../API/APIendpoint";
import {
  Card,
  CardContent,
  Paper,
  Button,
  CardActions,
  Input
} from "@material-ui/core";
import * as actionTypes from "../../../../store/actions/actionTypes";
class ApprovalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showReasonInputBox: false,
      reason: "",
      activityID: "",
      activityType: ""
    };
  }

  componentDidMount() {
    Axios.get(
      BASE + "api/Activities/activityId/" + this.props.MemberV.memNo, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }
    ).then(res => {
      this.setState({
        activityID: res.data.activityId,
        activityType: res.data.activityType
      });
    });
  }

  _handleRejectReasonSub(e) {
    Axios.post(
      BASE + "api/Activities/putRejectReason/" + this.state.activityID,
      {
        activityId: this.state.activityID,
        activityType: this.state.activityType,
        memNo: this.props.MemberV.memNo,
        rejectReason: this.state.reason
      }, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }
    )
    this.props.onCurrActivityTabChange(5)
  }

  render() {
    return (
      <div className="row">
        <Paper anchor="bottom" className="col-12  " elevation={0}>
          <div className="col-12 border-bottom-0 border-warning rounded">
            <div className="row">
              <div className="col-6 pl-0 p-1">
                <button
                  className="btn btn-outline-success form-control"
                  onClick={() =>
                    this.props.approveActivity(
                      this.props.activity.activityId,
                      approvalStatus.approve,
                      this.setState({
                        showReasonInputBox: false
                      }),
                      this.props.onCurrActivityTabChange(5)
                    )
                  }
                >
                  Approve
                </button>
              </div>

              <div className="col-6 pr-0 p-1">
                <button
                  className="btn btn-outline-danger form-control"
                  onClick={() =>
                    this.setState({
                      showReasonInputBox: true
                    })
                  }
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
          {this.state.showReasonInputBox === true ? (
            <Card>
              <CardContent>
                <Input
                  id="filled-multiline-flexible"
                  placeholder="Please provide a reason"
                  multiline
                  value={this.state.reason}
                  onChange={e => this.setState({ reason: e.target.value })}
                  fullWidth
                />
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={event => {
                    this.props.approveActivity(
                      this.props.activity.activityId,
                      approvalStatus.reject,
                      this.setState({
                        showReasonInputBox: false
                      })
                    );
                    this._handleRejectReasonSub(event);
                  }}
                >
                  Apply
                </Button>
              </CardActions>
            </Card>
          ) : null}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state, dispatch) => {
  return {
    activeStatus: state.MemberViewReducer.activeStatus,
    memberProfilePicture: state.memberProfilePictureReducer,
    pic: state.memberProfilePictureReducer.isExist,
    memberDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView

  };
};

const bindActionsToProps = dispatch => {
  return {
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      })
  }
}

export default connect(
  mapStateToProps,
  bindActionsToProps
)(ApprovalSection);
