import { AppBar, Tab, Tabs, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS, UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2, UPDATE_CURRENT_ADMIN_ACTIVITY_TAB } from "../../store/actions/actionTypes";
import AdminHome from "../adminNews/AdminHome";
import AdminNewsPage from "../adminNews/AdminNewsPage";
import AdminUserApproveAct from "../adminUser/activitiesTab/ApprovePendingActivities";
import PendingActivityApplication from "../adminUser/activitiesTab/PendingActivityApplication";
import AdminEndorsementView from "../adminUser/adminEndorsement/AdminEndorsementView";
import EndorsementDetails from "../adminUser/adminEndorsement/EndorsementDetails";
import AccountsView from "../adminViewMemberAreaPage/AccountsView";
import AccountViewAll from "../adminViewMemberAreaPage/AccountViewAll";
import Payment from "../adminViewMemberAreaPage/Payment";
import ViewUserDetails from "../adminViewMemberAreaPage/ViewUserDetails";
import DedicatedProfile from "../dedicatedHunter/DedicatedProfile";
import Assessments from "../learningManagementSystem/AdminAssessmentsTab/Assessments";
import Lessons from "../learningManagementSystem/AdminAssessmentsTab/Lessons.jsx";
import { GET_COURSE_MODULES_WITH_CHILDREN } from "../learningManagementSystem/LMSAPIEndPoints";
import AddFirearmDetails from "../loggeddInUser/LicensedFireArm/AddFirearmDetails";
import AddLicenseDetails from "../loggeddInUser/LicensedFireArm/AddLicenseDetails";
import LicenseAddNew from "../loggeddInUser/LicensedFireArm/LicenseAddNew";
import LicensedFirearms from "../loggeddInUser/LicensedFireArm/LicensedFireArms";
import LicensedFireArmUpdate from "../loggeddInUser/LicensedFireArm/LicensedFireArmUpdate";
import LicenseViewDetails from "../loggeddInUser/LicensedFireArm/LicenseViewDetails";
import LoyaltyDays from "../loggeddInUser/loyaltyDaysEarned/LoyaltyDays";
import DedicatedStatusDaysEarned from "../loggeddInUser/loyaltyDaysEarned/DedicatedStatusDaysEarned";
import CourseDaysEarned from "../loggeddInUser/loyaltyDaysEarned/CourseDaysEarned";
import EndorsementDaysEarned from "../loggeddInUser/loyaltyDaysEarned/EndorsementDaysEarned";
import ActivityDaysEarned from "../loggeddInUser/loyaltyDaysEarned/ActivityDaysEarned";
import MembershipFee from "../adminUser/processMembershipFee/MembershipFee";
import MainSalesCommission from "../loggeddInUser/salesCommission/MainSalesCommission";
import CommissionPaymentDetails from "../loggeddInUser/salesCommission/CommissionPaymentDetails";
import CommissionSelect from "../loggeddInUser/salesCommission/CommissionSelect"
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
function TabPane(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabPane.propTypes = {
  children: PropTypes.node
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
});

class ScrollableTabsButtonAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isSalesRep: false
    };
  }
  componentDidMount() {
    
    axios({
      url: `${BASE}api/admin/sales`,
      method: "get",
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` 
      }
    }).then(res => {
      res.data.filter(element => {
        if(element.memNo === this.props.MemberV.memNo) {
          this.setState({isSalesRep: true})
        }
      })
    })
  }
  handleChange = (event, value) => {
    if(value === 1 && this.props.isPartner && this.state.isSalesRep)
      return;

      this.setState({ value });
    if (value === 5) {
      this.props.onCurrActivityTabChange()
    }
  };
  //routing function based on the page load licence
  loadActivityTab = () => {
    switch (this.props.currActivityTab2) {
      case 0:
        return <PendingActivityApplication isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case 1:
        return <AdminUserApproveAct isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      default:
        return <PendingActivityApplication isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
    }
  };

  loadAdminEndorsementTab = () => {
    switch (this.props.currentAdminEndorsementTab) {
      case "0":
        return <AdminEndorsementView isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case "1":
        return <EndorsementDetails isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      default:
        return <AdminEndorsementView isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
    }
  };

  loadLicenseTab = () => {
    switch (this.props.currLicenseTab) {
      case "0":
        return <LicensedFirearms />;
      case "1":
        return <AddFirearmDetails />;
      case "2":
        return <AddLicenseDetails />;
      case "3":
        return <LicensedFireArmUpdate />;
      case "4":
        return <LicenseAddNew />;
      case "5":
        return <LicenseViewDetails />;
      default:
        return <LicensedFirearms />;
    }
  }; 

  loadNewsTab = () => {
    switch (this.props.currNewsTab) {
      case "0":
        return <AdminHome />;
      case "1":
        return <AdminNewsPage />;

      default:
        return <AdminNewsPage />;
    }
  };
  loadAccountViewTab = () => {
    switch (this.props.currAccountViewTab) {
      case 0:
        return <AccountsView isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case 1:
        return <Payment />;
      case 2:
        return <AccountViewAll />;
      case 3:
        return <MembershipFee />;
      default:
        return <AccountsView isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
    }
  };

  loadAssessmentsTab = () => {
    switch (this.props.currAssessmentTab) {
      case 0:
        return <Assessments  isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case 1:
        return <Lessons isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      default:
        return <Assessments isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
    }
  };

  loadLoyaltyDaysTab = () => {
    switch (this.props.currLoyaltyDaysTab) {
      case "0":
        return <LoyaltyDays isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case "1":
        return <DedicatedStatusDaysEarned isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case "2":
        return <CourseDaysEarned isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case "3":
        return <EndorsementDaysEarned isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      case "4":
        return <ActivityDaysEarned isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
      default:
        return <LoyaltyDays isPartner={this.props.isPartner} headerColor="#17a2b8"/>;
    }
  };
  loadCommissionTab = () => {
    switch (this.props.currentCommissionTab) {
      case "0":
        return <CommissionSelect />;
      case "1":
        return <MainSalesCommission />;
      case "2":
        return <CommissionPaymentDetails />;
      default:
        return <MainSalesCommission />;
    }
  };

  componentWillMount() {
    axios.get(GET_COURSE_MODULES_WITH_CHILDREN, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(res => {
      const modules = res.data;
      const assessments = modules.map(mod => {
        const material = mod.courseMaterial;
        return {
          assesmentId: mod.courseModuleId,
          title: mod.name,
          grade: "O",
          type: mod.type,
          hasChildren: material ? (material.length > 0) : false
        };
      });
      this.props.onAssesmentsChange(assessments);
      const material = modules.map(mod => [...mod.courseMaterial]);
      let lessons = [];
      material.forEach(mat => {
        mat.forEach(_lesson => {
          lessons.push({
            pkCourseId: _lesson.courseMaterialId,
            title: _lesson.name,
            content: _lesson.content,
            assesmnetId: _lesson.courseModuleId,
            type: _lesson.type
          });
        });
      });
      this.props.onLessonsChange(lessons);
    });
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const tabs = this.state.isSalesRep ? [
      "Profile",
      "Sales Commission",
      "Loyalty Days",
      "Account Details",
      "Dedicated Status",
      "Endorsement Applications",
      "Activity",
      "Assessments",
    ] : [
      "Profile",
      "Loyalty Days",
      "Account Details",
      "Dedicated Status",
      "Endorsement Applications",
      "Activity",
      "Assessments",
    ];
    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          color="default"
          style={{
            boxShadow:
              "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
            borderBottom: "2px solid #e5e2e8",
            backgroundColor: "#fcfcfc"
          }}
        >
        
        {this.props.isPartner && <div className="row mb-2  mt-2">
          <GenericHeader
            title={this.props.MemberV.title + ' ' + this.props.MemberV.firstname + ' ' + this.props.MemberV.surname}
            showBackButton
            subHeader={true}
            headerColor={"#fffff"}
            backFunction={() => this.props.back()}
          />
        </div>}
          <Tabs
            value={value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs.map((tab, index) => (
              
              // (tab !== "Sales Commission" || !this.props.isPartner) && <Tab key={index} label={tab} />
              <Tab key={index} label={tab} style={ this.props.isPartner && tab == "Sales Commission"  ? {cursor:'not-allowed', color: 'lightgrey'} : {}}/>

            ))}
          </Tabs>
        </AppBar>
        <div className="row p-0 m-0 ">
        {this.state.isSalesRep ? 
          <div className="pt-2 w-100">
            {value === 0 && <ViewUserDetails isPartner={this.props.isPartner} partnerExpiryDate={this.props} headerColor={this.props.headerColor} />}
            {value === 1 && this.loadCommissionTab()}
            {value === 2 && this.loadLoyaltyDaysTab()}
            {value === 3 && this.loadAccountViewTab()}
            {value === 4 && <DedicatedProfile isPartner={this.props.isPartner} headerColor={this.props.headerColor} />}
            {value === 5 && this.loadAdminEndorsementTab()}
            {value === 6 && this.loadActivityTab()}
            {value === 7 && this.loadAssessmentsTab()}
          </div> :
          <div className="pt-2 w-100">
            {value === 0 && <ViewUserDetails isPartner={this.props.isPartner} partnerExpiryDate={this.props}  headerColor={this.props.headerColor} />}
            {value === 1 && this.loadLoyaltyDaysTab()}
            {value === 2 && this.loadAccountViewTab()}
            {value === 3 && <DedicatedProfile isPartner={this.props.isPartner} headerColor={this.props.headerColor} />}
            {value === 4 && this.loadAdminEndorsementTab()}
            {value === 5 && this.loadActivityTab()}
            {value === 6 && this.loadAssessmentsTab()}
          </div>}
        </div>
      </div>
    );
  }
}

ScrollableTabsButtonAuto.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    MemberV: state.MemberViewReducer.MemberView,
    userProfileDetails: state.loginReducer.userProfileDetails,
    currentCommissionTab: state.CommissionReducer.currentCommissionTab,
    currLoyaltyDaysTab: state.LoyaltyDaysReducer.currentLoyaltyDaysTab,
    currActivityTab2: state.adminPortalReducer.currActivityTab,
    currAssessmentTab: state.lmsReducer.currAssessmentTab,
    currActivityTab: state.adminPortalReducer.adminPortal.currentActivityTab,
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    currentAdminEndorsementTab:
      state.endorsementReducer.currentAdminEndorsementTab,
    currentNationalRankingTab:
      state.adminPortalReducer.adminPortal.currentNationalRankingTab,
    activityMemberNumber:
      state.activitiesParametersReducer.activityMemberNumber,
    activityIdNumber: state.activitiesParametersReducer.activityID,
    currAccountViewTab: state.MemberViewReducer.currAccountViewTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLessonsChange: newLessons =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSONS,
        lessons: newLessons
      }),
    onAssesmentsChange: newAssesments =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENTS,
        assesments: newAssesments
      }),
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: UPDATE_CURRENT_ADMIN_ACTIVITY_TAB,
        currActivityTab: newCurrActivityTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ScrollableTabsButtonAuto));
