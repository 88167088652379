import {GET_COURSE_MODULES_WITH_CHILDREN} from "../../LMSAPIEndPoints";
import {
 FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN,
 FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS,
 FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE,
 TOGGLE_SHOW_CHILDREN
} from "../../../../store/actions/lmsActionTypes";

const fetchCourseModulesBegin = () => ({
  type: FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN,
  payload: []
});

const fetchCourseModulesSuccess = (modules) => ({
  type: FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS,
  payload: modules
});
 
const fetchCourseModulesFailure = (error) => ({
  type: FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE,
  error: error
});

export const fetchCourseModules = () => (dispatch) => {
  dispatch(fetchCourseModulesBegin())
  fetch(GET_COURSE_MODULES_WITH_CHILDREN)
  .then(res => res.json())
  .then(res => {

    dispatch(fetchCourseModulesSuccess(res.map(folder => {
      folder.showChildren = false; 
      return folder;
    })));
  })
  .catch(error => {
    dispatch(fetchCourseModulesFailure(error.message));
  })
}

export const toggleShowChildren  = (moduleId) => dispatch => {
  dispatch({
    type: TOGGLE_SHOW_CHILDREN,
    payload: moduleId
  })
}
