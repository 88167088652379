import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { activityDetailsStyles as styles } from "../../adminUser/activitiesTab/styles/styles";
import * as ApproveEndorsementActions from "./ApproveNewEndorsements/ApproveNewEndorsementsActions";
import UserSection from "./PendingActivitySubComponents/UserSection";
export const getCurrentDate = () => {

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let separator= "-";
  
  return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
}
class ApproveEndorsementDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: "",
      error: "",
      emailParam: {
        toEmail: this.props.memberDetails.email,
        ApplicationStatus: "Approved",
        Firstname: this.props.memberDetails.firstname,
        Surname: this.props.memberDetails.surname
      },
      memEmail: this.props.memberDetails.email,
      _applicationType: "Endorsement Application",
      showTextArea: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  async handleSubmitRes() {
    Axios({
      url:
        BASE + "api/EndorsementApplications/Reject/" +
        this.props.endApplication.endorsAppID +
        "/" +
        2 + "/" + this.state.reason,
      method: "post",
      headers: {
        "content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res =>
      this.props.ApproveEndorsementActions.sendEmail(this.state.emailParam)
    );
    this.updatePending();
    this.props.history.push("/admin/PendingEndorsementApplication");
  }
  updatePending = () => {
    let _pendingEndorsments = this.props.endorsements.filter(element => 
      element.endorsAppID !== this.props.endApplication.endorsAppID    
    )
    this.props.onEndorsementObjectUpdate(
      this.props.pendingCount,
      _pendingEndorsments,
      this.props.allEndorsements
    )
  }
  async handleSubmit(status) {
    const response = await this.props.ApproveEndorsementActions.approvePendingEndorsement(
      this.props.endApplication.endorsAppID,
      status,
      this.props.endApplication.reason
    );
    if (response) {
      if (response.status === 200) {
        // const response = await this.props.ApproveEndorsementActions.sendEmail(
        //   this.state.emailParam
        // );
        this.updatePending();
        Axios.post(BASE + "api/Member/extendMembershipDays/" + this.state.memEmail + "/" + this.props.loyaltyDaysObject.endorsementDays + "/endorsement",{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(
          Axios({
            url: BASE + "api/AutomatedEmail/post/SendLoyaltyEmail/" + this.state._applicationType + "/" + this.props.loyaltyDaysObject.endorsementDays,
            data: this.state.emailParam,
            method: "post",
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          })
        )
        if (response) {
          if (response.status === 200) {
            this.props.history.push("/admin/PendingEndorsementApplication");
          } else {
            this.setState({ error: "Error: couldnt send email" });
          }
        }
      } else {
        this.setState({ error: "Error: couldnt update status & send email" });
      }
    }
  }
  render() {
    const {
      memNo,
      endorsementNo,
      fireArmType,
      actionType,
      applicationType,
      caliber,
      section,
      motivation,
      applicationDate,
      status,
      reason,
      endorsAppID,
      endorsementPurpose
    } = this.props.endApplication;

    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <div className="container" style={{ marginTop: "60px", marginBottom: "80px" }}>
        <div>
          <GenericHeader
            title="Endorsement Applications"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/PendingEndorsementApplication");
            }}
          />
        </div>
        <UserSection user={this.props.memberDetails} />
        <div className="col-12 border-bottom bg-light">
          <h5>{`Endorsement Application No ${endorsAppID}`}</h5>
          <h5>{status}</h5>
          <h5>{`Application Date ${applicationDate.substring(0, 10)}`}</h5>
        </div>
        <div className="col-12">
          {info("Member No:", memNo ? memNo : "-")}
          {info("Endorsement No: ", endorsementNo ? endorsementNo : "-")}
          {info("Application Type:", applicationType ? applicationType : "-")}
          {info("Firearm Type:", fireArmType ? fireArmType : "-")}
          {info("Action Type:", actionType ? actionType : "-")}
          {info("Caliber:", caliber ? caliber : "-")}
          {info("Section:", section ? section : "-")}
          {info("Motivation:", motivation ? motivation : "-")}
          {info("Endorsement Purpose", endorsementPurpose ? endorsementPurpose : "-")}
          {info("Reason:", reason ? reason : "-")}
        </div>
        <div className="col-12">{this.state.error}</div>
          <div className="row p-3 border mt-2 rounded-top fixed-bottom bg-light">
            <div className="d-flex flex-column flex-wrap w-100">
              {!this.state.showTextArea ?
                <div className="d-flex w-100 justify-content-center align-items-center p-1">
                <div className="w-50 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-success border-dark text-dark w-40"
                      onClick={async () => {
                        var emailParam = this.state.emailParam;
                        emailParam.ApplicationStatus = "Approve";
                        this.setState({ emailParam: emailParam });
                        await this.handleSubmit(1);
                      }}
                    >
                      Approve
                    </button>
                </div>
                <div className="w-50 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-danger border-dark text-dark w-40"
                      onClick={() =>
                        this.setState({
                          showTextArea: true
                        })
                      }
                    >
                      Reject
                    </button>
                </div>
              </div>
              : <div className="d-flex justify-content-center w-100 p-3">
                  <textarea
                    style={{
                      width: "75%",
                      height: "100px"
                    }}
                    value={this.state.reason}
                    onChange={e => this.setState({ reason: e.target.value })}
                    placeholder="Provide a reason"
                  />
                </div>}
              {this.state.showTextArea === true ? (
                <div className="d-flex w-100 justify-content-center align-items-center p-1">
                  <div className="w-50 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-danger border-dark text-dark w-40"
                        onClick={async () => {
                          var emailParam = this.state.emailParam;
                          emailParam.ApplicationStatus = "Rejected";
                          this.setState({ emailParam: emailParam });
                          await this.handleSubmitRes();
                        }}
                      >
                        Reject
                      </button>
                  </div>
                  <div className="w-50 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-success border-dark text-dark w-40"
                      onClick={() =>{
                        this.setState({
                          showTextArea: false
                        })
                      }
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
      </div>
    );
  }
}

ApproveEndorsementDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    memberDetails: state.dashBoardReducer.membersPending,
    endApplication: state.approveNewEndorsementsState.application,
    reason: state.approveNewEndorsementsState.reason,
    loyaltyDaysObject: state.LoyaltyDaysReducer.loyaltyDaysObject,
    endorsements: state.dashBoardReducer.endorsements.pendingEndorsements,
    pendingEndorsementCount: state.dashBoardReducer.endorsements.pendingEndorsementCount,
    allEndorsements: state.dashBoardReducer.endorsements.allEndorsements
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ApproveEndorsementActions: bindActionCreators(
      ApproveEndorsementActions,
      dispatch
    ),
    onEndorsementObjectUpdate: (pendingCount, pendingEndorsements, allEndorsements) =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.GET_ALL_ENDORSEMENTS_URL,
        endorsements: {
          pendingEndorsementCount: pendingCount,
          pendingEndorsements: pendingEndorsements,
          allEndorsements: allEndorsements
        }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ApproveEndorsementDashboard));
