import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import MainActivities from "../loggeddInUser/activitiesPage/MainActivities";
import NewActivityDetails from "../loggeddInUser/activitiesPage/NewActivityDetails";
import NewActivityFirearmDetails from "../loggeddInUser/activitiesPage/NewActivityFirearmDetails";
import MainEndorsement from "../loggeddInUser/endorsement/MainEndorsement";
import EndorsementApplicationDetails from "../loggeddInUser/endorsement/EndorsementApplicationDeatails";
import EndorsementApplicationFirearm from "../loggeddInUser/endorsement/EndorsementApplicationFirearm";
import LicensedFirearms from "../loggeddInUser/LicensedFireArm/LicensedFireArms";
import LicensedFireArmUpdate from "../loggeddInUser/LicensedFireArm/LicensedFireArmUpdate";
import LicenseAddNew from "../loggeddInUser/LicensedFireArm/LicenseAddNew";
import LicenseViewDetails from "../loggeddInUser/LicensedFireArm/LicenseViewDetails";
import AddFirearmDetails from "../loggeddInUser/LicensedFireArm/AddFirearmDetails";
import EndorsementApplicationDeclaration from "../loggeddInUser/endorsement/EndorsementApplicationDeclaration";
import EndorsementApplicationDone from "../loggeddInUser/endorsement/EndorsementApplicationDone";
import EndorsementApplicationView from "../loggeddInUser/endorsement/EndorsementApplicationView";
import AddLicenseDetails from "../loggeddInUser/LicensedFireArm/AddLicenseDetails";
import DedicatedProfile from "../dedicatedHunter/DedicatedProfile";
import LMS from "../learningManagementSystem/UserHome/Lms";
import DisplaySection from "../learningManagementSystem/UserHome/DisplaySection.jsx";
import UserDetails from "../../components/loggeddInUser/userProfile/UserDetails";
import TakeCourse from "../learningManagementSystem/UserHome/TakeCourse";
import TakeQuiz from "../learningManagementSystem/UserHome/TakeQuiz";
import QuizResults from "../learningManagementSystem/UserHome/QuizResults";
import ViewResults from "../learningManagementSystem/UserHome/ViewResults.jsx";
import MainAccount from "../loggeddInUser/account/MainAccount";
import PaymentView from "../loggeddInUser/account/PaymentView";
import Payfast from "../loggeddInUser/account/Payfast";
import MainMembershipCard from "../loggeddInUser/membershipCard/MainMembershipCard";
import LoggedInAcitvityDetails from "../loggeddInUser/activitiesPage/ActivityDetails";
import Documents from "./../AdminDownloads/Documents";
import LoyaltyDays from "../loggeddInUser/loyaltyDaysEarned/LoyaltyDays";
import DedicatedStatusDaysEarned from "../loggeddInUser/loyaltyDaysEarned/DedicatedStatusDaysEarned";
import CourseDaysEarned from "../loggeddInUser/loyaltyDaysEarned/CourseDaysEarned";
import EndorsementDaysEarned from "../loggeddInUser/loyaltyDaysEarned/EndorsementDaysEarned";
import ActivityDaysEarned from "../loggeddInUser/loyaltyDaysEarned/ActivityDaysEarned";
import MainSalesCommission from "../loggeddInUser/salesCommission/MainSalesCommission";
import CommissionSelect from "../loggeddInUser/salesCommission/CommissionSelect";
import CommissionPaymentDetails from "../loggeddInUser/salesCommission/CommissionPaymentDetails";
import EditPaymentDetails from "../loggeddInUser/salesCommission/EditPaymentDetails";
import {
  LMS_ACTIONS,
  UPDATE_LICENCE_LIST
} from "../../store/actions/actionTypes";
import axios from "axios";
import { GET_COURSE_MODULES_WITH_CHILDREN } from "../learningManagementSystem/LMSAPIEndPoints";
import { BASE, GET_SPEC_LIST } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
function TabPane(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabPane.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFF"
  }
});

class ScrollableTabsButtonAuto extends React.Component {
  state = {
    value: 0,
    isSalesRep: false
  };
  componentDidMount() {
    if (sessionStorage.getItem("tokenKey") === null) {
      this.props.onUserDetailsChange("", "email");
      this.props.onUserDetailsChange("", "username");
      this.props.onUserDetailsChange("", "cellNo");
      this.props.onUserDetailsChange("", "physicalAddress");
      this.props.onUserDetailsChange("", "memberNumber");
      this.props.onUserDetailsChange("", "expiryDate");
      sessionStorage.removeItem("member");
      sessionStorage.removeItem("activeStatus");
      sessionStorage.removeItem("tokenKey");
      localStorage.removeItem("tokenKey");
      this.props.onUserDetailsChange(false, "isLoggedIn");
      this.props.onUserDetailsChange(false, "adminLevel");
      this.props.history.push("/");
    }

  }

  componentWillMount() {
    axios({
      url: `${BASE}api/admin/sales`,
      method: "get",
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` 
      }
    }).then(res => {
      res.data.filter(element => {
        if(element.memNo === this.props.userProfileDetails.memberNumber) {
          this.setState({isSalesRep: true})
        }
      })
    })
    axios
      .get(BASE + GET_SPEC_LIST + this.props.userProfileDetails.memberNumber, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(res => {
        var licenses = res.data;
        this.props.onUserLicenseChange(licenses);
      });
    axios.get(GET_COURSE_MODULES_WITH_CHILDREN, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(res => {
      const modules = res.data;
      const assessments = modules.map(mod => {
        const material = mod.courseMaterial;
        return {
          assesmentId: mod.courseModuleId,
          title: mod.name,
          grade: "O",
          type: mod.type,
          hasChildren: material ? (material.length > 0) : false
        };
      });
      this.props.onAssesmentsChange(assessments);
      const material = modules.map(mod => [...mod.courseMaterial]);
      let lessons = [];
      material.forEach(mat => {
        mat.forEach(_lesson => {
          lessons.push({
            pkCourseId: _lesson.courseMaterialId,
            title: _lesson.name,
            content: _lesson.content,
            assesmnetId: _lesson.courseModuleId,
            type: _lesson.type
          });
        });
      });
      this.props.onLessonsChange(lessons);
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  //routing function based on the page load licence
  loadActivityTab = () => {
    switch (this.props.currActivityTab) {
      case "0":
        return <MainActivities />;
      case "1":
        return <NewActivityDetails />;
      case "2":
        return <NewActivityFirearmDetails />;
      case "3":
        return (
          <LoggedInAcitvityDetails
            memberNumber={this.props.activityMemberNumber}
            activityId={this.props.activityIdNumber}
          />
        );
      default:
        return <MainActivities />;
    }
  };

  loadLicenseTab = () => {
    switch (this.props.currLicenseTab) {
      case "0":
        return <LicensedFirearms />;
      case "1":
        return <AddFirearmDetails />;
      case "2":
        return <AddLicenseDetails />;
      case "3":
        return <LicensedFireArmUpdate />;
      case "4":
        return <LicenseAddNew />;
      case "5":
        return <LicenseViewDetails />;
      default:
        return <LicensedFirearms />;
    }
  };

  loadLMS = () => {
    switch (this.props.currLmsTab) {
      case 0:
        return <LMS />;
      case 1:
        return <DisplaySection />;

      case 2:
        return <TakeCourse />;
      case 3:
        return <TakeQuiz />;
      case 4:
        return <QuizResults />;
      case 5:
        return <ViewResults />;
      default:
        return <LMS />;
    }
  };
  loadAccountTab = () => {
    switch (this.props.currAccountTab) {
      case "0":
        return <MainAccount />;
      case "1":
        return <PaymentView />;
      case "2":
        return <Payfast />;
      default:
        return <MainAccount />;
    }
  };

  loadEndorsementTab = () => {
    switch (this.props.currentEndorsementTab) {
      case "0":
        return <MainEndorsement />;
      case "1":
        return <EndorsementApplicationDetails />;
      case "2":
        return <EndorsementApplicationFirearm />;
      case "3":
        return <EndorsementApplicationDeclaration />;
      case "4":
        return <EndorsementApplicationDone />;
      case "5":
        return <EndorsementApplicationView />;
      default:
        return <MainEndorsement />;
    }
  };

  loadLoyaltyDaysTab = () => {
    switch (this.props.currLoyaltyDaysTab) {
      case "0":
        return <LoyaltyDays />;
      case "1":
        return <DedicatedStatusDaysEarned />;
      case "2":
        return <CourseDaysEarned />;
      case "3":
        return <EndorsementDaysEarned />;
      case "4":
        return <ActivityDaysEarned />;
      default:
        return <LoyaltyDays />;
    }
  };

  loadCommissionTab = () => {
    switch (this.props.currentCommissionTab) {
      case "0":
        return <CommissionSelect />;
      case "1":
        return <MainSalesCommission />;
      case "2":
        return <CommissionPaymentDetails />;
      case "3":
        return <EditPaymentDetails />;
      default:
        return <MainSalesCommission />;
    }
  };

  render() {
    const { value } = this.state;
    const tabs = this.state.isSalesRep ? [
      "Profile",
      "Sales Commission",
      "Loyalty Days",
      "My Account",
      "Membership Card",
      "Courses",
      "Dedicated Status",
      "Endorsement",
      "Activity",
      "License Reminders",
      "Documents"
    ] : 
    [
      "Profile",
      "Loyalty Days",
      "My Account",
      "Membership Card",
      "Courses",
      "Dedicated Status",
      "Endorsement",
      "Activity",
      "License Reminders",
      "Documents"
    ]
    return (
      <div
        className="container p-0"
        style={{
          maxWidth: "2180px"
        }}
      >
        <div className="col-12 p-0 m-0 ">
          <AppBar
            position="static"
            color="default"
            style={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
              borderBottom: "2px solid #e5e2e8",
              backgroundColor: "#fcfcfc"
            }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab} />
              ))}
            </Tabs>
          </AppBar>
        </div>
        <div className="row p-0 m-0 ">
          {this.state.isSalesRep ? 
            <div className="col-12 p-0">
              {value === 0 && <UserDetails />}
              {value === 1 && this.loadCommissionTab()}
              {value === 2 && this.loadLoyaltyDaysTab()}
              {value === 3 && this.loadAccountTab()}
              {value === 4 && <MainMembershipCard />}
              {value === 5 && this.loadLMS()}
              {value === 6 && <DedicatedProfile />}
              {value === 7 && this.loadEndorsementTab()}
              {value === 8 && this.loadActivityTab()}
              {value === 9 && this.loadLicenseTab()}
              {value === 10 && <Documents />}
            </div> :
            <div className="col-12 p-0">
              {value === 0 && <UserDetails />}
              {value === 1 && this.loadLoyaltyDaysTab()}
              {value === 2 && this.loadAccountTab()}
              {value === 3 && <MainMembershipCard />}
              {value === 4 && this.loadLMS()}
              {value === 5 && <DedicatedProfile />}
              {value === 6 && this.loadEndorsementTab()}
              {value === 7 && this.loadActivityTab()}
              {value === 8 && this.loadLicenseTab()}
              {value === 9 && <Documents />}
          </div>}
        </div>
      </div>
    );
  }
}

ScrollableTabsButtonAuto.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currentCommissionTab: state.CommissionReducer.currentCommissionTab,
    currLoyaltyDaysTab: state.LoyaltyDaysReducer.currentLoyaltyDaysTab,
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    currActivityTab: state.membersAreaReducer.membersArea.currentActivityTab,
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    currAccountTab: state.accountReducer.account.currentAccountTab,
    currLmsTab: state.lmsReducer.currLmsTab,
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    currNewsTab: state.articlesReducer.articles,
    userProfileDetails: state.loginReducer.userProfileDetails,
    activityMemberNumber:
    state.activitiesParametersReducer.activityMemberNumber,
    activityIdNumber: state.activitiesParametersReducer.activityID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserLicenseChange: newUserLicensesArray =>
      dispatch({
        type: UPDATE_LICENCE_LIST,
        payload: newUserLicensesArray
      }),
    onMemberCoursesChange: newMemberCourses =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_MEMBER_COURSES,
        memberCourses: newMemberCourses
      }),
    onQuizzesChange: newQuizzes =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZZES,
        quizzes: newQuizzes
      }),
    onLessonsChange: newLessons =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSONS,
        lessons: newLessons
      }),
    onSectionTitleChange: newSectionTitle =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_SECTION_TITLE,
        sectionTitle: newSectionTitle
      }),
    onAssesmentsChange: newAssesments =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENTS,
        assesments: newAssesments
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ScrollableTabsButtonAuto)));
