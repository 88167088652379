import React from 'react';

export const electronicCommunicationPolicy = (
  "NARFO South Africa embraces the digital age and encourages members to have some form of online \
  device to connect to our platform to enjoy the services we provide. Although you can visit any \
  of our partners or speak to a representative, our services are digitised. These includes end to \
  end administration of your profile and general communication. Our only form of communication is \
  electronic interaction through the platform, emails and through our social media platform.\
   \
  By continuing, you agree to the terms as stated above and give your consent to hold your personal\
  data in order for us to provide you with the services our platform provides. You also agree that we\
  may engage with you through electronic means and keep you informed about relevant information, \
  news and topics of interest."
);

export const electronicDeclaration = (
  'I hereby declare that the information furnished above is true and correct to the best\
  of my knowledge and I undertake to inform NARFO of any changes herein by either \
  updating my profile electronically or by informing NARFO of changes by email. In the \
  event that the information is found to be false or misleading or misrepresenting, I am\
  aware that my membership may be cancelled.'
);


export const genderConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      direction: 'row',
      placeholder: 'Gender',
      radios: [
        { value: 'male', label: 'Male'},
        { value: 'female', label: 'Female'},
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const firstNameConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Full Name',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const surnameConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Surname',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const nicknameConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Nickname',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const idNumberConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'ID Number',
    },
    validate: {
      required: true,
      minLength: 13,
      maxLength: 13
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const cellphoneNumberConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Cellphone Number',
    },
    validate: {
      required: true,

    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const occupationConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Occupation',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const ethnicityConfig = {
  config: {
    elementType: 'select',
    elementConfig: {
      type: 'select',
      placeholder: 'Ethnicity',
      options: [
        {value: 'black', label: 'Black'},
        {value: 'coloured', label: 'Coloured'},
        {value: 'indian', label: 'Indian'},
        {value: 'white', label: 'White'},
        {value: 'other', label: 'Other'}
      ],
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: '-'
};

export const titleConfig = {
  config: {
    elementType: 'select',
    elementConfig: {
      type: 'select',
      placeholder: 'Title',
      options: [
        {value: 'mr', label: 'Mr'},
        {value: 'ms', label: 'Ms'},
        {value: 'mrs', label: 'Mrs'},
        {value: 'dr', label: 'DR'},
      ],
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: '-'
};

export const physicalAddressConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Street Address',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const suburbConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Suburb',
    },
    validate: {
      required: false,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const cityConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'City',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const provinceConfig = {
  config: {
    elementType: 'select',
    elementConfig: {
      type: 'select',
      placeholder: 'Province',
      options: [
        {value: 'Eastern Cape', label: 'Eastern Cape'},
        {value: 'Free State', label: 'Free State'},
        {value: 'Gauteng', label: 'Gauteng'},
        {value: 'KwaZulu Natal', label: 'KwaZulu Natal'}, 
        {value: 'Limpopo', label: 'Limpopo'},
        {value: 'Mpumalanga', label: 'Mpumalanga'},
        {value: 'Northern Cape', label: 'Northern Cape'},
        {value: 'North West', label: 'North West'},
        {value: 'Western Cape', label: 'Western Cape'}
      ],
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: '-'
};

export const postalCodeConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'number',
      placeholder: 'Postal Code',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const fireArmsControlActConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      placeholder: 'Are you well acquainted with the current Firearms Control Act?',
      direction: 'row',
      radios: [
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const fireArmsUseStorageConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      placeholder: 'Are you well acquainted with the use and storage of your Firearm?',
      direction: 'row',
      radios: [
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const criminalOffenseConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      placeholder: 'Have you ever been found guilty of any criminal or firearm related offence where your fingerprints were taken?',
      direction: 'row',
      radios: [
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const declareCodeOfConductConfig = {
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      placeholder: 
      <div>
        <span>I hereby declare that I fully understand and abide by the </span>
        <a target="_blank" rel="noopener noreferrer" href="https://portal.narfo.co.za/uploads/2020/05/NARFO General Codes of Conduct (B)-Narfo.pdf">
          Code of Conduct
        </a>
      </div>,
      accept: {value: 'true', label: 'Yes'}
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const declareDisciplinaryCodeConfig = {
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      placeholder: 
      <div>
        <span>I hereby declare that I fully understand and abide by the </span>
        <a target="_blank" rel="noopener noreferrer" href="https://portal.narfo.co.za/uploads/2020/05/NARFO Disciplinary Code (C)-Narfo.pdf">
          Disciplinary Code
        </a>
      </div>,
      accept: {value: 'true', label: 'Yes'}
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const declareValidInformationConfig = {
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      placeholder: 'I hereby declare that all information in this document is true',
      accept: {value: 'true', label: 'Yes'}
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const declareElectronicPolicyConfig = {
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      placeholder: 'I hereby accept the Electronic Communication Policy',
      accept: {value: 'true', label: 'Yes'}
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  message: electronicCommunicationPolicy,
  value: ''
};

export const declareValidElectronicTrueConfig = {
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      placeholder: 'By checking this option, I hereby accept the above declaration',
      accept: {value: 'true', label: 'Yes'}
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  message: electronicDeclaration,
  value: ''
};

export const salesReferralConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      direction: 'row',
      placeholder: 'Were you referred by a sales representative?',
      radios: [
        { value: 'Yes', label: 'Yes'},
        { value: 'NM-0011', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const partnerReferralConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      direction: 'row',
      placeholder: 'Were you referred by a partner?',
      radios: [
        { value: 'Yes', label: 'Yes'},
        { value: 'Narfo Head Office', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const associationAffiliationConfig = {
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      direction: 'row',
      placeholder: 'Are you affiliated with another association?',
      radios: [
        { value: 'true', label: 'Yes'},
        { value: 'false', label: 'No'}
      ]
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const salesRepresentativesConfig = {
  config: {
    elementType: 'select',
    elementConfig: {
      type: 'select',
      placeholder: 'Sales Rep',
      options: [
        {value: '', label: ''},
      ],
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const partnersConfig = {
  config: {
    elementType: 'select',
    elementConfig: {
      type: 'select',
      placeholder: 'Partner',
      options: [
        {value: '', label: ''},
      ],
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const emailConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'email',
      placeholder: 'Email Address',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const usernameConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Username',
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const passwordConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Password',
    },
    validate: {
      required: true,
      minLength: 8
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};

export const confirmPasswordConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'password',
      placeholder: 'Confirm Password',
    },
    validate: {
      required: true,
      minLength: 8
    },
    valid: true,
    errors: [],
    touched: false,
    width: 'full',
  },
  value: ''
};
