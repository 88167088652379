import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_CURRENT_LMS_TAB
} from '../../store/LearningManagement/actionTypes';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { SubtitleBar } from '..';

export class ViewResults extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="row m-2 h4 position-sticky"
        >
          <SubtitleBar
            back
            variant='h4'
            color='primary'
            title={`${this.props.oneLesson.title.split('Quiz')} Quiz`}
            onBack={() => this.props.onCurrentLMSTabChange(0)} 
          />
        </div>
        <div className="row">
          <div className="col-1" />
          <div className="col-10">
            {this.props.resultsRecord.map((value, index) => (
              <div key={index} className="row  m-1 border-bottom">
                <div className="col-6 h6" align="left">Question {value.question} </div>
                <div className="col-6" align="right">
                  {value.score < 75 ? (
                    <HighlightOff
                      style={{
                        width: '45px',
                        height: '30px',
                        color: 'red'
                      }}
                    />
                  ) : (
                    <CheckCircleOutline
                      style={{
                        width: '45px',
                        height: '30px',
                        color: 'green'
                      }}
                    />
                  )}
                </div>
                
              </div>
            ))}
          </div>
          <div className="col-1" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  memberNumber: state.AuthReducer.memNo,
  oneLesson: state.nLearningManagementReducer.lesson,
  resultsRecord: state.nLearningManagementReducer.resultsRecord
});

const mapDispatchToProps = dispatch => ({
  onCurrentLMSTabChange: newCurrentLmsTab =>
    dispatch({
      type: UPDATE_CURRENT_LMS_TAB,
      currLmsTab: newCurrentLmsTab
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewResults);
