import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubtitleBar,IconLabelButton } from '../index';
import { 
  Grid, Typography
} from '@material-ui/core';
import { label } from '../../shared/helpers';
import { format } from 'date-fns';
import MemberService from '../../services/member.service';
import { printDedicatedStatus } from '../../shared/pdfHelpers';
import { FRONT } from "../../../API/APIendpoint";
import GenericTable from '../GenericTable/GenericTable';

const columns = [
  { field: 'date', headerName: 'Date' },
  { field: 'status', headerName: localStorage.getItem('isMobile') === 'true' ? 'Status' : 'Application Status' },
  { field: 'reason', headerName: 'Comment'}
];

class ViewDedicatedStatusDetails extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);

    //Get Date time 
    this.timelineDetails = this.props.dedicatedStatusRecord.dedicatedStatusTimelines;
    this.timelineDetails.sort(function(a,b){return new Date(b.date) - new Date(a.date)});

    this.timelineDetails = this.timelineDetails.map(x => {
      return ({date :new Date(x.date).toLocaleString(),
      status :x.status,
      reason : x.reason})
    });
  }

    downloadDedicatedStatus = (dedicatedStatusRecord) => {
      const memberId = this.props.memNo;
      return this.memberService.getMemberQRCode(memberId)
        .then((resp) => {
          const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
          return this.memberService.getAllImages().then((images) =>
            printDedicatedStatus(
              this.props.memberData, 
              dedicatedStatusRecord, 
              images.data.nnfo, 
              base64QRCode,
              images.data.pdfSign,
              images.data.pdflogo, 
              images.data.signature2,
              images.data.dsSportsShooter,
              images.data.dsHunter
            )
          );
        });
    }

    render() {
      const isMobile = localStorage.getItem('isMobile') === 'true';
      return (
        <React.Fragment>
          <SubtitleBar 
            variant="subtitle1"
            title="Details"
            back
            onBack={this.props.goBack}
            showDownloadDSDocument={this.props.dedicatedStatusRecord.status === 'Approved'}
            downloadDedicatedStatusCallBack={() => this.downloadDedicatedStatus(this.props.dedicatedStatusRecord, '#auto-table')}
            
          />
          <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
            <Grid container spacing={4}>
              {label('Dedicated Status No', this.props.dedicatedStatusRecord.dsno, false, isMobile)}
              {label('Application Type', this.props.dedicatedStatusRecord.dstype, false, isMobile)}
              {label('Application Date', format(
                new Date(this.props.dedicatedStatusRecord.applicationDate),
                'yyyy/MM/dd'), false, isMobile)}
              {label('Status', this.props.dedicatedStatusRecord.status, false, isMobile)}
              {label('Approval Date', this.props.dedicatedStatusRecord.approvalDate ? format(
                new Date(this.props.dedicatedStatusRecord.approvalDate),
                'yyyy/MM/dd') : "-", false, isMobile)}
              {label('Motivation', this.props.dedicatedStatusRecord.motivation, true, isMobile)}
            </Grid>
          </div>
          {this.props.dedicatedStatusRecord.proffessionalHunter.length > 0 ?
            <div className="mt-4">
              <Typography variant="h6">Has a proffessional hunter status with other association</Typography>
              <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
                <Grid container spacing={4}>
                  {label(
                    'Association Name', 
                    this.props.dedicatedStatusRecord.proffessionalHunter[0].association, 
                    this.props.dedicatedStatusRecord.proffessionalHunter[0].association.length > 7 
                      ? true : 
                      false)}
                  {label('Registration No', this.props.dedicatedStatusRecord.proffessionalHunter[0].regNo, false, isMobile)}
                  {label('Province Aquired', this.props.dedicatedStatusRecord.proffessionalHunter[0].province, false, isMobile)}
                  {label('Expiry Date', format(
                    new Date(this.props.dedicatedStatusRecord.proffessionalHunter[0].expiryDate.split('T')[0]), 
                    'yyyy/MM/dd'), false, isMobile)}
                  {label('Downloadable', 
                    <a
                      className="text-primary font-weight-bold"
                      style={{ textDecoration: 'none' }}
                      href={FRONT + this.props.dedicatedStatusRecord.proffessionalHunter[0].link}
                      target="_"
                    >
                    Document
                    </a>
                    , false, isMobile)}
                </Grid>
              </div>
            </div>
            : null}
          {this.props.dedicatedStatusRecord.otherDedicatedStatus.length > 0 ?
            <div className="mt-4">
              <Typography variant="h5">Has a dedicated status with other association</Typography>
              <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
                <Grid container spacing={4}>
                  {label(
                    'Association Name', 
                    this.props.dedicatedStatusRecord.otherDedicatedStatus[0].associationName, 
                    this.props.dedicatedStatusRecord.otherDedicatedStatus[0].associationName.length > 7 
                      ? true : 
                      false)}
                  {label('Dedicated Status No', this.props.dedicatedStatusRecord.otherDedicatedStatus[0].dsNo, false, isMobile)}
                  {label('Association Far No', this.props.dedicatedStatusRecord.otherDedicatedStatus[0].farNo, false, isMobile)}
                  {label('Expiry Date', format(
                    new Date(this.props.dedicatedStatusRecord.otherDedicatedStatus[0].expiryDate.split('T')[0]),
                    'yyyy/MM/dd'), false, isMobile)}
                  {label('Downloadable', 
                    <a
                      className="text-primary font-weight-bold"
                      style={{ textDecoration: 'none' }}
                      href={FRONT + this.props.dedicatedStatusRecord.otherDedicatedStatus[0].link}
                      target="_"
                    >
                    Document
                    </a>
                    , false, isMobile)}
                </Grid>
              </div>
            </div>
            : null}

            <div className="mt-4">
              <Typography variant="h5">Status timeline</Typography>
              <div >
              {this.props.dedicatedStatusRecord.dedicatedStatusTimelines.length !== 0 ?
              <GenericTable 
                columns={columns}
                rows={this.timelineDetails}
              /> : null}
              </div>
            
            {/* Implement Re-application */}
            {this.props.dedicatedStatusRecord.status === "Declined" || this.props.dedicatedStatusRecord.status === "Terminated" ? <SubtitleBar
                title=""
                reApply
                sortable
                callBack={() => this.props.reApplayCallBack(this.props.dedicatedStatusRecord.dstype)}
                dsApplications
              />
              : null}
            </div>
        </React.Fragment>
      );
    }
}
const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memberData: state.ProfileReducer.memberData,
  memNo: state.AuthReducer.memNo,
  dedicatedStatusRecord: state.nDedicatedStatusReducer.dedicatedStatusRecord
});

export default connect(mapStateToProps)(ViewDedicatedStatusDetails);