import * as Types from "./PendingActivityActionTypes";
import {
  activityApproval,
  activityEmail
} from "./PendingActivityAPI";

export const approvePendingBeginAC = () => {
  return {
    type: Types.APPROVE_PENDING_BEGIN,
    pendingActivityState: {
      approvalStatus: {
        loading: true,
        error: null
      }
    }
  };
};

export const approvePendingSuccessAC = (response) => {
  return {
    type: Types.APPROVE_PENDING_SUCCESS,
    pendingActivityState: {
      approvalStatus: {
        loading: false,
        response
      },
      activity: response.data
    }
  };
};

export const approvePendingErrorAC = (error) => {
  return {
    type: Types.APPROVE_PENDING_ERROR,
    pendingActivityState: {
      approvalStatus: {
        loading: false,
        error
      }
    }
  };
};

export const approvePending = (id, approvalStatus, reason, emailParams) => async (dispatch) => {
  dispatch(approvePendingBeginAC());
  try {
    const response = await activityApproval(id, approvalStatus, reason);
    dispatch(approvePendingSuccessAC(response));
    //DONE: This call will be done in the backend
    // if(response.status === 200){
    //   activityEmail(emailParams);
    // }
    return response;
  } catch (error) {
    dispatch(approvePendingErrorAC(error));
  }
}

export const setMemberActivityAC = (member, activity) => {
  return {
    type: Types.SET_MEMBER_ACTIVITY,
    pendingActivityState: {
      member,
      activity
    }
  }
}

export const setMemberActivity = (member, activity) => (dispatch) => dispatch(setMemberActivityAC(member, activity));
