import React from "react";

export function showOption(questionType, description, index,
	totalOptions, questionId, score, actualScore) {
	switch (questionType) {
	case 0:
	  return (
		<div className="d-flex w-100 mb-2">
		  <input
			className="d-inline-block mr-2 align-self-center"
			key={questionId * 10 + index}
			name={`${questionId}single`}
			type={'radio'}
			onChange={() => {
			  score['scoreIndex'] = index;
			}}
		  />
		  <label
			className="align-self-center mb-0"
		  >{description}</label>
		</div>
	  );
	case 1:
	  var arr = score['scoreArrayIndex'];
	  var numOfOptions = 0;
	  arr.forEach((a, i) => {
		if (a === 1) {
		  if (actualScore === numOfOptions) {
			arr[i] = 0;
		  }
		  numOfOptions += 1;
		}
	  });
  
	  return (
		<div className="d-flex w-100 mb-2">
		  {(actualScore <= numOfOptions && arr[index] !== 1) ?
			<div style={{
			  minWidth: '22.5px',
			  height: '22.5px',
			  border: '1px dashed'
			}}
			className="d-inline-block mr-2 align-self-center">
			</div> :
			<input
			  className="d-inline-block mr-2 align-self-center"
			  style={{
				minWidth: '22.5px'
			  }}
			  key={questionId * 10 + index}
			  type={'checkbox'}
			  onChange={(e) => {
				if (e.target.checked) {
				  arr[index] = 1;
				} else {
				  arr[index] = 0;
				}
			  }}
			/>
		  }
		  <label
			className="align-self-center mb-0"
		  >{description}</label>
		</div>
	  );
	case 2:
	  return sortOption();
	case 3:
	  return sortOption(64, false);
	default:
	  return null;
	}
  
	function sortOption(offset = 0, isNumbers = true) {
	  const choices = Array.from(new Array(totalOptions + 1),
		(v, k) => k + offset);
	  return (
		<div className="d-flex w-100 mb-2">
		  <select
			className="mr-2 w-3"
			key={questionId * 10 + index}
			onChange={(e) => {
			  let arr = score['scoreArrayValues'];
			  arr[index] = parseInt(e.target.value);
			}}
		  >
			{choices.map((c, k) => <option value={c} key={k}>{isNumbers ? c : String.fromCharCode(c)}</option>)}
		  </select>
		  <label
			className="align-self-center mb-0"
		  >{description}</label>
		</div>
	  );
	}
  }

export function calculateScore(questionType, trueScores, scored) {
  switch (questionType) {
    case 0:
      if (trueScores[scored] === 1) {
        return 1;
      } else {
        return 0;
      }
    case 1:
      let score = 0;
      trueScores.forEach((val, index) => {
        if (val === scored[index]) {
          score += 1;
        }
      })
      return score;
    case 2:
    case 3:
      let sum = 0;
      scored.forEach((val, index) => {
        if (index === 0) {
          sum = val;
        }
        else {
          if (index % 2 === 1) {
            sum -= val;
          }
          else {
            sum *= val;
          }
        }
      });
      return sum;
    default:
      return null;
  }
}
