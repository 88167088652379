import { withStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import DateRange from "@material-ui/icons/DateRange";
import PhotoLibrary from "@material-ui/icons/PhotoLibrary";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import React from "react";
import { Button, Col, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { bindActionCreators } from "redux";
import CustomModal from "../../../utils/CustomModal";
import GenericHeader from "../../../utils/GenericHeader";
import * as NewActActions from "./NewActivityActions";
import * as Constants from "./NewActivityConstants";
import Axios from "axios";
import { BASE, FRONT } from "../../../API/APIendpoint";
const styles = theme => ({
  activityEventButtons: {
    backgroundColor: "orange",
    border: "none"
  },
  scoreDoc: {
    display: "flex",
    flexDirection: "row"
  },
  fileInput: {
    width: "0.1px",
    height: "0.1px",
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1
  },
  label: {
    width: "100px"
  },
  addButton: {
    marginRight: "20px",
    marginBottom: "10px",
    marginLeft: "auto",
    width: "100px"
  },
  toggleButtonsSelected: {
    backgroundColor: "orange",
    color: "white",
    border: "none"
  },
  footer: {
    backgroundColor: "orange",
    borderTopRightRadius: "15px",
    borderTopLeftRadius: "15px",
    height: "55px"
  },
  toolbar: {
    width: "100%",
    flexGrow: 1,
    borderBottom: "1.8px solid #8c8b8a"
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontWeight: "bold",
    marginLeft: "5%"
  }
});
class NewActivityDetails extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileDelete = this.handleFileDelete.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.modalClose = this.modalClose.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.state = {
      showModal: false,
      approvedTypes: [],
      remStatusTypes: [],
      fileCheck: false,
      activityEvent: [],
      ranking: false,
      isOther: false,
      _activityNotes: ""
    };
  }
  async componentWillMount() {
    await Axios({
      url: BASE + "api/ActivityEventLookups",
      method: "get",
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
    })
      .then(res => {
        this.setState({
          activityEvent: res.data
        })
      })
    await this.props.NewActActions.getAssociations();
    await this.props.NewActActions.getActivityTypes();
    await this.props.NewActActions.getOptics();
    await this.props.NewActActions.getFirearmtypes();
    await this.props.NewActActions.getActivityEvents();
    this.props.NewActActions.reset();
    if (this.props.newActivityState.fileInputOpen === true) {
      this.props.NewActActions.toggleFileInput();
    }
    var groupStatusStripped = [];
    let count = 0;
    const memStatus = this.props.userActivities.approvedMemActivities;
    const groupStatus = this.props.newActivityState.activityTypes;
    let glegnth = groupStatus.length;
    while (glegnth !== 0) {
      groupStatusStripped.push(groupStatus[count].activity);
      count++;
      glegnth--;
    }
    let difference = memStatus
      .filter(x => !groupStatusStripped.includes(x))
      .concat(groupStatusStripped.filter(x => !memStatus.includes(x)));
    this.setState({
      remStatusTypes: difference
    });
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    const { documents } = this.props.newActivityState;
    const exists = documents.some(doc => doc.name === file.name);
    if (!exists) {
      this.props.NewActActions.addFile(documents.concat([file]));
    }
    this.props.NewActActions.toggleFileInput();
  }
  modalClose() {
    this.setState({ showModal: false });
  }
  handleFileDelete(file) {
    const { documents } = this.props.newActivityState;
    const newDocuments = documents.filter(doc => doc.name !== file.name);
    this.props.NewActActions.removeFile(newDocuments);
  }
  handleBack() {
    this.props.NewActActions.goBack(0, "currentActivityTab");
    this.props.NewActActions.reset();
  }
  async handleFormSubmit(e) {
    e.preventDefault();
    if (this.props.newActivityState.documents.length !== 0) {
      this.setState({ showModal: true });
      const formData = new FormData();
      formData.append("ActivityType", this.props.newActivityState.activityType);
      formData.append("MemNo", this.props.memberNumber);
      formData.append("ActivityDate", this.props.newActivityState.date);
      formData.append("FirearmType", this.props.newActivityState.firearm);
      formData.append("Caliber", this.props.newActivityState.caliber);
      formData.append("Score", this.props.newActivityState.score);
      formData.append("Optics", this.props.newActivityState.optics);
      formData.append("ActivityEvent", this.props.newActivityState.activityEvent);
      formData.append("Association", this.props.newActivityState.association);
      formData.append("BaseURL", FRONT);
      formData.append("ActivityNotes", this.state._activityNotes !== "" ? this.state._activityNotes : "No Activity Notes Supplied")
      this.props.newActivityState.documents.forEach((file, i) => {
        formData.append(`attachment${i + 1}`, file);
      });
      const response = await this.props.NewActActions.uploadActivity(formData);
      if (response) {
        if (response.status === 200) {
          this.setState({ showModal: false });
          this.props.NewActActions.goBack(0, "currentActivityTab");
          this.props.NewActActions.reset();
        }
      }
    } else {
      this.setState({ fileCheck: true })
    }

  }
  selectedEvent = (e) => {
    this.setState({ ranking: e === "1" ? true : false })
  }
  render() {
    const { classes } = this.props;
    const { isNarfoEvent } = this.props.newActivityState;
    const toggleNarfoStyle = isNarfoEvent ? classes.toggleButtonsSelected : "";
    const toggleOtherStyle = !isNarfoEvent ? classes.toggleButtonsSelected : "";
    if (
      this.props.newActivityState.getOpticsStatus.loading ||
      this.props.newActivityState.getActivityStatus.loading ||
      this.props.newActivityState.getFirearmTypesStatus.loading ||
      this.props.newActivityState.getAssociationsStatus.loading ||
      this.props.newActivityState.getActivityEventsStatus.loading
    )
      return (
        <div className="d-flex justify-content-center mt-5">
          <FadeLoader color="orange" margin="auto" />
        </div>
      );
    return (
      <div className="container">
        <GenericHeader
          title="Add Activity"
          showBackButton
          backFunction={this.handleBack}
        />
        <Form className="p-3" onSubmit={e => this.handleFormSubmit(e)}>
          <Form.Label className="ml-3">Activity Event</Form.Label>
          <Form.Row className="ml-3 mr-3">
            <Col className="d-flex justify-content-center">
              <Button
                className={classes.label + " " + toggleNarfoStyle}
                variant="outline-dark"
                onClick={() =>
                  {this.props.NewActActions.toggleIsNarfoEvent(true);
                    this.setState({
                      isOther: false
                    })}
                }
              >
                {Constants.ActivityEvent.narfo}
              </Button>
            </Col>
            <Col className="d-flex justify-content-center">
              <Button
                className={classes.label + " " + toggleOtherStyle}
                variant="outline-dark"
                onClick={() =>
                  {this.props.NewActActions.toggleIsNarfoEvent(false);
                  this.setState({
                    isOther: true
                  })}
                }
              >
                {Constants.ActivityEvent.other}
              </Button>
            </Col>
          </Form.Row>
          <Form.Group as={Col} controlId="formMemNo">
            <Form.Label>Member Number</Form.Label>
            <Form.Control
              value={this.props.memberNumber}
              readOnly="readOnly"
              onChange={e =>
                this.props.NewActActions.setMemberNumber(e.target.value)
              }
            />
          </Form.Group>
          {this.props.newActivityState.isNarfoEvent && (
            <Form.Group as={Col} controlId="event">
              <Form.Label>Activity Event</Form.Label>
              <Form.Control
                as="select"
                required
                onChange={e => {
                  this.selectedEvent(e.target.value.split("/")[1])
                  this.props.NewActActions.setActivityEvent(e.target.value.split("/")[0])
                }
                }
              >
                <option value="" disabled selected>Select Event</option>
                {this.state.activityEvent &&
                  this.state.activityEvent.map(
                    (act, i) => (
                      <option key={i} value={act.activityEvent + "/" + act.ranking}>
                        {act.activityEvent}
                      </option>
                    )
                  )}
              </Form.Control>
            </Form.Group>
          )}
          {!this.props.newActivityState.isNarfoEvent && (
            <Form.Group as={Col} controlId="association">
              <Form.Label>Activity Discipline</Form.Label>
              <Form.Control
                as="select"
                required
                onChange={e =>
                  this.props.NewActActions.setAssociation(e.target.value)
                }
              >
                <option value="" disabled selected>Select Discipline</option>
                {this.props.newActivityState.associationsList &&
                  this.props.newActivityState.associationsList.map(
                    (assoc, i) => (
                      <option key={i} value={assoc.discipline}>
                        {assoc.discipline}
                      </option>
                    )
                  )}
              </Form.Control>
            </Form.Group>
          )}
          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>Activity Type</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={e =>
                this.props.NewActActions.setActivityType(e.target.value)
              }
            >
              <option value="" disabled selected>Select Activity Type</option>
              {this.props.newActivityState.activityTypes.map((activity, i) => (
                <option key={i} value={activity.activity}>
                  {activity.activity}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Label className="ml-3">Activity Date</Form.Label>
          <InputGroup as={Col}>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <DateRange />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              as="input"
              type="date"
              required
              onChange={e => this.props.NewActActions.setDate(e.target.value)}
            />
          </InputGroup>
          <Form.Group as={Col} controlId="firearm">
            <Form.Label>Firearm Type</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={e =>
                this.props.NewActActions.setFirearmType(e.target.value)
              }
            >
              <option value="" disabled selected >Select Firearm Type</option>
              {this.props.newActivityState.firearmTypes &&
                this.props.newActivityState.firearmTypes.map((firearm, i) => (
                  <option key={i} value={firearm.fireArmType}>
                    {firearm.fireArmType}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          {!this.props.newActivityState.isNarfoEvent && (
            <React.Fragment>
              <Form.Group as={Col} controlId="optics">
                <Form.Label>Optics</Form.Label>
                <Form.Control
                  as="select"
                  required
                  onChange={e =>
                    this.props.NewActActions.setOptics(e.target.value)
                  }
                >
                  <option value="" disabled selected>Select Optics</option>
                  {this.props.newActivityState.opticsList &&
                    this.props.newActivityState.opticsList.map((optic, i) => (
                      <option key={i} value={optic.optics}>
                        {optic.optics}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </React.Fragment>
          )}
          {this.props.newActivityState.firearm !== "Not Shooting Related" &&
            <React.Fragment>
              <Form.Group as={Col} controlId="formScore">
                <Form.Label>Caliber</Form.Label>
                <Form.Control
                  placeholder="Please input the caliber"
                  required
                  onChange={e =>
                    this.props.NewActActions.setCaliber(e.target.value)
                  }
                />
              </Form.Group>
              {}
              <Form.Group as={Col} controlId="formScore">
                {this.state.ranking === true || this.state.isOther ?
                  <div>
                    <Form.Label>Score</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Please input score"
                      value={this.props.newActivityState.score == 0 ? "" : this.props.newActivityState.score}
                      onChange={e => {
                        this.props.NewActActions.setScore(e.target.value)

                      }}
                    />
                  </div> : null}
              </Form.Group>
            </React.Fragment>
          }
          <React.Fragment>
            <Form.Group as={Col}>
              <Form.Label>Activity Notes</Form.Label>
              <textarea
                  class="form-control"
                  id="motivation"
                  placeholder="Add activity notes"
                  onInput={a => {
                    this.setState({
                      _activityNotes: a.target.value
                    });
                  }}
                  rows="6"
                />
            </Form.Group>
          </React.Fragment>
          <Form.Label className="ml-3">Document</Form.Label>
          {this.props.newActivityState.documents.map((doc, index) => (
            <div key={index} className={"ml-3 mr-3 " + classes.scoreDoc}>
              {doc.type === "application/pdf" ? (
                <PictureAsPdf />
              ) : (
                  <PhotoLibrary />
                )}
              <p style={{ flex: 1, marginLeft: 5 }}>{doc.name}</p>
              <Close onClick={() => this.handleFileDelete(doc)} />
            </div>
          ))}
          {!this.props.newActivityState.fileInputOpen && (
            <React.Fragment>
              <Form.Row className="ml-3 mr 3">
                {this.props.newActivityState.documents.length === 0 ?
                  <Button
                  className={classes.activityEventButtons}
                  onClick={this.props.NewActActions.toggleFileInput}
                  >
                    Add New Document
                  </Button> :
                  null
                }
                {this.state.fileCheck ?
                  <p className="text-danger ml-1">add document</p> : null}
              </Form.Row>
              <Form.Row>
                <Button
                  type="submit"
                  variant="primary"
                  className={
                    classes.addButton + " " + classes.toggleButtonsSelected
                  }
                >
                  Add
                </Button>
              </Form.Row>
            </React.Fragment>
          )}
          {this.props.newActivityState.fileInputOpen && (
            <Form.Row className={`fixed-bottom p-2 ${classes.footer}`}>
              <Col className="d-flex justify-content-center">
                <Form.Group controlId="scoreDocs-cam">
                  <Form.Label className={`btn btn-dark ${classes.label}`}>
                    Camera
                  </Form.Label>
                  <Form.Control
                    className={classes.fileInput}
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={event => this.handleFileChange(event)}
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-center">
                <Form.Group controlId="scoreDocs-doc">
                  <Form.Label className={`btn btn-dark ${classes.label}`}>
                    Document
                  </Form.Label>
                  <Form.Control
                    className={classes.fileInput}
                    required
                    type="file"
                    accept=".pdf, .png, .jpg"
                    onChange={event => this.handleFileChange(event)}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
          )}
        </Form>
        <CustomModal
          show={this.state.showModal}
          onHide={this.modalClose}
          error={this.props.newActivityState.uploadStatus.error}
          title="Saving Activity"
          message="Saving your activity. Please wait"
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    newActivityState: state.newActivityState,
    userActivities: state.activitiesReducer
  };
};
const mapActionsToProps = dispatch => ({
  NewActActions: bindActionCreators(NewActActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(NewActivityDetails));