import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import DaysRecordMapper from "./DaysRecordMapper"
class DedicatedStatusDaysEarned extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const dedicatedStatusDaysE = this.props.loyaltyRecordArray.filter(
            element => element.name === "Dedicated Hunter" || element.name === "Dedicated Sports Person"
        )
        return(
            <div className={this.props.isPartner ? "" : "container"}>
                <GenericHeader 
                    headerColor={this.props.headerColor}
                    title="Dedicated Status Days Earned"
                    showBackButton
                    showTotalLoyaltyDays
                    backFunction={() => this.props.onLoyaltyDaysTabChange("0")}
                    totalDays={this.props.memLoyaltyRecord.dedicatedStatusDays}
                />
                <DaysRecordMapper 
                    filteredDaysRecord = {dedicatedStatusDaysE}
                    dateDescription = "Approval Date:"
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
return {
        memLoyaltyRecord: state.LoyaltyDaysReducer.loyaltyRecord,
        loyaltyRecordArray: state.LoyaltyDaysReducer.loyaltyRecordArray
    };
};
const mapDispatchToProps = dispatch => {
return {
    onLoyaltyDaysTabChange: pageNum =>
    dispatch({ 
        type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
        currentLoyaltyDaysTab: pageNum 
        })
    };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(DedicatedStatusDaysEarned);
