import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import * as actionTypes from "../../store/actions/actionTypes";
import Progress from "./memberApplicationProgress";
import "./styles/memberDetailsStyles.css";
import { NextPageButton, PreviousPageButton } from "./navigatorButtons";
import { FRONT } from "../../API/APIendpoint";
class Declaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextPage: false,
      children: ["", "", "", "", "", "", "", ""],
      showError: false
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit = event => {
    event.preventDefault();
  };

  componentWillUnmount() {
    this.props.onMemberDetailsChange(
      this.state.children,
      "declarationChildren"
    );
  }

  componentWillMount() {
    this.setState({
      children: this.props.memDetails.declarationChildren
        ? this.props.memDetails.declarationChildren
        : this.state.children,
      isNextPage: this.props.memDetails.declaractionAcceptance
    });
  }
  componentDidMount() {
    this.checkNotEmpty();
  }

  checkNotEmpty = () => {
    let isNextPage = true;
    this.state.children.map(child => {
      if (
        child === "" ||
        child === null ||
        child === undefined ||
        child === "0" ||
        child === false
      ) {
        isNextPage = false;
      }
      return null;
    });
    this.setState({
      nextPage: isNextPage
    });
  };

  handleFireArms_ControlOptionChange = changeEvent => {
    let array = this.state.children;
    array[0] = changeEvent.target.value;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(
      changeEvent.target.value,
      "FireArms_Control"
    );
    this.checkNotEmpty();
  };

  handleFireArmStorageOptionChange = changeEvent => {
    let array = this.state.children;
    array[1] = changeEvent.target.value;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(
      changeEvent.target.value,
      "FireArmStorage"
    );
    this.checkNotEmpty();
  };

  handleCriminalRecordOptionChange = changeEvent => {
    let array = this.state.children;
    array[2] = changeEvent.target.value;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(
      changeEvent.target.value,
      "CriminalRecord"
    );
    this.checkNotEmpty();
  };

  handleChange = (name, index) => event => {
    let array = this.state.children;
    array[index] = event.target.checked;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(event.target.checked, name);
    this.checkNotEmpty();
  };

  radioButtons = (
    description,
    onChangeCallback,
    variable,
    name1,
    name0,
    label1,
    label0
  ) => {
    return (
      <div className="member-details-radio-input">
        <label> {description} </label>
        <span>
          <Radio
            checked={variable === "1"}
            onChange={onChangeCallback}
            value="1"
            name={name1}
            aria-label="Yes"
          />
          <text>{label1}</text>
        </span>
        <span>
          <Radio
            checked={variable === "0"}
            onChange={onChangeCallback}
            value="0"
            name={name0}
            aria-label="No"
          />
          <text>{label0}</text>
        </span>
      </div>
    );
  };

  render() {
    return (
      <div className="member-details-root" style={{ marginTop: "80px" }}>
        <Progress progress={3} />
        <div className="member-details-form declaration">
          <form onSubmit={this.handleSubmit}>
            {this.radioButtons(
              "Are you well acquainted with the current Firearms Control Act?",
              this.handleFireArms_ControlOptionChange,
              this.props.memDetails.FireArms_Control,
              "RadioFireArm1",
              "RadioFireArm0",
              "Yes",
              "No"
            )}
            {this.radioButtons(
              "Are you well acquainted with the use and Storage of your Firearm?",
              this.handleFireArmStorageOptionChange,
              this.props.memDetails.FireArmStorage,
              "RadioFireArmStorage1",
              "RadioFireArmStorage0",
              "Yes",
              "No"
            )}
            {this.radioButtons(
              "Have you ever been found Criminal of any firearm related offence where your finger were prints taken?",
              this.handleCriminalRecordOptionChange,
              this.props.memDetails.CriminalRecord,
              "RadioCriminal1",
              "RadioCriminal0",
              "No",
              "Yes"
            )}
            <label>
              I hereby declare that I fully understand and abide by the code of
              conduct{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${FRONT}uploads/2020/05/NARFO General Codes of Conduct (B)-Narfo.pdf`}
              >
                Codes of Conduct
              </a>
              <br />
              <Checkbox
                checked={this.props.memDetails.CoC}
                onChange={this.handleChange("CoC", 3)}
                value="CoC"
              />
              Yes
              <br />
              {this.props.memDetails.CoCError}
            </label>
            <br />
            <label>
              I hereby declare that I fully understand and abide by the
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${FRONT}uploads/2020/05/NARFO Disciplinary Code (C)-Narfo.pdf`}
              >
                {" "}
                Disciplinary code
              </a>
              <br />
              <Checkbox
                checked={this.props.memDetails.DC}
                onChange={this.handleChange("DC", 4)}
                value="DC"
              />
              Yes
              <br />
              {this.props.memDetails.DCError}
            </label>
            <br />
            <label>
              I hereby declare that all information in this document is true
              <br />
              <Checkbox
                checked={this.props.memDetails.DocTrue}
                onChange={this.handleChange("DocTrue", 5)}
                value="DocTrue"
              />
              Yes
              <br />
              {this.props.memDetails.DocTrueError}
            </label>
            <br />
            <p>
              <b>Electronic Communication Policy</b>
              NARFO South Africa embraces the digital age and encourage members to 
              have some form of online device to engage with the association for some 
              of the services we provide. 
              Although you can visit any partners and speak to a
              representative, our services is digitized. These include online
              assessments, license renewal system, activity record keeping,
              dedicated hunter and sport shooting application and status and
              general communication. Our only form of communication is
              electronic interaction through the web, emails and via our social
              media platform. We take your privacy very seriously and in order
              for us to provide you with the service and keep you informed about
              relevant opportunities and topics of interest, we request your
              consent to hold your personal data and communicate with you
              electronically.
            </p>
            <br />
            <label>
              I hereby accept the Electronic Communication Policy
              <br />
              <Checkbox
                checked={this.props.memDetails.ElectronicComms}
                onChange={this.handleChange("ElectronicComms", 6)}
                value="1"
              />
              Yes
              <br />
              {this.props.memDetails.ElectronicError}
            </label>
            <br />
            <p>
              I hereby declare that the information furnished above is true and 
              correct to the best of my knowledge and I undertake to inform 
              NARFO of any changes in herein by either updating my profile 
              electronically or by Email informing NARFO of changes. 
              In the event that the information is found to be false or misleading 
              or misrepresenting, I am aware that my membership may be cancelled.
            </p>
            <br />
            <label>
              By checking this option, I hereby Accept the above Declaration
              <br />
              <Checkbox
                checked={this.props.memDetails.declaractionAcceptance}
                onChange={this.handleChange("declaractionAcceptance", 7)}
                value="Delaraction_Acceptance"
              />
            </label>
            <br />
            {this.state.showError ? (
              <p class="text-danger">You do not meet minimum requirements</p>
            ) : null}
            <div className="nav-button-parent">
              <Link to="/MemberAddress">
                <PreviousPageButton />
              </Link>
              {this.state.nextPage ? (
                <Link to="/SalesDetails">
                  <NextPageButton />
                </Link>
              ) : (
                <NextPageButton
                  onClick={() => this.setState({ showError: true })}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Declaration);
