import React, { Component } from 'react'
import Axios from 'axios';
import { connect } from "react-redux";
import { BASE } from '../../../../API/APIendpoint';
import { toast } from 'react-toastify';
toast.configure();
class ActivitySummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
          _activityScore: this.props.activity.score,
          activityEvent: []
        }
    }
    componentDidMount() {
        Axios({
            url: `${BASE}api/Activities/${this.props.activity.activityId}`,
            method: "get",
            headers: { 
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
        }).then(res => {
            this.setState({_activityScore: res.data.score})
        })
        Axios({
            url: `${BASE}api/ActivityEventLookups`,
            method: "get",
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
          })
            .then(res => {
              this.setState({
                activityEvent: res.data
              })
            })
    }
    updateActivityScore = e => {
        e.preventDefault();
        Axios({
            url: `${BASE}api/Activities/updateActivityScore/${this.props.activity.activityId}/${this.state._activityScore}`,
            method: "post",
            headers: { 
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
        }).then(res => {
            if(res.data.status){
                toast.success(res.data.message);
            } else {
                toast.error(res.data.message);
            }
        })
    }
    render() {
        if(this.props.activity) 
            return (
                <div className="row p-3 border-bottom">
                    <p className="col-12 m-0">{this.props.activity.activityType}</p>
                    <p className="col-12 m-0">{this.props.activity.activityDate.split("T")[0]}</p>
                    <div className="col-12 m-0">
                        <div >
                            {this.props.selectedActivityDescription.split('~')[1] === '1' || this.props.activity.score !== '0' ?
                            <div className="row">
                                <label className="col-2">Score</label>
                                <input
                                    type="text"
                                    className="form-control col-2"
                                    value={this.state._activityScore}
                                    onInput={a => {
                                        this.setState({
                                            _activityScore: a.target.value
                                        });
                                    }}
                                />
                                <div className="col-4">
                                    <button
                                        variant="contained"
                                        className="btn btn-warning text-white"
                                        onClick={e => this.updateActivityScore(e)}
                                    >
                                        UPDATE
                                    </button>
                                </div>
                            </div> : null}
                        </div>
                    </div>
                    <p className="col-12 m-0">{this.props.activity.status}</p>

                </div>
            );
        else return null;
    }
}
const mapStateToProps = state => {
    return {
            selectedActivityDescription: state.ActivityAprrovalReducer.selectedActivityDescription
        };
    };
export default connect(
mapStateToProps,
null
)(ActivitySummary);
