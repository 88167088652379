import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  UPDATE_CURRENT_LMS_TAB,
  UPDATE_SCORE,
  UPDATE_ASSESMENT
} from '../../store/LearningManagement/actionTypes';
import { VictoryPie, VictoryLabel } from 'victory';

export class QuizResults extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-2 col-sm-3" />
          <div className="col-8 col-sm-6" align="center">
            {this.props.score < 60 ? (
              <div className="row pb-3">
                <div className="col-12 h7 font-weight-bold" align="center">
                  Sorry, you failed the quiz! You need at least 60% to succeed.
                </div>
                <div className="col-12 h7 font-weight-bold" align="center">
                  Here are your results
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 h7 font-weight-bold" align="center">
                  Congratulations you have passed!
                </div>
                <div className="col-12 h7 font-weight-bold" align="center">
                  Here are your results
                </div>
              </div>
            )}

            <div className="col-12 mw-50">
              <svg viewBox="0 0 500 500">
                <VictoryPie
                  standalone={false}
                  labelComponent={<span />}
                  width={500}
                  height={500}
                  data={[
                    { key: '', y: this.props.score },
                    { key: '', y: 100 - this.props.score }
                  ]}
                  innerRadius={125}
                  colorScale={[`${this.props.score >= 60?'green':'red'}`, 'black']}
                />
                <VictoryLabel
                  textAnchor="middle"
                  style={{ fontSize: 70, fill: `${this.props.score >= 60?'green':'red'}` }}
                  x={250}
                  y={250}
                  text={this.props.score + '%'}
                />
              </svg>
            </div>
          </div>
          <div className="col-2 col-sm-3" />
        </div>

        {this.props.score < 60 ? (
          <div className="row pt-3">
            <div className="col-3 col-sm-4" />
            <div className="col-6 col-sm-4">
              <div className="row">
                <div className="col-12 m-2" align="center">
                  <button
                    className="btn bg-dark text-white form-control "
                    onMouseDown={() => this.props.onCurrentLMSTabChange(2)}
                  >
                    Restart Quiz
                  </button>
                </div>
                <div className="col-12 m-2" align="center">
                  <button
                    className="btn bg-dark text-white form-control"
                    onMouseDown={() => {
                      this.props.onCurrentLMSTabChange(4);
                    }}
                  >
                    View Results
                  </button>
                </div>
                <div className="col-12 m-2" align="center">
                  <button
                    className="btn bg-dark text-white form-control"
                    onMouseDown={() => {
                      this.props.onCurrentLMSTabChange(0);
                    }}
                  >
                    Back to Quizzes
                  </button>
                </div>
              </div>
            </div>
            <div className="col-3 col-sm-4" />
          </div>
        ) : (
          <div className="row pt-3">
            <div className="col-4" />
            <div className="col-4">
              <div className="row">
                <div className="col-12 m-2" align="center">
                  <button
                    className="btn bg-dark text-white form-control"
                    onMouseDown={() => {
                      this.props.onCurrentLMSTabChange(4);
                    }}
                  >
                    View Results
                  </button>
                </div>
                <div className="col-12 m-2" align="center">
                  <button
                    className="btn bg-dark text-white form-control"
                    onMouseDown={() => {
                      this.props.onCurrentLMSTabChange(0);
                    }}
                  >
                    Back to Quizzes
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  score: state.nLearningManagementReducer.score,
  oneAssesment: state.nLearningManagementReducer.assesment,
  memberName: state.ProfileReducer.memberData.firstname,
  oneLesson: state.nLearningManagementReducer.lesson
});

const mapDispatchToProps = dispatch => ({
  onScoreChange: newScore =>
    dispatch({ type: UPDATE_SCORE, score: newScore }),
  onAssesmentChange: newAssesment =>
    dispatch({
      type: UPDATE_ASSESMENT,
      assesment: newAssesment
    }),
  onCurrentLMSTabChange: newCurrentLmsTab =>
    dispatch({
      type: UPDATE_CURRENT_LMS_TAB,
      currLmsTab: newCurrentLmsTab
    })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizResults);
