import React, { Component } from "react";
import axios from "axios";
import "./styles/dstyle.css";
import star from "./images/star.jpg";
import gun from "./images/gun.png";
import activity from "./images/activity.png";
import newMember from "./images/newMember.png";
import memRenewal from "./images/renewal.png";
import FadeLoader from "react-spinners/FadeLoader";
import { connect } from "react-redux";
import { DASHBOARD_ACTIONS } from "../../store/actions/actionTypes";
import {
  BASE
} from "../../API/APIendpoint";
import DashBoardRowItems from "./DashBoardRowItems";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activitiesCount: 0,
      endorsementsCount:0,
      dsCount:0
    };
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }
  componentWillMount() {
    // this.setState({ loading: true});
      //Get Item count for dashboard 
      this.setState({ loading: true});

      axios.get(BASE + "api/Activities/GetDashboardItemCount",{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(response => {
          let pendingEndorsements = response.data.pendingEndorsements;
          let pendingActivities = response.data.pendingActivities;
          let pendingDS = response.data.pendingDS;
          // let members = response.data.members;
          this.setState({ loading: false, activitiesCount:pendingActivities.length, endorsementsCount: pendingEndorsements.length, dsCount: pendingDS.length});
          
          this.props.onStatusAChange(pendingActivities);
          this.props.onStatusEndorsementChange(pendingEndorsements);
          this.props.onStatusDSChange(pendingDS);
          // this.props.onMemberNumberChange(members);//Members are required in order to process activities,endorsements and ds statuses
          
        })
        .catch(err => {
           alert("Unable to log status please try again later");
        });
        // this.setState({ loading: false});
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">
              Loading the dashboard. Please wait ...
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="container ">
        <div className="row pt-4">
          <div className="col-12 h2 pl-0"> Dashboard </div>
          <div className="col-12 h5 pl-0"> Pending approvals </div>
        </div>
        {/* <DashBoardRowItems 
          dItemLinkSection="/admin/PendingNewMembers"
          dItemImage={newMember}
          dItemName="New Members"
          dItemCount={this.props.pendingNM}
        />
        <DashBoardRowItems 
          dItemLinkSection="/admin/MembershipRenewal"
          dItemImage={memRenewal}
          dItemName="Membership Renewal"
          dItemCount={this.props.pendingNR}
        /> */}
        <DashBoardRowItems 
          dItemLinkSection="/admin/PendingDedicatedHunter"
          dItemImage={star}
          dItemName="Dedicated Status Applications"
          dItemCount={this.props.dsCount}
        />
     
      <DashBoardRowItems 
        dItemLinkSection="/admin/PendingEndorsementApplication"
        dItemImage={gun}
        dItemName="Endorsement Application"
        dItemCount={this.props.pendingAE}
      /> 
        <DashBoardRowItems 
          dItemLinkSection="/admin/PendingActivityApplication"
          dItemImage={activity}
          dItemName="Activities"
          dItemCount={this.props.pendingA}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
	token: state.AuthReducer.token,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    pendingA: state.dashBoardReducer.activities.pendingCount,
    pendingNR: state.dashBoardReducer.newRenewals.pendingNRCount,
    pendingNM: state.dashBoardReducer.newMembers.pendingNMCount,
    pendingDS: state.dashBoardReducer.pendingDS,
    dsCount: state.dashBoardReducer.pendingDS.length,
    pendingAE: state.dashBoardReducer.endorsements.pendingEndorsementCount,
    pendings: state.dashBoardReducer.pendings,
    membersDSS: state.dashBoardReducer.membersDSS,
    membersDH: state.dashBoardReducer.membersDH,
    membersPending: state.dashBoardReducer.membersPending,
    membersRenewal: state.dashBoardReducer.membersRenewal,
    pendingNewMembers: state.dashBoardReducer.newMembers,
    pendingRenewals: state.dashBoardReducer.newRenewals,
    pendingEndorsements: state.dashBoardReducer.endorsements,
    endorsements: state.dashBoardReducer.endorsements,
    dedicatedStatus: state.dashBoardReducer.dedicatedStatus
  };
};
const mapDispatchToProps = dispatch => {
  return {
    // onDSSMembersChange: newDSSMembers =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_DSS_MEMBERS,
    //     membersDSS: newDSSMembers
    //   }),
    // onDHMembersChange: newDHMembers =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_DH_MEMBERS,
    //     membersDH: newDHMembers
    //   }),
    onStatusAChange: _activities =>
      dispatch({
        type: DASHBOARD_ACTIONS.GET_ACTIVITIES,
        activities: {
          pendingCount: _activities.length,
          activities: _activities
        }
      }),

    // onStatusNewMemberChange: _newMembers =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.GET_ALL_PENDING_MEMBERS_URL,
    //     newMembers: {
    //       pendingNMCount: _newMembers.filter(member => member.status === 2)
    //         .length,
    //       newMembers: _newMembers
    //     }
    //   }),
    // onStatusRenewalChange: _newRenewalMembers =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.GET_ALL_RENEWAL_MEMEBRS_URL,
    //     newRenewals: {
    //       pendingNRCount: _newRenewalMembers.filter(
    //         member => member.status === 4
    //       ).length,
    //       newRenewals: _newRenewalMembers
    //     }
    //   }),
    onStatusEndorsementChange: endorsements =>
      dispatch({
        type: DASHBOARD_ACTIONS.GET_ALL_ENDORSEMENTS_URL,
        endorsements: {
          pendingEndorsementCount: endorsements.length,
          pendingEndorsements: endorsements,
          allEndorsements: endorsements
        }
      }),

      //This updates any chnages made in the Pending DS application page
      //Remove and test 
    onStatusDSChange: newPending =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_DS_PENDINGS,
        pendingDS: newPending,
        dsCount: newPending.length
      }),
    onStatusAEChange: newPending =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_AE_PENDINGS,
        pendingAE: newPending
      }),
    // onNewMemberStatusChange: pendings =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_PENDINGS,
    //     pendings: pendings
    //   }),
    // onMemberNumberChange: members =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_TOTAL_MEMBERS,
    //     totalMembers: {
    //       totalMembers: members.length,
    //       members
    //     }
    //   }),
    // onNewMemberChange: membersPending =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS,
    //     membersPending: membersPending
    //   }),
    // onRenewalMemberChange: membersRenewal =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_RENEWAL_MEMBERS,
    //     membersRenewal: membersRenewal
    //   }),
    // onNewMemberCount: membersPending =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS,
    //     totalNewPandingMembers: {
    //       totalNewPandingMembers: membersPending.length,
    //       membersPending
    //     }
    //   }),
    // onRenewalMemberCount: membersRenewal =>
    //   dispatch({
    //     type: DASHBOARD_ACTIONS.UPDATE_TOTAL_MEMBERS,
    //     totalRenewalMembers: {
    //       totalRenewalMembers: membersRenewal.length,
    //       membersRenewal
    //     }
    //   })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
