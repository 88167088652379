import React, { Component } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import {
  COURSE_MODULE_NAME_UPDATE
} from "../LMSAPIEndPoints";

export default class EditMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editStatusMessage: 'Are you sure?',
      onEdit: false,
      newName: ""
    }
  }

  editModule = () => {
    this.setState({
      editStatusMessage: "Editing...",
      onEdit: true
    })
    const data = {
      CourseModuleId: this.props.id,
      Index: 0,
      Name: this.state.newName,
      type: this.props.type
    }
    fetch(COURSE_MODULE_NAME_UPDATE,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          editStatusMessage: res.message
        })
        setTimeout(() => {
          this.props.refresh();
          this.props.onClick(0);
        }, 1500);
      })
      .catch(error => {
        this.setState({
          deleteStatusMessage: error.message ? error.message : 'Failed to rename! Try again.'
        })
        setTimeout(() => {
          this.props.onClick(0);
        }, 1500);
      })
  }

  render() {
    return (
      <div className="popup-menu-container">
        <Edit className="nocursor" />
        {this.state.onEdit ?
          <label>{this.state.editStatusMessage}</label> :
          <input type="text" defaultValue={this.props.name}
            onChange={(e) => {
              this.setState({
                newName: e.target.value
              })
            }} />
        }
        <span className={this.state.onEdit ? 'hide' : ''} >
          <Check onClick={() => {
            this.editModule()
          }} />
          <Clear onClick={() => {
            this.props.onClick(0);
          }} />
        </span>
      </div>
    )
  }
}
