import * as actionTypes from "../actions/actionTypes";

const initialState = {
    localHostKey: "6LeKvckZAAAAACCxLi8-Wg3AhTzYMPTeHru4I4up",
    narfoQAKey: "6Lf60s0ZAAAAAE_KPf2TBKeenBTp91Q2q4WQ3AKu",
    narfoProductionKey: "6LfhXZQlAAAAAEUWghq6reTKn4MEBVlXA4ewp9kx"
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
export default reducer;