import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  editableCalendarConfig,
  editableTextInputConfig,
  dsApplicationsResponses
} from '../../shared/constants';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import { 
  Typography,
  Grid,
  Divider, 
  Checkbox
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {
  EditableGridLabel,
  IconLabelButton,
  Loader
} from '../index';
import MappedInput from '../MappedInput/MappedInput';
import { checkValidity, updateObject, initSelect, initCheckbox } from '../../shared/utility';
import { Attachment, SystemUpdateAlt } from '@material-ui/icons';
import { saProvinces } from '../../shared/constants';
import API_URL from '../../services/apiUrl';
import SITE_URL from '../../services/siteURL';
import DedicatedStatusService from '../../services/dedicatedstatus.service';
import { toast } from 'react-toastify';

toast.configure();

const columns = [
  { field: 'applicationDate', headerName: 'Date' },
  { field: 'dstype', headerName: localStorage.getItem('isMobile') === 'true' ? 'Type' : 'Application Type' },
  { field: 'status', headerName: localStorage.getItem('isMobile') === 'true' ? 'Status' : 'Application Status' }
];
class DedicatedStatusApplications extends Component {
  
  constructor(props) {
    super(props);

	  this.dedicatedStatusService = DedicatedStatusService(this.props.token)
    this.state = {
      controls: this.initControls(),
      requireCourse:true,
      isReApplication:false,
      selectedRadio1: dsApplicationsResponses.NO,
      selectedRadio2: dsApplicationsResponses.NO,
      selectedRadio3: dsApplicationsResponses.NO,
      selectedRadio4: dsApplicationsResponses.NO,
      selectedRadio5: dsApplicationsResponses.NO,
      selectedRadio6: dsApplicationsResponses.NO,
      selectedRadio7: dsApplicationsResponses.NO,
      selectedRadio8: dsApplicationsResponses.NO,
      dsApplication: {
        Ph: '',
        Ods: '',
        PhRegNo: '',
        PhAssociation: ' ',
        PhProvince: ' ',
        PhExpiryDate: new Date(),
        PhAttachment: ' ',
        dsType: ' ',
        OdsAssociationName: ' ',
        OdsFarNo: ' ',
        OdsDsNo: ' ',
        OdsExpiryDate: new Date(),
        Motivation: ' ',
        NonParticipationReason: ' ',
        applicationStatus: ' ',
        OdsAttachment: {},
        declaration: ' '
      },
      radioGroupConfig: {
        elementType: 'radioGroup',
        elementConfig: {
          direction: 'row',
          radios: [
            { value: dsApplicationsResponses.NO, label: 'No' },
            { value: dsApplicationsResponses.YES, label: 'Yes' }
          ]
        }
      },
      loading: false,
      formHasErrors: true,
      attachmentOds: {},
      attachmentPh: {},
      showFilePickerOds: false,
      showFilePickerPh: false,
      fileCheckOds: false,
      fileCheckPh: false,
      canSubmit: false,
	  _assessmentAverage: 0,
	  _practicalAtivities: 0,
    hasPassedAssessmentAverage : false,
    showExtraInfo:true,
    failureReasons:[]

    };
    this.fileOdsRef = React.createRef();
    this.filePhRef = React.createRef();
  }

  componentDidMount() {
	  this.applyCheck(this.props.dedicatedType, this.props.memNo)

    let _application = this.props.rejectedApplications.filter(ds => ds.dstype === this.props.dedicatedType)[0];
    //TODO: Check if is hunter or sports shooter

    if(_application){
      //Check if is dedicated status transfer 
      let isDedicatedTranfer = _application.otherDedicatedStatus.length > 0 ? true : false;
  
      //TODO:
      let attachmentPh = {};
      //Handle If is a tranfer from another association
      if(isDedicatedTranfer){
          attachmentPh = {
          result: isDedicatedTranfer.link, 
          name: isDedicatedTranfer.link
        };
      
        let _otherAssociation = _application.otherDedicatedStatus[0];
  
        this.setState({ 
          //Other status application
          isReApplication: true,
          selectedRadio1: dsApplicationsResponses.YES,
          dsApplication: {
            OdsAssociationName: _otherAssociation?.associationName ? _otherAssociation.associationName :'',
            OdsFarNo: _otherAssociation?.farNo ? _otherAssociation.farNo :'',
            OdsDsNo: _otherAssociation?.dsNo ? _otherAssociation.dsNo :'',
            OdsExpiryDate: _otherAssociation?.expiryDate ? _otherAssociation.expiryDate : new Date(),
            Ods: dsApplicationsResponses.YES,
            Motivation: _application?.motivation ? _application.motivation :'',
          },
        })
      }
  
    }
  }

  applyCheck = (dsType, memNo) => {
	this.dedicatedStatusService.getDedicatedSatusApllicationRequirements(dsType, memNo)
	  .then(res =>{ 
      this.setState({
          requireCourse: res.data.hasPassedAllModules && res.data.hasPassedAssessmentAverage && res.data.hasCompletedPracticals? false : true,
          hasPassedAssessmentAverage : res.data.hasPassedAssessmentAverage,
          failureReasons: res.data.failureReasons,
          _assessmentAverage: res.data.assessmentAverage,
          _practicalAtivities: res.data.practicalAtivities})
    })
	  .catch(() => this.setState({
		_assessmentAverage: 0,
		_practicalAtivities: 0
	  }))
  }

  initControls = () => ({
    Ph: {...editableTextInputConfig},
    Ods: {...editableTextInputConfig},
    PhRegNo: {...editableTextInputConfig},
    PhAssociation: {...editableTextInputConfig},
    PhProvince: initSelect(saProvinces),
    PhExpiryDate: {...editableCalendarConfig},
    PhAttachment: {...editableTextInputConfig},
    dsType: {...editableTextInputConfig},
    OdsAssociationName: {...editableTextInputConfig},
    OdsFarNo: {...editableTextInputConfig},
    OdsDsNo: {...editableTextInputConfig},
    OdsExpiryDate: {...editableCalendarConfig},
    Motivation: {...editableTextInputConfig},
    NonParticipationReason: {...editableTextInputConfig},
    applicationStatus: {...editableTextInputConfig},
    declaration: initCheckbox('I understand and wish to proceed')
  })

  handleRadioChange1 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio1: value,
      showExtraInfo: value == 'Yes' ? false : true,
      dsApplication: {
        ...this.state.dsApplication,
        Ods: value
      },
      controls: this.initControls()
    });
  }

  handleRadioChange2 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio2: value,
      dsApplication: {
        ...this.state.dsApplication,
        Ph: value
      },
      controls: this.initControls()
    });
  }

  handleRadioChange3 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio3: value,
      controls: this.initControls()
    });
  }

  handleRadioChange4 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio4: value,
      controls: this.initControls()
    });
  }

  handleRadioChange5 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio5: value,
      controls: this.initControls()
    });
  }

  handleRadioChange6 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio6: value,
      controls: this.initControls()
    });
  }

  handleRadioChange7 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio7: value,
      controls: this.initControls()
    });
  }

  handleRadioChange8 = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio8: value,
      controls: this.initControls()
    });
  }

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }
    value = typeof value === 'number' ? value.toString() : value;
    const validity = checkValidity(value === ' ' ? '' : value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });

    this.setState({
      controls,
      dsApplication: {
        ...this.state.dsApplication,
        [controlName]: value,
      },
      formHasErrors
    });
  };

  label = (title, name, fullWidth,validate) => (
    <Grid item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4} container>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.dsApplication[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)} 
        hasCharacters={validate}
      />
    </Grid>
  )

  handleFileUploadOds = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let attachmentOds = this.state.attachmentOds;
    attachmentOds = {
      result: file, 
      name: file.name
    };
    this.setState({
      attachmentOds, 
      showFilePickerOds: false,
      fileCheckPh: false
    });
  };

  handleFileUploadPh = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    let attachmentPh = this.state.attachmentPh;
    attachmentPh = {
      result: file, 
      name: file.name
    };
    this.setState({
      attachmentPh, 
      showFilePickerPh: false,
      fileCheckOds: false
    });
  };

  handleChangeDeclAccept = () => {
    // if(this.state.dsApplication.Motivation?.length > 5)
        
    this.setState({ canSubmit: !this.state.canSubmit});
  };

  hadleGoToCourses = () => {
    this.props.history.push("/member/courses");
  }

  submitHandler = () => {

    if(this.state.dsApplication.Motivation?.length < 5)
    {
      toast.error("Motivation is required");
      return;
    }

    let _noFileAttached = false;
    this.setState({ loading: true });

    const dsApplications = {...this.state.dsApplication};
    let data = new FormData();
        
    let formHasErrors = false;
    let controls = {...this.state.controls};

    if(this.state.selectedRadio1 === 'Yes') {
      if(Object.keys(this.state.attachmentOds).length === 0) {
        this.setState({ fileCheckOds: true });
        _noFileAttached = true;
      } else {
        Object.keys(dsApplications).forEach(key => {
                                
          let value = dsApplications[key];

          if(key === 'OdsAssociationName' ||
             key === 'OdsFarNo' ||
             key === 'OdsDsNo' ||
             key === 'OdsExpiryDate') {
            value = typeof value === 'number' ? value.toString() : value;
            const validity = checkValidity( 
              value === ' ' ? '' : value,
              controls[key].config.validate
            );
            const updatedControls = updateObject(controls[key], {
              config: updateObject(controls[key].config, {
                valid: validity.isValid,
                errors: validity.errors,
                touched: true,
              })
            });
            controls = {
              ...controls,
              [key]: updatedControls,
            };
            if (value === ' ') {
              formHasErrors = true;
            }

            this.setState({
              controls,
              dsApplication: {
                ...this.state.dsApplication,
                [key]: value,
              },
              formHasErrors
            });
          }
        });
      }
    } else if(this.state.selectedRadio2 === 'Yes') {
      if(Object.keys(this.state.attachmentPh).length === 0) {
        this.setState({ fileCheckPh: true });
        _noFileAttached = true;
      } else {
        Object.keys(dsApplications).forEach(key => {
                                
          let value = dsApplications[key];

          if(key === 'PhRegNo' ||
             key === 'PhAssociation' ||
             key === 'PhProvince') {
            value = typeof value === 'number' ? value.toString() : value;
            const validity = checkValidity( 
              value === ' ' ? '' : value,
              controls[key].config.validate
            );
            const updatedControls = updateObject(controls[key], {
              config: updateObject(controls[key].config, {
                valid: validity.isValid,
                errors: validity.errors,
                touched: true,
              })
            });
            controls = {
              ...controls,
              [key]: updatedControls,
            };
            if (value.length === ' ') {
              formHasErrors = true;
            }

            this.setState({
              controls,
              dsApplication: {
                ...this.state.dsApplication,
                [key]: value,
              },
              formHasErrors
            });
          }
        });
      }
    }
    
    if(_noFileAttached) {
      this.setState({ loading: false });
      return;
    } 

    if(!formHasErrors) {
      dsApplications.OdsAttachment = this.state.attachmentOds.result;
      dsApplications.dsType = this.props.dedicatedType;
      
      if (this.props.dedicatedType === 'Dedicated Hunter') {
        dsApplications.PhAttachment = this.state.attachmentPh.result;
        data.append('PhRegNo', dsApplications.PhRegNo);
        data.append('PhAssociation', dsApplications.PhAssociation);
        data.append('PhProvince', dsApplications.PhProvince);
        data.append('PhExpiryDate', new Date(dsApplications.PhExpiryDate).toDateString());
        data.append('PhAttachment', dsApplications.PhAttachment);
      }
      data.append('MemNo', this.props.memNo);
      data.append('DsType', dsApplications.dsType);
      data.append('Motivation', dsApplications.Motivation);
      data.append('NonParticipationReason', dsApplications.NonParticipationReason);
      data.append('OdsAssociationName', dsApplications.OdsAssociationName);
      data.append('OdsFarNo', dsApplications.OdsFarNo);
      data.append('OdsDsNo', dsApplications.OdsDsNo);
      data.append('OdsExpiryDate', new Date(dsApplications.OdsExpiryDate).toDateString());
      data.append('Ods', dsApplications.Ods);
      data.append('Ph', dsApplications.Ph);
      data.append('OdsAttachment', dsApplications.OdsAttachment);
      data.append('BaseURL', SITE_URL);

      this.props.addNewApplicationCallBack(data)
      .then(() =>{
        this.setState({ loading: false });
        toast.success("Application submitted");
        this.props.loadDSCallBack();
        this.props.goBack();
      })
      .catch((error) => {
        console.log("DS Application - ", data);
        console.log("DS ERROR", error);
        toast.error("Could not submit - " + error.statusText);
      });
    }
        
    this.setState({ loading: false });
    
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        <SubtitleBar 
          variant={isMobile ? 'h4' : 'subtitle1'}
          title={this.props.dedicatedType}
          back
          onBack={this.props.goBack}
        />
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mb-6">
          <Typography variant="h4" color="primary">
            Step 1: Details
          </Typography>
          <Divider/>
          <Grid item xs={12}>
            <div className="pl-8 pt-2">
              <Typography>
                Do you currently hold a {this.props.dedicatedType} status with any other accredited association?
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio1}
                  changed={this.handleRadioChange1}
                />
              </div>
              {this.state.selectedRadio1 === 'Yes' ?
                <div className="pb-4">
                  <Grid  item xs={12} container spacing={4}>
                    {this.label('Association Name', 'OdsAssociationName')}
                    {this.label('Association Far Number', 'OdsFarNo')}
                    {this.label('Dedicated Status Number', 'OdsDsNo')}
                    {this.label('Expiry Of Membership', 'OdsExpiryDate')}
                  </Grid>
                  <Grid  item xs={12} container>
                    <input 
                      type="file"
                      className="d-none"
                      ref={this.fileOdsRef}
                      onChange={this.handleFileUploadOds}
                    />
                    <Grid item xs={12}>
                      {!this.state.showFilePickerOds && <IconLabelButton
                        full
                        actionText="Attach Document"
                        icon={<SystemUpdateAlt />}
                        callBack={() => this.setState({showFilePickerOds: true})}
                      />}
                      {this.state.showFilePickerOds && 
                        <div className="d-flex justify-center">
                          <IconLabelButton
                            full
                            actionText="Document"
                            icon={<Attachment />}
                            callBack={() => this.fileOdsRef.current.click()}
                          />
                        </div>}
                      {Object.keys(this.state.attachmentOds).length > 0 ? 
                        <Grid item xs={12}>
                          <Typography className="word-wrap">{this.state.attachmentOds.name || 'file'}</Typography>
                        </Grid> : this.state.fileCheckOds ?  
                          <Typography style={{color: 'red'}}>Please add document</Typography> : 
                          null}
                    </Grid>
                  </Grid>
                </div>
                : null}
              {this.props.dedicatedType === 'Dedicated Hunter' ?
                <React.Fragment>
                  <Typography>
                    Are you or have you been registered as a Proffesional Hunter?
                  </Typography>
                  <div className="pl-3 pt-1 pb-1">
                    <MappedInput 
                      {...this.state.radioGroupConfig}
                      value={this.state.selectedRadio2}
                      changed={this.handleRadioChange2}
                    />
                  </div>
                  {this.state.selectedRadio2 === 'Yes' ?
                    <div className="pb-4">
                      <Grid  item xs={12} container spacing={4}>
                        {this.label('Association Name', 'PhAssociation')}
                        {this.label('Registration Number', 'PhRegNo')}
                        {this.label('Province in which you may operate', 'PhProvince')}
                        {this.label('Expiry date of your permmit', 'PhExpiryDate')}
                      </Grid>
                      <Grid  item xs={12} container>
                        <input 
                          type="file"
                          className="d-none"
                          ref={this.filePhRef}
                          onChange={this.handleFileUploadPh}
                        />
                        <Grid item xs={12}>
                          {!this.state.showFilePickerPh && <IconLabelButton
                            full
                            actionText="Attach Document"
                            icon={<SystemUpdateAlt />}
                            callBack={() => this.setState({showFilePickerPh: true})}
                          />}
                          {this.state.showFilePickerPh && 
                        <div className="d-flex justify-center">
                          <IconLabelButton
                            full
                            actionText="Document"
                            icon={<Attachment />}
                            callBack={() => this.filePhRef.current.click()}
                          />
                        </div>}
                          
                          {Object.keys(this.state.attachmentPh).length > 0 ? 
                            <Grid item xs={12}>
                              <Typography className="word-wrap">{this.state.attachmentPh.name || 'file'}</Typography>
                            </Grid> : this.state.fileCheckPh ?  
                              <Typography style={{color: 'red'}}>Please add document</Typography> : 
                              null}
                        </Grid>
                      </Grid>
                    </div>
                    : null}
                </React.Fragment>
                : null}
              <div className="pl-3 pt-1 pb-1" hidden={!this.state.requireCourse || this.state.isReApplication || !this.state.showExtraInfo}>
             
              <Alert severity="warning" style={{marginLeft:'-2em'}}>
                
                <AlertTitle><strong>If you are transferring an existing status, please select yes above and continue. <br/> <br/> If you are not transferring an existing status, you need to meet the requirements below:</strong></AlertTitle>
                <List>
                  {this.state.failureReasons?.map((reason,i) => <ListItem style={{fontSize:'13.5px', marginBottom:"-.5em"}} key={i}>- {reason}</ListItem>)}
                </List>

                <IconLabelButton 
                  full 
                  actionText="Go to Courses" 
                  color="primary"
                  callBack={this.hadleGoToCourses}/>
              </Alert>
              
              </div>
            </div>
          </Grid>
        </div>
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mb-6" hidden={this.state.requireCourse && this.state.showExtraInfo && !this.state.isReApplication}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
                Step 2: Motivation
            </Typography>
            <Divider/>
            <div className="pl-8 pt-2">
              <Typography>
                Have you attended any { this.props.dedicatedType == 'Dedicated Sports Person'? "sport shooting" : "hunting"} related activities, either on your own or any activity of NARFO or any other association?
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio4}
                  changed={this.handleRadioChange4}
                />
              </div>
              {this.label(`*Motivate why you are applying for a ${this.props.dedicatedType} status?`, 'Motivation', true)}

            </div>
          </Grid>
        </div>
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2" hidden={this.state.requireCourse && this.state.showExtraInfo && !this.state.isReApplication}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary">
                Step 3: Declarations
            </Typography>
            <Divider/>
            <div className="pl-8 pt-2">
              <Typography>
                Are you well acquainted with the current Firearms Control Act?
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio5}
                  changed={this.handleRadioChange5}
                />
              </div>
            </div>
            <div className="pl-8 pt-2">
              <Typography>
                Are you well acquainted with the use and storage of your Firearm?
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio6}
                  changed={this.handleRadioChange6}
                />
              </div>
            </div>
            <div className="pl-8 pt-2">
              <Typography>
                I hereby declare that all information in this document is true
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio7}
                  changed={this.handleRadioChange7}
                />
              </div>
            </div>
            <div className="pl-8 pt-2">
              <Typography>
                I hereby declare that I fully understand and obide by the Dedicated Status Declaration?
              </Typography>
              <div className="pl-3 pt-1 pb-1">
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio8}
                  changed={this.handleRadioChange8}
                />
              </div>
            </div>
            <div className="pl-8 pt-2">
              <Typography>
                Please read the{' '}
                <a
                  target="_blank"
                  style={{fontWeight: 'bold'}}
                  href={`${API_URL.split('api')}/uploads/2020/05/NARFO Declaration of Dedicated Status (K)-Dedicated Status.pdf`} rel="noreferrer"
                >
                Dedicated Status Declaration
                </a>{' '}carefully and make sure you undrstand the content.
              </Typography>
            </div>
            <div className="pl-8 pt-2">
              <Checkbox
                color="primary"
				checked={this.state.canSubmit}
                onClick={() => this.handleChangeDeclAccept()} 
              />
            </div>
            
          </Grid>
        </div>
        <Grid container direction='row' hidden={this.state.requireCourse && this.state.showExtraInfo && !this.state.isReApplication}>
          <div className="mt-4">
            <IconLabelButton 
              full 
              actionText="Submit" 
              color="primary"
              disabled={!this.state.canSubmit} 
              callBack={this.submitHandler}
            />
          </div>
          <div className="mt-4">
            <IconLabelButton 
              full 
              disabled={false}
              actionText="Clear" 
              color="primary" 
              callBack={this.props.goBack}
            />
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
	token: state.AuthReducer.token,
	memNo: state.AuthReducer.memNo
});

export default connect(mapStateToProps ,null)(DedicatedStatusApplications);