import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import DaysRecordMapper from "./DaysRecordMapper"
class CourseDaysEarned extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const _courseDays = this.props.loyaltyRecordArray.filter(
            element => element.name === "Cartridges" ||
                       element.name === "Firearms and Parts" ||
                       element.name === "Range Rules" ||
                       element.name === "Firearm Safety" ||
                       element.name === "Shot Placement" ||
                       element.name === "Animal Identification" ||
                       element.name === "Ethics Quiz" ||
                       element.name === "Ordinances" ||
                       element.name === "Ordinance Quiz" ||
                       element.name === "Shot Placement" ||
                       element.name === "Animal Identification" ||
                       element.name === "Catridges Quiz" ||
                       element.name === "Firearms and Parts Quiz" ||
                       element.name === "Range Rules" ||
                       element.name === "Firearms Safety and the FCA Quiz"
        )
        return(
            <div className={this.props.isPartner ? "" : "container"}>
                <GenericHeader 
                headerColor={this.props.headerColor}
                    title="Course Days Earned"
                    showBackButton
                    showTotalLoyaltyDays
                    backFunction={() => this.props.onLoyaltyDaysTabChange("0")}
                    totalDays={this.props.memLoyaltyRecord.courseDays}
                />
                <DaysRecordMapper 
                    filteredDaysRecord = {_courseDays}
                    dateDescription = "Date Completed:"
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
return {
        memLoyaltyRecord: state.LoyaltyDaysReducer.loyaltyRecord,
        loyaltyRecordArray: state.LoyaltyDaysReducer.loyaltyRecordArray
    };
};
const mapDispatchToProps = dispatch => {
return {
    onLoyaltyDaysTabChange: pageNum =>
    dispatch({ 
        type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
        currentLoyaltyDaysTab: pageNum 
        })
    };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(CourseDaysEarned);