//TODO: List to be updated everytime 
export const  ProductionKeywords = [
    'activity',
    'Load Development',
    'Reloading Course',
    'Reloading',
    'Research',
    'Hunter Training',
    'Firearm Related Conference or Workshop',
    'Hunting Related Conference or Workshop',
    'Sport Shooter Training',
    'General Training',
    'Tradefair',
    'Non Compliance',
    'Range Officer',
    'Firearm Training',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Culling',
    'Competency Training',
    'Problem Animal Control',
    'BBRBA – Sport Shooting - 50m',
    'BBRBA - Sport Shooting - 100m',
    'BBRBA - Hunting Buffalo- 50m',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Culling',
    'Competency Training',
    'Problem Animal Control',
    'BBRKA - Sport Shooting - 50m',
    'BBRKA - Sport Shooting - 100m',
    'BBRKA  - Hunting - 50m',
    'Practical Assessment',
    'Hunting',
    'Culling',
    'Competency Training',
    'PFTC Grading Shoot',
    'Problem Animal Control',
    'CFRBA - Accuracy Test',
    'CFRBA - MIL Load Development ',
    'CFRBA - Sport Shooting – 100m',
    'CFRBA  - Sport Shooting – 200m',
    'CFRBA  - Sport Shooting – 300m',
    'CFRBA - Paper Ghong – 100m',
    'CFRBA  - Paper Ghong – 200m',
    'CFRBA  - Paper Ghong – 300m',
    'CFRBA  - Paper Ghong – 350m',
    'CFRBA - Sport Shooting – Grouping - 100m',
    'CFRBA - Hunting – Springbok - 200m',
    'CFRBA - Hunting – Warthog - 100m',
    'CFRBA - Hunting – Jackal - 100m',
    'Practical Assessment',
    'Firearm Training',
    'Self Defense Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'CFRSA - Sport Shooting – 100m',
    'CFRSA - Sport Shooting – 200m',
    'CFRSA - Paper Ghong – Rapid Fire – 50m',
    'CFRSA - Paper Ghong – Rapid Fire – 25m',
    'CFRSA - Defensive Shooting – Rapid Fire – 25m',
    'CFRSA - Defensive Shooting – Rapid Fire – 50m',
    'Practical Assessment',
    'Firearm Training',
    'Self Defense Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'CFHGP - Sport Shooting – 10m',
    'CFHGP - Sport Shooting – 15m',
    'CFHGP - Rapid Fire - 10m',
    'CFHGP - Steel Challenge - 15m',
    'CFHGP - Defensive Shooting – 10m',
    'CFHGP - Defensive Shooting –  Rapid Fire - 10m',
    'CFHG',// added as filter
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Culling',
    'Competency Training',
    'Problem Animal Control',
    'PFTC Grading Shoot',
    'RFRBA - Sport Shooting – 25m',
    'RFRBA - Sport Shooting – 50m',
    'RFRBA - Sport Shooting – 75m',
    'RFRBA - Accuracy Test',
    'RFRBA - Paper Ghong – 25m',
    'RFRBA - Paper Ghong – 50m',
    'RFRBA - Paper Ghong – 75m',
    'Practical Assessment',
    'Firearm Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'RFRSA - Sport Shooting – 25m',
    'RFRSA - Sport Shooting – 50m',
    'RFRSA - Sport Shooting – 75m',
    'RFRSA - Paper Ghong – Rapid Fire – 15m',
    'RFRSA - Paper Ghong – Rapid Fire – 30m',
    'RFRSA - Paper Ghong – 25m',
    'RFRSA - Paper Ghong – 50m',
    'RFRSA - Paper Ghong – 75m',
    'RFRSA - Defensive Shooting – 15m',
    'RFRSA - Defensive Shoting – 25m',
    'RFRSA - Defensive Shooting – 15m',
    'Firearm Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'RFHGP – Sport Shooting – 10m',
    'RFHGP – Rapid Fire - 10m',
    'RFHGP – Steel Challenge - 10m',
    'RFHGP – Defensive Shooting – 10m',
    'Practical Assessment',
    'Hunting',
    'BPR – Sport Shooting - 50m',
    'BPR – Sport Shooting - 100m',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'BPHG – Sport Shooting – 10m',
    'BPHG – Sport Shooting – 15m',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Competency Training',
    'SG12 – Shotgun 12 Gauge – Single Stand',
    'Firearm Training',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Competency Training',
    'SG16 – Shotgun 16 Gauge – Single Stand',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Competency Training',
    'SGSA – Steel Challenge',
    'SGSA – Single Stand',
    'Practical Assessment',
    'Firearm Training',
    'Problem Animal Control',
    'LAR – Sport Shooting – 100m',
    'LAR – Rapid Fire - 15m',
    'LAR – Steel Challenge - 25m',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Culling',
    'Problem Animal Control',
    'CFHGH – Sport Shooting – 100m',
    'CFHGH - Hunting – Warthog - 100m',
    'Practical Assessment',
    'Firearm Training',
    'PCC  - Steel Challenge',
    'PCC - Sport Shooting – 10m',
    'PCC  - Sport Shooting – 15m',
    'PCC  - Rapid Fire - 10m',
    'PCC  - Steel Challenge - 10m',
    'PCC  - Defensive Shooting – 10m',
    'Hunting',
    'Competency Training',
    'SG410 – Shotgun 410 Gauge – Single Stand',
    'Practical Assessment',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Competency Training',
    'SG20 – Single Stand',
    'SGP12 – Steel Challenge',
    'Practical Assessment',
    'Firearm Training',
    'Hunting',
    'Competency Training',
    'Problem Animal Control',
    'SGP12 – Single Stand',
    'Practical Assessment',
    'Firearm Training',
    'Self Defense Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'CFHGR – Sport Shooting – 10m',
    'CFHGR – Sport Shooting – 15m',
    'CFHGR – Rapid Fire - 10m',
    'CFHGR - Steel Challenge - 15m',
    'CFHGR – Defensive Shooting – 10m',
    'CFHGR – Defensive Shooting –  Rapid Fire - 10m',
    'Practical Assessment',
    'Firearm Training',
    'Competency Training',
    'PFTC Grading Shoot',
    'RFHGR – Sport Shooting – 10m',
    'RFHGR – Rapid Fire - 10m',
    'RFHGR – Steel Challenge - 10m',
    'RFHGR – Defensive Shooting – 10m',
    'CFRBA – MOA Load Development ',
    'BBRBA – Load Development',
    'BBRKA – Load Development',
    'CFRSA  - Load Development',
    'CFRLAR - Load Development',
    'CFHGP - Load Development',
    'CFHGR - Load Development',
    'MLRBP - Load Development',
    'MLHBP - Load Development',
    'CFHGH - Load Development',
    'BRC - Practice',
    'BRC - Sporting Target Indoor',
    'BRC - Sporting Target Outdoor',
    'BRC - Sporting Target 3D',
    'BCP - Hunting',
    'BCP - Practice',
    'BCP - Sporting Target Indoor',
    'BCP - Sporting Target Outdoor',
    'BCP - Sporting Target 3D',
    'BCP - Hunting',
    'BBB - Practice',
    'BBB - Sporting Target Indoor',
    'BBB - Sporting Target Outdoor',
    'BBB - Sporting Target 3D',
    'BBB - Hunting',
    //Activity Descipline
    'Benchrest',
    'Bisley',
    'Bianchi',
    'CHASA Rifle',
    'CHASA Handgun',
    'CHASA Semi Auto',
    'Cowbow Action',
    'Double Tap',
    'F-Class',
    'Ghong Challenge',
    'Grouping',
    'Gallery Gun',
    'IDPA',
    'IPSC',
    'Mastershot',
    'Pin Shooting',
    'PRS',
    'Skeet',
    'Sporting Shotgun',
    'Sillhouette',
    'Steel Challenge Handgun',
    'Steel Challenge Shotgun',
    'Steel Challenge Semi Auto',
    'SADPA',
    'SACRA',
    'Trap',
    'Veldskiet',
    'XSSSA',
    '3 Gun',
    'SA Jag Sprinbok Tabel',
    'SA Jag Vlakvark Tabel',
    'Send-IT',
    'Team Shoot',
    'CHASA Sporting Shotgun',
    'Calvinia Steel Plate',
    'Paladin',
    'CHASA Vlakte Shoot',
    'NRL Hunter',
    'Wildman Challenge',
    'SA Jag Handgun',
    'GOSA Topshot',
    'Noodhulp Shoot',
    'CHASA Rimfire Rifle',
    
 ];

 export const  QAKeywords = [
 'activity',
 'Load Development',
 'Reloading Course',
 'Reloading',
 'Research',
 'Hunter Training',
 'Firearm Related Conference or Workshop',
 'Hunting Related Conference or Workshop',
 'Sport Shooter Training',
 'General Training',
 'Tradefair',
 'Non Compliance',
 'Range Officer',
 'Firearm Training',
 'Firearm Training',
 'Hunting',
 'Culling',
 'Competency Training',
 'Problem Animal Control',
 'BBRBA – Big Bore Rifle Bolt Action - Sport Shooting - 50m',
 'BBRBA - Big Bore Rifle Bolt Action - Sport Shooting - 100m',
 'BBRBA  - Big Bore Rifle Bolt Action - Hunting - 50m',
 'Firearm Training',
 'Hunting',
 'Culling',
 'Competency Training',
 'Problem Animal Control',
 'BBRKA – Big Bore Rifle Break Action - Sport Shooting - 50m',
 'BBRKA - Big Bore Rifle Break Action - Sport Shooting - 100m',
 'BBRKA  - Big Bore Rifle Break Action - Hunting - 50m',
 'Practical Assessment',
 'Hunting',
 'Culling',
 'Competency Training',
 'PFTC Grading Shoot',
 'Problem Animal Control',
 'CFRBA - Center Fire Rifle Bolt Action - Accuracy Test',
 'CFRBA – Center Fire Rifle Bolt Action - Load Development',
 'CFRBA – Center Fire Rifle Bolt Action -  Sport Shooting – 100m',
 'CFRBA  - Center Fire Rifle Bolt Action - Sport Shooting – 200m',
 'CFRBA  - Center Fire Rifle Bolt Action - Sport Shooting – 300m',
 'CFRBA – Center Fire Rifle Bolt Action  - Paper Ghong – 100m',
 'CFRBA  - Center Fire Rifle Bolt Action - Paper Ghong – 200m',
 'CFRBA  - Center Fire Rifle Bolt Action - Paper Ghong – 300m',
 'CFRBA  - Center Fire Rifle Bolt Action - Paper Ghong – 350m',
 'CFRBA – Center Fire Rifle Bolt Action - Sport Shooting – Grouping - 100m',
 'CFRBA – Center Fire Rifle Bolt Action - Hunting – Springbok - 200m',
 'CFRBA - Center Fire Rifle Bolt Action - Hunting – Warthog - 100m',
 'CFRBA - Center Fire Rifle Bolt Action - Hunting – Jackal - 100m',
 'Firearm Training',
 'Self Defense Training',
 'Competency Training',
 'PFTC Grading Shoot',
 'CFRSA  - Center Fire Rifle Semi Auto - Sport Shooting – 100m',
 'CFRSA  - Center Fire Rifle Semi Auto - Sport Shooting – 200m',
 'CFRSA  - Center Fire Rifle Semi Auto - Sport Shooting – Paper Ghong – Rapid Fire – 50m',
 'CFRSA  - Center Fire Rifle Semi Auto - Sport Shooting – Paper Ghong – Rapid Fire – 25m',
 'CFRSA  - Center Fire Rifle Semi Auto - Defensive Shooting – Rapid Fire – 25m',
 'CFRSA  - Center Fire Rifle Semi Auto - Defensive Shooting – Rapid Fire – 50m',
 'Firearm Training',
 'Self Defense Training',
 'Competency Training',
 'PFTC Grading Shoot',
 'CFHGP – Center Fire Handgun Pistol - Sport Shooting – 10m',
 'CFHGP – Center Fire Handgun Pistol - Sport Shooting – 15m',
 'CFHGP – Center Fire Handgun Pistol - Sport Shooting – Rapid Fire - 10m',
 'CFHGP - Center Fire Handgun Pistol - Sport Shooting - Steel Challenge - 10m',
 'CFHGP – Centre Fire Handgun Pistol - Defensive Shooting – 10m',
 'CFHGP – Centre Fire Handgun Pistol - Defensive Shooting –  Rapid Fire - 10m',
 'Firearm Training',
 'Hunting',
 'Culling',
 'Competency Training',
 'Problem Animal Control',
 'PFTC Grading Shoot',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting – 25m',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting – 50m',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting – 75m',
 'RFRBA  - Rim Fire Rifle Bolt Action - Accuracy Test',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting -  Paper Ghong – 25m',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting -  Paper Ghong – 50m',
 'RFRBA  - Rim Fire Rifle Bolt Action - Sport Shooting -  Paper Ghong – 75m',
 'Firearm Training',
 'Competency Training',
 'PFTC Grading Shoot',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting – 25m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting – 50m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting – 75m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting – Paper Ghong – Rapid Fire – 15m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting – Paper Ghong – Rapid Fire – 30m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting  - Paper Ghong – 25m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting  - Paper Ghong – 50m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sport Shooting  - Paper Ghong – 75m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sdefensive Shoting – 15m',
 'RFRSA  - Rim Fire Rifle Semi Auto - Sdefensive Shoting – 25m',
 'Firearm Training',
 'Competency Training',
 'PFTC Grading Shoot',
 'RFHG – Rim Fire Handgun - Sport Shooting – 10m',
 'RFHG – Rim Fire Handgun - Sport Shooting – Rapid Fire - 10m',
 'RFHG – Rim Fire Handgun - Sport Shooting – Steel Challenge - 10m',
 'RFHG – Rim Fire Handgun - Defensive Shooting – 10m',
 'Hunting',
 'BPR – Black Powder Rifle - Sport Shooting - 50m',
 'BPR – Black Powder Rifle - Sport Shooting - 100m',
 'Firearm Training',
 'Hunting',
 'BPHG – Black Powder Handgun - Sport Shooting – 10m',
 'BPHG – Black Powder Handgun - Sport Shooting – 15m',
 'Firearm Training',
 'Hunting',
 'Competency Training',
 'SGH – Shotgun 12 Gauge – Single Stand',
 'Firearm Training',
 'Firearm Training',
 'Hunting',
 'Competency Training',
 'SGH – Shotgun 16 Gauge – Single Stand',
 'Firearm Training',
 'Hunting',
 'Competency Training',
 'SGH – Shotgun Semi Auto – Steel Challenge',
 'SGH – Shotgun Semi Auto – Single Stand',
 'Firearm Training',
 'Problem Animal Control',
 'LAR – Lever Action Rifle - Sport Shooting – 100m',
 'LAR – Lever Action Rifle - Sport Shooting – Rapid Fire - 15m',
 'LAR – Lever Action Rifle - Sport Shooting – Steel Challenge - 25m',
 'Firearm Training',
 'Hunting',
 'Culling',
 'Problem Animal Control',
 'CFHHG – Center Fire Hunting Handgun - Sport Shooting – 100m',
 'CHHG - Center Fire Hunting Handgun - Hunting – Warthog - 100m',
 'Firearm Training',
 'PCC  - Pistol Caliber Carbine - Steel Challenge',
 'PCC  - Pistol Caliber Carbine - Sport Shooting – 10m',
 'PCC  - Pistol Caliber Carbine - Sport Shooting – 15m',
 'PCC  - Pistol Caliber Carbine - Sport Shooting – Rapid Fire - 10m',
 'PCC  - Pistol Caliber Carbine - Sport Shooting – Steel Challenge - 10m',
 'PCC  - Pistol Caliber Carbine - Defensive Shooting – 10m',
 'Hunting',
 'Competency Training',
 'SGH – Shotgun 410 Gauge – Single Stand',
 'Firearm Training',
 'Hunting',
 'Competency Training',
 'SGH – Shotgun 20 Gauge – Single Stand',
 'SGP12 – Shotgun Pump Action – Steel Challenge',
 'Firearm Training',
 'Hunting',
 'Competency Training',
 'Problem Animal Control',
 'SGP12 – Shotgun Pump Action – Single Stand',
 'Firearm Training',
 'Self Defense Training',
 'Competency Training',
 'PFTC Grading Shoot',
 'CFHGR – Center Fire Handgun Revolver - Sport Shooting – 10m',
 'CFHGR – Center Fire Handgun Revolver - Sport Shooting – 15m',
 'CFHGR – Center Fire Handgun Revolver - Sport Shooting – Rapid Fire - 10m',
 'CFHGR - Center Fire Handgun Revolver  - Sport Shooting - Steel Challenge - 10m',
 'CFHGR – Centre Fire Handgun Revolver - Defensive Shooting – 10m',
 'CFHGR – Centre Fire Handgun Revolver - Defensive Shooting –  Rapid Fire - 10m',
 'Firearm Training',
 'CFHG',// added as filter
 'Competency Training',
 'PFTC Grading Shoot',
 'RFHGR – Rim Fire Handgun - Sport Shooting – 10m',
 'RFHGR – Rim Fire Handgun - Sport Shooting – Rapid Fire - 10m',
 'RFHGR – Rim Fire Handgun - Sport Shooting – Steel Challenge - 10m',
 'RFHGR – Rim Fire Handgun - Defensive Shooting – 10m',
 // ActivityDiscipline
'Benchrest',
'Bisley',
'Bianchi',
'CHASA Rifle',
'CHASA Handgun',
'CHASA Semi Auto',
'Cowbow Action',
'Double Tap',
'F-Class',
'Ghong Challenge',
'Grouping',
'Gallery Gun',
'IDPA',
'IPSC',
'Mastershot',
'Pin Shooting',
'PRS',
'Skeet',
'Sporting Shotgun',
'Sillhouette',
'Steel Challenge Handgun',
'Steel Challenge Shotgun',
'Steel Challenge Semi Auto',
'SADPA',
'SACRA',
'Trap',
'Veldskiet',
'XSSSA',
'3 Gun',
'SA Jag Sprinbok Tabel',
'SA Jag Vlakvark Tabel',
'Send-IT',
'Team Shoot',
'CHASA Sporting Shotgun',
'Calvinia Steel Plate',
'Paladin',
'CHASA Vlakte Shoot',
'NRL Hunter',
'Wildman Challenge',
'SA Jag Handgun',
'GOSA Topshot',
'Noodhulp Shoot',
'CHASA Rimfire Rifle',
 ];