import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as constFile from "../../../store/constantFile/ConstFile";

const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 1,
    flexDirection: "col",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    display: "inline-block",
    fontSize: 14,
    color: "black"
  }
};

class License extends Component {
  handleSubmit = e => {
    this.props.onAddLicenseDetailsChange(
      this.props.licenceArray.custom,
      "Custom"
    );
    if (this.props.licenceArray.custom === constFile.CTRUE) {
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.licenseName,
        "LicenseName"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.model,
        "Model"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.description,
        "Description"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.licenseNo,
        "LicenseNo"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.code,
        "Code"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.serialNumber,
        "SerialNumber"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.firstIssueDate,
        "FirstIssueDate"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.issueDate,
        "IssueDate"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.expiryDate,
        "ExpiryDate"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.issued,
        "Issued"
      );
    } else if (this.props.licenceArray.custom === constFile.CFALSE) {
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.firearm,
        "Firearm"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.licenseNo,
        "LicenseNo"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.make,
        "Make"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.model,
        "Model"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.caliber,
        "Caliber"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.serialNumber,
        "SerialNumber"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.licenseType,
        "LicenseType"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.section,
        "Section"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.expiryDate,
        "ExpiryDate"
      );
      this.props.onAddLicenseDetailsChange(
        this.props.licenceArray.issueDate,
        "IssueDate"
      );
    }
    this.props.onCurrentLicenseTabChange("5", "currentLicenseTab");
  };
  render() {
    return (
      <div className="container border-bottom font-weight-bold pb-3 pt-1">
        {this.props.licenceArray.custom === constFile.CFALSE ? (
          <div onClick={e => this.handleSubmit(e)} style={{ cursor: "pointer" }}>
            <div>{`Firearm: ${this.props.licenceArray.firearm}`}</div>
            <div>{`License No.: ${this.props.licenceArray.licenseNo}`}</div>
            <div>{`Expiry Date: ${this.props.licenceArray.expiryDate.substring(0, 10)}`}</div>
            <div>{`Renewal Days Left: ${this.props.licenceArray.remainingDays}`}</div>
          </div>
        ) : (
          <div onClick={e => this.handleSubmit(e)} style={{ cursor: "pointer" }}>
            <div>{`Name: ${this.props.licenceArray.licenseName}`}</div>
            <div>{`License No.: ${this.props.licenceArray.licenseNo}`}</div>
            <div>{`Expiry Date: ${this.props.licenceArray.expiryDate.substring(0, 10)}`}</div>
            <div>{`Renewal Days Left: ${this.props.licenceArray.remainingDays}`}</div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currLicenseTab: state.licensesReducer.currentLicenseTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onAddLicenseDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(License));
