import Axios from "axios";
import * as EndPoints from "../../../../API/APIendpoint";

export const approveEndorsementApplication = async (
  endorsementNo,
  status,
  reason
) => {
  const config = {
    url: `${EndPoints.BASE}${
      EndPoints.APPROVE_ENDORSEMENT_APPLICATION
    }${endorsementNo}/${status}/Approve`,
    method: "post",
    data: JSON.stringify(reason),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };
  return await Axios(config);
};

export const sendApprovalEmail = async emailParam => {
  const config = {
    url: EndPoints.BASE + EndPoints.SEND_EMAIL_ENDORSEMENT_APPROVAL_URL,
    method: "post",
    data: emailParam,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };
  return await Axios(config);
};
