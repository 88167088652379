import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar,
  Overview,
  IconLabelButton
} from '../index';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import Loader from '../Loader/Loader';
import { UPDATE_DEDICATED_STATUS_RECORD } from '../../store/DedicatedStatus/types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CloudDownload } from '@material-ui/icons';
import ActivityActions from '../../store/Activities/actions';
import DedicatedService from '../../services/dedicatedstatus.service';
import ActivityService from '../../services/activities.service';
import MemberService from '../../services/member.service';
import { getMemberStatusFromInt } from '../../shared/helpers';
import { printActivities } from '../../shared/pdfHelpers';
import { MEMBER_STATUS } from '../../shared/constants';

const columns = [
  { field: 'applicationDate', headerName: 'Date' },
  { field: 'dstype', headerName: localStorage.getItem('isMobile') === 'true' ? 'Type' : 'Application Type' },
  { field: 'status', headerName: localStorage.getItem('isMobile') === 'true' ? 'Status' : 'Application Status' }
];

class ActivitiesReports extends Component {
  constructor(props) {
    super(props);
    this.dedicatedService = DedicatedService(this.props.token);
    this.activityService = ActivityService(this.props.token);
    this.memberService = MemberService(this.props.token);
  }
  
  // componentDidMount(){
  //     console.log("Reports", this.props.activities)
  // }

  downloadActivities = (activities,identifier) => this.dedicatedService
  .getApprovedDedicatedStatus(this.props.memNo)
  .then((res) => {
    let dsHunter = res.data
      .find(ds => ds.dstype.toLowerCase().includes('hunter') &&
        ds.status === MEMBER_STATUS.APPROVED) || {};
    let dsSports = res.data
      .find(ds => ds.dstype.toLowerCase().includes('sport') &&
        ds.status === MEMBER_STATUS.APPROVED) || {};
    return this.memberService.getAllImages()
      .then((images) => printActivities(
        activities,
        dsHunter.dsno,
        dsSports.dsno,
        identifier,
        images.data.narfo,
        { 
          ...this.props.memberDetails,
          status: getMemberStatusFromInt(this.props.memberDetails.status)
        },
      ));
  });

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <React.Fragment>
          <div className="border-radius-2 py-2 px-4 bg-white-2 mt-4">
            <SubtitleBar
              variant="subtitle2"
              title={'Activities Reports'}
            />

        <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}>Activities</Typography>
                </Grid>
                
                {
                  this.props.activities.length > 0?
                  <Grid item xs={12} md={8}>
                <IconLabelButton
                  actionText="Download"
                  icon={<CloudDownload />}
                  full
                  callBack={() => this.downloadActivities(this.props.activities.filter(a => a.status == "Approved"),'#auto-table')}
                />
                </Grid>

                :
                <Grid item xs={12} md={8}>
                <Typography style={{marginTop:'10px'}}><strong style={{fontStyle:'italic'}}>Report not found</strong></Typography>
                </Grid>}
              </Grid>

          </div>
      </React.Fragment>
    );
  }
}

ActivitiesReports.defaultProps = {
activities: [],
downloadActivityCallback: () => Promise.resolve(null),
};

const mapStateToProps = (state) => ({
	token: state.AuthReducer.token,
	memNo: state.AuthReducer.memNo,
	activities: state.nActivitiesReducer.activities,
    memberDetails: state.ProfileReducer.memberData,
});

const initActivityActions = dispatch => activityService => {
    const actions = ActivityActions(activityService);
    return {
      fetchActivities: (id) => dispatch(actions.fetchActivities(id)),
    };
  };
const mapDispatchToProps = dispatch => ({
  activityActions: initActivityActions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesReports);