
export const salesDetailMessage = (
  'We are almost done. In order to effectively provide you with the necessary assistance\
  and support, please select one of our partners and the representative who assisted you. \
  If no one personally attended to you or you did not join through one of our partners, \
  keep the selections as "No".'
);

export const loginDetailMessage = (
  'For your registration to be successful and use the online platform, we require a unique \
  email address and username. Also, please provide a password that you will use to log in \
  once your profile is activated. NEVER give your login details and password to anyone, this \
  will compromise the security of your personal information. After completing this step, you \
  will be redirected to the secure online payment platform. This is a third party service \
  provider. They will store your email address and username to identify the transaction. If \
  the payment is successful, your profile will be activated. If you do not complete the \
  online transaction, your profile will only be activated once payment has been verified.'
);