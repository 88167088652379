import React from "react";
import PropTypes from "prop-types";
const Message = ({ msg }) => {
  return (
    <div class="alert alert-success alert-dismissible fade show">
      <strong>Success!</strong> {msg}
      <button type="button" class="close" data-dismiss="alert">
        &times;
      </button>
    </div>
  );
};
Message.propTypes = {
  msg: PropTypes.string.isRequired
};
export default Message;
