import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import * as actionTypes from "../../store/actions/actionTypes";
import { BASE, ARTICLE_URL } from "../../API/APIendpoint";
import Axios from "axios";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import defPic from "./NewsImage/pp6.jpg";

function handleChange(e) {
  this.setState({ content: e.target.getContent() });
}
function buttonClickSaveArticle() {
  Axios({
      url: BASE + ARTICLE_URL,
      data: {
        title: this.state.title,
        content: this.state.content
      },
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      this.setState({
        articles: res.data
    })
    .then(this.props.history.push("/News"))  
  });
}
class AdminNewsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      bgNews: defPic,
      formData: new FormData(),
      newsPicture: ""
    };
    this.showNewsPicture = this.showNewsPicture.bind(this);
    this.newsPicture = React.createRef();
  }
  linkToNews() {
    this.props.history.push("/News");
  }
  showNewsPicture(e) {
    e.preventDefault();
    this.newsPicture.current.click();
  }
  pictureUpload(e) {
    e.preventDefault();
    const file = e.target.files[0];
    this.state.formData.append("fileName", file);
    let reader = new FileReader();
    reader.onloadend = t => {
      this.setState({
        bgNews: t.target.result
      });
    };
    Axios({
      url: BASE + "api/NewsArticles/uploadNewsPicture",
      data: this.state.formData,
      method: "post",
      headers: {
        "Content-type": "multipart/form-data",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {});
    reader.readAsDataURL(file);
  }
  render() {
    return (
      <div className="container " style={{ marginTop: "80px" }}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={e => this.pictureUpload(e)}
          ref={this.newsPicture}
        />
        <div className="material-editor-header">
          <div
            className="material-editor-header-back-button"
            onClick={() => {
              this.linkToNews();
            }}
          >
            <KeyboardArrowLeft
              style={{ width: "50px", height: "35px", cursor: "pointer" }}
            />
          </div>
          <Fragment>
            <div className="material-editor-header-input">
              <input
                className="form-control"
                placeholder="(News Article title)"
                onInput={a => {
                  this.setState({
                    title: a.target.value
                  });
                }}
                defaultValue=""
              />
            </div>
            <div className="material-editor-header-save-button">
              <button
                className="btn btn-outline-success form-control"
                onClick={buttonClickSaveArticle.bind(this)}
              >
                Save
              </button>
            </div>
          </Fragment>
        </div>
        <div className="flex-container">
          <img
            src={this.state.bgNews}
            alt="Activity"
            className="news-image mr-2"
          />
          <div
            className="mt-2 mb-2"
            onClick={this.showNewsPicture}
            style={{ cursor: "pointer" }}
          >
            Add Background
          </div>
        </div>
        <div>
          <Editor
            placeholder="123abc"
            init={{
              plugins: "link image code",
              toolbar:
                "undo redo | bold italic | alignleft aligncenter alignright | image | link | cut | copy | paste | font",
              height: "750px"
            }}
            apiKey="kmpgx6hmv3ix0abz8fn9sly4r5vf2fzw0tw2ubm88mhydjba"
            onChange={handleChange.bind(this)}
          />
        </div>
      </div>
    );
  }
}

AdminNewsPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.article
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewsPage);
