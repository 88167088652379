import * as Types from "./ApproveNewEndorsementsActionTypes";

const approveNewEndorsementsState = {
    approvalStatus: {
        loading: null,
        response: null,
        error: null
    },
    emailStatus: {
        loading: null,
        response: null,
        error: null
    },
    reason: "",
    application: null
};

const reducer = (state = approveNewEndorsementsState, action) => {
    switch (action.type) {
        case Types.APPROVE_ENDORSEMENT_BEGIN:
        case Types.APPROVE_ENDORSEMENT_ERROR:
        case Types.APPROVE_ENDORSEMENT_SUCCESS:
        case Types.EMAIL_BEGIN:
        case Types.EMAIL_ERROR:
        case Types.EMAIL_SUCCESS:
        case Types.SET_CURRENT_ENDORSEMENT:
        case Types.SET_REASON:
            return {
                ...state,
                ...action.approveNewEndorsementsState
            };
        default:
            return state;
    }
};

export default reducer;