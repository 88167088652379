import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Typography 
} from '@material-ui/core';

import {
  EditableGridLabel,
  IconLabelButton,
  Loader,
  SubtitleBar
} from '../index';
import {
  editableSelectConfig,
  editableTextInputConfig,
  editableTextInputConfigModelField,
  editableTextBoxInputConfig,
} from '../../shared/constants';
import { checkValidity, updateObject, initCheckbox } from '../../shared/utility';
import EndorsementsService from '../../services/endorsements.service';
import { SET_ENDORSEMENTS } from '../../store/Endorsements/actionTypes';

export class AddEndorsement extends Component {
  constructor(props) {
    super(props);
	this.endorsementsService = EndorsementsService(this.props.token);

    this.state = {
      controls: this.initControls(),
      endorsementApplication: {
        title: '',
        endorsementPurpose: '',
        applicationType: '',
        sectionType: '-',
        firearmType: '-',
        actionType: '-',
        caliber: '',
        make: '',
        model: '',
        serialNumber: '',
        motivation: '',
        declaration: ''
      },
      loading: false,
      formHasErrors: true,
    };
  }

  initSelect = (items) => ({
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
    }
  });

  initControls = () => ({
    endorsementPurpose: this.initSelect(this.props.endorsementPurposeType),
    title: {...editableTextInputConfig},
    applicationType: this.initSelect(this.props.applicationTypes),
    sectionType: this.initSelect(this.props.sectionTypes),
    firearmType: this.initSelect(this.props.firearmTypes),
    actionType: this.initSelect(this.props.actionTypes),
    caliber: {...editableTextInputConfig},
    make: {...editableTextInputConfig},
    model: {...editableTextInputConfigModelField},
    serialNumber: {...editableTextInputConfig},
    motivation: {...editableTextBoxInputConfig},
    declaration: initCheckbox('I hereby declare that all information submitted is true and accurate'), 
  });

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }
    value = typeof value === 'number' ? value.toString() : value;
    const validity = checkValidity(
      value === '' ? '' : value, 
      this.state.controls[controlName].config.validate
    );
    const updatedControls = updateObject(
      this.state.controls[controlName], {
        config: updateObject(this.state.controls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true,
        })
      }
    );
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });
    this.setState({
      controls,
      endorsementApplication: {
        ...this.state.endorsementApplication,
        [controlName]: value,
      },
      formHasErrors,
    });
  };

  label = (title, name, fullWidth) => (
    <Grid item xs={12} md={fullWidth ? 12: 6} lg={fullWidth ? 12: 4} container>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.endorsementApplication[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)}
        variant="standard"
      />
    </Grid>
  );
  
  submitHandler = async () => {
    this.setState({ loading: true });
    let formHasErrors = false;
    let controls = {...this.state.controls};

    Object.keys(this.state.endorsementApplication).forEach(key => {
      let value = this.state.endorsementApplication[key];
      value = typeof value === 'number' ? value.toString() : value;
      const validity = checkValidity( 
        value === '-' ? '' : value,
        controls[key].config.validate
      );
      const updatedControls = updateObject(
        controls[key], {
          config: updateObject(
            controls[key].config,
            {
              valid: validity.isValid,
              errors: validity.errors,
              touched: true,
            }
          )
        }
      );
      controls = {
        ...controls,
        [key]: updatedControls,
      };
      if (value === '-') {
        formHasErrors = true;
      }
    });

    if (!formHasErrors) {
      const endorsementApplication = {...this.state.endorsementApplication};
      const applicationParams = {
        endorsementPurpose: endorsementApplication.endorsementPurpose,
        endorsementType: endorsementApplication.applicationType,
        endorsementSection: endorsementApplication.sectionType
      };
      const applicationBody = {
        ApplicationType: endorsementApplication.applicationType,
        FireArmType: endorsementApplication.firearmType,
        ActionType: endorsementApplication.actionType,
        Make: endorsementApplication.make,
        Model: endorsementApplication.model,
        Section: endorsementApplication.sectionType,
        SerialNumber: endorsementApplication.serialNumber,
        Caliber: endorsementApplication.caliber,
        Declaration: endorsementApplication.declaration,
        EndorsementType: endorsementApplication.applicationType,
        Motivation: endorsementApplication.motivation,
        Title: endorsementApplication.title,
        MemNo: this.props.memNo,
      };   
      await this.endorsementsService.postMemberEndorsement(applicationBody, applicationParams)
	    .then(res => this.props.onEndorsementGet(this.props.endorsements.concat(res.data.endorsementApplication)))
      this.setState({loading: false});
      this.props.backCallback();
    } else {
      this.setState({ loading: false, formHasErrors, controls});
    }
  }
 
  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        <SubtitleBar title={isMobile ? 'Application' : 'Endorsement Application'} back onBack={this.props.backCallback}/>
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
          <Grid container spacing={8} direction="column" justify="space-around" alignItems="center">
            <Grid item container xs={12} spacing={0} direction="column" className="pt-6">
              {this.label('Endorsement Title', 'title')}
              {this.label('Endorsement Purpose', 'endorsementPurpose')}
            </Grid>
            <Grid item container xs={12} spacing={0} className="pt-6">
              <Grid item container xs={12} alignItems="baseline">
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Typography variant="subtitle2">
                    Firearm Details
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Typography>
                    *All fields are required
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} className="py-0">
                <Divider />
              </Grid>

              <Grid item xs={12} container>
                {this.label('Type of Firearm', 'firearmType')}
                {this.label('Firearm Action Type', 'actionType')}
                {this.label('Calibre', 'caliber')}
                {this.label('Firearm Make', 'make')}
                {this.label('Firearm Model', 'model')}
                {this.label('Firearm Serial Number', 'serialNumber')}
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={4}>
              <Grid item container xs={12} alignItems="baseline">
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Typography variant="subtitle2">
                    My Motivation
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <Typography>
                    *All fields are required
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} className="py-0">
                <Divider />
              </Grid>

              <Grid item xs={12} container spacing={4}>
                {this.label('Type of Application', 'applicationType')}
                {this.label('Section', 'sectionType')}
                {this.label('Briefly describe the purpose of and motivation for this firearm', 'motivation', true)}
                {this.label(' ', 'declaration', true)}
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled={this.state.formHasErrors}
            actionText="Submit" 
            color="primary" 
            callBack={this.submitHandler}
          />}
          { this.state.loading && <Loader />}
        </div>
      </React.Fragment>
    );
  }
}

AddEndorsement.defaultProps = {
  firearmTypes: [],
  actionTypes: [],
  backCallback: () => null,
};

AddEndorsement.propTypes = {
  backCallback: PropTypes.func.isRequired,
  actionTypes: PropTypes.array.isRequired
};

const mapStateToProps = state => {
  return {
    token: state.AuthReducer.token,
	  memNo: state.AuthReducer.memNo,
    endorsements: state.nEndorsementReducer.endorsements
  }
};

const mapDispatchToProps = dispatch => {
  return {
	onEndorsementGet: newEndorsement => 
	  dispatch({
		type: SET_ENDORSEMENTS,
		payload: newEndorsement
	})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEndorsement);