import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import lightGreen from '@material-ui/core/colors/lightGreen';

const arcBlue = "#0B7289";
const arcOrange = "#FFBA60";
const _grey = "#808080";
const _white = "#FFFFFF"
const _purple = "#4738a0";
const _lightGrey = "#F0F0F0";

const theme = createTheme({
  palette: {
    common: {
      blue: `${arcBlue}`,
      orange: `${arcOrange}`,
      grey: `${_grey}`,
      lightGrey: `${_lightGrey}`
    },
    primary: {
      main: `${_white}`,
      },
    secondary: {
      main:`${_purple}`
    }
  },
  typography: {
    h4: {
      fontWeight: 700,
      fontFamily: 'calibri'
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
      fontFamily: 'calibri'
    },
    useNextVariants: true,
  },
});

export default theme;