import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, Avatar, TextField } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { BASE, GOSA_HEADER } from "../../../API/APIendpoint";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#007bff",
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function GosaForm() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [idNo, setIdNo] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [idNoError, setIdNoError] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleChange = (name, event) => {
    // event.preventDefault();
    switch (name) {
      case "name":
        setName(event.target.value);
        break;

      case "lastName":
        setLastName(event.target.value);
        break;

      case "idNo":
        setIdNo(event.target.value);
        break;

      case "contactNo":
        setContactNo(event.target.value);
        break;

      case "email":
        setEmail(event.target.value);
        break;
      default:
        break;
    }

    validate();
  };

  const onSubmit = (event) => {
    setOpen(!open);
    setErrorMessage(false);
    setSuccessMessage(false);

    //TODO: Hardcode Partner ID based on Environments
    const partnerID = BASE.includes("apiqa") ? 57 : 66;

    const salesRepMemNo = BASE.includes("apiqa") ? 'NM-1100022' : 'NM-1009507';
    let payload = {
      firstname: name,
      surname: lastName,
      cellNo: contactNo,
      idno: idNo,
      email: email,
      storeId: partnerID,
      salesRepMemNo: salesRepMemNo,
    };

    axios({url: `${BASE}api/Partners/ImportmMembership`, method: "post", data: payload,        
    headers: {
        'Authorization': `Bearer ${GOSA_HEADER}`,
    }})
      .then(() => {
          setSuccessMessage(true);
          setErrorMessage(false);
          setOpen(false);
          setErrorMessage("");
      })
      .catch((err) => {
        setSuccessMessage(false);
        setErrorMessage(err?.response?.data);
        console.log("Registration Error ", err?.response?.data);
        setOpen(false);
      });
  };

  const handleIDChange = (field, event) => {
    const value = event.target.value;
    
    // Validate idNo field

      if (value.length === 13) {
        setIdNoError('');
      } else {
        setIdNoError('ID number must be 13 characters');
      }

    // Set the state
    if (value.length <= 13) {
      setIdNo(value);
    } else {
      // Handle other fields if needed
    }
  };

  
  const validate = () => {

    if (
      name.length > 1 &&
      lastName.length > 1 &&
      idNo.length == 13 &&
      contactNo.length > 1 &&
      email.length > 1
    )
      setIsValid(true);
    else setIsValid(false);
  };

  return (
    <div className={classes.root}>
      <div className="container text-center" style={{ marginTop: "80px" }}>
        <Grid container alignItems="center" justify="center">
          <Grid item xs={9} sm={5} md={5} lg={5} xl={5}>
            <div>
              <span
                class="material-icons"
                style={{
                  fontSize: "5rem",
                  WebkitTextStroketrokeWidth: "2.6px",
                }}
              >
                app_registration
              </span>

              <Typography variant="h5" style={{ marginBottom: "0.3em" }}>
                Please Enter your details
              </Typography>

              <TextField
                id="outlined-full-width"
                label="First Name"
                style={{ margin: 8 }}
                placeholder="Enter your name"
                value={name}
                required
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("name", e)}
                variant="outlined"
              />

              <TextField
                id="outlined-full-width"
                label="Last Name"
                required
                style={{ margin: 8 }}
                placeholder="Enter your last name"
                error=""
                value={lastName}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("lastName", e)}
                variant="outlined"
              />
              <TextField
              required
                id="outlined-full-width"
                label="ID number"
                style={{ margin: 8 }}
                type="number"
                placeholder="Enter your Identity No"
                error=""
                fullWidth
                margin="normal"
                error={Boolean(idNoError)}
                helperText={idNoError}
                value={idNo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleIDChange("idNo", e)}
                variant="outlined"
              />

              <TextField
              required
                id="outlined-full-width"
                label="Contact Number"
                style={{ margin: 8 }}
                placeholder="Enter your cell number"
                error=""
                fullWidth
                margin="normal"
                value={contactNo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("contactNo", e)}
                variant="outlined"
              />

              <TextField
              required
                id="outlined-full-width"
                label="Email Address"
                type="email"
                style={{ margin: 8 }}
                placeholder="Enter your email address"
                error=""
                fullWidth
                margin="normal"
                value={email}
                error={Boolean(emailError)}
                helperText={emailError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("email", e)}
                variant="outlined"
              />

              <Button
                type="submit"
                fullWidth
                style={{ margin: 8 }}
                variant="contained"
                color="primary"
                disabled={!isValid}
                onClick={onSubmit}
              >
                Register
              </Button>
            </div>
            {/* <Backdrop className={classes.backdrop} open={open} onClick={handleClose}> */}
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
          <div className={classes.alert, "container text-left"}>
            {errorMessage?.length > 1 && <Alert severity="error">
              <AlertTitle>Error registration</AlertTitle>
              <p style={{fontSize:'18px'}}>{errorMessage}</p>
            </Alert>}

            {successMessage && <Alert severity="success">
              <AlertTitle>Registration Success</AlertTitle>
              <p style={{fontSize:'18px'}}>Congratulations your details has been captured we've sent email to <strong>{email}</strong> for next steps</p>
            </Alert>}
          </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
