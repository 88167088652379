import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Typography,
  Grid 
} from '@material-ui/core';
import LicenceService from '../../services/licence.service';
import LicenceActions from '../../store/Licence/actions';
import MappedInput from '../MappedInput/MappedInput';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import {
  editableCalendarConfig,
  editableTextInputConfig,
  firearmType
} from '../../shared/constants';
import {
  EditableGridLabel,
  IconLabelButton,
  Loader
} from '../index';
import { checkValidity, updateObject, initSelect } from '../../shared/utility';

class AddLicenseReminder extends Component {
  constructor(props) {
    super(props);
    this.licenceService = LicenceService(this.props.token);
    this.licenceActions = this.props.licenceActions(this.licenceService);
    this.state = {
      controls: this.initControls(),
      _memNo: this.props.memNo,
      licenseRecord: {
        custom: '',
        caliber: '',
        expiryDate: new Date(),
        firearm: '',
        issueDate: new Date(),
        licenseNo: '',
        licenseType: '',
        make: '',
        model: '',
        section: '',
        serialNumber: '',
        description: ''
      },
      selectedRadio: firearmType.FIREARM,
      radioGroupConfig: {
        elementType: 'radioGroup',
        elementConfig: {
          direction: 'row',
          radios: [
            { value: firearmType.FIREARM, label: 'Firearm' },
            { value: firearmType.CUSTOM, label: 'Custom' }
          ]
        }
      },
      loading: false,
      formHasErrors: true,
    };
  }

  initControls = () => ({
    custom: {...editableTextInputConfig},
    caliber: {...editableTextInputConfig},
    expiryDate: {...editableCalendarConfig},
    firearm: {...editableTextInputConfig},
    issueDate: {...editableCalendarConfig},
    licenseNo: {...editableTextInputConfig},
    licenseType: {...editableTextInputConfig},
    make: {...editableTextInputConfig},
    model: {...editableTextInputConfig},
    section: initSelect(this.props.section),
    serialNumber: {...editableTextInputConfig},
    description: {...editableTextInputConfig}
  })

  handleRadioChange = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio: value,
      controls: this.initControls()
    });
  }

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }
    value = typeof value === 'number' ? value.toString() : value;
    const validity = checkValidity(value === '-' ? '' : value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });

    this.setState({
      controls,
      licenseRecord: {
        ...this.state.licenseRecord,
        [controlName]: value,
      },
      formHasErrors
    });
  };

  label = (title, name, fullWidth) => (
    <Grid item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.licenseRecord[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)} 
      />
    </Grid>
  )

  handleSubmit = async () => {
    this.setState({ loading: true });
    let formHasErrors = false;
    let controls = this.state.controls;
    Object.keys(this.state.licenseRecord).forEach(key => {
      let value = this.state.licenseRecord[key];
      value = typeof value === 'number' ? value.toString() : value;
      const validity = checkValidity(
        value === '-' ? '' : value, 
        controls[key].config.validate
      );
      const updatedControls = updateObject(controls[key], {
        config: updateObject(controls[key].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true,
        })
      });
      controls = {
        ...controls,
        [key]: updatedControls,
      };
      if (value === '-') {
        formHasErrors = true;
      }
    });
    if (!formHasErrors) {
      const licenseRecord = {...this.state.licenseRecord};
      licenseRecord.memNo = this.state._memNo;
      this.state.selectedRadio === firearmType.FIREARM ?
        licenseRecord.custom = 'false' :
        licenseRecord.custom = 'true';
      await this.props.addLicenseReminderCallback(licenseRecord)
        .then(() => this.licenceActions.fetchMemberLicenseReminders(this.props.memNo))
      ;
      this.setState({ loading: false });
      this.props.backCallback();
    } else {
      this.setState({ loading: false, formHasErrors, controls });
    }
  }

  render() {
    return (
      <React.Fragment>
        <SubtitleBar 
          variant="subtitle1"
          title="Firearm details"
          back
          onBack={this.props.backCallback}
        />
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="pl-3 pt-6">
                <Typography>Reminder type</Typography>
                <MappedInput 
                  {...this.state.radioGroupConfig}
                  value={this.state.selectedRadio}
                  changed={this.handleRadioChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} container spacing={4}>
              {this.label('Firearm', 'firearm')}
              {this.state.selectedRadio === firearmType.FIREARM ?
                this.label('Make', 'make') :
                null}
              {this.state.selectedRadio === firearmType.FIREARM ?
                this.label('Model', 'model') :
                null}
              {this.state.selectedRadio === firearmType.FIREARM ?
                this.label('Caliber', 'caliber') :
                null}
              {this.state.selectedRadio === firearmType.FIREARM ?
                this.label('Serial Number', 'serialNumber') :
                null}
              {this.label('License Number', 'licenseNo')}
              {this.state.selectedRadio === firearmType.FIREARM ?
                this.label('Section', 'section') :
                null}
              {this.label('Issue Date', 'issueDate')}
              {this.label('Expiry Date', 'expiryDate')}
              {this.label('Notes', 'description', true)}
            </Grid>
          </Grid>
        </div>
        <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled={this.state.formHasErrors || (this.state.licenseRecord.licenseNo === '' && this.state.licenseRecord.serialNumber === '')}
            actionText="Submit" 
            color="primary" 
            callBack={this.handleSubmit}
          />}
          {this.state.loading && <Loader />}
        </div>
      </React.Fragment>
    );
  }
}

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchMemberLicenseReminders: (id) => dispatch(action.fetchMemberLicenseReminders(id))
  };
};

const mapStateToProps = state => ({
  firearmLicenceList: state.LicenceReducer.firearmLicence,
  token: state.AuthReducer.token,
});

const mapDispatchToProps = dispatch => ({
  licenceActions: initialiseLicenceActions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLicenseReminder);