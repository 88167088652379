import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loginDetails: {
    email: "",
    password: "",
    userName: ""
  },
  resetPassDetails: {
    email: "",
    password: "",
    confirmPassword: "",
    identityNumber: ""
  },
  userProfileDetails: {
    username: "",
    email: "",
    idno: "",
    cellNo: "",
    physicalAddress: "",
    city: "",
    province: "",
    postalCode: "",
    memberNumber: "",
    occupation: "",
    userStatus:"",
    suburb: "",
    inceptionDate: "",
    expiryDate: "",
    isLoggedIn: false,
    adminLevel: false,
    partnerLevel:false,
    salesRepLevel:false,
    partnerId:-1
  },
  memberState: {
    isAdmin: false,
    isAdminMemView: false
  },
  memberObject: {
    firstname: "",
    surname: "",
    username: "",
    email: "",
    idno: "",
    cellNo: "",
    password: "",
    physicalAddress: "",
    suburb: "",
    city: "",
    suburb: "",
    province: "",
    postalCode: "",
    memberNumber: "",
    occupation: "",
    inceptionDate: "",
    expiryDate: ""
  },
  city: ""
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGIN_DETAILS:
      const newLoginDetails = {
        ...state.loginDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        loginDetails: newLoginDetails
      };
    case actionTypes.UPDATE_MEMBER_DETAILS_2:
      const newObj = {
        ...state.memberObject,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        memberObject: newObj
      };
    case actionTypes.UPDATE_RESET_DETAILS:
      const newResetDetails = {
        ...state.resetPassDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        resetPassDetails: newResetDetails
      };
    case actionTypes.UPDATE_USER_DETAILS:
      const newUserDetails = {
        ...state.userProfileDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        userProfileDetails: newUserDetails
      };
    case actionTypes.UPDATE_MEMBER_STATE:
      const newMemberState = {
        ...state.memberState,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        memberState: newMemberState
      };
    case actionTypes.UPDATE_MEMBER_CITY:
      return {
        ...state,
        city: action.city
      };
    default:
      return state;
  }
};

export default reducer;
