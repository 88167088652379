import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import ArrowForwardTwoToneIcon from '@material-ui/icons/ArrowForwardTwoTone';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Skeleton } from '@material-ui/lab';
import Avatar from 'react-avatar-edit';

export default function PartnerCard(props) {

  const ChooseIcon =(iconName)=>{
    switch (iconName)
    {
        case "commission":
            return  <MonetizationOnOutlinedIcon
            fontSize="large"
            style={{
                fontSize: "7rem",
                position: 'absolute',
                top: '40%',
                left: '80%',
                transform: 'translate(-50%, -50%)',
                color: 'rgba(255, 255, 255, 0.3)', // Adjust the color and opacity as needed
            }}
            />
        case "reps":
            return  <BusinessCenterOutlinedIcon
            fontSize="large"
            style={{
                fontSize: "7rem",
                position: 'absolute',
                top: '40%',
                left: '80%',
                transform: 'translate(-50%, -50%)',
                color: 'rgba(255, 255, 255, 0.3)', // Adjust the color and opacity as needed
            }}
            />
        case "members":
            return  <GroupAddOutlinedIcon
            fontSize="large"
            style={{
                fontSize: "7rem",
                position: 'absolute',
                top: '40%',
                left: '80%',
                transform: 'translate(-50%, -50%)',
                color: 'rgba(255, 255, 255, 0.3)', // Adjust the color and opacity as needed
            }}
            />

    }
  }
  return (
        <Grid item xs={12} style={{marginTop:"20px"}} >
            <div class={`card text-white bg-${props.color} mb-3`} >
                <div class="card-body">
                    <div style={{textAlign:'right'}}>
                    {ChooseIcon(props.icon)}

                    </div>
                    <h5 class="card-title">
                        <Typography gutterBottom style={props.black ? {color:'black'} : {color:'white'}} variant="h4" component="h2">{props.title}</Typography></h5>

                    {props.isloading 
                     ? <Box pt={0.5}>
                            <Skeleton />
                            <Skeleton width="60%" />
                        </Box>
                    
                    : <p class="card-text" style={props.black ? {color:'black'} : null}>{props.subTitle} : <strong> {props.subTitle === 'Amount due' ? 'R' : ''}
                    
                    {props.subTitleValue}
                    </strong></p>
                    }
                </div>
                <div class="card-footer border-black" style={props.black ? {color:'black',"textAlign":"center", cursor:'pointer'} : {color:"white","textAlign":"center", cursor:'pointer'}} onClick={_=> props.show()}>More info <ArrowForwardTwoToneIcon/></div>
                </div>
        </Grid>
  );
} 