import axios from 'axios';
import API_URL from './apiUrl';
const Cryptr = require("cryptr");


const getMember = headers => id => axios
  .get(`${API_URL}/Member/${id}`, { headers });

const getMemberByMemNo = headers => memNo => axios
  .get(`${API_URL}/Member/getMemByMemNo/${memNo}`, { headers });


const getMemNoByIdNo = headers => idNo => axios
  .get(`${API_URL}/Member/GetMemberNoById/${idNo}`, { headers });

const resetMemberPassword = headers => data => axios
  .post(`${API_URL}/Member/reset`, data, { headers: headers});

const getMemberProfilePicture = headers => id => axios
  .get(`${API_URL}/MemberProfilePictures/getMemId/${id}`, { headers});

const getMemberLoyaltyDays = headers => id => axios
  .get(`${API_URL}/LoyaltyDaysAdjust/getLoyaltyDays/${id}`, { headers });

const getMemberAccountHistory = headers => id => axios
  .get(`${API_URL}/account/member/${id}`, { headers });

const updateMemberDetails = headers => data => axios
  .post(`${API_URL}/Member/updateMemberDetails`, data, { headers });

const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");

const getMemberQRCode = headers => id => axios
  .get(`${API_URL}/Member/getMemQRCode/${cryptr.encrypt(id)}`, { headers });

const getAllImages = headers => () => axios
  .get(`${API_URL}/Images/getAll`, { headers });

const getPartnerLogo = headers => (storeId,logo) => axios
  .get(`${API_URL}/Images/GetPartnerLogo?storeId=${storeId}&logo=${logo}`, { headers });
  
const uploadProfilePicture = headers => (id, picture) => axios
  .post(`${API_URL}/MemberProfilePictures/addProfilePicture`,
    { ProfilePicture: picture, MemNo: id }, { headers }
  );

const getPayfastDetails = headers => () => axios
  .get(`${API_URL}/PayFastDetails`, { headers });

const getMembershipTypes = headers => (id) => axios
  .get(`${API_URL}/Partners/GetMembershipTypes?storeId=${id}`, { headers });

const getPartnerReport = headers => (id) => axios
  .get(`${API_URL}/Stores/GetPartnerReport?id=${id}`, { headers });
  
const getMemberDetailsByEmail = headers => memEmail => axios
  .get(`${API_URL}/Member/getMemByEmail/${memEmail}`, { headers });

const sendLoginEmail = () => emailData => axios({
	url: `${API_URL}/AutomatedEmail/SendLoginEamil`,
	method: "post",
	data: emailData
  })

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getMember: getMember(headers),
    getMemberByMemNo: getMemberByMemNo(headers),
    resetMemberPassword: resetMemberPassword(headers),
    getMemberProfilePicture: getMemberProfilePicture(headers),
    getMemberLoyaltyDays: getMemberLoyaltyDays(headers),
    getMemberAccountHistory: getMemberAccountHistory(headers),
    updateMemberDetails: updateMemberDetails(headers),
    getMemberQRCode: getMemberQRCode(headers),
    getAllImages: getAllImages(headers),
    getPartnerLogo: getPartnerLogo(headers),
    uploadProfilePicture: uploadProfilePicture(headers),
    getPayfastDetails: getPayfastDetails(headers),
    getMemberDetailsByEmail: getMemberDetailsByEmail(headers),
    getMemNoByIdNo:getMemNoByIdNo(headers),
	  sendLoginEmail: sendLoginEmail(),
    getMembershipTypes:getMembershipTypes(headers),
    getPartnerReport:getPartnerReport(headers),
  };
}