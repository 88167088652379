import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewNewsLetter from '../../components/NewsLetter/ViewNewsLetter';
import Container from '../../hoc/Container';
import NewsLetterService from '../../services/newsLetter.service';
import { format } from 'date-fns';
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

class NewsLetter extends Component {
  constructor(props) {
    super(props);
    this.NewsLetterService = NewsLetterService(this.props.token);
  	this.state = {
      newsLetterList: []}
  }

  componentDidMount() {
    this.NewsLetterService.getAllNewsLetter().then(resp =>{
      this.setState({newsLetterList : resp.data});
    })
    .catch(ex =>{
      console.log(ex);
    })
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Newsletter">
       {!this.state.loading && <ViewNewsLetter 
          newsLetterList={this.state.newsLetterList.map(item => {
            let date = new Date(item.date);
            item.date = date.getDay() +" " + month[date.getMonth()]  +" " + date.getFullYear()
            return item;
          })}
          memNo={this.props.memNo}
          isMobile={isMobile}
          history = {this.props.history}
        />  }
      </Container>
    );
  }
}

export default connect(null,null)(NewsLetter);