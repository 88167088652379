import Axios from "axios";
import * as EndPoints from "../../../../API/APIendpoint";

export const approveDSApplication = async (dsno, status, reason) => {
  const config = {
    url: `${EndPoints.BASE}${
      EndPoints.APPROVE_DS_APPLICATION
    }${dsno}/${status}`,
    method: "post",
    data: JSON.stringify(reason),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };

  return await Axios(config);
};

export const sendApprovalEmailDS = async emailParam => {
  const config = {
    url: EndPoints.BASE + EndPoints.SEND_EMAIL_NEW_DSSPORT_SHOOTER_URL,
    method: "post",
    data: emailParam,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };
  return await Axios(config);
};

export const sendApprovalEmailDH = async emailParam => {
  const config = {
    url: EndPoints.BASE + EndPoints.SEND_EMAIL_NEW_DS_HUNTER_URL,
    method: "post",
    data: emailParam,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };
  return await Axios(config);
};

export const sendRejectDSEmail = async emailParam => {
  const config = {
    url: EndPoints.BASE + EndPoints.SEND_DEDICATED_REJ,
    method: "post",
    data: emailParam,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
    }
  };
  return await Axios(config);
};


