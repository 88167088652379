import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";

const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 1,
    flexDirection: "col",
    borderBottom: "2px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    display: "inline-block",
    fontSize: 14,
    color: "black"
  }
};

class Account extends Component {
  handleSubmit = e => {
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.paymentType,
      "paymentType"
    );
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.recieptNumber,
      "recieptNo"
    );
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.invoiceNumber,
      "invoiceNo"
    );
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.paymentDate,
      "paymentDate"
    );
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.paymentDate.substring(0, 4),
      "year"
    );
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.paymentMethod,
      "paymentMethod"
    );
    this.props.onAddAccountDetailsChange(
      `R ${this.props.accountArray.amountPaid}`,
      "amountPaid"
    );
    this.props.onAddAccountDetailsChange("1", "currentAccountTab");
  };

  handleView = e => {
    this.props.onAddAccountDetailsChange(
      this.props.accountArray.paymentType,
      "paymentType"
    );
    this.props.onAddAccountDetailsChange("1", "currentAccountTab");
  };
  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );

    return (
      <div
        className="container pb-4 border-bottom"
        style={{ cursor: "pointer" }}
        onClick={this.handleSubmit}
      >
        {info("Payment Type:", this.props.accountArray.paymentType)}
        {info("Amount Paid:", this.props.accountArray.amountPaid)}
        {info(
          "Payment Date:",
          this.props.accountArray.paymentDate.substring(0, 10)
        )}
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    accountDetails: state.accountReducer.account
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Account));
