import React from "react";
import { isMobile } from "react-device-detect";
import { FRONT } from "../../../API/APIendpoint";
export let fileUploadWidth = "";
if (isMobile) {
  fileUploadWidth = "documents-list-item col-8 p-4 mt-0 mb-0 ml-3";
} else {
  fileUploadWidth = "documents-list-item col-11 p-4 mt-0 mb-0 ml-3";
}
function DefaultOrSelectFiles(props) {
  return (
    <div>
      {props.file.map((list, Index) => {
        return (
          <div key={Index}>
            {props.selectItems === true ? (
              <label className="row checkLabel filterButtons">
                <input
                  value={list.downloadId}
                  className="co-1"
                  type="checkbox"
                  onChange={props.itemFunction}
                />
                <div className="label-text align-self-center" />
                <div className={fileUploadWidth}>
                  <div className="h6 ml-6">{list.name}</div>
                  <div>{list.discription}</div>
                </div>
              </label>
            ) : (
              <div className="documents-list-item">
                <a
                  className="text-dark anchor-removeLine col-12 mb-2 mt-2"
                  href={FRONT + list.link}
                  target="_"
                >
                  <div className="pl-4 pr-4">
                    <div className="h6">{list.name}</div>
                    <div>{list.discription}</div>
                  </div>
                </a>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
export default DefaultOrSelectFiles;
