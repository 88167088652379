import {
  FETCH_MEMBER_DATA_ERROR,
  SET_MEMBER_DATA,
  SET_MEMBER_PROFILE_PICTURE,
  SET_MEMBER_DEDICATED_STATUS,
  SET_MEMBER_LOYALTY_DAYS,
  SET_MEMBER_ACCOUNT_HISTORY,
  SET_MEMBER_DEDICATED_STATUS_ACTIVITIES,
  SET_PAYFAST_DETAILS,
  EMAIL_SET_MEMBER_DATA_2,
  EMAIL_FETCH_MEMBER_DATA_ERROR_2
} from './types';

const fetchMemberData = service => id => dispatch => service.getMember(id)
  .then(response => dispatch({ type: SET_MEMBER_DATA, payload: response.data }))
  .catch(error => dispatch({ type: FETCH_MEMBER_DATA_ERROR, payload: error.message }));

const fetchMemberProfilePicture = service => id => dispatch => service.getMemberProfilePicture(id)
  .then(response => dispatch({ type: SET_MEMBER_PROFILE_PICTURE, payload: response.data[0].profilePicture }))
  .catch(() => dispatch({ type: SET_MEMBER_PROFILE_PICTURE, payload: '' }));

const fetchMemberDedicatedStatus = service => id => dispatch => service.getMemberDedicatedStatusType(id)
  .then(response => dispatch({ type: SET_MEMBER_DEDICATED_STATUS, payload: response.data }))
  .then(() => service.getDedicatedStatusActivities(id))
  .then(response => dispatch({ type: SET_MEMBER_DEDICATED_STATUS_ACTIVITIES, payload: response }))
  .catch(() => dispatch({ type: SET_MEMBER_DEDICATED_STATUS, payload: '' }));

const fetchMemberLoyaltyDays = service => id => dispatch => service.getMemberLoyaltyDays(id)
  .then(response => dispatch({
    type: SET_MEMBER_LOYALTY_DAYS,
    payload: response.data}))
  .catch(() => dispatch({ type: SET_MEMBER_LOYALTY_DAYS, payload: { daysEarned: {}, loyaltyHistory: [] } }));

const fetchMemberAccountHistory = service => id => dispatch => service.getMemberAccountHistory(id)
  .then(response => dispatch({ type: SET_MEMBER_ACCOUNT_HISTORY, payload: response.data }))
  .catch(() => dispatch({ type: SET_MEMBER_ACCOUNT_HISTORY, payload: [] }));

const fetchPayfastDetails = service => dispatch => service.getPayfastDetails()
  .then(response => dispatch({ type: SET_PAYFAST_DETAILS, payload: response.data }))
  .catch(() => dispatch({ type: SET_PAYFAST_DETAILS, payload: [] }));

const fetchMemberDetailsByEmail = service => memEmail => dispatch => service.getMemberDetailsByEmail(memEmail)
  .then(response => dispatch({ type: EMAIL_SET_MEMBER_DATA_2, payload: response.data }))
  .catch(() => dispatch({ type: EMAIL_FETCH_MEMBER_DATA_ERROR_2, payload: {} }));

export default function(memberService, dedicatedStatusService) {
  return {
    fetchMemberData: fetchMemberData(memberService),
    fetchMemberProfilePicture: fetchMemberProfilePicture(memberService),
    fetchMemberDedicatedStatus: fetchMemberDedicatedStatus(dedicatedStatusService),
    fetchMemberLoyaltyDays: fetchMemberLoyaltyDays(memberService),
    fetchMemberAccountHistory: fetchMemberAccountHistory(memberService),
    fetchPayfastDetails: fetchPayfastDetails(memberService),
    fetchMemberDetailsByEmail: fetchMemberDetailsByEmail(memberService)
  };
}
