import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { BASE, GET_ACCOUNT_LIST } from "../../API/APIendpoint.js";
import { UPDATE_ACCOUNT_LIST } from "../../store/actions/actionTypes";
import MainAccount from "../loggeddInUser/account/MainAccount";
import Payfast from "../loggeddInUser/account/Payfast";
import PaymentView from "../loggeddInUser/account/PaymentView";
function TabPane(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabPane.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "#FFF"
  }
});

class PendingMemberViewTabPane extends React.Component {
  state = {
    value: 1
  };
  componentDidMount() {
    if (!this.props.userProfileDetails.isLoggedIn) {
      this.props.history.push("/");
    }

    axios.get(BASE + GET_ACCOUNT_LIST + this.props.memberNumber,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      var accounts = res.data;
      this.props.onUserAccountChange(accounts);
    });
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  loadAccountTab = () => {
    switch (this.props.currAccountTab) {
      case "0":
        return <MainAccount />;
      case "1":
        return <PaymentView />;
      case "2":
        return <Payfast />;
      default:
        return <MainAccount />;
    }
  };

  render() {
    const { value } = this.state;
    const tabs = [
      "Profile",
      "My Account",
      "Membership Card",
      "Courses",
      "Dedicated Status",
      "Endorsement",
      "Activity",
      "License Reminders",
      "Documents"
    ];

    const ErrorPage = ({ percent, total, bColour }) => {
      return (
        <div className="container">
          <div className="row">
            <div
              className="col-12 text-danger pt-4 font-weight-bold"
              align="center"
              style={{ fontSize: "16px" }}
            >
              Your membership is not active, or you have not yet renewed your
              membership. Please proceed to pay and renew to gain access to your
              profile.
            </div>
          </div>
        </div>
      );
    };
    return (
      <div
        className="container p-0"
        style={{
          maxWidth: "2180px"
        }}
      >
        <div className="col-12 p-0 m-0 ">
          <AppBar
            position="static"
            color="default"
            style={{
              boxShadow:
                "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
              borderBottom: "2px solid #e5e2e8",
              backgroundColor: "#fcfcfc"
            }}
          >
            <Tabs
              value={value}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab} />
              ))}
            </Tabs>
          </AppBar>
        </div>
        <div className="row p-0 m-0 ">
          <div className="col-12 p-0">
            {value === 0 && <ErrorPage />}
            {value === 1 && this.loadAccountTab()}
            {value === 2 && <ErrorPage />}
            {value === 3 && <ErrorPage />}
            {value === 4 && <ErrorPage />}
            {value === 5 && <ErrorPage />}
            {value === 6 && <ErrorPage />}
            {value === 7 && <ErrorPage />}
            {value === 8 && <ErrorPage />}
          </div>
        </div>
      </div>
    );
  }
}

PendingMemberViewTabPane.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    currActivityTab: state.membersAreaReducer.membersArea.currentActivityTab,
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    currAccountTab: state.accountReducer.account.currentAccountTab,
    currLmsTab: state.lmsReducer.currLmsTab,
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    currNewsTab: state.articlesReducer.articles,
    userProfileDetails: state.loginReducer.userProfileDetails,
    activityMemberNumber:
      state.activitiesParametersReducer.activityMemberNumber,
    activityIdNumber: state.activitiesParametersReducer.activityID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserAccountChange: newUserAccountArray =>
      dispatch({
        type: UPDATE_ACCOUNT_LIST,
        payload: newUserAccountArray
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PendingMemberViewTabPane)));
