import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';

import {
  IconLabelButton,
  SubtitleBar
} from '../index';
import {
  formatISODateToLocaleString,
  getStatusColor
} from '../../shared/helpers';


const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  summarySpacing: {
    marginBottom: '16px'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
});
class ViewEndorsement extends Component {
  constructor(props) {
    super(props);

  }

  gridItem = (title, value, isMobile) => (
    <Grid
      item
      container
      xs={12}
      sm={4}
      md={4}
      spacing={1}
      direction="column"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant={isMobile ? 'h6' : 'body2'} align="left">{value || '-'}</Typography>
      </Grid>
      <Grid item>
        <Typography align="left">{title || '-'}</Typography>
      </Grid>
    </Grid>
  );
  
  render() {
    const {classes} = this.props;

    const formatStatus = item => 
      <span className={getStatusColor(item)}>{item}</span>;

    const isMobile = localStorage.getItem('isMobile') === 'true';
  
    return(
      <React.Fragment>
        <SubtitleBar
          title="Endorsement Details"
          back
          onBack={this.props.backCallback}
        />
       {this.props.endorsement.status == 'Approved'?
              <Grid item align="right">
                <IconLabelButton 
                  full 
                  actionText="Download Endorsement Document"
                  callBack={() => this.props.downloadCallback()}
                />
              </Grid>:
              null}
              <br/>
        <Grid container justify="center">
          <Grid item container xs={12} md={12} spacing={4} alignItems="center">
            <Grid  item xs={12}>
              <Card className={classes.root}>
                <CardContent>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
                    <Grid item xs={12}>
                      <Typography variant="h4" color="primary">
                        {this.props.endorsement.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider/>
                    </Grid>
                    <Grid item container xs={12} spacing={4}>
                      {this.gridItem('Endorsement Number', this.props.endorsement.endorsementNo, isMobile)}
                      {this.gridItem('Calibre', this.props.endorsement.caliber, isMobile)}
                      {this.gridItem('Status', formatStatus(this.props.endorsement.status), isMobile)}
                      {this.gridItem('Endorsement Type', this.props.endorsement.applicationType, isMobile)}
                      {this.gridItem('Serial Number', this.props.endorsement.serialNumber, isMobile)}
                      {this.gridItem('Type of Firearm', this.props.endorsement.fireArmType, isMobile)}
                      {this.gridItem('Model', this.props.endorsement.model, isMobile)}
                      {this.gridItem('Application Type', this.props.endorsement.applicationType, isMobile)}
                      {this.gridItem('Section', this.props.endorsement.section, isMobile)}
                      {this.gridItem('Application Date', formatISODateToLocaleString(this.props.endorsement.approvalDate), isMobile)}
                      {this.gridItem('Make', this.props.endorsement.make, isMobile)}
                      {this.gridItem('Motivation', this.props.endorsement.motivation, isMobile)}
                      {this.gridItem('Rejection Reason', this.props.endorsement.reason, isMobile)}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

ViewEndorsement.defaultProps = {
  backCallback: () => null,
  endorsement: {},
  downloadCallback: () => null,
};

ViewEndorsement.propTypes = {
  backCallback: PropTypes.func.isRequired,
  endorsement: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewEndorsement);