import React, { Component } from 'react';
import { 
  Typography, 
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  GenericTable,
  Loader,
  Overview,
  SubtitleBar,
  ViewActivity,
} from '../index';
import {
  formatISODateToLocaleString,
  getStatusColor,
  valid,
} from '../../shared/helpers';
import { 
  editableCalendarConfig,
  editableRadioInputConfig,
  editableSelectConfig,
} from '../../shared/constants';
import { updateObject } from '../../shared/utility';

const formatStatus = item => 
  <span className={getStatusColor(item)}>{item}</span>;

const columns = [
  {
    field: 'activityDate',
    headerName: 'Activity Date',
    sortable: true,
    format: (item) => formatISODateToLocaleString(item), 
    sortFunction: (itemData, sortOrder) => itemData
      .sort((a, b) => sortOrder * new Date(b.activityDate) - sortOrder * new Date(a.activityDate)),  
  },
  { field: 'activityDescription', headerName: 'Discipline'},
  { field: 'firearmType', headerName: 'Firearm Type' },
  {
    field: 'status',
    headerName: 'Activity Status',
    format: formatStatus,
  },
];

const columnsMobile = [
  {
    field: 'activityDate',
    headerName: 'Date',
    sortable: true,
    format: (item) => formatISODateToLocaleString(item), 
    sortFunction: (itemData, sortOrder) => itemData
      .sort((a, b) => sortOrder * new Date(b.activityDate) - sortOrder * new Date(a.activityDate)),  
  },
  { field: 'activityDescription', headerName: 'Discipline'},
];

const initDisciplineFilter = (value, items) => ({
  title: 'Discipline Type',
  value: value,
  id: 'select-discipline',
  name: 'discipline',
  options: {
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      // ...valid(value, editableSelectConfig.config.validate), remove forced checking of selection
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
      touched: true
    }
  }
});

const initRadioFilter = (id, name, title, value, items) => ({
  title,
  value,
  id,
  name,
  options: {
    ...editableRadioInputConfig,
    editable: true,
    config: {
      ...editableRadioInputConfig.config,
      ...valid(value, editableRadioInputConfig.config.validate),
      elementConfig: {
        ...editableRadioInputConfig.config.elementConfig,
        radios: items,
        name,
      },
      touched: true
    }
  }
});

const initCalendarFilter = (id, name, title, value, minDate) => ({
  title,
  value,
  id,
  name,
  options: {
    ...editableCalendarConfig,
    config: {
      ...editableCalendarConfig.config,
      ...valid(value, {
        ...editableCalendarConfig.config.validate,
        maxDate: null,
        minDate,
      }),
      touched: true
    }
  }
});

class MyActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: {
        organisation: 'all',
        type: 'all',
        start: null,
        end: new Date(),
        discipline: '-',
      },
      defaulFilterValue : {        
        organisation: 'all',
        type: 'all',
        start: null,
        end: new Date(),
        discipline: '-'
    },
      disciplineItems: [],
      activities: [],
      viewActivity: false,
      activity: {},
      loading: false,
    };
  }

  static getDerivedStateFromProps(nextProps,state) {
    let __activities = [];
    if((state.activities.length  < nextProps.activities.length) && JSON.stringify(state.defaulFilterValue) != JSON.stringify(state.filterValue))
      __activities = state.activities;
    else
      __activities = nextProps.activities;
    
    return { activities: __activities };
  }

  updateFilterValue = (obj) => {
    this.setState({
      filterValue: updateObject(this.state.filterValue, obj),
    });
  }

  filterOptions = (organisation, type, start, end, discipline) => {
    const elements = [
      {
        ...initRadioFilter(
          'radio-org', 'organisation', 'Activity Organisation',
          organisation,
          [ 
            { value: 'narfo', label: 'Narfo'},
            { value: 'other', label: 'Other'},
            { value: 'all', label: 'All'},
          ]
        ),
        onChange: (e) => this.updateFilterValue({
          organisation: e.target.value, discipline: '-'
        }),
      },
      {
        ...initRadioFilter(
          'radio-act-type', 'type', 'Activity Type',
          type,
          [ 
            { value: 'hunt', label: 'Hunting'},
            { value: 'sport', label: 'Sport Shooting'},
            { value: 'all', label: 'All'},
          ]
        ),
        onChange: (e) => this.updateFilterValue({type: e.target.value}),
      },
      {
        ...initCalendarFilter(
          'cal-start', 'start', 'Start Date',
          start, null
        ),
        onChange: (date) => this.updateFilterValue({start: date}),
      },
      {
        ...initCalendarFilter(
          'cal-end', 'end', 'End Date',
          end, new Date(start)
        ),
        onChange: (date) => this.updateFilterValue({end: date}),
      },
    ];
    if (this.state.filterValue.organisation !== 'all') {
      let disciplineItems = [];
      if (this.state.filterValue.organisation === 'other') {
        disciplineItems = [...this.props.disciplineFilterItems.disciplineTypes];
      } else {
        disciplineItems = [...this.props.disciplineFilterItems.eventTypes];
      }
      disciplineItems.push({value: 'none', label: 'None'});
      elements.push({
        ...initDisciplineFilter(discipline, disciplineItems),
        onChange: (e) => this.updateFilterValue({discipline: e.target.value}),
      });
    }
    return {
      elements,
      callBack: this.filterActivities,
    };
  };
  
  filterActivities = (filter) => {
    if(!filter){
      this.setState({...this.state,activities : this.props.activities,filterValue: this.state.defaulFilterValue});
      return 
    }
    let _activities = this.props.activities;
    if (filter.organisation && filter.organisation !== 'all') {
      _activities = _activities.filter(
        a => a.association ? a.association.toLowerCase() === filter.organisation : false
      );
    }
    if (filter.type && filter.type !== 'all') {
      _activities = _activities.filter(
        a => a.activityType ? 
          a.activityType.toLowerCase().includes(filter.type) : false
      );
    }
    if (filter.discipline && filter.discipline !== '-' && filter.discipline !== 'none') {
      _activities = _activities.filter(
        a => a.activityDescription ? 
          a.activityDescription.toLowerCase() === filter.discipline.toLowerCase() : false
      ); 
    }
    if (filter.start) {
      _activities = _activities.filter(a => {
        const actDate = new Date(a.activityDate);
        actDate.setHours(0,0,0,0);
        const startDate = filter.start;
        startDate.setHours(0,0,0,0);
        return actDate >= startDate;
      });
    }
    if (filter.end) {
      _activities = _activities.filter(a => {
        const actDate = new Date(a.activityDate);
        actDate.setHours(0,0,0,0);
        const endDate = filter.end;
        endDate.setHours(0,0,0,0);
        return actDate <= endDate;
      });
    }
    this.setState({...this.state, activities : _activities});
  };

  activityListForMoblile = () => {
    let newList = this.state.activities;
    newList.map(item =>{
      if(item.activityDescription.includes(' - ')) {
        let arr = item.activityDescription.split(' ');
        if(arr[0] === 'CFHG' || arr[0] === 'CFRBA') {
          item.activityDescription = `${arr[0]} ${arr[arr.length - 3]} ${arr[arr.length - 1]}`;
        } else {
          item.activityDescription = `${arr[0]} ${arr[arr.length - 1]}`;
        }
      } else if(item.activityDescription === 'Firearm Related Conference or Workshop') {
        item.activityDescription = 'FRCoW';
      } else if(item.activityDescription === 'Hunting Related Conference or Workshop') {
        item.activityDescription = 'HRCoW';
      }
    });
    return newList;
  };

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      this.state.loading && <Loader /> ||
      this.state.viewActivity && <ViewActivity
        backCallback={() => this.setState({activity: {}, viewActivity: false})}
        activity={this.state.activity}
      /> ||
      <React.Fragment>
        <table id="auto-table" className="d-none"></table>
        <Overview>
          <Typography>
          Use the activity section to records and keep track of your activities. 
          Click the plus button to add a new activity. You can add NARFO activities 
          or you can add activities from other associations. You can also print 
          an activity report to supplement your firearm license application as 
          proof of being an active shooter. You can also use the filter button 
          to filter only specific activities.
          </Typography>
          <br />
          <Typography color="error">
            No activity will be approved if the uploaded document does not have a
            date on it. Please add a date on every document before uploading.
          </Typography>
          <br />
          {isMobile &&(<Grid container direction='column'>
            <Typography>HRCoW - Hunting Related Conference or Workshop</Typography>
            <Typography>FRCoW - Firearm Related Conference or Workshop</Typography>
          </Grid>)}
        </Overview>
        <SubtitleBar
          title={isMobile ? 'Activity' : 'Activity History'}
          downloadActivity
          filter
          defaulFilterValue = {this.state.defaulFilterValue}
          filterOptions={this.filterOptions(
            this.state.filterValue.organisation,
            this.state.filterValue.type,
            this.state.filterValue.start,
            this.state.filterValue.end,
            this.state.filterValue.discipline,
            this.state.disciplineItems
          )}
          downloadActivityCallback={
            () => this.props.downloadActivityCallback(this.state.activities.filter(a => a.status == "Approved"),'#auto-table')
          }
          addActivityCallback={this.props.addActivityCallback}
        />
        <GenericTable
          columns={isMobile ? columnsMobile : columns}
          rows={isMobile ? this.activityListForMoblile() : this.state.activities}
          view
          onViewCallBack={(item) => {
            this.setState({loading: true });
            this.props.getActivityCallback(item.activityId)
              .then(res => this.setState({ activity: res.data }))
              .catch(() => this.setState({activity: item}))
              .finally(() => this.setState({
                viewActivity: true,
                loading: false
              }));
          }}
        />
      </React.Fragment>
    );
  }
}

MyActivities.propTypes = {
  activities: PropTypes.array.isRequired,
  downloadActivityCallback: PropTypes.func.isRequired,
  disciplineFilterItems: PropTypes.shape({
    eventTypes: PropTypes.array.isRequired,
    disciplineTypes: PropTypes.array.isRequired,
  }).isRequired,
  addActivityCallback: PropTypes.func.isRequired,
  getActivityCallback: PropTypes.func.isRequired,
};

MyActivities.defaultProps = {
  activities: [],
  downloadActivityCallback: () => Promise.resolve(null),
  disciplineFilterItems: {
    eventTypes: [],
    disciplineTypes: [],
  },
  addActivityCallback: () => null,
  getActivityCallback: (id) => Promise.resolve({id}),
};

export default MyActivities;
