import * as actionTypes from "../actions/actionTypes";

const initialState = {
    loginToRessetPassword: "1",
    landingPageContentToggle: 0,
    aboutUsToggler: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOGIN_PAGE_TOGGLER:
      return {
        ...state,
        loginToRessetPassword: action.loginToRessetPassword
      };
    case actionTypes.UPDATE_LANDING_PAGE_CONTENT:
      return {
        ...state,
        landingPageContentToggle: action.landingPageContentToggle
      };
    case actionTypes.UPDATE_ABOUT_US_CONTENT:
      return {
        ...state,
        aboutUsToggler: action.aboutUsToggler
      };
    default:
      return state;
  }
};

export default reducer;
