import React from 'react';
import {
  FormControl,
  FormHelperText,
  Grid, Typography,
} from '@material-ui/core';
import {
  MappedInput,
} from '../index';
import PropTypes from 'prop-types';

const EditableGridLabel = ({id ,title, value, edit, stretch, onChange, options, direction, variant='outlined', isMobile}) => (
  <Grid item xs={12} sm={stretch ? 12 : 3} container direction={direction}>
    { edit && options.editable ? 
      <div className="d-flex pl-3 w100">
        <FormControl 
          variant={variant}
          fullWidth
          error={!options.config.valid}
        >
          <MappedInput
            id={id}
            elementConfig={options.config.elementConfig}
            elementType={options.config.elementType}
            value={value}
            invalid={!options.config.valid}
            shouldValidate={options.config.validate}
            touched={options.config.touched}
            changed={onChange}
          />
          { !options.config.valid ? options.config.errors
            .map((errorText, key) => <FormHelperText key={key}>{errorText}</FormHelperText>) : null
          }
        </FormControl>
      </div> :
      <div className="pt-4">
        <Typography variant={isMobile ? 'h6' : 'body2'} style={{marginLeft:'20px'}} align="left" className="word-break">
          {value || '-'}
        </Typography>
      </div>
    }
    <Typography align="left" style={{marginLeft:'20px'}}>{title || '-'}</Typography>
  </Grid>
);

EditableGridLabel.defaultProps = {
  direction: 'column',
  stretch: false,
};

EditableGridLabel.propTypes = {
  direction: PropTypes.oneOf([
    'column',
    'column-reverse'
  ]),
  stretch: PropTypes.bool.isRequired,
};

export default EditableGridLabel;
