import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Radio from "@material-ui/core/Radio";
import { BASE, GET_SALESREP_LIST, GET_STORE_LIST } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { ValidatorForm } from "react-material-ui-form-validator";
import Progress from "./memberApplicationProgress";
import "./styles/memberDetailsStyles.css";
import { NextPageButton, PreviousPageButton } from "./navigatorButtons";

class SalesDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Store_list: [],
      CallResult: [],
      labelWidth: 0,
      isLoaded: false,
      UserID: "",
      children: ["", "", ""],
      nextPage: false,
      showError: false,
      refBySalesRep: "NM-0011",
      refByPartner: "Narfo Head Office"
    };
  }

  handleOptionChange = event => {
    this.setState({ CurrentMember_Associaton: event.target.value });
  };

  handleChange = (name, index) => event => {
    let array = this.state.children;
    array[index] = event.target.value;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(event.target.value, name);
    this.checkNotEmpty();
  };

  componentWillUnmount() {
    this.props.onMemberDetailsChange(this.state.children, "salesChildren");
  }

  componentWillMount() {
    this.setState({
      children: this.props.memDetails.salesChildren
        ? this.props.memDetails.salesChildren
        : this.state.children
    });
  }

  checkNotEmpty = () => {
    let isNextPage = true;
    this.setState({
      showError: false
    });
    const _children = this.state.children;
    _children.map(child => {
      if (
        child === "" ||
        child === null ||
        child === undefined ||
        child === "Select a Sales Represantative" ||
        child === "Select a Store"
      ) {
        isNextPage = false;
      }
      return null;
    });

    this.setState({
      nextPage: isNextPage
    });
  };

  handlePolicyOption = name => event => {
    this.props.onMemberDetailsChange(event.target.checked, name);
  };

  componentDidMount() {
    let array = this.state.children;
    array[0] = "NM-0011";
    array[1] = "Narfo Head Office";
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange("NM-0011", "SalesRep");
    this.props.onMemberDetailsChange("Narfo Head Office", "Store");
    this.checkNotEmpty();
    fetch(BASE + GET_SALESREP_LIST, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    })
      .then(res => res.json())
      .then(data => {
        this.setState({
          isLoaded: true,
          CallResult: data
        });
      });
    fetch(BASE + GET_STORE_LIST, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    })
      .then(res => res.json())
      .then(DataTransfer => {
        this.setState({
          isLoaded: true,
          Store_list: Array.from(DataTransfer)
        });
      });
  }

  handleInputChange = event => {
    event.preventDefault();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleRefBySalesRepChange = changeEvent => {
    changeEvent.preventDefault();
    this.setState({refBySalesRep: changeEvent.target.value});
  };
  handleRefByPartnerChange = changeEvent => {
    changeEvent.preventDefault();
    this.setState({refByPartner: changeEvent.target.value});
  };
  render() {
    return (
      <div className="member-details-root" style={{ marginTop: "80px" }}>
        <Progress progress={4} />
        <div className="member-details-form ">
          <ValidatorForm ref="form" align="center" autoComplete="off">
            <p className="mb-2">
              We are almost done with the last step. In order to effectively provide you with the necessary assistance and support, 
              please complete the last two questions.
            </p>
            <br />

            <div className="member-details-text-input-parent ethnicity">
              <div className="member-details-radio-input m-0">
                <label>Were you referred by a sales representative</label>
                <span>
                  <Radio
                    checked={this.state.refBySalesRep === "NM-0011"}
                    onChange={e =>{this.handleRefBySalesRepChange(e)}}
                    onClick={this.handleChange("SalesRep", 0)}
                    value="NM-0011"
                  />
                  <text>No</text>
                </span>
                <span>
                  <Radio
                    checked={this.state.refBySalesRep === "Yes"}
                    onChange={e =>this.handleRefBySalesRepChange(e)}
                    value="Yes"
                  />
                  <text>Yes</text>
                </span>
              </div>
              {this.state.refBySalesRep === "Yes" ?
              <React.Fragment>
                <label> Sales Representative </label>
                <div className="member-details-text-input">
                  <FormControl variant="Outlined">
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="Outlined-Sales-native-simple"
                    />
                    <Select
                      name="SalesRep"
                      native
                      label="Sales Representative"
                      value={this.props.memDetails.Sales_Reps}
                      onChange={this.handleChange("SalesRep", 0)}
                      input={
                        <OutlinedInput
                          name="SalesRep"
                          labelWidth={this.state.labelWidth}
                          id="OutlinedSalesNativeSimple"
                        />
                      }
                    >
                      <option>Select a Sales Represantative</option>
                      {this.state.CallResult.map(name => (
                        <option value={`${name.memNoNavigation.memNo}`}>{`${name
                          .memNoNavigation.firstname +
                          " " +
                          name.memNoNavigation.surname}`}</option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </React.Fragment> : null}
            </div>
            <div className="member-details-text-input-parent ethnicity">
              <div className="member-details-radio-input m-0">
                <label>Were you referred by a Partner</label>
                <span>
                  <Radio
                    checked={this.state.refByPartner === "Narfo Head Office"}
                    onChange={e =>this.handleRefByPartnerChange(e)}
                    onClick={this.handleChange("Store", 1)}
                    value="Narfo Head Office"
                  />
                  <text>No</text>
                </span>
                <span>
                  <Radio
                    checked={this.state.refByPartner === "Yes"}
                    id="refByPartnerSelect"
                    onChange={e =>this.handleRefByPartnerChange(e)}
                    value="Yes"
                  />
                  <text>Yes</text>
                </span>
              </div>
              {this.state.refByPartner === "Yes" ?
              <React.Fragment>
                <label> Partner </label>
                <div className="member-details-text-input">
                  <FormControl variant="Outlined">
                    <InputLabel
                      ref={ref => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="OutlinedStoreNativeSimple"
                    />
                    <Select
                      native
                      label="Stores"
                      name="Stores"
                      value={this.props.memDetails.Store}
                      onChange={this.handleChange("Store", 1)}
                      input={
                        <OutlinedInput
                          name="Store"
                          labelWidth={this.state.labelWidth}
                          id="OutlinedStoreNativeSimple"
                        />
                      }
                    >
                      <option>Select a Partner</option>
                      {this.state.Store_list.map(name => (
                        <option value={name.store}>{name.store}</option>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </React.Fragment> : null}
            </div>
            <br />
            <div className="member-details-radio-input m-0">
              <label>Are you affiliated with another association</label>
              <span>
                <Radio
                  checked={
                    this.props.memDetails.CurrentMember_Associaton === "Yes"
                  }
                  onChange={this.handleChange("CurrentMember_Associaton", 2)}
                  value="Yes"
                  name="radio-button-Yes"
                  aria-label="Yes"
                />
                <text>Yes</text>
              </span>
              <span>
                <Radio
                  checked={
                    this.props.memDetails.CurrentMember_Associaton === "No"
                  }
                  onChange={this.handleChange("CurrentMember_Associaton", 2)}
                  value="No"
                  name="radio-button-No"
                  aria-label="No"
                />
                <text>No</text>
              </span>
            </div>
            <br />
            {this.state.showError ? (
              <p class="text-danger">Please select all fields</p>
            ) : null}
            <div className="nav-button-parent">
              <Link to="/Declaration">
                <PreviousPageButton />
              </Link>
              {this.state.nextPage ? (
                <Link to="/loginDetails">
                  <NextPageButton />
                </Link>
              ) : (
                  <NextPageButton
                    onClick={() => {
                      this.setState({
                        showError: true
                      });
                    }}
                  />
                )}
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      }),
    onPostedMemberDetails: value =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS_FROM_DB,
        payload: value
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesDetails);
