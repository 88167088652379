import {
  SET_FIREARM_LICENCE_STATUS,
  SET_FIREARM_LICENCE_LIST,
  SET_FIREARM_LICENCE_RECORD,
  SET_FIREARM_LICENCE_APPLICATION_LIST
} from './types';

const INITIAL_STATE = {
  firearmLicenceStatus: {},
  firearmLicence: [],
  firearmLicenceRecord: {},
  firearmLicenseApplications : []
};


const reducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
  case SET_FIREARM_LICENCE_STATUS:
    return {
      ...state,
      firearmLicenceStatus: action.payload,
    };
  case SET_FIREARM_LICENCE_LIST:
    return {
      ...state,
      firearmLicence: action.payload
    };
  case SET_FIREARM_LICENCE_RECORD:
    return {
      ...state,
      firearmLicenceRecord: action.payload
    };

  case SET_FIREARM_LICENCE_APPLICATION_LIST:
    return {
      ...state,
      firearmLicenseApplications: action.payload
    };

  default:
    return state;
  }
};

export default reducer;