import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import { 
  SET_FIREARM_LICENCE_RECORD,
  SET_FIREARM_LICENCE_LIST
} from '../../store/Licence/types';
import { Grid } from '@material-ui/core';
import {
  IconLabelButton,
  Loader,
} from '../index';
import {
  checkValidity,
  updateObject,
  initSelect
} from '../../shared/utility';
import {
  editableTextInputConfig
} from '../../shared/constants';
import PropType from 'prop-types';
import EditableGridLabel from '../EditableGridLabel/EditableGridLabel';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();
class ViewLicenseReminders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseReminder: {},
      licenseReminderResponse: {},
      editDetails: false,
      loading: false,
      controls: this.initControls()
    };
  }

  componentDidMount() {
    let __firearmReminder = this.props.firearmLicenceRecord;
    let expiryDate = new Date(__firearmReminder.expiryDate);
    let issueDate = new Date(__firearmReminder.issueDate)

    if (issueDate)
    __firearmReminder.issueDate = format(new Date(__firearmReminder.issueDate.split('T')[0]),'yyyy/MM/dd');
    else
      console.log("issued Date : " + __firearmReminder.issuedDate + " cannot be converted. Just display as is");

    if (expiryDate)
      __firearmReminder.expiryDate = format(new Date(__firearmReminder.expiryDate.split('T')[0]),'yyyy/MM/dd');
    else
      console.log("Expiry Date : " + __firearmReminder.expiryDate + " cannot be converted. Just display as is");
    
    this.setState({ licenseReminder: __firearmReminder });
  }

    initControls = () => ({
      firearm: {...editableTextInputConfig},
      serialNumber: {...editableTextInputConfig},
      issueDate: {...editableTextInputConfig},
      make: {...editableTextInputConfig},
      licenseNo: {...editableTextInputConfig},
      expiryDate: {...editableTextInputConfig},
      caliber: {...editableTextInputConfig},
      section: initSelect(this.props.section),
      description: {...editableTextInputConfig},
    });

    editableGridLable = (title, name, stretch, isMobile) => (
      <EditableGridLabel 
        title={title}
        value={this.state.licenseReminder[name]}
        options={this.state.controls[name]}
        edit={this.state.editDetails}
        onChange={this.handleLabelChange(name)}
        stretch={stretch}
        isMobile={isMobile, false, isMobile}
      />
    );

    handleLabelChange = controlName => event => {
      const newValue = event.target.value;
      const validity = checkValidity (newValue, this.state.controls[controlName].config.validate);
      const updatedControls = updateObject(this.state.controls[controlName], {
        config: updateObject(this.state.controls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true,
        })
      });
      let formHasErrors = false;
      const controls = {
        ...this.state.controls,
        [controlName]: updatedControls,
      };
      Object.keys(controls).forEach(key => {
        if(!controls[key].config.valid) {
          formHasErrors = true;
        }
      });
      this.setState({
        controls,
        licenseReminder: {
          ...this.state.licenseReminder,
          [controlName]: newValue
        },
        formHasErrors,
      });
    };

    handleSubmit = () => {
      const data = {
        firearm: this.state.licenseReminder.firearm,
        serialNumber: this.state.licenseReminder.serialNumber,
        issueDate: this.state.licenseReminder.issueDate,
        make: this.state.licenseReminder.make,
        licenseNo: this.state.licenseReminder.licenseNo,
        expiryDate: this.state.licenseReminder.expiryDate,
        caliber: this.state.licenseReminder.caliber,
        section: this.state.licenseReminder.section,
        description: this.state.licenseReminder.description
      };

      this.setState({ loading: true, editDetails: false });
      this.props.updateLicenseReminderCallback(data)
        .then(res => {
          this.props.onLicenseRecordUpdate(res.data);

        let tempList = this.props.firearmLicence;
        let updatedObjectIndex = this.props.firearmLicence.findIndex(element => element.licenseNo == res.data.licenseNo);
        
        tempList[updatedObjectIndex] = {...res.data};
          
        //Update the list with new one 
        this.props.onLicenseRecordListUpdate(tempList);
        })
        .finally(() => this.setState({loading: false}));
    }

    cancelForm = () => {
      this.setState({
        licenseReminder: {...this.props.firearmLicenceRecord},
        editDetails: false,
        formHasErrors: true,
        controls: this.initControls(),
      });
    }
    
    render() {
      const isMobile = localStorage.getItem('isMobile') === 'true';
      return  (
        <React.Fragment>
          <SubtitleBar 
            variant="subtitle1"
            title="Details"
            edit={!this.state.editDetails}
            callBack={() => this.setState({editDetails:true})}
            remove={true}
            back
            onBack={this.props.backCallback}
            deleteLicenseReminderCallback={() => {
              this.props.deleteLicenseReminderCallback(this.state.licenseReminder.licenseNo)
                .then(res => {
                  if(res.data.isFound){
                    this.props.onLicenseRecordListUpdate(
                      this.props.firearmLicence.filter(element => element.licenseNo !== res.data.licensedFirearm.licenseNo)
                    ); 
                    toast.success(res.data.message);
                  } else {
                    toast.error(res.data.message);
                  }
                })
                .finally(this.props.backCallback);
            }}
          />
          <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
            <Grid container spacing={4}>
              {this.editableGridLable('Firearm', 'firearm', false, isMobile)}
              {this.editableGridLable('Serial Number', 'serialNumber', false, isMobile)}
              {this.editableGridLable('Issue date', 'issueDate', false, isMobile)}
              {this.editableGridLable('Expiry date', 'expiryDate',false, isMobile)}
              {this.editableGridLable('Make', 'make', false, isMobile)}
              {this.editableGridLable('License number', 'licenseNo', false, isMobile)}
              {this.editableGridLable('Caliber', 'caliber', false, isMobile)}
              {this.editableGridLable('Section', 'section', false, isMobile)}
              {this.editableGridLable('Notes', 'description', true, isMobile)}
            </Grid>
          </div>
          {this.state.editDetails && <div className="d-flex py-2">
            <IconLabelButton editButton={true} disabled={this.state.formHasErrors} actionText="Save Changes" color="primary" callBack={this.handleSubmit}/>
            <IconLabelButton editButton={true}  actionText="cancel" callBack={this.cancelForm}/>
          </div>}
          {this.state.loading && <Loader color="primary" />}
          
        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => ({
  firearmLicence: state.LicenceReducer.firearmLicence,
  firearmLicenceRecord: state.LicenceReducer.firearmLicenceRecord
});

const mapDispatchToProps = dispatch => ({
  onLicenseRecordUpdate: response => dispatch({ type: SET_FIREARM_LICENCE_RECORD, payload: response }),
  onLicenseRecordListUpdate: newList => dispatch({ type: SET_FIREARM_LICENCE_LIST, payload: newList })
});

ViewLicenseReminders.defaultProps = {
  backCallback: () => null,
  licenseReminder: {},
  updateLicenseReminderCallback: data => Promise.resolve(data)
};

ViewLicenseReminders.protoTypes = {
  licenseReminder: PropTypes.shape({
    firearm: PropType.string,
    serialNumber: PropType.string,
    issueDate: PropType.string,
    make: PropType.string,
    licenseNo: PropType.string,
    expiryDate: PropType.string,
    caliber: PropType.string,
    section: PropType.string,
    description: PropType.string
  }).isRequired,
  updateLicenseReminderCallback: PropTypes.func.isRequired,
  backCallback: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewLicenseReminders);