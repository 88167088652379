import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import jsPDF from "jspdf";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE, GET_DEDICATEDTYPE, POST_DEDICATED_STATUS_VIEW } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { STATUS } from "../../store/constantFile/ConstFile";
import GenericHeader from "../../utils/GenericHeader";
import DedicatedStatusApplication from "../dedicatedStatusApplication/DedicatedStatusApplication";
import * as DSApplicationActions from "../dedicatedStatusApplication/DedicatedStatusApplicationActions";
import "../loggeddInUser/membershipCard/resizer.css";
import DedicatedProfileView from "./dedicatedProfileView";
import DedicatedTimeline from "./dedicatedTimeline";
import { LMS_ACTIONS } from "../../store/actions/actionTypes";
import "./dhunter.css";
const Cryptr = require("cryptr");
class DedicatedProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "Dedicated Status",
      PDFSign: "",
      pdflogo: "",
      nnfo: "",
      date: "",
      index: "",
      qrCodeWithMemDet: ""
    };
    this.handleBack = this.handleBack.bind(this);
    this.handleView = this.handleView.bind(this);
  }
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    let empty = [];
    const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
    axios
      .get(BASE + "api/Member/getMemQRCode/" + cryptr.encrypt(this.props.memNo),{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
  )
      .then(resp => {
        const qrFinal = "data:image/jpeg;base64," + resp.data.base64QRString
        this.setState({
          qrCodeWithMemDet: qrFinal
        })
      });
    this.props.onDedicatedChange(empty, "Application");
    this.props.onDedicatedChange("Home", "Page");
    const user = {
      memNo: this.props.memberState.isAdmin
        ? this.props.MemberV.memNo
        : this.props.userProfileDetails.memberNumber
    };
    axios({
        url: BASE + POST_DEDICATED_STATUS_VIEW,
        data: user,
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        if (res.data.status === "success") {
          this.props.onDedicatedChange(res.data.dedicated, "Application");
        }
      })
      .catch(function(error) {
        if (error.status === 400);
      });
    axios.get(BASE + GET_DEDICATEDTYPE + this.props.memNo,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
).then(res => {
      this.props.DSApplicationActions.setDSVariables("DH", res.data.hunting);
      this.props.DSApplicationActions.setDSVariables("DS", res.data.sport);
      this.props.DSApplicationActions.setDSVariables("DS_DH", res.data.both);
    });
  }
  DateFind(dated) {
    if (!dated) {
      return dated;
    }
    var date = new Date(Date.parse(dated));
    var currentDate = date;
    var dater = currentDate.getDate();
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var monthDateYear = dater + " " + months[month] + " " + year;
    return monthDateYear;
  }
  DateExpire(dated) {
    if (!dated) {
      return dated;
    }
    var date = new Date(Date.parse(dated));
    var year = date.getFullYear();
    var expire = "31 December " + year;
    return expire;
  }
  handleView = key => {
    this.setState({
      Title: "Dedicated Status Application"
    });
    this.props.onDedicatedChange("View", "Page");
    this.props.onDedicatedChange(
      this.props.dedicated.Application[key],
      "DedicatedStatusApplication"
    );
  };
  handleBack = e => {
    if (this.props.dedicated.Page === "timeline") {
      this.setState({ Title: "Dedicated Status Application" });
      this.props.onDedicatedChange("View", "Page");
    } else {
      this.setState({ Title: "Dedicated Status" });
      this.props.onDedicatedChange("Home", "Page");
    }
  };
  handleApplication = e => {
    this.props.onDedicatedChange("Application", "Page");
    this.setState({ Title: "Dedicated Status Application" });
  };
  handleTimeline = e => {
    this.setState({
      Title: "Dedicated Status Timelines"
    });
    this.props.onDedicatedChange("timeline", "Page");
  };
  render() {
    const _activeDedicatedStatus = this.props.dedicated.Application;
    let fileUploadWidth = "";
    if (isMobile) {
      fileUploadWidth = "btn btn-outline-dark font-weight-bold";
    } else {
      fileUploadWidth = "btn btn-outline-dark font-weight-bold";
    }
    const print = async () => {
        const dedicatedStatus = this.props.dedicated.Application[
          this.state.index
        ]
        await axios.get(BASE + "api/member/" + dedicatedStatus.memNo,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => {
          let pdf = new jsPDF('p', 'mm', [300, 220]);
          pdf.addImage(this.state.qrCodeWithMemDet , "JPEG", 25, 240, 40, 35);
          pdf.addImage(this.props.base64Images.nnfo, "JPEG", 80, 10);
          pdf.setFont("times");
          pdf.setFontSize(14);
          pdf.setFontStyle("bold");
          pdf.text(
            50,
            55,
            "National Association of Responsible Firearm Owners"
          );
          pdf.setFontType("normal");
          pdf.text(90, 65, "South Africa");
          pdf.setFontStyle("bold");
          pdf.setFontSize(20);
          dedicatedStatus.dstype === "Dedicated Hunter"
            ? pdf.text(80, 75, "Dedicated Hunter")
            : pdf.text(70, 75, "Dedicated Sport Person");
          pdf.setFontSize(12);
          pdf.setFontType("normal");
          pdf.text(
            20,
            85,
            "I, the undersigned, Johann Scheffer, ID: 6509285076083, duly appointed by the board of NARFO in"
          );

          pdf.text(
            20,
            92,
            "terms of resolution RL-2018-002, past on 18/04/2018 in my capacity as the Chairman of the association,"
          );
          pdf.text(
            78,
            100,
            "hereby solemnly declare that"
          );
          pdf.setFontStyle("bold");
          pdf.setFontSize(24);
          pdf.setTextColor(255, 0, 0);
          dedicatedStatus.dstype === "Dedicated Hunter"
            ? pdf.text(60, 110, "Dedicated Hunter Status")
            : pdf.text(51, 110, "Dedicated Sport Person Status");
          pdf.setFontType("normal");
          pdf.setFontSize(12);
          pdf.setTextColor(0, 0, 0);
          dedicatedStatus.dstype === "Dedicated Hunter"
            ? pdf.text(75, 117, "Dedicated Hunter No:")
            : pdf.text(70, 117, "Dedicated Sport Person No:");
          pdf.setFontStyle("bold");
          dedicatedStatus.dstype === "Dedicated Hunter"
            ? pdf.text(115, 117, dedicatedStatus.dsno)
            : pdf.text(120, 117, dedicatedStatus.dsno);
          pdf.setFontType("normal");
          pdf.text(86, 125, "has been awarded to:");
          pdf.text(70, 130, "Member Names:");
          pdf.text(70, 135, "Member Number:");
          pdf.text(70, 140, "ID Number:");
          pdf.setFontStyle("bold");
          pdf.text(
            110,
            130,
            res.data.members.firstname + " " + res.data.members.surname
          );
          pdf.text(110, 135, dedicatedStatus.memNo);
          pdf.text(110, 140, res.data.members.idno);
          pdf.setFontStyle("bold");
          pdf.setFontType("normal");
          pdf.setFontSize(10);
          pdf.text(
            18,
            150,
            "who is a member in good standing of the association at the date of print and as per the FCA 60 of 2000 Regulation 4(1)(e)(i)"
          );
          pdf.text(
            18,
            155,
            "and complied with the provisions in terms of, Regulation 4(1)(b)(i)(ii)."
          );
          pdf.text(
            18,
            163,
            "The validity of this certifcate is subject to the maintenance of the awarded status in terms of the provisions set out in"
          );
          pdf.text(
            18,
            168,
            "Annexure (D) of the National Association of Responsible Firearm Owners Rules and Regulations guide in line with the"
          );
          pdf.text(
            18,
            173,
            "provisions of Regualtion 4(1)(c)(ii) of the Firearms Control Act 60 of 2000"
          );
          pdf.addImage(this.props.base64Images.pdfSign, "PNG", 40, 175, 40, 25);
          pdf.addImage(this.props.base64Images.pdflogo, "PNG", 140, 180, 45, 45);
          pdf.text(40, 200, "Johann Heinrich Scheffer");
          pdf.text(45, 205, "Executive Member");
          pdf.text(25, 210, "(Executive Member and Responsible Person)");
          pdf.setFontSize(9);
          pdf.text(
            20,
            215,
            "Duly appointed by the board in terms of resolution RL-2017-001"
          );
          pdf.text(
            20,
            220,
            "passed on 23/01/2017. The deponent acknowledges that he"
          );
          pdf.text(
            20,
            225,
            "understands and knows the contents of this solemn declaration"
          );
          pdf.text(
            20,
            230,
            "and that he has no objection in taking the declaration and that he"
          );
          pdf.text(
            20,
            235,
            "considers the declaration binding to his conscience"
          );
          pdf.setFontStyle("bold");
          pdf.text(138, 230, "Status awarded on:");
          pdf.text(148, 235, "Printed:");
          pdf.text(165, 230, this.DateFind(dedicatedStatus.approvalDate));
          pdf.text(160, 235, this.DateFind(new Date()));
          pdf.rect(135, 265, 55, 11);
          pdf.text(138, 252, "Hunting Association :");
          pdf.text(142, 257, "Sport Shooting :");
          pdf.setFontType("normal");
          pdf.text(168, 252, "FAR-1300135");
          pdf.text(165, 257, "FAR-1300134");
          pdf.setFontSize(11);
          pdf.text(140, 272, "SAPS Accredited Association");
          pdf.setTextColor(126, 126, 126);
          pdf.text(20, 280, "14 Phesante Close, Graanendal, Durbanville, 7551");
          pdf.text(20, 285, "http://www.narfo.co.za");
          pdf.setTextColor(0, 0, 0);
          pdf.text(135, 285, "NARFO Pty (Ltd) 2012/086864/07");
          pdf.addImage(this.props.base64Images.signature2, "PNG", 70, 240, 30, 30);
          dedicatedStatus.dstype === "Dedicated Hunter"
            ? pdf.save("Narfo Dedicated Hunter Status.pdf")
            : pdf.save("Narfo Dedicated Sport Shooter Status.pdf");
        });
    };
    return (
      <div className={this.props.isPartner ? "" :"container"}>
        <div className="activity-header-container">
          {(!this.props.memberState.isAdmin &&
            this.props.dedicated.Page === "View") ||
          this.props.dedicated.Page === "timeline" ||
          this.props.dedicated.Page === "Application" ? (
            <GenericHeader
              title={this.state.Title}
              showBackButton
              backFunction={() => {
                this.handleBack();
              }}
            />
          ) : this.props.memberState.isAdmin &&
            this.props.dedicated.Page === "View" ? (
            <GenericHeader
              headerColor={this.props.headerColor}
              title={this.state.Title}
              showBackButton
              backFunction={() => {
                this.handleBack();
              }}
              showTimelineButton
              timelineFunction={() => {
                this.handleTimeline();
              }}
            />
          ) : this.props.memberState.isAdmin &&
            this.props.dedicated.Page === "Home" ? (
            <GenericHeader title={this.state.Title} headerColor={this.props.headerColor}/>
          ) : this.props.DSApplicationState.DS_DH === false ? (
            <GenericHeader
              headerColor={this.props.headerColor}
              title={this.state.Title}
              showFilledAddButton
              addFunction={() => {
                this.handleApplication();
              }}
            />
          ) : (
            <GenericHeader title={this.state.Title} headerColor={this.props.headerColor}/>
          )}
        </div>
        {this.props.dedicated.Page === "timeline" && (
          <DedicatedTimeline
            status={this.props.dedicated.DedicatedStatusApplication}
          />
        )}
        {this.props.dedicated.Page === "Home" && (
          <div className={this.props.isPartner ? "pt-2" :"container pt-2"}>
            {!this.props.memberState.isAdmin && (
              <div className="row pl-3 pr-3">
                <p>
                  To apply for a Dedicated Hunter or Sport Shooter Status, you
                  first need to complete the online and practical assessment.
                  Read through the NARFO Dedicated Status (D) found in the
                  download section first. Then go to the{" "}
                  <span className="font-weight-bold"> Courses </span> section in
                  your members profile
                </p>
                <p>
                  Before applying, please carfully read through the NARFO
                  Declaration of Dedicated Status (K) document found in the
                  Downloads section
                </p>
                <p>
                  Your application(s) will not be considered if you merely want
                  more firearms. Becoming a dedicated hunter/sport shooter means
                  that you actively want to participate in hunting/sport
                  shooting activities as well as it is required of you to do
                  activities to keep your status, if you fail to do so, you may
                  lose your status
                </p>
              </div>
            )}
            <div className="row bg-light pt-1 pl-3 mb-2">
              <h6>Dedicated Status Applications</h6>
            </div>
            {_activeDedicatedStatus.length === 0 ?
            <div className="text-warning font-weight-bold">No Dedicated Status Applications yet</div> :
            <div className={this.props.isPartner ? "" :"container"}>
              {_activeDedicatedStatus.map((App, i) => (
                <div className="flex-container pt-3 pb-3 border-bottom font-weight-bold bg-white" key={i}>
                  <div
                    className="col-md-11 pl-0"
                    key={i}
                    onClick={() => {
                      this.handleView(i);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                  <span>{App.dstype}</span> <br />
                  <span>{App.applicationDate.split("T", 1)}</span> <br />
                  <span>
                    {App.status === STATUS.Approved && (
                      <div className="text-success">{App.status}</div>
                    )}
                    {App.status === STATUS.Pending && (
                      <div className="text-warning">{App.status}</div>
                    )}
                    {App.status === STATUS.Declined && (
                      <div className="text-danger">{App.status}</div>
                    )}
                    {App.status === STATUS.Terminated && (
                      <div className="text-danger">{App.status}</div>
                    )}
                  </span>
                </div>
                <div />
                <div className="mr-6">
                  {App.status === "Approved" &&
                    !this.props.memberState.isAdmin && (
                      <button
                        type="button"
                        style={{ marginRight: "40px" }}
                        className={fileUploadWidth}
                        onMouseDown={() => {
                          this.setState({
                            index: i
                          });
                        }}
                        onClick={print}
                      >
                        Certificate
                      </button>
                    )}
                </div>
              </div>))}
            </div>}
          </div>
        )}
        {this.props.dedicated.Page === "View" && (
          <DedicatedProfileView
            isPartner={this.props.isPartner}
            application={this.props.dedicated.DedicatedStatusApplication}
          />
        )}
        {this.props.dedicated.Page === "Application" && (
          <DedicatedStatusApplication />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    dedicated: state.dedicatedStatusReducer.dedicatedStatus,
    userProfileDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView,
    DSApplicationState: state.DSApplicationState,
    memNo: state.loginReducer.userProfileDetails.memberNumber
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
          dispatch({ 
              type: actionTypes.UPDATE_COMMISSION_TAB, 
              currentCommissionTab: newComTab 
          }),
    onDedicatedChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DEDICATED_STATUS,
        varValue: value,
        varName: vname
      }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({ 
          type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
          currentLoyaltyDaysTab: pageNum 
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    DSApplicationActions: bindActionCreators(DSApplicationActions, dispatch)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DedicatedProfile);
