import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MemberDetails from "../memberApplication/MemberDetails";

const styles = theme => ({
  root: {
    height: "100vh"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

class Register extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root} style={{ marginTop: "80px" }}>
        <div>
          <MemberDetails />
        </div>
        <div className="NavFooter" />
      </div>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Register);
