import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BASE, GET_SPECIFIC_USER_ENDORSEMENTS_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
const styles = {
  card: {
    minWidth: "200px"
  },
  root: {
    flex: "2px",
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: "15px",
    width: "10",
    paddingRight: "1px",
    paddingLeft: "1px",
    color: "black"
  },

  content: {
    width: "20%",
    display: "inline-block",
    fontSize: 14,
    color: "black",
    align: "left"
  },

  content1: {
    width: "20%",
    display: "inline-block",
    fontSize: 14,
    color: "black",
    align: "right"
  },
  states: {
    color: "#32CD32"
  }
};

class EndorrsementApplicationView extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      MemNo: props.userProfileDetails.memberNumber
    };
  }

  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    axios
      .get(
        BASE +
          GET_SPECIFIC_USER_ENDORSEMENTS_URL +
          this.props.userProfileDetails.memberNumber,
          {
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res => {
        this.props.onApplicationsChange(res.data);
      });
  }

  componentWillMount() {
    axios
      .get(
        BASE +
          GET_SPECIFIC_USER_ENDORSEMENTS_URL +
          this.props.userProfileDetails.memberNumber,{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res => {
        this.props.onApplicationsChange(res.data);
      });
  }

  render() {
    const DisplayEndorsementList = (
      <div className="container"
          style={{ height: "550px", overflow: "auto" }}>
        {this.props.applications.map((post,i) => (
          <div key={i} className="border-bottom font-weight-bold col-12">
            <div
              className="row pt-3 pb-3 pl-2"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onCurrentEndorsementTabChange("5");
                this.props.onApplicationChange(post);
              }}
            >
              <div className="col-12 m-0 pl-0">{post.title}</div>
              <div className="col-12 m-0 pl-0">
                {`Caliber: ${post.caliber}`}
              </div>
              <div className="col-12 m-0 pl-0">
                {`Make: ${post.make}`}
              </div>
              <div className="col-12 m-0 pl-0">
                {post.status === "Approved" ? (
                  <div className="text-success">Approved</div>
                ) : post.status === "Rejected" ? (
                  <div className="text-danger">Rejected</div>
                ) : (
                  <div className="text-warning">Pending</div>
                )}
              </div>
              {post.status === "Approved" ? 
              <div className="col-12 m-0 pl-0">
              {`Approval Date: ${post.approvalDate.substring(0, 10)}`}
              </div> : null}
            </div>
          </div>
        ))}
      </div>
    );
    return (
      <div className="container">
        <GenericHeader
          title="Endorsements"
          showFilledAddButton
          addFunction={() => {
            this.props.onCurrentEndorsementTabChange("1");
          }}
        />
        <div className="container">
          <div className="row pl-3 pr-3">
            You may apply for an endorsement to accompany your firearm license application. To create a new endorsement, 
            click the new button and fill in the firearm details and motivate the use of the firearm. Once approved, 
            you can print it out and add to it to your application.
          </div>
          <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
            <h6>Endorsement List</h6>
          </div>
        </div>
        {this.props.applications.length === 0
          ? <div className="pl-3 pr-3 text-warning font-weight-bold">No available endorsement applications currently</div>
          : DisplayEndorsementList}
      </div>
    );
  }
}

EndorrsementApplicationView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentEndorsementTab: state.currentEndorsementTab,
    MemberDetails: state.MemberViewReducer.MemberView,
    applications: state.endorsementReducer.applications,
    userProfileDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({ 
          type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
          currentLoyaltyDaysTab: pageNum 
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION,
        application: newApplication
      }),
    onApplicationsChange: newApplications =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATIONS,
        applications: newApplications
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_USER_ENDORSEMENT,
        varValue: value,
        varName: variableName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EndorrsementApplicationView));
