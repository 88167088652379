import React, { Component } from "react";
import { connect } from "react-redux";
import HunterDetails from "./HunterDetails";
import ShooterDetails from "./ShooterDetails";
import Radio from "@material-ui/core/Radio";
class Details extends Component {
  render() {
    const { dsType } = this.props;
    return (
      <div className="container bg-light">
        {this.props.DSApplicationState.DS === true &&
        this.props.DSApplicationState.DS_DH === false ? (
          <div className="col d-flex justify-content-center">
            <Radio
              id="RadioButtonDedicatedHunter"
              color="red"
              checked={dsType === "Dedicated Hunter"}
              onChange={() => this.props.toggleDS("Dedicated Hunter")}
              name="RadioButtonDedicatedHunter"
              aria-label="Dedicated Hunter"
            />
            <div className="mt-2">Dedicated Hunter</div>
          </div>
        ) : this.props.DSApplicationState.DH === true &&
          this.props.DSApplicationState.DS_DH === false ? (
          <div className="col d-flex justify-content-center">
            <Radio
              id="RadioButtonDedicatedSportsPerson"
              checked={dsType === "Dedicated Sports Person"}
              onChange={() => this.props.toggleDS("Dedicated Sports Person")}
              name="RadioButtonDedicatedSportsPerson"
              aria-label="Dedicated Sports Person"
            />
            <div className="mt-2">Dedicated Sports Person</div>
          </div>
        ) : this.props.DSApplicationState.DS_DH === false ? (
          <div className="row mb-2 mt-2">
            <div className="col d-flex justify-content-center">
              <Radio
                id="RadioButtonDedicatedHunter"
                checked={dsType === "Dedicated Hunter"}
                onChange={() => this.props.toggleDS("Dedicated Hunter")}
                name="RadioButtonDedicatedHunter"
                aria-label="Dedicated Hunter"
              />
              <div className="mt-2">Dedicated Hunter</div>
            </div>
            <div className="col d-flex justify-content-center">
              <Radio
                id="RadioButtonDedicatedSportsPerson"
                checked={dsType === "Dedicated Sports Person"}
                onChange={() => this.props.toggleDS("Dedicated Sports Person")}
                name="RadioButtonDedicatedSportsPerson"
                aria-label="Dedicated Sports Person"
              />
              <div className="mt-2">Dedicated Sports Person</div>
            </div>
          </div>
        ) : null}

        {(this.props.dsType === "Dedicated Hunter" ||
          this.props.DSApplicationState.DS === true) &&
        this.props.DSApplicationState.DS_DH === false ? (
          <HunterDetails
            DSApplicationState={this.props.DSApplicationState}
            setDSVariables={this.props.setDSVariables}
          />
        ) : (this.props.dsType === "Dedicated Sports Person" ||
            this.props.DSApplicationState.DH === true) &&
          this.props.DSApplicationState.DS_DH === false ? (
          <ShooterDetails
            DSApplicationState={this.props.DSApplicationState}
            setDSVariables={this.props.setDSVariables}
          />
        ) : (
          <HunterDetails
            DSApplicationState={this.props.DSApplicationState}
            setDSVariables={this.props.setDSVariables}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    DSApplicationState: state.DSApplicationState
  };
};

export default connect(mapStateToProps)(Details);
