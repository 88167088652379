import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar
} from '../index';
import { Grid, Typography, Button } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import Article from './Article';

const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  }
});

const columns = [
  { field: 'date', headerName: 'Date' },
  { field: 'title', headerName: 'Title'}
];

class ViewNewsLetter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLetterList: props.newsLetterList,
      viewArticles: true,
      selectedArticle: null
    };

    console.log(props.newsLetterList);
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;

    return(
      <React.Fragment>
         {
          this.state.viewArticles ?<>
            {/* <SubtitleBar variant="subtitle1" title={''} /> */}
              {this.props.newsLetterList.length !== 0 ?
                <GenericTable 
                  columns={columns}
                  rows={this.props.newsLetterList}
                  view
                  onViewCallBack={item => {
                    this.setState({ 
                      viewArticles: !this.state.viewArticles,
                      selectedArticle : item
                    });
                  }}
                /> : null}</>
                : 
                <Article 
                  article={this.state.selectedArticle}
                  goBack={() => this.setState({ viewArticles: true })}
                />
         }

      </React.Fragment>
    );
  }
}


export default connect(null, null)(withStyles(styles)(ViewNewsLetter));