import * as actionTypes from "./../actions/actionTypes";

const initialState = {
  signupDetails:{
    CellNo: null,
    City: null,
    CoC: null,
    CriminalRecord: null,
    CurrentMember_Associaton: null,
    DC: null,
    declaractionAcceptance: null,
    DocTrue: null,
    ElectronicComms: null,
    Email: null,
    Ethinicity: null,
    FireArmStorage: null,
    FireArms_Control:null,
    Firstname: null,
    IDNo: null,
    Occupation: null,
    Password: null,
    PasswordConfirm: null,
    PhysicalAddress: null,
    PostalCode: null,
    Province: null,
    SalesRep: null,
    Sex: null,
    Store: null,
    Suburb:null,
    Surname: null,
    Username: null
  },
  onSumbitError: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_SIGNUP_DETAILS:
      const newMemberDetails = {
        ...state.signupDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        signupDetails: newMemberDetails
      };

    case actionTypes.UPDATE_SIGNUP_DETAILS_FROM_DB:
     return {
       ...state,
       signupDetails: action.payload
     }
     case actionTypes.UPDATE_REG_SUBMIT_ERROR_MESSAGE:
      return {
        ...state,
        onSumbitError: action.value
      }
    default:
      return state;
  }
};

export default reducer;
