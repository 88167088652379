import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE } from "../../../API/APIendpoint";
import { CHECKOUT_DETAILS_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";

class PayFastDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MerchantId: "",
      MerchantKey: "",
      Amount: 0,
      ItemName: "",
      AmountError:"",
      PayFastMode:""
    };
  }

  componentDidMount() {
    Axios.get(BASE + "api/PayFastDetails",{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const payFastDetails = res.data[0];
      payFastDetails.itemName === "PayFast Sandbox" ?
      this.setState({
        PayFastMode: "PayFast Sandbox"
      }) :
      this.setState({
        PayFastMode: "PayFast"
      })
      this.props.onPayFastDetailsChange(payFastDetails);
    });
  }
  handleEditPayFastSandBoxDetails = async () => {
    const Cryptr = require("cryptr");
    const cryptr = new Cryptr("myTotalySecretKey");
    this.setState({
      PayFastMode: "PayFast Sandbox"
    })
    if(this.state.Amount !== 0){
      const config = {
        method: "post",
        url: BASE +"api/PayFastDetails/" + this.props.details.payFastDetailsID,
        data: {
          PayFastDetailsID: 1,
          MerchantId: cryptr.encrypt("10000100"),
          MerchantKey: cryptr.encrypt("46f0cd694581a"),
          Amount: this.state.Amount,
          ItemName: "PayFast Sandbox"
        },
        headers: {"Access-Control-Allow-Origin": "*",'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`}
      }
      await Axios(config)
      .then(res =>{
        alert(res.data.message);
      })
    }else{
      alert("Please fill Amount field")
    }
  };
  handleEditPayFastDetails = async () => {
    const Cryptr = require("cryptr");
    const cryptr = new Cryptr("myTotalySecretKey");
    this.setState({
      PayFastMode: "PayFast"
    })
    if(this.state.MerchantId !== "" && this.state.MerchantKey !== "" && this.state.Amount !== 0 && this.state.ItemName !== ""){
      const config = {
        method: "post",
        url: BASE +"api/PayFastDetails/" + this.props.details.payFastDetailsID,
        data: {
          PayFastDetailsID: 1, //Change this add new or edit the actual partner
          MerchantId: cryptr.encrypt(this.state.MerchantId),
          MerchantKey: cryptr.encrypt(this.state.MerchantKey),
          Amount: this.state.Amount,
          ItemName: this.state.ItemName
        },
        headers: {"Access-Control-Allow-Origin": "*",'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`}
      }
      await Axios(config)
      .then(res =>{
        alert(res.data.message);
      })
    }else{
      alert("Please fill in all the fields below")
    }
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleAmountChange = event =>{
    this.setState({Amount: event.target.value});
  }
  render() {
    return (
      <div className="container" style={{ marginTop: "70px" }}>
        <form>
          <GenericHeader title="Configure Payfast Details" />
          <div className="form-group">
            <div className="row">
              <div className="col-lg-6 col-sm-12 mb-3">
                <label>Merchant ID</label>
                <input
                  id="id"
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                      MerchantId: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                />
              </div>
              <div className="col-lg-6 col-sm-12 mb-3">
                <label>Merchant KEY</label>
                <input
                  id="key"
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                      MerchantKey: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <label>Amount</label>
                <input
                  id="amount"
                  type="number"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                      Amount: a.target.value
                    });
                  }}
                  onChange={this.handleAmountChange}
                />
                <label
                 className="text"
                >{this.state.AmountError}</label>
              </div>
              <div className="col-lg-6 col-sm-12">
                <label>Item Name</label>
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  onInput={a => {
                    this.setState({
                      ItemName: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                />
              </div>
              </div>
              <div className="font-weight-bold text-warning">{`Current State: ${this.state.PayFastMode} Mode`}</div>
              <div className="col-12 mt-5">
                <button
                  type="button"
                  className="btn btn-warning text-white float-right"
                  onClick={this.handleEditPayFastDetails}
                >
                  PayFast
                </button>
                <button
                  type="button"
                  className="btn btn-warning text-white float-left"
                  onClick={this.handleEditPayFastSandBoxDetails}
                >
                  Sandbox
                </button>
              </div>
          </div>
        </form>
      </div>
    );
  }
}
PayFastDetails.propTypes = {
  // details: Proptypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    details: state.checkoutDefaultsReducer.payfastObj
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPayFastDetailsChange: obj =>
      dispatch({
        type: CHECKOUT_DETAILS_ACTIONS.UPDATE_PAY_FAST_DETAILS,
        payfastObj: obj
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayFastDetails);
