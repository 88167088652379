import React, { Component} from "react";
import Add from "@material-ui/icons/Add";
import "./menuStyles.css";

export default class MainMenu extends Component {
  render() {
    return (
      <div className="popup-menu-container">
        <div id="createModule" onClick={() => {
          this.props.onClick(1);
        }}>
          <Add id="svgStatic"/>
          <label>{'Create ' + this.props.moduleTypeName} </label>
        </div>
      </div>
    )
  }
}
