import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CustomList from "./CustomList";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
};

class HamburgerMenu extends React.Component {
  state = {
    HamburgerToggled: false
  };

  toggleDrawer = open => () => {
    this.setState({
      HamburgerToggled: open
    });
  };

  render() {
    const headings = this.props.toggleHamburger
      ? [
        "Members",
        "Admin Management",
        "Course Management",
        "Dropdown Management",
        "Emails",
        "News",
        "Notifications",
        "Uploads"
      ]
      : ["About", "Firearm Owners", "Hunting", "Sport Shooting", "View All News"];

    const headings2 = this.props.toggleHamburger
      ? [
        "Members",
        "Admin Management",
        "Course Management",
        "Dropdown Management",
        "Emails",
        "News",
        "Notifications",
        "Uploads",
        "Configure Payfast",
        "Update Loyalty Days",
        "Process Commission Payment",
        "Process Member Payment",
        "DS NonCompliance List",
        "Logout"
      ]
      : ["About", "Firearm Owners", "Hunting", "Sport Shooting", "View All News", "Logout"];

    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
        <CustomList
          heading="Home"
          listOfButtons={
            this.props.userDetails.isLoggedIn === false ? headings : headings2
          }
        />
      </div>
    );

    return (
      <div>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={this.toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          open={this.state.HamburgerToggled}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer(false)}
            onKeyDown={this.toggleDrawer(false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

HamburgerMenu.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    toggleHamburger: state.mainReducer.toggleHamburger,
    userDetails: state.loginReducer.userProfileDetails
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(HamburgerMenu));
