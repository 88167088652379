import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar,
  IconLabelButton
} from '../index';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { printDedicatedStatus } from '../../shared/pdfHelpers';
import DedicatedStatusService from '../../services/dedicatedstatus.service';
import DedicatedStatusActions from '../../store/DedicatedStatus/actions';
import MemberService from '../../services/member.service';

class DedicatedStatusReports extends Component {
  constructor(props) {
    super(props);
    this.dedicatedStatusService = DedicatedStatusService(this.props.token);
    this.dedicatedStatusAction = this.props.dedicatedStatusActions(this.dedicatedStatusService);
    this.memberService = MemberService(this.props.token);
  }
  
  downloadDedicatedStatus = (dedicatedStatusRecord) => {
    return this.memberService.getMemberQRCode(this.props.memNo)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.memberService.getAllImages().then((images) =>
          printDedicatedStatus(
            this.props.memberData, 
            dedicatedStatusRecord, 
            images.data.nnfo, 
            base64QRCode,
            images.data.pdfSign,
            images.data.pdflogo, 
            images.data.signature2,
            images.data.dsSportsShooter,
            images.data.dsHunter
          )
        );
      });
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const DH = this.props.dedicatedStatusList?.find(d => d.dstype == 'Dedicated Hunter');
    const DS = this.props.dedicatedStatusList?.find(d => d.dstype == 'Dedicated Sports Person');

    return(
      <React.Fragment>
          <div className="border-radius-2 py-2 px-4 bg-white-2 mt-4">
            <SubtitleBar
              variant="subtitle2"
              title={'Dedicated Status Reports'}
            />

        <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}>Dedicated Hunter</Typography>
                </Grid>
                { 
                DH != null ?
                  <Grid item xs={12} md={8}>
                  <IconLabelButton
                    actionText="Download"
                    icon={<CloudDownload />}
                    full
                    callBack={() => this.downloadDedicatedStatus(DH)}
                  />
                  </Grid>
                  :
                  <Grid item xs={12} md={8}>
                  <Typography style={{marginTop:'10px'}}><strong style={{fontStyle:'italic'}}>Report not found</strong></Typography>
                  </Grid>
                }

                <Grid item xs={12} md={4}>
                  <Typography style={{marginTop:'10px'}}>Dedicated Sports Shooter</Typography>
                </Grid>
                
                {
                  DS != null?
                  <Grid item xs={12} md={8}>
                  <IconLabelButton
                    actionText="Download"
                    icon={<CloudDownload />}
                    full
                    callBack={() => this.downloadDedicatedStatus(DS)}
                  />
                </Grid>
                  :
                <Grid item xs={12} md={8}>
                <Typography style={{marginTop:'10px'}}><strong style={{fontStyle:'italic'}}>Report not found</strong></Typography>
                </Grid>}
              
              </Grid>

          </div>
      </React.Fragment>
    );
  }
}

const initDedicatedStatusActions = dispatch => dedicatedStatusService => {
    const actions = DedicatedStatusActions(dedicatedStatusService);
    return {
      fetchDedicatedStatusList: data => dispatch(actions.fetchDedicatedStatusList(data))
    };
  };
  
  const mapStateToProps = state => ({
    token: state.AuthReducer.token,
    memNo: state.AuthReducer.memNo,
    dedicatedStatusList: state.nDedicatedStatusReducer.dedicatedStatusList,
    memberData: state.ProfileReducer.memberData,
  });
  
  const mapDispatchToProps = dispatch => ({
    dedicatedStatusActions: initDedicatedStatusActions(dispatch)
  });

export default connect(mapStateToProps, mapDispatchToProps)(DedicatedStatusReports);