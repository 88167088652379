import * as Types from "./storelocatorActionTypes";

const storeLocatorState = {
  branches: [],
  url:
    "https://www.google.com/maps/embed/v1/search?q= CITY &key=AIzaSyC_WhGsGoYjzZiuOCyy4R4nYGNziKWrD68",
  searchString: "",
  city: "CITY"
};

const reducer = (state = storeLocatorState, action) => {
  switch (action.type) {
    case Types.SL_GET_BRANCHES_START:
    case Types.SL_GET_BRANCHES_FAILED:
    case Types.SL_GET_BRANCHES_SUCCESS:
    case Types.SL_UPDATE_BRANCHES:
    case Types.SL_UPDATE_SEARCH_STRING:
    case Types.SL_UPDATE_SEARCH_URL:
      return {
        ...state,
        ...action.storeLocatorState
      };
    default:
      return state;
  }
};

export default reducer;
