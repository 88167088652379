import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_LOGGED_IN_MEMBER } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { CHECKOUT_DETAILS_ACTIONS } from "../../store/actions/actionTypes";
import MembersAreaHeader from "../membersAreaPage/MembersAreaHeader";
import PendingMemberViewTabPane from "../membersAreaPage/PendingMemberViewTabPane";
import Loader from "./Loader";

class PendingMemberView extends Component {
  constructor(props) {
    super(props);
    this.loadMemberArea = this.loadMemberArea.bind(this);
    this.state = {
      loading: true,
      PayFastDetailsID: this.props.details.payFastDetailsID,
      MerchantId: this.props.details.merchantId,
      MerchantKey: this.props.details.merchantKey,
      Amount: this.props.details.amount,
      ItemName: this.props.details.itemName,
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
      PayFastDetailsID: this.props.details.payFastDetailsID,
      MerchantId: this.props.details.merchantId,
      MerchantKey: this.props.details.merchantKey,
      Amount: this.props.details.amount,
      ItemName: this.props.details.itemName,
    })
    axios
      .get(BASE + GET_LOGGED_IN_MEMBER + this.props.member.memberNumber,
        {
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.props.onMemberViewChange(res.data.member);
        this.props.onMemberActiveStatusChange(res.data.dedicatedStatus);
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
      axios.get(BASE + "api/PayFastDetails",{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        const payFastDetails = res.data[0];
        this.props.onPayFastDetailsChange(payFastDetails);
      });
  }

  loadMemberArea() {
    return (
      <div
        className="container"
        style={{
          maxWidth: "2180px",
          marginTop: "60px"
        }}
      >
        <div className="row">
          <div className="col-12 p-0">
            <MembersAreaHeader
              memberDetails={this.props.getLoggedInMember}
              adminView={false}
            />
          </div>
        </div>
        <div className="row pl-0">
          <div className="col-12 p-0">
            <PendingMemberViewTabPane />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loader />
    }
    return <div>{this.loadMemberArea()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    member: state.loginReducer.userProfileDetails,
    getLoggedInMember: state.MemberViewReducer.MemberView,
    memberDetails: state.loginReducer.userProfileDetails,
    details: state.checkoutDefaultsReducer.payfastObj
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
      onPayFastDetailsChange: obj =>
      dispatch({
        type: CHECKOUT_DETAILS_ACTIONS.UPDATE_PAY_FAST_DETAILS,
        payfastObj: obj
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingMemberView);
