import * as actionTypes from "../actions/actionTypes";

const initialState = {
  Options: {
    Section: "",
    ActivityType: "",
    FireArmType: "",
    Optics: "",
    Activity_Event: "",
    Store: "",
    ShootingRange: "",
    SRAddress: "",
    Adress: "",
    Act_Disc: "",

    DeleteSection: "",
    DeleteKey: "",
    DeleteActKey: "",
    DeleteFireKey: "",
    DeleteOpticsKey: "",
    DeleteActEventKey: "",
    DeleteSRangeKey: "",
    DeleteStoreKey: "",
    DeleteActDiscKey: "",
    show: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_OPTIONS:
      const newOptions = {
        ...state.Options,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        Options: newOptions
      };
    default:
      return state;
  }
};
export default reducer;
