import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography, Avatar, TextField } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { BASE, GOSA_HEADER } from "../../../API/APIendpoint";
import { Alert, AlertTitle } from "@material-ui/lab";
import API_URL from "../../services/apiUrl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#007bff",
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PartnerForm() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [idNo, setIdNo] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [idNoError, setIdNoError] = useState("");
  const [contactNoError, setcontactNoError] = useState("");
  const [nameError, setNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [salesReps, setSalesReps] = useState("");
  const [selectedRep, setSelectedRep] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [partnerLogo, setPartnerLogo] = useState("");
  
  const handleChange = (name, event) => {
    // event.preventDefault();
    switch (name) {
      case "name":
        setName(event.target.value);
        break;

      case "lastName":
        setLastName(event.target.value);
        break;

      case "idNo":
        setIdNo(event.target.value);
        break;

      case "contactNo":
        setContactNo(event.target.value);
        break;

      case "email":
        setEmail(event.target.value);
        break;
      default:
        break;
    }

    validate();
  };

  const handleRepChange = (event) => {
    setSelectedRep(event.target.value);
  };

  useEffect(() => {
    getSalesReps();
  }, []);

  const getSalesReps = () => {
    const params = new URLSearchParams(new URL(window.location).search);
    const partnerId = params.get('partnerId');
    setPartnerId(partnerId);

    axios.get(API_URL + "/salesReps/GetSalesRepsByPartnerId/" + partnerId).then((res) => {
      setSalesReps(res.data);
    });

    //Get Partner logo 
    axios.get(API_URL + "/Stores/GetStoreById?id=" + partnerId).then((res) => {
      setPartnerLogo(res.data.logo)
    });
  };

  const onSubmit = (event) => {
    setErrorMessage(false);
    setSuccessMessage(false);

    if (idNo.length === 13) {
      setIdNoError("");
    } else {
      setIdNoError("ID number must be 13 characters");
    }

    if (lastName.length > 1) {
      setLastNameError("");
    } else {
      setLastNameError("Last name is required");
    }

    if (name.length > 1) {
      setNameError("");
    } else {
      setNameError("Member name is required");
    }

    if (email.length > 1 && email.includes('@')) {
      setEmailError("");
    } else {
      setEmailError("Valid email is required");
    }

    if (contactNo.length > 1) {
      setcontactNoError("");
    } else {
      setcontactNoError("Contact number is required");
    }

    if (
      name.length > 1 &&
      lastName.length > 1 &&
      idNo.length == 13 &&
      contactNo.length > 1 &&
      email.length > 1 && email.includes('@')
    )
      setIsValid(true);
    else return;

    

    let payload = {
      firstname: name,
      surname: lastName,
      cellNo: contactNo,
      idno: idNo,
      email: email,
      storeId: partnerId,
      salesRepMemNo: selectedRep,
    };
    
    console.log("Payload", payload)
    
    setOpen(!open);

    axios({
      url: `${BASE}api/Partners/ImportmMembership`,
      method: "post",
      data: payload,
      headers: {
        Authorization: `Bearer ${GOSA_HEADER}`,
      },
    })
      .then(() => {
        setSuccessMessage(true);
        setErrorMessage(false);
        setOpen(false);
        setErrorMessage("");

        setName("");
        setLastName("");
        setIdNo("");
        setContactNo("");
        setEmail("");

      })
      .catch((err) => {
        setSuccessMessage(false);
        setErrorMessage(err?.response?.data);
        console.log("Registration Error ", err?.response?.data);
        setOpen(false);
      });
  };

  const handleIDChange = (field, event) => {
    const value = event.target.value;
    // Set the state
    if (value.length <= 13) {
      setIdNo(value);
    } else {
      // Handle other fields if needed
    }
  };

  const validate = () => {
  };

  return (
    <div className={classes.root}>
      <div className="container text-center" style={{ marginTop: "80px" }}>
              <img
                src={partnerLogo}
                alt="Partner Logo"
                style={{
                  marginTop: '-30px',
                  marginBottom: '10px',
                  width: "15rem",
                  height: "7rem",
                }}
              />
        <Grid container alignItems="center" justify="center">
          <Grid item xs={9} sm={5} md={5} lg={5} xl={5}>
            <div>
              <Typography variant="h5" style={{ marginBottom: "0.3em" }}>
                Please Enter your details
              </Typography>

              <TextField
                id="outlined-full-width"
                label="First Name"
                style={{ margin: 8 }}
                placeholder="Enter your name"
                value={name}
                required
                fullWidth
                margin="normal"
                error={Boolean(nameError)}
                helperText={nameError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("name", e)}
                variant="outlined"
              />

              <TextField
                id="outlined-full-width"
                label="Last Name"
                required
                style={{ margin: 8 }}
                placeholder="Enter your last name"
                error=""
                value={lastName}
                fullWidth
                margin="normal"
                error={Boolean(lastNameError)}
                helperText={lastNameError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("lastName", e)}
                variant="outlined"
              />
              <TextField
                required
                id="outlined-full-width"
                label="ID number"
                style={{ margin: 8 }}
                type="number"
                placeholder="Enter your Identity No"
                error=""
                fullWidth
                margin="normal"
                error={Boolean(idNoError)}
                helperText={idNoError}
                value={idNo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleIDChange("idNo", e)}
                variant="outlined"
              />

              <TextField
                required
                id="outlined-full-width"
                label="Contact Number"
                style={{ margin: 8 }}
                placeholder="Enter your cell number"
                error=""
                fullWidth
                margin="normal"
                value={contactNo}
                error={Boolean(contactNoError)}
                helperText={contactNoError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("contactNo", e)}
                variant="outlined"
              />

              <TextField
                required
                id="outlined-full-width"
                label="Email Address"
                type="email"
                style={{ margin: 8 }}
                placeholder="Enter your email address"
                error=""
                fullWidth
                margin="normal"
                value={email}
                error={Boolean(emailError)}
                helperText={emailError}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => handleChange("email", e)}
                variant="outlined"
              />

              <Select
                fullWidth
                style={{ margin: 8 }}
                variant="outlined"
                value={selectedRep}
                onChange={handleRepChange}
                displayEmpty
                className={classes.selectEmpty}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  <em >Select sales rep</em>
                </MenuItem>
                {salesReps && salesReps.map((item) => (
                  <MenuItem key={item.memNo} value={item.memNo}>
                    {item.firstname} {item.surname}
                  </MenuItem>
                ))}
              </Select>

              <Button
                type="submit"
                fullWidth
                style={{ margin: 8 }}
                variant="contained"
                color="primary"
                onClick={onSubmit}
              >
                Register
              </Button>
            </div>
            {/* <Backdrop className={classes.backdrop} open={open} onClick={handleClose}> */}
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className={(classes.alert, "container text-left")}>
              {errorMessage?.length > 1 && (
                <Alert severity="error">
                  <AlertTitle>Error registration</AlertTitle>
                  <p style={{ fontSize: "18px" }}>{errorMessage}</p>
                </Alert>
              )}

              {successMessage && (
                <Alert severity="success">
                  <AlertTitle>Registration Success</AlertTitle>
                  <p style={{ fontSize: "18px" }}>
                    Congratulations your details has been captured we've sent
                    email to <strong>{email}</strong> for next steps
                  </p>
                </Alert>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
