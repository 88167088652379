export const adminPortalHeaderStyles = {
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "2px",
    paddingLeft: "30px"
  },

  grow: {
    flex: "1px"
  },

  text: {
    paddingLeft: "20px",
    paddingBottom: "5px"
  },

  title: {
    padding: "10px"
  },

  h3: {
    marginleft: "15px"
  },
  
  fileInput: {
    borderBottom: "4px solid lightgray",
    borderRight: "4px solid lightgray",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
    padding: "10px",
    margin: "15px",
    cursor: "pointer"
  },

  imgPreview: {
    textAlign: "center",
    margin: "5px 15px",
    height: "200px",
    width: "500px",
    borderLeft: "1px solid gray",
    borderRight: "1px solid gray",
    borderTop: "5px solid gray",
    borderBottom: "5px solid gray"
  },

  img: {
    width: "100%",
    height: "100%"
  },

  previewText: {
    width: "100%",
    marginTop: "20px"
  },

  submitButton: {
    padding: "12px",
    marginLeft: "10px",
    background: "white",
    border: "4px solid lightgray",
    borderRadius: "15px",
    fontWeight: "700",
    fontSize: "10pt",
    cursor: "pointer"
  },

  hover: {
    background: "#efefef"
  },

  centerText: {
    text: "center",
    width: "500px"
  },

  //National Ranking
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  },

  container: {
    display: "flex",
    flexDirection: "column"
  },

  footer: {
    display: "flex",
    flexDirection: "row"
  },

  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    fullWidth: true,
    flex: "1px",
    margin: "5px"
  },

  grow_: {
    flex: "6px"
  },

  grow1: {
    flex: "4px"
  },

  select: {
    marginTop: "1%",
    margin: "5%",
    width: "30%"
  },

  select2: {
    width: "40%",
    marginLeft: "80%",
    marginTop: "1%",
    marginRight: "20%",
    align: "Right"
  },

  button: {
    margin: "30px",
    padding: "0",
    borderRadius: "20px",
    backgroundColor: "black",
    color: "white",
    display: "inline-block",
    flex: 1,
    width: "13%",
    height: "50px",
  },

  icon: {
    margin: "0px",
    padding: "0px",
    align: "center",
    fontSize: "50px"
  },

  //Avatar styles for uploading a profile picture
  avatar: {
    margin: "10px"
  },

  bigAvatar: {
    margin: "30px",
    width: "160px",
    height: "160px"
  }
};
