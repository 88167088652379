import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { connect } from "react-redux";
import { BASE, GET_SPECIFIC_USER_ENDORSEMENTS_URL, POST_ENDORSEMENTS_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import EndorsementHeaderApplication from "./EndorsementHeaderApplication";
import Radio from "@material-ui/core/Radio";

class EndorsementApplicationDeclaration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      MemNo: props.userProfileDetails.memberNumber,
      errorState:false,
      declAccptChecked: false
    };
  }

  handleChange = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.value, [name]);
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleDeclarationOption = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.checked, name);
  };

  handleCkeckedOption = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.checked, name);
  };
  handleDeclarationAcceptanceChecked = () => {
    this.setState({
      declAccptChecked : !this.state.declAccptChecked
    })
  }
  componentDidMount() {
    axios
      .get(
        BASE +
        GET_SPECIFIC_USER_ENDORSEMENTS_URL +
        this.props.userProfileDetails.memberNumber, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      }
      )
      .then(res => {
        this.props.onApplicationsChange(res.data);
        this.setState({
          Posts: res.data
        });
      });
    this.setState(this.state.inter);
  }

  handleSubmit = e => {
    axios
      .post(`${BASE}api/EndorsementApplications/PostEndorsementApplication?endorsementPurpose=${this.props.endorsementPurpose}&endorsementType=${this.props.EndorsementAppl.endorsementApplicationType}&endorsementSection=${this.props.EndorsementAppl.endorsementSection}`, this.props.newActDetails, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(res => {
        this.props.onApplicationChange(res.data);
        axios
          .get(
            BASE +
            GET_SPECIFIC_USER_ENDORSEMENTS_URL +
            this.props.userProfileDetails.memberNumber, {
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
          }
          )
          .then(res => {
            this.props.onApplicationsChange(res.data);
            this.setState({
              Posts: res.data
            });
          });
      });
    this.props.onCurrentEndorsementTabChange("0");
    this.forceUpdate();
  };

  handleCkeckedOption = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.checked, name);
  };

  componentWillMount() {
    this.forceUpdate();
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (value.length <= 1) {
        return false;
      }
      return true;
    });
  }

  render() {
    return (
      <div className="container">
        <EndorsementHeaderApplication />
        <form>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="motivation" align="left">
                  Briefly decribe and motivate the purpose of this firearm.
                </label>
                <textarea
                  class="form-control"
                  id="motivation"
                  required
                  value={this.props.newActDetails.Motivation}
                  onChange={this.handleChange("Motivation")}
                  rows="6"
                />
                {this.state.errorState ? <p className="text-danger m-0">This field can't be empty</p>:null}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="column">
            <div>I hereby declare that all information in my motivation is true and accurate</div>
              <Radio
                checked={this.state.declAccptChecked}
                onClick={() =>this.handleDeclarationAcceptanceChecked()}
              />
              Yes
            </div>
            {!this.state.declAccptChecked ? <p className="text-danger m-0">Please accept declaration</p>:null}
          </div>
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5">
            <div>
              <button
                type="button"
                className="btn btn-outline-dark text-black float-left"
                onClick={() => this.props.onCurrentEndorsementTabChange("2")}
              >
                Previous
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-warning text-white float-right"
                onClick={() => {
                  
                  if(this.props.newActDetails.Motivation!==""){
                    if(this.state.declAccptChecked){
                        this.handleSubmit();
                      axios
                      .get(
                        BASE +
                        GET_SPECIFIC_USER_ENDORSEMENTS_URL +
                        this.props.userProfileDetails.memberNumber, {
                        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
                      }
                      )
                      .then(res => {
                        this.props.onApplicationsChange(res.data);
                        this.setState({
                          Posts: res.data
                        });
                      });
                      this.props.onRefreshState()
                    }
                  }else{this.setState({errorState:true})}
                }}
              >
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

EndorsementApplicationDeclaration.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    endorsementPurpose: state.EndorsementPurposeReducer.EndorsementPurpose,
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    newActDetails: state.endorsementReducer.addEndorsementDetails,
    MemberDetails: state.MemberViewReducer.MemberView,
    application: state.endorsementReducer.application,
    userProfileDetails: state.loginReducer.userProfileDetails,
    applications: state.endorsementReducer.applications,
    EndorsementAppl: state.EndorsementApplReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADD_ENDORSEMENT_DETAILS,
        varValue: value,
        varName: variableName
      }),
    onApplicationsChange: newApplications =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATIONS,
        applications: newApplications
      }),
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION,
        application: newApplication
      }),
    onAddEndorsementChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_USER_ENDORSEMENT,
        varValue: value,
        varName: variableName
      }),
    onRefreshState: () =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.RESET_APPLICATION
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndorsementApplicationDeclaration);
