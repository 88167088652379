import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import request from "superagent";
import { BASE, GET_DOWNLOADS_URL, GET_UPLOAD_NEW_DOWNLOADS } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import Downloads from "./AdminDownloads";


const styles = theme => ({
  dropzone: {
    height: 80,
    width: "100%",
    backgroundColor: "#f2f2f2",
    margin: 5
  },
  uploadbutton: {
    position: "absolute",
    right: 5
  },
  line: {
    marginTop: 50
  }
});

class AdminDownloadList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      CallResult: [],
      show: false
    };
  }
  showDownloads() {
    this.setState({ show: !this.state.show });
  }

  showDropzone = style => {
    return this.state.show ? (
      <div className={style}>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop files here...</p>
                ) : (
                  <p>
                    Try dropping some files here, or click to select files to
                    upload.
                  </p>
                )}
              </div>
            );
          }}
        </Dropzone>
      </div>
    ) : null;
  };

  onDrop = files => {
    const req = request.post(BASE + GET_UPLOAD_NEW_DOWNLOADS);

    files.forEach(file => {
      req.attach(file.name, file).on("progress", function(e) {
      });
    });

    req.then(res => {
      if (res.statusCode === 200) {
        this.fetchdownloadData();
      } else {
      }
    });
  };

  componentDidMount() {
    axios.get(BASE + GET_DOWNLOADS_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
    }).then(res => {
      
      this.props.onDownloadsChange(res.data, "ListState");
    });
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GenericHeader
          title="Downloads"
          showUploadButton
          showSearchButton
          uploadFunction={() => this.showDownloads()}
          searchFunction={() => alert("needs search functionality")}
        />
        {this.showDropzone(classes.dropzone)}
        {this.props.downloads.ListState.map((name, index) => (
          <Downloads
            key={index}
            id={name.downloadId}
            title={name.name}
            website={name.link}
          />
        ))}
        <div
          style={{ float: "left", clear: "both" }}
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}
AdminDownloadList.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    downloads: state.downloadsReducer.downloads,
    viewPdf: state.downloadsReducer.viewPdf
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDownloadsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DOWNLOADS,
        varValue: value,
        varName: vname
      }),
    onViewPdfChange: payload =>
      dispatch({ type: actionTypes.UPDATE_DOWNLOADS, payload: payload })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminDownloadList));
