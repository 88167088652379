import React, { Component } from "react";
import Message from "./Message";
import Progress from "./Progress";
import SelectFiles from "./SelectFile";
import DefaultOrSelectFiles from "./DefaultOrSelectFiles";
import Radio from "@material-ui/core/Radio";
import * as actionTypes from "../../../store/actions/actionTypes";
import { connect } from "react-redux";
class UploadComponent extends Component {
  constructor(props) {
    super(props);
  }
  handleDocumentTypeChange = changeEvent => {
    changeEvent.preventDefault();
    this.props.onDocumentTypeChange(changeEvent.target.value, "documentType");
  };
  render() {
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div>
          {this.props.headerChange}
          {this.props.comInput("application/*", this.props.fileUpload)}
          {this.props.isUploadSelected ? 
            <div>
              <div>Choose Document Type</div>
              <Radio
                id="RadioButtonGeneral"
                checked={this.props.documentType === "General"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="General"
                name="RadioButtonGeneral"
                aria-label="General"
              />
              General
              <Radio
                id="RadioButtonNarfo"
                checked={this.props.documentType === "Narfo"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="Narfo"
                name="RadioButtonNarfo"
                aria-label="Narfo"
              />
              Narfo
              <Radio
                id="RadioButtonDedicatedStatus"
                checked={this.props.documentType === "Dedicated Status"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="Dedicated Status"
                name="RadioButtonDedicatedStatus"
                aria-label="Dedicated Status"
              />
              Dedicated Status
              <Radio
                id="RadioButtonHunting"
                checked={this.props.documentType === "Hunting"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="Hunting"
                name="RadioButtonHunting"
                aria-label="Hunting"
              />
              Hunting
              <Radio
                id="RadioButtonMember"
                checked={this.props.documentType === "Member"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="Member"
                name="RadioButtonMember"
                aria-label="Member"
              />
              Member
              <Radio
                id="RadioButtonTarget"
                checked={this.props.documentType === "Target"}
                onChange={e =>this.handleDocumentTypeChange(e)}
                value="Target"
                name="RadioButtonTarget"
                aria-label="Target"
              />
              Target
            </div> : null
          }
          {this.props.message ? <Message msg={this.props.message} /> : null}
          {this.props.fileUploads ? (
            <div>
              <div>File Upload Progress</div>
              <Progress percentage={this.props.uploadPercentage} />
            </div>
          ) : null}
          {this.props.comInput("video/*", this.props.videoUpload)}
          {this.props.comInput("image/*", this.props.pictureUpload)}
          {this.props.trash ? (
            <div className="col-lg-12">
              <SelectFiles
                deletedFiles={this.props.deletedFiles}
                itemFunction={this.props.checkDeletedItem}
              />
            </div>
          ) : (
            <div className="col-lg-12">
              <DefaultOrSelectFiles
                selectItems={this.props.selectItems}
                file={this.props.uploadedFile}
                itemFunction={this.props.checkItem}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    documentType: state.fileUploaderReducer.documentType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDocumentTypeChange: (value, vname) =>
      dispatch({
        type: actionTypes.CHANGE_DOCUMENT_TYPE,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadComponent);
