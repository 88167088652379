import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import { withStyles } from "@material-ui/core/styles";
import Visibility from "@material-ui/icons/Visibility";
import Axios from "axios";
import { BASE } from "../../API/APIendpoint";
const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 1,
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    display: "inline-block",
    fontSize: 15,
    color: "black"
  }
};
class AccountsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memPaymentDetails: []
    };
  }
  componentDidMount() {
    Axios.get(BASE + "api/account/member/" + this.props.MemberV.memNo, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(
      res => {
        this.setState({
          memPaymentDetails: res.data
        })
        this.props.onMemViewAcctDetGet(res.data)
      }
      
    );
  }
  DateDifference = (datePart, fromDate, todate) => {
    datePart = datePart.toLowerCase();
    var diff = todate - fromDate;
    var divideBy = { w: 604800000, d: 86400000, h: 3600000, m: 60000, s: 1000 };

    return Math.floor(diff / divideBy[datePart]);
  };
  memberStatus = () => {
    var daysToExpiry = this.DateDifference(
      "d",
      new Date(Date.now()),
      new Date(this.props.MemberV.expiryDate.substring(0, 10))
    );
    return daysToExpiry >= 0 ? (
      <div className="text-success">Approved</div>
    ) : (
        <div className="text-warning">Pending</div>
      );
  };
  isPaymentStillValid = () => {
    return (
      this.props.recentAccountEntry.memNo !== undefined &&
      this.DateDifference(
        "w",
        new Date(this.props.recentAccountEntry.paymentDate.substring(0, 10)),
        new Date(Date.now())
      ) < 52
    );
  };
  handleSubmit = item => {
    if(this.props.isPartner)
      return;

    this.props.onMemberPaymentChange(item);
    this.props.onCurrAccountViewTabChange(2);
  };
  render() {
    const _accountDetails = !this.props.memViewAccountDet ? this.state.memPaymentDetails : this.props.memViewAccountDet; 
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <div className={this.props.isPartner ? "" : "container"}>
        <div className="row">
          <GenericHeader 
          headerColor={this.props.headerColor}
            title="Accounts Details" 
            showDownloadButton={!this.props.isPartner}
            text="Manual Payment"
            downloadFunction={() => this.props.onCurrAccountViewTabChange(3)}
          />
        </div>
        {_accountDetails.length === 0 ?
          <div className="text-warning font-weight-bold">
            No Payment Related Data Found
        </div> : null}
        {
          _accountDetails.map((item, index) => (
            <div
              key={index}
              className={this.props.isPartner ? "" : "container border-bottom"} 
              style={{ cursor: "pointer" }}
              onMouseDown={() => this.handleSubmit(item, index)}
            >
              {info("Payment Type: ", item.paymentType)}
              {info("Date Paid:", item.paymentDate.substring(0, 10))}
              {info("Amount Paid:", `R ${item.amountPaid}`)}
              {this.props.isPartner && <hr/>}
            </div>
          ))
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    recentAccountEntry: state.accountReducer.recentAccountEntry,
    accountDetails: state.accountReducer.account,
    memViewAccountDet: state.accountReducer.memViewAccountDet
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onMemViewAcctDetGet: acctDet =>
      dispatch({
        type: actionTypes.MEM_VIEW_ACCT_DET,
        memViewAccountDet: acctDet
      }),
    onCurrAccountViewTabChange: newCurrAccountViewTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB,
        currAccountViewTab: newCurrAccountViewTab
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onMemberPaymentChange: newMemPayment =>
      dispatch({
        type: actionTypes.MEMBER_PAYMENT_ADD,
        PaymentObject: newMemPayment
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountsView));
