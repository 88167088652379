import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  EditableGridLabel,
  IconLabelButton,
  Loader,
  MappedInput,
  SubtitleBar
} from '../index';
import { 
  Grid, Typography 
} from '@material-ui/core';
import {
  editableCalendarConfig,
  editableTextInputConfig,
  editableNumberInputConfig,
  editableTextBoxInputConfig,
  ASSOCIATIONS,
  ADD_TO_GALLERY
} from '../../shared/constants';
import { checkValidity, updateObject, initSelect , convertToArrayOfObjects} from '../../shared/utility';
import { Attachment, Camera, SystemUpdateAlt } from '@material-ui/icons';

export class AddActivity extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      selectedRadio: ASSOCIATIONS.NARFO,
      controls: this.initControls(),
      radioGroupConfig : {
        elementType: 'radioGroup',
        elementConfig: {
          direction: 'row',
          radios: [
            { value: ASSOCIATIONS.NARFO, label: 'Narfo Activity'},
            { value: ASSOCIATIONS.OTHER, label: 'Other Activity'},
          ],
        },
      },
      selectedGalleryRadio :ADD_TO_GALLERY.NO,
      radioGalleryConfig : {
        elementType: 'radioGroup',
        elementConfig: {
          direction: 'row',
          radios: [
            { value: ADD_TO_GALLERY.YES, label: 'Yes'},
            { value: ADD_TO_GALLERY.NO, label: 'No'},
          ],
        },
      },
      activity: {
        activityEvent: '-',
        activityType: '-',
        activityDate: new Date(),
        firearmType: '-',
        firearmTypeLookup: '-',
        specie: '-',
        farmName:'',
        country: 'South Africa',
        province: '-',
        caliber: '',
        score: 0,
        optics: '-',
        activityDescription: '-', //discipline
        activityNotes: '',
      },
      rankingCheck: '0',
      loading: false,
      formHasErrors: true,
      attachments: [],
      showFilePicker: false,
      isFileUploaded: false,
      displayGalleryInfo:false
    };
    
    this.fileRef = React.createRef();
    this.camRef = React.createRef();

  }

  initializeProvince = () => {
    let _provinces = this.props.countryLookup.find(item =>item.value === "South Africa")?.states;

    return convertToArrayOfObjects(_provinces);
  };

  initControls = () => ({
    firearmType: initSelect(this.props.firearmTypes),
    firearmTypeLookup: initSelect([]),
    activityEvent: initSelect([]),
    activityType: initSelect(this.props.activityTypeLookup),
    province: initSelect(this.initializeProvince()),
    country: initSelect(this.props.countryLookup),
    specie: initSelect(this.props.specieLookup),
    farmName: {...editableTextInputConfig},
    activityDate: {...editableCalendarConfig},
    caliber: {...editableTextInputConfig},
    score: {...editableNumberInputConfig},
    optics: initSelect(this.props.opticsLookup),
    activityDescription: initSelect(this.props.activityDisciplines), //discipline
    activityNotes: {...editableTextBoxInputConfig},
  });
  
  handleRadioChange = (event) => {
    const value = event.target.value;
    let activity = {};
    if (value === ASSOCIATIONS.OTHER) {
      activity = {
        activityEvent: '-',
        activityDescription: '-', //discipline
      };
    } else {
      activity = {
        optics: '-',
        activityDescription: '-', //discipline
      };
    }
    this.setState({
      selectedRadio: value,
      activity: {
        ...this.state.activity,
        ...activity,
      },
      controls: this.initControls(),
    });
  };

  handleRadioGalleryChange = (event) => {
    const value = event.target.value;

    this.setState({
      selectedGalleryRadio: value,
      displayGalleryInfo: !this.state.displayGalleryInfo,
    });
  };

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }
    value = typeof value === 'number' ? value.toString() : value;
    const validity = checkValidity(value === '-' ? '' : value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid && key != 'activityNotes') {
        formHasErrors = true;
      }
    });

    //Filter firearm lookup list 
    if (controlName === 'activityEvent') {
      let options = this.state.controls['activityEvent'].config.elementConfig.options;
      let ranking = '';
      options.forEach(x => 
        x.value === value ? ranking = x.ranking : null
      );

      let _isHunting = false;

      if(value === 'Hunting')
          _isHunting = true;
        
      this.setState({
        isHunting: _isHunting,
        controls,
        activity: {
          ...this.state.activity,
          [controlName]: value,
        },
        rankingCheck: ranking
      });
    } 
    else if(controlName === "country"){
      let _country = event.target.value;
      
      let _provinces = this.props.countryLookup.find(item =>item.value == _country)?.states;
      const updatedControls = initSelect(_provinces.length > 0? convertToArrayOfObjects(_provinces) : []);

      const controls = {
        ...this.state.controls,
        province: updatedControls,
      };

      this.setState({
        controls,
        activity: {
          ...this.state.activity,
          [controlName]: value
        },
        formHasErrors,
      });
    }
    else if(controlName === "firearmType"){
      //update the Firearm lookup dropdown
      let _fireArm = this.props.firearmTypes.find(item =>item.value == event.target.value)
      let _fireArmLookups = this.props.firearmTypeLookup.filter(item =>item.fireArmID ==_fireArm.fireArmTypeId)
      const updatedControls = initSelect(_fireArmLookups.length > 0? _fireArmLookups : []);

      const controls = {
        ...this.state.controls,
        firearmTypeLookup: updatedControls,
      };

      this.setState({
        controls,
        activity: {
          ...this.state.activity,
          [controlName]: value
        },
        formHasErrors,
      });
    }
    else if(controlName === "firearmTypeLookup"){
      //update the Firearm lookup dropdown
      let _fireArmLookupItem = this.props.firearmTypeLookup.find(item => item.value === event.target.value);
      //At the moment there the Not shooting related Firearmtype has 1001 id but the rest of the lookups have none 
      //This creates inconsistencies because none of the lookups will be picked up by "Not shooting related" because instead of having 1001 id they have null
      let _activityTypeLookup = this.props.activityEvent.filter(item =>item.fireArmTypeLookupID == _fireArmLookupItem?.fireArmTypeId)
      const updatedControls = initSelect(_activityTypeLookup.length > 0? _activityTypeLookup : []);
      
      const controls = {
        ...this.state.controls,
        activityEvent: updatedControls,
      };
      this.setState({
        controls,
        activity: {
          ...this.state.activity,
          [controlName]: value
        },
        formHasErrors,
      });
    }
    else {
      this.setState({
        controls,
        activity: {
          ...this.state.activity,
          [controlName]: value,
        },
        formHasErrors,
      });
    }
  };

  label = (title, name, fullWidth, isMobile) => (
    <Grid item xs={12} md={fullWidth ? 12: 6} lg={fullWidth ? 12: 4} container>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.activity[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)}
        isMobile={isMobile}
      />
    </Grid>
  );

  
  labelTerms = (name, fullWidth) => (
    <Grid item xs={12} offset={4} md={fullWidth ? 12: 6} lg={fullWidth ? 12: 4} container>
      <a style={{color:"blue", marginLeft:'20px'}} target="_blank" href="https://portal.narfo.co.za/uploads/Downloads/Docs/NARFO%20Hunting%20Photo%20Gallery%20(P).pdf">
       Read Public Gallery Terms and Conditions
      </a>

    </Grid>
  );


  handleSubmit = () => {

    this.setState({ loading: true });
    let formHasErrors = false;
    let controls = { ...this.state.controls };
    if (this.state.attachments.length === 0) {
      this.setState({ isFileUploaded: true, loading: false });
      return;
    }

    //user does not select the add to gallary
    if(this.state.selectedGalleryRadio === ADD_TO_GALLERY.NO)
    {
      //The Gallaery fields are not required
      Object.keys(this.state.activity).forEach(key => {
        if ((key === 'activityEvent' && this.state.selectedRadio === ASSOCIATIONS.OTHER) || 
        ((key === 'optics' || key === 'activityDescription')&& this.state.selectedRadio === ASSOCIATIONS.NARFO) || key === 'country' || key === 'specie' || key === 'farmName' || key === 'province') {
          return;
        }
        let value = this.state.activity[key];
        value = typeof value === 'number' ? value.toString() : value;
        const validity = checkValidity(
          value === '-' ? '' : value, 
          controls[key].config.validate
        );
        const updatedControls = updateObject(controls[key], {
          config: updateObject(controls[key].config, {
            valid: validity.isValid,
            errors: validity.errors,
            touched: true,
          })
        });
        controls = {
          ...controls,
          [key]: updatedControls,
        };
  
        if (value === '-') {
          formHasErrors = true;
        }
      });
    }
    else
    {
      //If user selcted Yes then all fields are required 
      Object.keys(this.state.activity).forEach(key => {
        if ((key === 'activityEvent' && this.state.selectedRadio === ASSOCIATIONS.OTHER) || 
        ((key === 'optics' || key === 'activityDescription')&& this.state.selectedRadio === ASSOCIATIONS.NARFO)) {
          return;
        }
        let value = this.state.activity[key];
        value = typeof value === 'number' ? value.toString() : value;
        const validity = checkValidity(
          value === '-' ? '' : value, 
          controls[key].config.validate
        );
        const updatedControls = updateObject(controls[key], {
          config: updateObject(controls[key].config, {
            valid: validity.isValid,
            errors: validity.errors,
            touched: true,
          })
        });
        controls = {
          ...controls,
          [key]: updatedControls,
        };
  
        if (value === '-') {
          formHasErrors = true;
        }
      });
    }



    if (!formHasErrors) {
      const activity = {...this.state.activity};
      activity.association = this.state.selectedRadio;
      activity.activityDescription = this.state.selectedRadio === ASSOCIATIONS.OTHER ? activity.activityDescription : activity.activityEvent;
      activity.attachments = this.state.attachments;

      activity.addToGallery = this.state.selectedGalleryRadio;
      activity.addToGallery = this.state.selectedGalleryRadio;
      activity.addToGallery = this.state.selectedGalleryRadio;
      activity.addToGallery = this.state.selectedGalleryRadio;

      activity.firearmType = this.state.activity.firearmTypeLookup;

      this.props.submitActivity(activity);
      this.setState({ loading: false });
      this.props.backCallback();
    } else {
      this.setState({ loading: false, formHasErrors, controls });
    }
  }

  handleFileUpload = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    // Allowing file type
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|pdf|JPG|JPEG|PNG|GIF|PDF)$/);

    if (regex.test(file.name) == false || file.name.includes('tempImage')) {
      alert("Invalid file type, Please convert your image to jpg, jpeg or png")
      return;
    }

    const attachments = this.state.attachments;
    attachments.push({ result: file, name: file.name });
    this.setState({ attachments, showFilePicker: false, isFileUploaded: false });
  };

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        <SubtitleBar
          title="Add Activity"
          back 
          onBack={this.props.backCallback}
        />
        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="pl-3 pt-6">
                <Typography>
                  Activity Type
                </Typography>
                <MappedInput
                  {...this.state.radioGroupConfig} 
                  value={this.state.selectedRadio}
                  changed={this.handleRadioChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} container spacing={4}>
              {this.label('Firearm Type', 'firearmType', false, isMobile)}
              {this.label('Firearm Type Lookup', 'firearmTypeLookup', false, isMobile)}
              {this.state.selectedRadio === ASSOCIATIONS.OTHER ? 
                this.label('Activity Discipline', 'activityDescription', false, isMobile) :
                this.label('Activity Event', 'activityEvent', false, isMobile)
              }
            {/* Gallery questionaire  */}
            {this.state.isHunting && <Grid item xs={12}>
              <div className="pl-3 pt-6">
                  <Typography>
                    Would you like to add your Hunting image to Public Gallery?
                  </Typography>
                  <MappedInput
                    {...this.state.radioGalleryConfig} 
                    value={this.state.selectedGalleryRadio}
                    changed={this.handleRadioGalleryChange}
                  />
                </div>
              </Grid>}

              {this.state.displayGalleryInfo && <>
                {this.labelTerms('Terms', 'Terms', false, isMobile)}
                {this.label('Country', 'country', false, isMobile)}
                {this.label('Province/State', 'province', false, isMobile)}
                {this.label('Farm Name', 'farmName', false, isMobile)}
                {this.label('Specie', 'specie', false, isMobile)}
              </>}


              {this.label('Activity Type', 'activityType', false, isMobile)}
              {this.label('Activity Date', 'activityDate', false, isMobile)}
              {this.label('Caliber', 'caliber', false, isMobile)}
              {this.state.selectedRadio === ASSOCIATIONS.OTHER &&
                this.label('Optics', 'optics', false, isMobile)
              }     
              {this.state.selectedRadio === ASSOCIATIONS.OTHER ? 
                this.label('Score', 'score', false, isMobile) :
                this.state.rankingCheck == '1' ?
                  this.label('Score', 'score', false, isMobile) :
                  null
              } 
              
            </Grid>
            {this.label('Activity Notes', 'activityNotes', true)}
            <Grid item xs={12} container>
              <input 
                accept='image/png, image/jpg, image/jpeg, image/bmp, .pdf' // 
                type="file"
                className="d-none"
                ref={this.fileRef}
                onChange={this.handleFileUpload}
              />
              <input
                accept='image/*'
                type='file'
                capture='environment'
                className="d-none"
                ref={this.camRef}
                onChange={this.handleFileUpload}
              />
              <Grid item xs={12}>
                {!this.state.showFilePicker && <IconLabelButton
                  disabled  = {this.state.attachments.length > 0} 
                  full
                  actionText="Attach Document"
                  icon={<SystemUpdateAlt />}
                  callBack={() => this.setState({showFilePicker: true})}
                />}
                {this.state.showFilePicker && 
                  <div className="d-flex justify-center">
                    <IconLabelButton
                      full
                      actionText="Camera"
                      icon={<Camera />}
                      callBack={() => this.camRef.current.click()}
                    />
                    <IconLabelButton
                      full
                      actionText="Document"
                      icon={<Attachment />}
                      callBack={() => this.fileRef.current.click()}
                    />
                  </div>
                }
              </Grid>
              {this.state.attachments.length > 0 ?
                this.state.attachments
                  .map((attach, key) =>
                    <Grid key={key} item xs={12}>
                      <Typography className="word-wrap">{attach.name || 'file'}</Typography>
                    </Grid>
                  ) : this.state.isFileUploaded ?
                  <Typography style={{ color: 'red' }}>Please add document</Typography> :
                  null}
            </Grid>
          </Grid>
        </div>
        <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled={this.state.formHasErrors}
            actionText="Submit" 
            color="primary" 
            callBack={this.handleSubmit}
          />}
          { this.state.loading && <Loader />}
        </div>
      </React.Fragment>
    );
  }
}

AddActivity.defaultProps = {
  submitActivity: (data) => Promise.resolve(data),
  activityEvent: [],
  firearmTypes: [],
  firearmTypeLookup: [],
  activityDisciplines: [],
  opticsLookup: [],
  activityTypeLookup: [],
  backCallback: () => null,
};

AddActivity.propTypes = {
  submitActivity: PropTypes.func.isRequired,
  activityEvent: PropTypes.array.isRequired,
  firearmTypes: PropTypes.array.isRequired,
  firearmTypeLookup: PropTypes.array.isRequired,
  activityDisciplines: PropTypes.array.isRequired,
  opticsLookup: PropTypes.array.isRequired,
  backCallback: PropTypes.func.isRequired,
  activityTypeLookup: PropTypes.array.isRequired,
};

export default AddActivity;
