import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core/';

import { withRouter,Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const styles = (theme) => ({
  root: {
    paddingTop: '100px'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    paddingBottom: '8px',
    textAlign: 'justify'
  },
  offlineButton: {
    marginTop: theme.spacing(4),
  },
});

class Return extends Component {
  
  handleSubmit = () => {
    
    // this.props.history.push('/member/my-profile');
    // window.location.reload();


  };
  render() {
    const {classes} = this.props; 
    return (
      <React.Fragment>
        <Grid item container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
          <Grid item container direction="column" alignItems="center" justifyContent="center" xs={11}>
            <Typography className={classes.paragraph}>
              Payment processed successfully
            </Typography>
            <Typography className={classes.paragraph}>
              An email will be sent confirming the approval of your application.
              Thank you for joining!
            </Typography>
            {/* <Button
              size="small"
              disableElevation
              variant="contained"
              color="secondary" 
              className={classes.offlineButton}
              onClick={this.handleSubmit}
            >
              Back to profile
            </Button> */}
            <Redirect to={this.props.authRedirectPath} />

          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
Return.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
	authRedirectPath: state.AuthReducer.authRedirectPath,
	token: state.AuthReducer.token,
  recatchaKey: state.RecaptchaKeyReducer,
  member: state.ProfileReducer.memberData
});

export default connect(mapStatetoProps, null)(withStyles(styles)(withRouter(Return)));