import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import { base64StringToBlob } from 'blob-util';
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, FRONT } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import "./MainActivities.css";
import { isMobile } from "react-device-detect";
export const _getDocument = (name, type) => {
  Axios.get(BASE + "api/Activities/getActivityDocByteRep/" + name + "/" + type,{
    headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
    _blobRenderFile(res.data.base64DocRep, res.data.type, res.data.name)
  });
}
export const _blobRenderFile = (base64DocRep, type, name) => {
  const blob = base64StringToBlob(base64DocRep, type);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name + "." + type;
  a.click();
  a.remove();
  document.addEventListener("focus", window.URL.revokeObjectURL(blob))
}
const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    margin: "auto"
  },
  line: {
    marginBottom: "40px"
  }
});

class ActivityDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Activity: undefined,
      status: "",
      activityLink: ""
    };
  }

  componentDidMount() {
    const activities = this.props.data.filter(
      act => act.memNo === this.props.memberNumber
    );
    const activity = activities.find(
      act => act.activityId === parseInt(this.props.activityId)
    );
    this.setState({
      Activity: activity
    });
  }
  render() {
    if (!this.props.loggedIn) {
      window.location = "/login";
    }
    if (this.state.Activity === undefined) {
      return <div>No data</div>;
    }
    return (
      <ActivityDetailsPage
        goBack={this.props.onCurrActivityTabChange}
        activity={this.state.Activity}
        props={this.props}
      />
    );
  }
}

export function status(status) {
  if (status) {
    switch (status.toLowerCase()) {
      case "approved":
        return <label className="success-color">{status}</label>;
      case "pending":
        return <label className="warning-color">{status}</label>;
      case "rejected":
        return <label className="danger-color">{status}</label>;
      default:
        return <label className="danger-color"> Not Found </label>;
    }
  }
  return <label className="danger-color"> Not Found </label>;
}

export const notfound = <span className="danger-color"> Not Found </span>;

export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  const maxLength = 2;
  const zeroString = "0";
  if (month.length < maxLength) month = zeroString + month;
  if (day.length < maxLength) day = zeroString + day;

  return [year, month, day].join("/");
}

class pageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectionReason: "",
      astatus: "",
      docName: "",
      type: "",
      _memberNotes: ""
    };
  }
  componentDidMount() {
    Axios.get(BASE + "api/Activities/" + this.props.activity.activityId,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(
      res => {
        this.setState({
          astatus: res.data.status,
          rejectionReason: res.data.rejectReason,
          activityLink: res.data.link,
          _memberNotes: res.data.memberNotes
        });
      }
    );
  }
  render() {
    const Activity = this.props.activity
      ? this.props.activity
      : {
          Type: "type",
          Date: "02/07/2015",
          Status: "approved",
          ID: "NM-234",
          Description: "Ceres veldskiet 108gr LapuasWindage corection",
          FireArm: "Center Fire Rifle Bolt Action CFRBA",
          Caliber: "4 rounds/second",
          Association: "Narfo"
        };
    const activityViewInfo = (name, value) => (
      <tr>
        <td>{name}</td>
        {value ? 
          <td className="font-weight-bold" style={{width: "45%"}}>{`: ${value}`}</td> :
          notfound
        }
        
      </tr>
    );
    const activityViewInfo2 = (name, value) => (
      <tr>
        <td>{name}</td>
        {value === "NULL" || !value ? 
          <td className="font-weight-bold text-danger" style={{width: "45%"}}>Null</td> :
          <td className="font-weight-bold" style={{width: "45%"}}>
            {": "}
            <a
                className="text-primary font-weight-bold mb-2 mt-2"
                href={FRONT + this.state.activityLink}
                target="_"
              >
                Document
            </a>
          </td>
        }
        
      </tr>
    );
    const notfound = <span className="danger-color"> Not Found </span>;
    return (
      <div className="container">
        <div>
          <GenericHeader
            title="Activity Details"
            showBackButton
            backFunction={() => {
              this.props.goBack("0", "currentActivityTab");
            }}
          />
        </div>
        {!isMobile ?
          <div className="container">
            <div className="font-weight-bold">
              {Activity.activityType ? Activity.activityType : notfound} <br />
              {Activity.activityDate
                ? formatDate(Activity.activityDate)
                : notfound}{" "}
              <br />
              {status(Activity.status)}
            </div>
              <table style={{width:"100%"}}>
                <tbody>
                  {activityViewInfo("Activity ID", Activity.activityId)}
                  {this.state.astatus === "Rejected" ?
                    activityViewInfo("Rejection Reason", this.state.rejectionReason) :
                    null
                  }
                  {activityViewInfo("Activity Description", Activity.activityDescription)}
                  {activityViewInfo("Firearm Type", Activity.firearmType)}
                  {activityViewInfo("Association", Activity.association)}
                  {Activity.score !== "0" ?
                    activityViewInfo("Score", Activity.score) :
                    null
                  }
                  {activityViewInfo2("Activity Document", FRONT + this.state.activityLink)}
                </tbody>
              </table>
              <div>
                <span>
                  Activity Notes: <br />
                </span>
                <span>
                  <b>
                    {this.state._memberNotes
                      ? this.state._memberNotes
                      : notfound}
                  </b>
                </span>
              </div>
          </div> :
          <div className="container">
          <div className="font-weight-bold">
            {Activity.activityType ? Activity.activityType : notfound} <br />
            {Activity.activityDate
              ? formatDate(Activity.activityDate)
              : notfound}{" "}
            <br />
            {status(Activity.status)}
          </div>
          <div>
            <div>
              <span>
                Activity ID: <br />
              </span>
              <span>
                <b>
                  {Activity.activityId
                    ? Activity.activityId
                    : notfound}
                </b>
              </span>
            </div>
            {this.state.astatus === "Rejected" ? (
              <div>
                <span>
                  Rejection Reason: <br />
                </span>
                <span>
                  <b>
                    <div>{this.state.rejectionReason}</div>
                  </b>
                </span>
              </div>
            ) : null}
            <div>
              <span>
                Activity Description: <br />
              </span>
              <span>
                <b>
                  {Activity.activityDescription
                    ? Activity.activityDescription
                    : notfound}
                </b>
              </span>
            </div>
            <div>
              <span>
                Firearm Type: <br />
              </span>
              <span>
                <b>{Activity.firearmType ? Activity.firearmType : notfound}</b>
              </span>
            </div>
            <div>
              <span>
                Association: <br />
              </span>
              <span>
                <b>{Activity.association ? Activity.association : notfound}</b>
              </span>
            </div>
            <div>
              <span>
                Score: <br />
              </span>
              <span>
                <b>{Activity.score ? Activity.score : notfound}</b>
              </span>
            </div>
            <div>Activity Document</div>
            {this.state.activityLink === "NULL" || !this.state.activityLink ? (
              <div className="font-weight-bold text-danger">Null: </div>
            ) : (
              <a
                  className="text-primary font-weight-bold mb-2 mt-2"
                  href={FRONT + this.state.activityLink}
                  target="_"
                >
                  Document
              </a>
            )}
            <div>
              <span>
                Activity Notes: <br />
              </span>
              <span>
                <b>
                  {this.state._memberNotes
                    ? this.state._memberNotes
                    : notfound}
                </b>
              </span>
            </div>
          </div>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    data: state.activitiesReducer.userActivities,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    ActivityTab: state.adminPortalReducer.adminPortal.currentActivityTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      })
  };
};

export const ActivityDetailsPage = withStyles(styles)(pageLayout);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDetails);
