import React, { Component } from "react";

class CodeOfConduct extends Component {
  render() {
    return (
      <div className="container pr-4 pl-3">
        <div>
          <h3 align="center" className="font-weight-bold p-3 mb-0">
            Code of Conduct
          </h3>
          <div className="container p-3">
            <p>
              The National Association of Responsible Firearm Owners (NARFO)
              promotes the responsible, legal and safe use of firearms and
              follows this code of conduct listed below.
            </p>
          </div>
          <div align="center">
            <ol align="left">
              <li>Any firearm in your possession must be licenced</li>
              <li>
                All firearms, regardless, must always be handled in a safe and
                responsible manner
              </li>
              <li>
                All firearms must only be handled in places where it is safe to
                do so
              </li>
              <li>
                All firearms, when transported or carried on the person, should
                be concealed
              </li>
              <li>
                Never point a firearm at any person unless you are compelled to
                do so within the framework of the law
              </li>
              <li>
                All firearms, when not in use, should be stored in the
                prescribed safe place
              </li>
              <li>
                Never carry a firearm in a place which is declared as a “gun
                free zone”
              </li>
              <li>Always carry your firearm license with you</li>
              <li>
                When loading, or unloading your firearm, do so in a safe place
                with a muzzle pointing in a safe direction. Special attention
                should be given if, in the unfortunate event of an accidental
                discharge, that deflection or ricochets are minimised and that
                care is taken that nothing that is out of sight could be hit
              </li>
              <li>
                Always keep your finger off the trigger when handling a firearm
                until you are ready and sure of the shot
              </li>
              <li>
                Never hand someone a loaded firearm, always make sure it is
                unloaded and safe to do so
              </li>
              <li>
                When receiving a firearm, always assume it is loaded and treat
                it as such
              </li>
              <li>
                When firing you’re firearm, always be sure of your target and
                what could be beyond your target
              </li>
              <li>
                Always use the correct ammunition in the firearm it is intended
                for
              </li>
              <li>
                Where possible, always use eye and ear protection. The noise
                produced by a firearm can cause permanent ear damage. Any debris
                caused by the firing of a firearm can cause damage to the eyes
              </li>
              <li>
                Never place any foreign object in the barrel of a firearm.
                Always make sure that when you load or unload your firearm, it
                is clear of any foreign objects.
              </li>
              <li>
                Never perform any alterations to any firearm unless it is done
                by a professional qualified to do so
              </li>
              <li>Any repairs should always be done by a qualified person</li>
              <li>
                Never tamper with the trigger mechanism, it may result in a
                accidental discharge
              </li>
              <li>
                Make sure you know the mechanics of your firearm in terms of,
                safety, dismantling and cleaning, loading and unloading, removal
                and insertion of any cartridge holder or magazine
              </li>
              <li>
                You are always responsible for your firearm, it is a fulltime
                job
              </li>
              <li>
                Never give someone else access to the safe storage of your
                firearm
              </li>
              <li>
                You may not use or be in possession of someone else’s firearm
                unless the person who holds the license is in arm’s length
              </li>
              <li>
                You may not let someone use your firearm if you are not in arm’s
                length
              </li>
              <li>
                You may only have in your possession a maximum of 200 rounds per
                firearm unless you have a dedicated status
              </li>
              <li>
                You should not have in your possession any ammunition, firearms
                or parts thereof if you do not have the prescribed license for
                such items
              </li>
              <li>
                You may never use your firearm to conduct or participate in any
                illegal activities
              </li>
              <li>
                It is your duty as a responsible firearm owner to report to the
                authorities any illegal activities which specifically involves
                the use of firearms, both legal and illegal
              </li>
              <li>
                It is your duty to promote these codes of conduct to fellow
                firearm owners and the general public
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
export default CodeOfConduct;
