import { MenuItem, Select, TextField, Grid,Typography } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import TabPane from "../../../components/adminPortal/TabPane";

import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import MemberCard from "./MemberCard";
import { viewAllMemberStyles } from "./styles/styles";

const userStatus = Object.freeze({
  Approved: 1,
  Pending: 2,
  Rejected: 3,
  Renewing: 4,
});

class CardMembers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      MemberList: [],
      filtertext: "All",
      showProfile: false
    };
  }
  onChangeText = (event) => {
    this.setState({ searchText: event });
  };

  onchangeFilter(event) {
    this.setState({ filtertext: event });
  
    if(!userStatus[event]){
        this.setState({ MemberList: this.props.partnerMembers});
        this.props.onMemberViewListChange(this.props.partnerMembers);
    }
    else
    {
        const data = this.props.partnerMembers.filter(p => p.status === userStatus[event]);
        this.setState({ MemberList: data});
        this.props.onMemberViewListChange(data);
    }

  }

  componentDidMount() {

    this.setState({ MemberList: this.props.partnerMembers });
    this.props.onMemberViewListChange(this.props.partnerMembers);
    this.props.onMemberStateChange(true, "isAdmin");
  }

  resetFilters = () => {
    this.props.onAdminActivityTypeFilterChange("Activity Type");
    this.props.onAdminActivityStatusFilterChange("Status");
  };

  showFilterBar = () => {
    this.setState({ searchToggle: !this.state.searchToggle });
  };

  ShowMemberProfile =() =>{
      this.setState({showProfile: true})
  }

  HideMemberProfile =() =>{
    this.setState({showProfile: false})
}

  getFilteredMembers = (event) => {

    //If it's partner 
    if (event.key == "Enter" || event.key == 13) {
      
        const data = this.props.partnerMembers.filter(x => x.idno.includes(this.state.searchText) || x.surname.includes(this.state.searchText) || x.email.includes(this.state.searchText) || x.memNo.includes(this.state.searchText));
        
        this.setState({ MemberList: data });
        this.props.onMemberViewListChange(data);
        this.props.onMemberStateChange(true, "isAdminMemView");
    }
    
  };

  render() {
    let MemberV = this.props.MemberList;
    let lastLetter = "";
    let currentLetter;
    let displayLetter = false;
    MemberV.sort((a, b) => (a.firstname > b.firstname ? 1 : -1));
    return (
      <React.Fragment>

        {/* List of members */}
        {!this.state.showProfile && <Grid item xs={12} style={{ marginTop: "20px" }}>
          <GenericHeader
            title="All Members"
            showSearchButton
            subHeader={this.props.subHeader}
            headerColor="#17a2b8"
            searchFunction={() => this.showFilterBar()}
          />

          <div className="d-flex mt-1 justify-content-between">
            <div className="d-flex w-25 flex-column">
              <div>User status</div>
              <Select
                autoWidth
                value={this.state.filtertext}
                onChange={(event) => this.onchangeFilter(event.target.value)}
                defaultValue="Activity Type"
                inputProps={{
                  name: "Activity Type",
                  id: "ActivityTypeSelector",
                }}
              >
                <MenuItem value="Activity Type">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Renewing">Renewing</MenuItem>
              </Select>
            </div>
            <TextField
              id="outlinedFirearm"
              placeholder="Search by Surname, Email, MemNo or ID No"
              className="w-25"
              value={this.state.searchText}
              onChange={(event) => this.onChangeText(event.target.value)}
              onKeyDown={(event) => this.getFilteredMembers(event)}
              variant="outlined"
            />
          </div>

          {Array.isArray(MemberV) && MemberV.length === 0 ? (
            <div className="d-flex justify-content-center mt-5">
               <Typography style={{marginTop:'10px'}}><strong style={{fontStyle:'italic'}}>Members not found</strong></Typography>
                
            </div>
          ) : (
              //List of members container
            <div >
              {this.state.MemberList.map((MemberMap, index) => {
                currentLetter = MemberMap.firstname
                  .trim()
                  .toLowerCase()
                  .substring(0, 1);

                if (currentLetter !== lastLetter) {
                  displayLetter = MemberMap.firstname
                    .trim()
                    .toUpperCase()
                    .substring(0, 1);
                } else {
                  displayLetter = "";
                }

                lastLetter = currentLetter;
                return (
                  <MemberCard
                    key={index}
                    MemberArray={MemberMap}
                    firstLetter={displayLetter}
                    ShowMemberProfile={this.ShowMemberProfile}
                    HideMemberProfile={this.HideMemberProfile}
                    
                  />
                );
              })}
            </div>
          )}
        </Grid>}


        {this.state.showProfile && <TabPane isPartner={true} back={this.HideMemberProfile} headerColor="#17a2b8"/> }

      </React.Fragment>
    );
  }
}
CardMembers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    MemberDetails: state.MemberViewReducer.MemberView,
    MemberList: state.MemberViewReducer.MemberList,
    showActFilterBar: state.MemberViewReducer.showFilterBar,
    MemSearch: state.MemberViewReducer.Search,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onMemberViewListChange: (newUserMemberArray) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW_LIST,
        payload: newUserMemberArray,
      }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value,
      }),

    onShowFilterBarToggle: () =>
      dispatch({ type: actionTypes.TOGGLE_SHOW_FILTER_BAR }),

    onAdminActivityTypeFilterChange: (newAdminActivityTypeFilter) =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_TYPE_FILTER,
        payload: newAdminActivityTypeFilter,
      }),
    onAdminActivityStatusFilterChange: (newAdminActivityStatusFilter) =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_STATUS_FILTER,
        payload: newAdminActivityStatusFilter,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(viewAllMemberStyles)(CardMembers));
