import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { formatDate, notfound, status } from "../../loggeddInUser/activitiesPage/ActivityDetails";
import { activityStyles as styles } from "./styles/styles";

function Activity(props) {
  const { classes } = props;

  return (
    <Card
      className={classes.card}
      raised={false}
      onClick={() => {
        props.onActivityParametersChange(props.activity.memNo, props.activity.activityId);
        props.onCurrentActivityTabChange(1);
        
      }}>
      <CardContent className={classes.root}>
        <span>{props.activity.activityType}</span> <br />
        <span>{formatDate(props.activity.activityDate)}</span> <br />
        <span>{`Score: ${props.activity.score?props.activity.score : notfound}`}</span> <br />
        <span>{status(props.activity.status)}</span>
      </CardContent>
    </Card>
  );
}

Activity.propTypes = {
  classes: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    onActivityParametersChange: (memberNumber, activityId) => {
      return dispatch({
        type: actionTypes.UPDATE_ACTIVITY_PARAMETERS,
        activityMemberNumber: memberNumber,
        activityID: activityId
      })
    },
    onCurrentActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      }),
    onAnyPropertyChange: (variableValue, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACTIVITY_TAB,
        varName: variableName,
        varValue: variableValue
      })
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(Activity));
