import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DateTime} from 'luxon';
import JWT from 'jwt-decode';
import axios from 'axios';
import API_URL from '../../services/apiUrl';
import MemberActions from '../../store/Profile/actions';
import LicenceActions from '../../store/Licence/actions';
import {
  AccountDetails,
  LoyaltyProgramme,
  ProfileDetails,
  TabGroup,
  Notifications
} from '../../components';
import {
  currentYear,
  getMemberStatusFromInt,
  getDedicatedStatusTextFromStatusObject,
  formatISODateToLocaleString,
  getDedicatedStatusFromActivities,
  labelHtml
} from '../../shared/helpers';
import {
  printCard,
  printCertificate,
  printAccountTransaction,
} from '../../shared/pdfHelpers';
import MemberService from '../../services/member.service';
import LicenceService from '../../services/licence.service';
import DedicatedService from '../../services/dedicatedstatus.service';
import {
  MEMBER_STATUS,
} from '../../shared/constants';
import Container from '../../hoc/Container';
import LookupActions from '../../store/Lookup/actions';
import LookupService from '../../services/lookup.service';
import DedicatedStatusActions from '../../store/DedicatedStatus/actions';
import {SubtitleBar} from '../../components/index'

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import MemberPartnerDetails from '../../components/Profile/MemberPartnerDetails';


//Converted MakeStyles to WithStyles 
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});


class Profile extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);
    this.licenceService = LicenceService(this.props.token);
    this.dedicatedService = DedicatedService(this.props.token);
    this.memberActions = this.props.memberActions(this.memberService, this.dedicatedService);
    this.licenceActions = this.props.licenceActions(this.licenceService);
    this.lookupService = LookupService(this.props.token);
    this.lookupActions = this.props.lookupActions(this.lookupService);
    this.dedicatedStatusAction = this.props.dedicatedStatusActions(this.dedicatedService);

    this.state = {
      loading: false,
      isNewMember: false,
      memberStatus: null,
      membershipTypes: [],
      accountRecords: [],
      membershipType: null
    };
  }

  componentDidMount() {
    this.setState({loading: true});

    //Check if user is new 
    this.memberService.getMemberByMemNo(this.props.memNo).then(response=>{

      //Check if user is on status 5(New) Ferox user 
      if(response.data.status == 5 || response.data.status == 8)
          this.props.history.push('/member/profile-completion');

      if(response.data.status == 2)
          this.setState({isNewMember:true});
      this.setState({memberStatus:response.data.status})
    });

    this.getMembershipTypes();
    
    const id = this.props.memNo;
    const user = { memNo: id };

    this.memberActions.fetchMemberData(id)
      .then(() => this.memberActions.fetchMemberDedicatedStatus(id))
      .then(() => this.memberActions.fetchMemberAccountHistory(id))
      .then(() => this.memberActions.fetchMemberLoyaltyDays(id))
      .then(() => this.licenceActions.fetchFirearmLicenceStatus(id))
      .then(() => this.licenceActions.fetchMemberLicenseReminders(id))
      .then(() => this.lookupActions.fetchFirearmActions())
      .then(() => this.lookupActions.fetchSections())
      .then(() => this.dedicatedStatusAction.fetchDedicatedStatusList(user))
      .finally(() => {
        this.setState({loading: false});
        this.memberActions.fetchMemberProfilePicture(id);
      });

      this.refreshMemberAccountHistory();
  }

  memberDataToDetails = () => ({
    nickName: this.props.memberData.nickname,
    firstName: this.props.memberData.firstname,
    surname: this.props.memberData.surname,
    occupation: this.props.memberData.occupation,
    identityNumber: this.props.memberData.idno,
    cellNumber: this.props.memberData.cellNo,
    emailAddress: this.props.memberData.email,
    streetAddress: this.props.memberData.physicalAddress,
    suburb: this.props.memberData.suburb,
    postalCode: this.props.memberData.postalCode,
    city: this.props.memberData.city,
    province: this.props.memberData.province,
    personalAssisstant:this.props.personalAssisstant
  });

  memberDataToStatus = () => ({
    currentYear: currentYear,
    membership: this.props.memberData.memType,
    status: getMemberStatusFromInt(this.props.memberData.status),
    inceptionDate: formatISODateToLocaleString(this.props.memberData.inceptionDate),
    expiryDate: formatISODateToLocaleString(this.props.memberData.expiryDate),
    approvalDate: formatISODateToLocaleString(this.props.memberData.approvalDate),
    dedicatedSportsStatus: getDedicatedStatusFromActivities(this.props.dedicatedActivities.dedicatedShooter,this.props.dedicatedStatus.sport),
    dedicatedHunterStatus: getDedicatedStatusFromActivities(this.props.dedicatedActivities.dedicatedHunter,this.props.dedicatedStatus.hunting),
    loyaltyDays: (() => {
      const daysEarned = this.props.loyaltyProgram.daysAdded;
      return daysEarned?.dedicatedStatusDays + daysEarned?.courseDays +
        daysEarned?.endorsementDays + daysEarned?.generalActivityDays;
    })(),
    expiredFirearms: this.props.licenceStatus.expired,
    firearmsToBeRenewed: this.props.licenceStatus.toBeRenewed,
    firearms: this.props.licenceStatus.firearms,
  });

  memberDataToAccountStatus = () => ({
    status: getDedicatedStatusTextFromStatusObject(this.props.dedicatedStatus),
    inceptionDate: formatISODateToLocaleString(this.props.memberData.inceptionDate),
    expiryDate: formatISODateToLocaleString(this.props.memberData.expiryDate),
    expiryISO: this.props.memberData.expiryDate
  });

  getMembershipTypes = () => {
    this.memberService.getMembershipTypes(this.props.memberData.storeId).then(response=>{
      this.setState({membershipTypes:response.data})
      
      return response.data;
    })
    .catch(error=>{
      console.log("Error retrieving Membership types ", error)
      this.setState({membershipTypes:[]})
    });
  };


groupAccountData = (data)=> {
  return data.reduce((result, currentItem) => {
    const key = currentItem.transactionNo;

    if (!result[key]) {
        result[key] = [];
    }

    result[key].push(currentItem);

    return result;
}, {});
}

refreshMemberAccountHistory =()=>{
  let id = this.props.memberData.memNo;
  //Get history of accounts 
    axios.get(API_URL + "/account/member/" + id, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(res => {
        var data = this.groupAccountData(res.data);
        var _records = this.memberAccountToAccountHistory(data);
        this.setState({accountRecords: _records.reverse()})
        // if(res.data.length > this.props.accountHistory.length){
        // }
        // else
        // {
        //   var data = this.groupAccountData(this.props.accountHistory);
        //   var _records = this.memberAccountToAccountHistory(data);
        //   this.setState({accountRecords: _records.reverse()})
        // }
      }
    );
}

  memberAccountToAccountHistory = (groupedData) => {
    const groupedArray = Object.values(groupedData);

    const dd =  groupedArray.map((accountData) => ({
      paymentDate: accountData[0].paymentDate,
      paymentType: accountData.length > 1 ? accountData[1].paymentType : accountData[0].paymentType,
      amountPaid: accountData.length > 1 ? accountData[0].amountPaid + accountData[1].amountPaid: accountData[0].amountPaid,
      data:[...accountData],
      invoiceDate: formatISODateToLocaleString(accountData[0].invoiceDate),
    }));
    
    return dd.map((accountData) => ({
      ...accountData,
      invoiceDate: formatISODateToLocaleString(accountData.invoiceDate),
    }));

  };

  memberDataToLoyaltyDays = () => {
    if(!this.props.loyaltyProgram) {
      return [];
    }
    return {
      daysEarned: this.props.loyaltyProgram.daysAdded,
      loyaltyHistory: this.props.loyaltyProgram.daysHistory?.map(h => ({...h, date: formatISODateToLocaleString(h.date)})),
      daysList: this.props.loyaltyProgram?.daysList
    }
  };

  downloadMemberCard = () => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.dedicatedService.getApprovedDedicatedStatus(memberId)
          .then((res) => {
            let dsHunter = res.data
              .find(ds => ds.dstype.toLowerCase().includes('hunter') && ds.status === MEMBER_STATUS.APPROVED) || {};
            let dsSports = res.data
              .find(ds => ds.dstype.toLowerCase().includes('sport') && ds.status === MEMBER_STATUS.APPROVED) || {};
            return { dsHunter, dsSports };
          }).then(({ dsHunter, dsSports }) => this.memberService.getAllImages().then((images) =>

          // getPartnerLogo(this.props.memberData.storeId) call this service to retrieve partner logo based on partnerId 
            printCard(this.props.memberData, dsHunter.dsno, dsSports.dsno,
              images.data.narfo, images.data.pdflogo, base64QRCode)
          ));
      });
  }

  downloadAccountTransaction = (account) => this.memberService.getAllImages()
    .then((images) => printAccountTransaction(
      account, this.props.memberData, images.data.nnfo, images.data.pdflogo
    ));


  downloadMemberCertificate = () => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.memberService.getAllImages().then((images) =>
          printCertificate(
            this.props.memberData, images.data.nnfo, base64QRCode,
            images.data.pdfSign, images.data.pdflogo
          )
        );
      });
  }

  uploadProfilePicture = (result) => {
    const memberId = this.props.memberData.memNo;
    this.memberService.uploadProfilePicture(memberId, result)
      .then(() => this.memberActions.fetchMemberProfilePicture(memberId));
  }

  partnerInformation =()=>{
    var currentMembership = this.state.membershipTypes.find(m => m.id == this.props.memberData.membershipTypeId)
    var showPartnerMembership = this.state.membershipTypes?.length > 0 ? true : false;
    
    if(!currentMembership)
      return {
        membershipType : '-',
        PartnerName : this.props.memberData.store,
        PartnerExpiryDate : "-"
      }

    return {
      showPartnerMembership,
      membershipType : currentMembership?.name || '-',
      PartnerName : this.props.memberData.store,
      PartnerExpiryDate : formatISODateToLocaleString(this.props.memberData.partnerExpiryDate) || "-"
    }
  }
  

  tabViews = (isMobile)  => [
    {
      label: isMobile ? 'Details' : 'My Details',
      view:this.props.daysLeft <= 1 && this.props.memberData.status != 9? 
        <Grid 
        container 
        direction='row' 
        justify='center' 
        style={{paddingTop:"4em", fontWeight:"bold", color:"red"}}
        >
          Your membership is not currently active, please complete the payment process on the Accounts page to continue
        </Grid> :
        <ProfileDetails 
          myDetails={this.memberDataToDetails()}
          myStatus={this.memberDataToStatus()}
          profilePicture={this.props.profilePicture}
          memNo={this.props.memberData.memNo}
          fetchMemberData={this.memberActions.fetchMemberData}
          downloadMemberCardCallback={this.downloadMemberCard}
          downloadMemberCertificateCallback={this.downloadMemberCertificate}
          uploadProfilePictureCallback={this.uploadProfilePicture}
          updateMemberDetailsCallback={this.memberService.updateMemberDetails}
          daysLeft={this.props.memberData2.daysLeft}
          isPartnerMember = {this.props.daysLeft <= 1 && this.props.memberData.status == 9}
          partnerInformation = {this.partnerInformation()}
        /> 
    },
    {
      label: isMobile ? 'Account' :'Account Details',
      view: <AccountDetails
        memberStatus={this.state.memberStatus}
        accountStatus={this.memberDataToAccountStatus()}
        // accountHistory={this.memberAccountToAccountHistory()}
        accountHistory={this.state.accountRecords}
        printAccountTransactionCallback={this.downloadAccountTransaction}
        resetPasswordCallback={this.memberService.resetMemberPassword}
        // membershipTypes={this.getMembershipTypes()}
        memberData={this.props.memberData}
        membershipTypes={this.state.membershipTypes}
      />
    },
    {
      label: isMobile ? 'Loyalty' : 'Loyalty Programme',
      view:this.props.daysLeft <= 1 ?
        <Grid 
        container 
        direction='row' 
        justify='center' 
        style={{paddingTop:"4em", fontWeight:"bold", color:"red"}}
        >
          Your membership is not currently active, please complete the payment process on the Accounts page to continue
        </Grid> : 
        <LoyaltyProgramme
          LoyaltyDays = {this.memberDataToLoyaltyDays()}
        />
    },
  ];

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;
    return (
      <div>
        {/* Partner information section */}
        <MemberPartnerDetails personalAssisstant = {this.props.personalAssisstant} memberData={this.props.memberData}/>

        <Container title="My Profile">
          {!this.state.loading &&  <TabGroup 
            status = {this.state.isNewMember}
            loading={this.state.loading} 
            items={this.tabViews(isMobile)} 
            isMobile={isMobile}
          />}
          {!this.state.loading && <Notifications show = {!this.state.isNewMember} />}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberData: state.ProfileReducer.memberData,
  profilePicture: state.ProfileReducer.profilePicture,
  dedicatedStatus: state.ProfileReducer.dedicatedStatus,
  dedicatedActivities: state.ProfileReducer.dedicatedStatusActivities,
  loyaltyProgram: state.ProfileReducer.loyaltyProgram,
  accountHistory: state.ProfileReducer.accountHistory,
  licenceStatus: state.LicenceReducer.firearmLicenceStatus,
  assesmentCompletion: state.nLearningManagementReducer.assesmentCompletion,
  memberData2: state.ProfileReducer.memberData2,
  personalAssisstant: state.ProfileReducer.personalAssisstant,
  daysLeft: DateTime.fromISO(state.ProfileReducer.memberData.expiryDate).diffNow('days').days,
});

const initialiseMemberActions = dispatch => (memberService, dedicatedStatusService) => {
  const action = MemberActions(memberService, dedicatedStatusService);
  return {
    fetchMemberData: (id) => dispatch(action.fetchMemberData(id)),
    fetchMemberProfilePicture: (id) => dispatch(action.fetchMemberProfilePicture(id)),
    fetchMemberDedicatedStatus: (id) => dispatch(action.fetchMemberDedicatedStatus(id)),
    fetchMemberLoyaltyDays: (id) => dispatch(action.fetchMemberLoyaltyDays(id)),
    fetchMemberAccountHistory: (id) => dispatch(action.fetchMemberAccountHistory(id)),
    fetchMemberDetailsByEmail: (mail) => dispatch(action.fetchMemberDetailsByEmail(mail))
  };
};

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchFirearmLicenceStatus: (id) => dispatch(action.fetchFirearmLicenceStatus(id)),
    fetchMemberLicenseReminders: (id) => dispatch(action.fetchMemberLicenseReminders(id))
  };
};

const initLookupActions = dispatch => lookupService => {
  const actions = LookupActions(lookupService);
  return {
    fetchFirearmActions: () => dispatch(actions.fetchFirearmActions()),
    fetchSections: () => dispatch(actions.fetchSections()),
  };
};

const initDedicatedStatusActions = dispatch => dedicatedStatusService => {
  const actions = DedicatedStatusActions(dedicatedStatusService);
  return {
    fetchDedicatedStatusList: data => dispatch(actions.fetchDedicatedStatusList(data))
  };
};

const mapDispatchToProps = dispatch => ({
  memberActions: initialiseMemberActions(dispatch),
  licenceActions: initialiseLicenceActions(dispatch),
  lookupActions: initLookupActions(dispatch),
  dedicatedStatusActions: initDedicatedStatusActions(dispatch)
});

export default connect(mapStateToProps ,mapDispatchToProps)(withStyles(styles, { withTheme: true })(Profile));
