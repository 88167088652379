import { makeStyles } from '@material-ui/styles';
import safety from "../assetsNew/narfo_safety.jpg";

export const useStyles = makeStyles(theme => ({
    staticPageLayout: {
        paddingTop: "100px",
        paddingBottom: "100px",
        paddingLeft: "12%",
        paddingRight: "12%",
        backgroundColor: "#F9F9F9"
    },
    staticHeaderContainer: {
        backgroundColor: '#F7F7F7', 
        paddingLeft: '9em', 
        paddingTop: '1.5em',
        paddingBottom: '3em'
    },
    staticHeaderContainerMobile: {
        paddingTop: '1.5em',
		marginBottom: '1em'
    },
    learnMoreButton: {
        width: '100%',
        height: '45px',
        fontWeight: 700
    },
    servicesContainer: {
        paddingBottom: '1em',
        paddingTop: '1em'
    },
	initiativesContainer: {
        backgroundColor: "#F9F9F9",
        paddingBottom: '1em',
        paddingTop: '1em'
    },
    servicesContainerMobile: {
        padding: '2em'
    },
    servicesContainerChild1: {
        paddingTop: "70px",
        paddingLeft: "12%",
    },
	initiativeChild: {
        paddingTop: "70px",
        paddingLeft: "12%",
        backgroundColor: "#F9F9F9"
    },
    opaqueOverlay: {
        backgroundImage: `url("${safety}")`, 
        backgroundPosition: 'center', 
        backgroundSize: 'cover',
        paddingTop: "70px",
        paddingLeft: "12%",
        backgroundColor: "#F9F9F9",
        position:'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
            backgroundColor: 'rgba(0,0,0,0.25)'
        }
    },

    exploreContainerChild1: {
        paddingTop: "70px",
        paddingLeft: "12%",
    },

    servicesContainerChild2: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '70px',
    },
    servicesContainerChildMobile: {
        paddingTop: "1em",
        paddingLeft: '1em',
        paddingRight: '1em'
    },
    communityContainer: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: "70px",        
    },
    exploreContainer: {
        paddingBottom: "70px",
    },
	blueButton: {
		padding: '10px',
		margin: '20px 0',
		color: 'white',
		background: '#4F36A6',
		width: '100%'
	},
	grayButton: {
		padding: '10px',
		marginTop: '20px',
		color: 'white',
		background: '#343a40',
		width: '200px'
	}
}))