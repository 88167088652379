import API_URL from './apiUrl';
import axios from 'axios';

const sendVerificationCode = header => email => axios.
  post(`${API_URL}/AutomatedEmail/SendVerificationCode?email=`+email, {header});

  const verifyMemberEmail = header => (email, code) => axios.
  post(`${API_URL}/Member/VerifyAccount?email=${email}&code=${code}`, {header});
  
  const isValidMember = header => (email,username) => axios.
  post(`${API_URL}/Member/IsValidMember?email=${email}&username=${username}`, {header});

  const isValidCode = header => (email,code) => axios.
  post(`${API_URL}/Member/IsValidCode?email=${email}&code=${code}`, {header});

  const checkIDNo = header => (idNo) => axios.
  get(`${API_URL}/Member/CheckIDNo?idNo=${idNo}`, {header});

  const signup = header => formValues=> axios.
  post(`${API_URL}/Member/post/set`,formValues, {header});


  // axios.post(`${API_URL}/Member/post/set`, formValues, {headers})
  // .then(response => {
  //   localStorage.setItem('memNo', response.data.message.memNo);
  //   localStorage.setItem('email', response.data.message.email);
  //   dispatch(signupSuccess(response.data.message.memNo, response.data.message.email));
  // })
  // .catch(err => {
  //   dispatch(signupFail(err.response));
  // });

  function registerNewUser (newMember){
    let formValues = new FormData();
  formValues.append('sex', newMember.gender);
  formValues.append('Nickname', newMember.nickname);
  formValues.append('firstname', newMember.firstName);
  formValues.append('surname', newMember.surname);
  formValues.append('cellNo', newMember.cellphoneNumber);
  formValues.append('idNo', newMember.idNumber);
  formValues.append('occupation', newMember.occupation);
  formValues.append('ethinicity', newMember.ethnicity);
  formValues.append('physicalAddress', newMember.physicalAddress);
  formValues.append('suburb', newMember.suburb);
  formValues.append('city', newMember.city);
  formValues.append('province', newMember.province);
  formValues.append('postalCode', newMember.postalCode);
  formValues.append('FireArmControl', (newMember.fireArmsControlAct === 'true'));
  formValues.append('FireArmsStorage', (newMember.fireArmsUseStorage === 'true'));
  formValues.append('Criminal', (newMember.criminalOffense === 'true'));
  formValues.append('CodeOfConduct', (newMember.declareCodeOfConduct === 'true'));
  formValues.append('DisciplinaryConduct', (newMember.declareDisciplinaryCode === 'true'));
  formValues.append('CorrectInfo', (newMember.declareValidInfo === 'true'));
  formValues.append('ElectronicComms', (newMember.declareElectronicPolicy === 'true'));
  formValues.append('DeclarationAcceptance', (newMember.declareValidElectronicTrue === 'true'));
  formValues.append('salesRep', newMember.salesReferral);
  formValues.append('store', newMember.partnerReferral);
  formValues.append('CurrentAssociation', (newMember.associationAffiliation === 'true'));
  formValues.append('email', newMember.email);
  formValues.append('username', newMember.username);
  formValues.append('password', newMember.password);
  // formValues.append('code',newMember.code);

      return this.signup(formValues)
  }

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    sendVerificationCode: sendVerificationCode(headers),
    isValidCode: isValidCode(headers),
    isValidMember: isValidMember(headers),
    signup: signup(headers),
    registerNewUser:registerNewUser,
    verifyMemberEmail: verifyMemberEmail(headers),
    checkIDNo: checkIDNo(headers)
  };
}
