import React, { Component } from "react";
import { connect } from "react-redux";
import * as constFile from "../../../store/constantFile/ConstFile";
import UpdateCustomLicense from "./UpdateCustomLicense";
import UpdateLicense from "./UpdateLicense";

class LicensedFireArmUpdate extends Component {
  
  ActionSelect() {
    if (this.props.newlicenceDetails.Custom === constFile.CTRUE ) {
      return <UpdateCustomLicense/>;
    } else if (this.props.newlicenceDetails.Custom === constFile.CFALSE) {
      return <UpdateLicense/>;
    }
  }


  render() {
    return (
      <div>
      {this.ActionSelect()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.currentActivityTab,
    newlicenceDetails: state.licensesReducer.licence
  };
};

export default connect(
  mapStateToProps,
  null
)(LicensedFireArmUpdate);
