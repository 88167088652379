import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import "./ldays.css";
import LoyaltyDaysItem from "./LoyaltyDaysItem";
class UpdateLoyaltyDays extends Component {
  state = {
    daysArray: [],
    dedicateStatusDays: 0,
    courseDays: 0,
    endorsementDays: 0,
    generalActivity: 0
  }
  onSubmit = () => {
    axios({
      url: BASE + "api/LoyaltyDaysAdjust/updateLoyaltyDays/" + this.state.dedicateStatusDays
                  + "/" + this.state.courseDays + "/" + this.state.endorsementDays + "/" + this.state.generalActivity,
      data: this.state.selectedDeletedFile,
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      if(res.data.status === "success") {
        this.props.onLoyaltyDaysChange({
          ldaId: 1, 
          dedicateStatusDays: this.state.dedicateStatusDays, 
          courseDays: this.state.courseDays, 
          endorsementDays: this.state.endorsementDays, 
          generalActivity: this.state.generalActivity
        })
      }
      else
        alert(res.data.message);
    });
  };
  componentDidMount(){
    var num = 0;
    const tempDaysArray = [];
    while(num <= 50) {
      tempDaysArray.push(num);
      num ++;
    }
    this.setState({
      daysArray: tempDaysArray
    })
  }
  render(){
    return(
      <form className="container" style={{ marginTop: "60px"}}>
        <div className="row m-4">
          <GenericHeader
            title="Update Loyalty Days"
          />
        </div>
        <table style={{width: "95%", marginLeft: "25px"}}>
          <LoyaltyDaysItem
            loyaltyTitle="Dedicated Status Days"
            loyaltyDays={this.props.loyaltyDaysObject.dedicateStatusDays}
            setDays={a => {
              this.setState({
                dedicateStatusDays: a.target.value
              });
            }}
            daysList={this.state.daysArray}
          />
          <LoyaltyDaysItem
            loyaltyTitle="Course Days"
            loyaltyDays={this.props.loyaltyDaysObject.courseDays}
            setDays={a => {
              this.setState({
                courseDays: a.target.value
              });
            }}
            daysList={this.state.daysArray}
          />
          <LoyaltyDaysItem
            loyaltyTitle="Endorsement Days"
            loyaltyDays={this.props.loyaltyDaysObject.endorsementDays}
            setDays={a => {
              this.setState({
                endorsementDays: a.target.value
              });
            }}
            daysList={this.state.daysArray}
          />
          <LoyaltyDaysItem
            loyaltyTitle="General Activity"
            loyaltyDays={this.props.loyaltyDaysObject.generalActivity}
            setDays={a => {
              this.setState({
                generalActivity: a.target.value
              });
            }}
            daysList={this.state.daysArray}
          />
        </table>
        <div className="formButton placeAtEnd pr-5">
          <Link to="/UpdateLoyaltyDays">
            <button className="btn btn-warning mt-3" onClick={() => this.onSubmit()}>
              SAVE
            </button>
          </Link>
        </div>
      </form>
    )
  }
}
const mapStateToProps = state => {
    return {
        loyaltyDaysObject: state.LoyaltyDaysReducer.loyaltyDaysObject
    };
  };
const mapDispatchToProps = dispatch => {
  return {
    onLoyaltyDaysChange: loyaltyDaysObjectDisp =>
      dispatch({
        type:actionTypes.GET_LOYALTY_DAYS,
        loyaltyDaysObject: loyaltyDaysObjectDisp
      })
  }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UpdateLoyaltyDays);