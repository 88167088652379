import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import axios from "axios";
import { BASE } from "../../../API/APIendpoint";
class MemberCounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeFilter: "",
            title: "",
            partnerList: [],
            salesRepList: [],
            selectedPartner: "",
            selectedConsultant: "",
            selectedProvince: "",
            titleFilter: "",
            isGetRejected: true,
            _count: 0
        }
    }
    componentDidMount() {
        axios({
            url: `${BASE}api/Stores`,
            method: "get",
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
        }).then(res => {
            this.setState({
                partnerList: res.data
            })
        })
        axios({
            url: `${BASE}api/admin/sales`,
            method: "get",
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
        }).then(res => {
            this.setState({
                salesRepList: res.data
            })
        })
    }
    getMembers = e => {
        e.preventDefault();
        if(this.state.typeFilter === "1") {
            axios({
                url: `${BASE}api/Member/getByPartner/${this.state.selectedPartner}`,
                method: "get",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                }
            }).then(res => {
                this.setState({
                    _count: res.data.length,
                    typeFilter: ""
                })
            })
        } else if(this.state.typeFilter === "2") {
            axios({
                url: `${BASE}api/Member/getByConsultant/${this.state.selectedConsultant}`,
                method: "get",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                }
            }).then(res => {
                this.setState({
                    _count: res.data.length,
                    typeFilter: ""
                })
            })
        } else if(this.state.typeFilter === "3") {
            axios({
                url: `${BASE}api/Member/getByDemographicArea/${this.state.selectedProvince}`,
                method: "get",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                }
            }).then(res => {
                this.setState({
                    _count: res.data.length,
                    typeFilter: ""
                })
            })
        } else if(this.state.typeFilter === "4") {
            axios({
                url: `${BASE}api/Member/getTerminatedMembers`,
                method: "get",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                }
            }).then(res => {
                this.setState({
                    _count: res.data.length,
                    isGetRejected: false
                })
            })
        }
    }
    render() {
        const info = (row1, row2) => (
            <div className="row  mt-2 mb-1">
              <div className="col-12">{row1} </div>
              <div className="col-12 font-weight-bold">{row2}</div>
            </div>
          );
        return(
            <div className="container" style={{ marginTop: "60px" }}>
                <GenericHeader 
                    title={this.state.typeFilter === "4" ? "Rejected Members" : `${this.state.title} Members`}
                    showMemberNumberDays
                    totalMembers={this.state._count}
                />
                {info("Choose Type Filter",
                    <select
                        className="form-control input-sm m-0"
                        aria-describedby="emailHelp"
                        onInput={a => {
                            this.setState({
                                typeFilter: a.target.value,
                                isGetRejected: true
                            });
                        }}
                        >
                        <option>Select Type Filter</option>
                        <option value="1">Members Per Partner</option>
                        <option value="2">Members Per Consultant</option>
                        <option value="3">Members Per Demographic Area</option>
                        <option value="4">Terminated Members</option>
                    </select>
                )}
                {this.state.typeFilter === "1" ? 
                    <div>
                        {info("Members Per Partner", 
                        <select
                            className="form-control input-sm m-0"
                            aria-describedby="emailHelp"
                            onInput={a => {
                                this.setState({
                                    selectedPartner: a.target.value,
                                    title: a.target.value,
                                    isGetRejected: true
                                });
                            }}
                            >
                            <option>Select Parnter</option>
                            {this.state.partnerList.map((part, index) => (
                                <option value={part.store} index={index}>{part.store}</option>
                            ))}
                        </select>)}
                        <div className="placeAtEnd">
                            <button className="btn btn-warning mt-3" onClick={e => this.getMembers(e)}>
                                EXECUTE
                            </button>
                        </div>
                    </div>
                    : this.state.typeFilter === "2" ?
                    <div>
                        {info("Members Per Consultant", 
                        <select
                            className="form-control input-sm m-0"
                            aria-describedby="emailHelp"
                            onInput={a => {
                                this.setState({
                                    selectedConsultant: a.target.value.split(" ")[0],
                                    title: a.target.value.substring(a.target.value.indexOf(" ")),
                                    isGetRejected: true
                                });
                            }}
                            >
                            <option>Select Consultant</option>
                            {this.state.salesRepList.map(name => (
                                <option value={`${name.memNoNavigation.memNo} ${name
                                    .memNoNavigation.firstname +
                                    " " +
                                    name.memNoNavigation.surname}`}>{`${name
                                    .memNoNavigation.firstname +
                                    " " +
                                    name.memNoNavigation.surname}`}</option>
                            ))}
                        </select>)}
                        <div className="placeAtEnd">
                            <button className="btn btn-warning mt-3" onClick={e => this.getMembers(e)}>
                                EXECUTE
                            </button>
                        </div>
                    </div> : this.state.typeFilter === "3" ?
                    <div>
                        {info("Members Per Province", 
                        <select
                            className="form-control input-sm m-0"
                            aria-describedby="emailHelp"
                            onInput={a => {
                                this.setState({
                                    selectedProvince: a.target.value,
                                    title: a.target.value,
                                    isGetRejected: true
                                });
                            }}
                        >
                            <option>Select Province</option>
                            <option value="Eastern Cape">Eastern Cape</option>
                            <option value="Free State">Free State</option>
                            <option value="Gauteng">Gauteng</option>
                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                            <option value="Limpopo">Limpopo</option>
                            <option value="Mpumalanga">Mpumalanga</option>
                            <option value="Northern Cape">Northern Cape</option>
                            <option value="North West">North West</option>
                            <option value="Western Cape">Western Cape</option>
                        </select>)}
                        <div className="placeAtEnd">
                            <button className="btn btn-warning mt-3" onClick={e => this.getMembers(e)}>
                                EXECUTE
                            </button>
                        </div>
                    </div> : this.state.typeFilter === "4" && this.state.isGetRejected ?
                    <div className="row mt-3 ml-3">
                        <div className="col-10 font-weight-bold">Get Rejected Members</div>
                        <button className="btn btn-warning col-1" onClick={e => this.getMembers(e)}>
                            EXECUTE
                        </button>
                    </div> : null}
            </div>
        )
    }
}
export default MemberCounts;