import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_MEMBER } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import "./profile.css";
import UserDetails from "../loggeddInUser/userProfile/UserDetails";
import { isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
toast.configure();
export class EditMemberDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemNo: this.props.memberDetails.memberNumber,
      CellNo: this.props.memberDetails.cellNo,
      PhysicalAddress: this.props.memberDetails.physicalAddress,
      Province: this.props.memberDetails.province,
      PostalCode: this.props.memberDetails.postalCode,
      Email: this.props.memberDetails.email,
      Occupation: this.props.memberDetails.occupation,
      City: this.props.memberDetails.city,
      Suburb: this.props.memberDetails.suburb,
      userDetails: false
    };
    this._submitForm = React.createRef();
  }
  _clickSubmitButton = e => {
    e.preventDefault();
    this._submitForm.current.click();
  }
  onChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  onSubmit = e => {
    e.preventDefault();
    const {
      MemNo,
      CellNo,
      PhysicalAddress,
      Province,
      PostalCode,
      Email,
      Occupation,
      City,
      Suburb
    } = this.state;
    axios
      .post(BASE + "api/Member/updateMemberDetails", {
        MemNo,
        CellNo,
        PhysicalAddress,
        Province,
        PostalCode,
        Email,
        Occupation,
        City,
        Suburb
      }, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
      })
      .then(response => {
        if(!response.data.status){
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          axios.get(BASE + GET_MEMBER + this.state.MemNo, {
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
          }).then(response => {
            this.props.onMemberDetailsChange(
              response.data.members.username,
              "username"
            );
            this.props.onMemberDetailsChange(
              response.data.members.email,
              "email"
            );
  
            this.props.onMemberDetailsChange(response.data.members.idno, "idno");
            this.props.onMemberDetailsChange(
              response.data.members.cellNo,
              "cellNo"
            );
            this.props.onMemberDetailsChange(
              response.data.members.physicalAddress,
              "physicalAddress"
            );
            this.props.onMemberDetailsChange(response.data.members.city, "city");
            this.props.onMemberDetailsChange(response.data.members.suburb, "suburb");
            this.props.onMemberDetailsChange(
              response.data.members.province,
              "province"
            );
            this.props.onMemberDetailsChange(
              response.data.members.postalCode,
              "postalCode"
            );
            this.props.onMemberDetailsChange(
              response.data.members.memNo,
              "memberNumber"
            );
            this.props.onMemberDetailsChange(
              response.data.members.occupation,
              "occupation"
            );
  
            this.props.onMemberDetailsChange(
              response.data.members.inceptionDate,
              "inceptionDate"
            );
            this.props.onMemberDetailsChange(
              response.data.members.expiryDate,
              "expiryDate"
            );
          });
        }
      });
    this.setState({
      userDetails: true
    });
  };
  render() {
    var width1 = "";
    var width2 = "";
    if (isMobile) {
      width1 = "20%"
      width2 = "20%"
    } else {
      width1 = "29%"
      width2 = "40%"
    }
    const info = (row1, row2) => (
      <tbody>
        <tr >
          <td style={{ width: width1 }}>{row1}</td>
          {isMobile ? null : <td style={{ width: "48%" }} />}
          <td className="font-weight-bold" style={{ width: width2 }}>{row2}</td>
        </tr>
      </tbody>
    );
    if (this.state.userDetails !== true) {
      return (
        <div>
          <form className="container mb-2">
            <div className="row ml-1 mr-1">
              <GenericHeader
                title="Edit Details"
                showBackButton
                backFunction={() => {
                  this.setState({
                    userDetails: true
                  });
                }}
              />
            </div>

            <table className="ml-4">
              {info("Member No", `: ${this.props.memberDetails.memberNumber}`)}
              {info("Identity No", `: ${this.props.memberDetails.idno}`)}
              {info("Username", `: ${this.props.memberDetails.username}`)}
              {info("Cell Number",
                <input
                  type="number"
                  className="form-control"
                  id="cellNo"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      CellNo: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.CellNo}
                />
              )}
              {info("Physical Address",
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      PhysicalAddress: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.PhysicalAddress}
                />
              )}
              {info("Suburb",
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      Suburb: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.Suburb}
                />
              )}
              {info("City",
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      City: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.City}
                />
              )}
              {info("Province",
                <select
                  className="form-control input-sm m-0"
                  id="province"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      Province: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                >
                  <option>{this.state.Province}</option>
                  <option value="Gauteng">Gauteng</option>
                  <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                  <option value="Mpumalanga">Mpumalanga</option>
                  <option value="Western">Western Cape</option>
                  <option value="Limpopo">Limpopo</option>
                  <option value="Eastern Cape">Eastern Cape</option>
                  <option value="North West">North West</option>
                  <option value="Free State">Free State</option>
                  <option value="Northern Cape">Northern Cape</option>
                </select>
              )}
              {info("Postal Code",
                <input
                  type="text"
                  className="form-control "
                  id="code"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      PostalCode: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.PostalCode}
                />
              )}
              {info("Email",
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      Email: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.Email}
                />
              )}
              {info("Occupation",
                <input
                  type="text"
                  className="form-control"
                  id="occupation"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      Occupation: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  value={this.state.Occupation}
                />
              )}
              <button
                style={{ display: "none" }}
                onClick={e => this.onSubmit(e)}
                ref={this._submitForm}
              />
            </table>
          </form>
          <div className="container">
            <div className="container placeAtEnd mr-4">
              <button class="btn btn-warning mt-3" onClick={e => this._clickSubmitButton(e)}>
                SAVE
              </button>
            </div>
          </div>
        </div>
      );
    } else if (this.state.userDetails === true) {
      return (
        <UserDetails />
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    memberDetails: state.loginReducer.memberObject,
    memberCity: state.loginReducer.city
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_DETAILS_2,
        varValue: value,
        varName: vname
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMemberDetails);
