import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { withRouter } from "react-router-dom";

const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  }
};

class Return extends Component {
  handleSubmit = e => {
    sessionStorage.clear();
    this.props.history.push("/App");
  };

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 pt-5 pb-5" />
          <div className="col-12 h3">Payment processed successfully</div>
          <div className="col-12 h5 pb-2">
            An email with login details will be sent to you upon approval of
            your application. Thank you for joining!
          </div>
          <div className="col-12">
            <button
              className="btn btn-outline-success"
              onClick={this.handleSubmit}
            >
              Go to the login page
            </button>
          </div>
        </div>
      </div>
    );
  }
}
Return.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    accountDetails: state.accountReducer.account,
    memberDetails: state.loginReducer.userProfileDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onLoginDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        varValue: value,
        varName: vname
      }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Return)));
