import React, { Component } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { ValidatorForm } from "react-form-validator-core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Button from "@material-ui/core/Button";
import {
  BASE,
  GET_STORE_LIST,
  POST_STORE,
  DELETE_STORE
} from "../../../API/APIendpoint";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";

const styles = theme => ({
  root: {
    height: "100%"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    textAlign: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    align: "center"
  },

  footer: {
    display: "comtent",
    flexDirection: "row"
  },

  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    fullWidth: true,
    flex: "1",
    margin: "5",
    instantValidate: true
  },

  textField: {
    marginTop: "1%",
    marginLeft: "30%",
    marginRight: "30%",
    flex: 1,
    margin: 5,
    width: "40%"
  },

  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#FFFFFF",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "#F5A623",
      color: "White",
      borderColor: "Black"
    },
    width: "20%",
    color: "Black",
    align: "Left",
    marginLeft: "10%",
    marginRight: "30%"
  },

  select: {
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%",
    flex: "1",
    margin: "5"
  }
});

class DropDownStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Store_List: [],
      DelKey: "",
      isValid: false,
      showValid: true
    };
  }
  handleChange = name => event => {
    this.props.onSectionChange(event.target.value, [name]);
    this.setState({ showValid: true });
    this.checkNotEmpty();
  };
  checkNotEmpty = () => {
    let V = true;
    if (
      this.props.Selections.Store === "" ||
      this.props.Selections.Store === null ||
      this.props.Selections.Store === undefined ||
      this.props.Selections.Adress === "" ||
      this.props.Selections.Adress === null ||
      this.props.Selections.Adress === undefined
    ) {
      V = false;
    }
    this.setState({
      isValid: V
    });
  };

  submitSection() {
    if (
      this.state.isValid &&
      !(this.props.Selections.Store === "") &&
      !(this.props.Selections.Adress === "")
    ) {
      fetch(BASE + POST_STORE, {
        method: "POST",
        body: JSON.stringify({
          Store: this.props.Selections.Store,
          Adress: this.props.Selections.Adress
        }),
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(() => {
        fetch(BASE + GET_STORE_LIST,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(res => res.json())
          .then(data => {
          
            this.setState({
              Store_List: data
            });
          });
      });
    }
    this.setState({ isValid: false });
    this.componentDidMount();
  }
  DeleteSection() {
    fetch(BASE + DELETE_STORE + this.props.Selections.DeleteStoreKey, {
      method: "POST",
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
    }).then(() => {
      fetch(BASE + GET_STORE_LIST,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => res.json())
        .then(data => {
          this.setState({
            Store_List: data
          });
        });
    });
    this.componentDidMount();
  }

  DeleteKey = e => {
     this.setState({
       DelKey: [e.target.value]
     });
     this.props.onSectionChange(e.target.value, "DeleteStoreKey");
  };

  componentDidMount() {
    this.props.onSectionChange("", "Store");
    this.props.onSectionChange("", "Adress");

    this.setState({ showValid: false });
    fetch(BASE + GET_STORE_LIST,
      {
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => res.json())
        .then(data => {
        this.setState({
          Store_List: data
        });
      });
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (this.state.showValid) {
        if (!value) {
          return false;
        }
        if (value.length < 0) {
          return false;
        }
      }
      return true;
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.container} noValidate autoComplete="off">
          <h1 className="row d-none d-md-block" align="Middle">
            Store
          </h1>
          <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit={()=>this.handleSubmit()}
          >
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.Selections.Store}
              label="Store"
              onInput={this.handleChange("Store")}
              name="Store"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Store Name",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.Selections.Adress}
              label="Address"
              onInput={this.handleChange("Adress")}
              name="Address"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the Shop Name/ Address",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />

            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                this.submitSection();
              }}
            >
              Add to Drop Downs
            </Button>
            <Select
              className={classes.textField}
              native
              value={this.props.Selections.DeleteStoreKey}
              onChange={this.DeleteKey}
              input={
                <OutlinedInput labelWidth={0} name="Store_List" id="Store" />
              }
            >
              {this.state.Store_List.map(activityType => (
                <option key={activityType.storeId} value={activityType.storeId}>
                  {activityType.store} : {activityType.adress}
                </option>
              ))}
            </Select>

            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                this.DeleteSection();
              }}
            >
              Remove Store
            </Button>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

DropDownStore.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails,
    Selections: state.DropDownReducer.Options,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSectionChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_OPTIONS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DropDownStore));
