import {
  SET_COMMISSIONS,
  SET_BANKING_DETAILS,
  SET_SALES_REPS
} from './types';

const INITIAL_STATE = {
  commissions: [],
  bankingDetails: {
    repDetails: {},
    partnerDetails: {},
  },
  salesReps: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    
  case SET_COMMISSIONS:
    return { ...state, commissions: payload };

  case SET_BANKING_DETAILS:
    return { ...state, bankingDetails: payload };

  case SET_SALES_REPS:
	return { ...state, salesReps: payload };

  default:
    return state;
  }
};

