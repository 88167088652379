import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
const styles = {
  card: {
    minWidth: "100%",
    display: "inline-block"
  },
  root: {
    display: "inline-block",
    flexDirection: "row",
    height: "100%",
    padding: 5
  },
  button: {
    marginLeft: 650,
    marginTop: -30,
    padding: 10,
    width: 20,
    height: 60,
    border: "2px solid black",
    borderRadius: 50
  },
  rightContainer: {
    display: "inline-block",
    flexDirection: "column",
    flex: 2,
    paddingLeft: 30,
    padding: 5,
    paddingBottom: 10
  },
  grow: {
    flex: 1
  },
  text: {
    paddingLeft: 20,
    paddingBottom: 10
  },
  title: {
    padding: 5,
    marginLeft: "-150px"
  },
  title1: {
    marginLeft: "450px" 
  },
  score: {
    padding: 5,
    display: "inline-block"
  },
  scoreNumber: {
    paddingTop: "25px",
    color: "orange",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "10px",
    marginLeft: "-350px"
  },
  scoreText: {
    color: "black",
    fontWeight: "bold",
    fontSize: "10px"
  },
  Contain: {
    marginLeft: 250,
    marginTop: -50,
    paddingLeft: 150
  },
  Contain1: {
    marginLeft: 400,
    marginTop: -50
  }
};

class Ranking extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.title1}>
          <Typography  className="font-weight-bold">
            #{this.props.RankingArray.nrkey}
          </Typography>
        </div>
        <Container className={classes.Contain} align ="center" >
          <Row>
            <Col>
              <Row>
                <Col xs={{ order: 1 }}>
                  <Typography variant="h8">
                    {this.props.RankingArray.name}
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col xs={{ order: 1 }}>
                  {" "}
                  {this.props.RankingArray.association}
                </Col>
              </Row>
              <Row>
                <Col xs={{ order: 1 }}>
                  {" "}
                  {this.props.RankingArray.firearmType}
                </Col>
              </Row>
              <Row>
                <Col xs={{ order: 1 }}>
                  {" "}
                  {this.props.RankingArray.activityType}
                </Col>
              </Row>
            </Col>
            <Col xs lg="3">
              <Typography className={classes.scoreNumber}>
                {this.props.RankingArray.score} <br />
                <span className={classes.scoreText}>-score-</span>
              </Typography>
            </Col>
          </Row>
        </Container>

        <hr className={classes.hr1} />
        <br></br>
      </div>
    );
  }
}
Ranking.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  null,
  null
)(withStyles(styles)(Ranking));
