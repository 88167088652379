import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import NotificationService from '../../services/Notification.service';
import NotificationActions from '../../store/Notifications/actions';
import API_URL from '../../services/apiUrl';
import { FRONT } from '../../../API/APIendpoint';
const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  },
  dialog: {
    backgroundImage: `url(${FRONT}Images/notification.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "500px 500px",
    backgroundPosition:'center',
    backgroundColor:'transparent',
    boxShadow: 'none',
    zIndex:'1000',
  },
  dialogMobile: {
    backgroundImage: `url(${FRONT}Images/notification.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "300px 300px",
    backgroundPosition:'center',
    backgroundColor:'transparent',
    boxShadow: 'none',
    zIndex:'1000',
  },
  dialogContent:{
    position: "absolute",
    top: "50%",
    left: "50%",
    textSlign: "justify",
    textJustify: "inter-word",
    transform: "translate(-50%, -50%)",
    color:"white",
  }
});

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.classes = styles();
    this.notificationService = NotificationService(this.props.token);
    this.notificationActions = this.props.notificationActions(this.notificationService);
    this.state = {
        open:false,
        notificationMessages:[]
    }
  }

  handleClose = () => {
      this.notificationActions.putMemberNotification(this.state.notificationMessages[0].notificationsId,this.props.memNo)
      .then((response) =>{
            if(response.payload.data.length > 0)
                this.setState({notificationMessages: response.payload.data});
            else
                this.setState({open:false});
      })
  };

  componentDidMount() {
    this.notificationActions.fetchNotifications(this.props.memNo)
      .then((reponse) =>{
          this.setState({notificationMessages: reponse.data, open:true});
      });
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;
    const showCustomizedDialogOnMobile = this.state.notificationMessages[0]?.content.length < 134; // if it's less then show customized dialog
    return(
      <React.Fragment>

       {this.props.show && this.state.notificationMessages.length > 0 && 
       
       (
       <Dialog
          open={this.state.open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: isMobile ? showCustomizedDialogOnMobile ? classes.dialogMobile :null : classes.dialog }}
        >
          <DialogTitle hidden id="alert-dialog-title">{this.state.notificationMessages[0].title}</DialogTitle>
          <DialogContent style={ isMobile ? showCustomizedDialogOnMobile ? {height:"300px",width:"300px"} : null : {height:"500px",width:"500px"}}>
            <DialogContentText id="alert-dialog-description">
             <div class="container">
              <div class={isMobile ? showCustomizedDialogOnMobile ? classes.dialogContent:"" :classes.dialogContent}>{this.state.notificationMessages[0].content}</div>
            </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{color:'white'}} onClick={this.handleClose} variant="contained" color="primary">
             { this.state.notificationMessages.length > 1 ? "Next" : "Got it"}
            </Button>
          </DialogActions>
        </Dialog>
        
        )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
    memNo: state.AuthReducer.memNo,
    notificationData: state.NotificationReducer.notifications
  });

const initNotificationActions = dispatch => notificationService => {
    const actions = NotificationActions(notificationService);
    return {
      fetchNotifications: (memNo) => dispatch(actions.fetchNotifications(memNo)),
      putMemberNotification: (id,memNo) => dispatch(actions.putMemberNotification(id,memNo))
    };
  };

const mapDispatchToProps = dispatch => ({
    notificationActions: initNotificationActions(dispatch),
  });
  
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notifications));
