/* eslint-disable no-undef */
let SITE_URL = '';

if(process.env.NODE_ENV == 'development') {
  SITE_URL = 'http://localhost:3000/';
} else {
	if(window.location.origin.includes('qaportal.narfo.co.za')) {
		SITE_URL = 'http://qaportal.narfo.co.za/';
	} else if(window.location.origin.includes('portal.narfo.co.za')) {
		SITE_URL = 'https://portal.narfo.co.za/';
	}
}

export default SITE_URL;
