import { withStyles } from "@material-ui/core/styles";
import Axios from "axios";
import React, { Component } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { BASE, GET_UPDATELICENCE_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as constFile from "../../../store/constantFile/ConstFile";
import LicensedFireArmHeader from "./LicensedFireArmHeader";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    width: "60%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginLeft: "20%",
    marginTop: "1%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: "5px",
    backgroundColor: "white",
    color: "black",
    borderColor: "#000000",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50,
    '&:hover': {
      backgroundColor: "#F5A623",
      color: 'White',
      border: "none"
    },
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class UpdateCustomLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      showValid: true
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.checkNotEmpty()
    if (this.state.isValid
      && !(this.props.newlicenceDetails.LicenseName === "")
      && !(this.props.newlicenceDetails.Model === "")
      && !(this.props.newlicenceDetails.Code === "")
      && !(this.props.newlicenceDetails.FirstIssueDate === "")
      && !(this.props.newlicenceDetails.LicenseNo === "")
      && !(this.props.newlicenceDetails.IssueDate === "")
      && !(this.props.newlicenceDetails.ExpiryDate === "")
      && !(this.props.newlicenceDetails.Issued === "")) {

      const JSONbody = {
        LicenseNo: this.props.newlicenceDetails.LicenseNo,
        LicenseName: this.props.newlicenceDetails.LicenseName,
        Model: this.props.newlicenceDetails.Model,
        Code: this.props.newlicenceDetails.Code,
        SerialNumber: this.props.newlicenceDetails.SerialNumber,
        FirstIssueDate: this.props.newlicenceDetails.FirstIssueDate,
        IssueDate: this.props.newlicenceDetails.IssueDate,
        ExpiryDate: this.props.newlicenceDetails.ExpiryDate,
        Issued: this.props.newlicenceDetails.Issued,
        MemNo: (this.props.memberState.isAdmin
          ? this.props.MemberV.memNo
          : this.props.userDetails.memberNumber),
        Custom: constFile.CTRUE
      }
      Axios({
        url: BASE + GET_UPDATELICENCE_URL,
        data: JSONbody,
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
        .then(this.props.onCurrentLicenseTabChange("0", "currentLicenseTab"))
    }
  };
  handleChange = name => event => {
    this.props.onAddLicenseDetailsChange(event.target.value, [name]);
    this.setState({ showValid: true })
    this.setState({ isValid: false })
    this.checkNotEmpty()
  };

  checkNotEmpty = () => {
    let V = true;
    if (this.props.newlicenceDetails.LicenseName === "" ||
      this.props.newlicenceDetails.LicenseName === null ||
      this.props.newlicenceDetails.LicenseName === undefined ||
      this.props.newlicenceDetails.Model === "" ||
      this.props.newlicenceDetails.Model === null ||
      this.props.newlicenceDetails.Model === undefined ||
      this.props.newlicenceDetails.FirstIssueDate === "" ||
      this.props.newlicenceDetails.FirstIssueDate === null ||
      this.props.newlicenceDetails.FirstIssueDate === undefined ||
      this.props.newlicenceDetails.LicenseNo === "" ||
      this.props.newlicenceDetails.LicenseNo === null ||
      this.props.newlicenceDetails.LicenseNo === undefined ||
      this.props.newlicenceDetails.Issued === "" ||
      this.props.newlicenceDetails.Issued === null ||
      this.props.newlicenceDetails.Issued === undefined ||
      this.props.newlicenceDetails.IssueDate === "" ||
      this.props.newlicenceDetails.IssueDate === null ||
      this.props.newlicenceDetails.IssueDate === undefined ||
      this.props.newlicenceDetails.ExpiryDate === "" ||
      this.props.newlicenceDetails.ExpiryDate === null ||
      this.props.newlicenceDetails.ExpiryDate === undefined) { V = false; }
    this.setState({
      isValid: V
    });
  }

  componentDidMount() {
    this.setState({ showValid: false })
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (this.state.showValid) {
        if (!value) {
          return false;
        }
        if (value.length < 0) {
          return false;
        }
      }
      return true;
    });

  }

  render() {
    const { classes } = this.props;
    return (

      <div className="container">
        <div className="row">
          <LicensedFireArmHeader />

        </div>
        <div className={classes.overall}>
          <form className={classes.container} noValidate autoComplete="off">
            <ValidatorForm
              ref="form"
              align="center"
              autoComplete="off"
              onSubmit={this.handleSubmit}
            >
              <TextValidator
                id="OutlinedSection"
                label="Enter License Name"
                className={classes.textField}
                value={this.props.newlicenceDetails.LicenseName}
                onChange={this.handleChange("LicenseName")}
                name="LicenseName"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the License Name",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <TextValidator
                id="OutlinedSection"
                label="Model"
                className={classes.textField}
                value={this.props.newlicenceDetails.Model}
                onChange={this.handleChange("Model")}
                name="Model"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Model",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                id="OutlinedSection"
                label="License Number"
                className={classes.textField}
                value={this.props.newlicenceDetails.LicenseNo}
                onChange={this.handleChange("LicenseNo")}
                name="LicenseNo"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the License Number",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                id="OutlinedSection"
                label="First Issued"
                className={classes.textField}
                value={this.props.newlicenceDetails.FirstIssueDate.substring(0, 10)}
                onChange={this.handleChange("FirstIssueDate")}
                name="FirstIssueDate"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the First Date of Issue",
                  "This field is required"
                ]}
                InputLabelProps={{
                  shrink: true
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                id="OutlinedSection"
                label="Issue Date"
                className={classes.textField}
                value={this.props.newlicenceDetails.IssueDate.substring(0, 10)}
                onChange={this.handleChange("IssueDate")}
                name="IssueDate"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Issue Date",
                  "This field is required"
                ]}
                InputLabelProps={{
                  shrink: true
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                id="OutlinedSection"
                label="Expiry Date"
                className={classes.textField}
                value={this.props.newlicenceDetails.ExpiryDate.substring(0, 10)}
                onChange={this.handleChange("ExpiryDate")}
                name="ExpiryDate"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter the Expiry Date",
                  "This field is required"
                ]}
                InputLabelProps={{
                  shrink: true
                }}
                type="date"
                margin="normal"
                variant="outlined"
              />

              <TextValidator
                id="OutlinedSection"
                label="Issued"
                className={classes.textField}
                value={this.props.newlicenceDetails.Issued}
                onChange={this.handleChange("Issued")}
                name="Issued"
                validators={["isEmpty", "required"]}
                errormessages={[
                  "Please Enter Who Issued the license",
                  "This field is required"
                ]}
                margin="normal"
                variant="outlined"
              />
              <div className={classes.footer}>
                <button
                  variant="contained"
                  className={classes.button}
                  onClick={() =>
                    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
                  }
                >
                  Previous
            </button>
                <div className={classes.grow} />
                <button
                  variant="contained"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  Update
            </button>
              </div>
            </ValidatorForm>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.currentActivityTab,
    currentLicenseTab: state.membersAreaReducer.currentLicenseTab,
    userDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onAddLicenseDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UpdateCustomLicense));
