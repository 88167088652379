import { makeStyles } from '@material-ui/styles';

// import sportShootingImg1BackgroundImg from "../assetsNew/dedicatedSportsShooter.png"
// import spApplicationImg from "../assetsNew/spApplicationImg.jpg";
import rulesBackgroundImg from "../assetsNew/rulesBackgroundImg.png";
// import applicationsBackgroundImg from "../assetsNew/applicationsBackgroundImg.png";
import targetsDownloadImg from "../assetsNew/target.png";

export const useStylesSportShooting = makeStyles(theme => ({
    container:{
        marginTop:'-22px'
    },
    SportShootingContainer: {
        paddingLeft: '3.5em', 
        paddingRight: '3.5em',
        marginTop: '3em',
        marginBottom: '3em',
        width: '100%',
        height: '100%'
    },
    SportShootingContainerMobile: {
        padding: '2em', 
    },
   
    
    rulesBackground: {
        backgroundImage: `url(${rulesBackgroundImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '70%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: '400px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '300px'
        }
    },

    targetsDownloadBackground: {
        backgroundImage: `url(${targetsDownloadImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // height: '100%',
        // width: '100%',
        // [theme.breakpoints.down('md')]: {
        //     height: '400px'
        // },
        // [theme.breakpoints.down('sm')]: {
        //     height: '300px'
        // }
    },

    overviewContainer: {
        height: '500px'
    },
    overviewContainerMobile: {
        height: '400px'
    },
    overviewContainerMobileSmall: {
        height: '300px'
    },
    overviewText: {
        color: 'white',
        textShadow: '1px 2px black',
        marginLeft: '14em',
        marginTop: '9em',
        marginBottom: '0.5em'
    },
    overviewTextMobile: {
        color: 'white',
        textShadow: '1px 2px black',
        marginLeft: '0.5em',
        marginTop: '5em',
        marginBottom: '0.5em'
    },
    headerSpacing: {
        marginBottom: '0.5em'
    },
    listContainer: {
        marginLeft: '2.5em',
        marginBottom: '1em'
    },
    anchorPoint: {
        position: 'absolute'
    },
    downloadText: {
        color: '#4F36A6',
        textDecoration: 'underline'
    },
    spanText: {
        color: '#4F36A6'
    },
    targetDownloadsContainer: {
        paddingLeft:"2em", 
        paddingRight:"2em",
        marginBottom:"2em"
    }
}))