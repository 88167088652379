export const NEW_ACT_ACTIVITY_EVENT = 'NEW_ACT_ACTIVITY_EVENT',
    NEW_ACT_ADD_FILE = 'NEW_ACT_ADD_FILE',
    NEW_ACT_REMOVE_FILE = 'NEW_ACT_REMOVE_FILE',
    NEW_ACT_SET_DATE = 'NEW_ACT_SET_DATE',
    NEW_ACT_SET_ASSOCIATION = 'NEW_ACT_SET_ASSOCIATION',
    NEW_ACT_SET_MEMBER_NUMBER = 'NEW_ACT_SET_MEMBER_NUMBER',
    NEW_ACT_SET_ACTIVITY_EVENT = 'NEW_ACT_SET_ACTIVITY_EVENT',
    NEW_ACT_TOGGLE_NARFO_EVENT = 'NEW_ACT_TOGGLE_NARFO_EVENT',
    NEW_ACT_SET_EVENT_DESCRIPTION = 'NEW_ACT_SET_EVENT_DESCRIPTION',
    NEW_ACT_SET_ACTIVITY_TYPE = 'NEW_ACT_SET_ACTIVITY_TYPE',
    NEW_ACT_SET_FIREARM_TYPE = 'NEW_ACT_SET_FIREARM_TYPE',
    NEW_ACT_SET_OPTICS = 'NEW_ACT_SET_OPTICS',
    NEW_ACT_SET_SCORE = 'NEW_ACT_SET_SCORE',
    NEW_ACT_SET_CALIBER = 'NEW_ACT_SET_CALIBER',
    NEW_ACT_UPLOAD_BEGIN = 'NEW_ACT_UPLOAD_BEGIN',
    NEW_ACT_UPLOAD_FAILURE = 'NEW_ACT_UPLOAD_FAILURE',
    NEW_ACT_UPLOAD_SUCCESS = 'NEW_ACT_SET_SUCCESS',
    NEW_ACT_GET_ACTIVITIES_SUCCESS = 'NEW_ACT_GET_ACTIVITIES_SUCCESS',
    NEW_ACT_GET_ACTIVITIES_BEGIN = 'NEW_ACT_GET_ACTIVITIES_BEGIN',
    NEW_ACT_GET_ACTIVITIES_FAILURE = 'NEW_ACT_GET_ACTIVITIES_FAILURE',
    NEW_ACT_GET_OPTICS_SUCCESS = 'NEW_ACT_GET_OPTICS_SUCCESS',
    NEW_ACT_GET_OPTICS_BEGIN = 'NEW_ACT_GET_OPTICS_BEGIN',
    NEW_ACT_GET_OPTICS_FAILURE = 'NEW_ACT_GET_OPTICS_FAILURE',
    NEW_ACT_GET_FIREARM_TYPES_SUCCESS = 'NEW_ACT_GET_FIREARM_TYPES_SUCCESS',
    NEW_ACT_GET_FIREARM_TYPES_BEGIN = 'NEW_ACT_GET_FIREARM_TYPES_BEGIN',
    NEW_ACT_GET_FIREARM_TYPES_FAILURE = 'NEW_ACT_GET_FIREARM_TYPES_FAILURE',
    NEW_ACT_GET_ASSOCIATIONS_SUCCESS = 'NEW_ACT_GET_ASSOCIATIONS_SUCCESS',
    NEW_ACT_GET_ASSOCIATIONS_BEGIN = 'NEW_ACT_GET_ASSOCIATIONS_BEGIN',
    NEW_ACT_GET_ASSOCIATIONS_FAILURE = 'NEW_ACT_GET_ASSOCIATIONS_FAILURE',
    NEW_ACT_GET_ACTIVITY_EVENT_SUCCESS = 'NEW_ACT_GET_ACTIVITY_EVENT_SUCCESS',
    NEW_ACT_GET_ACTIVITY_EVENT_BEGIN = 'NEW_ACT_GET_ACTIVITY_EVENT_BEGIN',
    NEW_ACT_GET_ACTIVITY_EVENT_FAILURE = 'NEW_ACT_GET_ACTIVITY_EVENT_FAILURE',
    NEW_ACT_TOGGLE_FILE_INPUT = 'NEW_ACT_TOGGLE_FILE_INPUT',
    NEW_ACT_RESET = 'NEW_ACT_RESET';


