import * as Types from "./ApproveNewEndorsementsActionTypes";
import { approveEndorsementApplication, sendApprovalEmail } from "./ApproveNewEndorsementsAPI";

export const approvePendingEndorsementBeginAC = () => {
    return {
        type: Types.APPROVE_ENDORSEMENT_BEGIN,
        approveNewEndorsementsState: {
            approvalStatus: {
                loading: true,
                error: null
            }
        }
    };
};

export const sendEmailBeginAC = () => {
    return {
        type: Types.EMAIL_BEGIN,
        approveNewEndorsementsState: {
            emailStatus: {
                loading: true,
                error: null
            }
        }
    };
};

export const sendEmailSuccessAC = (response) => {
    return {
        type: Types.EMAIL_SUCCESS,
        approveNewEndorsementsState: {
            emailStatus: {
                loading: false,
                response
            },
        }
    };
};

export const approvePendingEndorsementSuccessAC = (response) => {
    return {
        type: Types.APPROVE_ENDORSEMENT_SUCCESS,
        approveNewEndorsementsState: {
            approvalStatus: {
                loading: false,
                response
            },
        }
    };
};

export const sendEmailErrorAC = (error) => {
    return {
        type: Types.EMAIL_ERROR,
        approveNewEndorsementsState: {
            emailStatus: {
                loading: false,
                error
            }
        }
    };
};

export const approvePendingEndorsementErrorAC = (error) => {
    return {
        type: Types.APPROVE_ENDORSEMENT_ERROR,
        approveNewEndorsementsState: {
            approvalStatus: {
                loading: false,
                error
            }
        }
    };
};

export const sendEmail = (emailParam) => async (dispatch) => {
    dispatch(sendEmailBeginAC());
    try {
        const response = await sendApprovalEmail(emailParam);
        dispatch(sendEmailSuccessAC(response));
        return await response;
    } catch (error) {
        dispatch(sendEmailErrorAC(error));
    }
}

export const approvePendingEndorsement = (id, status, reason) => async (dispatch) => {
    dispatch(approvePendingEndorsementBeginAC());
    try {
        const response = await approveEndorsementApplication(id, status, reason);
        dispatch(approvePendingEndorsementSuccessAC(response));
        return response;
    } catch (error) {
        dispatch(approvePendingEndorsementErrorAC(error));
    }
}

export const setReasonAC = (reason) => {
    return {
        type: Types.SET_REASON,
        approveNewEndorsementsState: {reason}
    }
}
export const setReason = (reason) => (dispatch) => dispatch(setReasonAC(reason));

export const setCurrentEndorsementAC = (application) => {
    return {
        type: Types.SET_CURRENT_ENDORSEMENT,
        approveNewEndorsementsState: {application}
    }
}
export const setCurrentEndorsement = (application) => (dispatch) => dispatch(setCurrentEndorsementAC(application));
