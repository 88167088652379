import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core/";

import * as actionTypes from "../../../store/actions/actionTypes";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  formControl: {
    margin: theme.spacing.unit,
    display: "flex"
  },
  formControl1: {
    margin: theme.spacing.unit,
    display: "flex",
    flex: 1
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  },
  grow: {
    flex: 1
  }
});

function FilterBar(props) {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <FormControl className={classes.formControl1}>
        <InputLabel className={classes.select} htmlFor="ActivityTypeSelector">
          Activity Type
        </InputLabel>
        <Select
          className={classes.select}
          value={props.actTypeFilter}
          onChange={event =>
            props.onActivityTypeFilterChange(event.target.value)
          }
          defaultValue="Activity Type"
          inputProps={{
            name: "ActivityType",
            id: "ActivityTypeSelector"
          }}
        >
          <MenuItem value="Activity Type">
            <em>All</em>
          </MenuItem>
          <MenuItem value="Practical">Practical Assessment</MenuItem>
          <MenuItem value="Dedicated Sports Shooting Activity">
            Dedicated Sport Shooting Activity
          </MenuItem>
        </Select>
      </FormControl>
      <div className={classes.grow} />
      <FormControl className={classes.formControl1}>
        <InputLabel className={classes.select} htmlFor="ActivityStatusSelector">
          Status
        </InputLabel>
        <Select
          className={classes.select}
          value={props.actStatusFilter}
          onChange={event =>
            props.onActivityStatusFilterChange(event.target.value)
          }
          defaultValue="All"
          inputProps={{
            name: "ActivityStatus",
            id: "ActivityStatusSelector"
          }}
        >
          <MenuItem value="Status">
            <em>All</em>
          </MenuItem>
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Pending"}>Pending</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    actTypeFilter: state.activitiesReducer.activityAdminTypeFilter,
    actStatusFilter: state.activitiesReducer.activityAdminStatusFilter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onActivityTypeFilterChange: newTypeFilter =>
      dispatch({
        type:
          actionTypes.ACTIVITY_ACTIONS
            .UPDATE_ADMIN_ACTIVITY_TYPE_FILTERIVITY_TYPE_FILTER,
        payload: newTypeFilter
      }),
    onActivityStatusFilterChange: newStatusFilter =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_STATUS_FILTER,
        payload: newStatusFilter
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FilterBar));
