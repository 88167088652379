import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core/';
import {
  Visibility, 
  VisibilityOff 
} from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  row: {
    flexDirection: 'row',
  },
  col: {
    flexDirection: 'column',
  },
  labelAbove: ''
}));

const MappedInput = ( props ) => {
  let inputElement = null;
  let errorFlag = false;
  const classes = useStyles();

  if (props.invalid && props.shouldValidate && props.touched) {
    errorFlag = true;
  }

  switch ( props.elementType ) {
  case ('input'):
    inputElement = (
      <React.Fragment>
        <InputLabel htmlFor={props.key} >
          {props.elementConfig.placeholder}
        </InputLabel>
        <Input
          error={errorFlag}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed} 
          {...props.endAdornment}
        />
      </React.Fragment>
    );
    break;
  case ('password'):
    inputElement = (
      <React.Fragment>
        <InputLabel htmlFor={props.key} >
          {props.elementConfig.placeholder}
        </InputLabel>
        <Input
          error={errorFlag}
          type={props.showPassword ? 'text' : 'password'}
          value={props.value}
          onChange={props.changed} 
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={props.showHide}
                onMouseDown={props.showHide}
              >
                {props.showPassword ? <Visibility /> : <VisibilityOff/>}
              </IconButton>
            </InputAdornment>
          }
        />
      </React.Fragment>
    );
    break;
  
  case ('select'):
    inputElement = (
      <React.Fragment>
        <FormLabel component="label">
          {props.elementConfig.placeholder}
        </FormLabel>
        <Select
          inputProps={{MenuProps: {disableScrollLock: true}}}
          id={props.id}
          value={props.value}
          onChange={props.changed}
          error={errorFlag}
        >
          <MenuItem value="-" key={props.id}>Please Select</MenuItem>
          {props.elementConfig.options.map(option => 
            <MenuItem
              key={`${props.id} ${option.value}`}
              value={option.value}
            >
              {option.label}
            </MenuItem>)
          }
        </Select>
      </React.Fragment>
    );
    break;
  case ('radioGroup'):
    inputElement = (
      <React.Fragment>
        <FormLabel component="label">
          {props.elementConfig.placeholder}
        </FormLabel>
        <RadioGroup
          className={classes[props.elementConfig.direction || 'col']}
          aria-label={props.elementConfig.name}
          name={props.elementConfig.name}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.radios.map((radio) => 
            <FormControlLabel
              key={radio.value}
              control={<Radio />} 
              value={radio.value} label={radio.label}
            />)
          }
        </RadioGroup>
      </React.Fragment>
    );
    break;

  case ('checkBox'):
    inputElement = (
      <React.Fragment>
        <FormLabel component="label">
          {props.elementConfig.placeholder}
        </FormLabel>
        <FormControlLabel
          aria-label={props.elementConfig.name}
          name={props.elementConfig.name}
          onChange={props.changed}
          control={<Checkbox color="primary" />} 
          value={props.elementConfig.accept.value}
          label={props.elementConfig.accept.label}
          
        />
      </React.Fragment>
    );
    break;
  
  case ('calendar'):
    inputElement = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={errorFlag}
          autoOk
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={props.value}
          InputAdornmentProps={{ position: 'end' }}
          onChange={props.changed}
        />
      </MuiPickersUtilsProvider>
    );
    break;
  case ('textArea'):
    inputElement = (
      <TextField
        id={props.id}
        error={errorFlag}
        value={props.value}
        {...props.elementConfig}
        onChange={props.changed}
      />
    );
    break;
  default:
    inputElement = (
      <React.Fragment>
        <InputLabel htmlFor={props.key} >
          {props.elementConfig.placeholder}
        </InputLabel>
        <Input
          error={errorFlag}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed} 
          {...props.endAdornment}
        />
      </React.Fragment>
      
    );
  }

  return (
    <React.Fragment>
      
      {inputElement}
    </React.Fragment>
  );
};

export default MappedInput;