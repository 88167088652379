import axios from 'axios';
import API_URL from './apiUrl';

const getMemberDedicatedStatusType = headers => id => axios
  .get(`${API_URL}/DedicatedStatus/DedicatedType/${id}`, { headers });

const getApprovedDedicatedStatus = headers => id => axios
  .get(`${API_URL}/DedicatedStatus/approvedDS/${id}`, { headers });

const getDedicatedStatusActivities = headers => id => axios
  .get(`${API_URL}/Member/getLoggedInMember/${id}`, { headers })
  .then((res) => res.data.dedicatedStatus);

const getAllMemberDeducatedStatuses = headers => memNo => axios
  .get(`${API_URL}/DedicatedStatus/GetAllMemberDedicatedStatuses?memNo=${memNo}`, { headers });

const getAllDeducatedStatuses = headers => data => axios
  .post(`${API_URL}/DedicatedStatus/post/Dedicatedfind`, data, { headers });

const addNewApplication = headers => data => axios({
  url: `${API_URL}/DedicatedStatus/new/`,
  method: 'post',
  data: data,
  headers: headers
});

const getDSApllicationRequirements = headers => (dsType, memNo) => axios({
  url: `${API_URL}/DedicatedStatus/DSApplicationRequirements/${dsType}/${memNo}`,
  method: 'get',
  headers: headers
})

const getDedicatedSatusApllicationRequirements = headers => (dsType, memNo) => axios({
  url: `${API_URL}/DedicatedStatus/ApplicationRequirements?dsType=${dsType}&memNo=${memNo}`,
  method: 'get',
  headers: headers
})

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getMemberDedicatedStatusType: getMemberDedicatedStatusType(headers),
    getApprovedDedicatedStatus: getApprovedDedicatedStatus(headers),
    getDedicatedStatusActivities: getDedicatedStatusActivities(headers),
    getAllDeducatedStatuses: getAllDeducatedStatuses(headers),
    getAllMemberDeducatedStatuses: getAllMemberDeducatedStatuses(headers),
    addNewApplication: addNewApplication(headers),
  	getDSApllicationRequirements: getDSApllicationRequirements(headers),
    getDedicatedSatusApllicationRequirements: getDedicatedSatusApllicationRequirements(headers)
  };
}
