import {
  SET_FIREARM_LICENCE_STATUS,
  SET_FIREARM_LICENCE_LIST,
  SET_FIREARM_LICENCE_RECORD,
  SET_FIREARM_LICENCE_APPLICATION_LIST
} from './types';
import {
  getDayDifferenceFromToday
} from '../../shared/helpers';

const fetchFirearmLicenceStatus = service => id => dispatch => service.getFirearmLicence(id)
  .then((res) => {
    let expired = 0;
    let toBeRenewed = 0;
    let firearms = res.data.length;

    res.data.forEach(licence => {
      const dayDiff = getDayDifferenceFromToday(licence.expiryDate);
      if(dayDiff < 0) {
        expired += 1;
      }
      //Check if needs to be renewed
      if((dayDiff < 120) && !(dayDiff < 0)) 
        toBeRenewed += 1;
    });
    return { expired, toBeRenewed, firearms };
  })
  .then((res) => dispatch({ type: SET_FIREARM_LICENCE_STATUS, payload: res }))
  .catch(() => dispatch({ type: SET_FIREARM_LICENCE_STATUS, payload: {} }));

const fetchMemberLicenseReminders = service => id => dispatch => service.getFirearmLicence(id)
  .then((res) => dispatch({ type: SET_FIREARM_LICENCE_LIST, payload: res.data }))
  .catch(() => dispatch({ type: SET_FIREARM_LICENCE_LIST, payload: [] }));

const fetchMemberLicenseReminderRecord = service => id => dispatch => service.getFirearmLicenceByID(id)
  .then(res => dispatch({ type: SET_FIREARM_LICENCE_RECORD, payload: res.data }))
  .catch(() => dispatch({ type: SET_FIREARM_LICENCE_RECORD, payload: {} }));

  //License Applications 
const fetchMemberLicenseApplications = service => memNo => dispatch => service.getLicenseApplicationsByMemNo(memNo)
  .then((res) => dispatch({ type: SET_FIREARM_LICENCE_APPLICATION_LIST, payload: res.data }))
  .catch(() => dispatch({ type: SET_FIREARM_LICENCE_APPLICATION_LIST, payload: [] }));

export default function(licenceService) {
  return {
    fetchFirearmLicenceStatus: fetchFirearmLicenceStatus(licenceService),
    fetchMemberLicenseReminders: fetchMemberLicenseReminders(licenceService),
    fetchMemberLicenseReminderRecord: fetchMemberLicenseReminderRecord(licenceService),
    fetchMemberLicenseApplications: fetchMemberLicenseApplications(licenceService)
  };
}
