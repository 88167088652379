import {
  SET_ACTIVITY_TYPE,
  SET_DISCIPLINES,
  SET_DOCUMENT_TYPE,
  SET_EVENT_LOOKUP,
  SET_FIREARM_TYPES,
  SET_FIREARM_TYPES_LOOKUP,
  SET_OPTICS_LOOKUP,
  SET_ACTION_TYPE,
  SET_ENDORSEMENT_APPLICATION_TYPE,
  SET_SECTION_LOOKUP,
  SET_PURPOSE_LOOKUP,
  SET_SPECIE_TYPE
} from './types';

const INITIAL_STATE = {
  eventLookup: [],
  disciplines: [],
  firearmTypes: [],
  firearmTypeLookup: [],
  opticsLookup: [],
  activityType: [],
  specieType:[],
  firearmActionType: [],
  sectionLookup: [],
  documentTypes: [
    {
      documentLookupId: 0,
      documentType: 'General'
    },
    {
      documentLookupId: 1,
      documentType: 'Narfo'
    },
    {
      documentLookupId: 2,
      documentType: 'Dedicated Status'
    },
    {
      documentLookupId: 3,
      documentType: 'Hunting'
    },
    {
      documentLookupId: 4,
      documentType: 'Member'
    },
    {
      documentLookupId: 5,
      documentType: 'Target'
    },
  ],
  endorsementApplicationType: [
    {
      applicationTypeLookupId: 0,
      applicationType: 'Self Defence'
    },
    {
      applicationTypeLookupId: 1,
      applicationType: 'Hunting'
    },
    {
      applicationTypeLookupId: 2,
      applicationType: 'Sports Shooting'
    }
  ],
  endorsementPurposeType: [
    {
      purposeTypeLookupId: 0,
      purposeType: 'New Lisence'
    },
    {
      purposeTypeLookupId: 1,
      purposeType: 'Renewal'
    },
  ]
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

  case SET_DISCIPLINES:
    return { ...state, disciplines: payload };

  case SET_EVENT_LOOKUP:
    return { ...state, eventLookup: payload };

  case SET_FIREARM_TYPES:
    return {...state, firearmTypes: payload};

  case SET_FIREARM_TYPES_LOOKUP:
    return {...state, firearmTypeLookup: payload};
    
  case SET_OPTICS_LOOKUP:
    return {...state, opticsLookup: payload};

  case SET_ACTIVITY_TYPE:
    return {...state, activityType: payload};
    
  case SET_SPECIE_TYPE:
    return {...state, specieType: payload};

  case SET_DOCUMENT_TYPE:
    return {...state, documentTypes: payload};

  case SET_ACTION_TYPE:
    return {...state, firearmActionType: payload};
    
  case SET_ENDORSEMENT_APPLICATION_TYPE:
    return {...state, endorsementApplicationType: payload};

  case SET_SECTION_LOOKUP:
    return {...state, sectionLookup: payload};
    
  case SET_PURPOSE_LOOKUP:
    return {...state, endorsementPurposeType: payload};

  default:
    return state;
  }
};
