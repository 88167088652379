import {
  FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN,
  FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS,
  FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE,
  TOGGLE_SHOW_CHILDREN
} from "../actions/lmsActionTypes";

const initialState = {
  error: null,
  loading: null,
  payload: []
}

const reducer = (state = initialState, action) => {
  switch(action.type){
    case FETCH_COURSE_MODULES_WITH_CHILDREN_BEGIN:
      return {
        ...state,
        loading: true
      }
    case FETCH_COURSE_MODULES_WITH_CHILDREN_SUCCESS:
      return {
        ...state,
        loading: false,
        payload: [...action.payload]
      }
    case FETCH_COURSE_MODULES_WITH_CHILDREN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case TOGGLE_SHOW_CHILDREN:
      const id = action.payload;
      let modules = [...state.payload];
      modules.some(mod => {
        if(mod.courseModuleId === id){
          mod.showChildren = !mod.showChildren;
          return null;
        }
        return null;
      });
      return {
        ...state,
        payload: [...modules]
      }
    default:
      return state;
  }
}

export default reducer;
