import * as actionTypes from "../actions/actionTypes";

const initialState = {
  addActivityDetails: {
    ActivityType: "",
    MemNo: "",
    ActivityDate: null,
    ActivityDescription: "",
    DateSubmitted: null,
    FirearmType: "",
    Caliber: "",
    PostalShoot: 0,
    Score: 0,
    MemNoNavigation: null,
    FileUploadStatus: "Idle",
    FilesCount: 0,
    Status: ""
  },
  approvedMemActivities: [],
  adminViewActivityDetails: {},
  activityTypeFilter: "",
  activityDateFilter: "",
  showActivityFilterBar: false,
  userActivities: [],
  activityAdminTypeFilter: "",
  activityAdminStatusFilter: "Status",
  showAdminActivityFilterBar: false,
  adminActivities: []
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.APPROVED_MEMBER_ACTIVITY:
      return {
        ...state,
        approvedMemActivities: action.approvedMemActivities
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ADD_ACTIVITY_DETAILS:
      const newActivityDetails = {
        ...state.addActivityDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        addActivityDetails: newActivityDetails
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_VIEW_ACTIVITY_DETAILS:
      const newAddAdminViewActivityDetails = {
        ...state.addAdminViewActivityDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        addAdminViewActivityDetails: newAddAdminViewActivityDetails
      };
    case actionTypes.ACTIVITY_ACTIONS.SET_ADMIN_VIEW_ACTIVITY_DETAILS:
      return {
        ...state,
        adminViewActivityDetails: { ...action.payload }
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ACTIVITY_TYPE_FILTER:
      return {
        ...state,
        activityTypeFilter: action.payload
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ACTIVITY_DATE_FILTER:
      return {
        ...state,
        activityDateFilter: action.payload
      };
    case actionTypes.ACTIVITY_ACTIONS.TOGGLE_SHOW_ACTIVITY_FILTER_BAR:
      return {
        ...state,
        showActivityFilterBar: !state.showActivityFilterBar
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_USER_ACTIVITIES:
      return {
        ...state,
        userActivities: action.payload
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_TYPE_FILTER:
      return {
        ...state,
        activityAdminTypeFilter: action.payload
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_STATUS_FILTER:
      return {
        ...state,
        activityAdminStatusFilter: action.payload
      };
    case actionTypes.ACTIVITY_ACTIONS.TOGGLE_SHOW_ADMIN_ACTIVITY_FILTER_BAR:
      return {
        ...state,
        showAdminActivityFilterBar: !state.showAdminActivityFilterBar
      };
    case actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITIES:
      return {
        ...state,
        adminActivities: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
