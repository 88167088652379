import React, { Component } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable
} from "react-beautiful-dnd";
import Drag from "@material-ui/icons/DragIndicator";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export default class Folders extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modules: [],
      numChildsSelected: 0,
      numParentsSelected: 0,
      moduleFocusedId: 0,
      idArray: []
    }
    this.mainDroppable = "folders";
  }

  getList = id =>
    this.state.modules.find(mod => mod.courseModuleId === id).courseMaterial;

  apiParentCallback = (from, to) => {
    const fromId = this.state.modules[to].courseModuleId;
    const toId = this.state.modules[from].courseModuleId;
    this.props.apiDragParentCallBack(fromId, toId);
  }

  apiChildCallback = (from, to, listOfChildren) => {
    const fromId = listOfChildren[to].courseMaterialId;
    const toId = listOfChildren[from].courseMaterialId;
    this.props.apiDragChildCallBack(fromId, toId);
  }

  apiChildTransfer = (sourceMaterial, destinationModule) => {
    this.props.apiTransferChildCallback(sourceMaterial, destinationModule);
  }

  onDragEnd = (result) => {
    // dropped outside the list
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    let existingItems = this.state.modules;
    let state = {};
    if (source.droppableId === destination.droppableId) {
      let modules = [];
      if (source.droppableId === this.mainDroppable) {
        modules = reorder(
          existingItems,
          source.index,
          destination.index
        );
        state = { modules };
        this.apiParentCallback(source.index, destination.index);
      } else {
        modules = reorder(
          this.getList(source.droppableId),
          source.index,
          destination.index
        );

        const existingIndex = this.state.modules
          .findIndex(item => item.courseModuleId === source.droppableId);
        let existing = existingItems[existingIndex];
        this.apiChildCallback(source.index, destination.index, existing.courseMaterial);
        existing.courseMaterial = [...modules]

        state = {
          ...this.state,
          modules: [...existingItems]
        }
      }
      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.apiChildTransfer(result[destination.droppableId][parseInt(destination.index)]
        .courseMaterialId, destination.droppableId);
      const keys = Object.keys(result);
      let existingIndex = this.state.modules.findIndex(item => parseInt(item.courseModuleId) === parseInt(keys[0]));
      let existing = existingItems[existingIndex];
      existing.courseMaterial = result[keys[0]]

      existingIndex = this.state.modules.findIndex(item => parseInt(item.courseModuleId) === parseInt(keys[1]));
      existing = existingItems[existingIndex];
      existing.courseMaterial = result[keys[1]]

      state = {
        ...this.state,
        modules: [...existingItems]
      }
      this.setState({ ...result });
    }
  }

  checkBoxCallBack = (isParent, value, array) => {
    if (isParent) {
      this.setState({
        numParentsSelected: value + this.state.numParentsSelected
      })
      this.props.checkBoxCallBack(
        value + this.state.numParentsSelected,
        this.state.numChildsSelected, array);
    } else {
      this.setState({
        numChildsSelected: value + this.state.numChildsSelected
      })
      this.props.checkBoxCallBack(this.state.numParentsSelected,
        value + this.state.numChildsSelected, array);
    }
  }

  checkBoxVisibility = (isParent) => {
    if (isParent && this.state.numChildsSelected > 0) {
      return { visibility: "hidden" }
    } else if (!isParent && this.state.numParentsSelected > 0) {
      return { visibility: "hidden" }
    } else {
      return { visibility: "visible" }
    }
  }

  checkBox = (isParent, id, name, folderId) => {
    return (
      <label id="checkBox" style={this.checkBoxVisibility(isParent)}>
        <input type="checkbox" onChange={(event) => {
          this.setState({
            moduleFocusedId: folderId
          })
          if (event.target.checked) {
            let array = this.state.idArray;
            array.push({ id: id, folder: isParent, name: name });
            this.setState({
              idArray: array
            });
            this.checkBoxCallBack(isParent, 1, array)
          } else {
            let array = this.state.idArray;
            const index = array.findIndex(a => a.id === id);
            array.splice(index, 1);
            this.setState({
              idArray: array
            });
            this.checkBoxCallBack(isParent, -1, array)
          }
        }} />
        <span></span>
      </label>
    );
  }

  child = (child, index, folderId, type) => {
    return (
      <Draggable key={child.courseMaterialId} draggableId={child.courseMaterialId} index={index}>
        {(provided, snapshot) => (
          <div
            className="module-folder-children"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              {...provided.dragHandleProps}
              id="folder-child"
            >
              <Drag />
              {this.checkBox(false, child.courseMaterialId, child.name, folderId)}
            </div>
            <div id="nameDiv"
              onClick={() => {
                if (type === 2) {
                  this.props.openEditor(true, child, 1);
                } else {
                  this.props.openEditor(true, child, 2);
                }
              }}>
              {child.name}
            </div>
          </div>
        )}
      </Draggable>
    );
  }

  showChildren = (children, folderId, type) => {
    return (
      <Droppable droppableId={folderId} type={this.mainDroppable}>
        {(provided, snapshot) => (
          <div
            className="module-folder-children-container"
            ref={provided.innerRef}
          >
            <hr />
            {children.map((child, index) => this.child(child, index, folderId, type))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  editModuleButton = (id, name, type) => {
    return (
      <div id="createResource" onClick={() => {
        this.setState({
          moduleFocusedId: id
        })
        this.props.onClick(3, id, name, type);
      }}>
        <Edit id="svgStatic" />
        <label>Edit name </label>
      </div>
    );
  }

  folder = (folder, index) => {
    return (
      <Draggable key={folder.courseModuleId} draggableId={folder.courseModuleId} index={index}>
        {(provided, snapshot) => (
          <div className="module-folder"
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <div
              id="folder"
              {...provided.dragHandleProps}
            >
              <Drag />
              {this.checkBox(true, folder.courseModuleId, folder.name)}
            </div>
            <div
              id="nameDiv"
            >
              <span style={{ width: "80%" }} onClick={() => { this.toggleShowChildren(folder.courseModuleId) }}
              >{folder.name}</span>
              <div id="menu">
                {this.editModuleButton(folder.courseModuleId, folder.name, folder.type)}
                {this.addAssessmentButtons(folder.courseModuleId)}
              </div>
            </div>
            {folder.showChildren ? this.showChildren(folder.courseMaterial, folder.courseModuleId, folder.type) : null}
          </div>
        )}
      </Draggable>
    );
  }

  addAssessmentButtons = (id) => {
    return (
      <div id="createResource" onClick={() => {
        this.setState({
          moduleFocusedId: id
        })
        this.props.onClick(4, id);
      }}>
        <Add id="svgStatic" />
        <label>Create Resource </label>
      </div>
    );
  }

  toggleShowChildren = (folderid) => {
    const modules = this.state.modules;
    const existingIndex = modules.findIndex(item => parseInt(item.courseModuleId) === parseInt(folderid));
    const existing = modules[existingIndex];
    existing.showChildren = !existing.showChildren;
    this.setState({
      modules
    })
  }

  openChildren = (folderid, value) => {
    const modules = this.state.modules;
    const existingIndex = modules.findIndex(item => parseInt(item.courseModuleId) === parseInt(folderid));
    const existing = modules[existingIndex];
    existing.showChildren = value;
    this.setState({
      modules
    })
  }


  populateModules = (modulesProp) => {
    this.setState({
      modules: modulesProp.map(mod => { mod.showChildren = false; return mod; })
    });
  }

  componentDidMount() {
    this.populateModules(this.props.modules);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.modules.length > this.props.modules.length ||
      nextProps.modules.length < this.props.modules.length) {
      this.populateModules(nextProps.modules);
    }

    if (nextProps.modules.length > 0 &&
      nextProps.modules.length === this.props.modules.length) {

      let modules = this.state.modules;
      const nextModule = nextProps.modules
        .find(mod => mod.courseModuleId === this.state.moduleFocusedId);
      if (nextModule) {
        const nextChildren = nextModule.courseMaterial;
        const index = modules
          .findIndex(mod => mod.courseModuleId === this.state.moduleFocusedId);

        if (modules[index].name !== nextModule.name) {
          modules[index].name = nextModule.name;
          this.setState({
            modules
          })
        }

        let currentChildren = modules[index].courseMaterial;
        if (nextChildren.length > currentChildren.length ||
          nextChildren.length < currentChildren.length) {
          modules[index].courseMaterial = nextChildren;
          this.setState({
            modules: nextProps.modules
          })
        }
      }
    }

    return true;
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId={this.mainDroppable} type="app">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                width: "96%",
                overflowY: "auto",
                maxHeight: "500px"
              }}>
              {this.state.modules.map((item, index) =>
                this.folder(item, index)
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }
}
