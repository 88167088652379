import { SET_DOCUMENTS } from './actionTypes';

const INITIAL_STATE = {
  documents: [],
};

export default (state = INITIAL_STATE, {type, payload }) => {
  switch(type) {
  
  case SET_DOCUMENTS:
    return {
      ...state,
      documents: payload
    };
  default:
    return state;
  }
};