import React, { Component } from "react";
import PropTypes from "prop-types";
import * as actionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  BASE,
  GET_NATIONALRANKING_URL
} from "../../API/APIendpoint";
import axios from "axios";
import NationalRankingHeader from "./NationalRankingHeader";
import { styles } from "../../assets/styles/styles";

class NationalRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      ID: 2
    };
  }

  componentDidMount() {
    axios.get(BASE + GET_NATIONALRANKING_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.setState({
        Posts: res.data
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <NationalRankingHeader />
      </div>
    );
  }
}
NationalRanking.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    newActDetails: state.nationalRankingReducer.nationalRanking,
    nationalRankingList: state.nationalRankingReducer.nationalRankingList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddNationalRankingDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_NATIONAL_RANK_LIST,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NationalRanking));
