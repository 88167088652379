import * as actionTypes from "../actions/actionTypes";
const initialState = {
  profilePicture: "",
  memberID: "",
  isExist: ""
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MEMBER_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.profilePicture,
        memberID: action.memberID
      };
    case actionTypes.UPDATE_MEMBER_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.profilePicture
      };
    case actionTypes.MEMBER_PP_RECORD_EXISTS:
      return {
        ...state,
        isExist: action.isExist
      };
    default:
      return state;
  }
};
export default reducer;
