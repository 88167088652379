import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FRONT } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import Account from "./Account";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
class MainAccount extends Component {
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
  }
  checkoutScreen = () => {
    return (
      <form action="https://sandbox.payfast.co.za/eng/process" method="POST">
        <input type="hidden" name="merchant_id" value="10012356" />
        <input type="hidden" name="merchant_key" value="6axxom6bviznq" />
        <input type="hidden" name="return_url" value={`${FRONT}Return.html`} />
        <input type="hidden" name="cancel_url" value={`${FRONT}Cancel`} />
        <input type="hidden" name="notify_url" value={`${FRONT}Notify`} />
        <input type="hidden" name="name_first" value="John" />
        <input type="hidden" name="name_last" value="Doe" />
        <input type="hidden" name="email_address" value="john@doe.com" />

        <input type="hidden" name="m_payment_id" value="01AB" />
        <input type="hidden" name="amount" value="100.00" />
        <input type="hidden" name="item_name" value="Test Item" />
        <input type="hidden" name="item_description" value="A test product" />
        <input type="hidden" name="custom_int1" value="2" />
        <input
          type="hidden"
          name="custom_str1"
          value="Extra order information"
        />

        <input type="hidden" name="email_confirmation" value="1" />
        <input
          type="hidden"
          name="confirmation_address"
          value="ssoginga@retrorabbit.co.za"
        />

        <button
          type="submit"
          className="btn btn-warning form-control text-light"
        >
          Renew Membership
        </button>
      </form>
    );
  };

  DateDifference = (datePart, fromDate, todate) => {
    datePart = datePart.toLowerCase();
    var diff = todate - fromDate;
    var divideBy = { w: 604800000, d: 86400000, h: 3600000, m: 60000, s: 1000 };

    return Math.floor(diff / divideBy[datePart]);
  };

  hasToRenewMembership = () => {
    var daysToExpiry = this.DateDifference(
      "d",
      new Date(Date.now()),
      new Date(this.props.memberDetails.expiryDate)
    );

    return daysToExpiry >= 0 && daysToExpiry > 60 ? false : true;
  };

  descending = (a, b) => {
    return b - a;
  };

  render() {
    return (
      <div className="container">
        <GenericHeader title="My Account" />
        {this.hasToRenewMembership() && (
          <div className="container">
            <div className="row d-none d-md-block" align="left">
              <div className="row">
                <div className="col-6  ">
                  <button
                    type="submit"
                    className="btn btn-outline-warning form-control text-dark border border-dark"
                    onMouseDown={() => {
                      this.props.history.push("/Checkout");
                    }}
                  >
                    Pay Membership - Payfast
                  </button>
                </div>
                <div className="col-6 ">
                  <button
                    type="submit"
                    className="btn btn-outline-warning form-control text-dark border border-dark "
                    onMouseDown={() => {
                      this.props.history.push("/Manual");
                    }}
                  >
                    Pay Membership - Bank Deposit
                  </button>
                </div>
              </div>
            </div>
            <div className="d-md-none pl-4 m-0" align="center">
              <div className="row" align="center">
                <div className="col-12  ">
                  <button
                    type="submit"
                    className="btn btn-warning form-control text-dark border border-dark"
                    onMouseDown={() => {
                      this.props.history.push("/Checkout");
                    }}
                  >
                    Pay Membership - Payfast
                  </button>
                </div>
                <div className="col-12 ">
                  <button
                    type="submit"
                    className="btn btn-warning form-control text-dark border border-dark "
                    onMouseDown={() => {
                      this.props.history.push("/Manual");
                    }}
                  >
                    Pay Membership - Bank Deposit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row pl-3 pr-3">
            You can keep track of your payments here. Click on any transaction to see more details and also print your receipt.
          </div>
          <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
            <h6>Payment History</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {this.props.accountList.map((account, index) => (
              <Account key={index} accountArray={account} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    accountDetails: state.accountReducer.account,
    accountList: state.accountReducer.accountList,
    memberDetails: state.loginReducer.userProfileDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onUserAccountChange: newUserAccountArray =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT_LIST,
        payload: newUserAccountArray
      }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({ 
          type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
          currentLoyaltyDaysTab: pageNum 
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainAccount));
