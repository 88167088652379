import {
  SET_ACTIVITIES,
  SET_ACTIVITY_MATRIX,
  SET_ACTIVITY_RANKING
} from './types';

const INITIAL_STATE = {
  activities: [],
  activityMatrix: [],
  activityRanking: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {

  case SET_ACTIVITIES:
    return { ...state, activities: payload };
  
  case SET_ACTIVITY_MATRIX:
    return {...state, activityMatrix: payload };
  
  case SET_ACTIVITY_RANKING:
    return {...state, activityRanking: payload };

  default:
    return state;
  }
};
