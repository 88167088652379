import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import UserSection from "./PendingActivitySubComponents/UserSection";
import { bindActionCreators } from "redux";
import * as ApproveNewDSActions from "../Approvals/ApproveNewDS/ApproveNewDSActions";
import { withStyles } from "@material-ui/core/styles";
import { activityDetailsStyles as styles } from "../../adminUser/activitiesTab/styles/styles";
import axios from "axios";
import { BASE,FRONT  } from "../../../API/APIendpoint";
import { MEMBER_ASSESSMENT } from "../../learningManagementSystem/LMSAPIEndPoints";
import { getCurrentDate } from "../Approvals/ApproveDashboardEndors";
import MainAcivityDetailsApproval from "./MainActivityDetailsApproval";
import ActivityDetailsApproval from "./ActivityDetailsApproval";
import { isMobile } from "react-device-detect";
import { DASHBOARD_ACTIONS } from "../../../store/actions/actionTypes";

class ApproveDedicatedStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Title: "Dedicated Status",
      showTextArea: false,
      reason: "",
      error: "",
      submitNum: "",
      linkSportShooter: "",
      linkDedicatedHunter: "",
      linkProfessionalHunter: "",
      linkActivityPractical: "",
      docName: "",
      type: "",
      memCourseObj: {},
      assesmentlist: {},
      practicalActivities: [],
      emailParam: {
        toEmail: this.props.user.email,
        Subject: "Status Updated",
        Body: "",
        Firstname: this.props.user.firstname,
        Surname: this.props.user.surname
      },
      currentDate: getCurrentDate()
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {

    var lessonList = [];
    axios.get(BASE + "api/Activities/activityPracticals/" + this.props.application.memNo, {
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(resp => {
         this.setState({
          practicalActivities: resp.data.activityPracticals
         })
    })
    axios
      .get(
        BASE +
          "api/ProffessionalHunters/getPHunterByDId/" +
          this.props.application.dedicatedId, {
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.setState({
          linkProfessionalHunter: res.data.link

        })
      })
    axios
    .get(
      BASE +
        "api/OtherDedicatedStatus/getLinkByID" +
        this.props.application.dedicatedId, {
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
    .then(res => {
      this.setState({
        documentLink: res.data.link
      })
    })
    axios
      .get(
        BASE +
          "api/Activities/getActivityDocument/" +
          this.props.application.memNo, {
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res =>
        this.setState({
          linkActivityPractical: res.data.link
        })
      )
      .catch(function(error) {
        console.log(error);
      });

    //TODO: To be removed 
    //   axios.get(BASE + 'api/CourseModule/withChildren', {
    //     headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
		// 	// console.log('response', res.data)
    //     const modules = res.data;
    //     const assessments = modules.map(mod => {
    //       const material = mod.courseMaterial;
    //       return {
    //         assesmentId: mod.courseModuleId,
    //         title: mod.name,
    //         grade: "O",
    //         type: mod.type,
    //         hasChildren: material ? material.length > 0 : false
    //       };
    //     });
		// // console.log(assessments)
    //     this.setState({
    //       assesmentlist: assessments
    //     })
    //     const material = modules.map(mod => [...mod.courseMaterial]);
    //     let lessons = [];
    //   material.forEach(mat => {
    //     mat.forEach(_lesson => {
    //       lessons.push({
    //         pkCourseId: _lesson.courseMaterialId,
    //         title: _lesson.name,
    //         content: _lesson.content,
    //         assesmnetId: _lesson.courseModuleId,
    //         type: _lesson.type
    //       });
    //     });
    //   });
    //   lessonList = lessons;

    // //Get member assessments 
    // fetch(MEMBER_ASSESSMENT + "/" + this.props.application.memNo, {
    //   headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
    //   .then(res => res.json())
    //   .then(res => {
    //       let certificates = {};
    //       this.state.assesmentlist?.length && this.state.assesmentlist.map(ass => {
    //       if (ass.hasChildren && ass.type === 1) {
    //           return certificates[`${ass.assesmentId}`] = [ass.title];
    //       }
    //         return null;
    //       });
    //       lessonList.map(lesson => {
    //         let arr = certificates[`${lesson.assesmnetId}`];
    //         if (arr) {
    //           const score = res.data.find(
    //             d => d.courseMaterialId === lesson.pkCourseId
    //           );
    //           arr.push(parseInt(score ? score.completion : 0));
    //         return certificates[`${lesson.assesmnetId}`] = arr;
    //         }
    //         return null;
    //       });
    //     })
    //   });

    axios.get(BASE + `api/DedicatedStatus/GetMembersAssessmentProgresss?memNo=${this.props.application.memNo}`, {
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res =>{
      let original = [[res.data.dh.name, ...res.data.dh.progress],[res.data.dss.name,...res.data.dss.progress]]

      this.setState({
            memCourseObj: original
          });
    })
    .catch(function(error) {
      console.log(error);
    });
  }

  updatePending = (dedicatedStatus,status) => {
    let _pendingDS = [...this.props.pendingDS];
    
    _pendingDS.forEach(element => {
      if(element.dedicatedId === dedicatedStatus.dedicatedId) {
        element.status = status
      }      
    })
    return _pendingDS;
  }

  Bar = (percent, score, title) => {
    return (
      <div className="row">
        <div className="col-3">
          <div
            className="row pb-4 pl-2 text-light"
            style={{
              background: `#79c44a`,
              borderRadius: `25px 0 0 25px`,
              height: "20px"
            }}
          >
            {title}
          </div>
        </div>
        <div className="col-9 pl-1">
          <div
            className="progress"
            style={{ borderRadius: `0 25px 25px 0`, height: "23px" }}
          >
            <div
              className="progress-bar text-right text-light pr-2"
              style={Object.assign(
                { width: `${percent}%` },
                { background: `#79c44a` },
                { fontSize: "15px" }
              )}
            >
              {score}
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayCourseProgress = () => {
    let divs = [];
    let assComp = this.state.memCourseObj;

    Object.keys(assComp).forEach((_key, index) => {
      const assesment = Array.from(assComp[_key]);

      let total = assesment.length - 1;
      total = total ? total : 1;
      const progress = assesment.reduce((a, b) => {
        if (typeof b !== "string") {
          return a + b;
        }
        return a;
      }, 0);
      let assesmentName;
      if ( isMobile ) {
        if (assesment[0] === "Dedicated Hunter Assessment Modules")
          assesmentName = "DHA"
        else
          assesmentName = "DSSA"
      } else {
        assesmentName = assesment[0]
      }
      divs.push(
        <div key={index} className="col-12 pb-2">
          {this.Bar(progress / total, `${progress / total} %`, assesmentName)}
        </div>
      );
    });
    return divs;
  };
  async handleSubmit(status) {
    const res = await this.props.ApproveNewDSActions.approvePendingDS(
      this.props.application.dedicatedId,
      status,
      this.state.reason
    );
    if (res) {
      if (res.status === 200) {
        const dsApps = this.updatePending(this.props.application,"Approved");
        this.props.onStatusDSChange(dsApps);
        this.props.history.push("/admin/PendingDedicatedHunter");
        if (this.props.application.dstype === "Dedicated Sports Person") {
          await axios({
            url: BASE + "api/Member/extendMembershipDays/" + this.props.user.email + "/" + this.props.loyaltyDaysObject.dedicateStatusDays + "/dedicatedStatus",
            method: "post",
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          })
          .then(
            await axios({
              url: BASE + "api/AutomatedEmail/post/SendLoyaltyEmail/" + this.props.application.dstype + "/" + this.props.loyaltyDaysObject.dedicateStatusDays,
              data: this.state.emailParam,
              method: "post",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            })
          )
        } else {
          await axios({
            url: BASE + "api/Member/extendMembershipDays/" + this.props.user.email + "/" + this.props.loyaltyDaysObject.dedicateStatusDays + "/dedicatedStatus",
            method: "post",
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          })
          .then(
            await axios({
              url: BASE + "api/AutomatedEmail/post/SendLoyaltyEmail/" + this.props.application.dstype + "/" + this.props.loyaltyDaysObject.dedicateStatusDays,
              data: this.state.emailParam,
              method: "post",
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
              }
            })
          )
        }
      }
    }
  }
  async handleSubmitRej(status) {
    const res = await this.props.ApproveNewDSActions.approvePendingDS(
      this.props.application.dedicatedId,
      status,
      this.state.reason
    );
    const dsApps = this.updatePending(this.props.application,"Rejected");
    
    if (res) {
      if (res.status === 200) {
        this.props.onStatusDSChange(dsApps);
        this.props.history.push("/admin/PendingDedicatedHunter");
      }
    }
  }
  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    const statusTypeQ =
      this.props.application.dstype === "Dedicated Sports Person"
        ? "Dedicated Sport Shooter Status Certificate From Other Association:"
        : "Dedicated Hunter Status Certificate From Other Association:";
    const documentLink =
      this.props.application.dstype === "Dedicated Sports Person"
        ? this.state.linkSportShooter
        : this.state.linkDedicatedHunter;
    const _practicalActivities = this.state.practicalActivities;
    const _renderPracticalActivityDetails = this.props.renderPracticalActivityDetails;
    const _activityID = this.props.activityID;
    if (_renderPracticalActivityDetails) {
      return (
        <div
          className="container"
          style={{ marginTop: "60px", marginBottom: "60px" }}
        >
          <div>
            <GenericHeader
              title="Activity Details"
              showBackButton
              backFunction={() => {
                this.props.onActivityParametersChange(false);
              }}
            />
          </div>
          {<ActivityDetailsApproval activityID={_activityID} />}
        </div>
      )
    } else {
      return (
        <div
          className="container"
          style={{ marginTop: "60px", marginBottom: "85px" }}
        >
          <div>
            <GenericHeader
              title="Dedicated Status Applications"
              showBackButton
              backFunction={() => {
                this.props.history.push("/admin/PendingDedicatedHunter");
              }}
            />
          </div>
          <UserSection user={this.props.user} />
          <div className="col-12 border-bottom bg-light">
            <div>Dedicated Type: {this.props.application.dstype}</div>
  
            <div>Status: {this.props.application.status}</div>
            <div>
              Application Date:{" "}
              {this.props.application.applicationDate.substring(0, 10)}
            </div>
          </div>
          <div className="container-fluid">
            {info("ID Number:", this.props.user.idno)}
            {info("Member Number:", this.props.application.memNo)}
            {info("DS Number:", this.props.application.dsno)}
            {info(
              "Date:",
              this.props.application.applicationDate.substring(0, 10)
            )}
            {info("Theoretical Assessments:")}
            <div className="row m-2">{this.displayCourseProgress()}</div>
            {info("Practical Assessments:")}
            {_practicalActivities.length > 0 ? (
              _practicalActivities.map((activity, index) => (
                <MainAcivityDetailsApproval 
                  key={index}
                  activity={activity} 
                />
              ))
            ) : (
              <p className="ml-2 font-weight-bold text-danger">Couldn't find any practical activities</p>
            )}
            {!this.state.linkActivityPractical
              ? null
              : info(
                  "Practical Assessment:",
                  <a
                    className="text-primary mb-2 mt-2"
                    href={this.state.linkActivityPractical}
                    target="_"
                  >
                    Document
                  </a>
                )}
            {info("Motivation:", this.props.application.motivation)}
            {info(
              statusTypeQ,
              !this.state.documentLink ? (
                <div className="text-primary mb-2 mt-2">null</div>
              ) : (
                <a
                  className="text-primary mb-2 mt-2"
                  href={FRONT + this.state.documentLink}
                  target="_"
                >
                  Document
                </a>
              )
            )}
            {!this.state.linkProfessionalHunter
              ? null
              : info(
                  "Proffesional Hunter Certificate From Other Association:",
                  <a
                    className="text-primary mb-2 mt-2"
                    href={this.state.linkProfessionalHunter}
                    target="_"
                  >
                    Document
                  </a>
                )}
          </div>
          <div className="row p-3 border mt-2 rounded-top fixed-bottom bg-light">
            <div className="d-flex flex-column flex-wrap w-100">
              {!this.state.showTextArea ?
                <div className="d-flex w-100 justify-content-center align-items-center p-1">
                  <div className="w-50 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-success border-dark text-dark w-40"
                        onClick={async () => {
                          await this.handleSubmit(1);
                        }}
                      >
                        Approve
                      </button>
                  </div>
                  <div className="w-50 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-danger border-dark text-dark w-40"
                        onClick={() => {
                          this.setState({
                            showTextArea: true,
                            submitNum: 2
                          });
                          this.props.onSetRejectType("Rejected")
                        }}
                      >
                        Reject
                      </button>
                  </div>
                </div>
              : <div className="d-flex justify-content-center w-100 p-3">
                  <textarea
                    style={{
                      width: "75%",
                      height: "100px"
                    }}
                    value={this.state.reason}
                    onChange={e => this.setState({ reason: e.target.value })}
                    placeholder="Provide a reason"
                  />
                </div>}
              {this.state.showTextArea === true ? (
                <div className="d-flex w-100 justify-content-center align-items-center p-1">
                  <div className="w-50 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-danger border-dark text-dark w-40"
                        onClick={async () => {
                          await this.handleSubmitRej(this.state.submitNum);
                          this.setState({
                            showReasonInputBox: false
                          });
                          this.props.ApproveNewDSActions.sendDedRej(
                            {
                              toEmail: this.props.user.email,
                              Subject: "Status Updated",
                              Reason: this.state.reason,
                              ApplicationStatus: this.props.rejectTypeFromStore,
                              Firstname: this.props.user.firstname,
                              Surname: this.props.user.surname
                            }
                          );
                        }}
                      >
                        Apply
                      </button>
                  </div>
                  <div className="w-50 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-success border-dark text-dark w-40"
                      onClick={() =>{
                        this.setState({
                          showTextArea: false
                        });
                        this.props.onSetRejectType("")
                      }
                      }
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
    }
    
}

const mapStateToProps = state => {
  return {
    renderPracticalActivityDetails: state.activitiesParametersReducer.renderPracticalActivityDetails,
    activityID: state.activitiesParametersReducer.activityID,
    memberDetails: state.dashBoardReducer.membersPending,
    user: state.approveNewDSState.user,
    lessonList: state.lmsReducer.lessons,
    application: state.approveNewDSState.application,
    assesmentCompletion: state.lmsReducer.assesmentCompletion,
    loyaltyDaysObject: state.LoyaltyDaysReducer.loyaltyDaysObject,
    rejectTypeFromStore: state.dedicatedStatusReducer.rejectType,
    pendingDS: state.dashBoardReducer.pendingDS,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ApproveNewDSActions: bindActionCreators(ApproveNewDSActions, dispatch),
    onDedicatedChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DEDICATED_STATUS,
        varValue: value,
        varName: vname
      }),
      onStatusDSChange: newPending =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_DS_PENDINGS,
        pendingDS: newPending
      }),
    onSetRejectType: rejectType =>
      dispatch({
        type: actionTypes.REJECT_DS_TYPE,
        rejectType: rejectType
      }),
    onActivityParametersChange: (renderPracticalActivityDetails) => {
      return dispatch({
        type: actionTypes.UPDATE_ACTIVITY_PARAMETERS,
        renderPracticalActivityDetails: renderPracticalActivityDetails
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ApproveDedicatedStatus));
