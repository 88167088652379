import * as actionTypes from "../actions/actionTypes";

const initialState = {
  notification: {
    currentNotificationTab: "",
    id: "",
    title: "",
    content: "",
    date: null
  },
  notifications: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NOTIFICATION:
      return {
        ...state,
        notification: action.notification
      };
    case actionTypes.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      };
    default:
      return state;
  }
};

export default reducer;
