import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, InputGroup } from "react-bootstrap";
import DateRange from "@material-ui/icons/DateRange";
toast.configure();
class ProcessMemberPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PaymentMethod: "",
      AmountPaid: "",
      CostCode: "",
      Email: "",
      MemNo: "",
      TransactionNumber: "",
      PaymentDate: ""
    };
    this._submitForm = React.createRef();
  }
  _clickSubmitButton = e => {
    e.preventDefault();
    this._submitForm.current.click();
  }
  handleSubmit = async e => {
    e.preventDefault();
    let yearInt = parseInt(new Date().getFullYear(), 10) + 1;
    const accountInfo = {
      PaymentType: "Membership Fees " + yearInt,
      PaymentMethod: this.state.PaymentMethod,
      AmountPaid: this.state.AmountPaid,
      Email: this.state.Email,
      CostCode: this.state.CostCode,
      Paid: true,
      MemNo: this.state.MemNo,
      TransactionNo: this.state.TransactionNumber,
      PaymentDate: this.state.PaymentDate
    };
    Axios({
      url: `${BASE}api/Member/ApproveNewMember/${this.state.MemNo}/1`,
      data: accountInfo,
      method: "post",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      toast.success(res.data.status)
    })
  };
  render() {
    const info = (row1, row2) => (
        <tbody>
          <tr >
            <td style={{ width: "29%" }}>{row1}</td>
            <td style={{ width: "48%" }} />
            <td className="font-weight-bold" style={{ width: "40%" }}>{row2}</td>
          </tr>
        </tbody>
      );
    return (
        <div style={{ marginTop: "60px" }}>
        <form className="container">
          <div className="row">
            <GenericHeader title="Process Member Payment" />
          </div>
          <table className="ml-4">
            {info("Member Number", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    MemNo: a.target.value
                  });
                }}
              />
            )}
            {info("Payment Method", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    PaymentMethod: a.target.value
                  });
                }}
              />
            )}
            {info("Amount Paid", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    AmountPaid: a.target.value
                  });
                }}
              />
            )}
            {info("Cost Code", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    CostCode: a.target.value
                  });
                }}
              />
            )}
            {info("Transaction Number", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    TransactionNumber: a.target.value
                  });
                }}
              />
            )}
            {info("PaymentDate", 
                <InputGroup>
                    <InputGroup.Prepend>
                    <InputGroup.Text>
                        <DateRange />
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        as="input"
                        type="date"
                        required
                        onInput={a => {
                            this.setState({
                              PaymentDate: a.target.value
                            });
                          }}
                    />
                </InputGroup>
            )}
            <button
              style={{ display: "none" }}
              onClick={e => this.handleSubmit(e)}
              ref={this._submitForm}
            />
          </table>
        </form>
        <div className="container">
          <div className="container placeAtEnd mr-4">
            <button className="btn btn-warning mt-3" onClick={e => this._clickSubmitButton(e)}>
              SAVE
            </button>
          </div>
        </div>
      </div>
    );
  }
}
export default ProcessMemberPayment;
