import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    border: '5px solid #4F36A6',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  dNone: {
    display: 'none',
  },
  small: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  large: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  icon: {
    position: 'absolute',
    zIndex: 2,
  }
}));

const ProfilePicture = ({large, src, callBack}) => {
  const classes = useStyles();
  const fileRef = React.createRef(); 

  const _handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = (event) => {
      callBack(event.target.result, file);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className={classes.root}>
      <input type="file"
        className={classes.dNone}
        onChange={e => _handleImageChange(e)}
        ref={fileRef}
      />
      <InsertPhotoIcon color="action" className={classes.icon}/>
      <Avatar onClick={() => fileRef.current.click()} alt="profile picture" src={src} className={large?classes.large:classes.small} />
    </div>
  );
};

ProfilePicture.defaultProps = {
  callBack: (result, file) => ({result,file}),
};

ProfilePicture.propTypes = {
  large: PropTypes.bool,
  src: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default ProfilePicture;