export const emailUsernameConfig = {
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      placeholder: 'Email Address',
    },
    validate: {
      required: true,
      minLength: 2
    },
    valid: true,
    errors: [],
    touched: false,
  },
  value: ''
};

export const passwordConfig = {
  config: {
    elementType: 'password',
    elementConfig: {
      type: 'password',
      placeholder: 'Password',
    },
    validate: {
      required: true,
      minLength: 8
    },
    valid: true,
    errors: [],
    touched: false,
  },
  value: ''
};