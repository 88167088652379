import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Assessments, LearningMaterial, TabGroup } from '../../components';
import Container from '../../hoc/Container';
import LMSService from '../../services/learningManagement.service';
import {
  UPDATE_LESSONS,
  UPDATE_ASSESMENTS,
  UPDATE_QUIZ_COMPLETION,
  UPDATE_ASSESMENT_COMPLETION,
  UPDATE_PROGRESS_ARRAY
} from '../../store/LearningManagement/actionTypes';

class Courses extends Component {
  constructor(props) {
    super(props);
    this.lmsService = LMSService(this.props.token);

    this.state = {
      loading: false,
      cachedCourses: []
    };
  }

  componentDidMount() {
    this.setState({loading: true});
    this.loadCourseProgressContent();
    // this.writeCourseMaterialToIndexDB();
    

    this.setState({loading: false});
  }

  writeCourseMaterialToIndexDB = async (data) =>{

    console.log("##Writing")
    const dbName = 'courseDatabase';
    const dbVersion = 1;
    const request = indexedDB.open(dbName, dbVersion);


    // Handle database creation or version change
    request.onupgradeneeded = function (event) {
      const db = event.target.result;

      // Create an object store
      const objectStore = db.createObjectStore('courseModules', { keyPath: 'courseModuleId' });

      // Define the structure of the stored data
      objectStore.createIndex('index', 'index', { unique: false });
      objectStore.createIndex('name', 'name', { unique: false });
      objectStore.createIndex('type', 'type', { unique: false });
      // Add other fields as needed

      console.log('Database setup complete');
    };

    // Handle successful database opening
    request.onsuccess = function (event) {
      const db = event.target.result;

      try {
            // Start a transaction and get the object store
            const transaction = db.transaction(['courseModules'], 'readwrite');
            const objectStore = transaction.objectStore('courseModules');
      
            // Add each course module to the object store
            data.forEach((courseModule) => {
              const addRequest = objectStore.add(courseModule);
      
              addRequest.onsuccess = function () {
                console.log('Course module added successfully');
              };
      
              addRequest.onerror = function (error) {
                console.error('Error adding course module:', error);
              };
            });
      
            // Close the transaction when done
            transaction.oncomplete = function () {
              console.log('Transaction completed');
            };
      
            transaction.onerror = function (error) {
              console.error('Transaction error:', error);
            };
      } catch (error) {
        
        console.log("[Error writing to DB]", error)
      }
    };

    // Handle errors during database opening
    request.onerror = function (event) {
      console.error('Error opening database:', event.target.error);
    };


  }

  readCoursesFromIndexDB = async () => {
    
    return new Promise((resolve, reject) => {
      // Open (or create) a database
      const dbName = 'courseDatabase';
      const dbVersion = 1;
      const request = indexedDB.open(dbName, dbVersion);
  
      // Handle successful database opening
      request.onsuccess = function (event) {

        try {
          console.log("##readCoursesFromIndexDB:114");
        const db = event.target.result;
  
        // Start a transaction and get the object store
        const transaction = db.transaction(['courseModules'], 'readonly');
        const objectStore = transaction.objectStore('courseModules');
  
        // Open a cursor to iterate through all records
        const cursorRequest = objectStore.openCursor();
        const _courseRecords = [];
        console.log("##readCoursesFromIndexDB:114");
  
        cursorRequest.onsuccess = function (event) {
          const cursor = event.target.result;
  
          if (cursor) {
            // Access the current record in the cursor
            _courseRecords.push(cursor.value);
            // Move to the next record in the cursor
            cursor.continue();
          } else {
            // No more records
            console.log('All records retrieved', _courseRecords);
            resolve(_courseRecords);
          }
        };
  
        cursorRequest.onerror = function (error) {
          console.error('Cursor request error:', error);
          reject(error);
        };
  
        // Close the transaction when done
        transaction.oncomplete = function () {
          console.log('Transaction completed');
        };
  
        transaction.onerror = function (error) {
          console.error('Transaction error:', error);
          reject(error);
        };
        } catch (error) {
          
          console.log("[Reading courses error]", error)

          indexedDB.deleteDatabase(dbName);
          resolve([]);
        } 

      };
  
      // Handle errors during database opening
      request.onerror = function (event) {
        console.error('Error opening database:', event.target.error);
        reject(event.target.error);
      };
    });
  };
  
  loadCourseProgressContent = async () => {
    
    var courseRecords = await this.readCoursesFromIndexDB();

    if(courseRecords.length > 0)
    {
      const modules = courseRecords;
      console.log("courseRecords DB",courseRecords)

        const assessments = modules.map(mod => {
          const material = mod.courseMaterial;
          return {
            assesmentId: mod.courseModuleId,
            title: mod.name,
            grade: 'O',
            type: mod.type,
            hasChildren: material ? material.length > 0 : false
          };
        });
        this.props.onAssesmentsChange(assessments);
        const material = modules.map(mod => [...mod.courseMaterial]);
        let lessons = [];
        material.forEach(mat => {
          mat.forEach(_lesson => {
            lessons.push({
              pkCourseId: _lesson.courseMaterialId,
              title: _lesson.name,
              content: _lesson.content,
              assesmnetId: _lesson.courseModuleId,
              type: _lesson.type
            });
          });
        });
        this.props.onLessonsChange(lessons);
      
        let _progresArr = [];
        await this.lmsService.getMemberAssessments(this.props.memNo)
          .then(res => {
            this.props.onQuizCompletionChange(res.data.data);
            let certificates = {};
            this.props.assesmentList.map(ass => {
              if (ass.hasChildren && ass.type === 1) {
                return certificates[`${ass.assesmentId}`] = [ass.title];
              }
              return null;
            });
            this.props.lessonList.map(lesson => {
              let arr = certificates[`${lesson.assesmnetId}`];
              if (arr) {
                const score = res.data.data.find(
                  d => d.courseMaterialId === lesson.pkCourseId
                );
                arr.push(parseInt(score ? score.completion : 0));
                return certificates[`${lesson.assesmnetId}`] = arr;
              }
              return null;
            });
            this.props.onAssCompletionChange(certificates);
            Object.keys(certificates).forEach((_key) => {
              const assesment = Array.from(certificates[_key]);
              let total = assesment.length - 1;
              total = total ? total : 1;
              const progress = assesment.reduce((a, b) => {
                if (typeof b !== 'string') {
                  return a + b;
                }
                return a;
              }, 0);
              _progresArr.push((progress / total));
            });
            this.props.onProgressArrayUpdate(_progresArr);
          });
    }
    else{
      //Make a call to the database and cache the data 
      await this.lmsService.getCourseModules()
      .then(res => {
      console.log("No DB")

        const modules = res.data;
        this.writeCourseMaterialToIndexDB(modules);
        const assessments = modules.map(mod => {
          const material = mod.courseMaterial;
          return {
            assesmentId: mod.courseModuleId,
            title: mod.name,
            grade: 'O',
            type: mod.type,
            hasChildren: material ? material.length > 0 : false
          };
        });
        this.props.onAssesmentsChange(assessments);
        const material = modules.map(mod => [...mod.courseMaterial]);
        let lessons = [];
        material.forEach(mat => {
          mat.forEach(_lesson => {
            lessons.push({
              pkCourseId: _lesson.courseMaterialId,
              title: _lesson.name,
              content: _lesson.content,
              assesmnetId: _lesson.courseModuleId,
              type: _lesson.type
            });
          });
        });
        this.props.onLessonsChange(lessons);
      })
      .then(async () => {
        let _progresArr = [];
        await this.lmsService.getMemberAssessments(this.props.memNo)
          .then(res => {
            this.props.onQuizCompletionChange(res.data.data);
            let certificates = {};
            this.props.assesmentList.map(ass => {
              if (ass.hasChildren && ass.type === 1) {
                return certificates[`${ass.assesmentId}`] = [ass.title];
              }
              return null;
            });
            this.props.lessonList.map(lesson => {
              let arr = certificates[`${lesson.assesmnetId}`];
              if (arr) {
                const score = res.data.data.find(
                  d => d.courseMaterialId === lesson.pkCourseId
                );
                arr.push(parseInt(score ? score.completion : 0));
                return certificates[`${lesson.assesmnetId}`] = arr;
              }
              return null;
            });
            this.props.onAssCompletionChange(certificates);
            Object.keys(certificates).forEach((_key) => {
              const assesment = Array.from(certificates[_key]);
              let total = assesment.length - 1;
              total = total ? total : 1;
              const progress = assesment.reduce((a, b) => {
                if (typeof b !== 'string') {
                  return a + b;
                }
                return a;
              }, 0);
              _progresArr.push((progress / total));
            });
            this.props.onProgressArrayUpdate(_progresArr);
          });
      });
    }

    // OLD PROCESS 

    // await this.lmsService.getCourseModules()
    //   .then(res => {
    //     const modules = res.data;

    //     this.writeCourseMaterialToIndexDB(modules);

    //     console.log("TS COURSE",modules)

    //     const assessments = modules.map(mod => {
    //       const material = mod.courseMaterial;
    //       return {
    //         assesmentId: mod.courseModuleId,
    //         title: mod.name,
    //         grade: 'O',
    //         type: mod.type,
    //         hasChildren: material ? material.length > 0 : false
    //       };
    //     });
    //     this.props.onAssesmentsChange(assessments);
    //     const material = modules.map(mod => [...mod.courseMaterial]);
    //     let lessons = [];
    //     material.forEach(mat => {
    //       mat.forEach(_lesson => {
    //         lessons.push({
    //           pkCourseId: _lesson.courseMaterialId,
    //           title: _lesson.name,
    //           content: _lesson.content,
    //           assesmnetId: _lesson.courseModuleId,
    //           type: _lesson.type
    //         });
    //       });
    //     });
    //     this.props.onLessonsChange(lessons);
    //   })
    //   .then(async () => {
    //     let _progresArr = [];
    //     await this.lmsService.getMemberAssessments(this.props.memNo)
    //       .then(res => {
    //         this.props.onQuizCompletionChange(res.data.data);
    //         let certificates = {};
    //         this.props.assesmentList.map(ass => {
    //           if (ass.hasChildren && ass.type === 1) {
    //             return certificates[`${ass.assesmentId}`] = [ass.title];
    //           }
    //           return null;
    //         });
    //         this.props.lessonList.map(lesson => {
    //           let arr = certificates[`${lesson.assesmnetId}`];
    //           if (arr) {
    //             const score = res.data.data.find(
    //               d => d.courseMaterialId === lesson.pkCourseId
    //             );
    //             arr.push(parseInt(score ? score.completion : 0));
    //             return certificates[`${lesson.assesmnetId}`] = arr;
    //           }
    //           return null;
    //         });
    //         this.props.onAssCompletionChange(certificates);
    //         Object.keys(certificates).forEach((_key) => {
    //           const assesment = Array.from(certificates[_key]);
    //           let total = assesment.length - 1;
    //           total = total ? total : 1;
    //           const progress = assesment.reduce((a, b) => {
    //             if (typeof b !== 'string') {
    //               return a + b;
    //             }
    //             return a;
    //           }, 0);
    //           _progresArr.push((progress / total));
    //         });
    //         this.props.onProgressArrayUpdate(_progresArr);
    //       });
    //   });
  }

  tabViews = (isMobile) => [
    {
      label: isMobile ? 'Material' : 'Course learning material',
      view: <LearningMaterial/>
    },
    {
      label: isMobile ? 'Quizzes' : 'Course assessment quizzes',

      view: <Assessments
        getQuestionByIdCallBack={this.lmsService.getQuestionById}
        getCourseMaterialByIdCallBack={this.lmsService.getCourseMaterialById}
        getCourseModuleNameByIdCallBack={this.lmsService.getCourseModuleNameById}
        postMemberAssessmentCallBack={this.lmsService.postMemberAssessment}
        getMemberAssessmentsByMemberNo={this.lmsService.getMemberAssessmentsByMemberNo}
      />
    }
  ];

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Learning Portal">
        <TabGroup 
          loading={this.state.loading}
          items={this.tabViews(isMobile)}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  assesmentList: state.nLearningManagementReducer.assesments,
  lessonList: state.nLearningManagementReducer.lessons,
});

const mapDispatchToProps = dispatch => ({
  onQuizCompletionChange: newQuizCompletion =>
    dispatch({
      type: UPDATE_QUIZ_COMPLETION,
      quizCompletion: newQuizCompletion
    }),
  onAssCompletionChange: assesmentCompletion =>
    dispatch({
      type: UPDATE_ASSESMENT_COMPLETION,
      assesmentCompletion: assesmentCompletion
    }),
  onLessonsChange: newLessons => 
    dispatch({
      type: UPDATE_LESSONS,
      lessons: newLessons
    }),
  onAssesmentsChange: newAssesments =>
    dispatch({
      type: UPDATE_ASSESMENTS,
      assesments: newAssesments
    }),
  onProgressArrayUpdate: _progressArr =>
    dispatch({
      type: UPDATE_PROGRESS_ARRAY,
      progressArr: _progressArr
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);