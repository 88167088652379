import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CardCommission from '../../components/PartnerDashboard/CardCommission';
import Container from '../../hoc/Container';
import CommissionsService from '../../services/commissions.service';
import CardMembers from '../../components/PartnerDashboard/CardMembers';
import PartnerCard from '../PartnerDashboard/PartnerCard';
import * as actions from '../../store/Auth/Login/actions'

class SalesRepDashboard extends Component {
  constructor(props) {
    super(props);
    this.CommissionsService = CommissionsService(this.props.token)
  	this.state = {
        commissionList: [],
        salesRepList: [],
        partnerMembers: [],
        amountDue:0,
        showCommissions:false,
        showSalesReps:false,
        showMembers:false,
        commissonIsloading: true,
        repsIsloading: true,
        memIsloading: true,
    }
  }

  componentDidMount() {

    this.CommissionsService.getMemberRoles(this.props.memNo).then(resp =>{
      this.props.onUpdate(resp.data.roles.isSalesRep,resp.data.roles.isPartner,resp.data.roles.partnerId);

      if(resp.data.roles.isPartner)
        this.props.history.push("/my-profile");

      this.CommissionsService.getMembersBySalesRepMemNo(this.props.memNo).then(resp =>{
        this.setState({partnerMembers: resp.data})
        this.setState({memIsloading: false})
      })
      .catch(ex =>{
        console.log(ex);
        this.setState({memIsloading: false})
      })
    })
    .catch(err =>{
      console.error("Error retriving Roles", err);
    })

    this.CommissionsService.getAllSalesRepCommission(this.props.memNo).then(resp =>{
          this.setState({commissionList: resp.data.repsMemComm, amountDue: resp.data.amountDue || "0.00"})
          this.setState({commissonIsloading: false})
        })
        .catch(ex =>{
          console.log(ex);
          this.setState({commissonIsloading: false})
        })
  }

  back = ()=>{
      this.setState({showCommissions:false,showMembers:false,showSalesReps:false});
  }

  render() {
    return(
      !this.props.partnerLevel && <Container title="Sales Rep Portal" center="center">
        <Grid container spacing={4}>
           <Grid item xs={12} md={4} style={this.state.showCommissions ? {cursor:'not-allowed'} : {}}>
              <PartnerCard title="Commission" isloading={this.state.commissonIsloading}  show={_=> this.setState({showCommissions:true,showSalesReps:false,showMembers:false})} subTitle="Amount due" subTitleValue={this.state.amountDue} color={this.state.showCommissions ? "secondary":"info"} icon="commission"/>
            </Grid>

            <Grid item xs={12} md={4} > 
              <PartnerCard title="Members" isloading={this.state.memIsloading} show={_=> this.setState({showMembers:true,showSalesReps:false,showCommissions:false,})} subTitle="Number of Members" subTitleValue={this.state.partnerMembers.length} black color={this.state.showMembers ? "secondary":"warning"} icon="members"/>
            </Grid>
        </Grid>

        {this.state.showCommissions && <CardCommission commission={this.state.commissionList} back={this.back}/>}

        {this.state.showMembers && <CardMembers partnerMembers={this.state.partnerMembers} back={this.back}/>}

      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdate: (isSalesRep,isPartner,partnerId)=> dispatch(actions.updateRole(isSalesRep, isPartner, partnerId))
});

const mapStateToProps = (state) => ({
	adminLevel: state.loginReducer.userProfileDetails.adminLevel,
	partnerLevel: state.loginReducer.userProfileDetails.partnerLevel,
	partnerId: state.loginReducer.userProfileDetails.partnerId,
	salesRepLevel: state.loginReducer.userProfileDetails.salesRepLevel,
	memNo: state.AuthReducer.memNo,
	token: state.AuthReducer.token,
});

export default connect(mapStateToProps,mapDispatchToProps)(SalesRepDashboard);