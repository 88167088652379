import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import MembershipCard from "./MembershipCard";
import { BASE, GET_MEMBER, FRONT } from "../../../API/APIendpoint";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import jsPDF from "jspdf";
import axios from "axios";
import * as actionTypes from "../../../store/actions/actionTypes";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
const Cryptr = require("cryptr");
export const DateFind = dated => {
  if (!dated) {
    return dated;
  }
  var date = new Date(Date.parse(dated));
  var currentDate = date;
  var dater = currentDate.getDate();
  var months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];
  var month = currentDate.getMonth();
  var year = currentDate.getFullYear();
  var monthDateYear = dater + " " + months[month] + " " + year;
  return monthDateYear;
};
class MainMembershipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      member: [],
      PDFSign: "",
      pdflogo: "",
      nnfo: "",
      date: "",
      wildman: "",
      encryptedMemString: "",
      qrCodeWithMemDet: "",
      _dss: "",
      _dhs: ""
    };
    this.memCertDownload = React.createRef();
    this.memCardPrint = React.createRef();
  }
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
    axios
      .get(BASE + "api/Member/getMemQRCode/" + cryptr.encrypt(this.props.userDetails.memberNumber),{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(resp => {
        const qrFinal = "data:image/jpeg;base64," + resp.data.base64QRString
        this.setState({
          qrCodeWithMemDet: qrFinal
        })
      });
    this.getDate();
    axios({
      url: BASE +
      GET_MEMBER +
      (this.props.memberState.isAdmin
        ? this.props.MemberV.memNo
        : this.props.userDetails.memberNumber),
      method: "get",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(json => {
      this.setState({
        member: json.data.members,
        encryptedMemString:
          FRONT + "?" + cryptr.encrypt(json.data.members.memNo)
      });
    });
    axios({
      url: `${BASE}api/DedicatedStatus/approvedDS/${this.props.userDetails.memberNumber}`,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      res.data.forEach(element =>{
        if(element.dstype === "Dedicated Sports Person"){
          this.setState({
            _dss: element.dsno
          })
        }
        if(element.dstype === "Dedicated Hunter"){
          this.setState({
            _dhs: element.dsno
          })
        }
      })
    })
    .catch(function (error) { });
  }
  getDate = () => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let today = new Date();
    let date =
      today.getDate() +
      " " +
      months[parseInt(today.getMonth())] +
      " " +
      today.getFullYear();
    this.setState({ date });
  };
  FindAddress(member) {
    let Address = "";
    if (member.physicalAddress) {
      Address = member.physicalAddress;
      if (member.suburb) {
        Address += ", " + member.suburb;

        if (member.city) {
          Address += ", " + member.city;
        }

        if (member.postalCode) {
          Address += ", " + member.postalCode;
        }
      }
    }
    return Address;
  }
  memberCertificateDownloader = () => {
    this.memCertDownload.current.click();
  };
  memberCardDownloader = () => {
    this.memCardPrint.current.click();
  }
  render() {
    const print = async () => {
        let member = this.state.member;
        let pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(this.state.qrCodeWithMemDet , "JPEG", 15, 255, 40, 35);
        pdf.addImage(this.props.base64Images.nnfo, "JPEG", 10, 12);
        pdf.setTextColor(126, 126, 126);
        pdf.setFont("times");
        pdf.setFontSize(10);
        pdf.text(10, 60, "NARFO Pty (Ltd) 2012/086864/07");
        pdf.setTextColor(0, 0, 0);
        pdf.setFont("times");
        pdf.setFontSize(14);
        pdf.text(80, 18, "National Association of Responsible Firearm Owners");
        pdf.setFontSize(10);
        pdf.text(120, 25, "of South Africa");
        pdf.line(70, 30, 150, 30);
        pdf.setTextColor(126, 126, 126);
        pdf.setFontSize(10);
        pdf.setFontType("italic");
        pdf.text(85, 37, "SAPS Accredited Association");
        pdf.setFontType("normal");
        pdf.text(70, 45, "Hunting Association");
        pdf.setTextColor(0, 0, 0);
        pdf.text(110, 45, "FAR-1300135");
        pdf.setTextColor(126, 126, 126);
        pdf.text(70, 50, "Sport Shooting");
        pdf.setTextColor(0, 0, 0);
        pdf.text(110, 50, "FAR-1300134");
        pdf.text(70, 55, "http://www.narfo.co.za");
        pdf.setTextColor(126, 126, 126);
        pdf.text(110, 55, "Email:");
        pdf.setTextColor(0, 0, 0);
        pdf.text(120, 55, "info@narfo.co.za");
        pdf.line(70, 60, 150, 60);
        pdf.setFontSize(8);
        pdf.setTextColor(126, 126, 126);
        pdf.text(156, 32, "14 Phesante Close, Graanendal");
        pdf.text(156, 38, "Durbanville");
        pdf.text(156, 44, "7551");
        pdf.setTextColor(0, 0, 0);
        pdf.setFontStyle("bold");
        pdf.setFontSize(20);
        pdf.text(66, 70, "Certificate of Membership");
        pdf.line(10, 75, 200, 75);
        pdf.setFontStyle("normal");
        pdf.setFontSize(14);
        pdf.text(10, 85, "To whom it may concern");
        pdf.text(10, 95, "This is to certify that");
        pdf.text(10, 105, "Name:");
        pdf.text(10, 115, "Address:");
        pdf.text(10, 125, "ID No:");
        pdf.text(10, 135, "Membership Number:");
        pdf.text(10, 145, "Membership Type:");
        pdf.text(
          10,
          155,
          "has been member in good standing of the association since"
        );
        pdf.text(10, 165, "Current membership is valid until");
        pdf.setFontStyle("bold");
        pdf.text(40, 105, member.firstname + " " + member.surname);
        pdf.text(40, 115, this.FindAddress(member));
        pdf.text(40, 125, member.idno);
        pdf.text(60, 135, member.memNo);
        pdf.text(60, 145, member.memType);
        pdf.text(130, 155, DateFind(member.inceptionDate));
        pdf.text(80, 165, DateFind(member.expiryDate));
        pdf.addImage(this.props.base64Images.pdfSign, "JPEG", 17, 190, 60, 45);
        pdf.setTextColor(0, 0, 0);
        pdf.setFontStyle("normal");
        pdf.text(28, 240, "Executive Member");
        pdf.addImage(this.props.base64Images.pdflogo, "JPEG", 115, 165);
        pdf.text(136, 240, DateFind(this.state.date));
        pdf.setTextColor(126, 126, 126);
        pdf.setFontSize(10);
        pdf.text(
          55,
          265,
          "This document only provides proof of membership and cannot be used to provide proof of"
        );
        pdf.text(
          55,
          270,
          "dedicated hunter or sports person status. These can only be verified by the approved dedicated"
        );
        pdf.text(
          55,
          275,
          "certificates which complies stipulations of Section 16(2) of the Firearms Control Act 60 of 2000"
        );
        pdf.setFontSize(18);
        pdf.setFontStyle("bold");
        pdf.setTextColor(31, 78, 121);
        pdf.text(55, 285, "Firearm Ownership is a Fulltime Responsibility");
        pdf.save("NARFO Proof Of Membership.pdf");
    };
    const printCard = () => {
      let member = this.state.member;
      let pdf = new jsPDF("p", "mm", "a4");
      pdf.line(55, 70, 165, 70);
      pdf.line(55, 125, 55, 70);
      pdf.line(165, 125, 165, 70);
      pdf.addImage(this.props.base64Images.nnfo , "JPEG", 67, 76, 20, 15);
      pdf.setTextColor(126, 126, 126);
      pdf.setFontSize(5);
      pdf.text(64, 95, `Responsible Firearm Ownership`);
      pdf.text(62.5, 97, `info@narfo.co.za | www.narfo.co.za`);
      pdf.setTextColor(0, 0, 0);
      pdf.setFontStyle("bold");
      pdf.text(69, 99, `Date: ${DateFind(this.state.date)}`);
      pdf.setFontSize(11);
      pdf.text(94, 80, "Membership Card");
      pdf.setFontStyle("normal");
      pdf.setTextColor(126, 126, 126);
      pdf.setFontSize(8);
      pdf.text(94, 85, member.firstname + " " + member.surname);
      pdf.setFontSize(5);
      pdf.text(96, 90, `Identity Number:`);
      pdf.setTextColor(50,205,50);
      pdf.text(109, 90, `${member.idno}`);
      pdf.setTextColor(126, 126, 126);
      pdf.text(96, 93, `Member Number:`);
      pdf.setTextColor(50,205,50);
      pdf.text(110, 93, `${member.memNo}`);
      pdf.setTextColor(126, 126, 126);
      pdf.text(96, 96, `Member Inception Date:`);
      pdf.setTextColor(50,205,50);
      pdf.text(115, 96, `${member.inceptionDate.split("T")[0]}`);
      pdf.setTextColor(126, 126, 126);
      pdf.text(96, 99, `Member Expiry Date:`);
      pdf.setTextColor(50,205,50);
      pdf.text(113, 99, `${member.expiryDate.split("T")[0]}`);
      pdf.setTextColor(126, 126, 126);
      pdf.text(96, 102, `Membership Type:`);
      pdf.setTextColor(50,205,50);
      pdf.text(111, 102, `${member.memType}`);
      if(this.state._dhs !== "" && this.state._dss !== "") {
        pdf.setTextColor(126, 126, 126);
        pdf.text(96, 105, `Dedicated Hunter Status Number:`);
        pdf.setTextColor(50,205,50);
        pdf.text(123, 105, `${this.state._dhs}`);

        pdf.setTextColor(126, 126, 126);
        pdf.text(96, 108, `Dedicated Sports Shooter Status Number:`);
        pdf.setTextColor(50,205,50);
        pdf.text(129.5, 108, `${this.state._dss}`);
      }
      if(this.state._dhs !== "" && this.state._dss === "") {
        pdf.setTextColor(126, 126, 126);
        pdf.text(96, 105, `Dedicated Hunter Status Number:`);
        pdf.setTextColor(50,205,50);
        pdf.text(123, 105, `${this.state._dhs}`);
      }
      if(this.state._dhs === "" && this.state._dss !== "") {
        pdf.setTextColor(126, 126, 126);
        pdf.text(96, 105, `Dedicated Sports Shooter Status Number:`);
        pdf.setTextColor(50,205,50);
        pdf.text(129.5, 105, `${this.state._dss}`);
      }
      pdf.setTextColor(126, 126, 126);
      pdf.text(84, 120, `Accreditation:Hunting - FAR: 1300135  |Sports Shooting:-FAR: 1300134`);
      pdf.line(55, 125, 165, 125);
      pdf.addImage(this.state.qrCodeWithMemDet , "JPEG", 139, 78, 23, 18);
      pdf.line(165, 183, 165, 128);
      pdf.line(55, 128, 165, 128);
      pdf.line(55, 183, 55, 128);
      pdf.addImage(this.props.base64Images.wildman, "JPEG", 55.4, 128.5, 109.1, 54);
      pdf.line(55, 183, 165, 183);
      pdf.save("NARFO Membership Card.pdf");
    };
    return (
      <div className="container">
        <GenericHeader
          title="Membership"
          showDownloadButton2
          text2="Card"
          downloadFunction2={this.memberCardDownloader}
          showDownloadButton
          text="Certificate"
          downloadFunction={this.memberCertificateDownloader}
        />
        <button
          onClick={print}
          ref={this.memCertDownload}
          style={{ display: "none" }}
        >
          down
        </button>
        <button
          onClick={printCard}
          ref={this.memCardPrint}
          style={{ display: "none" }}
        >
          down
        </button>
        <div className="container">
          <MembershipCard />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    memberState: state.loginReducer.memberState
  };
};
const mapDispatchToProps = dispatch => {
  return {
      onCommissionTabChange: newComTab =>
          dispatch({ 
              type: actionTypes.UPDATE_COMMISSION_TAB, 
              currentCommissionTab: newComTab 
          }),
      onAddAccountDetailsChange: (value, variableName) =>
          dispatch({
            type: actionTypes.UPDATE_ACCOUNT,
            varValue: value,
            varName: variableName
          }),
      onLoyaltyDaysTabChange: pageNum =>
          dispatch({ 
              type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
              currentLoyaltyDaysTab: pageNum 
          }),
      onCurrentLMSTabChange: newCurrentLmsTab =>
          dispatch({
            type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
            currLmsTab: newCurrentLmsTab
          }),
      onCurrentEndorsementTabChange: newTabName =>
          dispatch({
            type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
            currTab: newTabName
          }),
      onCurrActivityTabChange: (value, vname) =>
          dispatch({
            type: actionTypes.UPDATE_MEMBERS_AREA,
            varValue: value,
            varName: vname
          }),
      onCurrentLicenseTabChange: (value, variableName) =>
          dispatch({
            type: actionTypes.UPDATE_MEMBERS_AREA,
            varValue: value,
            varName: variableName
          }),
      };
  };
export default connect(
mapStateToProps,
mapDispatchToProps
)(MainMembershipCard);
