import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { BASE } from "../../API/APIendpoint";
import { STATUS } from "../../store/constantFile/ConstFile"
class StatusView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.match.params.key,
      DedicatedStatusApplication: {}
    };
  }

  componentDidMount() {
    let keyValue = { QR: this.state.key };
    axios
      .post(BASE + "api/QR/post/key",keyValue,
      {headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
      }})
      .then(res => {
        this.setState({ DedicatedStatusApplication: res.data.dedicated });
      })
      .catch(function(error) {});
  }
  render() {
    return (
      <div className="container">
        <div className="row">
          <img
            className="image container w-50 h-50"
            src="https://i.screenshot.net/llqlzsl"
            alt="logo"
          />
        </div>
        <div className="row ">
          <table className="table table-borderless font-weight-lighter mb-5">
            <tbody>
              <tr>
                <th>Member No: </th>
                <th className="text-right">
                  {this.state.DedicatedStatusApplication.memNo}
                </th>
              </tr>
              <tr>
                <th>DSS No: </th>
                <th className="text-right">
                  {this.state.DedicatedStatusApplication.dsno}
                </th>
              </tr>
              <tr>
                <th>Discipline:</th>
                <th className="text-right">
                  {this.state.DedicatedStatusApplication.dstype}
                </th>
              </tr>

              <tr>
                <th>Status: </th>
                {this.state.DedicatedStatusApplication.status ===
                  STATUS.Approved && (
                  <th className="text-success text-right">
                    {this.state.DedicatedStatusApplication.status}
                  </th>
                )}
                {this.state.DedicatedStatusApplication.status ===
                 STATUS.Pending && (
                  <th className="text-warning text-right">
                    {this.state.DedicatedStatusApplication.status}
                  </th>
                )}
                {this.state.DedicatedStatusApplication.status ===
                  STATUS.Declined && (
                  <th className="text-danger text-right">
                    {this.state.DedicatedStatusApplication.status}
                  </th>
                )}
                 {this.state.DedicatedStatusApplication.status ===
                  STATUS.Terminated && (
                  <th className="text-danger text-right">
                    {this.state.DedicatedStatusApplication.status}
                  </th>
                )}
              </tr>
              <tr>
                <th>Approval Date</th>
                {this.state.DedicatedStatusApplication.dateApproved ===
                  null && <th className="text-right">-</th>}
                {this.state.DedicatedStatusApplication.dateApproved !==
                  null && (
                  <th className="text-right">
                    {" "}
                    {this.state.DedicatedStatusApplication.dateApproved}
                  </th>
                )}
              </tr>

              {this.state.DedicatedStatusApplication.status ===
               STATUS.Declined && (
                <tr>
                  <th>Refusal Reason</th>

                  <th className="text-danger text-right">
                    {this.state.DedicatedStatusApplication.refusalReason}
                  </th>
                </tr>
              )}
              
              {this.state.DedicatedStatusApplication.status ===
               STATUS.Terminated && (
                <tr>
                  <th>Termination Reason</th>

                  <th className="text-danger text-right">
                    {this.state.DedicatedStatusApplication.terminationReason}
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
export default StatusView;
