import React, { Component } from "react";
import TabPanes from "../adminPortal/TabPane";
import MembersAreaHeader from "../membersAreaPage/MembersAreaHeader";
import { LMS_ACTIONS } from "../../store/actions/actionTypes";
import * as actionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";

class MembersArea extends Component {
  constructor(props)
  {
    super(props);
  }
  componentWillMount() {}
  render() {
    return (
      <div
        className="container"
        style={{
          maxWidth: "2180px",
          marginTop: "60px"
        }}
      >
        <MembersAreaHeader
          memberDetails={this.props.MemberV}
          adminView={true}
        />
        <TabPanes />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    MemberV: state.MemberViewReducer.MemberView,
    userProfileDetails: state.loginReducer.userProfileDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onMemberCoursesChange: newMemberCourses =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_MEMBER_COURSES,
        memberCourses: newMemberCourses
      }),
    onQuizzesChange: newQuizzes =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZZES,
        quizzes: newQuizzes
      }),
    onLessonsChange: newLessons =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSONS,
        lessons: newLessons
      }),
    onAssesmentsChange: newAssesments =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENTS,
        assesments: newAssesments
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersArea);
