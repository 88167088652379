import React, { Component } from "react";
import logo from "../../../assets/img/NarfoLogo.png";
import nnfo from "./images/nnfo - Copy.jpg";
import narfoLogo from "./images/narfoLogo.jpg";
import {
  BASE,
  GET_MEMBER,
  POST_DEDICATED_STATUS_VIEW
} from "../../../API/APIendpoint";
import axios from "axios";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import { isMobile } from "react-device-detect";
class MembershipCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemberDets: [],
      DedicatedStatus: []
    };
  }

  componentDidMount() {
    axios({
      url: BASE +
      GET_MEMBER +
      (this.props.memberState.isAdmin
        ? this.props.MemberV.memNo
        : this.props.userDetails.memberNumber),
      method: "get",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(json => {
      this.setState({
        MemberDets: json.data.members
      });
      const user = { memNo: this.state.MemberDets.memNo };
      axios({
        url: `${BASE}api/DedicatedStatus/approvedDS/${this.props.userDetails.memberNumber}`,
        method: 'get',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        this.setState({DedicatedStatus: res.data})
      })
      .catch(function (error) { });
    });
  }

  todaysDate = () => {
    var today = new Date();
    return (
      (today.getDate() < 10?`0${today.getDate()}`:today.getDate()) + "-" + ((today.getMonth() + 1) < 10?`0${(today.getMonth() + 1)}`:(today.getMonth() + 1)) + "-" + today.getFullYear()
    );
  };
  render() {

    const row = (col1, col2, index) => (
      <div className="info-text" key={index}>
        <div>{col1} </div>
        <div className="text-success pl-2"> {col2}</div>
      </div>
    );

    return (
      <div className="pt-4">
        <div className="membership-card-root">
          <div className={isMobile?"membership-card-body-mobile":"membership-card-body"}>
            <div className="membership-card-body-company-details">
              <div className="membership-card-body-company-details-logo">
                <img
                  src={nnfo}
                  alt="NARFO Logo"
                /> 
              </div>
              <div className="membership-card-body-company-details-info">
                <div className="row">
                  <div className="col-12-xs ml-2">Responsible Firearm Ownership</div>
                </div>
                <div className="row">
                  <div className="col-12-xs">
                    info@narfo.co.za | www.narfo.co.za
                    </div>
                </div>
                <div className="row">
                  <div className="col-12 font-weight-bold" align="center">
                    Date: {this.todaysDate()}
                  </div>
                </div>
              </div>
            </div>

            <div className={isMobile?"membership-card-body-member-details2-mobile":"membership-card-body-member-details2"}>
              <div className="membership-card-body-member-details-header">
                {isMobile ?
                  <h5 className="font-weight-bold mb-0">
                    Membership Card
                  </h5> :
                  <h4 className="font-weight-bold mb-0">
                    Membership Card
                  </h4>
                }
                <div>
                  {this.state.MemberDets.firstname}{" "}
                  {this.props.getLoggedInMember?this.props.getLoggedInMember.surname: " "}
                </div>
              </div>
              <div className="membership-card-body-member-details-info">
                {row("Identity Number:", this.props.getLoggedInMember?this.props.getLoggedInMember.idno: " ")}
                {row("Member Number:", this.state.MemberDets.memNo)}
                {row(
                  "Member Expiry Date:",
                  this.state.MemberDets.expiryDate === null ||
                    this.state.MemberDets.expiryDate === undefined
                    ? this.state.MemberDets.expiryDate
                    : this.state.MemberDets.expiryDate.substring(0, 10)
                )}
                {row("Membership Type:", this.state.MemberDets.memType)}
                {this.state.DedicatedStatus.length !== 0 ? this.state.DedicatedStatus.map((name, index) => (
                  name.dstype === "Dedicated Sports Person" ?
                  row("DSS Number:", name.dsno, index) : 
                  name.dstype === "Dedicated Hunter" ?
                  row("DHS Number:", name.dsno, index) :
                  null
                )) : null}
              </div>
            </div>
          </div>
          <div className="membership-card-footer">
            {"Accreditation:Hunting - FAR: 1300135  |Sports Shooting:-FAR: 1300134"}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    memberState: state.loginReducer.memberState,
    getLoggedInMember: state.MemberViewReducer.getLoggedInMember
  };
};

export default connect(mapStateToProps)(MembershipCard);
