import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Progress from "./memberApplicationProgress";
import "./styles/memberDetailsStyles.css";
import {
  NextPageButton,
  PreviousPageButton,
  POPIActInfo
} from "./navigatorButtons";

class MemberAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextPage: false,
      children: ["", "", "", "", ""],
      provinceSelected: false
    };
  }

  handleChange = (name, index) => event => {
    let array = this.state.children;
    array[index] = event.target.value;
    this.setState({
      children: array
    });
    this.props.onMemberDetailsChange(event.target.value, name);
    this.checkNotEmpty();
  };

  checkNotEmpty = () => {
    let isNextPage = true;
    this.state.children.map((child, index) => {
      if (
        child === "" ||
        child === null ||
        child === undefined ||
        child === "select province"
      ) {
        isNextPage = false;
      }
      if (index === 3 && (child === "select province" || child === "")) {
        this.setState({
          provinceSelected: false
        });
      } else if (index === 3 && child !== "select province") {
        this.setState({
          provinceSelected: true
        });
      }
      return null;
    });
    this.setState({
      nextPage: isNextPage
    });
  };

  componentWillUnmount() {
    this.props.onMemberDetailsChange(this.state.children, "addressChildren");
  }

  componentDidMount() {
    this.checkNotEmpty();
  }

  componentWillMount() {
    this.setState({
      children: this.props.memDetails.addressChildren
        ? this.props.memDetails.addressChildren
        : this.state.children
    });
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (!value) {
        return false;
      }
      if (value.length <= 1) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isCellNo", value => {
      if (!value) {
        return false;
      }
      if (value.length !== 10) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isPostalCode", value => {
      if (!value) {
        return false;
      }
      if (value.length < 4 || value.length > 6) {
        return false;
      }
      return true;
    });
  }

  render() {
    const provinces = [
      "select province",
      "Gauteng",
      "Mpumalanga",
      "Eastern Cape",
      "Western Cape",
      "Limpopo",
      "Free State",
      "KwaZulu Natal",
      "North West",
      "Northern Cape"
    ];
    return (
      <div className="member-details-root" style={{ marginTop: "80px" }}>
        <Progress progress={2} />
        <div className="member-details-form">
          {POPIActInfo()}
          <br />
          <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onError={error => {
              this.setState({ nextPage: false });
            }}
          >
            <div className="member-details-text-input-parent">
              <label> Residential Address </label>
              <div className="member-details-text-input">
                <TextValidator
                  Physical
                  Address
                  name="PhysicalAddress"
                  id="OutlinedAddress"
                  type="text"
                  value={this.props.memDetails.PhysicalAddress}
                  onChange={this.handleChange("PhysicalAddress", 0)}
                  validators={["isEmpty", "required"]}
                  errormessages={[
                    "Residential address is too short",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent">
              <label> Suburb </label>
              <div className="member-details-text-input">
                <TextValidator
                  name="Suburb"
                  id="OutlinedSuburb"
                  type="text"
                  value={this.props.memDetails.Suburb}
                  onChange={this.handleChange("Suburb", 1)}
                  validators={["isEmpty", "required"]}
                  errormessages={[
                    "Suburb requires atleast 4 characters",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="member-details-text-input-parent">
              <label> City </label>
              <div className="member-details-text-input">
                <TextValidator
                  name="City"
                  id="OutlinedCity"
                  type="text"
                  value={this.props.memDetails.City}
                  onChange={this.handleChange("City", 2)}
                  validators={["isEmpty", "required"]}
                  errormessages={[
                    "City name is too short",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>

            <div className="member-details-text-input-parent ethnicity">
              <label> Province </label>
              <div className="member-details-text-input">
                <FormControl variant="Outlined">
                  <Select
                    Province
                    native
                    type="text"
                    value={this.props.memDetails.Province}
                    onChange={this.handleChange("Province", 3)}
                    input={
                      <OutlinedInput
                        name="Province"
                        labelWidth={0}
                        id="OutlinedProvinceNativeSimple"
                      />
                    }
                  >
                    {provinces.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {this.state.provinceSelected ? null : (
                <p class="text-danger">Please Select</p>
              )}
            </div>

            <div className="member-details-text-input-parent">
              <label> Postal Code </label>
              <div className="member-details-text-input post-code">
                <TextValidator
                  name="PostalCode"
                  id="outlined-PostalCode"
                  type="text"
                  value={this.props.memDetails.PostalCode}
                  onChange={this.handleChange("PostalCode", 4)}
                  validators={["isPostalCode", "required"]}
                  errormessages={[
                    "invalid postal code",
                    "This field is required"
                  ]}
                  margin="normal"
                  variant="outlined"
                />
              </div>
            </div>
            <div className="nav-button-parent">
              <Link to="/MemberDetails">
                <PreviousPageButton />
              </Link>
              {this.state.nextPage ? (
                <Link to="/declaration">
                  <NextPageButton />
                </Link>
              ) : (
                <NextPageButton />
              )}
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberAddress);
