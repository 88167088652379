import { createTheme } from '@material-ui/core/styles';

const theme =  createTheme({
  palette: {
    primary: {
      light: '#E3DFF0',
      main: '#4F36A6',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#151515',
      light: '#4F4F4F',
      contrastText: '#483C9E',
    },
    common: {
      white2: {
        main: '#FAFAFA',
      },
      white3: {
        main: '#F9F9F9',
      },
    },
    action: {
      hover: '#DADADAAB',
      active: '#4F36A6',
    },
    buttonPrimary: {
      main: '#483C9E',
    },
    buttonSecondary: {
      main: '#E3DFF0',
    },
    buttonContrast: {
      main: '#FFFFFF',
      contrastText: '#E3DFF0'
    },
    tableLabelColor: {
      main: '#898989',
    },
    tableBackgroundColor: {
      main: '#EDEDED',
    },
    text: {
      primary: '#4F4F4F',
      secondary: '#483C9E',
    },
    navBarBackdrop: {
      background: 'linear-gradient(150deg, #FFFFFF 70%, #4F36A6 70%)',
    },
    footerBackdrop: {
      background: '#F0F0F0',
    },
    mainBackground: {
      backgroundColor: '#EEEDF0',
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif"',
    fontSize: 16,
    lineHeight: '1.375rem',
    button: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    h1: {
      fontWeight: 800,
      fontSize: '1.875rem',
      color: '#4F4F4F',
      lineHeight: '2.0625rem',
    },
    h4: {
      fontWeight: 800,
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    h5: {
      fontWeight: 800,
      fontSize: '1.25rem',
      lineHeight: '1.6rem',
    },
    h6: {
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    subtitle1: {
      fontSize: '1.875rem',
      color: '#483C9E',
      lineHeight: '2.0625rem',
      fontWeight: 'lighter',
    },
    subtitle2: {
      fontSize: '1.5rem',
      color: '#483C9E',
      lineHeight: '2.0625rem',
      fontWeight: 'lighter',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
    },
    body2: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
    nav: {
      fontSize: '0.9375rem',
      color: '#1A1630',
      lineHeight: '1.277rem',
      fontWeight: 'medium',

    },
    paragraph: {
      fontSize: '0.9375rem',
      color: '#000000',
      lineHeight: '1.2rem',
      fontWeight: 'normal'
    },
    icons: {
      fontSize: '1.625rem'
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        margin: '5px 0',
      },
    },
    MuiTypography: {
      root: {
        paddingLeft: '10px',
      }
    },
    MuiTabs: {
      flexContainer: {
        padding: '5px',
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#EDEDED',
      },
      head: {
        backgroundColor: 'transparent'
      }
    },
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: '0 2px',
      }
    },
    MuiTableCell: {
      root: {
        fontSize: '1rem',
        lineHeight: '2.4rem',
        padding: '0 8px',
        maxWidth: '250px',
      },
      body: {
        color: '#898989',
      }
    },
    MuiButton: {
      containedSecondary: {
        color: '#483C9E',
        backgroundColor: '#E3DFF0',
        '&:hover': {
          color: '#FAFAFA',
          backgroundColor: '#7867b1'
        }
      },
    },
    MuiRadio: {
      colorSecondary: {
        color: '#4F36A6',
        '&.Mui-checked': {
          color: '#4F36A6',
        }
      },
    },
    actionbutton: {
      backgroundColor: '#ffffff',
      color: '#4F36A6',
      padding: '15px',
      border: '1px solid #EEEDF0',
      boxShadow: 'none',
      borderRadius: '0',
      borderTop: '0',
      fontWeight: 'bold',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
      }
    },
    sectionHeading: {
      fontSize: '1.5rem',
      fontWeight: '500',
      color: '#4F36A6',
      borderBottom: '1px solid #EEEDF0',
      padding: '15px'
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media(min - width: 0px) and(orientation: landscape)': {
        minHeight: 48
      },
      '@media(min - width: 600px)': {
        minHeight: 64
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: (factor) => `${factor * 4}px`,
  targetList: {
    fontWeight: 800,
    fontSize: '1.25rem',
    lineHeight: '1.6rem',
  },
})

export default theme;
