import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import {
  BASE,
  DELETE_ADMINS,
  GET_ADMINS_LIST,
  GET_ALL_MEMBERS_DEDICATED,
  GET_ALL_ADMIN_LIST,
  POST_ADMIN, POST_SALES
} from "../../API/APIendpoint";
import { STATUS, PAGE } from "../../store/constantFile/ConstFile";
import { connect } from "react-redux";
import GenericHeader from "../../utils/GenericHeader";
import MemberCard from "../viewAllMember/MemberCard";
import { withStyles } from "@material-ui/core/styles";
import Select from 'react-select';
import * as actionTypes from "../../store/actions/actionTypes";
import Radio from "@material-ui/core/Radio";
import MenuItem from '@material-ui/core/MenuItem';
import { toast } from 'react-toastify';
import Input from "@material-ui/core/Input";
import { copyFileSync } from "fs";
toast.configure();
const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
  root: {
    height: "100%"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    textAlign: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    align: "center"
  },

  footer: {
    display: "comtent",
    flexDirection: "row"
  },

  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    fullWidth: true,
    flex: "1",
    margin: "5",
    instantValidate: true
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#F5A623",
    "&:hover": {
      backgroundColor: "#FF0000"
    },
    width: "auto",
    color: "white",
    marginLeft: "1%"
  },
  textField: {
    marginTop: "1%",
    marginLeft: "30%",
    marginRight: "30%",
    flex: 1,
    margin: 5
  },
  Drop: {
    marginTop: "0.1%",
    marginLeft: "1%",
    marginRight: "5%",
    flex: 1,
    margin: 1,
    width: "100%"
  },
  Head: {
    marginTop: "1%",
    marginLeft: "1%",
    marginRight: "5%",
    flex: 1,
    margin: 1,
    color: "#000000"
  }
});


function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}
const components = {
  Option,
};

class adminHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admins: [],
      pages: PAGE.home,
      memNo: "",
      Admin_List: [],
      Member_List: [],
      Selected: "",
      NotSelected: "",
      isClearable: true,
      isLoading: true,
      isSearchable: true,
      single: "",
      AdminId: "",
      DropDown: [{ value: "", label: "" }],
      AdminDropDown: [{ value: "", label: "" }],
      selectedOption: null,
      userIds: [],
      _oldSalesRepMemNo: "",
      _newSalesRepMemNo: "",
      _adminMemNo: "",
      _invalidAdminRemovalMsg: "",
      _invalidSalesRemovalMsg: "",
      _invalidEntryMsg: "",
      _partnerStoreID: "",
      _commisionPercentage: "",
      _invalidcommisionPercentageMsg: "",
      _commisionFrequency: "",
      _invalidcommisionFrequencyMsg: "",
      _bankName: "",
      _invalidBankMsg: "",
      _branchCode: "",
      _invalidBranchCodeMsg: "",
      _accNumber: "",
      _accType:"",
      _invalidAccNumberMsg: "",
      _invalidAccTypeMsg: "",
      _salesRepForPartner: "",
      _ivalidSalesRepForPartner: "",
      _store: "",
      _invalidStoreMsg: "",
      _storeAddress: "",
      _invalidStoreAddressMsg: "",
      _invalidAdminMsg: "",
      _isAddRepToPartnerSelected: false,
      _isAddParnterSelected: false,
      _salesRepReplaceSelected: true,
      _adminRemoveSelected: false,
      _memberToPartnerSelected: false,
      _adminSalesAddSelected: true,
      _salesRepAddSelected: false,
      _salesRepToPartner: false,
      _allStores: []
    };
  }
  _handleChange = name => value => {
    if (!(value.value === "")) {
      this.setState({
        [name]: value.value,
      });
    }

  };

  componentDidMount() {
	this.refreshAPI();
    fetch(BASE + GET_ADMINS_LIST,{
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => res.json())
      .then(data => {
        let Admin_arr = [];
        data.map(V => (
          Admin_arr =
          [...Admin_arr, {
            value: V.adminId, label: V.memNo + "-" +
              V.memNoNavigation.firstname + " " +
              V.memNoNavigation.surname + "-" +
              V.memType
          }]
        ))
        this.setState({
          AdminDropDown: Admin_arr,
          Admin_List: data
        });
      });
    fetch(BASE + GET_ALL_MEMBERS_DEDICATED,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
      })
      .then(res => res.json())
      .then(data => {
        let arr = [];
        data.map(V => (
          arr = [...arr, { value: V.memNo, label: V.memNo + "-" + V.firstname + " " + V.surname }]
        ))
        this.setState({
          DropDown: arr,
          Member_List: data
        });
      });
    axios({
      url: `${BASE}api/Stores`,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res =>{
      this.setState({
        _allStores: res.data
      })
    })
  }
  DeleteKey = e => {
    this.setState({
      DelKey: [e.value]
    });
    this.props.onSectionChange(e.value, "DeleteAdminKey");
  };
  refreshAPI() {
    axios
      .get(BASE + GET_ALL_ADMIN_LIST, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(res => {
        this.setState({ admins: res.data });
      })
      .catch(function (error) {
        if (error.status === 400) {
          alert(error.response.data.message);
        }
      });
  }
  getColor(type) {
    switch (type) {
      case STATUS.Approved: {
        return "#4BB543";
      }
      case STATUS.Pending: {
        return "#FFCC00";
      }
      case STATUS.Declined: {
        return "#CC3300";
      }
      case STATUS.Terminated: {
        return "#CC3300";
      }
      default:
    }
  }
  handleMemberNumber = e => {
    this.setState({
      memNo: e.target.value
    });
  };
  handleAdminMember = () => {
    if (this.state.pages === PAGE.home) {
      this.setState({ pages: PAGE.edit });
    } else {
      this.setState({ pages: PAGE.home });
    }
  };

  handleAdminMemberDel = () => {
    if (this.state.pages === PAGE.home) {
      this.setState({ pages: PAGE.delete });
    } else {
      this.setState({ pages: PAGE.home });
    }
    this.componentDidMount();
  };

  finalSubmit() {
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else {
      axios({
        url: BASE + POST_ADMIN,
        data: {
          MemNo: this.state.memNo
        },
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
        .then(res => {
          alert(res.data.message);
          this.refreshAPI();
          this.setState({ pages: PAGE.home });
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          }
        });
    }
  }
  addSubmit() {
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else {
      axios({
        url: BASE + POST_SALES,
        data: {
          MemNo: this.state.memNo
        },
        method: "post",
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
        .then(res => {
          alert(res.data.message);
          this.refreshAPI();
          this.setState({ pages: PAGE.home });
        })
        .catch(function (error) {
          if (error.response.status === 400) {
            alert(error.response.data.message);
          }
        });
    }
  }
  setDrop() {
    this.state.Member_List.map(V => (
      this.setState({
        DropDown: [{ value: V.memNo, label: V.memNo }]
      })
    ))
  }
  handleChange = selectedOption => {
    this.setState({
      memNo: selectedOption.value
    });
  };
  addToArray = (data) => {
    this.state.userIds.push(data)
  }
  replaceSalesRep = () => {
    axios({
      url: `${BASE}api/admin/replaceSalesRep/${this.state._oldSalesRepMemNo}/${this.state._newSalesRepMemNo}`,
      method: "post",
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      toast.success(res.data.message);
      this.refreshAPI();
      this.setState({ 
        pages: PAGE.home,
        _oldSalesRepMemNo: "",
        _newSalesRepMemNo: ""
      });
    })
  }
  removeAdmin = () => {
    axios({
      url: `${BASE}api/admin/removeAdmin/${this.state._adminMemNo}`,
      method: "post",
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      toast.success(res.data.message);
      this.refreshAPI();
      this.setState({ 
        pages: PAGE.home,
        _adminMemNo: ""
      });
    })
  }
  newPartner = () => {
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else {
      axios({
        url: `${BASE}api/admin/newParnter/${this.state._store}/${this.state._storeAddress}`,
        method: "post",
        data: {
          MemNo: this.state.memNo,
          Commision: this.state._commisionPercentage,
          Frequency: this.state._commisionFrequency,
          BankName: this.state._bankName,
          BranchCode: this.state._branchCode,
          AccountNo: this.state._accNumber,
          AccountType: this.state._accType
        },
        headers: { 
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        toast.success(res.data.message);
        this.refreshAPI();
        this.setState({ 
          pages: PAGE.home,
          _adminSalesAddSelected: true
        });
      })
    }
  }
  assignSalesRepToPartner = () => {
    axios({
      url: `${BASE}api/admin/assignSalesRepToPartner/${this.state._partnerStoreID}`,
      method: "post",
      data: {
        MemNo: this.state.memNo,
        Commision: this.state._commisionPercentage,
        Frequency: this.state._commisionFrequency,
        BankName: this.state._bankName,
        BranchCode: this.state._branchCode,
        AccountNo: this.state._accNumber,
        AccountType: this.state._accType
      },
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      toast.success(res.data.message);
      this.refreshAPI();
      this.setState({ 
        pages: PAGE.home,
        _adminSalesAddSelected: true
      });
    })
  }
  addSalesRep = () => {
    axios({
      url: `${BASE}api/admin/post/set/sales2`,
      method: "post",
      data: {
        MemNo: this.state.memNo,
        MemType: "Sales",
        StoreID: this.state._partnerStoreID,
        Commision: this.state._commisionPercentage,
        Frequency: this.state._commisionFrequency,
        BankName: this.state._bankName,
        BranchCode: this.state._branchCode,
        AccountNo: this.state._accNumber,
        AccountType: this.state._accType
      },
      headers: { 
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      toast.success(res.data.message);
      this.refreshAPI();
      this.setState({ 
        pages: PAGE.home,
        _adminSalesAddSelected: true
      });
    })
  }
  checkSalesRepDeletion = e => {
    e.preventDefault();
    if(this.state._oldSalesRepMemNo === "")
      this.setState({_invalidSalesRemovalMsg: "Please select Sales Rep to delete"})
    if(this.state._newSalesRepMemNo === "")
      this.setState({_invalidEntryMsg: "Please select Sales Rep to asigned"})
    if(this.state._oldSalesRepMemNo !== "" && this.state._newSalesRepMemNo !== "" && this.state._oldSalesRepMemNo === this.state._newSalesRepMemNo)
      this.setState({_invalidEntryMsg: "Cannot remove and asign same sales rep"})
    if(this.state._oldSalesRepMemNo !== "" && this.state._newSalesRepMemNo !== "" && this.state._oldSalesRepMemNo !== this.state._newSalesRepMemNo)
      this.replaceSalesRep();
  }
  checkAdminDeletion = e => {
    e.preventDefault();
    if(this.state._adminMemNo === "")
      this.setState({_invalidAdminRemovalMsg: "Please select Admin to remove"})
    else
      this.removeAdmin();
  }
  checkMemberToPartnerAllocation = e => {
    e.preventDefault();
    console.log(this.state._partnerStoreID)
    if(!this.state._isAddParnterSelected) {
      if(this.state._store === "")
        this.setState({_invalidStoreMsg: "Please enter partner name"})
      if(this.state._storeAddress === "")
        this.setState({_invalidStoreAddressMsg: "Please enter store address"})
    }
    if(this.state._partnerStoreID === "")
      this.setState({_ivalidSalesRepForPartner: "Please Select Partner to assign"})
    if(this.state._salesRepForPartner === "" || this.state._salesRepForPartner === "Select Sales Rep")
      this.setState({_invalidSalesRepMsg: "Please select Sales Rep to assign"})
    if(this.state._commisionPercentage === "")
      this.setState({_invalidcommisionPercentageMsg: "Please enter percentage"})
    if(this.state._commisionFrequency === "")
      this.setState({_invalidcommisionFrequencyMsg: "Please enter frequency"})
    if(this.state._bankName === "")
      this.setState({_invalidBankMsg: "Please enter bank name"})
    if(this.state._branchCode === "")
      this.setState({_invalidBranchCodeMsg: "Please enter branch code"})
    if(this.state._accNumber === "")
      this.setState({_invalidAccNumberMsg: "Please enter account number"})
    if(this.state._accType === "")
      this.setState({_invalidAccTypeMsg: "Please enter account type"})
    if(!this.state._isAddParnterSelected && !this.state._salesRepAddSelected) {
      if(this.state._store !== "" || this.state._storeAddress !== "" ||
        this.state._commisionPercentage !== "" && this.state._commisionFrequency !== "" &&
        this.state._bankName !== "" && this.state._branchCode !== "" && this.state._accNumber !== "" && this.state._accType !== "")
        this.newPartner();
    } else if(this.state._salesRepAddSelected) {
      if(this.state._commisionPercentage !== "" && this.state._commisionFrequency !== "" &&
        this.state._bankName !== "" && this.state._branchCode !== "" && this.state._accNumber !== "" && this.state._accType !== "")
        this.addSalesRep();
    } else {
      if(this.state._commisionPercentage !== "" && this.state._commisionFrequency !== "" &&
        this.state._bankName !== "" && this.state._branchCode !== "" && this.state._accNumber !== "" && this.state._accType !== "")
        this.assignSalesRepToPartner();
    }
  }
  checkIsAdmin = () => {
    let _isPartnerRole = false;
    const _memAlTypes = this.state.Member_List.filter(element => element.memNo === this.state.memNo);
    _memAlTypes.forEach(item =>{
      if(item.memType === "Partner") {
        _isPartnerRole = true;
      }
    })
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else if(_isPartnerRole) {
      this.setState({_invalidAdminMsg: "Member is already a Partner"})
    } else {
      axios({
        url: `${BASE}api/admin/post/isAdmin2/${this.state.memNo}`,
        method: "post",
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        if(res.data.message){
          this.refreshAPI();
          this.setState({
            _adminSalesAddSelected: false
          })
        } else {
          this.setState({
            _invalidAdminMsg: "Member must be an administrator"
          })
        }
      })
    }
  }
  checkIsSales = () => {
    let _isSalesRole = false;
    const _memAlTypes = this.state.Member_List.filter(element => element.memNo === this.state.memNo);
    _memAlTypes.forEach(item =>{
      if(item.memType === "Sales") {
        _isSalesRole = true;
      }
    })
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else if(_isSalesRole) {
      this.setState({_invalidAdminMsg: "Member is already a Sales Rep"})
    } else {
      this.setState({
        _salesRepAddSelected: true,
        _adminSalesAddSelected: false
      })
    }
  }
  checkIsPartner = () => {
    if(!this.state.memNo) {
      this.setState({_invalidAdminMsg: "Please select member"})
    } else {
      axios({
        url: `${BASE}api/admin/post/isPartner/${this.state.memNo}`,
        method: "post",
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        if(res.data.message){
          this.refreshAPI();
          this.setState({
            _invalidAdminMsg: "Member already assigned to Partner"
          })
        } else {
          this.setState({
            _isAddRepToPartnerSelected: true,
            _isAddParnterSelected: true,
            _adminSalesAddSelected: false
          })
        }
      })
    }
  }
  render() {
    const { classes } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        '& input': {
          font: 'inherit',
        },
      }),
    };
    const salesReps = this.state.Member_List.filter(element => element.memType == "Sales");
    const _admins = this.state.Member_List.filter(element => element.memType == "Admin");
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        {this.state.pages !== PAGE.home ? (
          <GenericHeader
            showBackButton
            backFunction={() => {
              this.setState({ 
                pages: PAGE.home
              });
            }}
            title="Admin/Sales Members"
            showAddButton
            addFunction={() => this.handleAdminMember()}
            showDeleteButton
            DeleteFunction={() => this.handleAdminMemberDel()}
          />
        ) : (
            <GenericHeader
              title="Admin/Sales Members"
              showAddButton
              addFunction={() => this.handleAdminMember()}
              showDeleteButton
              DeleteFunction={() => this.handleAdminMemberDel()}
            />
          )}
        {this.state.pages === PAGE.home ?
          Array.from(new Set(this.state.admins.map(a => a.memNo))).map(memNo => {
            return <MemberCard MemberArray={this.state.admins.find(a => a.memNo === memNo).memNoNavigation} />
          })  : 
        null}
        {this.state.pages === PAGE.edit && (
          <div>
            <div className=" container mt-3">
              {this.state._adminSalesAddSelected ?
                <div>
                  <h6 className="text-secondary">Enter Member Number</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        memNo: a.target.value,
                        _invalidSalesRepMsg: ""
                      });
                    }}
                  >
                    <option>Select Sales Rep</option>
                    {this.state.Member_List.map(item => 
                      <option value={item.memNo}>{`${item.memNo}-${item.firstname} ${item.surname}-${item.memType}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._invalidAdminMsg}</div>
                  <button
                    type="button"
                    className="btn mt-2 mr-3 btn-secondary"
                    onClick={() => {
                      this.finalSubmit();
                    }}
                  >
                    Add New Admin
                  </button>
                  <button
                    type="button"
                    className="btn mt-2 mr-2 btn-secondary"
                    onClick={() => {
                      this.checkIsSales();
                    }}
                  >
                    Add New Sales
                  </button>
                  <button
                    type="button"
                    className="btn mt-2 mr-2 ml-2 btn-secondary"
                    onClick={() =>this.checkIsAdmin()}
                  >
                    Add New Partner
                  </button>
                  <button
                    type="button"
                    className="btn mt-2 mr-2 ml-2 btn-secondary"
                    onClick={() =>this.checkIsPartner()}
                  >
                    Rep To Partner
                  </button>
                </div> : this.state._isAddRepToPartnerSelected ?
                <div>
                  <h6 className="text-secondary">Select Partner To Allocate In</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _partnerStoreID: a.target.value,
                        _invalidSalesRepMsg: ""
                      });
                    }}
                  >
                    <option value="Select Partner" selected>Select Partner</option>
                    {this.state._allStores.map(item => 
                      <option value={item.storeId}>{`${item.store}-${item.adress}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._ivalidSalesRepForPartner}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Percentage</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _commisionPercentage: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidcommisionPercentageMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Frequency</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _commisionFrequency: a.target.value,
                        _invalidcommisionFrequencyMsg: ""
                      });
                    }}
                  >
                    <option>Select Frequency</option>
                    <option value="M">Monthly</option>
                    <option value="A">Annum</option>
                  </select>
                  <div className="text-danger">{this.state._invalidcommisionFrequencyMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Bank Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _bankName: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBankMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Branch Code</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _branchCode: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBranchCodeMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Number</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accNumber: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccNumberMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Type</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accType: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccTypeMsg}</div>
                  <div className="placeAtEnd">
                    <button className="btn btn-warning mt-3" onClick={e => this.checkMemberToPartnerAllocation(e)}>
                      ASSIGN
                    </button>
                  </div>
                </div> : this.state._salesRepAddSelected ?
                <div>
                  <h6 className="text-secondary">Select Partner To Allocate In</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _partnerStoreID: a.target.value,
                        _invalidSalesRepMsg: ""
                      });
                    }}
                  >
                    <option value="Select Partner" selected>Select Partner</option>
                    {this.state._allStores.map(item => 
                      <option value={item.storeId}>{`${item.store}-${item.adress}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._ivalidSalesRepForPartner}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Percentage</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _commisionPercentage: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidcommisionPercentageMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Frequency</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _commisionFrequency: a.target.value,
                        _invalidcommisionFrequencyMsg: ""
                      });
                    }}
                  >
                    <option>Select Frequency</option>
                    <option value="M">Monthly</option>
                    <option value="A">Annum</option>
                  </select>
                  <div className="text-danger">{this.state._invalidcommisionFrequencyMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Bank Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _bankName: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBankMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Branch Code</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _branchCode: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBranchCodeMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Number</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accNumber: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccNumberMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Type</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accType: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccTypeMsg}</div>
                  <div className="placeAtEnd">
                    <button className="btn btn-warning mt-3" onClick={e => this.checkMemberToPartnerAllocation(e)}>
                      ASSIGN
                    </button>
                  </div>
                </div> :
                <div>
                  <h6 className="text-secondary mt-1">Enter Partner Name</h6>
                    <input
                      type="text"
                      className="form-control"
                      onInput={a => {
                        this.setState({
                          _store: a.target.value
                        });
                      }}
                    />
                    <div className="text-danger">{this.state._invalidStoreMsg}</div>
                    <h6 className="text-secondary mt-1">Enter Partner Address</h6>
                    <input
                      type="text"
                      className="form-control"
                      onInput={a => {
                        this.setState({
                          _storeAddress: a.target.value
                        });
                      }}
                    />
                    <div className="text-danger">{this.state._invalidStoreAddressMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Percentage</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _commisionPercentage: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidcommisionPercentageMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Commision Frequency</h6>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _commisionFrequency: a.target.value,
                        _invalidcommisionFrequencyMsg: ""
                      });
                    }}
                  >
                    <option>Select Frequency</option>
                    <option value="M">Monthly</option>
                    <option value="A">Annum</option>
                  </select>
                  <div className="text-danger">{this.state._invalidcommisionFrequencyMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Bank Name</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _bankName: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBankMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Branch Code</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _branchCode: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidBranchCodeMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Number</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accNumber: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccNumberMsg}</div>
                  <h6 className="text-secondary mt-1">Enter Account Type</h6>
                  <input
                    type="text"
                    className="form-control"
                    onInput={a => {
                      this.setState({
                        _accType: a.target.value
                      });
                    }}
                  />
                  <div className="text-danger">{this.state._invalidAccTypeMsg}</div>
                  <div className="placeAtEnd">
                  <button className="btn btn-warning mt-3" onClick={e => this.checkMemberToPartnerAllocation(e)}>
                    ASSIGN
                  </button>
                </div>
                </div>
              }
            </div>
            <div className="text-danger">{this.state._invalidAdminRemovalMsg}</div>
          </div>
        )}
        {this.state.pages === PAGE.delete && (
          <div>
            <div className=" container mt-3">
            <div>Choose MemType To Remove</div>
              <Radio
                checked={this.state._salesRepReplaceSelected}
                onChange={() =>this.setState({_adminRemoveSelected: false, _salesRepReplaceSelected: true})}
              />
              Remove Sales Rep
              <Radio
                checked={this.state._adminRemoveSelected}
                onChange={() =>this.setState({_adminRemoveSelected: true, _salesRepReplaceSelected: false})}
              />
              Remove Sale Admin
              {this.state._adminRemoveSelected ? 
                <div>
                  <div className="mt-2">Admin To Be Removed</div>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _adminMemNo: a.target.value,
                        _invalidAdminRemovalMsg: ""
                      });
                    }}
                  >
                    <option>Select Admin</option>
                    {_admins.map(item => 
                      <option value={item.memNo}>{`${item.memNo}-${item.firstname} ${item.surname}-${item.memType}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._invalidAdminRemovalMsg}</div>
                  <div className="placeAtEnd">
                    <button className="btn btn-warning mt-3" onClick={e => this.checkAdminDeletion(e)}>
                      Delete
                    </button>
                  </div>
                </div> :
                <div>
                  <div className="mt-2">Sales Rep To Be Removed</div>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _oldSalesRepMemNo: a.target.value,
                        _invalidSalesRemovalMsg: ""
                      });
                    }}
                  >
                    <option>Select Old Sales Rep</option>
                    {salesReps.map(item => 
                      <option value={item.memNo}>{`${item.memNo}-${item.firstname} ${item.surname}-${item.memType}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._invalidSalesRemovalMsg}</div>
                  <div className="mt-2">Sales Rep To Be Added</div>
                  <select
                    className="form-control input-sm m-0"
                    aria-describedby="emailHelp"
                    onInput={a => {
                      this.setState({
                        _newSalesRepMemNo: a.target.value,
                        _invalidEntryMsg: ""
                      });
                    }}
                  >
                    <option>Select New Sales Rep</option>
                    {salesReps.map(item => 
                      <option value={item.memNo}>{`${item.memNo}-${item.firstname} ${item.surname}-${item.memType}`}</option>
                    )}
                  </select>
                  <div className="text-danger">{this.state._invalidEntryMsg}</div>
                  <div className="placeAtEnd">
                    <button className="btn btn-warning mt-3" onClick={e => this.checkSalesRepDeletion(e)}>
                      Delete
                    </button>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    Selections: state.DropDownReducer.Options
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSectionChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_OPTIONS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(adminHandler));
