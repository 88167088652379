import React, { useState } from 'react';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import {
  Grid, Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import IconLabelButton from '../DownloadButton/IconLabelButton';
import { CloudDownload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  getYearFromISO,
  valid,
  yearsRange,
} from '../../shared/helpers';
import { editableSelectConfig } from '../../shared/constants';

const useStyles = makeStyles(() => ({
  commisionContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - 350px)',
    paddingRight: '10px',
    paddingLeft: '10px',
  }
}));

const label = (value, title, isMobile) => (
  <Grid item xs={12} sm={4}>
    <div className="d-flex col justify-content-start pt-4">
      <Typography variant={isMobile ? 'h6' : 'body2'} align="left">{value || '-'}</Typography>
      <Typography variant="body1" align="left">{title}</Typography>
    </div>
  </Grid>
);

const commision = (comm, key, callBack) => {
  const {type, commissionNumber, paymentDate, amountTotal} = comm;
  const isMobile = localStorage.getItem('isMobile') === 'true';
  return (
    <div className="outer-shadow my-4 bg-white-2 px-10 py-5 border-radius-2" key={key}>
      <Grid container spacing={2}>
        <Grid item xs={12} container>
          <Grid item xs={10} md={6}>
            <Typography variant="h4" align="left" className='pt-3'>
              {isMobile ? type.split('Commission') : type || 'undefined '}  
            </Typography>
          </Grid>
          <Grid item xs={2} md={6} container justify="flex-end">
            <IconLabelButton 
              actionText="Download PDF"
              icon={<CloudDownload />}
              callBack={() => callBack(comm, '#auto-table')}
            />
          </Grid>
        </Grid>
        <Grid item spacing={2} xs={12} container>
          {label(commissionNumber, 'Commission number', isMobile)}
          {label(getYearFromISO(paymentDate), 'Commission year', isMobile)}
          {label(`R ${amountTotal}`, 'Commission amount', isMobile)}
        </Grid>
      </Grid>
    </div>
  );
};

const initCommissionType = (value, items) => ({
  title: 'Sales commission type',
  value: value,
  id: 'select-com-type',
  name: 'commissionType',
  options: {
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      ...valid(value, editableSelectConfig.config.validate),
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
      touched: true
    }
  }
});

const initYear = (value, items) => ({
  title: 'Select Year',
  value: value,
  id: 'select-year',
  name: 'year',
  options: {
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      ...valid(value, editableSelectConfig.config.validate),
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
      touched: true
    }
  }
});

const yearslist = yearsRange(2021, 2000, -1).map(y => ({ label: y, value: y }));

const AllCommissions = (props) => {
  const classes = useStyles();
  const items = [
    { label: 'Sales Rep Commission', value: 'Sales Rep Commission' },
    { label: 'Owner Commission', value: 'Owner Commission' },
  ];

  const [commissionType, setCommissionType] = useState(initCommissionType('-', items));
  const [year, setYear] = useState(initYear('-', yearslist));
  const filterOptions = {
    elements: [
      {
        ...commissionType,
        onChange: (e) => setCommissionType(initCommissionType(e.target.value, items))
      },
      {
        ...year,
        onChange: (e) => setYear(initYear(e.target.value, yearslist))
      }
    ],
    callBack: props.filterCommissions
  };
  return (
    <React.Fragment>
      <SubtitleBar title="All Commissions" filter filterOptions={filterOptions}/> 
      <div className={classes.commisionContainer}>
        <table id="auto-table" className="d-none"></table>
        {props.commissions.map((com, key) => commision(com, key, props.downloadCommission))}
      </div>
    </React.Fragment>
  );
};

AllCommissions.defaultProps = {
  commissions: [],
  downloadCommission: () => null,
  filterCommissions: () => Promise.resolve(null),
};

AllCommissions.propTypes = {
  commissions: PropTypes.array.isRequired,
  downloadCommission: PropTypes.func.isRequired,
  filterCommissions: PropTypes.func.isRequired,
};

export default AllCommissions;