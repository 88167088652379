import * as actionTypes from "../actions/actionTypes";

const initialState = {
  memberDetails: {
    memNo: "",
    username: "",
    firstname: "",
    surname: "",
    title: "",
    Approved: "",
    DSType: "",
    IDNo: "",
    Email: "",
    CellNo: "",
    PhysicalAddress: "",
    suburb: "",
    city: "",
    postalCode: "",
    Sex: "",
    Ethinicity: "",
    MemType: "",
    InceptionDate: "",
    ExpiryDate: "",
    SalesReps: "",
    Store: "",
    ActivityType: "Activity Type",
    ActivityDate: null,
    ActivityDescription: "",
    DateSubmitted: null,
    FirearmType: "",
    Caliber: "",
    PostalShoot: 0,
    Score: 0,
  },
  activityTypeFilter: "Activity Type",
  activityDateFilter: "2019-01-01",
  showActivityFilterBar: false,
  MemberList: [],
  ActivityList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MEMBER_DETAILS:
      const newMemberDetails = {
        ...state.MemberDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        MemberDetails: newMemberDetails
      };
    case actionTypes.UPDATE_MEMBER_DETAILS_LIST:
      return {
        ...state,
        MemberList: action.payload
      };
    default:
      return state;
  }
};

export default reducer 