import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconLabelButton,
  SubtitleBar
} from '../index';
import { Divider, Grid } from '@material-ui/core';
import { getStatusColor, label, formatISODateToLocaleString} from '../../shared/helpers';
import API_URL from '../../services/apiUrl';
import ActivitiesService from '../../services/activities.service';

const formatStatus = item => 
  <span className={getStatusColor(item)}>{item}</span>;

const downloadLinks = (links, ref) => {

  let url =  API_URL.includes("backend") ? (API_URL.replaceAll('backend.', '')).replaceAll('api', '') : (API_URL.replaceAll('api', '')).replaceAll('qa','qaportal');
  
  if(url === "https://narfo.co.za/")
     url = "https://portal.narfo.co.za/";

  //Check 

  //NB::For now replace https with http
  links.forEach(link => {
    ref.current.href = `${url.replaceAll('https','https')}${link}`;
    ref.current.click();
  });
};

function ViewActivity(props) {
  const ref = React.createRef();
  const activityService = ActivitiesService(localStorage.getItem('token'));
  const isMobile = localStorage.getItem('isMobile') === 'true';
  const [gallery, setGallary] = useState(null);

  useEffect(() => {
    getGalleryActivityItem();
  }, []);

  const getGalleryActivityItem =()=>{
    activityService.getGalleryActivity(props.activity.activityId).then(response =>{
      setGallary(response.data);
    });
  }
  return (
    <React.Fragment>
      <SubtitleBar
        title="Activity Details"
        back
        onBack={props.backCallback}
      />
      <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
        <Grid container spacing={4}>
          {label('Activity ID', props.activity.activityId, false, isMobile)}
          {label('Association', props.activity.association, false, isMobile)}
          {label('Status', props.activity.activityType, false, isMobile)}
          {label('Firearm', props.activity.firearmType, false, isMobile)}
          {label('Discipline', props.activity.activityDescription, false, isMobile)}

          {label('Activity Date', formatStatus(formatISODateToLocaleString(props.activity.activityDate)), false, isMobile)}
          {label('Activity Score', formatStatus(props.activity.score), false, isMobile)}
          {label('Firearm Caliber', formatStatus(props.activity.caliber), false, isMobile)}
          {label('Activity Status', formatStatus(props.activity.status), false, isMobile)}
          {props.activity.status =='Rejected' && label('Rejection Reason', formatStatus(props.activity.rejectReason), false, isMobile)}

          { gallery && label('Gallery Status', formatStatus(props.activity.status === 'Pending' ? 'Pending' : (gallery?.approved ? 'Approved' : 'Rejected')  ), false, isMobile)}

          { gallery && !gallery?.approved  && label('Gallery rejection Reason', formatStatus(gallery?.rejectionReason), false, isMobile)}

          <Grid item xs={12} className="py-5">
            <Divider />
          </Grid>
          {label('Notes', props.activity.memberNotes, false, isMobile)}
        </Grid>
      </div>
      {props.activity.link && <div className="mt-4">
        <a ref={ref} className="d-none" target="_"></a>
        <IconLabelButton 
          full 
          actionText="Download Activity Document"
          callBack={() => downloadLinks([props.activity.link], ref)}
        />
      </div>}
    </React.Fragment>
  );
}

ViewActivity.defaultProps = {
  backCallback: () => null,
  activity: {},
};

ViewActivity.propTypes = {
  backCallback: PropTypes.func.isRequired,
  activity: PropTypes.object.isRequired,
};

export default ViewActivity;

