import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import "../Uploads.css";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE, FRONT } from "../../../API/APIendpoint";
import "w3-css/w3.css";
import UploadComponent from "./UploadComponent";
import FilterButtons from "./FilterButtons";
import { connect } from "react-redux";
class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Value: "Please provide a description",
      loading: false,
      formData: new FormData(),
      fileSelectorHeader: false,
      selectItems: false,
      fileUploads: false,
      filter: false,
      undo: false,
      discriptionHeader: false,
      typeFilter: false,
      FileType: "",
      Name: "",
      sortType: "",
      uploadedFile: [],
      deletedFiles: [],
      trash: false,
      selectedFiles: [],
      selectedDeletedFile: [],
      filterButton: false,
      applyFilter: false,
      message: "",
      uploadPercentage: 0,
      fileObject: "",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false,
      resertAll: false,
      filtering: false
    };
    this.fileUpload = React.createRef();
    this.videoUpload = React.createRef();
    this.pictureUpload = React.createRef();
    this.showFileUpload = this.showFileUpload.bind(this);
    this.showVideoUpload = this.showVideoUpload.bind(this);
    this.showPictureUpload = this.showPictureUpload.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }
  componentWillMount() {
    this.setState({ loading: true });
    axios
      .get(BASE + "api/Downloads/all",{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        this.setState({ uploadedFile: response.data });
        axios
          .get(BASE + "api/Downloads/allDeletedDownloads",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({ deletedFiles: response.data });
            this.setState({ loading: false });
          })
      })
      .catch(err => {
        if (err.response.status !== null) {
          alert(err.response.data.message);
        } else {
          alert("Unable to log status please try again later");
        }
      });
  }
  checkItem(e) {
    const id = parseInt(e.target.value);
    if (e.target.checked && !this.state.selectedFiles.includes(id)) {
      const newSelected = this.state.selectedFiles.concat([id]);
      this.setState({
        selectedFiles: newSelected
      });
    }
    if (!e.target.checked && this.state.selectedFiles.includes(id)) {
      const newSelected = this.state.selectedFiles.filter(item => item !== id);
      this.setState({
        selectedFiles: newSelected
      });
    }
  }
  checkDeletedItem(e) {
    const id = parseInt(e.target.value);
    if (e.target.checked && !this.state.selectedDeletedFile.includes(id)) {
      const newSelected = this.state.selectedDeletedFile.concat([id]);
      this.setState({
        selectedDeletedFile: newSelected
      });
    }
    if (!e.target.checked && this.state.selectedDeletedFile.includes(id)) {
      const newSelected = this.state.selectedDeletedFile.filter(
        item => item !== id
      );
      this.setState({
        selectedDeletedFile: newSelected
      });
    }
  }
  handlePermanentDelete = async () => {
    await axios({
      url: BASE + "api/downloads/permanentDelete",
      data: this.state.selectedDeletedFile,
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
      .then(res => {
        this.setState({ loading: true });
        axios
          .get(BASE + "api/Downloads/all",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({
              uploadedFile: response.data
            });
            axios
              .get(BASE + "api/Downloads/allDeletedDownloads",{
                headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
              .then(response => {
                this.setState({
                  deletedFiles: response.data,
                  loading: false
                });
              })
              .catch(err => {
                if (err.response.status !== null) {
                  alert(err.response.data.message);
                } else {
                  alert("Unable to log status please try again later");
                }
              });
          })
          .catch(err => {
            if (err.response.status !== null) {
              alert(err.response.data.message);
            } else {
              alert("Unable to log status please try again later");
            }
          });
      })
      .catch(err => {
        if (err.response.status !== null) {
          alert(err.response.data.message);
        } else {
          alert("Unable to log status please try again later");
        }
      });
  };
  handleDelete = () => {
    axios
      .post(BASE + "api/Downloads/deleteFile", this.state.selectedFiles,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.setState({ loading: true });
        axios
          .get(BASE + "api/Downloads/all",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({
              uploadedFile: response.data,
              undo: true
            });
            axios
              .get(BASE + "api/Downloads/allDeletedDownloads",{
                headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
              .then(response => {
                this.setState({
                  deletedFiles: response.data,
                  loading: false
                });
              })
              .catch(err => {
                if (err.response.status !== null) {
                  alert(err.response.data.message);
                } else {
                  alert("Unable to log status please try again later");
                }
              });
          })
          .catch(err => {
            if (err.response.status !== null) {
              alert(err.response.data.message);
            } else {
              alert("Unable to log status please try again later");
            }
          });
      })
      .catch(err => {
        if (err.response.status !== null) {
          alert(err.response.data.message);
        } else {
          alert("Unable to log status please try again later");
        }
      });
  };
  handleUndo = async () => {
    await axios
      .post(BASE + "api/Downloads/undoDeleteFile", this.state.selectedFiles,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.setState({ loading: true });
        axios
          .get(BASE + "api/Downloads/all",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({
              uploadedFile: response.data,
              loading: false,
              fileSelectorHeader: false,
              undo: false,
              selectItems: false
            });
          })
          .catch(err => {
            if (err.response.status !== null) {
              alert(err.response.data.message);
            } else {
              alert("Unable to log status please try again later");
            }
          });
      })
      .catch();
  };
  handleRecycle = async () => {
    await axios
      .post(
        BASE + "api/Downloads/undoDeleteFile",
        this.state.selectedDeletedFile,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      )
      .then(res => {
        this.setState({ loading: true });
        axios
          .get(BASE + "api/Downloads/allDeletedDownloads",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({
              deletedFiles: response.data
            });
            axios
              .get(BASE + "api/Downloads/all",{
                headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
              .then(response => {
                this.setState({
                  uploadedFile: response.data,
                  loading: false
                });
              })
              .catch(err => {
                if (err.response.status !== null) {
                  alert(err.response.data.message);
                } else {
                  alert("Unable to log status please try again later");
                }
              });
          })
          .catch(err => {
            if (err.response.status !== null) {
              alert(err.response.data.message);
            } else {
              alert("Unable to log status please try again later");
            }
          });
      })
      .catch(err => {
        if (err.response.status !== null) {
          alert(err.response.data.message);
        } else {
          alert("Unable to log status please try again later");
        }
      });
  };
  showFileUpload() {
    this.fileUpload.current.click();
  }
  showVideoUpload() {
    this.videoUpload.current.click();
  }
  showPictureUpload() {
    this.pictureUpload.current.click();
  }
  resertSelect(e) {
    e.preventDefault();
    this.setState({
      discriptionHeader: false,
      fileObject: "",
      Name: ""
    });
  }
  headerChange() {
    if (this.state.fileSelectorHeader === true) {
      if (this.state.undo === true) {
        return (
          <GenericHeader
            showBackButton
            title="Uploads"
            backFunction={() => {
              this.setState({
                fileSelectorHeader: false,
                undo: false,
                selectItems: false,
                selectedDeletedFile: []
              });
            }}
            showUndo
            undo={this.handleUndo}
          />
        );
      }
      if (this.state.selectItems === true) {
        return (
          <GenericHeader
            title="Uploads"
            showBackButton
            showDeleteIcon
            backFunction={() => {
              this.setState({
                fileSelectorHeader: false,
                selectItems: false,
                undo: false,
                selectedDeletedFile: []
              });
            }}
            delete={this.handleDelete}
          />
        );
      }
      if (this.state.trash === true) {
        return (
          <GenericHeader
            title="Uploads"
            showBackButton
            showRecyleButton
            showDeleteForeverButton
            backFunction={() => {
              this.setState({
                fileSelectorHeader: false,
                trash: false,
                selectedDeletedFile: []
              });
            }}
            recycle={this.handleRecycle}
            deleteForever={this.handlePermanentDelete}
          />
        );
      }
      if (this.state.fileUploads === true) {
        return (
          <div>
            <GenericHeader
              title="Uploads"
              showBackButton
              showFolderIcon
              showVideoIcon
              showImageIcon
              uploadPicture={this.showPictureUpload}
              uploadVideo={this.showVideoUpload}
              uploadDocument={this.showFileUpload}
              backFunction={() => {
                this.setState({
                  fileSelectorHeader: false,
                  fileUploads: false,
                  selectedDeletedFile: []
                });
              }}
            />
            <div className="textarea-container">
              {this.state.discriptionHeader === true ? (
                <div className="uploadFileName">
                  <div
                    className="pl-2 pr-2 h-6 checkLabel"
                    onClick={t => this.resertSelect(t)}
                  >
                    {" "}
                    <i className="material-icons">close</i>{" "}
                  </div>
                  {this.state.Name !== "" ? (
                    <div className="h6">{this.state.Name}</div>
                  ) : null}
                </div>
              ) : null}
              <textarea
                id="Value"
                placeholder={this.state.Value}
                onInput={e => this.setState({ Value: e.target.value })}
                className="textarea col-lg-12"
                rows="3"
              />
              <button
                className="pb-2 btn-primary-outline"
                onClick={e => this.onUploadButtonClick(e)}
              >
                Apply
              </button>
            </div>
          </div>
        );
      }
      if (this.state.filter === true) {
        return (
          <div>
            <GenericHeader
              title="Uploads"
              showBackButton
              showFilterButton
              showUploadButton
              showSelectIcon
              backFunction={() => {
                this.setState({
                  fileSelectorHeader: false,
                  selectedDeletedFile: []
                });
              }}
              onFilterClick={() =>
                this.setState({ filter: true, fileSelectorHeader: true })
              }
              uploadFunction={() =>
                this.setState({
                  fileUploads: true,
                  fileSelectorHeader: true
                })
              }
              select={() =>
                this.setState({ selectItems: true, fileSelectorHeader: true })
              }
            />
            <FilterButtons
              generalButton={this.state.generalButton}
              narfoButton={this.state.narfoButton}
              huntingButton={this.state.huntingButton}
              dsButton={this.state.dsButton}
              memberButton={this.state.memberButton}
              targetButton={this.state.targetButton}
              generalDocumentFilter={this.generalDocumentFilter}
              narfoDocumentFilter={this.narfoDocumentFilter}
              huntingDocumentFilter={this.huntingDocumentFilter}
              dsDocumentFilter={this.dsDocumentFilter}
              memberDocumentFilter={this.memberDocumentFilter}
              targetDocumentFilter={this.targetDocumentFilter}
              resertAll={this.resertAll}
              applyFilter={this.applyFilter}
            />
          </div>
        );
      }
    } else {
      return (
        <GenericHeader
          title="Uploads"
          showTrashIcon
          showFilterButton
          showUploadButton
          showSelectIcon
          trashed={() => {
            this.setState({
              trash: true,
              fileSelectorHeader: true
            });
          }}
          onFilterClick={() =>
            this.setState({
              filter: true,
              fileSelectorHeader: true,
              fileUploads: false,
              selectItems: false
            })
          }
          uploadFunction={() =>
            this.setState({
              fileUploads: true,
              fileSelectorHeader: true
            })
          }
          select={() =>
            this.setState({ selectItems: true, fileSelectorHeader: true })
          }
        />
      );
    }
  }
  onUploadButtonClick = async e => {
    e.preventDefault();
    if (this.state.fileObject !== "") {
      this.state.formData.append("fileName", this.state.fileObject);
      this.state.formData.append("Description", this.state.Value);
      this.state.formData.append("DocumentType", this.props.documentType);
      this.state.formData.append("BaseURL", FRONT);
      await axios({
        url: BASE + "api/Downloads/uploadFiles/",
        data: this.state.formData,
        method: "post",
        headers: {
          "Content-type": "multipart/form-data",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        },
        onUploadProgress: progressEvent => {
          this.setState({
            uploadPercentage: parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          });
          setTimeout(() => this.setState({ uploadPercentage: 0 }), 5000);
        }
      }).then(res => {
        axios
          .get(BASE + "api/Downloads/all",{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(response => {
            this.setState({
              uploadedFile: response.data,
              Value: "Please provide a description",
              discriptionHeader: false,
              message: "file uploaded"
            });
            setTimeout(() => this.setState({ message: "" }), 4000);
          })
          .catch(err => {
            if (err.response.status !== null) {
              alert(err.response.data.message);
            } else {
              alert("Unable to log status please try again later");
              this.setState({
                formData: new FormData()
              })
            }
          });
      });
      this.setState({
        formData: new FormData()
      })
    }
  };
  handleChange(e) {
    e.preventDefault();
    let stringFile = e.target.value.split("\\").pop();
    const file = e.target.files[0];
    this.setState({
      Name: stringFile.substring(0, stringFile.indexOf(".")),
      discriptionHeader: e.bubbles,
      fileObject: file
    });
  }
  resetFilterType = () => {
    this.setState({
      filtering: false,
      huntingButton: false,
      filter: false,
      documentType: "",
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  };
  generalDocumentFilter = () => {
    this.setState({
      documentType: "General",
      huntingButton: false,
      generalButton: true,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  narfoDocumentFilter = () => {
    this.setState({
      documentType: "Narfo",
      huntingButton: false,
      generalButton: false,
      narfoButton: true,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  huntingDocumentFilter = () => {
    this.setState({
      documentType: "Hunting",
      huntingButton: true,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  dsDocumentFilter = () => {
    this.setState({
      documentType: "Dedicated Status",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: true,
      memberButton: false,
      targetButton: false
    });
  }
  memberDocumentFilter = () => {
    this.setState({
      documentType: "Member",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: true,
      targetButton: false
    });
  }
  targetDocumentFilter = () => {
    this.setState({
      documentType: "Target",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: true
    });
  }
  resertAll = () => {
    this.setState({ loading: true });
    axios
      .get(BASE + "api/Downloads/all",{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        this.setState({
          uploadedFile: response.data,
          loading: false,
          fileSelectorHeader: false,
          filter: false,
          applyFilter: false,
          typeFilter: false,
          filterButton: false
        });
      })
      .catch(err => {
        if (err.response.status !== null) {
          alert(err.response.data.message);
        } else {
          alert("Unable to log status please try again later");
        }
      });
  };
  resetFilterType = () => {
    this.setState({
      typeFilter: false,
      filterButton: false
    });
  };
  applyFilter() {
    this.setState({ loading: true });
    this.setState({
      filtering: true,
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    })
    axios({
      url: BASE + "api/downloads/filterByDocumentType/" + this.state.documentType,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      this.setState({
        uploadedFile: res.data,
        loading: false,
        filter: false,
        fileSelectorHeader: false
      })
    })
    this.resetFilterType();
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading files. Please wait ...</p>
          </div>
        </div>
      );
    }
    const comInput = (accept, ref) => (
      <input
        type="file"
        accept={accept}
        style={{ display: "none" }}
        onChange={e => this.handleChange(e)}
        ref={ref}
      />
    );
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <UploadComponent
          isUploadSelected={this.state.fileUploads}
          headerChange={this.headerChange()}
          comInput={comInput}
          fileUpload={this.fileUpload}
          message={this.state.message}
          fileUploads={this.state.fileUploads}
          uploadPercentage={this.state.uploadPercentage}
          videoUpload={this.videoUpload}
          selectItems={this.state.selectItems}
          pictureUpload={this.pictureUpload}
          trash={this.state.trash}
          deletedFiles={this.state.deletedFiles}
          checkDeletedItem={e => this.checkDeletedItem(e)}
          uploadedFile={this.state.uploadedFile}
          checkItem={e => this.checkItem(e)}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    documentType: state.fileUploaderReducer.documentType
  };
};
export default connect(
  mapStateToProps,
  null
)(Uploads);
