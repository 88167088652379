import React, { Component } from "react";
import ArrowBack from "@material-ui/icons/Check";
import { Button} from "@material-ui/core";

class EndorsementApplicationDone extends Component {
  render() {
    return (
      <div className="container" align="center">
        <h1>Thank you for applying</h1>
        <ArrowBack
          onClick={() => this.props.history.push("/MainEndorsement")}
          style={{ fontSize: 100, color: "#6BC20A" }}
        />
        <Button />
        <div />
      </div>
    );
  }
}

export default EndorsementApplicationDone;
