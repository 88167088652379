import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CustomModal from '../../utils/CustomModal';
import * as DSApplicationActions from "./DedicatedStatusApplicationActions";
import Buttons from './SubComponents/Buttons';
import Declaration from './SubComponents/Declaration';
import Details from './SubComponents/Details';
import Motivation from './SubComponents/Motivation';
import Stepper from "./SubComponents/Stepper";

class DedicatedStatusApplication extends React.PureComponent {
  constructor(props) {
    super(props);
    this.displayForm = this.displayForm.bind(this);
  }
  displayForm(step) {
    const { changeStep, toggleDSType, setValidationError, setDSVariables } = this.props.DSApplicationActions;
    const { currentStep, dsType, error } = this.props.DSApplicationState;
    switch (step) {
      case 1:
        return <Details
          changeStep={changeStep}
          currentStep={currentStep}
          dsType={dsType}
          toggleDS={toggleDSType}
          setValidationError={setValidationError}
          error={error}
          setDSVariables={setDSVariables}
          DSApplicationState={this.props.DSApplicationState}
        />
      case 2:
        return <Motivation
          changeStep={changeStep}
          currentStep={currentStep}
          setValidationError={setValidationError}
          error={error}
          setDSVariables={setDSVariables}
          DSApplicationState={this.props.DSApplicationState}
          dsType={dsType}
        />
      case 3:
        return <Declaration
          changeStep={changeStep}
          currentStep={currentStep}
          dsType={dsType}
          setValidationError={setValidationError}
          error={error}
          setDSVariables={setDSVariables}
        />
      default:
        return <div>Error</div>
    }
  }
  render() {
    return (
      <div className="mb-5 pb-5">
        <Stepper progress={this.props.DSApplicationState.currentStep} />
        {this.displayForm(this.props.DSApplicationState.currentStep)}

        <CustomModal
          error={this.props.DSApplicationState.applicationStatus.error}
          show={this.props.DSApplicationState.showModal}
          onHide={() => this.props.DSApplicationActions.setDSVariables("showModal", false)}
          title="Submitting Application"
          message="Your application is being submitted. Might take some time to upload documents. Please wait"
        />
        <div className="row">
          <p className="text-danger">{this.props.DSApplicationState.detailsError}</p>
          <p className="text-danger">{this.props.DSApplicationState.motivationError}</p>
        </div>
        <Buttons
          changeStep={this.props.DSApplicationActions.changeStep}
          currentStep={this.props.DSApplicationState.currentStep}
          submitApplication={this.props.DSApplicationActions.submitDSApplication}
          redirect={this.props.DSApplicationActions.redirectToDSList}
          setDSVariables={this.props.DSApplicationActions.setDSVariables}
          DSApplicationState={this.props.DSApplicationState}
          memNo={this.props.memNo}
          toggleDS={this.props.DSApplicationActions.toggleDSType}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    DSApplicationState: state.DSApplicationState,
    memNo: state.loginReducer.userProfileDetails.memberNumber
  };
};

const mapActionsToProps = dispatch => {
  return {
    DSApplicationActions: bindActionCreators(DSApplicationActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(DedicatedStatusApplication);