import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import * as StoreLocatorActions from "../storeLocator/storeLocatorActions";
class ShootingRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shootingRangesList: []
    };
  }
  handleSelectChange = e => {
    let city = e.target.value;
    let url = this.props.storeLocatorState.url.replace(
      this.props.storeLocatorState.city,
      city
    );
    this.props.StoreLocatorActions.updateSearchString(url, city);
  };
  componentDidMount() {
    Axios.get(BASE + "api/ShootingRanges",
    {headers: {
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
    }}).then(res => {
      this.setState({
        shootingRangesList: res.data
      });
    });
  }
  render() {
    return (
      <div className="container pr-5 pl-5">
        <div className="row">
          <GenericHeader title="Shooting Range" />
        </div>
        <div>
          <p align="center" className="pt-3 mb-0">
            Find shooting ranges near you to practice or do an activity.
          </p>
        </div>
        <p align="center" className="p-3 mb-0">
          Choose Shooting Range
        </p>
        <div align="center">
          <div className="col-md-6">
            <select
              className="form-control input-sm m-0"
              onChange={e => this.handleSelectChange(e)}
            >
              <option value="Select Store">Select Shooting Range</option>
              {this.state.shootingRangesList.map((branch, index) => (
                <option id="numberOptions" key={index} value={branch.adress}>
                  {branch.shootingRange}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row mt-3" styles={{ height: "100%" }}>
          <iframe
            title="Store Locator"
            width="100%"
            height="700px"
            frameBorder="0"
            styles={{ border: 0 }}
            src={this.props.storeLocatorState.url}
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    storeLocatorState: state.storeLocatorState
  };
};
const mapActionsToProps = dispatch => ({
  StoreLocatorActions: bindActionCreators(StoreLocatorActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapActionsToProps
)(ShootingRange);
