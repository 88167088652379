import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { BASE, GET_ACCOUNT_LIST, GET_LOGGED_IN_MEMBER, UPLOAD_PROFILE_PICTURE } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import narfo_default from "../adminPortal/images/dProfile.jpg";
import editicon from "../adminPortal/images/edit-icon.png";
import "../adminPortal/styles/dstyle.css";
import { membershipExpired } from "../main/MembersArea";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./imageStyler.css";
toast.configure();
class MembersAreaHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logginStatus: true,
      time: {},
      start: false,
      profilePictureExists: "Exists",
      file: "srclogo.svg",
      imagePreviewUrl: narfo_default,
      imageStateTrack: "0",
      image: narfo_default,
      member: props.memberDetails,
      membershipExpired: false,
      dedicatedHUNTER: "",
      dedicatedSHOOTER: "",
      hunterAndShooter: "",
      seconds: 3600
    };
    this.timer = 0;
  }
  componentDidMount() {
    axios({
      url: `${BASE}api/Images/getAll`,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      if(res.data.state){
        this.props.onBase4ImagesGet({
          narfo: res.data.narfo,
          nnfo: res.data.nnfo,
          pdfSign: res.data.pdfSign,
          pdflogo: res.data.pdflogo,
          signature2: res.data.signature2,
          wildman: res.data.wildman
        });
        console.log(res.data.message);
      } else {
        console.log(res.data.message);
      }
    })
    if (this.props.adminView) {
      axios
        .get(BASE + GET_LOGGED_IN_MEMBER + this.props.memberDetails.memNo,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => {
          this.props.onmembershipExpiredStatusChange(res.data.dedicatedStatus);
        });
      axios
        .get(BASE + GET_LOGGED_IN_MEMBER + this.props.MemberV.memNo,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(res => {
          this.setState({
            dedicatedHUNTER: res.data.dedicatedStatus.dedicatedHunter,
            dedicatedSHOOTER: res.data.dedicatedStatus.dedicatedShooter,
            hunterAndShooter: res.data.dedicatedStatus.hunterAndshooter
          });
        });
    }
    this.props.onCurrAccountViewTabChange(0);
    const getUserAccountsUrl = this.props.memberDetails.memberNumber;
    axios.get(BASE + GET_ACCOUNT_LIST + getUserAccountsUrl,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      var accounts = res.data;
      this.props.onUserAccountChange(accounts);

      if (accounts && accounts.length) {
        this.props.onRecentAccountEntryChange(accounts[accounts.length - 1]);
      } else {
        this.props.onRecentAccountEntryChange({});
      }
    });
    const memNumber = this.props.adminView
      ? this.props.MemberV.memNo
      : this.state.member.memberNumber;
    axios
      .get(BASE + "api/MemberProfilePictures/getMemId/" + memNumber,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        const res = response.data;
        this.setState({
          image:
            res[0] === null ? narfo_default : res[0].profilePicture.toString()
        });
        res[0]
          ? this.props.onMemberProfilePictureGet(
              res[0].profilePicture,
              res[0].memPPId
            )
          : this.setState({
              profilePictureExists: "NotExists"
            });
        this.props.memberProfilePictureExistance(
          this.state.profilePictureExists
        );
      });
    this.setState({
      membershipExpired: membershipExpired(this.props.memberDetails.expiryDate)
    });
  }
  componentWillMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({
      time: timeLeftVar
    });
    this.startTimer();
  }
  startTimer = () => {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    });
    if (seconds === 1800)
      toast.success('Auto Logout in 30 minutes!')
    if (seconds === 900)
      toast.info('Auto Logout in 15 minutes!')
    if (seconds === 600)
      toast.warning('Incomplete form progress will be lost!')
    if (seconds === 10)
      toast.error('Auto Logout in 10 seconds!')
    if (sessionStorage.getItem("event") === "click" || sessionStorage.getItem("event") === "scroll" || sessionStorage.getItem("event") === "keypress") {
      this.setState({
        seconds: 3600
      })
      sessionStorage.setItem("event", "")
    }
    if (seconds === 0) { 
      this.props.onUserDetailsChange("", "email");
      this.props.onUserDetailsChange("", "username");
      this.props.onUserDetailsChange("", "cellNo");
      this.props.onUserDetailsChange("", "physicalAddress");
      this.props.onUserDetailsChange("", "memberNumber");
      this.props.onUserDetailsChange("", "expiryDate");
      sessionStorage.removeItem("member");
      sessionStorage.removeItem("activeStatus");
      sessionStorage.removeItem("tokenKey");
      localStorage.removeItem("tokenKey");
      this.props.onUserDetailsChange(false, "isLoggedIn");
      this.props.onUserDetailsChange(false, "adminLevel");
      this.props.onUserDetailsChange(false, "partnerLevel");
      this.props.onUserDetailsChange(-1, "partnerId");
      this.props.onUserDetailsChange(false, "salesRepLevel");
      window.location = "/login";
      clearInterval(this.timer);
    }
  }
  secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    };
    return obj;
  }
  _handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = t => {
      const memNo = this.state.member.memberNumber;
      axios
        .post(BASE + UPLOAD_PROFILE_PICTURE,{
          ProfilePicture: t.target.result,
          MemNo: memNo
        },{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(function(response) {})
        .catch(function(error) {});
      this.setState({
        file: file,
        imagePreviewUrl: t.target.result,
        image: t.target.result,
        imageStateTrack: "1"
      });
    };
    reader.readAsDataURL(file);
  }
  render() {
    if (!this.props.memberDetails) {
      return <div>Loading...</div>;
    }
    return (
      <div
        className="container"
        style={{
          maxWidth: "2180px",
          marginTop: "0px"
        }}
      >
        <div className="row pl-3 pt-3 pb-3">
          <div className="col-12">
            <div className="row">
              <div className="col-6 col-md-2 pr-0 ml-2" align="left">
                <div className="image-upload">
                  <label htmlFor="file-input">
                    <div style={{ cursor: "pointer" }}>
                      <img
                        src={this.state.image}
                        alt={this.state.imagePreviewUrl}
                        className="img"
                      />
                      <img src={editicon} alt="PIC" className="imageIcon" />
                    </div>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    onChange={e => this._handleImageChange(e)}
                  />
                </div>
              </div>
              <div className="col-4 col-md-4 pl-4 pt-2">
                <div className="container">
                  <div className="row">
                    {this.props.adminView ? (
                      <div>
                        <div className="row">
                          <div className="col-12 h4 m-0 font-weight-bold pr-0 pl-0">
                            <div> {this.props.MemberV.username}</div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className=" pl-0 font-weight-bold">
                                Dedicated Hunter:
                              </div>
                              <div className=" pr-0 pl-2 font-weight-bold text-success">
                                {this.state.dedicatedHUNTER}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="pl-0 font-weight-bold">
                                Sport Shooter:
                              </div>
                              <div className="pr-0 pl-2 font-weight-bold text-success">
                                {this.state.dedicatedSHOOTER}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="pl-0 font-weight-bold">
                                Hunter And Sport Shooter:
                              </div>
                              <div className="pr-0 pl-2 font-weight-bold text-success">
                                {this.state.hunterAndShooter}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {this.state.membershipExpired === false ? (
                          <div className="row">
                            <div className="col-12 h4 m-0 font-weight-bold pr-0 pl-0">
                              <div> {this.props.memberDetails.username}</div>
                            </div>
                            {this.props.activeStatus.dedicatedHunter
                              .toString()
                              .substring(0, 1) !== "0" ? (
                              <div className="col-12">
                                <div className="row">
                                  <div className=" pl-0 font-weight-bold">
                                    Dedicated Hunter:
                                  </div>
                                  <div className=" pr-0 pl-2 font-weight-bold text-success">
                                    {this.props.activeStatus.dedicatedHunter}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.props.activeStatus.dedicatedShooter
                              .toString()
                              .substring(0, 1) !== "0" ? (
                              <div className="col-12">
                                <div className="row">
                                  <div className="pl-0 font-weight-bold">
                                    Sport Shooter:
                                  </div>
                                  <div className="pr-0 pl-2 font-weight-bold text-success">
                                    {this.props.activeStatus.dedicatedShooter}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.props.activeStatus.hunterAndshooter
                              .toString()
                              .substring(0, 1) !== "0" ? (
                              <div className="col-12">
                                <div className="row">
                                  <div className="pl-0 font-weight-bold">
                                    Hunter And Sport Shooter:
                                  </div>
                                  <div className="pr-0 pl-2 font-weight-bold text-success">
                                    {this.props.activeStatus.hunterAndshooter}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="col-12 h4 m-0 font-weight-bold pr-0 pl-0">
                            <div> {this.props.memberDetails.username}</div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeStatus: state.MemberViewReducer.activeStatus,
    memberProfilePicture: state.memberProfilePictureReducer,
    pic: state.memberProfilePictureReducer.isExist,
    memberDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onmembershipExpiredStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),
    onBase4ImagesGet: imagesObject =>
      dispatch({
        type: actionTypes.GET_BASE64_IMAGES,
        base64Images: imagesObject
      }),
    onCurrAccountViewTabChange: newCurrAccountViewTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB,
        currAccountViewTab: newCurrAccountViewTab
      }),
    onRecentAccountEntryChange: newRecentAccountEntry =>
      dispatch({
        type: actionTypes.UPDATE_RECENT_ACCOUNT_ENTRY,
        recentAccountEntry: newRecentAccountEntry
      }),
    onUserAccountChange: newUserAccountArray =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT_LIST,
        payload: newUserAccountArray
      }),
    onMemberProfilePictureGet: (profilePicture, memberID) =>
      dispatch({
        type: actionTypes.GET_MEMBER_PROFILE_PICTURE,
        profilePicture: profilePicture,
        memberID: memberID
      }),
    memberProfilePictureExistance: isExist =>
      dispatch({
        type: actionTypes.MEMBER_PP_RECORD_EXISTS,
        isExist: isExist
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onMemberProfilePictureUpdate: updatedMPicture => {
      dispatch({
        type: actionTypes.UPDATE_MEMBER_PROFILE_PICTURE,
        profilePicture: updatedMPicture
      });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersAreaHeader);
