import React, { Component } from "react";
import * as actionTypes from "../../../store/actions/actionTypes";
import { connect } from "react-redux";

class FileUploader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fileName: "Choose file",
      files: [],
      image: null
    }
  }


  componentWillUnmount() {
    const _files = this.state.files;
    this.props.onFilesChange(_files, this.props.fileGroup ? this.props.fileGroup : "default");
  }

  isFileExist = (file) => {
    let files = this.state.files;
    return files.find(_file => _file.name === file.name);
  }

  processImage = (imageFile) => {
    try {
      this.setState({
        image: URL.createObjectURL(imageFile)
      });
    }
    catch (error) {
      this.setState({
        image: imageFile
      });
    }
  }

  showImage = () => {
    if (this.state.image) {
      return (
        <div className="p-2 text-center">
          <img
            alt="Sorry"
            width="200px"
            height="200px"
            src={this.state.image}
          />
        </div>
      );
    }
    return null
  }

  render() {
    return (
      <div>
        <div class="input-group mb-2">
          <div class="input-group-prepend"
            onClick={() => {
              this.props.fileParse(null);
              this.processImage(null);
            }}
          >
            <span
              class="input-group-text"
              id="inputGroupFileAddon01"
            >
              Clear All
            </span>
          </div>
          <div class="custom-file">
            <input
              type="file"
              accept='image/*'
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={(event) => {
                const _file = event.target.files[0];
                this.setState({
                  fileName: event.target.value.split("\\").pop(),
                });
                this.props.fileParse(_file);
                this.processImage(_file);
              }}
            />
            <label class="custom-file-label" for="inputGroupFile01">{this.state.fileName}</label>
          </div>
        </div>
        {this.showImage()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFilesChange: (files, vname) =>
      dispatch({
        type: actionTypes.UPDATE_FILES_TO_UPLOAD,
        payload: files,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileUploader);
