import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyDedicatedStatusDetails from '../../components/MyDedicatedStatus/MyDedicatedStatusDetails';
import Container from '../../hoc/Container';
import DedicatedStatusService from '../../services/dedicatedstatus.service';
import DedicatedStatusActions from '../../store/DedicatedStatus/actions';

class MyDedicatedStatus extends Component {
  constructor(props) {
    super(props);
    this.dedicatedStatusService = DedicatedStatusService(this.props.token);
    this.dedicatedStatusAction = this.props.dedicatedStatusActions(this.dedicatedStatusService);
	this.state = {
		_dedicatedStatusList: [],
		sportShooterCount: 0,
      	hunterCount: 0,
	}
 
  }
  componentDidMount() {
    this.setState({loading: true});
    const user = { memNo: this.props.memNo };
    this.dedicatedStatusAction.fetchDedicatedStatusList(user)
    .then((response)=> {
      this.setState({...this.state,_dedicatedStatusList: response.payload,loading: false})
    })
    .catch((error)=>{
      this.setState({_dedicatedStatusList: []})
      this.setState({loading: false});
    })
  }

  static getDerivedStateFromProps(nextProps) {
    let __dedicatedStatusList = [];
    let __sportShooterCount = 0;
    let __hunterCount = 0;
    __dedicatedStatusList = nextProps.dedicatedStatusList;
    if(__dedicatedStatusList.length !== 0) {
      __dedicatedStatusList.map((item) => {
        if(item.dstype === 'Dedicated Sports Person' || item.dstype === 'DSS') {
          __sportShooterCount = __sportShooterCount + 1;
        }
        if(item.dstype === 'Dedicated Hunter' || item.dstype === 'DH') {
          __hunterCount = __hunterCount + 1;
        }
        // item.applicationDate = new Date(item.applicationDate.split('T')[0]).toLocaleDateString();

        if(localStorage.getItem('isMobile') === 'true') {
          if(item.dstype === 'Dedicated Sports Person') {
            item.dstype = 'DSS';
          }
          if(item.dstype === 'Dedicated Hunter') {
            item.dstype = 'DH';
          }
        }
      });
    } 

    return { 
      _dedicatedStatusList: __dedicatedStatusList,
      sportShooterCount: __sportShooterCount,
      hunterCount: __hunterCount
    };
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Dedicated status">
       {!this.state.loading && <MyDedicatedStatusDetails 
          addNewApplicationCallBack={this.dedicatedStatusService.addNewApplication}
          dedicatedStatusService = {this.dedicatedStatusService}
          dedicatedStatusList={this.state._dedicatedStatusList}
          memNo={this.props.memNo}
          isMobile={isMobile}
          history = {this.props.history}
          sportShooterCount={this.state.sportShooterCount}
          hunterCount={this.state.hunterCount}
        />  }
      </Container>
    );
  }

}
const initDedicatedStatusActions = dispatch => dedicatedStatusService => {
  const actions = DedicatedStatusActions(dedicatedStatusService);
  return {
    fetchDedicatedStatusList: data => dispatch(actions.fetchDedicatedStatusList(data))
  };
};

const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  dedicatedStatusList: state.nDedicatedStatusReducer.dedicatedStatusList
});

const mapDispatchToProps = dispatch => ({
  dedicatedStatusActions: initDedicatedStatusActions(dispatch)
});

export default connect(mapStateToProps,mapDispatchToProps)(MyDedicatedStatus);