export const ASCENDING_ABREV = 'asc';
export const DESCENDING_ABREV = 'desc';

export const MEMBER_STATUS = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected',
  RENEWING: 'Renewing',
};

export const COMMISSION_TYPES = {
  SALES_REP_COMMISSION: 'Sales Rep Commission',
  OWNER_COMMISSION: 'Owner Commission',
};

export const ASSOCIATIONS = {
  NARFO: 'NARFO',
  OTHER: 'OTHER',
};

export const ADD_TO_GALLERY = {
  YES: 'Yes',
  NO: 'No',
};

export const editableTextInputConfig = {
  editable: true,
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      variant: 'outlined',
    },
    validate: {
      required: true,
      minLength: 1
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableTextUnvalidatedInputConfig = {
  editable: true,
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      variant: 'outlined',
    },
    validate: {
      required: false
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableTextInputConfigModelField = {
  editable: true,
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'text',
      variant: 'outlined',
    },
    validate: {
      required: false,
      minLength: 0
    },
    valid: true,
    errors: [],
    touched: false
  },
};


export const editableSelectConfig = {
  editable: true,
  config: {
    elementType: 'select',
    elementConfig: {
      options: []
    },
    validate: {
      required: true
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableRadioInputConfig = {
  editable: false,
  config: {
    elementType: 'radioGroup',
    elementConfig: {
      type: 'text',
      direction: 'row',
      radios: []
    },
    validate: {
      required: true,
    },
    valid: false,
    touched: false,
  }
};

export const editableCheckBoxInputConfig = {
  editable: true,
  config: {
    elementType: 'checkBox',
    elementConfig: {
      type: 'checkBox',
      direction: 'row',
      accept: []
    },
    validate: {
      required: true,
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableCalendarConfig = {
  editable: true,
  config: {
    elementType: 'calendar',
    validate: {
      required: true,
      maxDate: new Date('2050/01/01'),
      minDate: new Date('2000/01/01'),
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableNumberInputConfig = {
  editable: true,
  config: {
    elementType: 'input',
    elementConfig: {
      type: 'number',
    },
    validate: {
      required: true,
      minLength: 1
    },
    valid: true,
    errors: [],
    touched: false
  },
};

export const editableTextBoxInputConfig = {
  editable: true,
  config: {
    elementType: 'textArea',
    elementConfig: {
      multiline: true,
      rows: 4,
      variant: 'outlined'
    },
    validate: {
      required: true,
      minLength: 1
    },
    valid: false,
    errors: [],
    touched: false
  },
};

export const firearmType = {
  CUSTOM: 'CUSTOM',
  FIREARM: 'FIREARM'
};

export const dsApplicationsResponses = {
  YES: 'Yes',
  NO: 'No'
};

export const saProvinces = [
  {value: 'Eastern Cape', label: 'Eastern Cape'},
  {value: 'Free State', label: 'Free State'},
  {value: 'Gauteng', label: 'Gauteng'},
  {value: 'KwaZulu-Natal', label: 'KwaZulu-Natal'},
  {value: 'Limpopo', label: 'Limpopo'},
  {value: 'Mpumalanga', label: 'Mpumalanga'},
  {value: 'Northern Cape', label: 'Northern Cape'},
  {value: 'North West', label: 'North West'},
  {value: 'Western Cape', label: 'Western Cape'}
];

export const targetDownsLoadLevels = {
  FIREARM_TYPES: 'Firearm Types',
  FIREARM_LOOK_UP: 'Firearm Types Lookup',
  ACTIVITY_LOOK_UP: 'Activity Event Lookup',
  EVENT_RULES: 'Event Rules'
};