import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import DropDownSection from "./DropDownSection";
import DropDownActivityType from "./DropDownActivityType";
import DropDownFireArm from "./DropDownFireArm";
import DropDownOptics from "./DropDownOptics";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import DropDownActivityEvent from "./DropDownActivityEvent";
import DropDownStore from "./DropDownStore";
import DropDownActivityDisc from "./DropDownActivityDisc";
import DropDownShootingRange from "./DropDownShootingRange";

const styles = theme => ({
  root: {
    height: "100%"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    textAlign: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    align: "center"
  },

  footer: {
    display: "comtent",
    flexDirection: "row"
  },

  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    fullWidth: true,
    flex: "1",
    margin: "5",
    instantValidate: true
  },

  grow: {
    flex: "6"
  },

  grow1: {
    flex: "4"
  },

  select: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    flex: "2",
    margin: "5"
  }
});

class EditDropDown extends Component {
  handleChange = name => event => {
    this.props.onSectionChange(event.target.value, [name]);
  };

  ActionSelect() {
    if (this.props.Selections.show === "Section") {
      return <DropDownSection />;
    } else if (this.props.Selections.show === "Activity Type") {
      return <DropDownActivityType />;
    } else if (this.props.Selections.show === "Fire-Arm Type") {
      return <DropDownFireArm />;
    } else if (this.props.Selections.show === "Optics") {
      return <DropDownOptics />;
    } else if (this.props.Selections.show === "Activity Event") {
      return <DropDownActivityEvent />;
    } else if (this.props.Selections.show === "Store") {
      return <DropDownStore />;
    } else if (this.props.Selections.show === "ShootingRange") {
      return <DropDownShootingRange />;
    } else if (this.props.Selections.show === "Activity Discipline") {
      return <DropDownActivityDisc />;
    }
  }

  render() {
    const { classes } = this.props;
    const actiontype = [
      "Select a Drop Down List",
      "Activity Discipline",
      "Activity Type",
      "Fire-Arm Type",
      "Optics",
      "Section",
      "Store",
      "ShootingRange"
    ];
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <GenericHeader title="Drop Down Lists" />

        <form className={classes.container} noValidate autoComplete="off">
          <Select
            className={classes.select}
            native
            value="Add Licence Type"
            onChange={this.handleChange("show")}
            input={
              <OutlinedInput
                labelWidth={0}
                name="Add Licence Type"
                id="Section"
              />
            }
          >
            {actiontype.map((activityType, index) => (
              <option key={index} value={activityType}>
                {activityType}
              </option>
            ))}
          </Select>
        </form>
        {this.ActionSelect()}
      </div>
    );
  }
}

EditDropDown.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    memDetails: state.registrationReducer.signupDetails,
    Selections: state.DropDownReducer.Options
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSectionChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_OPTIONS,
        varValue: value,
        varName: vname
      }),

    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_SIGNUP_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditDropDown));
