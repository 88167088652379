import { withStyles } from "@material-ui/core/styles";
import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { BASE, DELETE_LICENCE } from "../../../API/APIendpoint";
import Axios from "axios";


const styles = {
  card: {
    minWidth: 500
  },
  overall: {
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  },
  root: {
    flex: 2,
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8",
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20",
    paddingRight: 20,
    color: "black"
  },
  content: {
    width: "20%",
    display: "inline-block",
    fontSize: 14,
    color: "black"
  }
};

class LicenseViewCustom extends Component {
  onDeleteLicence = () => {
    Axios({
      url: `${BASE}${DELETE_LICENCE}${this.props.licenceDetails.SerialNumber}`,
      method: "post",
      headers: { 
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`}
      }).then(() =>
      this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    );
  }
  render() {
    const licenseViewInfo = (name, value) => (
      <tr>
        <td>{name}</td>
        <td className="font-weight-bold" style={{ width: "45%" }}>{`: ${value}`}</td>
      </tr>
    );
    const DisplayLicenseDetails = (
      <div>
        <table style={{ width: "100%" }}>
          <tbody>
            {licenseViewInfo("Name", this.props.licenceDetails.LicenseName)}
            {licenseViewInfo("Model", this.props.licenceDetails.Model)}
            {licenseViewInfo("License No.", this.props.licenceDetails.LicenseNo)}
            {this.props.licenceDetails.Code !== null ?
              licenseViewInfo("Code", this.props.licenceDetails.Code) :
              null}
            {licenseViewInfo("Issue Date", this.props.licenceDetails.IssueDate.substring(0, 10))}
            {licenseViewInfo("Expirey Date", this.props.licenceDetails.ExpiryDate.substring(0, 10))}
            {licenseViewInfo("Issued", this.props.licenceDetails.Issued)}
          </tbody>
        </table>
      </div>
    );
    return (
      <div className="container">
        <div>
          <GenericHeader
            title="Custom License Reminders"
            showBackButton
            backFunction={() => {
              this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
            }}
            showEditButton
            editFunction={() => {
              this.props.onCurrentLicenseTabChange("3", "currentLicenseTab");
            }}
            showDeleteForeverButton
            deleteForever={() => {
              this.onDeleteLicence();
            }}
          />
        </div>
        <div className="container">
          {DisplayLicenseDetails}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.currentActivityTab,
    licenceDetails: state.licensesReducer.licence
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onLicenceChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LicenseViewCustom));


