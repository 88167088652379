import API_URL from './apiUrl';
import axios from 'axios';

const getMemberActivities = (headers) => (id) => axios.
  get(`${API_URL}/Activities/memNo/${id}`, {headers});

const submitActivity = (headers) => (data) => axios.
  post(`${API_URL}/Activities`, data, {headers: 
    {
      ...headers, 
      'Content-type': 'multipart/form-data'
    }
  });

const getActivityByID = (headers) => (id) => axios.
  get(`${API_URL}/Activities/${id}`, {headers});

const getGalleryActivity = (headers) => (id) => axios.
  get(`${API_URL}/Gallery/HasGallleryItem?activityId=${id}`, {headers});

const getGalleryData = (headers) => axios.
  get(`${API_URL}/Gallery/GetGalleryData`, {headers});

const getActivityMatrix = (headers) => (id) => axios.
  get(`${API_URL}/Activities/activityMatrix/${id}`, {headers});

const getActivityRanking = (headers) => (id) => axios.
  get(`${API_URL}/Activities/GetActivityRanking/${id}`, {headers});

const getNarfoRankings = (headers) => (discipline,year) => axios.
  get(`${API_URL}/Activities/GetNarfoRankings?discipline=${discipline}&year=${year}`, {headers});

const getOtherDisciplineRankings = (headers) => (discipline,year) => axios.
  get(`${API_URL}/Activities/GetNarfoRankings?discipline=${discipline}&year=${year}`, {headers});

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getMemberActivities: getMemberActivities(headers),
    submitActivity: submitActivity(headers),
    getActivityByID: getActivityByID(headers),
    getGalleryActivity: getGalleryActivity(headers),
    getGalleryData: getGalleryData(headers),
    getActivityMatrix: getActivityMatrix(headers),
    getActivityRanking: getActivityRanking(headers),
    getNarfoRankings: getNarfoRankings(headers),
    getOtherDisciplineRankings: getOtherDisciplineRankings(headers)
  };
}
