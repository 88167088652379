import * as actionTypes from "../actions//actionTypes";

const initialState = {
  rankByYear:{},
  rankByActivity:{},
  rankByAssociation:{},
  rankByFirearmType:{},
  nationalRanking: {
    Rank: "",
    Score: "0",
    SportShooter: {},
    Year: "",
    Association: "",
    FirearmType: "",
    ActivityType: "",
    currentNationalRankingTab: "0",
    MemNo: ""
  },

  NationalRankingList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_RANK_BY_YEAR:
      return {
        ...state,
        rankByYear: action.rankByYear
      };
      case actionTypes.UPDATE_RANK_BY_ACTIVITY:
      return {
        ...state,
        rankByActivity: action.rankByActivity
      };
      case actionTypes.UPDATE_RANK_BY_FIREARMTYPE:
      return {
        ...state,
        rankByFirearmType: action.rankByFirearmType
      };
      case actionTypes.UPDATE_RANK_BY_ASSOCIATION:
      return {
        ...state,
        rankByAssociation: action.rankByAssociation
      };
    case actionTypes.UPDATE_NATIONAL_RANK:
      const newNationalRanking = {
        ...state.licence,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        nationalRanking: newNationalRanking
      };
    case actionTypes.UPDATE_NATIONAL_RANK_LIST:
      return {
        ...state,
        NationalRankingList: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
