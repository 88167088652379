import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewEmail from '../../components/MemberEmails/ViewEmail';
import Container from '../../hoc/Container';
import EmailService from '../../services/email.service';
import { format } from 'date-fns';
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

class MemberEmails extends Component {
  constructor(props) {
    super(props);
    this.EmailService = EmailService(this.props.token);
  	this.state = {
        emailList: []
    }
  }

  componentDidMount() {
    this.EmailService.getEmailHistory().then(resp =>{
    //   this.setState({emailList : resp.data.reverse(), loading:false});

      let emails =[];
      
      resp.data.forEach(email => {
          email.body = email.body.replace(/&nbsp;/g,'').replace(/<\/p>/gm,'</p><h1></h1>'); 
          emails.push(email);
        });
        
      this.setState({ loading: false,emailList: emails.reverse()});

    })
    .catch(ex =>{
      console.log(ex);
    })
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Emails">
       {!this.state.loading && <ViewEmail 
          emailList={this.state.emailList.map(item => {
            let date = new Date(item.dateSent);
            item.dateSent = date.getDay() +" " + month[date.getMonth()]  +" " + date.getFullYear()
            return item;
          })}
          memNo={this.props.memNo}
          isMobile={isMobile}
          history = {this.props.history}
        />  }
      </Container>
    );
  }
}

export default connect(null,null)(MemberEmails);