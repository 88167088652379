import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../../API/APIendpoint";
import { formatDate, status } from "../../loggeddInUser/activitiesPage/ActivityDetails";
import { isMobile } from "react-device-detect";
class ActivityDetailsApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Activity: undefined,
          status: "",
          activityLink: "",
          rejectionReason: "",
          astatus: "",
          docName: "",
          type: "",
          Activity: {}
        };
      }
      componentDidMount() {
        Axios.get(BASE + "api/Activities/" + this.props.activityID,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(
          res => {
            this.setState({
              Activity: res.data,
              astatus: res.data.status,
              rejectionReason: res.data.rejectReason,
              activityLink: res.data.link
            });
          }
        );
      }
      render() {
        const Activity = this.state.Activity
        const activityViewInfo = (name, value) => (
          <tr>
            <td>{name}</td>
            {value ? 
              <td className="font-weight-bold" style={{width: "45%"}}>{`: ${value}`}</td> :
              notfound
            }
            
          </tr>
        );
        const activityViewInfo2 = (name, value) => (
          <tr>
            <td>{name}</td>
            {value === "NULL" || !value ? 
              <td className="font-weight-bold text-danger" style={{width: "45%"}}>Null</td> :
              <td className="font-weight-bold" style={{width: "45%"}}>
                {": "}
                <a
                    className="text-primary font-weight-bold mb-2 mt-2"
                    href={this.state.activityLink}
                    target="_"
                  >
                    Document
                </a>
              </td>
            }
            
          </tr>
        );
        const notfound = <span className="danger-color"> Not Found </span>;
        return (

          <div className="container">
            {!isMobile ?
          <div className="container">
            <div className="font-weight-bold">
              {Activity.activityType ? Activity.activityType : notfound} <br />
              {Activity.activityDate
                ? formatDate(Activity.activityDate)
                : notfound}{" "}
              <br />
              {status(Activity.status)}
            </div>
              <table style={{width:"100%"}}>
                <tbody>
                  {activityViewInfo("Activity ID", Activity.activityId)}
                  {this.state.astatus === "Rejected" ?
                    activityViewInfo("Rejection Reason", this.state.rejectionReason) :
                    null
                  }
                  {activityViewInfo("Activity Description", Activity.activityDescription)}
                  {activityViewInfo("Firearm Type", Activity.firearmType)}
                  {activityViewInfo("Association", Activity.association)}
                  {activityViewInfo("Score", Activity.score)}
                  {activityViewInfo2("Activity Document", this.state.activityLink)}
                </tbody>
              </table>
          </div> :
          <div>
            <div className="font-weight-bold">
              {Activity.activityType ? Activity.activityType : notfound} <br />
              {Activity.activityDate
                ? formatDate(Activity.activityDate)
                : notfound}{" "}
              <br />
              {status(Activity.status)}
            </div>
            <div>
              <div>
                <span>
                  Activity ID: <br />
                </span>
                <span>
                  <b>
                    {Activity.activityId
                      ? Activity.activityId
                      : notfound}
                  </b>
                </span>
              </div>
              {this.state.astatus === "Rejected" ? (
                <div>
                  <span>
                    Rejection Reason: <br />
                  </span>
                  <span>
                    <b>
                      <div>{this.state.rejectionReason}</div>
                    </b>
                  </span>
                </div>
              ) : null}
              <div>
                <span>
                  Activity Description: <br />
                </span>
                <span>
                  <b>
                    {Activity.activityDescription
                      ? Activity.activityDescription
                      : notfound}
                  </b>
                </span>
              </div>
              <div>
                <span>
                  Firearm Type: <br />
                </span>
                <span>
                  <b>{Activity.firearmType ? Activity.firearmType : notfound}</b>
                </span>
              </div>
              <div>
                <span>
                  Association: <br />
                </span>
                <span>
                  <b>{Activity.association ? Activity.association : notfound}</b>
                </span>
              </div>
              <div>
                <span>
                  Score: <br />
                </span>
                <span>
                  <b>{Activity.score ? Activity.score : notfound}</b>
                </span>
              </div>
              <div>Activity Document</div>
              {this.state.activityLink === "NULL" || !this.state.activityLink ? (
                <div className="font-weight-bold text-danger">Null: </div>
              ) : (
                <a
                    className="text-primary font-weight-bold mb-2 mt-2"
                    href={this.state.activityLink}
                    target="_"
                  >
                    Document
                </a>
              )}
            </div>
        </div>}
          </div>
        );
    }
    
}
export default ActivityDetailsApproval;