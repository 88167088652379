import * as actionTypes from "../actions/actionTypes";

const initialState = {
  activityMemberNumber: null,
  activityID: null,
  renderPracticalActivityDetails: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACTIVITY_PARAMETERS:
      return {
        ...state,
        activityMemberNumber: action.activityMemberNumber,
        activityID: action.activityID,
        renderPracticalActivityDetails: action.renderPracticalActivityDetails
      };
    default:
      return state
  }
}

export default reducer;