import * as Types from "./PendingActivityActionTypes";

const pendingActivityState = {
    approvalStatus: {
        loading: null,
        response: null,
        error: null
    },
    member: null,
    activity: null,
};

const reducer = (state = pendingActivityState, action) => {
    switch (action.type) {
        case Types.APPROVE_PENDING_BEGIN:
        case Types.APPROVE_PENDING_ERROR:
        case Types.SET_MEMBER_ACTIVITY:
        case Types.APPROVE_PENDING_SUCCESS:
            return {
                ...state,
                ...action.pendingActivityState
            };
        default:
            return state;
    }
};

export default reducer;