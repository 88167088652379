import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import * as actionTypes from "../../store/actions/actionTypes";
import { Link } from "react-router-dom";
import { styles } from "./styles/styles";
import dpicUser from "./dProfile.jpg";
import axios from "axios";
import { BASE } from "../../API/APIendpoint";
class MemberCard extends Component {
  constructor(props) {
    super(props);

    this.state = { addPadding: "0px", roles: [] };
  }
  componentWillMount() {
    axios.get(BASE + "api/admin/getuserrole/" + this.props.MemberArray.memNo, {
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
      .then(res => this.setState({ roles: res.data }))
  }
  componentDidMount() {
    if (this.props.firstLetter !== "") {
      this.setState({ addPadding: "30px" });
    }
  }
  handleSubmit = e => {
    this.props.onMemberViewChange(this.props.MemberArray);
  };

  render() {
    const { classes } = this.props;
    return (
      <Card
        className={classes.card}
        raised={true}
        onClick={this.handleSubmit}
        component={Link}
        to="/admin/AdminMembersArea"
        style={{
          padding: "0px"
        }}
      >
        <CardContent
          style={{
            paddingTop: this.state.addPadding
          }}
        >
          {Array.isArray(this.props.MemberArray) ||
            this.props.MemberArray.length === 0 ? (
              "Please wait while members still loading..."
            ) : (
              <ul className="ml-0 pl-1">
                <div
                  className="row h-4  border-bottom "
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.handleSubmit(this.props.MemberArray);
                  }}
                >
                  <div className="row pl-2">
                    <div className="col-3 ">
                      <div className="h6 mb-0">{this.props.firstLetter}</div>
                      <img src={dpicUser} alt="Gun" className="avatar" />
                    </div>
                    <div className="col-9 pl-2 pt-1 " align="left">
                      <div className="h6">
                        {this.props.MemberArray.firstname
                          ? `${this.props.MemberArray.firstname + " " + this.props.MemberArray.surname}`
                          : null}
                      </div>
                      <Typography className={classes.content}>
                        Member Number: {this.props.MemberArray.memNo}
                      </Typography>
                      <div className="d-flex text-dark ont-weight-normal">
                        Roles:
                      {this.state.roles.map((roles, index) =>
                        <div className="d-flex" key={index}>
                          <div className="text-dark">{roles.memType}{index !== this.state.roles.length -1 ? " , " : null}</div>

                        </div>
                      )}
                      </div>

                      {/* TODO: show only when you wants to show dedicated statuses as
                    well, uncomment */}
                      {this.props.MemberArray.dedicatedStatus.map((status, i) => (
                        <div key={i}>
                          <Typography className={classes.content}>
                            {status.status} {status.dstype} <br />
                          </Typography>
                        </div>
                      ))}
                      <br />
                    </div>
                  </div>
                </div>
              </ul>
            )}
        </CardContent>
      </Card>
    );
  }
}

MemberCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    member: state.MemberViewReducer.MemberView,
    currLicenseTab: state.licensesReducer.currentLicenseTab
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCurrentMemberTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),

    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(MemberCard));
