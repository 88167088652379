import * as actionTypes from "../actions/actionTypes";

const initialState = {
  currentNewsId: '0'
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NEWS_ID:
      if(!action.currentNewsId) {
        return state;
      } else {
        return {
          ...state,
          currentNewsId: action.currentNewsId
        };
      }
    default:
      return state;
  }
};
export default reducer;
