import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader.js";
import { MEMBER_ASSESSMENT } from "../LMSAPIEndPoints";
import axios from "axios";
import { BASE } from "../../../API/APIendpoint";
export class Assessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memCourseObj: {},
      assesmentlist: {}
    }
  }
  componentDidMount() {


  //   var lessonList = [];
  //   axios.get(BASE + 'api/CourseModule/withChildren', {
  //     headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
  //     const modules = res.data;
  //     const assessments = modules.map(mod => {
  //       const material = mod.courseMaterial;
  //       return {
  //         assesmentId: mod.courseModuleId,
  //         title: mod.name,
  //         grade: "O",
  //         type: mod.type,
  //         hasChildren: material ? material.length > 0 : false
  //       };
  //     });
  //     this.setState({
  //       assesmentlist: assessments
  //     })
  //     const material = modules.map(mod => [...mod.courseMaterial]);
  //     let lessons = [];
  //   material.forEach(mat => {
  //     mat.forEach(_lesson => {
  //       lessons.push({
  //         pkCourseId: _lesson.courseMaterialId,
  //         title: _lesson.name,
  //         content: _lesson.content,
  //         assesmnetId: _lesson.courseModuleId,
  //         type: _lesson.type
  //       });
  //     });
  //   });
  //   lessonList = lessons;
  // fetch(MEMBER_ASSESSMENT + "/" + this.props.MemberV.memNo, {
  //   headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
  //   .then(res => res.json())
  //   .then(res => {
  //       let certificates = {};
  //       this.state.assesmentlist.map(ass => {
  //       if (ass.hasChildren && ass.type === 1) {
  //           return certificates[`${ass.assesmentId}`] = [ass.title];
  //       }
  //         return null;
  //       });
  //       lessonList.map(lesson => {
  //         let arr = certificates[`${lesson.assesmnetId}`];
  //         if (arr) {
  //           const score = res.data.find(
  //             d => d.courseMaterialId === lesson.pkCourseId
  //           );
  //           arr.push(parseInt(score ? score.completion : 0));
  //         return certificates[`${lesson.assesmnetId}`] = arr;
  //         }
  //         return null;
  //       });
  //       this.setState({
  //         memCourseObj: certificates
  //       });
  //     })
  //   });

  axios.get(BASE + `api/DedicatedStatus/GetMembersAssessmentProgresss?memNo=${this.props.MemberV.memNo}`, {
    headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res =>{
    let original = [[res.data.dh.name, ...res.data.dh.progress],[res.data.dss.name,...res.data.dss.progress]]

    this.setState({
          memCourseObj: original
        });
    })
    .catch(function(error) {
    console.log(error);
    });
  }
  displayCourseProgress = title => {
    let assComp = this.state.memCourseObj;
    if (Object.keys(assComp).length > 0) {
      const key = Object.keys(assComp).find(_key => {
        const values = assComp[_key];
        if (values[0] === title) {
          return _key;
        }
        return null;
      });

      const assesment = Array.from(assComp[key]);
      let total = assesment.length - 1;
      const progress = assesment.reduce((a, b) => {
        if (typeof b !== "string") {
          if (b >= 70) {
            return a + 1;
          }
        }
        return a;
      }, 0);

      let average = assesment.reduce((a, b) => {
        if (typeof b !== "string") {
          return a + b;
        }
        return a;
      }, 0);

      average = average / (total ? total : 1);
      return (
        <Fragment>
          <div className="col-12 h6">
            {progress + "/" + total + " Complete"}
          </div>
          <div className="col-12 h6 text-success">
            {average >= 70 ? "Passed" : "-"}
          </div>
        </Fragment>
      );
    }
  };

  render() {
    return (
      <div className={this.props.isPartner ? "" : "container"}>
        <div className="row">
          <GenericHeader title="Assessments" headerColor={this.props.headerColor}/>
        </div>
        <div className="row">
          <div className="col-sm-12 align-center p-2 m-2">
            {this.props.assesmentList
              .filter(l => l.type === 1)
              .map((assesment, index) => (
                <div
                  key={index}
                  className="row border-bottom p-2 mb-2"
                  style={{ cursor: "pointer" }}
                  onMouseDown={() => {
                    this.props.onCurrentAssessmentTabChange(1);
                    this.props.onAssesmentChange(assesment);
                  }}
                >
                  <div className="col-12 h6" style={{ cursor: "pointer" }}>
                    {assesment.title}
                  </div>
                  {this.displayCourseProgress(assesment.title)}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    MemberV: state.MemberViewReducer.MemberView,
    assesmentList: state.lmsReducer.assesments,
    assesmentCompletion: state.lmsReducer.assesmentCompletion,
    lessonList: state.lmsReducer.lessons
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentAssessmentTabChange: newCurrAssessmentTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_ASSESSMENT_TAB,
        currAssessmentTab: newCurrAssessmentTab
      }),
    onAssCompletionChange: assesmentCompletion =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENT_COMPLETION,
        assesmentCompletion: assesmentCompletion
      }),
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      }),
    onAssesmentChange: newAssesment =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENT,
        assesment: newAssesment
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Assessments);
