export const UPDATE_RECENT_ACCOUNT_ENTRY = "UPDATE_RECENT_ACCOUNT_ENTRY";
export const UPDATE_GET_LOGGED_IN_MEMBER = "UPDATE_GET_LOGGED_IN_MEMBER";

export const UPDATE_ACTIVE_STATUS = "UPDATE_ACTIVE_STATUS";
export const CHECKOUT_DETAILS_ACTIONS = {
  UPDATE_PAY_FAST_DETAILS: "UPDATE_PAY_FAST_DETAILS",
  UPDATE_PAY_FAST_URL: "UPDATE_PAY_FAST_URL",
  UPDATE_MERCHANT_ID: "UPDATE_MERCHANT_ID",
  UPDATE_MERCHANT_KEY: "UPDATE_MERCHANT_KEY",
  UPDATE_AMOUNT: "UPDATE_AMOUNT",
  UPDATE_ITEM_NAME: "UPDATE_ITEM_NAME"
};

export const UPDATE_CURRENT_ACCOUNT_VIEW_TAB =
  "UPDATE_CURRENT_ACCOUNT_VIEW_TAB";

export const UPDATE_CURRENT_ACTIVITY_TAB = "UPDATE_CURRENT_ACTIVITY_TAB";
export const UPDATE_ADD_ACTIVITY_DETAILS = "UPDATE_ADD_ACTIVITY_DETAILS";
export const UPDATE_USER_ACTIVITIES = "UPDATE_USER_ACTIVITIES";
export const UPDATE_ACTIVITY_TYPE_FILTER = "UPDATE_ACTIVITY_TYPE_FILTER";
export const UPDATE_ACTIVITY_DATE_FILTER = "UPDATE_ACTIVITY_DATE_FILTER";
export const TOGGLE_SHOW_FILTER_BAR = "TOGGLE_SHOW_FILTER_BAR";
export const SEARCH = "SEARCH";
export const TOGGLE_SHOW_ACTIVITY_FILTER_BAR =
  "TOGGLE_SHOW_ACTIVITY_FILTER_BAR";

export const ACTIVITY_ACTIONS = {
  UPDATE_ADD_ACTIVITY_DETAILS: "UPDATE_ADD_ACTIVITY_DETAILS",
  UPDATE_USER_ACTIVITIES: "UPDATE_USER_ACTIVITIES",
  UPDATE_ACTIVITY_TYPE_FILTER: "UPDATE_ACTIVITY_TYPE_FILTER",
  UPDATE_ACTIVITY_DATE_FILTER: "UPDATE_ACTIVITY_DATE_FILTER",
  TOGGLE_SHOW_ACTIVITY_FILTER_BAR: "TOGGLE_SHOW_ACTIVITY_FILTER_BAR",
  UPDATE_ADMIN_ACTIVITIES: "UPDATE_ADMIN_ACTIVITIES",
  UPDATE_ADMIN_VIEW_ACTIVITY_DETAILS: "UPDATE_ADMIN_VIEW_ACTIVITY_DETAILS",
  SET_ADMIN_VIEW_ACTIVITY_DETAILS: "SET_ADMIN_VIEW_ACTIVITY_DETAILS",
  UPDATE_ADMIN_ACTIVITY_TYPE_FILTER: "UPDATE_ADMIN_ACTIVITY_TYPE_FILTER",
  UPDATE_ADMIN_ACTIVITY_STATUS_FILTER: "UPDATE_ADMIN_ACTIVITY_STATUS_FILTER",
  TOGGLE_SHOW_ADMIN_ACTIVITY_FILTER_BAR: "TOGGLE_SHOW_ADMIN_ACTIVITY_FILTER_BAR"
};

export const LMS_ACTIONS = {
  UPDATE_ASSESMENT_COMPLETION: "UPDATE_ASSESMENT_COMPLETION",
  UPDATE_COURSES: "UPDATE_COURSES",
  UPDATE_COURSE: "UPDATE_COURSE",
  UPDATE_IS_ADD_NEW_COURSE: "UPDATE_IS_ADD_NEW_COURSE",
  UPDATE_QUIZZES: "UPDATE_QUIZZES",
  UPDATE_CURRENT_LMS_TAB: "UPDATE_CURRENT_LMS_TAB",
  UPDATE_ASSESMENTS: "UPDATE_ASSESMENTS",
  UPDATE_ASSESMENT: "UPDATE_ASSESMENT",
  UPDATE_LESSONS: "UPDATE_LESSONS",
  UPDATE_LESSON: "UPDATE_LESSON",
  UPDATE_ADMIN_PAGE_ITERATOR: "UPDATE_ADMIN_PAGE_ITERATOR",
  UPDATE_CURRENT_QUESTION: "UPDATE_CURRENT_QUESTION",
  UPDATE_QUIZ: "UPDATE_QUIZ",
  UPDATE_OPTIONS: "UPDATE_OPTIONS",
  UPDATE_QUESTION_TYPE: "UPDATE_QUESTION_TYPE",
  UPDATE_SELECTED_ANSWER: "UPDATE_SELECTED_ANSWER",
  UPDATE_CURRENT_QUIZ_INDEX: "UPDATE_CURRENT_QUIZ_INDEX",
  UPDATE_SCORE: "UPDATE_SCORE",
  UPDATE_NEXT: "UPDATE_NEXT",
  UPDATE_PREVIOUS: "UPDATE_PREVIOUS",
  UPDATE_SECTION_TITLE: "UPDATE_SECTION_TITLE",
  UPDATE_RESULTS_RECORD: "UPDATE_RESULTS_RECORD",
  UPDATE_MEMBER_COURSES: "UPDATE_MEMBER_COURSES",
  UPDATE_QUIZ_COMPLETION: "UPDATE_QUIZ_COMPLETION",
  UPDATE_CURRENT_ASSESSMENT_TAB: "UPDATE_CURRENT_ASSESSMENT_TAB",
  UPDATE_CERTIFICATES: "UPDATE_CERTIFICATES",
  UPDATE_COURSE_MODULE_NAME: "UPDATE_COURSE_MODULE_NAME",
  TRACK_CURRENT_MODULE_ID: "TRACK_CURRENT_MODULE_ID",
};

export const ENDORSEMENT_ACTIONS = {
  UPDATE_CURRENT_ENDORSEMENT_TAB: "UPDATE_CURRENT_ENDORSEMENT_TAB",
  UPDATE_ADMIN_ENDORSEMENT: "UPDATE_ADMIN_ENDORSEMENT",
  UPDATE_ADD_ENDORSEMENT_DETAILS: "UPDATE_ADD_ENDORSEMENT_DETAILS",
  UPDATE_ENDORSEMENT_DATE_FILTER: "UPDATE_ENDORSEMENT_DATE_FILTER",
  UPDATE_ENDORSEMENT_TYPE_FILTER: "UPDATE_ENDORSEMENT_TYPE_FILTER",
  UPDATE_USER_ENDORSEMENT: "UPDATE_USER_ENDORSEMENT",
  UPDATE_ENDORSEMENT: "UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB",
  UPDATE_A_USER_ENDORSEMENT: "UPDATE_ADMIN_ENDORSEMENT",
  UPDATE_A_ENDORSEMENT_LIST: "UPDATE_ENDORSEMENT_LIST",
  UPDATE_ADMIN_ADD_ENDORSEMENT: "UPDATE_ADD_ENDORSEMENT_DETAILS",
  UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB: "UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB",
  UPDATE_APPLICATION: "UPDATE_APPLICATION",
  UPDATE_APPLICATIONS: "UPDATE_APPLICATIONS",
  UPDATE_CURRENT_PAGE: "UPDATE_CURRENT_PAGE",
  UPDATE_CERTIFICATE: "UPDATE_CERTIFICATE",
  RESET_APPLICATION: "RESET_APPLICATION"
};

export const DASHBOARD_ACTIONS = {
  UPDATE_DS_APPLICATIONS: "UPDATE_DS_APPLICATIONS",
  UPDATE_ACTIVITY_OBJECT: "UPDATE_ACTIVITY_OBJECT",
  UPDATE_PENDING: "UPDATE_PENDING",
  UPDATE_TOTAL_MEMBERS: "UPDATE_TOTAL_MEMBERS",
  UPDATE_PENDINGS: "UPDATE_PENDINGS",
  GET_ACTIVITIES: "GET_ACTIVITIES",
  UPDATE_A_PENDINGS: "UPDATE_A_PENDINGS",
  UPDATE_DS_PENDINGS: "UPDATE_DS_PENDINGS",
  UPDATE_AE_PENDINGS: "UPDATE_AE_PENDINGS",
  UPDATE_DH_MEMBERS: "UPDATE_DH_MEMBERS",
  UPDATE_DSS_MEMBERS: "UPDATE_DSS_MEMBERS",
  UPDATE_PENDING_MEMBERS: "UPDATE_PENDING_MEMBERS",
  UPDATE_RENEWAL_MEMBERS: "UPDATE_RENEWAL_MEMBERS",
  UPDATE_TOTAL_RENEWALS: "UPDATE_TOTAL_RENEWALS",
  UPDATE_TOTA_NEW_MEMBERS: "UPDATE_TOTA_NEW_MEMBERS",
  GET_ALL_PENDING_MEMBERS_URL: "GET_ALL_PENDING_MEMBERS_URL",
  GET_ALL_RENEWAL_MEMEBRS_URL: "GET_ALL_RENEWAL_MEMEBRS_URL",
  UPDATE_PENDING_ENDORSEMENTS: "UPDATE_PENDING_ENDORSEMENTS",
  GET_ALL_ENDORSEMENTS_URL: "GET_ALL_ENDORSEMENTS_URL"
};
export const CHANGE_DOCUMENT_TYPE = "CHANGE_DOCUMENT_TYPE"
export const UPDATE_DOWNLOADS = "UPDATE_DOWNLOADS";
export const GET_LOYALTY_DAYS = "GET_LOYALTY_DAYS";
export const UPDATE_SIGNUP_DETAILS = "UPDATE_SIGNUP_DETAILS";
export const UPDATE_SIGNUP_DETAILS_FROM_DB = "UPDATE_SIGNUP_DETAILS_FROM_DB";
export const UPDATE_LOGIN_DETAILS = "UPDATE_LOGIN_DETAILS";
export const UPDATE_RESET_DETAILS = "UPDATE_RESET_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const MEMBER_PAYMENT_ADD = "MEMBER_PAYMENT_ADD";
export const UPDATE_MEMBER_VIEW = "UPDATE_MEMBER_VIEW";
export const UPDATE_MEMBER_VIEW_LIST = "UPDATE_MEMBER_VIEW_LIST";
export const UPDATE_CURRENT_MEMBER_TAB = "UPDATE_CURRENT_MEMBER_TAB";
export const UPDATE_ADD_MEMBER_VIEW_DETAILS = "UPDATE_ADD_MEMBER_VIEW_DETAILS";
export const UPDATE_MEMBER_VIEW_ACTIVITIES = "UPDATE_MEMBER_VIEW_ACTIVITIES";
export const UPDATE_MEMBER_VIEW_ACTIVITY_TYPE_FILTER =
  "UPDATE_MEMBER_VIEW_ACTIVITY_TYPE_FILTER";
export const UPDATE_MEMBER_VIEW_ACTIVITY_DATE_FILTER =
  "UPDATE_MEMBER_VIEW_ACTIVITY_DATE_FILTER";
export const TOGGLE_MEMBER_VIEW_SHOW_ACTIVITY_FILTER_BAR =
  "TOGGLE_MEMBER_VIEW_SHOW_ACTIVITY_FILTER_BAR";
export const UPDATE_VIEW_LICENCE = "UPDATE_LICENCE";
export const UPDATE_VIEW_LICENCE_LIST = "UPDATE_LICENCE_LIST";
export const UPDATE_REP_NUM = "UPDATE_REP_NUM";
export const ON_COMMISSION_REC_UPDATE = "ON_COMMISSION_REC_UPDATE";
export const BANKING_DETAILS_UPDATE = "BANKING_DETAILS_UPDATE";
export const UPDATE_COMMISSION_TAB = "UPDATE_COMMISSION_TAB";
export const MONTHLY_COMMISSION = "MONTHLY_COMMISSION";
export const UPDATE_COMMISSION_CATEGORY = "UPDATE_COMMISSION_CATEGORY"
export const UPDATE_LICENCE = "UPDATE_LICENCE";
export const UPDATE_LICENCE_LIST = "UPDATE_LICENCE_LIST";
export const UPDATE_CURRENT_LICENCE_TAB = "UPDATE_CURRENT_LICENCE_TAB";
export const DELETE_LICENCE = "DELETE_LICENCE";
export const GET_BASE64_IMAGES = "GET_BASE64_IMAGES";
export const IS_PARTNER_UPDATE = "IS_PARTNER_UPDATE";
export const UPDATE_BANKING_DETAILS_VIEW = "UPDATE_BANKING_DETAILS_VIEW";
export const BANKING_DETAILS_EDIT = "BANKING_DETAILS_EDIT";
export const UPDATE_MEMBERS_AREA = "UPDATE_MEMBERS_AREA";
export const CHANGE_ENDORSEMENT_PURPOSE = "CHANGE_ENDORSEMENT_PURPOSE";
export const UPDATE_ADMIN_PORTAL = "UPDATE_ADMIN_PORTAL";

export const UPDATE_DEDICATED_STATUS = "UPDATE_DEDICATED_STATUS";
export const REJECT_DS_TYPE = "REJECT_DS_TYPE";
export const MEM_VIEW_ACCT_DET = "MEM_VIEW_ACCT_DET";
export const UPDATE_CONTACT_US_DETAILS = "UPDATE_CONTACT_US_DETAILS";
export const UPDATE_NEWSFEED = "UPDATE_NEWS_FEED";
export const UPDATE_LOYALTY_DAYS_TAB = "UPDATE_LOYALTY_DAYS_TAB";
export const GET_MEM_LOYALTY_RECORD = "GET_MEM_LOYALTY_RECORD";
export const GET_LOYALTY_DAYS_ARRAY = "GET_LOYALTY_DAYS_ARRAY";
export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const UPDATE_ACCOUNT_LIST = "UPDATE_ACCOUNT_LIST";
export const UPDATE_MAIN_DETAILS = "UPDATE_MAIN_DETAILS";
export const TOGGLE_SHOW_ADMIN_HAMBURGER = "TOGGLE_SHOW_ADMIN_HAMBURGER";
export const TOGGLE_HAMBURGER = "TOGGLE_HAMBURGER";
export const HANDLE_MEMBER_PAGE_TOGGLE = "HANDLE_MEMBER_PAGE_TOGGLE";
export const UPDATE_ARTICLES = "UPDATE_ARTICLES";
export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_MEMBER_STATE = "UPDATE_MEMBER_STATE";
export const UPDATE_MEMBER_DETAILS = "UPDATE_MEMBER_DETAILS";
export const UPDATE_MEMBER_DETAILS_2 = "UPDATE_MEMBER_DETAILS_2";
export const UPDATE_MEMBER_CITY = "UPDATE_MEMBER_CITY";
export const UPDATE_MEMBER_DETAILS_LIST = "UPDATE_MEMBER_DETAILS_LIST";
export const UPDATE_APPLICATION_POST = "UPDATE_APPLICATION_POST";
export const UPDATE_CURRENT_ADMIN_ACTIVITY_TAB =
  "UPDATE_CURRENT_ADMIN_ACTIVITY_TAB";
export const UPDATE_ACTIVITY_PARAMETERS = "UPDATE_ACTIVITY_PARAMETERS";
export const GET_MEMBER_PROFILE_PICTURE = "GET_MEMBER_PROFILE_PICTURE";
export const UPDATE_MEMBER_PROFILE_PICTURE = "UPDATE_MEMBER_PROFILE_PICTURE";
export const MEMBER_PP_RECORD_EXISTS = "MEMBER_PP_RECORD_EXISTS";
export const RESET_MEMBERS_VIEWED_ACTIVITY = "RESET_MEMBERS_VIEWED_ACTIVITY";
export const UPDATE_FILES_TO_UPLOAD = "UPDATE_FILES_TO_UPLOAD";
export const UPDATE_FILES_TO_UPLOAD_RESET = "UPDATE_FILES_TO_UPLOAD_RESET";
export const UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2 =
  "UPDATE_CURRENT_ADMIN_ACTIVITY_TAB";
export const UPDATE_NATIONAL_RANK = "UPDATE_NATIONAL_RANK";
export const UPDATE_NATIONAL_RANK_LIST = "UPDATE_NATIONAL_RANK_LIST";
export const UPDATE_RANK_BY_YEAR = "UPDATE_RANK_BY_YEAR";
export const UPDATE_RANK_BY_ACTIVITY = "UPDATE_RANK_BY_ACTIVITY";
export const UPDATE_RANK_BY_FIREARMTYPE = "UPDATE_RANK_BY_FIREARMTYPE";
export const UPDATE_RANK_BY_ASSOCIATION = "UPDATE_RANK_BY_ASSOCIATION";
export const UPDATE_REG_SUBMIT_ERROR_MESSAGE =
  "UPDATE_REG_SUBMIT_ERROR_MESSAGE";
export const UPDATE_OPTIONS = "UPDATE_OPTIONS";
export const REDIRECT_TO_DS_LIST = "REDIRECT_TO_DS_LIST";
export const APPROVED_MEMBER_ACTIVITY = "APPROVED_MEMBER_ACTIVITY";
export const UPDATE_VALIDATE_SECTION = "UPDATE_VALIDATE_SECTION";
export const UPDATE_VALIDATE__APPLICATION_TYPE = 'UPDATE_VALIDATE__APPLICATION_TYPE';
export const UPDATE_SELECTED_ACTIVITY_DESCRIPTION = 'UPDATE_SELECTED_ACTIVITY_DESCRIPTION';

/**/
export const UPDATE_ABOUT_US_CONTENT = 'UPDATE_ABOUT_US_CONTENT';
export const UPDATE_LANDING_PAGE_CONTENT = 'UPDATE_LANDING_PAGE_CONTENT';
export const UPDATE_LOGIN_PAGE_TOGGLER = 'UPDATE_LOGIN_PAGE_TOGGLER';
export const UPDATE_FIREARM_OWNERS_CONTENT = 'UPDATE_FIREARM_OWNERS_CONTENT';
export const UPDATE_SPORT_SHOOTING_CONTENT = 'UPDATE_SPORT_SHOOTING_CONTENT';
export const UPDATE_HUNTING_CONTENT = 'UPDATE_HUNTING_CONTENT';
export const UPDATE_NEWS_ID = 'UPDATE_NEWS_ID';
export const UPDATE_PAGE_ONE = 'UPDATE_PAGE_ONE';
/**/
