import {
    SET_NOTIFICATIONS,
  } from './types';

  const fetchNotifications = service => (memNo) => dispatch => service
    .getMemberNotifications(memNo)
    .then((res) => res)
    .catch(() => []);
  
  const putMemberNotification = service => (id,memNo) => dispatch => service
    .updateMemberNotification(id,memNo)
    .then((res) => dispatch({ type: SET_NOTIFICATIONS, payload: res }))
    .catch(() => dispatch({ type: SET_NOTIFICATIONS, payload: []}));

  export default function(notificationService) {
    return {
      fetchNotifications: fetchNotifications(notificationService),
      putMemberNotification: putMemberNotification(notificationService)
    };
  }
  