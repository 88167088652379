import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import * as actionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  BASE,
  GET_NATIONALRANKING_URL,
  FILTER_NATIONAL_RANKING_URL
} from "../../API/APIendpoint";
import axios from "axios";
import GeneriHeader from "../../utils/GenericHeader";
import Ranking from "./Ranking";
import { Form } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { styles } from "./styles/styles";
import { FilterList as FilterListIcon } from "@material-ui/icons";

class OcassionalHunter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      filteredPosts: [],
      ID: 2,
      filter: {
        firearmType: null,
        ActivityType: null,
        Association: null
      },
      filterOpen: false
    };
  }

  showFilterButton = show => {
    return show ? (
      <IconButton onClick={() => this.filterFunction()}>
        <FilterListIcon />
      </IconButton>
    ) : null;
  };
  componentDidMount() {
    axios.get(BASE + GET_NATIONALRANKING_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.setState({
        Posts: res.data
      });
    });
  }
  handleActivityChange = event => {
    var filter = this.state.filter;
    filter.ActivityType = event.target.value;
    this.setState({ filter: filter });
  };

  handleAssociationChange = event => {
    var filter = this.state.filter;
    filter.Association = event.target.value;
    this.setState({ filter: filter });
  };

  handleFirearmChange = event => {
    var filter = this.state.filter;
    filter.firearmType = event.target.value;
    this.setState({ filter: filter });
  };

  handleFirearmFilter = e => {
    axios
      .post(BASE + FILTER_NATIONAL_RANKING_URL,this.state.filter,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.setState({
          Posts: res.data
        });
      });
  };
  handlerRanking = () => {
    const data = this.state.filter;
    axios.post(BASE + FILTER_NATIONAL_RANKING_URL,data,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(res => {
        this.setState({
          filteredPosts: res
        });
      })
      .catch();
  };
  toggleFilter = () => {
    this.setState({
      filterOpen: !this.state.filterOpen
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.static}>
        <GeneriHeader title="Other Association Ranking" />
        {this.state.filterOpen ? (
          <Fragment>
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Container className={classes.container}>
                <div className={classes.filterBy}>Filter By:</div>
                <Form.Row>
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Firearm</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Select FirearmType"
                        value={this.state.filter.firearmType}
                        onChange={e => {
                          const _filter = this.state.filter;
                          _filter.firearmType = e.target.value;
                          this.setState({
                            filter: _filter
                          });
                        }}
                      >
                        <option />
                        <option>Center Fire Bolt Action - CFBA </option>
                        <option>Rim Fire Bolt Action - RFBA</option>
                        <option>Center Fire Rifle Semi Auto - CFRSA</option>
                        <option>Rim Fire Rifle Semi Auto - RFRSA</option>
                        <option>Center Fire Handgun - CFHG</option>
                        <option>Rim Fire Hand Gun - RFHG</option>
                        <option>Big Bore Rifle Bolt Action - BBRBA</option>
                        <option>Big Bore Rifle Break Action - BBRKA</option>
                        <option>Shot Gun 12 Guage - SG12</option>
                        <option>Shot Gun 16 Gauge - SG16</option>
                        <option>Muzzle Loader Rifle - MLRBP</option>
                        <option>Muzzle Loader Hand Gun - MLHBP</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Activity</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Select ActivityType"
                        value={this.state.filter.ActivityType}
                        onChange={e => {
                          const _filter = this.state.filter;
                          _filter.ActivityType = e.target.value;
                          this.setState({
                            filter: _filter
                          });
                        }}
                      >
                        <option />
                        <option>NARFO</option>
                        <option>Benchrest</option>
                        <option>Bisley</option>
                        <option>Bianchi</option>
                        <option>CHASA Range Shoot</option>
                        <option>Cowboy Action</option>
                        <option>Double Trap</option>
                        <option>F-Class</option>
                        <option>Ghong Challenge</option>
                        <option>Grouping</option>
                        <option>IDPA</option>
                        <option>IPSC</option>
                        <option>Mastershot</option>
                        <option>Pin Shooting</option>
                        <option>PRS</option>
                        <option>Skeet</option>
                        <option>Sport Shotgun</option>
                        <option>Silhouette</option>
                        <option>Steel Challenge</option>
                        <option>SADPA</option>
                        <option>SACRA</option>
                        <option>Trap</option>
                        <option>Veldskiet</option>
                        <option>XSSSA</option>
                        <option>3-GUN</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col xs={6}>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Association</Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Select Association"
                        value={this.state.filter.Association}
                        onChange={e => {
                          const _filter = this.state.filter;
                          _filter.ActivityType = e.target.value;
                          this.setState({
                            filter: _filter
                          });
                        }}
                      >
                        <option />
                        <option>
                          CTSASA - Clay Target Shooting Association of South
                          Africa
                        </option>
                        <option>
                          SAHRSA - South African Hunting Rifle Shooting
                          Association
                        </option>
                        <option>SABU - South African Bisley Union</option>
                        <option>
                          SAMSSA - South African Metallic Silhouette Shooting
                          Association
                        </option>
                        <option>
                          SABSF - South African Bench Rest Federation
                        </option>
                        <option>
                          SADPA - South African Defense Pistol Association
                        </option>
                        <option>
                          SAPSA - South African Practical Shooting Association
                        </option>
                        <option>
                          SACRA - South African Combat Rifle Association
                        </option>
                        <option>
                          IDPA - International Defense Pistol Association
                        </option>
                        <option>
                          NRL SA - National Rifle League South Africa
                        </option>
                        <option>
                          XSSSA - Extreme Steel Shooting South Africa
                        </option>
                        <option>
                          SAPSF - South African Pin Shooting Federation
                        </option>
                        <option>
                          PRS SA - Precision Rifle Series South Africa
                        </option>
                        <option>WSSA - Western Shooters of South Africa</option>
                        <option>
                          Mastershot - Mastershot Series South Africa
                        </option>
                        <option>3 Gun - 3 Gun Nation South Africa</option>
                        <option>
                          Steel Challenge - Steel Challenge South Africa
                        </option>
                        <option>SAPF - South African Pistol Federation</option>
                        <option>
                          SAARA - South African Air Rifle Federation
                        </option>
                        <option>
                          SAHGA - South African Hunters and Game Conservation
                          Association Multi Discipline
                        </option>
                        <option>
                          BASA - Big Bore Association of South Africa
                        </option>
                        <option>
                          BPSU - Black Powder Shooting Union of South Africa
                        </option>
                        <option>
                          SAPSSF - South African Precision Sport Shooting
                          Federation
                        </option>
                        <option>LRSC - Long Range Shooting Club</option>
                        <option>NARFO</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Row>
                  <Col>
                    <button
                      onClick={() => {
                        const _filter = {
                          firearmType: null,
                          ActivityType: null,
                          Association: null
                        };
                        this.setState({
                          filter: _filter,
                          applyClicked: false
                        });
                        this.toggleFilter();
                      }}
                      className={`${
                        this.state.resetClicked ? "button-active" : ""
                      }`}
                    >
                      Reset All
                    </button>
                  </Col>

                  <Col xs={7}>
                    <button
                      onClick={() => {
                        this.setState({ applyClicked: true });
                        this.handlerRanking();
                        this.toggleFilter();
                      }}
                      className={`${
                        this.state.applyClicked ? "button-active" : ""
                      }`}
                    >
                      Apply Filter
                    </button>
                  </Col>
                </Row>
              </Container>
            </Form>
            <br />
            <hr className={classes.hr} />
          </Fragment>
        ) : null}
        <br />

        <div className={classes.root}>
          {this.state.applyClicked
            ? this.state.filteredPosts.map((ranking, index) => (
                <Ranking key={index} RankingArray={ranking} />
              ))
            : this.state.Posts.map((ranking, index) => (
                <Ranking key={index} RankingArray={ranking} />
              ))}
        </div>
      </div>
    );
  }
}
OcassionalHunter.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    newActDetails: state.nationalRankingReducer.nationalRanking,
    nationalRankingList: state.nationalRankingReducer.nationalRankingList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddNationalRankingDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_NATIONAL_RANK_LIST,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OcassionalHunter));
