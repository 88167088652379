import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import NotificationHome from "../adminNotifications/NotificationHome"

class Notifications extends Component {
  render() {
    return (
        // Like a container (The mainn folder holds containers for components)
        <div className="mainHome" style={{marginTop: "60px"}}>
        <Paper elevation={0}>
          <NotificationHome/>
        </Paper>
      </div>
    );
  }
}

export default Notifications;
