import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import AdminNewsHome from "../adminNews/AdminNewsHome"

class News extends Component {
  render() {
    return (
        <div className="mainHome" style={{marginTop: "60px"}}>
        <Paper elevation={0}>
          <AdminNewsHome/>
        </Paper>
      </div>
    );
  }
}

export default News;
