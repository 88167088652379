export const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 1,
    flexDirection: "col",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    display: "inline-block",
    fontSize: 14,
    color: "black"
  }
};

export const viewAllMemberStyles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80vh",
    overflow: "auto"
  }
};
