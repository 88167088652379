import React from "react";
import { fileUploadWidth } from "./DefaultOrSelectFiles";
function SelectFile(props) {
  return (
    <div>
      {props.deletedFiles.map((list, Index) => {
        return (
          <div key={Index}>
            <label className="row checkLabel filterButtons">
              <input
                value={list.downloadId}
                className="co-1"
                type="checkbox"
                onChange={props.itemFunction}
              />
              <div className="label-text align-self-center" />
              <div className={fileUploadWidth}>
                <div className="h6 ml-6">{list.name}</div>
                <div>{list.discription}</div>
              </div>
            </label>
          </div>
        );
      })}
    </div>
  );
}
export default SelectFile;
