import React, { Component } from 'react';
import {
  labelHtml
} from '../../shared/helpers';

import Container from '../../hoc/Container';

import {
    GoogleApiWrapper,
    Map,
    InfoWindow, 
    Marker } from 'google-maps-react';
// import MAP_API from  '../../../../services/mapApi';
import MAP_API from  '../../services/mapApi';
import {SubtitleBar, Loader} from '../../components/index'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import StoreIcon from '@material-ui/icons/Store';
import axios from 'axios';
import { BASE } from '../../../API/APIendpoint';
import { add, parse } from 'date-fns';
import LeafMaps from '../../containers/MapsContainer/LeafMaps';

//Converted MakeStyles to WithStyles 
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  partnerSection:{
    marginBottom:'7%',
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // maxWidth: 500,
  },
  image: {
    // width: 380,
    // height: 540,
    marginBottom:'10px',
    paddingRight:'30px'
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100px',
  },
  
  heading: {
    lineHeight: '1',
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 'bold',
    background: '#F0F0F0',
    textAlign: 'center',
    padding: '60px',
    marginTop:  '-6px',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.h6.fontSize
    }
  },
  caption: {
    fontSize: theme.typography.h6.fontSize,
    color: '#6E6983',
    paddingLeft: '0',
    lineHeight: '1.5',
    margin: '10px 0'
  },
  
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  paper: {
    boxShadow: 'none',
    padding: '40px 0 178px',
  },

  
});


class MemberPartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        partners:[],
        partnerLocations:[],
        memberPartner:{},
        address: '',
        // showingInfoWindow: false,
        // activeMarker: {},
        selectedPlace: {},
        mapCenter: null,
        bounds: null,
        selectOptions : []
      };
  }

  componentDidMount(){

    //Get All the partners 
    axios({
        url: `${BASE}api/Stores`,
        method: 'get',
    }).then(res => {
        
      let memberPartner = null;
      let mapCenter = {}

      let partnerLocations = res.data.map(address =>{
        if(address.store == this.props.memberData.store){
          memberPartner = address;

          mapCenter = {
            lat: parseFloat(address.lat), 
            lng: parseFloat(address.long)
          }
          
          return <Marker 
          style={{cursor:'hand'}}
          name={address.store}
          onClick={this.onMarkerClick}
          position={{lat:  address.lat, lng: address.long}} />

        }
      } );
      
      this.setState({mapCenter:mapCenter,partners:res.data, partnerLocations:partnerLocations, memberPartner:memberPartner});
    })
  }

  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: {},
  };
  
  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
 
  onMapClicked = (props) => {
    // alert("loop");
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;
    const containerStyle = {
      // position: 'relative',  
      width: '71%',
      height: '60%',
      borderRadius:'10px',
      padding:'30px',
    }
    const containerStyleMobile = {
      // position: 'relative',  
      width: '740px',
      height: '300px',
      borderRadius:'10px',
    }

    const iconColor = {
      color:'#4F36A6',
      margin:'0px',
      marginRight:'5px'
    }

    // var bounds = new this.props.google.maps.LatLngBounds();
    // // bounds.extend({lat: -33.843223, lng: 18.6748262});
    //   console.log(this.state.mapCenter);
    //   let lat = this.state.mapCenter?.lat ?? 0;
    //   let long = this.state.mapCenter?.long ?? 0;

    //   for (var i = 0; i < 3; i++) {
    //     let point = {lat: lat + (i/70), lng: long + (i/70)};
    //     bounds.extend(point);
    //   }
      
    return (
      <div className="inner-shadow py-2 px-4 bg-white-4">
        <Container title='Affiliation'> 

       
        <div className={classes.partnerSection}>
            <Grid container spacing={2}>
              <Grid item sm={4} xs={12}>
              <Grid container>
              <div className={classes.image} >
                    <img className={classes.img} alt="PartnerLogo" src={this.state.memberPartner?.logo} />
              </div>
                </Grid>             

              <Grid item>
                    <Typography variant="h6"><StoreIcon style={iconColor} fontSize={'medium'}/><strong>{this.state.memberPartner?.store}</strong></Typography><br/>
                    <Typography variant="h6"><LanguageIcon style={iconColor} fontSize={'medium'}/><strong><a href={this.state.memberPartner?.website?.includes('http') ? this.state.memberPartner?.website :`http://${this.state.memberPartner?.website}`} target="_blank">{this.state.memberPartner?.website}</a> </strong></Typography><br/>
                    <Typography variant="h6"><EmailIcon style={iconColor} fontSize={'medium'}/> <strong>{this.state.memberPartner?.email}</strong></Typography>
                  </Grid>
              </Grid>

              {/* Don't load the map until points are loaded  */}
              {this.state.mapCenter && <Grid item sm={8} xs={12}>
                <LeafMaps position={this.state.mapCenter} partner={this.state.memberPartner} containerStyleMobile={containerStyleMobile}/>
                {/* <Map     
                  initialCenter={this.state.mapCenter}
                  // bounds={this.state.bounds}
                  style={ isMobile ? containerStyleMobile: containerStyle} containerStyle={isMobile ? containerStyleMobile: containerStyle} google={this.props.google} 
                  >

                {this.state?.partnerLocations ?? []}
                 <InfoWindow onClose={this.onInfoWindowClose}>
                  <div>
                      <h1>{this.state.selectedPlace?.name}</h1>
                  </div>
              </InfoWindow>
            </Map>  */}
              </Grid>}
            </Grid>
          </div>
        
        <div style={{opacity:1}}>
        <SubtitleBar 
          variant="subtitle1" 
          title="Representative"
        />
        </div>
        <div className={''} style={ isMobile? {marginTop:"140px"} : {marginTop:"-20px"}}>

          <Grid  container spacing={4} sm={12}>
            {labelHtml('Nick Name', this.props.personalAssisstant?.nickname, isMobile)}
            {labelHtml('First Name', this.props.personalAssisstant?.firstname, isMobile)}
            {labelHtml('Surname', this.props.personalAssisstant?.surname, isMobile)}
            {labelHtml('WhatsApp Only',this.props.personalAssisstant?.cellNo, isMobile)}
            {labelHtml('Email Address', this.props.personalAssisstant?.email, isMobile)}
          </Grid>
        </div>
        </Container>
      </div>
    );
  }
}

const LoadingContainer = (props) => (
  <Loader />
)

export default GoogleApiWrapper({ 
    apiKey: (`${MAP_API}`),
    LoadingContainer: LoadingContainer
})(withStyles(styles, { withTheme: true })(MemberPartnerDetails));
