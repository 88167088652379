import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Editor } from "@tinymce/tinymce-react";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ARTICLE_URL, BASE, NEWS_BY_ARTICLE_URL } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import defPic from "./NewsImage/pp6.jpg";
import "./newsPicture.css";

class EditNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      content: "",
      bgNews: defPic,
      index: this.props.match.params.no,
      formData: new FormData(),
      newsPicture: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.showNewsPicture = this.showNewsPicture.bind(this);
    this.newsPicture = React.createRef();
  }
  componentDidMount() {
    this.setState({
      title: this.props.oneArticle[this.state.index].title,
      content: this.props.oneArticle[this.state.index].content
    });
  }
  pictureUpload(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.state.formData.append("fileName", file);
    let reader = new FileReader();
    reader.onloadend = t => {
      this.setState({
        bgNews: t.target.result
      });
    };
    this.state.formData.append(
      "NewsId",
      this.props.oneArticle[this.state.index].newsArticleId
    );
    Axios({
      url: BASE + "api/NewsArticles/uploadNewsPicture",
      data: this.state.formData,
      method: "post",
      headers: {
        "Content-type": "multipart/form-data",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
      }
    }).then(res => {});
    reader.readAsDataURL(file);
  }

  showNewsPicture(e) {
    e.preventDefault();
    this.newsPicture.current.click();
  }
  handleChange(e) {
    this.setState({ content: e.target.getContent() });
  }
  onTitleChange(e) {
    e.preventDefault();
    this.setState({ title: e.target.value });
  }
  linkToNews() {
    this.props.history.push("/News");
  }
  buttonClickUpdateArticle() {
    this.setState({
      title:
        this.state.title === this.props.oneArticle[this.state.index].title
          ? this.props.oneArticle[this.state.index].title
          : this.state.title,
      content:
        this.state.content === this.props.oneArticle[this.state.index].content
          ? this.props.oneArticle[this.state.index].content
          : this.state.content
    });
    Axios({
      url: BASE + NEWS_BY_ARTICLE_URL + this.props.oneArticle[this.state.index].newsArticleId,
      data: {
        NewsArticleId: this.props.oneArticle[this.state.index].newsArticleId,
        Title: this.state.title,
        Content: this.state.content
      },
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      Axios.get(BASE + ARTICLE_URL,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
        const article = res.data;
        this.props.onArticlesChange(article);
      });
      this.props.history.push("/News");
    });
  }
  render() {
    return (
      <div className="container " style={{ marginTop: "80px" }}>
        <input
          type="file"
          accept="image/png, image/jpeg"
          style={{ display: "none" }}
          onChange={e => this.pictureUpload(e)}
          ref={this.newsPicture}
        />
        <div>
          <div className="material-editor-header">
            <div
              className="material-editor-header-back-button"
              onClick={() => {
                this.linkToNews();
              }}
            >
              <KeyboardArrowLeft
                style={{ width: "50px", height: "35px", cursor: "pointer" }}
              />
            </div>
            <Fragment>
              <div className="material-editor-header-input">
                <input
                  className="form-control"
                  type="text"
                  placeholder={this.props.oneArticle[this.state.index].title}
                  onChange={e => this.onTitleChange(e)}
                />
              </div>
              <div className="material-editor-header-save-button">
                <button
                  className="btn btn-outline-success form-control"
                  onClick={() => {
                    this.buttonClickUpdateArticle();
                  }}
                >
                  Save
                </button>
              </div>
            </Fragment>
          </div>
          <div className="flex-container">
            <img
              src={this.state.bgNews}
              alt="Activity"
              className="news-image mr-2"
            />
            <div
              className="mt-2 mb-2"
              onClick={this.showNewsPicture}
              style={{ cursor: "pointer" }}
            >
              Add Background
            </div>
          </div>
          <Editor
            className="mt-4"
            initialValue={this.props.oneArticle[this.state.index].content}
            init={{
              plugins: "link image code",
              toolbar:
                "undo redo | bold italic | alignleft aligncenter alignright | image | link | cut | copy | paste | font",
              height: "650px"
            }}
            apiKey="kmpgx6hmv3ix0abz8fn9sly4r5vf2fzw0tw2ubm88mhydjba"
            onChange={e => this.handleChange(e)}
          />
        </div>
      </div>
    );
  }
}
EditNews.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      }),
    onArticlesChange: newArticles =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLES,
        articles: newArticles
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNews);
