import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Typography,
} from '@material-ui/core/';
import {
  IconLabelButton,
  Filter,
} from '../index';
import {
  ArrowBack,
  Edit,
  Print,
  DeleteForeverRounded,
  CloudDownload
} from '@material-ui/icons';
import Icon from '@material-ui/core/Icon';
import { isMobile } from 'react-device-detect';

// For dropdown
import NativeSelect from '@material-ui/core/NativeSelect';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));


const ActionType = (props) => {
  const { 
    apply, 
    reApply,
    callBack, 
    edit, 
    filter, 
    filterOptions, 
    dropdownSelect,
    dropdownSelectOptions, 
    printer, 
    defaulFilterValue,
    addLicenseReminders,
    addLicenseApplication, 
    downloadDedicatedStatusCallBack, 
    showDownloadDSDocument,
    downloadDHCourseCertificateCallBack,
    showDownloadDHCourseCertificate,
  } = props;

  if (edit) {
    return <IconLabelButton
      icon={<Edit />}
      actionText="Edit"
      callBack={callBack}
    />;
  }
  if (dropdownSelect) {
    return <DropDownSelect callBack={props.callBack} providers = {props.providers}/>
  }

  if (filter) {
    return <Filter
      callBack={filterOptions.callBack}
      elements={filterOptions.elements}
      defaulFilterValue={defaulFilterValue}
    />;
  }

  if (printer) {
    return <IconLabelButton
      icon={<Print />}
      actionText="Print"
      callBack={callBack}
    />;
  }

  if (apply) {
    return <IconLabelButton
      icon={<Icon >
        <i className="material-icons">
			    add_circle
        </i>
      </Icon>}
      actionText="Apply"
      callBack={callBack}
    />;
  }
  if (reApply) {
    return <IconLabelButton
      icon={<Icon >
        <i className="material-icons">
        loop
        </i>
      </Icon>}
      actionText="Re-Apply"
      callBack={callBack}
    />;
  }
  if (addLicenseReminders) {
    return <IconLabelButton
      icon={<Icon >
        <i className="material-icons">
			    add_circle
        </i>
      </Icon>}
      actionText="Add reminder"
      callBack={addLicenseReminders}
    />;
  }

  if (addLicenseApplication) {
    return <IconLabelButton
      icon={<Icon >
        <i className="material-icons">
			    add_circle
        </i>
      </Icon>}
      actionText="Add Application"
      callBack={addLicenseApplication}
    />;
  }

  if (downloadDedicatedStatusCallBack && showDownloadDSDocument) {
    return <IconLabelButton
      icon={<CloudDownload />}
      actionText="Download"
      callBack={downloadDedicatedStatusCallBack}
    />;
  }

  if (downloadDHCourseCertificateCallBack && showDownloadDHCourseCertificate) {
    return <IconLabelButton
      icon={<CloudDownload />}
      actionText="DHS"
      callBack={downloadDHCourseCertificateCallBack}
      textMobileAlt={true}
      titleAlt="DHS"
    />;
  }

  return null;
};

const DropDownSelect = (props) => {
  const actionType = ActionType(props);
  const classes = useStyles();
  const [state, setState] = React.useState({
    name: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    props.callBack(event.target.value);
  };

  return (
        <FormControl className={classes.formControl}>
        <NativeSelect
          defaultValue={"All"}
          onChange={handleChange}
          name="providers"
          className={classes.selectEmpty}
          inputProps={{ 'aria-label': 'name' }}
        >
          <option value="">All</option>
          {
            props.providers?.map(item =>{
              return <option value={item}>{item}</option>
            })
          }
        </NativeSelect>
        <FormHelperText>Provider</FormHelperText>
      </FormControl>
  );
};

const SubtitleBar = (props) => {

  const actionType = ActionType(props);
  return (
    <Grid container className="my-7">
      <Grid item container>
        <Grid
          item
          container
          xs={actionType ? 10 : 12}
          sm={actionType ? 11 : 12}
          lg={actionType ? 9 : 12}
          xl={actionType ? 11 : 12}
          alignItems="center"
        >
          {props.back &&
            <Grid item xs={1} container alignContent="center">
              <ArrowBack color="primary" className="pointer" onClick={() => props.onBack()} />
            </Grid>
          }
          <Grid item xs={11} container alignContent="center">
            <Grid item
              xs={props.downloadActivity || props.remove || props.removeApplication || props.downloadLicenseReminders || props.showDownloadDSSCourseCertificate ? 6 : 12}
              sm={props.downloadActivity || props.remove || props.removeApplication || props.downloadLicenseReminders || props.showDownloadDSSCourseCertificate ? 8 : 12}
              lg={props.downloadActivity || props.remove || props.removeApplication || props.downloadLicenseReminders ? 5 : props.showDownloadDSSCourseCertificate ? 6 : 12}
              className="self-center"
            >
              <Typography variant={props.variant} color={props.color ?? 'primary'} align="left">{props.title}</Typography>
            </Grid>
            {props.downloadActivity &&
              <Grid
                item
                container
                xs={isMobile ? 3 : 6}
                sm={isMobile ? 3 : 4}
                lg={7}
                className="self-center flex-nowrap"
                direction="row"
				wrap={false}
              >
                <Grid item>
                  <IconLabelButton
                    icon={<CloudDownload />}
                    actionText="Document"
                    variant="text"
                    color="primary"
                    callBack={props.downloadActivityCallback}
                  />
                </Grid>
                <Grid item>
                  <IconLabelButton
                    icon={<Icon >
                      <i className="material-icons">
						add_circle
                      </i>
                    </Icon>}
                    actionText="Add Activities"
                    variant="text"
                    color="primary"
                    callBack={props.addActivityCallback}
                  />
                </Grid>
              </Grid>}
            {props.remove &&
              <Grid 
                container
                xs={6}
                sm={4}
                lg={6}
                className="self-center"
                direction="row"
                justify="flex-end"
              >
                <IconLabelButton 
                  icon={<DeleteForeverRounded />}
                  actionText="Delete"
                  variant="text"
                  color="primary"
                  callBack={props.deleteLicenseReminderCallback}
                />
              </Grid>}

              {props.removeApplication &&
              <Grid 
                container
                xs={6}
                sm={4}
                lg={6}
                className="self-center"
                direction="row"
                justify="flex-end"
              >
                <IconLabelButton 
                  icon={<DeleteForeverRounded />}
                  actionText="Delete"
                  variant="text"
                  color="primary"
                  callBack={props.deleteLicenseApplicationCallback}
                />
              </Grid>}

            {props.downloadLicenseReminders &&
                <Grid 
                  container
                  xs={6}
                  sm={4}
                  lg={6}
                  className="self-center"
                  direction="row"
                  justify="flex-end"
                >
                  <IconLabelButton 
                    icon={<CloudDownload />}
                    actionText="Download Reminders"
                    variant="text"
                    color="primary"
                    callBack={props.downloadLicenseRemindersCallback}
                  />
                </Grid>}
            {props.showDownloadDSSCourseCertificate &&
              <Grid 
                container
                xs={6}
                sm={2}
                lg={6}
                className="self-center"
                direction="row"
              >
                <IconLabelButton 
                  icon={<CloudDownload />}
                  actionText="DSS"
                  textMobileAlt={true}
                  titleAlt="DSS"
                  callBack={props.downloadDSSCourseCertificateCallback}
                />
              </Grid>}
          </Grid>
        </Grid>
       {props.targetsDescription && <Typography  align="left">
        You can download targets based on the firearm type you will be using. Select one of the option below. Select a sub category of that firearm and select the type of event you want to shoot.
        <br/>You can then check out the rules and format of the shoot and download the target.
        </Typography>}
        {actionType &&
          <Grid
            container
            justify="flex-end"
            xs={2}
            sm={1}
            lg={3}
            xl={1}
          >
            {actionType}
          </Grid>}
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

SubtitleBar.defaultProps = {
  variant: 'subtitle1',
  title: 'Title',
  callBack: () => null,
  onBack: () => null,
  filterOptions: {},
  downloadActivity: false,
  downloadActivityCallback: () => Promise.resolve(null),
  addActivityCallback: () => null,
  deleteLicenseReminderCallback: () => Promise.resolve(null),
  deleteLicenseApplicationCallback: () => Promise.resolve(null)
};

SubtitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  filter: PropTypes.bool,
  dropdownSelect: PropTypes.bool,
  providers: PropTypes.array,
  printer: PropTypes.bool,
  back: PropTypes.bool,
  variant: PropTypes.oneOf(['subtitle1', 'subtitle2']),
  callBack: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  filterOptions: PropTypes.object.isRequired,
  dropdownSelectOptions: PropTypes.object.isRequired,
  downloadActivity: PropTypes.bool.isRequired,
  downloadActivityCallback: PropTypes.func.isRequired,
  addActivityCallback: PropTypes.func.isRequired,
  deleteLicenseReminderCallback: PropTypes.func.isRequired,
  deleteLicenseApplicationCallback: PropTypes.func.isRequired
};

export default SubtitleBar;
