import {
  FETCH_MEMBER_DATA_ERROR,
  SET_MEMBER_PROFILE_PICTURE,
  SET_MEMBER_DATA,
  SET_MEMBER_DEDICATED_STATUS,
  SET_MEMBER_LOYALTY_DAYS,
  SET_MEMBER_ACCOUNT_HISTORY,
  SET_MEMBER_DEDICATED_STATUS_ACTIVITIES,
  EMAIL_SET_MEMBER_DATA_2,
  EMAIL_FETCH_MEMBER_DATA_ERROR_2
} from './types';

const INITIAL_STATE = {
  memberData: {},
  errorMessage: '',
  dedicatedStatus: '',
  dedicatedStatusActivities: {},
  loyaltyProgram: {
    daysEarned: {},
    loyaltyHistory: [],
  },
  accountHistory: [],
  profilePicture: '',
  memberData2: {},
  isMemberExpired: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case FETCH_MEMBER_DATA_ERROR:
    return {
      ...state,
      memberData: {},
      errorMessage: action.payload,
    };

  case SET_MEMBER_DATA:
    return {
      ...state,
      memberData: action.payload.members,
      isMemberExpired: action.payload.isMemberExpired,
      personalAssisstant: action.payload.personalAssisstant,
      errorMessage: '',
    };
  
  case SET_MEMBER_PROFILE_PICTURE:
    return {
      ...state,
      profilePicture: action.payload,
    };

  case SET_MEMBER_DEDICATED_STATUS:
    return {
      ...state,
      dedicatedStatus: action.payload,
    };

  case SET_MEMBER_LOYALTY_DAYS:
    return {
      ...state,
      loyaltyProgram: action.payload,
    };

  case SET_MEMBER_ACCOUNT_HISTORY:
    return {
      ...state,
      accountHistory: action.payload,
    };
  
  case SET_MEMBER_DEDICATED_STATUS_ACTIVITIES:
    return {
      ...state,
      dedicatedStatusActivities: action.payload,
    };

  case EMAIL_FETCH_MEMBER_DATA_ERROR_2:
    return {
      ...state,
      memberData2: {},
    };

  case EMAIL_SET_MEMBER_DATA_2:
    return {
      ...state,
      memberData2: action.payload
    };

  default:
    return state;
  }
};

export default reducer;
