import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../../API/APIendpoint";
import GenericHeader from "../../../utils/GenericHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, InputGroup } from "react-bootstrap";
import DateRange from "@material-ui/icons/DateRange";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
toast.configure();
class StoreOwnerCommission extends Component {
  constructor(props) {
    super(props);
    this.state = {
        PaymentDate: "",
        PaymentMethod: "", 
        AccountType: "",
        Branch: "",
        Paid: "",
        PaymentTransNo: ""
    };
    this._submitForm = React.createRef();
  }
  _clickSubmitButton = e => {
    e.preventDefault();
    this._submitForm.current.click();
  }
  handleSubmit = async e => {
    e.preventDefault();
    const accountInfo = {
        ComissionId: this.props.commInfo.commissionID,
        PaymentDate: this.state.PaymentDate,
        PaymentMethod: this.state.PaymentMethod,
        Branch: this.props.commInfo.branchName,
        RepNo: this.props.commInfo.repNum,
        Paid: 1
    };
    if( this.props.commCategory.frequency === "M" && this.props.commCategory.commType === "Owner Commission") {
      Axios({
        url: `${BASE}api/commission/post/monthly/CommissionPaymentOC`,
        data: accountInfo,
        method: "post",
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        this.props.onCommissionGet(
            this.props.commissionRecord.filter(element => 
                element.commissionID !== this.props.commInfo.commissionID)
        )
        toast.success(res.data.message);
        this.props.backFunction();
      })
    } else if(this.props.commCategory.frequency === "A" && this.props.commCategory.commType === "Owner Commission") {
      Axios({
        url: `${BASE}api/commission/post/yearly/CommissionPaymentOC`,
        data: accountInfo,
        method: "post",
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        this.props.onCommissionGet(
            this.props.commissionRecord.filter(element => 
                element.commissionID !== this.props.commInfo.commissionID)
        )
        toast.success(res.data.message);
        this.props.backFunction();
      })
    } else if(this.props.commCategory.frequency === "M" && this.props.commCategory.commType === "Sales Rep Commission") {
      Axios({
        url: `${BASE}api/commission/post/monthly/CommissionPaymentSR`,
        data: accountInfo,
        method: "post",
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        this.props.onCommissionGet(
          this.props.commissionRecord.filter(element => 
              element.commissionID !== this.props.commInfo.commissionID)
        )
        toast.success(res.data.message);
        this.props.backFunction();
      }) 
    } else if(this.props.commCategory.frequency === "A" && this.props.commCategory.commType === "Sales Rep Commission") {
      Axios({
        url: `${BASE}api/commission/post/yearly/CommissionPaymentSR`,
        data: accountInfo,
        method: "post",
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        this.props.onCommissionGet(
            this.props.commissionRecord.filter(element => 
                element.commissionID !== this.props.commInfo.commissionID)
        )
        toast.success(res.data.message);
        this.props.backFunction();
      })  
    }
  };
  render() {
    const info = (row1, row2) => (
        <tbody>
          <tr >
            <td style={{ width: "29%" }}>{row1}</td>
            <td style={{ width: "48%" }} />
            <td className="font-weight-bold" style={{ width: "40%" }}>{row2}</td>
          </tr>
        </tbody>
      );
    return (
        <div>
        <form className="container">
          <div className="row">
            <GenericHeader 
            title="Store Owner Commission" 
            showBackButton
            subHeader
            backFunction={() => this.props.backFunction()}
            />
          </div>
          <table className="ml-4">
            {info("Branch", this.props.commInfo.branchName)}
            {info("Rep Number", this.props.commInfo.repNum)}
            {info("Comm Amount", this.props.commInfo.comission)}
            {info("Financial Year", parseInt(new Date().getFullYear(), 10) + 1)}
            {info("PaymentDate", 
                <InputGroup>
                    <InputGroup.Prepend>
                    <InputGroup.Text>
                        <DateRange />
                    </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        as="input"
                        type="date"
                        required
                        onInput={a => {
                            this.setState({
                                PaymentDate: a.target.value
                            });
                          }}
                    />
                </InputGroup>
            )}
            {info("Payment Method", 
              <input
                type="text"
                className="form-control"
                onInput={a => {
                  this.setState({
                    PaymentMethod: a.target.value
                  });
                }}
              />
            )}
            <button
              style={{ display: "none" }}
              onClick={e => this.handleSubmit(e)}
              ref={this._submitForm}
            />
          </table>
        </form>
        <div className="container">
          <div className="container placeAtEnd mr-4">
            <button className="btn btn-warning mt-3" 
              onClick={e => {
                this._clickSubmitButton(e);
              }}>
              Pay Comm
            </button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
    return {
        commInfo: state.CommissionReducer.commInfo,
        commissionRecord: state.CommissionReducer.commissionRecord,
        commCategory: state.CommissionCategoryReducer.commCategory
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
        onCommissionPay: repNum =>
            dispatch({
                type: actionTypes.UPDATE_REP_NUM,
                repNum: repNum
        }),
        onCommissionGet: commRecord => 
            dispatch({
                type: actionTypes.ON_COMMISSION_REC_UPDATE,
                ownerCommission: commRecord
        })
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(StoreOwnerCommission);
