import * as actionTypes from "../actions/actionTypes";

const initialState = {
  downloads: {
    isPdf: false,
    pdfLink: "",
    ListState: [],
    FileUploadStatus: "Idle",
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DOWNLOADS:
      const newDownloads = {
        ...state.downloads,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        downloads: newDownloads
      };
    default:
      return state;
  }
};

export default reducer;
