import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, FRONT, GET_DOWNLOADS_URL } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import "./styles.css";
import FilterButtons from "./uploads/FilterButtons";
import FadeLoader from "react-spinners/FadeLoader";
import { LMS_ACTIONS } from "../../store/actions/actionTypes";
class test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filter: false,
      documentType: "",
      generalButton: false,
      huntingButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false,
      resertAll: false,
      applyFilter: false,
      filteredDocumentArray: [],
      filtering: false
    };
  }
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    axios.get(BASE + GET_DOWNLOADS_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.props.onDownloadsChange(res.data, "ListState");
    });
  }
  applyFilter = () => {
    this.setState({ loading: true });
    this.setState({
      filtering: true,
      filter: false,
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    })
    axios({
      url: BASE + "api/downloads/filterByDocumentType/" + this.state.documentType,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      this.setState({
        filteredDocumentArray: res.data,
        loading: false,
      })
    })
  }
  resetFilterType = () => {
    this.setState({
      filtering: false,
      huntingButton: false,
      filter: false,
      documentType: "",
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  };
  generalDocumentFilter = () => {
    this.setState({
      documentType: "General",
      huntingButton: false,
      generalButton: true,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  narfoDocumentFilter = () => {
    this.setState({
      documentType: "Narfo",
      huntingButton: false,
      generalButton: false,
      narfoButton: true,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  dsDocumentFilter = () => {
    this.setState({
      documentType: "Dedicated Status",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: true,
      memberButton: false,
      targetButton: false
    });
  }
  memberDocumentFilter = () => {
    this.setState({
      documentType: "Member",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: true,
      targetButton: false
    });
  }
  huntingDocumentFilter = () => {
    this.setState({
      documentType: "Hunting",
      huntingButton: true,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: false
    });
  }
  targetDocumentFilter = () => {
    this.setState({
      documentType: "Target",
      huntingButton: false,
      generalButton: false,
      narfoButton: false,
      dsButton: false,
      memberButton: false,
      targetButton: true
    });
  }
  documentHeaderChange = () => {
    if (this.state.filter === true) {
      return (
        <div>
          <GenericHeader
            title="Documents"
            showBackButton
            backFunction={() => {
              this.setState({ filter: false })
            }}
          />
          <FilterButtons
            generalButton={this.state.generalButton}
            narfoButton={this.state.narfoButton}
            dsButton={this.state.dsButton}
            huntingButton={this.state.huntingButton}
            memberButton={this.state.memberButton}
            targetButton={this.state.targetButton}
            huntingDocumentFilter={this.huntingDocumentFilter}
            generalDocumentFilter={this.generalDocumentFilter}
            narfoDocumentFilter={this.narfoDocumentFilter}
            dsDocumentFilter={this.dsDocumentFilter}
            memberDocumentFilter={this.memberDocumentFilter}
            targetDocumentFilter={this.targetDocumentFilter}
            resertAll={this.resetFilterType}
            applyFilter={this.applyFilter}
          />
        </div>
      );
    } else {
      return(
        <GenericHeader 
          title="Documents" 
          showFilterButton
          onFilterClick={() =>
            this.setState({ filter: true })
          }
        />
      )
    }
  }
  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading files. Please wait ...</p>
          </div>
        </div>
      );
    }
    const documentArray = this.state.filtering === true ? this.state.filteredDocumentArray : this.props.documents.ListState
    return (
      <div className="container">
        {this.documentHeaderChange()}
        <div className="container">
          <div className="row pl-3 pr-3">
            All the relevant documentation can be found in this section. Use the filter button to search for a specific type of document. 
            You can also download all of the NARFO targets in this section.
          </div>
          <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
            <h6>Documents Links</h6>
          </div>
        </div>
        {documentArray.map((name, index) => (
          <div key={index}className="documents-list-item">
            <a
              className="text-dark anchor-removeLine col-12 mb-2 mt-2"
              href={FRONT + name.link}
              target="_"
              blank ="true"
            >
              <div className="pl-4 pr-4">
                <div className="h6">{name.name}</div>
                <div>{name.discription}</div>
              </div>
            </a>
          </div>
        ))}
        <div
          style={{ float: "left", clear: "both" }}
          ref={el => {
            this.messagesEnd = el;
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    documents: state.documentReducer.documents
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
          dispatch({ 
              type: actionTypes.UPDATE_COMMISSION_TAB, 
              currentCommissionTab: newComTab 
          }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({ 
          type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
          currentLoyaltyDaysTab: pageNum 
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onDownloadsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DOWNLOADS,
        varValue: value,
        varName: vname
      }),
    onViewPdfChange: payload =>
      dispatch({ type: actionTypes.UPDATE_DOWNLOADS, payload: payload })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(test);
