import React, { Component } from "react";
import { connect } from "react-redux";
import { GET_COURSE_MODULES_WITH_CHILDREN } from "../LMSAPIEndPoints";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import { MEMBER_ASSESSMENT } from "../../learningManagementSystem/LMSAPIEndPoints";
import GenericHeader from "../../../utils/GenericHeader";
import * as actionTypes from "../../../store/actions/actionTypes";
import { isMobile } from "react-device-detect";
import axios from "axios";

export class Lms extends Component {
  componentDidMount() {
    this.props.onCommissionTabChange("0");
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onLoyaltyDaysTabChange("0");
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
    axios.get(GET_COURSE_MODULES_WITH_CHILDREN,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const modules = res.data;
      const assessments = modules.map(mod => {
        const material = mod.courseMaterial;
        return {
          assesmentId: mod.courseModuleId,
          title: mod.name,
          grade: "O",
          type: mod.type,
          hasChildren: material ? material.length > 0 : false
        };
      });
      this.props.onAssesmentsChange(assessments);
      const material = modules.map(mod => [...mod.courseMaterial]);
      let lessons = [];
      material.forEach(mat => {
        mat.forEach(_lesson => {
          lessons.push({
            pkCourseId: _lesson.courseMaterialId,
            title: _lesson.name,
            content: _lesson.content,
            assesmnetId: _lesson.courseModuleId,
            type: _lesson.type
          });
        });
      });
      this.props.onLessonsChange(lessons);
    });
    fetch(MEMBER_ASSESSMENT + "/" + this.props.userDetails.memberNumber, {
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(res => {
        this.props.onQuizCompletionChange(res.data);
        let certificates = {};
        this.props.assesmentList.map(ass => {
          if (ass.hasChildren && ass.type === 1) {
             return certificates[`${ass.assesmentId}`] = [ass.title];
          }
          return null;
        });
        this.props.lessonList.map(lesson => {
          let arr = certificates[`${lesson.assesmnetId}`];
          if (arr) {
            const score = res.data.find(
              d => d.courseMaterialId === lesson.pkCourseId
            );
            arr.push(parseInt(score ? score.completion : 0));
           return certificates[`${lesson.assesmnetId}`] = arr;
          }
          return null;
        });
        this.props.onAssCompletionChange(certificates);
      });
  }

  displayCourseProgress = () => {
    let divs = [];
    let assComp = this.props.assesmentCompletion;
    Object.keys(assComp).forEach((_key, index) => {
      const assesment = Array.from(assComp[_key]);
      let total = assesment.length - 1;
      total = total ? total : 1;
      const progress = assesment.reduce((a, b) => {
        if (typeof b !== "string") {
          return a + b;
        }
        return a;
      }, 0);
      let assesmentName;
      if ( isMobile ) {
        if (assesment[0] === "Dedicated Hunter Assessment Modules")
          assesmentName = "DHA"
        else
          assesmentName = "DSSA"
      } else {
        assesmentName = assesment[0]
      }
      divs.push(
        <div key={index} className="col-12 pb-2">
          {this.Bar(progress / total, `${progress / total} %`, assesmentName)}
        </div>
      );
    });
    return divs;
  };

  Bar = (percent, score, title) => {
    return (
      <div className="row">
        <div className="col-3">
          <div
            className="row pb-4 pl-2 text-light"
            style={{
              background: `#79c44a`,
              borderRadius: `25px 0 0 25px`,
              height: "20px"
            }}
          >
            {title}
          </div>
        </div>
        <div className="col-9 pl-1">
          <div
            className="progress"
            style={{ borderRadius: `0 25px 25px 0`, height: "22px" }}
          >
            <div
              className="progress-bar text-right text-light pr-2"
              style={Object.assign(
                { width: `${percent}%` },
                { background: `#79c44a` },
                { fontSize: "15px" }
              )}
            >
              {score}
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleClick = section => {
    if (section === "certificate") {
      this.props.onSectionTitleChange("Course Assessment Certificates");
      this.props.onCurrentLMSTabChange(1);
    } else if (section === "material") {
      this.props.onSectionTitleChange("Course Learning Material");
      this.props.onCurrentLMSTabChange(1);
    } else {
      this.props.onSectionTitleChange("Course Assessment Quizzes");
      this.props.onCurrentLMSTabChange(1);
    }
  };

  render() {
    return (
      <div className="container">
        <div className="activity-header-container">
          <GenericHeader title="Courses" />
        </div>

        <div className="pl-4">Course Assessment Quiz Progress</div>
        <div className="container">
          <div className="row m-2">{this.displayCourseProgress()}</div>
        </div>

        <div className="container">
          <div
            className="col-12  h7 m-0 p-2 border-bottom"
            style={{ cursor: "pointer" }}
            onMouseDown={() => this.handleClick("material")}
          >
            Course Learning Material
          </div>
          <div
            className="col-12  h7 m-0 p-2 border-bottom"
            style={{ cursor: "pointer" }}
            onMouseDown={() => this.handleClick("quiz")}
          >
            Course Assessment Quizzes
          </div>
          <div
            className="col-12  h7 m-0 p-2 border-bottom"
            style={{ cursor: "pointer" }}
            onMouseDown={() => this.handleClick("certificate")}
          >
            Course Assessments Certificates
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    lessonList: state.lmsReducer.lessons,
    assesmentList: state.lmsReducer.assesments,
    currentLMSpage: state.lmsReducer.currLmsTab,
    assesmentCompletion: state.lmsReducer.assesmentCompletion,
    userDetails: state.loginReducer.userProfileDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCommissionTabChange: newComTab =>
          dispatch({ 
              type: actionTypes.UPDATE_COMMISSION_TAB, 
              currentCommissionTab: newComTab 
          }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      }),
    onLoyaltyDaysTabChange: pageNum =>
      dispatch({ 
          type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
          currentLoyaltyDaysTab: pageNum 
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onCurrActivityTabChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onMemberCoursesChange: newMemberCourses =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_MEMBER_COURSES,
        memberCourses: newMemberCourses
      }),
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      }),
    onAssCompletionChange: assesmentCompletion =>
      dispatch({
        type: actionTypes.LMS_ACTIONS.UPDATE_ASSESMENT_COMPLETION,
        assesmentCompletion: assesmentCompletion
      }),
    onQuizzesChange: newQuizzes =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZZES,
        quizzes: newQuizzes
      }),
    onLessonsChange: newLessons =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSONS,
        lessons: newLessons
      }),
    onSectionTitleChange: newSectionTitle =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_SECTION_TITLE,
        sectionTitle: newSectionTitle
      }),
    onAssesmentsChange: newAssesments =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENTS,
        assesments: newAssesments
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lms);
