import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { APPROVE_NEW_MEMBER_RENEWAL_URL, BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import defaultImg from "../images/dProfile.jpg";
import "../styles/styles.css";
class ApproveNewMembers extends Component {
  state = {
    showReasonInputBox: false,
    isApproved: false,
    isRejected: false,
    reason: "",
    Posts: [],
    userEndorsementList: [],
    image: null,
    _newMembers: this.props.newMembers
  };

  constructor(props) {
    super(props);
    //  this.findMember = this.findMember.bind(this);
    if (!this.props.loggedIn) {
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    axios
      .get(BASE + "api/MemberProfilePictures/getMemId/" + this.props.currentMember.memNo,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        const res = response.data;
        this.setState({
          image:
            res[0] === null ? null : res[0].profilePicture.toString()
        });
      })
  }

  handleSubmit = e => {
    this.props.history.push("/admin/PaymentRenewal");
    this.props.onAdminPortalChange("0");
  };

  updateRejectStatus = e => {
    axios.post(
      BASE +
        APPROVE_NEW_MEMBER_RENEWAL_URL +
        this.props.newMember.memNo +
        "/" +
        3,{},{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
    );
  };

  updatePendingToReject = () => {
    this.state._newMembers.forEach(element => {
      if(element.memNo === this.props.newMember.memNo) {
        element.status = 3 
      }      
    })
    this.props.onNewMemberObjectChange(
      this.props.pendingNMCount,
      this.state._newMembers
    )
  }

  render() {
    return (
      <div
        className="container"
        style={{ marginTop: "60px", marginBottom: "80px" }}
      >
        <div>
          <GenericHeader
            title="Membership Renewal"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/PendingNewMembers");
            }}
          />
            <div className="media border-bottom ml-3">
              <div className="my-auto">
                <img
                  src={this.state.image?this.state.image:defaultImg}
                  alt="profile"
                  className="border border-solid rounded-circle"
                  style={{
                    width:"70px",
                    height:"70px"
                  }}
                />
              </div>
              <div className="admin-dashboard-details-status">
                <span>
                  {this.props.currentMember.username
                    ? this.props.currentMember.username
                    : "-"}
                </span>{" "}
                <br />
                <span>
                  {" "}
                  {this.props.currentMember.sex
                    ? this.props.currentMember.sex
                    : "-"}
                </span>
                <br />
                <span>
                  {this.props.currentMember.inceptionDate
                    ? this.props.currentMember.inceptionDate.substring(0, 10)
                    : "-"}
                </span>
                <br />
                <span className="text-warning">Pending</span>
              </div>
            </div>
          <div
            className={`admin-dashboard-details-dashboard-info ${
              this.state.reject ? "on-reject" : ""
            } `}
          >
            <div>
              <span>Member No:</span> <br />
              <span className="information">
                {this.props.newMember.memNo ? this.props.newMember.memNo : "-"}
              </span>
            </div>
            <div>
              <span>ID Number:</span> <br />
              <span className="information">
                {this.props.newMember.idno ? this.props.newMember.idno : "-"}
              </span>
            </div>
            <div>
              <span>Cell Number:</span> <br />
              <span className="information">
                {this.props.newMember.cellNo
                  ? this.props.newMember.cellNo
                  : "-"}
              </span>
            </div>
            <div>
              <span>Address:</span> <br />
              <span className="information">
                {this.props.newMember.physicalAddress
                  ? this.props.newMember.physicalAddress
                  : "-"}
              </span>
            </div>
            <div>
              <span>Ethnicity:</span> <br />
              <span className="information">
                {" "}
                {this.props.newMember.ethinicity
                  ? this.props.newMember.ethinicity
                  : "-"}
              </span>
            </div>
            <div>
              <span>Role:</span> <br />
              <span className="information">
                {this.props.newMember.memType
                  ? this.props.newMember.memType
                  : "-"}
              </span>
            </div>
            <div>
              <span>Inception Date:</span> <br />
              <span className="information">
                {this.props.newMember.inceptionDate
                  ? this.props.newMember.inceptionDate.substring(0, 10)
                  : "-"}
              </span>
            </div>
            <div>
              <span>Expiry Date:</span> <br />
              <span className="information">
                {this.props.newMember.expiryDate
                  ? this.props.newMember.expiryDate.substring(0, 10)
                  : "-"}
              </span>
            </div>
            <div>
              <span>Payment:</span> <br />
              <span className="information">
                {this.props.newMember.association
                  ? this.props.newMember.association
                  : "Manual"}
              </span>
            </div>
          </div>
          <div className="row p-3 border mt-2 rounded-top fixed-bottom bg-light">
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-outline-success border-dark text-dark w-75"
                onClick={() => {
                  this.props.history.push("/PaymentNewMember");
                }}
              >
                Approve
              </button>
            </div>
            <div className="col d-flex justify-content-center">
              <button
                className="btn btn-outline-danger border-dark text-dark w-75"
                onClick={() => {
                  this.updateRejectStatus();
                  this.updatePendingToReject();
                  this.props.history.push("/admin/PendingNewMembers");
                }}
              >
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    names: state.membersAreaReducer.membersArea.memberName,
    members: state.MemberViewReducer.MemberList,
    currentMember: state.MemberViewReducer.MemberView,
    userProfileDetails: state.loginReducer.userProfileDetails,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    memberList: state.dashBoardReducer.totalMembers.members,
    newMember: state.dashBoardReducer.membersPending,
    newMembers: state.dashBoardReducer.newMembers.newMembers,
    pendingNMCount: state.dashBoardReducer.newMembers.pendingNMCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      }),
    onDataChange: (data, name) => {
      return dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: data,
        varName: name
      });
    },
    onNewMemberObjectChange: (pendingCount, memArray) =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.GET_ALL_PENDING_MEMBERS_URL,
        newMembers: {
          pendingNMCount: pendingCount,
          newMembers: memArray
        },
      }),
    onResetViewMembers: () => {
      return dispatch({
        type: actionTypes.RESET_MEMBERS_VIEWED_ACTIVITY
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveNewMembers);
