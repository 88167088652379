import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography
} from '@material-ui/core';
import {
  SubtitleBar,
  Overview
} from '../index';
import GenericTable from '../GenericTable/GenericTable';
import Loader from '../Loader/Loader';
import ViewLicenseReminders from './VewLicenseReminders';
import LicenceActions from '../../store/Licence/actions';
import licenceService from '../../services/licence.service';
import AddLicenseReminder from './AddLicenseReminder';

const columns = [
  { field: 'firearm', headerName: 'Firearm Name' },
  { field: 'custom', headerName: 'Type' },
  { field: 'serialNumber', headerName: 'Serial Number' },
  { field: 'licenseNo', headerName: 'License Number' },
  { field: 'issueDate', headerName: 'Issue Date' },
  { field: 'remainingDays', headerName: 'Expires In' }
];
const columnsMobile = [
  { field: 'licenseNo', headerName: 'License Number' },
  { field: 'issueDate', headerName: 'Issue Date' },
];

class LicenseRemindersDetails extends Component {
  constructor(props) {
    super(props);
    this.licenceService = licenceService(this.props.token);
    this.licenceActions = this.props.licenceActions(this.licenceService);

    this.state = {
      viewLicense: false,
      addLicense: false,
      licenseReminder: {},
      _firearmLicenseList: [],
      loading: false
    };
  }
  static getDerivedStateFromProps(nextProps) {
    let __firearmLicense = [];
    __firearmLicense = nextProps.firearmLicenceList;
    if(__firearmLicense.legnth !== 0) {
      __firearmLicense.map((item) => {
        if(item.custom === 'false' || item.custom === 'Firearm'){
          item.custom = 'Firearm';
        } else {
          item.custom = 'Custom';
        }
        item.issued = item.issued.split(' ')[0];
        if(!item.remainingDays.toString().includes('days')){
          item.remainingDays = `${item.remainingDays} days`;
        }
      });
    }
    
    return { _firearmLicenseList: __firearmLicense };
  }

  render() {

    return (
      this.state.loading && <Loader /> ||
      this.state.viewLicense && <ViewLicenseReminders 
        backCallback={() => this.setState({licenseReminder: {}, viewLicense: false})}
        licenseReminder={this.state.licenseReminder}
        updateLicenseReminderCallback={this.props.updateLicenseReminderCallback}
        deleteLicenseReminderCallback={this.props.deleteLicenseReminderCallback}
        section={this.props.sectionLookup.map(g => ({
          value: g.section,
          label: g.section
        }))}
      /> ||
      this.state.addLicense && <AddLicenseReminder 
        backCallback={() => this.setState({licenseReminder: {}, addLicense: false})}
        section={this.props.sectionLookup.map(g => ({
          value: g.section,
          label: g.section
        }))}
        addLicenseReminderCallback={this.props.addLicenseReminderCallback}
        memNo={this.props.memNo}
        getFirearmLicence={this.props.getFirearmLicence}
      /> ||
    <div>
      <Overview>
        <Typography align="justify">
      Please note that it remains the responsibility of each member to timelessly renew their firearm license in accordance with the Firearms Control Act 60 of 2000 and the Regulations.
      The system was designed to send you a reminder email at 120 days before the expiry of the specific licence. NARFO does not take any responsibility for any reminder that the member failed to receive or a failure of the system.
        </Typography>
      </Overview>
      <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mt-4">
        <SubtitleBar
          variant="subtitle2"
          title={this.props.isMobile ? 'Reminders' : 'My Reminders'}
          addLicenseReminders={() => this.setState({ addLicense: true })}
          downloadLicenseReminders
          downloadLicenseRemindersCallback={
            () => this.props.downloadLicenseRemindersCallback(this.state._firearmLicenseList, '#auto-table')
          }
        />
        
        <div style={{display: 'block', margin:'0 auto', overflowX:'auto', whiteSpace:'nowrap' }}>

        <GenericTable
          columns={this.props.isMobile ? columnsMobile : columns}
          rows={this.state._firearmLicenseList}
          view
          onViewCallBack={(item) => {
            this.setState({ loading: true });
            this.licenceActions.fetchMemberLicenseReminderRecord(item.licenseNo)
              .then(() => this.setState({
                viewLicense: true,
                loading: false
              }));
          }}
        />
        </div>
      </div>
    </div>
    );
  }
}

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchMemberLicenseReminderRecord: (id) => dispatch(action.fetchMemberLicenseReminderRecord(id))
  };
};
  
const mapDispatchToProps = dispatch => ({
  licenceActions: initialiseLicenceActions(dispatch),
});

const mapStateToProps = state => ({
	token: state.AuthReducer.token,
	firearmLicenceList: state.LicenceReducer.firearmLicence,
	sectionLookup: state.nLookupReduder.sectionLookup,
	memNo: state.AuthReducer.memNo
});

LicenseRemindersDetails.propTypes ={
  downloadLicenseRemindersCallback: PropTypes.func.isRequired
};

LicenseRemindersDetails.defaultProps = {
  downloadLicenseRemindersCallback: () => Promise.resolve(null)
};

export default connect(mapStateToProps, mapDispatchToProps)(LicenseRemindersDetails);
