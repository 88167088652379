import React, { Component } from "react";
import Axios from "axios";
import { BASE,FRONT } from "../../../../API/APIendpoint";
export default class ActivityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityLink: "",
      docName: "",
      type: ""
    };
  }
  componentDidMount() {
    Axios.get(BASE + "api/Activities/" + this.props.activity.activityId,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(
      res => {
        this.setState({
          activityLink: FRONT + res.data.link
        });
      }
    );
  }
  render() {
    const info = (row1, row2) => (
      <div className="row mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    if (this.props.activity)
      return (
        <div
          className={this.props.isPartner? "" :"container"}
          style={{ height: "400px", marginLeft:"10px", overflow: "scroll" }}
        >
          {info("Member Number:", this.props.activity.memNo)}
          {info("Activity ID:", `Activity ${this.props.activity.activityId}`)}
          {info(
            "Activity Description:",
            !this.props.activity.activityDescription
              ? "null"
              : this.props.activity.activityDescription
          )}
          {info("Firearm Type:", this.props.activity.firearmType)}
          {info("Caliber:", this.props.activity.caliber)}
          {info("Association:", this.props.activity.association)}
          { this.props.activity.status ==="Rejected" && info("Rejection Reason:", this.props.activity.rejectReason)}
          <div className="col-12">
            <p className="m-0">Score Document(s):</p>
            {this.state.activityLink === "NULL" || !this.state.activityLink ? (
              <div className="font-weight-bold text-danger">Null: </div>
            ) : (
              <a
                  className="text-primary font-weight-bold mb-2 mt-2"
                  href={this.state.activityLink}
                  target="_"
                  blank
                >
                  Document
              </a>
            )}
          </div>
        </div>
      );
    else return null;
  }
}
