import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE, FRONT, GET_DEDICATEDTYPE, POST_DEDICATED_STATUS_VIEW } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import * as DSApplicationActions from "../../dedicatedStatusApplication/DedicatedStatusApplicationActions";
import * as DedicatedStatusApplicationActionTypes from "../DedicatedStatusApplicationActionTypes";

class Buttons extends React.Component {
    constructor(props) {
        super(props);
        this.handleBack = this.handleBack.bind(this);
        this.state = {
            Title: "Dedicated Status"
        };
    }

    handleBack = e => {
        if (this.props.dedicated.Page === "timeline") {
            this.setState({ Title: "Dedicated Status Application" });
            this.props.onDedicatedChange("View", "Page");
        } else {
            this.setState({ Title: "Dedicated Status" });
            this.props.onDedicatedChange("Home", "Page");
        }
    };

    submit = async () => {
        const {
            Ph,
            Ods,
            PhRegNo,
            PhAssociation,
            PhProvince,
            PhExpiryDate,
            PhAttachment,
            dsType,
            OdsAssociationName,
            OdsFarNo,
            OdsDsNo,
            OdsExpiryDate,
            Motivation,
            NonParticipationReason,
            applicationStatus,
            OdsAttachment
        } = this.props.DSApplicationState;
        let data = new FormData();
        if (this.props.DSApplicationState.dsType === "Dedicated Hunter") {
            data.append("PhRegNo", PhRegNo);
            data.append("PhAssociation", PhAssociation);
            data.append("PhProvince", PhProvince);
            data.append("PhExpiryDate", PhExpiryDate);
            data.append("PhAttachment", PhAttachment);
        }
        data.append("MemNo", this.props.memNo);
        data.append("DsType", dsType);
        data.append("Motivation", Motivation);
        data.append("NonParticipationReason", NonParticipationReason);
        data.append("OdsAssociationName", OdsAssociationName);
        data.append("OdsFarNo", OdsFarNo);
        data.append("OdsDsNo", OdsDsNo);
        data.append("OdsExpiryDate", OdsExpiryDate);
        data.append("Ods", Ods);
        data.append("Ph", Ph);
        data.append("OdsAttachment", OdsAttachment);
        data.append("BaseURL", FRONT);
        this.props.setDSVariables("showModal", true);
        await this.props.submitApplication(data);

        if (applicationStatus.response) {
            if (applicationStatus.response.status === 200) {
                //already have an approved or pending ds application
                this.props.setDSVariables("showModal", false);
            }
            if (applicationStatus.response.status === 201) {
                //successful - redirect
                this.props.setDSVariables("showModal", false);
                // this.props.redirectToDSList();
            }
        }

        if (applicationStatus.error) {
            //show the error
        }
        const user = {
            memNo: this.props.memberState.isAdmin
                ? this.props.MemberV.memNo
                : this.props.userProfileDetails.memberNumber
        };
        axios({
            url: BASE + POST_DEDICATED_STATUS_VIEW,
            data: user,
            method: "post",
            headers: {
                "Content-type": "application/json",
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
        })
            .then(res => {
                if (res.data.status === "success") {
                    this.props.onDedicatedChange(res.data.dedicated, "Application");
                }
            })
            .catch(function (error) {
                if (error.status === 400);
            });

        axios.get(BASE + GET_DEDICATEDTYPE + this.props.memNo, {
            headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
        }).then(res => {
            this.props.DSApplicationActions.setDSVariables("DH", res.data.hunting);
            this.props.DSApplicationActions.setDSVariables("DS", res.data.sport);
            this.props.DSApplicationActions.setDSVariables("DS_DH", res.data.both);
        });
        this.props.setDSVariables("showModal", false);
        this.props.clearState();
        this.props.DSApplicationActions.setDSVariables("NonParticipationReason", "");
        this.props.changeStep(1)

        if (this.props.DSApplicationState.DS === true) {
            this.props.toggleDS("Dedicated Hunter")
        } else {
            this.props.toggleDS("Dedicated Sports Person")
        }
        this.handleBack();
    };

    validateMotivation = () => {
        const {
            Participation,
            Motivation,
            NonParticipationReason
        } = this.props.DSApplicationState;
        if (!Motivation) {
            this.props.setDSVariables("error", "Please fill the motivation field");
        }
        if (!NonParticipationReason && Participation === "No") {
            this.props.setDSVariables("error", "Please fill the motivation field");
        }
    };

    validateDetails = () => {
        const {
            Ph,
            PhRegNo,
            PhAssociation,
            PhProvince,
            PhExpiryDate,
            PhAttachment
        } = this.props.DSApplicationState;

        const {
            Ods,
            OdsAssociationName,
            OdsFarNo,
            OdsDsNo,
            OdsExpiryDate,
            Assessments,
        } = this.props.DSApplicationState;
        if (Ph === "Yes") {
            if (!PhRegNo || !PhAssociation || !PhProvince || !PhExpiryDate || !PhAttachment) {
                this.props.setDSVariables(
                    "detailsError",
                    "Please fill in all the information about your Proffessional Hunter accreditation!"
                );
            } else {
                this.props.setDSVariables("detailsError", null);
            }
        }
        if (Ods === "Yes") {
            if (!OdsAssociationName || !OdsFarNo || !OdsDsNo || !OdsExpiryDate) {
                this.props.setDSVariables(
                    "detailsError",
                    "Please fill in all the information about your Dedicated Status!"
                );
            } else {
                this.props.setDSVariables("detailsError", null);
            }
        }

        if (!Assessments) {
            this.props.setDSVariables(
                "detailsError",
                "Please select if you have completed the assessments or not"
            );
        } else {
            this.props.setDSVariables("detailsError", null);
        }
    };

    next = () => {
        this.validateDetails();
        if (this.props.currentStep === 2) {
            this.validateMotivation();
        }
        if (
            this.props.DSApplicationState.detailsError === null &&
            this.props.DSApplicationState.motivationError === null
        ) {
            this.props.changeStep(this.props.currentStep + 1);
        }
    };
    render() {
        const { currentStep, changeStep } = this.props;
        return (
            <div className="container fixed-bottom border rounded-top bg-white p-2">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        {currentStep > 1 && (
                            <button
                                className="btn btn-warning text-white"
                                disabled={currentStep === 1}
                                onClick={() => changeStep(currentStep - 1)}
                            >
                                Previous
                            </button>
                        )}
                    </div>
                    {currentStep === 3 ? (
                        <div className="col d-flex justify-content-center">
                            <button
                                className="btn btn-warning text-white"
                                onClick={async () => await this.submit()}
                            >
                                Submit
              </button>
                        </div>
                    ) : (
                            <div className="col d-flex justify-content-center">
                                <button
                                    className="btn btn-warning text-white"
                                    disabled={currentStep === 3}
                                    onClick={this.next}
                                >
                                    Next
              </button>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dedicated: state.dedicatedStatusReducer.dedicatedStatus,
        userProfileDetails: state.loginReducer.userProfileDetails,
        memberState: state.loginReducer.memberState,
        MemberV: state.MemberViewReducer.MemberView,
        memNo: state.loginReducer.userProfileDetails.memberNumber,
        DSApplicationState: state.DSApplicationState
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onDedicatedChange: (value, vname) =>
            dispatch({
                type: actionTypes.UPDATE_DEDICATED_STATUS,
                varValue: value,
                varName: vname
            }),
        DSApplicationActions: bindActionCreators(DSApplicationActions, dispatch),
        clearState: () => {
            dispatch({
                type: DedicatedStatusApplicationActionTypes.CLEAR_STATE
            })
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Buttons);
