
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Loader,
  AddEndorsement,
  MyEndorsements,
} from '../../components';
import Container from '../../hoc/Container';
import {printEndorsement} from '../../shared/pdfHelpers';
import MemberActions from '../../store/Profile/actions';
import EndorsementActions from '../../store/Endorsements/actions';
import DedicatedService from '../../services/dedicatedstatus.service';
import EndorsementService from '../../services/endorsements.service';
import MemberService from '../../services/member.service';

class Endorsements extends Component {
  constructor(props) {
    super(props);
    this.endorsementService = EndorsementService(this.props.token);
    this.endorsementActions = this.props.endorsementActions(this.endorsementService);
    this.dedicatedService = DedicatedService(this.props.token);
    this.memberService = MemberService(this.props.token);
    this.memberActions = this.props.memberActions(this.memberService, this.dedicatedService);
    

    this.state = {
      loading: false,
      addEndorsement: false
    };
  }

  componentDidMount() {
    const memberId = this.props.memNo;

    this.setState({loading: true});
    this.endorsementActions.fetchEndorsements(memberId)
      .finally(() => this.setState({loading:false}));
    this.memberActions.fetchMemberData(memberId)
      .then(() => this.memberActions.fetchMemberDedicatedStatus(memberId))
      .finally(() => {
        this.setState({loading: false});
      });
  }

  downloadMemberEndorsement = (endorsementData) => {
    const memberId = this.props.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.memberService.getAllImages().then((images) =>
          printEndorsement(
            this.props.memberData, endorsementData, images.data.nnfo, base64QRCode,
            images.data.pdfSign, images.data.pdflogo, images.data.signature2
          )
        );
      });
  }

  addEndorsement = () => {
    this.setState({addEndorsement: true});
  }

  onViewMyEndorsements = () => {
    this.setState({addEndorsement: false});
    this.endorsementActions.fetchEndorsements(this.props.memNo);
  }
  
  render() {
    return (
      <Container title="Endorsements">
        {this.state.loading && <Loader/> ||
        this.state.addEndorsement && <AddEndorsement
          backCallback={this.onViewMyEndorsements}
          actionTypes={this.props.firearmActionTypeLookup['actions'].map(e => ({
            value: e.action1,
            label: e.action1
          }))}
          firearmTypes={this.props.firearmActionTypeLookup['fireArmTypes'].map(f => ({
            value: f.type,
            label: f.type
          }))}
          sectionTypes={this.props.sectionLookup.map(g => ({
            value: g.section,
            label: g.section
          })).filter(function(obj) {
            if (
              obj.label === 'Section 13' || obj.label === 'Section 15' || obj.label === 'Section 16'
            ){
              return obj;
            }
          })
          }
          applicationTypes={this.props.applicationTypeLookup.map(h => ({
            value: h.applicationType,
            label: h.applicationType
          }))}
          endorsementPurposeType={this.props.endorsementPurposeType.map(h => ({
            value: h.purposeType,
            label: h.purposeType
          }))}
        >

        </AddEndorsement> ||
        <MyEndorsements 
          endorsements={this.props.endorsements}
          addEndorsementCallback={this.addEndorsement}
          downloadMemberEndorsementCallback={this.downloadMemberEndorsement}
        />
        }
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  endorsements: state.nEndorsementReducer.endorsements,
  memberData: state.ProfileReducer.memberData,
  dedicatedStatus: state.ProfileReducer.dedicatedStatus,
  firearmActionTypeLookup: state.nLookupReduder.firearmActionType,
  sectionLookup: state.nLookupReduder.sectionLookup,
  applicationTypeLookup: state.nLookupReduder.endorsementApplicationType,
  endorsementPurposeType: state.nLookupReduder.endorsementPurposeType
});

const initEndorsementActions = dispatch => endorsementService => {
  const actions = EndorsementActions(endorsementService);
  return {
    fetchEndorsements: (id) => dispatch(actions.fetchEndorsements(id))
  };
};

const initialiseMemberActions = dispatch => (memberService, dedicatedStatusService) => {
  const action = MemberActions(memberService, dedicatedStatusService);
  return {
    fetchMemberData: (id) => dispatch(action.fetchMemberData(id)),
    fetchMemberDedicatedStatus: (id) => dispatch(action.fetchMemberDedicatedStatus(id)),
  };
};

const mapDispatchToProps = dispatch => ({
  endorsementActions: initEndorsementActions(dispatch),
  memberActions: initialiseMemberActions(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Endorsements);