import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { Form, InputGroup } from "react-bootstrap";
import DateRange from "@material-ui/icons/DateRange";
import { BASE } from "../../../API/APIendpoint";
import Axios from "axios";
import { toast } from 'react-toastify';
import Radio from "@material-ui/core/Radio";
import CommissionTemplate from './CommissionTemplate';

toast.configure();
class ProcessCommissionPayment extends Component {
    constructor(props){
        super(props);
        this.state = {
          showCommissionTemplate:false,
          frequency: "",
          commType: "",
          _invalidCommissionTypeSelectMsg: "",
          _invalidFrequencySelectMsg: "",
          _invalidComDateSelectMsg: "",
          _comDate: "",
          _Commissions: [],
          _printCommissionSelect: false,
          _processCommissionSelect: true,
          _comDateTrack: ""
        }
    }
    componentWillUnmount() {
      this.props.onMemberStateChange(false, "isAdminMemView");
    }
    updateCommissionCategory = e => {
      e.preventDefault();
      if(this.state.frequency === "")
        this.setState({_invalidFrequencySelectMsg: "Please select Commission frequency"})
      if(this.state.commType === "")
        this.setState({_invalidCommissionTypeSelectMsg: "Please select commission type"})
      if(this.state.frequency !== "" && this.state.commType !== "") {
        this.props.onCommissionCategorySelect(this.state.commType, this.state.frequency);
        this.setState({
          _invalidFrequencySelectMsg: "",
          _invalidCommissionTypeSelectMsg: "",
          showCommissionTemplate:true
        })
      }
    }
    
    backFunction = () =>{
      this.setState({showCommissionTemplate:false});
    }
    onCommissionsGet = e => {
      e.preventDefault();
      this.setState({_invalidComDateSelectMsg: ""})
      if(this.state._comDateTrack === "")
        this.setState({_invalidComDateSelectMsg: "Please Select Commission Date"})
      Axios({
        url: `${BASE}api/commission/GetCommissionByDate/${this.state._comDateTrack}`,
        method: "get",
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
        .then(res => {
          if(res.data.message){
            toast.success("Commissions Found")
            this.props.onMothlyCommissionGet(res.data.repsMemComm);
            this.props.history.push("/MainSalesCommission");
            this.setState({
              _invalidComDateSelectMsg: ""
            })
          } else {
            toast.warning("No Commissions Found")
          }
        });
    }
    render() {
      const info = (row1, row2) => (
        <div className="row  mt-2 mb-1">
          <div className="col-12">{row1} </div>
          <div className="col-12 font-weight-bold">{row2}</div>
        </div>
      );
      return (
        <div className="container" style={{ marginTop: "60px" }}>
          <div className="row">
            <GenericHeader 
              title="Process Commission Payment"
            />
          </div>
          
         {!this.state.showCommissionTemplate &&  <div>
          <div>Choose Commission Action</div>
            <Radio
              checked={this.state._processCommissionSelect}
              onChange={() =>this.setState({_processCommissionSelect: true, _printCommissionSelect: false})}
            />
            Process Commission Payments
            <Radio
              checked={this.state._printCommissionSelect}
              onChange={() =>this.setState({_printCommissionSelect: true, _processCommissionSelect: false})}
            />
            Print Commissions
          </div>}

        {!this.state.showCommissionTemplate && <div> 
              {this.state._processCommissionSelect ? (
                <div>
                  {info("Choose Commission Frequency",
                <select
                  className="form-control input-sm m-0"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      frequency: a.target.value,
                      _invalidFrequencySelectMsg: ""
                    });
                  }}
                >
                  <option value="">Select Commission Frequency</option>
                  <option value="M">Monthly</option>
                  <option value="A">Yearly</option>
                </select>
              )}
              <div className="text-danger">{this.state._invalidFrequencySelectMsg}</div>
              {info("Choose Commission Type",
                <select
                  className="form-control input-sm m-0"
                  aria-describedby="emailHelp"
                  onInput={a => {
                    this.setState({
                      commType: a.target.value,
                      _invalidCommissionTypeSelectMsg: ""
                    });
                  }}
                >
                  <option value="">Select Commission Type</option>
                  <option value="Owner Commission">Owner Commission</option>
                  <option value="Sales Rep Commission">Sales Rep Commission</option>
                </select>
              )}
              <div className="text-danger">{this.state._invalidCommissionTypeSelectMsg}</div>
              <div className="placeAtEnd">
                <button className="btn btn-warning mt-3" 
                  onClick={e => {
                    this.updateCommissionCategory(e); 
                  }}
                >
                  PAYMENTS PAGE
                </button> 
              </div>
            </div>
          ) : (
            <div>
              <div className="mt-2">Select Month of Commissions</div>
              <InputGroup className="mb-3 pl-0" id="datepicker">
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <DateRange />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  as="input"
                  type="date"
                  required
                  onChange={event =>
                    this.setState({_comDateTrack: event.target.value})
                  }
                />
              </InputGroup>
              <div className="text-danger">{this.state._invalidComDateSelectMsg}</div>
              <div className="placeAtEnd">
                <button className="btn btn-warning mt-3" 
                  onClick={e => this.onCommissionsGet(e)}
                >
                  PRINTING PAGE
                </button> 
              </div>
            </div>
          )}
        </div>}

          {/* Commission template */}
          { this.state.showCommissionTemplate && <CommissionTemplate backFunction = {this.backFunction}/>}
        </div>
      );
    }
}
const mapStateToProps = state => {
  return {
    ownerCommission: state.CommissionReducer.ownerCommission,
    isAdmin: state.loginReducer.memberState.isAdmin
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value
      }),
    onMothlyCommissionGet: mCom =>
        dispatch({ 
            type: actionTypes.MONTHLY_COMMISSION, 
            monthlyCommission: mCom 
        }),
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onCommissionCategorySelect: (commType, frequency) =>
      dispatch({
        type: actionTypes.UPDATE_COMMISSION_CATEGORY,
        commCategory: {
          commType: commType,
          frequency: frequency
        }
      }),
    onCommissionGet: commRecord => 
      dispatch({
        type: actionTypes.ON_COMMISSION_REC_UPDATE,
        ownerCommission: commRecord
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProcessCommissionPayment);