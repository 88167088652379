import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Typography
} from '@material-ui/core';
import {
  SubtitleBar,
  Overview
} from '../index';
import GenericTable from '../GenericTable/GenericTable';
import Loader from '../Loader/Loader';
import ViewLicenseApplications from './ViewLicenseApplications';
import LicenceActions from '../../store/Licence/actions';
import licenceService from '../../services/licence.service';
import AddLicenseApplication from './AddLicenseApplication';
import { PersonAddDisabledOutlined } from '@material-ui/icons';

const columns = [
  // { field: 'referenceNumber', headerName: 'Reference Number' },
  { field: 'applicationType', headerName: 'Application Type' },
  { field: 'serialNumber', headerName: 'Serial Number' },
  { field: 'applicationNumber', headerName: 'Application Number' },
  // { field: 'idorInstNumber', headerName: 'ID/Inst Number' },
  // { field: 'calibre', headerName: 'Calibre' },
  // { field: 'make', headerName: 'Make' },
  { field: 'statusDate', headerName: 'Status Date' },
  { field: 'status', headerName: 'Status' },
  { field: 'statusDescription', headerName: 'Status Description' },
  { field: 'nextStep', headerName: 'Next Step' }
];

const columnsMobile = [
  { field: 'serialNumber', headerName: 'Serial Number' },
  { field: 'status', headerName: 'Status' },
];

class LicenseApplicationDetails extends Component {
  constructor(props) {
    super(props);
    this.licenceService = licenceService(this.props.token);
    this.licenceActions = this.props.licenceActions(this.licenceService);

    this.state = {
      ViewLicenseApplication: false,
      addLicense: false,
      addLicenseApplication: false,
      licenseApplication: {},
      firearmLicenseApplications: [],
      loading: false
    };
  }
  
  backCallback(application){

    if(application){
      const updatedApplication = [...this.state.firearmLicenseApplications];
      
      updatedApplication.push(application)
  
      this.setState({firearmLicenseApplications: updatedApplication, addLicenseApplication: false})
    }
    else
    this.setState({licenseApplication:{}, addLicenseApplication: false})

  }

  deleteCallback(application){
    const updatedApplication = this.state.firearmLicenseApplications.filter(app => app.applicationNumber !== application.applicationNumber);
    this.setState({firearmLicenseApplications: updatedApplication, ViewLicenseApplication: false})

  }
  componentDidMount(){
    
    this.props.getFirearmsLicenceApplication.then((response) =>{
      
      this.setState({firearmLicenseApplications : response.data});
    });
  }
  
  static getDerivedStateFromProps(nextProps) {
    let _firearmLicenseApplications = [];
    _firearmLicenseApplications = nextProps.firearmLicenseApplications;

    if(_firearmLicenseApplications?.length > 0)
      return { firearmLicenseApplications: _firearmLicenseApplications };
    else
      return null;
      
  }

  render() {
    return (
      this.state.loading && <Loader /> ||
      this.state.ViewLicenseApplication && <ViewLicenseApplications 
        backCallback={(app) => this.setState({ViewLicenseApplication:false})}
        licenseApplication={this.state.licenseApplication}
        timeline={[]}
        deleteLicenseApplicationCallback={this.props.deleteLicenseApplicationCallback}
      /> || 
     
      
      this.state.addLicenseApplication && <AddLicenseApplication
        backCallback={(app) => this.backCallback(app)}
        addLicenseApplicationCallback={this.props.addLicenseApplicationCallback}
        memNo={this.props.memNo}
        getFirearmsLicenceApplication={this.props.getFirearmsLicenceApplication}
      /> ||
    <div>
      <Overview>
      <Typography align="justify" style={{marginTop:'-20px'}}>
        <p><strong>For any enquiries regarding the status of your firearm application or renewal please complete the following:</strong> <br/></p>
        <div>
          <p>&ensp; - If you have received a reference number allocated to your application / renewal, please provide <strong>Reference Number</strong> and <strong>ID/Institution Number</strong> OR <br/></p>

          <p>&ensp; - If you want to refine your search, please provide your <strong>firearm serial number</strong>  and <strong>Reference Number</strong> OR <br/></p>

          <p>&ensp; - If you did not recieve a reference number per sms when you applied or renewed your license, please provide your <strong>ID/Institution Number</strong> as well as your <strong>firearm serial number</strong>.</p>
        </div>
        </Typography><br/>
      </Overview>
      <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mt-4">
        <SubtitleBar
          variant="subtitle2"
          title={this.props.isMobile ? 'Applications' : 'My Applications'}
          addLicenseApplication={() => this.setState({ addLicenseApplication: true })}
        />

        <GenericTable
          columns={this.props.isMobile ? columnsMobile : columns}
          rows={this.state.firearmLicenseApplications}
          view
          onViewCallBack={(item) => {
            this.setState({ ViewLicenseApplication: true, licenseApplication: item});
          }}
        />
      </div>
    </div>
    );
  }
}

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchMemberLicenseApplications: (memNo) => dispatch(action.fetchMemberLicenseApplications(memNo))
  };
};
  
const mapDispatchToProps = dispatch => ({
  licenceActions: initialiseLicenceActions(dispatch),
});

const mapStateToProps = state => ({
	token: state.AuthReducer.token,
	// firearmLicenseApplications: state.LicenceReducer.firearmLicenseApplications,
	memNo: state.AuthReducer.memNo
});

export default connect(mapStateToProps, mapDispatchToProps)(LicenseApplicationDetails);
