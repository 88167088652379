import React from 'react';
import {
  Button,
  IconButton,
  Typography
} from '@material-ui/core/';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.buttonSecondary,
  },
}));

const IconLabelButton = ({helpText, actionText, icon, color, variant, full, disabled, callBack, dsApplications, quizProgress, textMobileAlt, titleAlt,editButton}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div className={dsApplications ? null : null}>
      {helpText && (<Typography variant="body1" align="left">{helpText}</Typography>)}
      { matches && !full ?
        textMobileAlt ?
          <Button
            variant="contained"
            color={color}
            size="small"
            onClick={() => callBack()}
            className={classes.button}
            endIcon={icon}
          >
            {titleAlt}
          </Button> :
          <IconButton onClick={() => callBack()}>
            {icon}
          </IconButton> :
        <Button
          variant={variant}
          color={color}
          size="large"
          endIcon={icon}
          className={classes.button}
          onClick={() => callBack()}
          style={dsApplications ? {width: '340px'} : quizProgress ? {width: '110px'} : null}
          disabled={disabled}
        >
          {actionText}
        </Button>
      }

      {editButton && matches && <Button
            variant="contained"
            color={color}
            size="small"
            onClick={() => callBack()}
            className={classes.button}
            endIcon={icon}
          >
            {actionText}
          </Button> }
    </div>
  );
};

IconLabelButton.defaultProps = {
  color: 'secondary',
  callBack: () => null,
  disabled: false,
  full: false,
  variant: 'contained',
};

IconLabelButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  callBack: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  full: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['text', 'contained']).isRequired,
};

export default IconLabelButton;