import * as actionTypes from "../actions/actionTypes";

const initialState = {
    monthlyCommission: [],
    bankingDetails: [],
    bankEditObject: {},
    isPartner: false,
    bankDetailsViewObj: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MONTHLY_COMMISSION:
      if(!action.monthlyCommission) {
        return state;
      } else {
        return {
          ...state,
          monthlyCommission: action.monthlyCommission
        };
      }
    case actionTypes.BANKING_DETAILS_UPDATE:
      if(!action.bankingDetails){
        return state;
      } else {
        return {
          ...state,
          bankingDetails: action.bankingDetails
        };
      }
    case actionTypes.BANKING_DETAILS_EDIT:
      if(!action.bankEditObject){
        return state;
      } else {
        return {
          ...state,
          bankEditObject: action.bankEditObject
        };
      }
    case actionTypes.IS_PARTNER_UPDATE:
      if(!action.isPartner){
        return state;
      } else {
        return {
          ...state,
          isPartner: action.isPartner
        };
      }
    case actionTypes.UPDATE_BANKING_DETAILS_VIEW:
      if(!action.bankDetailsViewObj){
        return state;
      } else {
        return {
          ...state,
          bankDetailsViewObj: action.bankDetailsViewObj
        };
      }
    default:
      return state;
  }
};
export default reducer;
