import React, { Component, Fragment } from "react";
import PropType from "prop-types";
import { connect } from "react-redux";
import MainMenu from "./MainMenu";
import AddMenu from "./AddMenu";
import EditMenu from "./EditMenu";
import AddModule from "./AddModule";
import BulkMainMenu from "./BulkMainMenu";
import BulkDelete from "./BulkDelete";
import BulkMoveToFolder from "./BulkMoveToFolder";
import {
  fetchCourseModules
} from "../CourseComponents/Material/CourseMaterialActions";

class LMSMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: 0,
      numberOfSelection: 0,
      selectedComponentsType: "unknown"
    }
  }

  refresh = () => {
    this.props.fetchCourseModules();
    this.props.changeMenu(0, 0);
  }

  onChangeMenu = () => {
    switch (this.state.menu) {
      case 0:
        return <MainMenu
          isFolder={this.props.isFolder}
          onClick={this.ChangeMenu}
          moduleTypeName={this.props.moduleTypeName} />;
      case 1:
        return <AddModule
          onClick={this.ChangeMenu}
          fetchCourseModules={this.props.fetchCourseModules}
          type={this.props.type}
        />
      case 2:
        return <BulkDelete
          isFolder={this.props.numParent > this.props.numChildren}
          refresh={this.refresh}
          idArray={this.props.idArray}
          onClick={this.ChangeMenu} />;
      case 3:
        return <EditMenu
          name={this.props.name}
          id={this.props.moduleId}
          onClick={this.ChangeMenu}
          refresh={this.refresh}
          type={this.props.addModuleType} />;
      case 4:
        return <AddMenu
          onClick={this.ChangeMenu}
          parentId={this.props.moduleId}
          refresh={this.refresh} />;
      case 5:
        return <BulkMainMenu
          onClick={this.ChangeMenu}
          numberOfSelection={this.state.numberOfSelection}
          selectedComponentsType={this.state.selectedComponentsType}
          isParents={this.props.numParent > this.props.numChildren}
        />
      case 6:
        return <BulkMoveToFolder
          folders={this.props.modules}
          onClick={this.ChangeMenu}
          refresh={this.refresh}
          idArray={this.props.idArray}
        />;
      default:
        return <MainMenu
          onClick={this.ChangeMenu}
          moduleTypeName="No Name" />;
    }
  }

  ChangeMenu = (option) => {
    this.setState({
      menu: parseInt(option)
    })
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.outSideMenu !== this.props.outSideMenu
      && nextProps.outSideMenu > 0) {
      this.setState({
        menu: nextProps.outSideMenu
      })
    }
    if (nextProps.numParent === 0 && nextProps.numChildren === 0
      && (this.props.numParent > 0 || this.props.numChildren > 0)) {
      this.setState({
        menu: 0,
        numberOfSelection: 0,
        selectedComponentsType: this.props.moduleTypeName + " resources"
      });
    }
    if (nextProps.numChildren > 0 && this.props.numChildren !== nextProps.numChildren
      && nextProps.numParent === 0) {
      this.setState({
        menu: 5,
        numberOfSelection: nextProps.numChildren,
        selectedComponentsType: nextProps.numChildren === 1 ?
          this.props.moduleTypeName + " resource" : this.props.moduleTypeName + " resources"
      });
    }
    else if (nextProps.numParent > 0 && this.props.numParent !== nextProps.numParent
      && nextProps.numChildren === 0) {
      this.setState({
        menu: 5,
        numberOfSelection: nextProps.numParent,
        selectedComponentsType: nextProps.numParent === 1 ?
          this.props.moduleTypeName : this.props.moduleTypeName + 's'
      });
    }
    return true;
  }

  render() {
    return (
      <Fragment>
        {this.onChangeMenu()}
      </Fragment>
    )
  }
}

LMSMenu.propTypes = {
  fetchCourseModules: PropType.func.isRequired
};

export default connect(
  null,
  { fetchCourseModules }
)(LMSMenu);
