import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import {Typography } from "@material-ui/core";
import OccasionalHunter from "./OccasionalHunterNR";
import DedicatedHunter from "./DedicatedSportHunterNR";
import NationalRanking from "./NationalRanking";

function TabPane(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabPane.propTypes = {
  children: PropTypes.node.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
});

class NationalRankingHeader extends React.Component {
  state = {
    value: 0
  };

  handleChange = (value) => {
    this.setState({ value });
  };

  loadNationalRankingTab = () => {
    switch (this.props.currActivityTab) {
      case "0":
        return <OccasionalHunter />;
      case "1":
        return <DedicatedHunter />;
      default:
        return <NationalRanking />;
    }
  };

  render() {
    return (
      <div className="container">
        <DedicatedHunter />
      </div>
    );
  }
}

NationalRankingHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    onCurrentNatinalRankingTabChange: state.onCurrentNatinalRankingTabChange
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentNatinalRankingTabChange: newTabName =>
      dispatch({ type: actionTypes.UPDATE_CURRENT_PAGE, currTab: newTabName }),
    onAddNatinalRankingChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_NATIONAL_RANK_LIST,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NationalRankingHeader));
