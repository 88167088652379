import { library } from "@fortawesome/fontawesome-svg-core";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from "react";
//import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import "./App.css";
import AdminDownloads from "./components/AdminDownloads/AdminDownloadList";
import test from "./components/AdminDownloads/test";
import Uploads from "./components/AdminDownloads/uploads/Uploads";
import adminHandler from "./components/adminHandler/adminHandler";
import AdminNewsPage from "./components/adminNews/AdminNewsPage";
import EditNews from "./components/adminNews/EditNews";
import ApproveEndorsementDashboard from "./components/adminPortal/Approvals/ApproveDashboardEndors";
import ApprovePendingDedicatedStatus from "./components/adminPortal/Approvals/ApproveDedicatedStatus";
import ApproveMembershipRenewal from "./components/adminPortal/Approvals/ApproveMembershipRenewal";
import ApproveNewMembers from "./components/adminPortal/Approvals/ApproveNewMembers";
import ApprovePendingActivities from "./components/adminPortal/Approvals/ApprovePendingActivities";
import DedicatedHunterMembers from "./components/adminPortal/DedicatedHunterMembers";
import UpdateLoyaltyDays from "./components/adminPortal/loyaltyDays/UpdateLoyaltyDays";
import MainAdmin from "./components/adminPortal/MainAdmin";
import MembershipRenewal from "./components/adminPortal/Pending/MembershipRenewal";
import PendingActivityApplication from "./components/adminPortal/Pending/PendingActivityApplication";
import PendingDedicatedHunter from "./components/adminPortal/Pending/PendingDedicatedHunter";
import PendingEndorsementApplication from "./components/adminPortal/Pending/PendingEndorsementApplication";
import PendingNewMembers from "./components/adminPortal/Pending/PendingNewMembers";
import PaymentRenewal from "./components/adminPortal/Reciept/PaymentMembershipRenewal";
import PaymentNewMember from "./components/adminPortal/Reciept/PaymentNewMember";
import UploadProfilePicture from "./components/adminPortal/UploadProfilPicture";
import ActivityDetails from "./components/adminUser/activitiesTab/ActivityDetails";
import AdminUserApproveAct from "./components/adminUser/activitiesTab/ApprovePendingActivities";
import MainActivities from "./components/adminUser/activitiesTab/MainActivities";
import AdminUserMainAct from "./components/adminUser/activitiesTab/PendingActivityApplication";
import AdminEndorsementView from "./components/adminUser/adminEndorsement/AdminEndorsementView";
import AdminEndorsementDetails from "./components/adminUser/adminEndorsement/EndorsementDetails";
import EditDropDown from "./components/adminUser/EditDropDowns/EditDropDown";
import AccountsView from "./components/adminViewMemberAreaPage/AccountsView";
import AccountViewAll from "./components/adminViewMemberAreaPage/AccountViewAll";
import ChangePassword from "./components/editMemberDetails/ChangePassword";
import EditMemberDetails from "./components/editMemberDetails/EditMemberDetails";
import NewsArticle from "./components/homePage/newsFeed/NewsArticle";
import ViewAllNews from "./components/homePage/newsFeed/ViewAllNews";
import LMSNavigator from "./components/learningManagementSystem/CourseComponents/LMSNavigator";
import CheckoutPage from "./components/loggeddInUser/account/CheckoutPage";
import Leave from "./components/loggeddInUser/account/Leave";
import ManualPay from "./components/loggeddInUser/account/ManualPay";
import Notify from "./components/loggeddInUser/account/Notify";
import PayfastDetails from "./components/loggeddInUser/account/PayFastDetails";
import PaymentView from "./components/loggeddInUser/account/PaymentView";
import AvatarProfile from "./components/loggeddInUser/avatarProfile";
import EndorsementApplicationDeatails from "./components/loggeddInUser/endorsement/EndorsementApplicationDeatails";
import EndorsementApplicationDeclaration from "./components/loggeddInUser/endorsement/EndorsementApplicationDeclaration";
import EndorsementApplicationDone from "./components/loggeddInUser/endorsement/EndorsementApplicationDone";
import EndorsementApplicationFirearm from "./components/loggeddInUser/endorsement/EndorsementApplicationFirearm";
import EndorsementApplicationView from "./components/loggeddInUser/endorsement/EndorsementApplicationView";
import MainEndorsement from "./components/loggeddInUser/endorsement/MainEndorsement";
import LicensedFireArms from "./components/loggeddInUser/LicensedFireArm/LicensedFireArms";
import UserDetails from "./components/loggeddInUser/userProfile/UserDetails";
import LoginHandler from "./components/loginPage/LoginHandler";
import ResetPassword from "./components/loginPage/ResetPassword";
import ResetRedirect from "./components/loginPage/ResetRedirect";
import ResetResponse from "./components/loginPage/ResetResponse";
import AdminMembersArea from "./components/main/AdminMembersArea";
import MembersArea from "./components/main/MembersArea";
import News from "./components/main/News";
import Notifications from "./components/main/Notifications";
import Emails from "./components/main/Emails";
import Register from "./components/main/Register";
import Declaration from "./components/memberApplication/Declaration";
import LoginDetails from "./components/memberApplication/LoginDetails";
import MemberAddress from "./components/memberApplication/MemberAddress";
import MemberDetails from "./components/memberApplication/MemberDetails";
import SalesDetails from "./components/memberApplication/SalesDetails";
import PendingMemberView from "./components/membersAreaPage/PendingMemberView";
import DedicatedSportHunterNR from "./components/nationalRanking/DedicatedSportHunterNR";
import NationalRanking from "./components/nationalRanking/NationalRanking";
import OccasionalHunterNR from "./components/nationalRanking/OccasionalHunterNR";
import NavBar from "./v3/containers/NavBar/Navbar";
import MemberQRDetails from "./components/qrLinkToMember/MemberQRDetails";
import ShootingRange from "./components/shootingRangeLocator/ShootingRange";
import StatusView from "./components/statusView/statusView";
import StoreLocatorContent from "./components/storeLocator/StoreLocatorContent";
import ViewAllMember from "./components/viewAllMember/ViewAllMember";
import ProcessMemberPayment from "./components/navBar/ProcessMemberPayment";
import ProcessCommissionPayment from "./components/navBar/commissions/ProcessCommissionPayment";
import StoreOwnerCommission from "./components/navBar/commissions/StoreOwnerCommission"
import CommissionTemplate from "./components/navBar/commissions/CommissionTemplate";
import MainSalesCommission from "./components/loggeddInUser/salesCommission/MainSalesCommission";
import CommissionPaymentDetails from "./components/loggeddInUser/salesCommission/CommissionPaymentDetails";
import MemberCounts from "./components/adminPortal/memberCounts/MemberCounts";
import CommissionSelect from "./components/loggeddInUser/salesCommission/CommissionSelect";
import EditPaymentDetails from "./components/loggeddInUser/salesCommission/EditPaymentDetails";
import DSNonComplianceList from "./components/navBar/reports/DSNonComplianceList";
import ForgotEmail from './components/loginPage/ForgotEmail'

/*
#####################################################
*/
import {
	Activities,
	Cancel,
	Documents,
	Endorsements, 
	Login,
	Logout,
	Profile,
  WhatsAppDocuments,
  ProfileCompletion,
	Sales,
	Signup,
	Return,
  MemberEmails,
	LicenseRemiders,
	MyDedicatedStatus,
  Subscriptions,
  ReportsDownload,
  NewsLetter,
  Courses,
  PartnerDashboard,
  SalesRepDashboard
  } from './v3/containers/index';
import theme from './v3/theme/theme';
import themev1 from './materialUI/NarfoTheme';
import {
	BrowserRouter, 
	Redirect,
	Route, 
	Switch
} from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Layout from './v3/hoc/Layout/Layout';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

//More info
import HuntingGallery from "./v3/containers/Gallery/HuntingGallery";// For Wordpress 
import TargetDownloads  from './v3/containers/TargetDownloads/TargetDownloads' // For Wordpress 
import PartnersLocation from "./v3/containers/PartnersLocation/PartnersLocation";
import ShootingRangeLocations from "./v3/containers/ShootingRangeLocations/ShootingRangeLocations";
import FirearmsGuardian from "./v3/containers/FirearmsGuardian/FirearmsGuardian";
import { Alert } from "@material-ui/lab";
import GosaForm from "./v3/containers/WordPressForms/GosaForm";
import PartnerForm from "./v3/containers/WordPressForms/PartnerForm";

import PcoasaForm from "./v3/containers/WordPressForms/PcoasaForm";
//TODO: check if the links to wordpress sites is working 

library.add(faUpload);

const styles = () => ({
	app: {
		flexShrink: 0,
		height: '100%',
		minHeight: '100vh'
	},
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: this.props.snippedUrl
    };

    //Refresh the session token
    if(sessionStorage.getItem("tokenKey") == null)
       sessionStorage.setItem("tokenKey", localStorage.getItem('token'));

  }
  render() {
	const { classes } = this.props;
	const ProtectedRoute = ({ component: Component, ...rest }) => (
		<Route {...rest} render={(props) => (
		  this.props.isAuthenticated === true
			? <Component {...props} />
			: <Redirect to={{
			  pathname: '/login',
			}} />
		)} />
	  );
    return (
	<BrowserRouter>
		<CssBaseline />
		<ThemeProvider theme={theme}>
			<Paper className={classes.app}>
          <div
            className="container pr-0 pl-0"
            style={{
              maxWidth: "2180px"
            }}
          >
            <Layout>
            <Switch>
              <Route path="/admin/AdminManagement" component={adminHandler} exact />
              {this.state.toggle.substring(this.state.toggle.indexOf("?"), 1) === '?' && this.state.toggle.substring(this.state.toggle.indexOf("?"), 2) !== '?=' ?
              <MemberQRDetails snippedUrl={this.state.toggle.substring(this.state.toggle.indexOf("?") + 1)} /> :
              this.state.toggle.substring(this.state.toggle.indexOf("?"), 2) === '?=' ?
              <ResetRedirect snippedUrl={this.state.toggle.substring(this.state.toggle.indexOf("=") + 1)} /> :
              <Route path="/" component={Login} exact />}


			<Route exact path='/login' component={Login}/>
			<Route exact path='/sign-up' component={Signup}/>
			<Route exact path='/logout' component={Logout}/>
			<Route exact path="/return" component={Return}/>
			<Route exact path="/cancel" component={Cancel}/>  
			<Route exact path='/wp-target-downloads' component={TargetDownloads}/>
			<Route path="/admin/MainAdmin" component={MainAdmin} exact />
			<Route path="/Declaration" component={Declaration} exact />
      <Route path='/whatsapp-documents' component={WhatsAppDocuments}/>
      <Route path="/hunting-gallery" component={HuntingGallery} exact />
      <Route path="/wp-gosa-member-application" component={GosaForm} exact />
      <Route path="/online-member-application/:partnerId" component={PartnerForm} exact />
      <Route path="/wp-pcoasa-member-application" component={PcoasaForm} exact />
      <Route path="/wp-firearms-guardian" component={FirearmsGuardian} exact />

			<ProtectedRoute exact path='/member/my-profile' component={Profile}/>
			<ProtectedRoute exact path='/member/partner-dashboard' component={PartnerDashboard}/>
			<ProtectedRoute exact path='/member/sales-rep-dashboard' component={SalesRepDashboard}/>
			<ProtectedRoute exact path='/member/profile-completion' component={ProfileCompletion}/>
			<ProtectedRoute exact path='/member/sales-commission' component={Sales}/>
			<ProtectedRoute exact path='/member/activities' component={Activities}/>
			<ProtectedRoute exact path='/member/endorsements' component={Endorsements}/>
			<ProtectedRoute exact path='/member/documents' component={Documents}/>
			<ProtectedRoute exact path='/member/license-reminders' component={LicenseRemiders}/>
      <ProtectedRoute exact path='/member/dedicated-status' component={MyDedicatedStatus}/>
      <ProtectedRoute exact path='/member/courses' component={Courses}/>
      <ProtectedRoute exact path='/member/member-emails' component={MemberEmails}/>
      {/* TODO: subscription */}
      <ProtectedRoute exact path='/member/subscriptions' component={Subscriptions}/>
      <ProtectedRoute exact path='/member/reports-download' component={ReportsDownload}/>
      <ProtectedRoute exact path='/member/NewsLetter' component={NewsLetter}/>




			{/**VERSION 2 PUBLIC PAGES
			 */}

              <Route
                path="/Login/ForgotEmail"
                component={ForgotEmail}
                exact
              />
              <Route
                path="/Login/ResetPassword"
                component={ResetPassword}
                exact
              />
              <Route
                path="/AdminUserMainAct"
                component={AdminUserMainAct}
                exact
              />
              <Route
                path="/AdminUserApproveAct"
                component={AdminUserApproveAct}
                exact
              />
              <Route path="/admin/UpdateLoyaltyDays" component={UpdateLoyaltyDays} exact />
              <Route path="/admin/ProcessMemberPayment" component={ProcessMemberPayment} exact />
              <Route path="/Login/Redirect" component={ResetRedirect} exact />
              <Route path="/ChangePassword" component={ChangePassword} exact />
              <Route path="/Login/Response" component={ResetResponse} exact />
              <Route path="/admin/MembersArea" component={MembersArea} exact />
              <Route
                path="/admin/PendingMemberView"
                component={PendingMemberView}
                exact
              />
              <Route path="/AvatarProfile" component={AvatarProfile} exact />
              <Route
                path="/admin/AdminMembersArea"
                component={AdminMembersArea}
                exact
              />
              <Route path="/Register" component={Register} exact />
              <Route path="/MemberAddress" component={MemberAddress} exact />
              <Route path="/LoginDetails" component={LoginDetails} exact />
              <Route path="/MemberDetails" component={MemberDetails} exact />
              <Route path="/MemberAddress" component={MemberAddress} exact />
              <Route
                path="/admin/MembersArea/MainEndorsement"
                component={MainEndorsement}
                exact
              />
              <Route
                path="/AdminEndorsementView"
                component={AdminEndorsementView}
                exact
              />
              <Route path="/Declaration" component={Declaration} exact />
              <Route path="/ViewAllAccount" component={AccountViewAll} exact />
              <Route path="/AccountsView" component={AccountsView} exact />
              <Route path="/SalesDetails" component={SalesDetails} exact />
              <Route path="/admin/CourseManagement" component={LMSNavigator} exact />
              <Route
                path="/admin/DropdownManagement"
                component={EditDropDown}
                exact
              />
              <Route
                path="/admin/ProcessCommissionPayment"
                component={ProcessCommissionPayment}
                exact
              />
              <Route
                path="/MainSalesCommission"
                component={MainSalesCommission}
                exact
              />
              <Route
                path="/CommissionTemplate"
                component={CommissionTemplate}
                exact
              />
              <Route
                path="/StoreOwnerCommission"
                component={StoreOwnerCommission}
                exact
              />
              <Route
                path="/CommissionPaymentDetails"
                component={CommissionPaymentDetails}
                exact
              />
              <Route
                path="/MemberCounts"
                component={MemberCounts}
                exact
              />
              <Route
                path="/admin/DSNonComplianceList"
                component={DSNonComplianceList}
                exact
              />
              <Route
                path="/CommissionSelect"
                component={CommissionSelect}
                exact
              />
              <Route
                path="/EditPaymentDetails"
                component={EditPaymentDetails}
                exact
              />
              <Route
                path="/LearningManagementSystem"
                component={LMSNavigator}
                exact
              />
              <Route path="/Admin" component={MainAdmin} exact />
              {<Route path="/License" component={LicensedFireArms} exact />}
              <Route path="/admin/News" component={News} exact />
              <Route path="/AddArticle" component={AdminNewsPage} />
              <Route path="/EditNews/:no" component={EditNews} />

              <Route path="/admin/Notifications" component={Notifications} exact />
              <Route path="/admin/Emails" component={Emails} exact />

              <Route
                path="/Dedicated/Status/:key"
                component={StatusView}
                exact
              />
              <Route path="/ViewAllMember" component={ViewAllMember} exact />
              <Route
                path="/admin/MembersArea/EndorsementApplicationView"
                component={EndorsementApplicationView}
                exact
              />
              <Route path="/Cancel" component={Cancel} exact />
              <Route path="/Notify" component={Notify} exact />
              <Route path="/Leave" component={Leave} exact />
              <Route path="/Checkout" component={CheckoutPage} exact />
              <Route path="/Manual" component={ManualPay} exact />
              <Route
                path="/admin/MembersArea/EndorsementApplicationDetails"
                component={EndorsementApplicationDeatails}
                exact
              />
              <Route
                path="/admin/MembersArea/EndorsementApplicationFirearm"
                component={EndorsementApplicationFirearm}
                exact
              />
              <Route
                path="/admin/MembersArea/EndorsementApplicationDeclaration"
                component={EndorsementApplicationDeclaration}
                exact
              />
              <Route
                path="/admin/MembersArea/EndorsemtnApplicationDone"
                component={EndorsementApplicationDone}
                exact
              />
              <Route
                path="/admin/MembersArea/EndorsementApplicationView"
                component={EndorsementApplicationView}
                exact
              />
              <Route
                path="/admin/MembersArea/EndorsementApplications"
                component={MainEndorsement}
                exact
              />
              <Route
                path="/AdminEndorsementDetails"
                component={AdminEndorsementDetails}
                exact
              />
              <Route path="/PaymentView" component={PaymentView} exact />
              <Route path="/Admin" component={ViewAllMember} exact />
              <Route path="/Activity" component={MainActivities} exact />
              <Route path="/admin/Members" component={ViewAllMember} exact />
              <Route
                path="/ActivityDetails"
                component={ActivityDetails}
                exact
              />
              <Route
                path="/SportShootingRanking"
                component={NationalRanking}
                exact
              />
              <Route
                path="/OccasionalHunterNR"
                component={OccasionalHunterNR}
                exact
              />
              <Route
                path="/DedicatedSportHunterNR"
                component={DedicatedSportHunterNR}
                exact
              />
              <Route
                path="/EditMemberDetails"
                component={EditMemberDetails}
                exact
              />
              <Route path="/MainActivities" component={MainActivities} exact />
              <Route path="/StatusView" component={StatusView} exact />
              <Route path="/UserDetails" component={UserDetails} exact />
              <Route
                path="/DedicatedHunterMembers"
                component={DedicatedHunterMembers}
                exact
              />
              <Route
                path="/admin/PendingDedicatedHunter"
                component={PendingDedicatedHunter}
                exact
              />
              <Route
                path="/admin/PendingEndorsementApplication"
                component={PendingEndorsementApplication}
                exact
              />
              <Route
                path="/admin/PendingActivityApplication"
                component={PendingActivityApplication}
                exact
              />
              <Route
                path="/admin/PendingNewMembers"
                component={PendingNewMembers}
                exact
              />
              <Route
                path="/admin/MembershipRenewal"
                component={MembershipRenewal}
                exact
              />
              <Route
                path="/admin/ApproveNewMembers"
                component={ApproveNewMembers}
                exact
              />
              <Route
                path="/admin/ApproveMembershipRenewal"
                component={ApproveMembershipRenewal}
                exact
              />
              <Route
                path="/admin/ApprovePendingActivities"
                component={ApprovePendingActivities}
                exact
              />
              <Route
                path="/admin/ApprovePendingDedicatedStatus"
                component={ApprovePendingDedicatedStatus}
                exact
              />
              <Route
                path="/PaymentNewMember"
                component={PaymentNewMember}
                exact
              />
              <Route path="/PaymentRenewal" component={PaymentRenewal} exact />
              <Route path="/Downloads" component={AdminDownloads} exact />
              <Route path="/admin/Uploads" component={Uploads} exact />
              <Route path="/Article/:number" component={NewsArticle} exact />
              <Route path="/ViewAllNews" component={ViewAllNews} exact />

              <Route path="/test" component={test} exact />
              <Route
                path="/UploadProfilePicture"
                component={UploadProfilePicture}
                exact
              />
              <Route
                path="/admin/ApproveEndorsementDashboard"
                component={ApproveEndorsementDashboard}
                exact
              />
              <Route
                path="/admin/ConfigurePayfast"
                component={PayfastDetails}
                exact
              />
              <Route
                path="/MemberQRDetails/:linkstr"
                component={MemberQRDetails}
                exact
              />

              <Redirect from="*" to="/" />
            </Switch>
			</Layout>
          </div>
		</Paper>
		</ThemeProvider>
	</BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
	isAuthenticated: state.AuthReducer.token !== null,
});

export default connect(mapStateToProps, null)(withStyles(styles)(App));