export const ActivityEvent = {
    narfo: 'NARFO',
    other: 'OTHER'
}

export const placeholders = {
    memNo: 'i.e. NM-0001',
    event: ' Select Event',
    activity: 'Select Activity Type',
    score: '81.00',
}

export const dateFormat = {
    date: 'dd/MM/yyyy'
}