import React, { Component } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  ASCENDING_ABREV,
  DESCENDING_ABREV
} from '../../shared/constants';

import { format } from 'date-fns';


class GenericTable extends Component {
  constructor(props) {
    super(props);
    let sortableColumn = props.columns.find((col) => col.sortable) || '';

    this.state = {
      sortBy: sortableColumn.field,
      sortOrder: DESCENDING_ABREV,
      fields: props.columns.map(col => col.field),
      rows: props.rows,
    };
  }

  requestSort = (pSortBy, sortFunction) => {
    let sortBy = this.state.sortBy;
    let sortOrder = this.state.sortOrder;
    let sortOrderInt = -1;
    return () => {
      if (pSortBy === this.state.sortBy) {
        sortOrder = sortOrder === ASCENDING_ABREV ? DESCENDING_ABREV : ASCENDING_ABREV;
        sortOrderInt = sortOrder === ASCENDING_ABREV ? -1 : 1;
      } else {
        sortBy = pSortBy;
        sortOrder = DESCENDING_ABREV;
        sortOrderInt = -1;
      }
      const sortedItems = sortFunction(this.props.rows, sortOrderInt);
      this.setState({
        sortOrder: sortOrder,
        sortBy: sortBy,
        rows: sortedItems,
      });
    };
  }

  buildHeader = (columns, sortBy, sortOrder) => (
    <TableHead>
      <TableRow>
        {columns.map((col, index) =>
          <TableCell key={index}>
            {col.sortable ?
              <TableSortLabel
                active={sortBy === col.field}
                direction={sortOrder}
                onClick={this.requestSort(col.field, col.sortFunction)}
              >
                {col.headerName}
              </TableSortLabel> :
              col.headerName
            } 
          </TableCell>
        )}
        {this.props.view && <TableCell></TableCell> }
      </TableRow>
    </TableHead>
  );

  getColumnFormat = (field) => {
    const found = this.props.columns.find(col => col.field === field);
    return found ? found.format : null;
  }

  getDateFormat =(field) => {
    try  
    {
      let issueDate = new Date(field);
      if (issueDate)
        return format(new Date(field.split('T')[0]),'yyyy/MM/dd');
  
        return field;

    }
    catch(ex){

      console.log("Invalid date", field)
      return field;
    }
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <React.Fragment>
        <Table className="mt-12">
          {this.buildHeader(this.props.columns, this.state.sortBy, this.state.sortOrder)}
          <TableBody>
            {
              !this.props.subscription ?
            this.state.rows.map((item, id) => (
              <TableRow key={id}>
                {this.state.fields.map((field, index) => (
                  <TableCell key={index} className="md-4">
                    {(() => { 
                      const format = this.getColumnFormat(field);

                      if(field == "issueDate")
                        return this.getDateFormat(item[field]);
                      else if(field.toLowerCase().includes("date"))
                        return this.getDateFormat(item[field]);

                      return format ? format(item[field]) : item[field];
                    })()}
                  </TableCell>))
                }
                {this.props.view &&
                <TableCell className="pointer" onClick={() => this.props.onViewCallBack(item, id)}>
                  <Typography color="primary" align="center">view</Typography>
                </TableCell>
                }
              </TableRow>
            ))
            :
            this.state.rows.map((item, id) => (
              <TableRow key={id} style={{marginBottom:'10px'}}>
                {this.state.fields.map((field, index) => (
                  <TableCell key={index}>
                    {
                    field == 'link' ?
                    <a href={item[field]} target="_" blank="true" style={{color:'#483C9E'}}>{item[field]}</a>
                    :
                    field =='thumbnail' ?
                    <div> 
                    <iframe src={item[field]} width={isMobile ? "100%" :"10%"}  frameborder="0" allowfullscreen="true" allowtransparency="true"></iframe>
                     <br/>
                      Date : {item['date']}
                      </div>
                :
                (() => { 
                  const format = this.getColumnFormat(field);
                  return format ? format(item[field]) : item[field];
                })()
                }

                  </TableCell>))
                }
                <br/>
              </TableRow> 
            ))
            }
          </TableBody>
          <TableFooter />
        </Table>
        {
          this.state.rows.length === 0 
       && (this.props.narfo ? <Typography>To qualify for a ranking position, you need to submit at least three of the same disciplines</Typography>
        : <Typography>No data</Typography>) }
      </React.Fragment>
    );
  }
}

GenericTable.getDerivedStateFromProps = (props) => ({
  rows: props.rows,
});

GenericTable.defaultProps = {
  rows: [],
  columns: [],
  onViewCallBack: (item, index) => ({ item, index }),
};

GenericTable.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onViewCallBack: PropTypes.func.isRequired,
  view: PropTypes.bool,
};

export default GenericTable;
