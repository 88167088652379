import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import * as actionTypes from "../../store/actions/actionTypes";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import "../editMemberDetails/profile.css";

const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "80%",
    overflow: "auto"
  },
  content: {
    width: "100%",
    height: "80%",
    border: "0px"
  }
};

class AdminNewsCard extends Component {
  handleSubmit = e => {
    this.props.onArticleChange(1, "currentNewsTab");
  };

  render() {
    const { article, checked, toggleCheck, updateSelectedArticle } = this.props;
    return (
      <div class="row border-bottom  border-gray">
        <div className={`col-1 ${checked ? "" : "news-checked"}`}>
          <Checkbox
            value={article.newsArticleId}
            onChange={e => updateSelectedArticle(e)}
          />
        </div>
        <div className="col-11">
          <div className="row">
            <div className="col-12 font-weight-bold" onClick={toggleCheck}>
              {article.title}
            </div>
            <div className="col-sm-12">
              <iframe
                title="article"
                srcdoc={article.content.substring(0, 2000)}
                style={{ border: "0px" }}
                width="109%"
                height="110"
                border="0"
                scrolling="yes"
              />
            </div>
          </div>
        </div>
        <div />
      </div>
    );
  }
}
AdminNewsCard.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.article
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminNewsCard));
