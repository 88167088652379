import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import { membershipExpired } from "../main/MembersArea";
import "./qrLink.css";
class MemberQRDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memDetails: [],
      dedicatedStatus: [],
      membershipExpiryDate: ""
    };
  }
  componentDidMount() {
    const Cryptr = require("cryptr");
    const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
    const decryptedString = cryptr.decrypt(this.props.snippedUrl);
    Axios({
      url: BASE + "api/Member/getLoggedInMember/" + decryptedString,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    }).then(res =>
      this.setState({
        memDetails: res.data.member,
        dedicatedStatus: res.data.member.dedicatedStatus,
        membershipExpiryDate: res.data.member.expiryDate
      })
    );
  }
  render() {
    const memQRD = (name, value) => (
      <div className="container font-weight-bold font-italic member-info-styler h6 mt-2 mb-2">
        <div className="text-primary">{name}</div>
        <div className="text-success">{value}</div>
      </div>
    );
    if(!membershipExpired(this.state.membershipExpiryDate)) {
      return (
        <div className="container">
          <GenericHeader title="Certificate Authenticated" />
          <div>{memQRD("Name", this.state.memDetails.firstname)}</div>
          <div>{memQRD("Last Name", this.state.memDetails.surname)}</div>
          <div>{memQRD("ID Number", this.state.memDetails.idno)}</div>
          <div>
            {memQRD("Membership Type", this.state.memDetails.memType)}
          </div>
          <div>
            {memQRD("Address", this.state.memDetails.physicalAddress)}
          </div>
          <div>
            {memQRD("Member Since Date", this.state.memDetails.inceptionDate.substring(0, 10))}
          </div>
          <div>
            {memQRD("Member Expiry Date", this.state.memDetails.expiryDate.substring(0, 10))}
          </div>
          <div>{memQRD("Email", this.state.memDetails.email)}</div>
          {this.state.dedicatedStatus.map((content, index) => {
            return this.state.dedicatedStatus.status === "Approved" ? (
              <div key={index}>
                <div>{memQRD(content.dstype, content.dedicatedId)}</div>
              </div>
            ) : null;
          })}
        </div>
      );
    } else {
      return (
        <div className="container">
          <GenericHeader title="Certificate Unauthenticated" />
          <div className="font-weight-bold font-italic member-info-styler h6 mt-2 mb-2 text-danger">
            Membership Expired
          </div>
        </div>
      )
    }
  }
}
export default MemberQRDetails;
