import React, { Component } from 'react';

export default class Declaration extends Component {
    constructor(props) {
        super(props);
        this.validateDeclaration = this.validateDeclaration.bind(this);
        this.state = {
            fca: null,
            faStorage: null,
            ordinances: null,
            guilty: null,
            docDeclaration: null,
            codeOfConduct: null,
            dsDeclatation: null
        }
    }

    validateDeclaration() {
        let valid = true;
        const fields = Object.keys(this.state)
        fields.map(f => {
            if (!this.state[f]) { valid = false; }
            return null;
        })
        return valid;
    }

    render() {

        return (
            <div className="container-fluid bg-light">
                <div className="col">
                    <p className="col-12 mb-0">Are you well acquainted with the current Firearms Control Act?</p>
                </div>
                <div className="row form-check-inline mb-3">
                    <div className="col">
                        <label className="form-check-label ml-5">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="fca"
                                value="Yes"
                                onChange={(e) => {
                                    this.setState({ fca: e.target.value });
                                    this.props.setValidationError(this.validateDeclaration())
                                }}
                            />
                            Yes
                    </label>
                    </div>
                    <label className="form-check-label ml-3">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="fca"
                            value="No"
                            onChange={(e) => {
                                this.setState({ fca: e.target.value });
                                this.props.setValidationError(this.validateDeclaration())
                            }}
                        />
                        No
                    </label>
                </div>
                <div className="col">
                    <p className="col-12 mb-0" >Are you well acquainted with the use and storage of your Firearm?</p>
                </div>
                <div className="row form-check-inline mb-3">
                    <div className="col">
                        <label className="form-check-label ml-5">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="faStorage"
                                value="Yes"
                                onChange={(e) => {
                                    this.setState({ faStorage: e.target.value });
                                    this.props.setValidationError(this.validateDeclaration())
                                }}
                            />
                            Yes
                    </label>
                    </div>
                    <label className="form-check-label ml-3">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="faStorage"
                            value="No"
                            onChange={(e) => {
                                this.setState({ faStorage: e.target.value });
                                this.props.setValidationError(this.validateDeclaration())
                            }}
                        />
                        No
                    </label>
                </div>
                {this.props.dsType === "Hunting" &&
                    <React.Fragment>
                        <div className="col">
                            <p className="col-12 mb-0">Are you well acquainted with the hunting ordinances and proclamations for the provinces you intend to hunt in?</p>
                        </div>
                        <div className="row form-check-inline mb-3">
                            <div className="col">
                                <label className="form-check-label ml-5">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="ordinances"
                                        value="Yes"
                                        onChange={(e) => {
                                            this.setState({ ordinances: e.target.value });
                                            this.props.setValidationError(this.validateDeclaration())
                                        }}
                                    />
                                    Yes
                                </label>
                            </div>
                            <label className="form-check-label ml-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="ordinances"
                                    value="No"
                                    onChange={(e) => {
                                        this.setState({ ordinances: e.target.value });
                                        this.props.setValidationError(this.validateDeclaration())
                                    }}
                                />
                                No
                            </label>
                        </div>
                        <div className="col">
                            <p className="col-12 mb-0">Have you ever been found guilty of any offence related to hunting where your finger prints was taken?</p>
                        </div>
                        <div className="row form-check-inline mb-3">
                            <div className="col">
                                <label className="form-check-label ml-5">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="guilty"
                                        value="Yes"
                                        onChange={(e) => {
                                            this.setState({ guilty: e.target.value });
                                            this.props.setValidationError(this.validateDeclaration())
                                        }}
                                    />
                                    Yes
                                </label>
                            </div>
                            <label className="form-check-label ml-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="guilty"
                                    value="No"
                                    onChange={(e) => {
                                        this.setState({ guilty: e.target.value });
                                        this.props.setValidationError(this.validateDeclaration())
                                    }}
                                />
                                No
                            </label>
                        </div>
                    </React.Fragment>}
                <div className="col">
                    <p className="col-12 mb-0">I hereby declare that all information in this document is true</p>
                </div>
                <div className="row form-check-inline mb-3">
                    <div className="col mt-0">
                        <label className="form-check-label ml-5">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="docDeclaration"
                                value="Yes"
                                onChange={(e) => {
                                    this.setState({ docDeclaration: e.target.value });
                                    this.props.setValidationError(this.validateDeclaration())
                                }}
                            />
                            Yes
                    </label>
                    </div>
                    <label className="form-check-label ml-3">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="docDeclaration"
                            value="No"
                            onChange={(e) => {
                                this.setState({ docDeclaration: e.target.value });
                                this.props.setValidationError(this.validateDeclaration())
                            }}
                        />
                        No
                    </label>
                </div>
                <div className="col">
                    <p className="col-12 mb-0">I hereby declare that I fully understand and abide by the codes of conduct as set out by the NARFO</p>
                </div>
                <div className="row form-check-inline mb-3">
                    <div className="col">
                        <label className="form-check-label ml-5">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="codeOfConduct"
                                value="Yes"
                                onChange={(e) => {
                                    this.setState({ codeOfConduct: e.target.value });
                                    this.props.setValidationError(this.validateDeclaration())
                                }}
                            />
                            Yes
                    </label>
                    </div>
                    <label className="form-check-label ml-3">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="codeOfConduct"
                            value="No"
                            onChange={(e) => {
                                this.setState({ codeOfConduct: e.target.value });
                                this.props.setValidationError(this.validateDeclaration())
                            }}
                        />
                        No
                    </label>
                </div>
                <div className="col">
                    <p className="col-12 mb-0">I hereby declare that I fully understand and abide by the Dedicated Status Declaration?</p>
                </div>
                <div className="row form-check-inline mb-3">
                    <div className="col">
                        <label className="form-check-label ml-5">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="dsDeclatation"
                                value="Yes"
                                onChange={(e) => {
                                    this.setState({ dsDeclatation: e.target.value });
                                    this.props.setValidationError(this.validateDeclaration())
                                }}
                            />
                            Yes
                    </label>
                    </div>
                    <label className="form-check-label ml-3">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="dsDeclatation"
                            value="No"
                            onChange={(e) => {
                                this.setState({ dsDeclatation: e.target.value });
                                this.props.setValidationError(this.validateDeclaration())
                            }}
                        />
                        No
                    </label>
                </div>
                <div className="row mt-2">
                    <p className="p-0 m-0 font-weight-bold text-center col-12">Dedicated Status Declaration:</p>
                    <p className="p-0 m-0 font-weight-bold text-center col-12">PLEASE READ THIS CAREFULLY AND MAKE SURE YOU UNDERSTAND THE CONTENTS !</p>
                </div>
                <div className="row">
                    <p className="col-12">{this.state.error}</p>
                </div>
            </div>
        )
    }
}
