import React, { Component } from "react";
import defaultImg from "../images/dProfile.jpg";
import defaultImgGl from "../images/gl.jpg";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { styles } from "../../../utils/styles/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as ApproveNewDSActions from "../Approvals/ApproveNewDS/ApproveNewDSActions";
import axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { DASHBOARD_ACTIONS } from "../../../store/actions/actionTypes";
import {
  BASE,
  GET_PENDING_DS,
} from "../../../API/APIendpoint";

import Axios from "axios";

import Radio from "@material-ui/core/Radio";
class PendingDedicatedHunter extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      _allPendingDS: true,
      _newPendingDSForTheMonth: false,
      _currentMonth: new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`,
      members:[],
    }
    // this.findMember = this.findMember.bind(this);
  }
  componentWillMount() {
    // this.setState({ loading: true })

    // axios
    //   .get(BASE + GET_PENDING_DS, {
    //     headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    //   })
    //   .then(response => {
    //     this.setState({ loading: false });
    //     this.props.onStatusDSChange(response.data);
    //     console.log("DATA in PendingDH", response.data)
        
    //   })
    //   .catch(err => {
    //     console.error("Error loading DS applications", err)
    //   });

    this.props.onStatusDSChange(this.props.pendingDS);
    this.props.pendingDS.forEach(ds => {
      Axios({
        url: BASE + `api/Member/FindMemberByMemNo/${ds.memNo}`,
        method: "get",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
            this.setState({members : [...this.state.members,res.data] });
        })
        
      this.setState({ loading: false});

    });

  }

  handleSubmit = (member, application) => {
    this.props.onMemberViewChange(member);
    this.props.ApproveNewDSActions.setCurrentDS(application, member);
    this.props.history.push("/admin/ApprovePendingDedicatedStatus");
  };

  render() {
    const pendingNewDS = this.props.pendingDS;
    const pendingFilteredDS = pendingNewDS;
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">
              Loading the Dedicated Status Please wait ...
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="mb-4">
          <GenericHeader
            title="Dedicated Status Applications"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/MainAdmin");
            }}
          />
        </div>
        {pendingFilteredDS.length === 0 ? (
          <div className="text-warning font-weight-bold">There are no pending Dedicated Status Applications currently...</div>
        ) : (
            
          this.state.loading ?
          <div className="row" style={{ marginTop: "80px" }}>
            <div className="col-12 d-flex justify-content-center">
              <FadeLoader color="orange" margin="auto" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <p className="font-weight-bold">
                Loading the activities. Please wait ...
              </p>
            </div>
          </div>
      :
            <ul className="ml-0 pl-1">
              {pendingFilteredDS.map((a, index) => {
                const member = this.state.members.filter(ds => ds.memNo == a.memNo)[0];

                if(!member)
                return null;

                return (
                  <div
                    key={index}
                    className="row h-4 mb-2 border-bottom pb-2"
                    style={{ cursor: "pointer" }}
                    onMouseDown={() => this.handleSubmit(member, a)}
                  >
                    <div className="row pl-2">
                      <div className="col-4 my-auto">
                        <div className="h6 mb-0 pr-2">
                          {member
                            ? `${member.firstname.toString().substring(0, 1)}`
                            : null}
                        </div>
                        <img src={member.sex === "Male" ? defaultImg : defaultImgGl} alt="Gun" className="avatar" style={{ width: "100px", height: "100px" }} />
                      </div>
                      <div className="col-8 pl-1 pt-3 " align="left">
                        <div className="h6">
                          {member ? `${member.firstname} ${member.surname}` : null}
                        </div>
                        <div className="h6">
                          {a.dsno}
                        </div>
                        <div className="h6">
                          {member ? `${member.email}` : null}
                        </div>
                        <div className="h6">
                          {member ? `Expiry Date: ${member.expiryDate.substring(0, 10)}` : null}
                        </div>
                        <div className="text-warning">
                          Pending Dedicated Status Approval
                    </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </ul>
          )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    pendingDS: state.dashBoardReducer.pendingDS,
    // memberList: state.dashBoardReducer.totalMembers.members,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ApproveNewDSActions: bindActionCreators(ApproveNewDSActions, dispatch),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onStatusDSChange: newPending =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_DS_PENDINGS,
        pendingDS: newPending.filter(ds => ds.status == 'Pending')
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PendingDedicatedHunter)));
