import React, { Component } from 'react'
import { BASE } from "../../../../API/APIendpoint";
import axios from "axios";
import defaultImg from "../../images/dProfile.jpg";
export default class UserSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      image: null
    }
  }

  componentDidMount() {
    axios
      .get(BASE + "api/MemberProfilePictures/getMemId/" + this.props.user.memNo,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        const res = response.data;
        this.setState({
          image:
            res[0] === null ? null : res[0].profilePicture.toString()
        });
      })
  }
  render() {
    if (this.props.user)
      return (
        <div className="media border-bottom m-2 mt-3">
          <div className="my-auto">
            <img
              src={this.state.image?this.state.image:defaultImg}
              alt="profile"
              className="border border-solid rounded-circle"
              style={{
                width:"80px",
                height:"80px"
              }}
            />
          </div>
          <div className="media-body">
            <h5 className="mt-0 ml-2">{this.props.user.firstname + " " + this.props.user.surname}</h5>
            {this.props.user.dedicatedStatus.length > 0 ?
              this.props.user.dedicatedStatus.map((_dStatus, index) =>
                <h6 key={index} className="ml-2">{`${_dStatus.dstype} ${_dStatus.status}`}</h6>
              ) :
            < h6 className="font-weight-bold text-danger ml-2">{"Non-Dedicated Member"}</h6>
            }
          </div>
        </div>
      )
    else return null;
  }
}
