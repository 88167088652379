import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import orange from "@material-ui/core/colors/orange";
import Radio from "@material-ui/core/Radio";
import {
  BASE,
  GET_SECTION_LIST,
  GET_ACTIVITY_TYPE_LIST
} from "../../../API/APIendpoint";
import "w3-css/w3.css";

const styles = {
  root: {
    color: orange[600],
    "&$checked": {
      color: orange[500]
    }
  },
  checked: {}
};

class EndorsementApplicationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Section: '',
      ActType_List: [],
      isValid: false,
      ApplicationType: '',
      isClicked: false,
      selectedSportShooting: false,
      huntingSelected: false,
      errorState: false,
      selectedSelfDefence: false
    };
  }

  state = {
    selectedValue: "a"
  };

  handleChecked = event => {
    this.setState({ selectedValue: event.target.value });
  };

  componentDidMount() {
    fetch(BASE + GET_SECTION_LIST,
      {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
      })
      .then(res => res.json())
      .then(data => {
        this.setState({
          Section: data
        });
      });

    fetch(BASE + GET_ACTIVITY_TYPE_LIST,
      {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({
          ActType_List: data
        });
      });
    this.props.onAddEndorsementDetailsChange("", "Title")
  }

  handleChange = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.value, [name]);
    if(name === 'Title' && event.target.value.length !== 0){
      this.setState({
        errorState: false
      })
    } else {
      this.setState({
        errorState: true
      })
    }
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleCkeckedOption = event => {
    this.props.onAddEndorsementDetailsChange(
      event.target.checked,
      "DedicatedStatus"
    );
  };

  componentWillMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (value.length <= 0) {
        return false;
      }
      return true;
    });

    ValidatorForm.addValidationRule("isSelected", value => {
      if (value.length <= 0) {
        return false;
      }
      return true;
    });
  }

  handleSelectedHunting = ApplicationType => event => {
    this.props.onAddEndorsementDetailsChange(event.target.value, [
      ApplicationType
    ]);
  };

  onHunting = () => {
    this.props.onEndorsementApplicationTypeUpdate("Hunting")
    this.setState({
      huntingSelected: true,
      selectedSportShooting: false,
      selectedSelfDefence: false
    });
  };
  selfDefence = () => {
    this.props.onEndorsementApplicationTypeUpdate("Self Defence")
    this.setState({
      huntingSelected: false,
      selectedSelfDefence: true,
      selectedSportShooting: false
    });
  };

  onSportShooting = () => {
    this.props.onEndorsementApplicationTypeUpdate("Sports Person")
    this.setState({
      selectedSportShooting: true,
      huntingSelected: false,
      selectedSelfDefence: false
    });
  };

  onSection13 = () => {
    this.props.onEndorsementSectionUpdate("Section 13")
    this.setState({
      section13: true,
      section15: false,
      section16: false
    });
  };
  onSection15 = () => {
    this.props.onEndorsementSectionUpdate("Section 15")
    this.setState({
      section13: false,
      section15: true,
      section16: false
    });
  };

  onSection16 = () => {
    this.props.onEndorsementSectionUpdate("Section 16")
    this.setState({
      section13: false,
      section15: false,
      section16: true
    });
  };
  handleSubmit = () => {
    if(this.props.newActDetails.Title !== '' && 
      this.props.newActDetails.Title !== undefined &&
      this.props.EndorsementPurpose !== '' &&
      this.props.EndorsementPurpose !== undefined &&
      this.props.EndorsementAppl.endorsementApplicationType !== '' &&
      this.props.EndorsementAppl.endorsementApplicationType !== undefined &&
      this.props.EndorsementAppl.endorsementSection !== '' &&
      this.props.EndorsementAppl.endorsementSection !== undefined){
      this.props.onCurrentEndorsementTabChange("2")
    } 
  }
  handleEndorsementPurposeChange = changeEvent => {
    changeEvent.preventDefault();
    this.props.onEndorsementPurposeChange(changeEvent.target.value, "EndorsementPurpose");
  };
  render() {
    this.props.newActDetails.MemNo = this.props.userDetails.memberNumber;
    let unclickedButton = "btn btn-outline-dark";
    let clickedButton = "btn btn-warning text-white";
    return (
      <div className="container">
        <GenericHeader
          title="Endorsement Application"
          showBackButton
          backFunction={() => {
            this.props.onCurrentEndorsementTabChange("0");
          }}
        />

        <div className="pl-2">
          <form className="">
            <div className="form-group mt-3">
              <div className="row">
                <div className="col-12">
                  <label htmlFor="title" align="left">
                    Endorsement title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    placeholder="Enter Description (Firearm and Caliber)"
                    onChange={this.handleChange("Title")}
                    name="Title"
                    value={this.props.newActDetails.Title}
                  />
                  {(this.props.newActDetails.Title === '' || this.props.newActDetails.Title === undefined) ? <p className="text-danger m-0">This can't be emplty</p> : null}

                </div>
              </div>
            </div>
            <div>
              <div className="form-group mt-3" />
            </div>
            <div className="form-group">
              <div className="row">
                <div className="container">
                  <div>
                    <div>What is the purpose of the application?</div>
                    <Radio
                      checked={this.props.endorsementPurpose === "New Lisence"}
                      onChange={e =>this.handleEndorsementPurposeChange(e)}
                      value="New Lisence"
                    />
                    New Lisence
                    <Radio
                      checked={this.props.endorsementPurpose === "Renewal"}
                      onChange={e =>this.handleEndorsementPurposeChange(e)}
                      value="Renewal"
                    />
                    Renewal
                  </div>
                  {(this.props.EndorsementPurpose === '' || this.props.EndorsementPurpose === undefined) ? <p className="text-danger m-0">Please select purpose</p> : null}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <label htmlFor="title" align="left">
                    Type of Application
                  </label>
                  <div />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementApplicationType === 'Self Defence'
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={this.selfDefence}
                  >
                    Self defence
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementApplicationType === 'Hunting'
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={this.onHunting}
                  >
                    Hunting
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementApplicationType === 'Sports Person'
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={this.onSportShooting}
                  >
                    Sport Shooting
                  </button>
                </div>
              </div>
              {(this.props.EndorsementAppl.endorsementApplicationType === '' || this.props.EndorsementAppl.endorsementApplicationType === undefined) ? <p className="text-danger m-0">Please select application type</p> : null}
              <div className="row mt-3">
                <div className="col-12">
                  <label htmlFor="title" align="left">
                    Section
                  </label>
                  <div />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementSection === "Section 13"
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={() => this.onSection13()}
                  >
                    13
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementSection === "Section 15"
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={() => this.onSection15()}
                  >
                    15
                  </button>
                </div>
                <div className="col-4">
                  <button
                    type="button"
                    className={
                      this.props.EndorsementAppl.endorsementSection === "Section 16"
                        ? clickedButton
                        : unclickedButton
                    }
                    onClick={() => this.onSection16()}
                  >
                    16
                  </button>
                </div>
              </div>
              {(this.props.EndorsementAppl.endorsementSection === '' || this.props.EndorsementAppl.endorsementSection === undefined) ? <p className="text-danger m-0">Please select section</p> : null}
              <div className="form-group mt-5" />
              <div className="form-group mt-5" />
              <div className="form-group mt-5" />
              <div className="form-group mt-5" />
              <div className="form-group mt-5" />
              <div className="form-group mt-5">
                <div className="row">
                  <div className="col-11">
                    <button
                      type="button"
                      className="btn btn-warning text-white float-right"
                      onClick={this.handleSubmit}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
              <div className="row fixed-bottom" align="right" />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

EndorsementApplicationDetails.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    newActDetails: state.endorsementReducer.addEndorsementDetails,
    endorsementPurpose: state.EndorsementPurposeReducer.EndorsementPurpose,
    MemberDetails: state.MemberViewReducer.MemberView,
    userDetails: state.loginReducer.userProfileDetails,
    EndorsementPurpose: state.EndorsementPurposeReducer.EndorsementPurpose,
    EndorsementAppl: state.EndorsementApplReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADD_ENDORSEMENT_DETAILS,
        varValue: value,
        varName: variableName
      }),
    onEndorsementPurposeChange: (value, vname) =>
      dispatch({
        type: actionTypes.CHANGE_ENDORSEMENT_PURPOSE,
        varValue: value,
        varName: vname
      }),
    onEndorsementSectionUpdate: endorsementSection =>
      dispatch({
        type: actionTypes.UPDATE_VALIDATE_SECTION,
        endorsementSection: endorsementSection
      }),
    onEndorsementApplicationTypeUpdate: endorsementApplicationType =>
      dispatch({
        type: actionTypes.UPDATE_VALIDATE__APPLICATION_TYPE,
        endorsementApplicationType: endorsementApplicationType
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EndorsementApplicationDetails));
