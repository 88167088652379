import React, { Component } from 'react';
import { SET_EVENT_RULES, SET_FIREARM_TYPES_LOOKUP, SET_EVENT_NAME } from '../../store/FireArmType/actionType';
import { connect } from 'react-redux';
import FireArmAction from '../../store/FireArmType/actions';
import FireArmTypeService from '../../services/firearmType.service';
import { targetDownsLoadLevels } from '../../shared/constants';
import {
  SubtitleBar,
  Loader
} from '../../components/index';
import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ViewEventRules from './WPViewEventRules';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  pointer: {
    cursor: 'pointer'
  },
});

class ActivityEventLookup extends Component {
  constructor(props) {
    super(props);
    this.fireArmTypeService = FireArmTypeService();
    this.eventRulesActions_ = this.props._eventRulesActions(this.fireArmTypeService);
    this.state = {
      isEventRules: false,
      isLoader: false
    };
  }

  goBack = () => {
    this.props.isFirearmTypeLookup(this.props.fireArmTypesLookups);
  };

  onViewEvent = (eventName) => {
    this.props.onEventNameUpdate(eventName);
    this.setState({ isEventRules: true, isLoader: false })
  };

  render() {
    const { classes } = this.props;
    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      <React.Fragment>
        {this.props.level === targetDownsLoadLevels.ACTIVITY_LOOK_UP && (<SubtitleBar title={this.props.level} back onBack={this.goBack}></SubtitleBar>)}
        {this.state.isLoader && (<Loader />)}
        <Grid container justify="center">
          {this.props.level === targetDownsLoadLevels.ACTIVITY_LOOK_UP && (<Grid item container xs={12} md={12} spacing={4} alignItems="center">
            {this.props.activatyLookups.length === 0 ? <Grid item>No activity event available</Grid> : null}
            {this.props.activatyLookups.map((item, index) => (
              item.ranking == true && <Grid key={index} item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
                      <Grid item xs={10}>
                        <Typography variant={isMobile ? "h6" : "h5"} style={{ color: "#4F36A6" }}>
                          {item.activityEvent}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.pointer}
                          onClick={() => {
                            this.setState({ isLoader: true });
                            this.eventRulesActions_.fetchEventByActivityID(item.activityEventId).then(() => this.onViewEvent(item.activityEvent));
                          }}
                        >
                          VIEW
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>)}
        </Grid>
        {this.state.isEventRules && (<ViewEventRules />)}
      </React.Fragment>
    );
  }
}

const initialiseEventRulesActions = dispatch => (fireArmTypeService) => {
  const action = FireArmAction(fireArmTypeService);
  return {
    fetchEventByActivityID: (id) => dispatch(action.fetchEventByActivityID(id)),
  };
};


const mapStateToProps = state => ({
  level: state.fireArmTypeTargets.level,
  fireArmTypesLookups: state.fireArmTypeTargets.fireArmTypesLookups,
  activatyLookups: state.fireArmTypeTargets.activatyLookups,
  eventRules: state.fireArmTypeTargets.eventRules,
});

const mapDispatchToProps = dispatch => ({
  onEventUpdate: (eventRules) =>
    dispatch({
      type: SET_EVENT_RULES,
      payload: eventRules
    }),
  _eventRulesActions: initialiseEventRulesActions(dispatch),
  isFirearmTypeLookup: (fireArmTypesLookup) =>
    dispatch({
      type: SET_FIREARM_TYPES_LOOKUP,
      payload: fireArmTypesLookup
    }),
  onEventNameUpdate: (eventName) =>
    dispatch({
      type: SET_EVENT_NAME,
      payload: eventName
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityEventLookup));