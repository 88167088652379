import Axios from 'axios';
import * as EndPoints from "../../API/APIendpoint";

export const submitApplication = async (data) => {
    const config = {
        url: `${EndPoints.BASE}${EndPoints.NEW_DS_APPLICATION}`,
        method: 'post',
        data,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(config);
}


