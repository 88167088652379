import {
  SET_DOCUMENTS
} from './actionTypes';

const fetchDocuments = service => () => dispatch => service
  .getDocuments()
  .then((res) => dispatch({
    type: SET_DOCUMENTS,
    payload: res.data.map(documents => ({
      ...documents
    })),
  }));

export default function(documentsService) {
  return {
    fetchDocuments: fetchDocuments(documentsService)
  };
}