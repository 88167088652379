// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import activitiesReducer from "./store/reducers/activities";
import contactUsReducer from "./store/reducers/contactUs";
import dedicatedStatusReducer from "./store/reducers/dedicatedStatus";
import downloadsReducer from "./store/reducers/downloads";
import licensesReducer from "./store/reducers/licenses";
import MemberViewReducer from "./store/reducers/viewMember";
import ActivityViewReducer from "./store/reducers/ViewActivities";
import LisenceViewReducer from "./store/reducers/ViewLicence";
import lmsReducer from "./store/reducers/lms";
import loginReducer from "./store/reducers/login";
import membersAreaReducer from "./store/reducers/membersArea";
import registrationReducer from "./store/reducers/registration";
import accountReducer from "./store/reducers/account";
import mainReducer from "./store/reducers/main";
import adminPortalReducer from "./store/reducers/adminPortal";
import articlesReducer from "./store/reducers/articles";
import notificationsReducer from "./store/reducers/notifications";
import activitiesParametersReducer from "./store/reducers/activityParameters";
import memberDetailsReducer from "./store/reducers/loggedInMember";
import fileUploaderReducer from "./store/reducers/fileUploader";
import storeLocatorReducer from "./components/storeLocator/storeLocatorReducer";
import DropDownReducer from "./store/reducers/DropDowns";
import dashBoardReducer from "./store/reducers/dashBoard";
import memberProfilePictureReducer from "./store/reducers/memberProfilePicture";
import endorsementReducer from "./store/reducers/endorsements";
import nationalRankingReducer from "./store/reducers/nationalRanking";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./materialUI/NarfoTheme";
import checkoutDefaultsReducer from "./store/reducers/checkoutDefaults";
import documentReducer from "./store/reducers/document";
import lmsCourseModuleReducer from "./store/lmsReducers/lmsCourseModule";
import lmsStatistics from "./store/lmsReducers/lmsStatistics";
import NewActivityReducer from "./components/loggeddInUser/activitiesPage/NewActivityReducer";
import FilterReducer from "./components/loggeddInUser/activitiesPage/Filter/FilterReducer";
import PendingActivityReducer from "./components/adminPortal/Approvals/PendingActivitySubComponents/PendingActivityReducer";
import ApproveNewMemberReducer from "./components/adminPortal/Approvals/ApproveNewMembers/ApproveNewMemberReducer";
import ApproveNewEndorsementsReducer from "./components/adminPortal/Approvals/ApproveNewEndorsements/ApproveNewEndorsementsReducer";
import ApproveNewDSReducer from "./components/adminPortal/Approvals/ApproveNewDS/ApproveNewDSReducer";
import DSApplicationReducer from "./components/dedicatedStatusApplication/DedicatedStatusApplicationReducer";
import LoyaltyDaysReducer from "./store/reducers/loyaltyDays";
import CommissionReducer from "./store/reducers/commission";
import CommissionCategoryReducer from "./store/reducers/commissionCategory";
import CommissionRecordReducer from "./store/reducers/commissionRecords";
import Base64ImagesReducer from "./store/reducers/base64Images";
import EndorsementPurposeReducer from "./store/reducers/endorsementPurpose";
import RecaptchaKeyReducer from "./store/reducers/recaptureKeys";
import EndorsementApplReducer from './store/reducers/endorsementAppl';
import ActivityAprrovalReducer from './store/reducers/activityAproval';
/*VERSION 3
*/
import AuthReducer from './v3/store/Auth/Login/reducer';
import ProfileReducer from './v3/store/Profile/reducer';
import NotificationReducer from './v3/store/Notifications/reducer';
import LicenceReducer from './v3/store/Licence/reducer';
import nCommissionReducer from './v3/store/SalesCommissions/reducer';
import nActivitiesReducer from './v3/store/Activities/reducer';
import nLookupReduder from './v3/store/Lookup/reducer';
import nEndorsementReducer from './v3/store/Endorsements/reducer';
import nDocumentsReducer from './v3/store/Documents/reducer';
import nDedicatedStatusReducer from './v3/store/DedicatedStatus/reducer';
import nLearningManagementReducer from './v3/store/LearningManagement/reducer';
import nSignupReducer from './v3/store/Auth/Signup/reducer';
import fireArmTypeReducer from './v3/store/FireArmType/reducer';
/*
*/

/*VERSION 2
 */
import GeneralPageTogglerReducer from './store/reducers/generalPageToggler';
import NewsIdReducer from './store/reducers/newsId';
import GenericTabbedPaneTogglerReducer from './store/reducers/genericTabbedPaneToggler';
import NextPageReducer from './store/reducers/nextPage';
import HuntingTogglerReducer from './store/reducers/huntingToggler';
import SportShootingToggleReducer from './store/reducers/sportShootingToggle';
/*
 */

function saveTolocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (e) {
    console.log(e)
  }
}
function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch (e) {
    console.log(e)
    return undefined;
  }
}
const rootReducer = combineReducers({
  LoyaltyDaysReducer: LoyaltyDaysReducer,
  DropDownReducer: DropDownReducer,
  activitiesReducer: activitiesReducer,
  contactUsReducer: contactUsReducer,
  dedicatedStatusReducer: dedicatedStatusReducer,
  downloadsReducer: downloadsReducer,
  licensesReducer: licensesReducer,
  MemberViewReducer: MemberViewReducer,
  LisenceViewReducer: LisenceViewReducer,
  ActivityViewReducer: ActivityViewReducer,
  lmsReducer: lmsReducer,
  loginReducer: loginReducer,
  membersAreaReducer: membersAreaReducer,
  registrationReducer: registrationReducer,
  accountReducer: accountReducer,
  mainReducer: mainReducer,
  adminPortalReducer: adminPortalReducer,
  endorsementReducer: endorsementReducer,
  nationalRankingReducer: nationalRankingReducer,
  articlesReducer: articlesReducer,
  notificationsReducer: notificationsReducer,
  activitiesParametersReducer: activitiesParametersReducer,
  checkoutDefaultsReducer: checkoutDefaultsReducer,
  memberDetailsReducer: memberDetailsReducer,
  dashBoardReducer: dashBoardReducer,
  memberProfilePictureReducer: memberProfilePictureReducer,
  fileUploaderReducer: fileUploaderReducer,
  storeLocatorState: storeLocatorReducer,
  documentReducer: documentReducer,
  newActivityState: NewActivityReducer,
  pendingActivityState: PendingActivityReducer,
  lmsCourseModuleReducer: lmsCourseModuleReducer,
  lmsStatistics: lmsStatistics,
  activityFilterState: FilterReducer,
  approveNewMemberState: ApproveNewMemberReducer,
  approveNewEndorsementsState: ApproveNewEndorsementsReducer,
  approveNewDSState: ApproveNewDSReducer,
  DSApplicationState: DSApplicationReducer,
  CommissionReducer: CommissionReducer,
  CommissionCategoryReducer: CommissionCategoryReducer,
  CommissionRecordReducer: CommissionRecordReducer,
  Base64ImagesReducer: Base64ImagesReducer,
  EndorsementPurposeReducer: EndorsementPurposeReducer,
  RecaptchaKeyReducer: RecaptchaKeyReducer,
  EndorsementApplReducer: EndorsementApplReducer,
  ActivityAprrovalReducer: ActivityAprrovalReducer,

  /*VERSION 3*/
  AuthReducer: AuthReducer,
  ProfileReducer: ProfileReducer,
  NotificationReducer: NotificationReducer,
  LicenceReducer: LicenceReducer,
  nCommissionReducer: nCommissionReducer,
  nActivitiesReducer: nActivitiesReducer,
  nLookupReduder: nLookupReduder,
  nEndorsementReducer: nEndorsementReducer,
  nDocumentsReducer: nDocumentsReducer,
  nDedicatedStatusReducer: nDedicatedStatusReducer,
  nLearningManagementReducer: nLearningManagementReducer, 
  nSignupReducer: nSignupReducer,
  fireArmTypeTargets: fireArmTypeReducer,
  /**/

  /*VERSION 2*/
  GeneralPageTogglerReducer: GeneralPageTogglerReducer,
  NewsIdReducer: NewsIdReducer,
  GenericTabbedPaneTogglerReducer: GenericTabbedPaneTogglerReducer,
  NextPageReducer: NextPageReducer,
  HuntingTogglerReducer: HuntingTogglerReducer,
  SportShootingToggleReducer: SportShootingToggleReducer
  /**/
});

const persistedState = loadFromLocalStorage()
const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(thunk))
);
store.subscribe(() => saveTolocalStorage(store.getState()));

const readUrl = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);

ReactDOM.render(
  <Provider store={store}>
      <App 
	    snippedUrl={readUrl} 
	  />
  </Provider>,
  document.getElementById("root"),
  document.addEventListener("click", function () {
    sessionStorage.setItem("event", "click")
  }),
  document.addEventListener("scroll", function () {
    sessionStorage.setItem("event", "scroll")
  }),
  document.addEventListener("keypress", function () {
    sessionStorage.setItem("event", "keypress")
  })
);

serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});