import React, { Component } from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";

export class TakeCourse extends Component {
  render() {
    return (
      <div className="container" style={{ height: "100%" }}>
        <div className="row">
          <GenericHeader
            title={this.props.oneLesson.title + " Course Material"}
            showBackButton
            backFunction={() => this.props.onCurrentLMSTabChange(1)}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div dangerouslySetInnerHTML={{__html: this.props.oneLesson.content}} />
          </div>
          <div className="col-1"> </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    oneLesson: state.lmsReducer.lesson,
    currentLMSpage: state.lmsReducer.currLmsTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLessonChange: newLesson =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSON,
        lesson: newLesson
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TakeCourse);
