export const FETCH_MEMBER_DATA_SUCCESSFUL = 'FETCH_MEMBER_DATA_SUCCESSFUL';
export const FETCH_MEMBER_DATA_ERROR = 'FETCH_MEMBER_DATA_ERROR';
export const SET_MEMBER_DATA = 'SET_MEMBER_DATA';
export const SET_MEMBER_PROFILE_PICTURE = 'SET_MEMBER_PROFILE_PICTURE';
export const SET_MEMBER_DEDICATED_STATUS = 'SET_MEMBER_DEDICATED_STATUS';
export const SET_MEMBER_LOYALTY_DAYS = 'SET_MEMBER_LOYALTY_DAYS';
export const SET_MEMBER_ACCOUNT_HISTORY = 'SET_MEMBER_ACCOUNT_HISTORY';
export const SET_MEMBER_DEDICATED_STATUS_ACTIVITIES = 'SET_MEMBER_DEDICATED_STATUS_ACTIVITIES';
export const SET_PAYFAST_DETAILS = 'SET_PAYFAST_DETAILS';
export const EMAIL_SET_MEMBER_DATA_2 = 'EMAIL_SET_MEMBER_DATA_2';
export const EMAIL_FETCH_MEMBER_DATA_ERROR_2 = 'EMAIL_FETCH_MEMBER_DATA_ERROR_2'; 
