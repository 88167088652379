import React, { Component } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import {
  COURSE_MODULE,
  COURSE_MATERIAL
} from "../LMSAPIEndPoints";

export default class BulkDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteStatusMessage: `${this.props.idArray.length} ${this.props.isFolder ?
        'module(s)' : 'resource(s)'} will be deleted, Are you sure?`,
      onDelete: false
    }

    this.count = 0;
  }
  deleteModule = (id, name, isParent) => {
    this.setState({
      deleteStatusMessage: 'Deleting ' + name + '...',
      onDelete: true
    })
    if (isParent !== undefined) {
      let url = "";
      if (this.props.isFolder) {
        url = COURSE_MODULE
      } else {
        url = COURSE_MATERIAL
      }
      fetch(url + '/' + id,
        {
          method: 'POST'
        }
      )
        .then(res => res.json())
        .then(res => {
          this.setState({
            deleteStatusMessage: res.message
          })
          this.count += 1;
          setTimeout(() => {
            if (this.count === this.props.idArray.length) {
              this.props.refresh();
              this.props.onClick(0);
            }
          }, 1500);
        })
        .catch(error => {
          this.setState({
            deleteStatusMessage: error.message ? error.message : 'Failed to delete! Try again.'
          })
          this.count += 1;
          setTimeout(() => {
            if (this.count === this.props.idArray.length) {
              this.props.onClick(0);
            }
          }, 1500);
        })
    } else {
      this.setState({
        deleteStatusMessage: 'Something went wrong'
      })
      setTimeout(() => {
        this.props.onClick(0);
      }, 2000);
    }
  }

  deleteAll = () => {
    this.props.idArray.forEach(elem => {
      this.deleteModule(elem.id, elem.name, elem.folder);
    })
  }

  render() {
    return (
      <div className="popup-menu-container">
        <Delete className="nocursor" />
        <label>{this.state.deleteStatusMessage}</label>
        <span className={this.state.onDelete ? 'hide' : ''} >
          <Check onClick={() => { this.deleteAll() }} />
          <Clear onClick={() => { this.props.onClick(5) }} />
        </span>
      </div>
    )
  }
}
