import {
  POPULATION_WEEK_DATA,
  POPULATION_MONTH_DATA,
  POPULATION_YEAR_DATA
} from "../actions/lmsActionTypes";

const initialState = {
  weekData: [],
  monthData: [],
  yearData: []
}

function isLoadWhenExisting(dataArray, key, data){
  const array = dataArray;
  const index = array.findIndex(element => element.key === key);

  if(index > 0){
    array[index] = {
      key: key,
      data: data
    }
  } else {
    array.push({
      key: key,
      data: data
    })
  }
  return Array.from(array);
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case POPULATION_WEEK_DATA:
      return {
        ...state,
        weekData: isLoadWhenExisting(state.weekData, action.key, action.payload)
      }
    case POPULATION_MONTH_DATA:
      return {
        ...state,
        monthData: isLoadWhenExisting(state.monthData, action.key, action.payload)
      }
    case POPULATION_YEAR_DATA:
      return {
        ...state,
        yearData: isLoadWhenExisting(state.yearData, action.key, action.payload)
      }
    default:
      return state;
  }
}

export default reducer;