import React from 'react';
import {DateTime} from 'luxon';
import {
  MEMBER_STATUS
} from './constants';
import { checkValidity } from './utility';
import { Grid, Typography} from '@material-ui/core';
import Link from '@material-ui/core/Link';

export const currentYear = DateTime.now().year;
export const todaysDate = DateTime.now();
var aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
export const financialYear = aYearFromNow.getFullYear();

export const getMemberStatusFromInt = (digit) => {
  switch (digit) {
  case 1:
    return MEMBER_STATUS.APPROVED;
  case 2:
    return MEMBER_STATUS.PENDING;
  case 3:
    return MEMBER_STATUS.REJECTED;
  case 4:
    return MEMBER_STATUS.RENEWING;
  default:
    return '';
  }
};

export const getDedicatedStatusTextFromStatusObject = (dedicatedStatus) => {
  if (dedicatedStatus.both) {
    return 'Dedicated sports shooter and dedicated hunter';
  } else if (dedicatedStatus.hunting) {
    return 'Dedicated hunter';
  } else if (dedicatedStatus.sport) {
    return 'Dedicated sport shooter';
  }
  return 'No dedicated status';
};

export const getDedicatedStatusNumberFromMember = (allDedicatedStatus, action) => {
// The DS object on the member redux is an object of status objects, however there should not
// be more than 2 for a regular user. We loop through the outer object and test is the action type
// of the status object is of type action, and then return the DS number for that object.
  if (!allDedicatedStatus)
  {
    return 'no dedicated status';
  } else {
    for (let singleStatus of allDedicatedStatus) {
      if (singleStatus.dstype == action && singleStatus.status === 'Approved') {
        return singleStatus.dsno;
      }
    }
    return 'no dedicated status';
  }
};


export const formatISODateToLocaleString = (isoDate) => {
  const dateTime = DateTime.fromISO(isoDate);

  if(!dateTime.isValid) {
    return 'invalid';
  }

  // return dateTime.toLocaleString({ locale: 'en-gb' });
  return dateTime.toLocaleString({ locale: 'af-ZA' }); //Change to SA CultureInfo

};

export const validateEmail = (email) => {
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  return emailRegex.test(email);
};

export const getDedicatedStatusFromActivities = (activity,dsApplications) => {
  // let numberOfActivities = activity;
  // if(activity && activity.includes(' ')) {
  //   numberOfActivities = activity.split(' ')[0];
  // }

  //If there's status 
  if(dsApplications)
      return "Active";
  else
    return 'Not Active';
    
  // (myStatus.dedicatedSportsStatus == `Not Active` ? "Do not comply" : myStatus.dedicatedSportsStatus == `Active` ? "Comply" : "No Application") 
};

export const getDayDifferenceFromToday = (isoDate) => {
  const targetDate = DateTime.fromISO(isoDate);
  const result = targetDate.diff(todaysDate, 'days').toObject();
  return result.days;
};

export const getYearFromISO = (isoDate) => {
  const dateTime = DateTime.fromISO(isoDate);

  if(!dateTime.isValid) {
    return 'invalid';
  }

  return dateTime.year.toString();
};

export const getUniqueValuesfromObjectArray = (array, key) => {
  const res = {};

  array.forEach(obj => {
    res[`${typeof obj[key]}_${obj[key]}`] = obj[key];
  });

  return Object.keys(res).map(key => res[key]);
};

export const yearsRange = (start, stop, step) => Array
  .from(
    { length: (stop - start) / step + 1},
    (_, i) => start + (i * step)
  );

export const getStatusColor = (statusString) => {
  const colorObject = {
    [MEMBER_STATUS.APPROVED]: 'success-text',
    [MEMBER_STATUS.PENDING]: 'warning-text',
    [MEMBER_STATUS.REJECTED]: 'danger-text',
    [MEMBER_STATUS.RENEWING]: 'info-text',
  };
  return colorObject[statusString];
};

export const valid = (value, rules) => {
  const res = checkValidity(value === '-' ? '' : String(value), rules);
  return {
    valid: res.isValid,
    errors: res.errors,
  };
};

export const label = (title, value, hasDescription, isMobile) => (
  <React.Fragment>
    {hasDescription ? 
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant={isMobile ? 'h6' : 'body2'} align="left" className="word-break">
          {value || '-'}
        </Typography> <br/>
        <Typography align="left">{title || '-'}</Typography>
      </Grid> : 
      <Grid item xs={12} sm={4} md={4} container direction="column">
        <Typography variant={isMobile ? 'h6' : 'body2'} align="left">{value || '-'}</Typography>
        <Typography align="left">{title || '-'}</Typography>
      </Grid>}
  </React.Fragment>
);

export const ButtonMailto = ({ mailto, label }) => {
  return (
      <Link
          to='#'
          onClick={(e) => {
              window.location = mailto;
              e.preventDefault();
          }}
      >
          {label}
      </Link>
  );
};

export const ButtonCallNumber = ({ call, label }) => {
  return (
      <Link
          to= "#"
          onClick={(e) => {
              window.location = call;
              e.preventDefault();
          }}>
          {label}
      </Link>
  );
};


export const labelHtml = (title, value, hasDescription, isMobile) => (
  <React.Fragment>
    {hasDescription ? 
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant={isMobile ? 'h6' : 'body2'} align="left" className="word-break">
          {value || '-'}
        </Typography> <br/>
        <Typography align="left">{title || '-'}</Typography>
      </Grid> : 
      <Grid item xs={12} sm={4} md={4} container direction="column">
        
        { title != 'Email Address' && title != 'Cell Number'?
          <Typography variant={isMobile ? 'h6' : 'body2'} align="left">{value || '-'}</Typography> :
          
          title === 'Cell Number' ? 
          <Typography variant={'body2'} variant="subtitle2" align="left"><ButtonCallNumber label={value} call={"tel:" + value} /></Typography>:
          <Typography variant={'body2'} variant="subtitle2" align="left"><ButtonMailto label={value} mailto={"mailto:" + value} /></Typography>
          }
        <Typography align="left">{title || '-'}</Typography>

      </Grid>}
  </React.Fragment>
);

export const DateFind = (dated) => {
  if (!dated) {
    return dated;
  }
  var date = new Date(Date.parse(dated));
  var currentDate = date;
  var dater = currentDate.getDate();
  var months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  var month = currentDate.getMonth();
  var year = currentDate.getFullYear();
  var monthDateYear = dater + ' ' + months[month] + ' ' + year;
  return monthDateYear;
};