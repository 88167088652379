import { Button } from "@material-ui/core";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { connect } from "react-redux";
import "react-vertical-timeline-component/style.min.css";
import { BASE, FRONT, POST_DEDICATED_STATUS_VIEW } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { STATUS } from "../../store/constantFile/ConstFile";
import "./dhunter.css";
class DedicatedProfileView extends Component {
  constructor(props) {
    super(props);
    this.handleReason = this.handleReason.bind(this);
    this.state = {
      DedicatedStatusApplication: props.application,
      reason: false,
      textReason: "",
      practicalAssessment: "",
      currentStatus: "",
      proffessionalHunter: {}
    };
  }
  componentDidMount() {
    axios
      .get(
        BASE +
          "api/Activities/activityScore/" +
          this.state.DedicatedStatusApplication.memNo,{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
      
      )
      .then(res =>
        this.setState({
          practicalAssessment: res.data
        })
      );
      axios({
        url: BASE + "api/ProffessionalHunters/getPHunterByDId/" + this.state.DedicatedStatusApplication.dedicatedId,
        method: "get",
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        this.setState({
          proffessionalHunter: res.data.pfh
        })
      })
      .catch(() => {
        this.setState({
          proffessionalHunter: false
        })
      });
  }
  refreshPage() {
    const user = {
      memNo: this.props.memberState.isAdmin
        ? this.props.MemberV.memNo
        : this.props.userProfileDetails.memberNumber
    };
    axios({
      url: BASE + POST_DEDICATED_STATUS_VIEW,
      data: user,
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      if (res.data.status === "success") {
        this.props.onDedicatedChange(res.data.dedicated, "Application");
      }
    })
    .catch(function(error) {});
    this.props.onDedicatedChange("Home", "Page");
  }
  handleTextArea = event => {
    this.setState({ textReason: event.target.value });
  };
  handleReason(actionType) {
      switch (actionType) {
        case STATUS.Declined: {
          const request = {
            Approved: STATUS.Declined,
            Remarks: this.state.textReason,
            Dsno: this.props.application.dsno
          };
          this.handleAxiosStatus(request);
          break;
        }
        case STATUS.Terminated: {
          const request = {
            Approved: STATUS.Terminated,
            Remarks: this.state.textReason,
            Dsno: this.props.application.dsno
          };
          this.handleAxiosStatus(request);
          break;
        }
        default:
      }
  }
  handleAxiosStatus(request) {
    switch (request.Approved) {
      case STATUS.Approved: {

       let id = this.state.DedicatedStatusApplication.dedicatedId;

        axios({
          url: BASE + `api/DedicatedStatus/approval/${id}/1`,
          data: request,
          method: "post",
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        })
        .then(req => {
          if (req.status === 200) {
            // alert(req.data.message);
            this.refreshPage();
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            alert(err.response.data.message);
          } else {
            alert("Unable to log status please try again later");
          }
        });
        break;
      }
      case STATUS.Terminated: {
        axios({
          url: BASE + "api/DedicatedStatus/terminate",
          data: request,
          method: "post",
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        })
        .then(req => {
          if (req.status === 200) {
            alert(req.data.message);
            this.refreshPage();
          }
        })
        .catch(err => {
          if (err.response.status === 400) {
            alert(err.response.data.message);
          } else {
            alert("Unable to log status please try again later");
          }
        });
        break;
      }
      case STATUS.Declined: {
        axios({
          url: BASE + "api/DedicatedStatus/decline",
          data: request,
          method: "post",
          headers: {
            "Content-type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
          }
        })
        .then(reps=>{
          // alert("Success");
          this.refreshPage();
        })
        .catch(err => {
          if (err.response.status === 400) {
            alert(err.response.data.message);
          } else {
            alert("Unable to log status please try again later");
          }
        });
        break;
      }
      default:
    }
  }
  render() {
    return (
      <div>
        <table className="table table-borderless table-sm mb-1 ml-2">
          <tbody>
            {
              <tr>
                <th>Dedicated Status No </th>
                <th className="font-weight-bold">: {this.state.DedicatedStatusApplication.dsno}</th>
              </tr>
            }
            <tr>
              <th>Application Type </th>
              <th className="font-weight-bold">: {this.state.DedicatedStatusApplication.dstype}</th>
            </tr>
            <tr>
              <th>Application Date </th>
              <th className="font-weight-bold">
                : {this.state.DedicatedStatusApplication.applicationDate.split(
                  "T",
                  1
                )}
              </th>
            </tr>
            <tr>
              <th>Status </th>
              {this.state.DedicatedStatusApplication.status ===
                STATUS.Approved && (
                <th className="text-success font-weight-bold">
                  : {this.state.DedicatedStatusApplication.status}
                </th>
              )}
              {this.state.DedicatedStatusApplication.status ===
                STATUS.Pending && (
                <th className="text-warning font-weight-bold">
                  : {this.state.DedicatedStatusApplication.status}
                </th>
              )}
              {this.state.DedicatedStatusApplication.status ===
                STATUS.Declined && (
                <th className="text-danger font-weight-bold">
                  : {this.state.DedicatedStatusApplication.status}
                </th>
              )}
              {this.state.DedicatedStatusApplication.status ===
                STATUS.Terminated && (
                <th className="text-danger font-weight-bold">
                  {this.state.DedicatedStatusApplication.status}
                </th>
              )}
            </tr>
            <tr>
              <th>Approval Date</th>
              {this.state.DedicatedStatusApplication.approvalDate === null && (
                <th className="text-left font-weight-bold">: -</th>
              )}
              {!this.state.DedicatedStatusApplication.approvalDate ? null : (
                <th className="font-weight-bold">
                  : {this.state.DedicatedStatusApplication.approvalDate.split("T",1)}
                </th>
              )}
            </tr>
            <tr>
              <th>Motivation</th>
              {this.state.DedicatedStatusApplication.motivation === null && (
                <th className=" text-left font-weight-bold">: -</th>
              )}
              {this.state.DedicatedStatusApplication.motivation !== null && (
                <th className="font-weight-bold">: {this.state.DedicatedStatusApplication.motivation}</th>
              )}
            </tr>
            {this.state.DedicatedStatusApplication.status ===
              STATUS.Declined && (
              <tr>
                <th>Refusal Reason</th>

                <th className="text-danger font-weight-bold">
                  : {this.state.DedicatedStatusApplication.remarks}
                </th>
              </tr>
            )}
            {this.state.DedicatedStatusApplication.status ===
              STATUS.Terminated && (
              <tr>
                <th>Termination Reason</th>

                <th className="text-danger font-weight-bold">
                  : {this.state.DedicatedStatusApplication.remarks}
                </th>
              </tr>
            )}
            {this.state.proffessionalHunter === false ? null : (
              <tr>
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th>Currently holds a <p className="font-weight-bold mb-0">Proffesional Hunter</p> Status</th>
                : null}
              </tr>
            )}
            {this.state.proffessionalHunter === false ? null : (
              <tr>
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th>Registration Number</th>
                : null}
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th className="font-weight-bold">: {this.state.proffessionalHunter.regNo}</th>
                : null}
              </tr>
            )}
            {this.state.proffessionalHunter === false ? null : (
              <tr>
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th>Association Name</th>
                : null}
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th className="font-weight-bold">: {this.state.proffessionalHunter.association}</th>
                : null}
              </tr>
            )}
            {this.state.proffessionalHunter === false ? null : (
              <tr>
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th>Province</th>
                : null}
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th className="font-weight-bold">: {this.state.proffessionalHunter.province}</th>
                : null}
              </tr>
            )}
            {this.state.proffessionalHunter === false ? null : (
              <tr>
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th>Expiry Date</th>
                : null}
                {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                  <th className="font-weight-bold">: {!this.state.proffessionalHunter.expiryDate ?
                          null :
                          this.state.proffessionalHunter.expiryDate.split("T",1)}</th>
                : null}
              </tr>
            )}
            {this.state.proffessionalHunter === false || this.state.proffessionalHunter.link === null ? null : (
              <tr>
              {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
               <th>Proffessional Hunter Document </th>
              : null}
              {this.state.DedicatedStatusApplication.dstype === "Dedicated Hunter" ?
                <th className="font-weight-bold">
                  : <a
                        className="text-primary col-4 pl-0 mb-1"
                        href={FRONT + this.state.proffessionalHunter.link}
                        target="_"
                        styles={{cursor: "pointer"}}
                      >
                        Document
                  </a>
                </th>
              : null}
            </tr>
            )}

            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                <tr>
                  <th>
                    Currently holds a <p className="font-weight-bold mb-0">{this.state.DedicatedStatusApplication.dstype}</p>{" "}status
                  </th>
                </tr>
              ) : 
            null}
            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                <tr>
                  <th>Dedicated Status No</th>
                  <th className="font-weight-bold">
                    : {
                        this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
                          .dsNo
                      }
                  </th>
                </tr>
              ) : 
            null}
            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                <tr>
                  <th>Association Name</th>
                  <th className="font-weight-bold">
                    : {
                        this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
                        .associationName
                      }
                  </th>
                </tr>
              ) : 
            null}
            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                <tr>
                  <th>Association FAR No</th>
                  <th className="font-weight-bold">
                    : {
                      this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
                        .farNo
                      }
                  </th>
                </tr>
              ) : 
            null}
            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                <tr>
                  <th>Expiry Date</th>
                  <th className="font-weight-bold">
                    : {this.state.DedicatedStatusApplication.otherDedicatedStatus[0].expiryDate.split("T",1)}
                  </th>
                </tr>
              ) : 
            null}
            {this.state.DedicatedStatusApplication.otherDedicatedStatus.length !==
              0 ? !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
              .associationName ? null : (
                !this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
                  .link ? null : (
                  <tr>
                    <th>{ `${this.state.DedicatedStatusApplication.otherDedicatedStatus[0]
                        .associationName} Document`
                        }  
                    </th>
                    <th className="font-weight-bold">
                      : <a
                            className="text-primary col-4 pl-0 mb-1"
                            href={FRONT + this.state.DedicatedStatusApplication.otherDedicatedStatus[0].link}
                            target="_"
                            styles={{cursor: "pointer"}}
                          >
                            Document
                       </a>
                    </th>
                  </tr>
                )
              ) : 
            null}
          </tbody>
        </table>
        {this.props.memberState.isAdmin && !this.props.isPartner && (
          <div>
            {" "}
            <div className="row mb-3">
              <div className="col">
                <button
                  className="btn btn-outline-success container"
                  onClick={() => {
                    this.setState = {
                      reason: false
                    };
                    this.handleAxiosStatus({
                      Approved: STATUS.Approved,
                      Dsno: this.props.application.dsno
                    });
                  }}
                >
                  Approve
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-primary container"
                  onClick={() => {
                    this.handleAxiosStatus({
                      Approved: STATUS.Approved,
                      Dsno: this.props.application.dsno
                    });
                  }}
                >
                  Re Activate
                </button>
              </div>
              <div className="col">
                <button
                  onClick={() => {
                    this.setState({
                      reason: true,
                      currentStatus : "Decline"
                    })
                  }}
                  className="btn btn-outline-danger container"
                >
                  Decline
                </button>
              </div>
              <div className="col">
                <button
                  onClick={() => {
                    this.setState({
                      reason: true,
                      currentStatus : "Terminate"
                    })
                  }}
                  className="btn btn-outline-secondary container"
                >
                  Terminate
                </button>
              </div>
            </div>
            <div className="row">
              {this.state.reason && (
                <div>
                  <textarea
                    onChange={this.handleTextArea}
                    className="container form-control border-danger  text-danger"
                    rows="4"
                    placeholder="Please provide a reason"
                  />
                  <Button
                    size="small"
                    onClick={() => {
                      this.setState = {
                        reason: false
                      };
                      this.state.currentStatus === "Decline" ? this.handleReason(STATUS.Declined) : this.handleReason(STATUS.Terminated);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState,
    MemberV: state.MemberViewReducer.MemberView
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onDedicatedChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DEDICATED_STATUS,
        varValue: value,
        varName: vname
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DedicatedProfileView);
