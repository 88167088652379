import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import axios from "axios";
import { BASE } from "../../../API/APIendpoint";
class LoyaltyDays extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.onCommissionTabChange("0");
        this.props.onAddAccountDetailsChange(0, "currentAccountTab");
        this.props.onCurrentLMSTabChange(0);
        this.props.onCurrentEndorsementTabChange("0");
        this.props.onCurrActivityTabChange("0", "currentActivityTab");
        this.props.onCurrentLicenseTabChange("0", "currentLicenseTab")
        let _memNo = this.props.userDetails.adminLevel && window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1) !== "MembersArea"?
                this.props.MemberV.memNo:
                this.props.memberDetails.memberNumber
        axios.get(BASE + "api/LoyaltyDaysAdjust/getLoyaltyDays/" + _memNo,{
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(resp => {
              this.props.onLoyaltyDaysGet(resp.data.daysList)
              this.props.onLoyaltyDaysArrayRecordGet(resp.data.daysHistory)
              this.props.onLoyaltyDaysRecordGet(resp.data.daysAdded)

            //   this.props.onLoyaltyDaysArrayRecordGet(resp.data.daysHistory.result.value.daysRecordObject)
            //   this.props.onLoyaltyDaysRecordGet(resp.data.daysAdded.value.daysObject)

            })
    }
    render() {
        const totalNumDays = (!this.props.memLoyaltyRecord.dedicatedStatusDays ?
            null :
            this.props.memLoyaltyRecord.dedicatedStatusDays) +
            (!this.props.memLoyaltyRecord.courseDays ?
            null :
            this.props.memLoyaltyRecord.courseDays) +
            (!this.props.memLoyaltyRecord.endorsementDays ?
            null :
            this.props.memLoyaltyRecord.endorsementDays) +
            (!this.props.memLoyaltyRecord.generalActivityDays ?
            null :
            this.props.memLoyaltyRecord.generalActivityDays);
        const loyaltyInfo = (daysNum, typeDays, tabChangeNum) => (
            !daysNum ? null :
            <tr>
                <td>{typeDays}</td>
                <td>
                    <button 
                        className="btn btn-primary"
                        onClick={() => this.props.onLoyaltyDaysTabChange(tabChangeNum)}
                    >{daysNum}</button>
                </td>
            </tr>
        );
        return(
            <div className={this.props.isPartner ? "" : "container"}>
                <GenericHeader 
                    headerColor={this.props.headerColor}
                    title="Loyalty Days"
                    showTotalLoyaltyDays
                    totalDays={totalNumDays}
                />
                <div className={this.props.isPartner ? "" : "container"}>
                    <div className="row pl-3 pr-3">
                        The loyalty program adds days to your membership every time you submit an activity or complete a course or successfully apply for a dedicated status. 
                        The program runs on an annual cycle and you can earn a maximum of 30 loyalty days for each year cycle.
                    </div>
                    <div className="row bg-light pt-1 pl-3 mb-2 mt-2">
                        <h6>Loyalty Days History</h6>
                    </div>
                </div>
                {totalNumDays === 0 ? 
                <p className="pl-1 pr-1 text-warning font-weight-bold">You have not earned loyalty days yet...</p> :
                <div className={this.props.isPartner ? "d-flex justify-content-center" : "container d-flex justify-content-center"}>
                    <table style={{width:"100%"}} className="text-left">
                        <tbody>
                            <tr className="pb-2">
                                <th className="font-weight-bold">Type Of Days Earned</th>
                                <th className="font-weight-bold">Total</th>
                            </tr>
                            {loyaltyInfo(this.props.memLoyaltyRecord.dedicatedStatusDays, 
                                "Dedicated Status Days Earned",
                                "1")}
                            {loyaltyInfo(this.props.memLoyaltyRecord.courseDays, 
                                "Course Days Earned",
                                "2")}
                            {loyaltyInfo(this.props.memLoyaltyRecord.endorsementDays, 
                                "Endorsement Days Earned",
                                "3")}            
                            {loyaltyInfo(this.props.memLoyaltyRecord.generalActivityDays, 
                                "Activity Days Earned",
                                "4")}
                        </tbody>
                    </table>
                </div>}
            </div>
        )
    }
}
const mapStateToProps = state => {
return {
        memberDetails: state.loginReducer.memberObject,
        memLoyaltyRecord: state.LoyaltyDaysReducer.loyaltyRecord,
        MemberV: state.MemberViewReducer.MemberView,
        userDetails: state.loginReducer.userProfileDetails
    };
};
const mapDispatchToProps = dispatch => {
return {
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onLoyaltyDaysTabChange: pageNum =>
        dispatch({ 
            type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
            currentLoyaltyDaysTab: pageNum 
        }),
    onAddAccountDetailsChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          varValue: value,
          varName: variableName
        }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
        dispatch({
          type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
          currLmsTab: newCurrentLmsTab
        }),
    onCurrentEndorsementTabChange: newTabName =>
        dispatch({
          type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
          currTab: newTabName
        }),
    onCurrActivityTabChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: vname
        }),
    onCurrentLicenseTabChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: variableName
        }),
    onLoyaltyDaysGet: loyaltyDaysObject =>
        dispatch({
          type:actionTypes.GET_LOYALTY_DAYS,
          loyaltyDaysObject: loyaltyDaysObject
        }),
    onLoyaltyDaysArrayRecordGet: aRecord =>
        dispatch({ 
            type: actionTypes.GET_LOYALTY_DAYS_ARRAY, 
            loyaltyRecordArray: aRecord 
        }),
    onLoyaltyDaysRecordGet: record =>
        dispatch({ 
            type: actionTypes.GET_MEM_LOYALTY_RECORD, 
            loyaltyRecord: record 
        }),
    };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(LoyaltyDays);