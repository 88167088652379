import React, { Component } from "react";
import { connect } from "react-redux";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader.js";
import {MEMBER_ASSESSMENT} from "../LMSAPIEndPoints";

export class Lessons extends Component {
  getMemberResultForThisCourse = () => {
    fetch(MEMBER_ASSESSMENT + '/' + this.props.memberNo,{headers:{'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`}} )
    .then(res => res.json())
    .then(res => {
      this.setState({
        results: Array.from(res.data)
      })
    })
    .catch(() => {
      this.setState({
        results: 'Error'
      })
    })
  };

  componentDidMount(){
    this.getMemberResultForThisCourse();
  }

  getScore = (id) => {
    if(this.state.results === 'Error'){
      return 'Error';
    }
    if(this.state.results.length === 0){
      return 'Not Taken'
    }
    const result = this.state.results.find(res => res.courseMaterialId === id);
    return result?(result.completion + '%'):'Loading...'
  }

  constructor(props){
    super(props);
    this.state = {
      results: []
    }
  }

  displayLessonOutcome = () => {
    var secondList = this.props.lessonList.filter(lesson => {
      if(lesson.assesmnetId === this.props.assessment.assesmentId){
        return lesson;
      }
      return null;
    });

    return (
      <div className="row ml-4">
        {secondList.map((lesson, index) => (
          <div
            key={index}
            className="col-12 h7 p-1 border-bottom"
            style={{ cursor: "pointer" }}
          >
            <div className="row pb-2 pt-2">
              <div className="col-8">{lesson.title}</div>
              <div className="col-4 text-warning">
                {this.getScore(lesson.pkCourseId)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    return (
      <div className={this.props.isPartner ? "" : "container"}>
        <div className="row">
          <GenericHeader
          headerColor={this.props.headerColor}
            title={this.props.assessment.title}
            showBackButton
            backFunction={() => {
              this.props.onCurrentAssessmentTabChange(0);
            }}
          />
        </div>
        {this.displayLessonOutcome()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    MemberV: state.MemberViewReducer.MemberView,
    assessmentList: state.lmsReducer.assesments,
    assessment: state.lmsReducer.assesment,
    assesmentCompletion: state.lmsReducer.assesmentCompletion,
    lessonList: state.lmsReducer.lessons,
    memberNo: state.MemberViewReducer.MemberView.memNo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentAssessmentTabChange: newCurrAssessmentTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_ASSESSMENT_TAB,
        currAssessmentTab: newCurrAssessmentTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lessons);
