import React from 'react';
import {
  // Paper,
  Snackbar, Typography,
} from '@material-ui/core';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(() => ({
  success: {
    backgroundColor: '#4caf50'
  },
  info: {
    backgroundColor: '#2196f3'
  },
  warning: {
    backgroundColor: '#ff9800'
  },
  error: {
    backgroundColor: '#f44336'
  }
}));

const Container = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  localStorage.setItem('isMobile', `${!matches}`);
  return (
    <React.Fragment>
      <Snackbar 
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        open={true}
      >
        {/* <Paper className={classes.success}> */}
        <div>{props.message}</div>
        {/* </Paper> */}
      </Snackbar>
      <div className={`pt-10 ${matches ? 'px-12' : 'px-3'}`}>
        <div  className="mb-2" classes={classes}>
          <Typography  align={props.center || "left"} variant={matches ? 'h3' : 'h1'}>{props.title}</Typography>
        </div>
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default Container;