import React, { Component } from 'react';
import { connect } from 'react-redux';
import {DateTime} from 'luxon';
import JWT from 'jwt-decode';
import axios from 'axios';
import MemberActions from '../../store/Profile/actions';
import LicenceActions from '../../store/Licence/actions';
import {
  currentYear,
  getMemberStatusFromInt,
  formatISODateToLocaleString,
  getDedicatedStatusFromActivities,
} from '../../shared/helpers';
import {
  printCard,
  printCardWhatsAPP,
  printCertificate,
  printAccountTransaction,
} from '../../shared/pdfHelpers';
import MemberService from '../../services/member.service';
import LicenceService from '../../services/licence.service';
import DedicatedService from '../../services/dedicatedstatus.service';
import {
  MEMBER_STATUS,
} from '../../shared/constants';
import LookupActions from '../../store/Lookup/actions';
import LookupService from '../../services/lookup.service';
import DedicatedStatusActions from '../../store/DedicatedStatus/actions';
import SITE_URL from '../../services/siteURL';
import { withStyles } from '@material-ui/core/styles';
const GET_TOKEN = () =>{
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI2ZDA2YmNkMC02YWU1LTRkZmItODc0OS1mNTYwMGUzZjJhNGYiLCJlbWFpbCI6IndoYXRzYXBwQG5hcmZvLmNvLnphIiwianRpIjoiZDA5Y2Y3YTEtNmRkZS00Y2MzLWFiOGQtNjNkMjI4MmNkZjE3IiwiZ2l2ZW5fbmFtZSI6IldoYXRzQVBQIiwiZXhwIjoxOTgzNzY2Nzk5LCJpc3MiOiJodHRwOi8vbG9jYWxob3N0OjUyMDI2LyIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NTIwMjYvIn0.JqMSZpeAqwZbIa5qrhtq51-AkgY5ljfSBA7pd3f-U7c';
  }
  
const TOKEN = GET_TOKEN();


//Converted MakeStyles to WithStyles 
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    // maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});


class WhatsAppDocuments extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(TOKEN);
    this.licenceService = LicenceService(TOKEN);
    this.dedicatedService = DedicatedService(TOKEN);
    this.memberActions = this.props.memberActions(this.memberService, this.dedicatedService);
    this.licenceActions = this.props.licenceActions(this.licenceService);
    this.lookupService = LookupService(TOKEN);
    this.lookupActions = this.props.lookupActions(this.lookupService);
    this.dedicatedStatusAction = this.props.dedicatedStatusActions(this.dedicatedService);

    this.state = {
      loading: false,
      isNewMember: false,
      memberStatus: null
    };
  }

  //Get Query parameters
  parseUrlQuery = (value) => {
    var urlParams = new URL(value).searchParams
    return Array.from(urlParams.keys()).reduce((acc, key) => {
      acc[key] = urlParams.getAll(key)
      return acc
    }, {})
  }

  componentDidMount() {
    const {idNo, doctype} = this.parseUrlQuery(window.location);

    this.memberService.getMemNoByIdNo(idNo)
    .then(resp =>{
      const memNo = resp.data;

      //Check if user is new 
      this.memberService.getMemberByMemNo(memNo).then(response=>{
  
        if(response.data.status == 2)
            this.setState({isNewMember:true});
        
            this.setState({memberStatus:response.data.status})
      });

      //Redux stuff
      const user = { memNo: memNo };

      this.memberActions.fetchMemberData(memNo)
      .then(() => this.memberActions.fetchMemberDedicatedStatus(memNo))
      .then(() => this.memberActions.fetchMemberAccountHistory(memNo))
      .then(() => this.memberActions.fetchMemberLoyaltyDays(memNo))
      .then(() => this.licenceActions.fetchFirearmLicenceStatus(memNo))
      .then(() => this.licenceActions.fetchMemberLicenseReminders(memNo))
      .then(() => this.lookupActions.fetchFirearmActions())
      .then(() => this.lookupActions.fetchSections())
      .then(() => this.dedicatedStatusAction.fetchDedicatedStatusList(user))
      .finally(() => {
        this.setState({loading: false});
        this.memberActions.fetchMemberProfilePicture(memNo);
      });
    })

    //Document selected
    if(doctype == 11)
      this.downloadMemberCard(doctype);
    else if(doctype == 12)
      this.downloadMemberCertificate(doctype);

  }

  memberDataToDetails = () => ({
    nickName: this.props.memberData.nickname,
    firstName: this.props.memberData.firstname,
    surname: this.props.memberData.surname,
    occupation: this.props.memberData.occupation,
    identityNumber: this.props.memberData.idno,
    cellNumber: this.props.memberData.cellNo,
    emailAddress: this.props.memberData.email,
    streetAddress: this.props.memberData.physicalAddress,
    suburb: this.props.memberData.suburb,
    postalCode: this.props.memberData.postalCode,
    city: this.props.memberData.city,
    province: this.props.memberData.province,
    personalAssisstant:this.props.personalAssisstant
  });

  memberDataToStatus = () => ({
    currentYear: currentYear,
    membership: this.props.memberData.memType,
    status: getMemberStatusFromInt(this.props.memberData.status),
    inceptionDate: formatISODateToLocaleString(this.props.memberData.inceptionDate),
    expiryDate: formatISODateToLocaleString(this.props.memberData.expiryDate),
    approvalDate: formatISODateToLocaleString(this.props.memberData.approvalDate),
    dedicatedSportsStatus: getDedicatedStatusFromActivities(this.props.dedicatedActivities.dedicatedShooter,this.props.dedicatedStatus.sport),
    dedicatedHunterStatus: getDedicatedStatusFromActivities(this.props.dedicatedActivities.dedicatedHunter,this.props.dedicatedStatus.hunting),
    loyaltyDays: (() => {
      const daysEarned = this.props.loyaltyProgram.daysAdded;
      return daysEarned?.dedicatedStatusDays + daysEarned?.courseDays +
        daysEarned?.endorsementDays + daysEarned?.generalActivityDays;
    })(),
    expiredFirearms: this.props.licenceStatus.expired,
    firearmsToBeRenewed: this.props.licenceStatus.toBeRenewed,
    firearms: this.props.licenceStatus.firearms,
  });

  downloadMemberCard = (docType) => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.dedicatedService.getApprovedDedicatedStatus(memberId)
          .then((res) => {
            let dsHunter = res.data
              .find(ds => ds.dstype.toLowerCase().includes('hunter') && ds.status === MEMBER_STATUS.APPROVED) || {};
            let dsSports = res.data
              .find(ds => ds.dstype.toLowerCase().includes('sport') && ds.status === MEMBER_STATUS.APPROVED) || {};
            return { dsHunter, dsSports };
          }).then(({ dsHunter, dsSports }) => this.memberService.getAllImages().then((images) =>{
          let pdf = printCardWhatsAPP(this.props.memberData, dsHunter.dsno, dsSports.dsno,images.data.narfo, images.data.wildman, base64QRCode)

              var formData = new FormData();
              let apiURL = "";
              //Send API yo save the document 
              formData.append(`file`, pdf); //single file upload 
                var url = `https://apiqa.narfo.co.za/api/Document/UploadBlobDocument?memberNo=${memberId}&docType=${docType}&baseURL=${SITE_URL}`;
                axios.post(url,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                  })
                  .then(success => {
                    console.log("Success",success.data.location)

                     window.open(success.data.location,'_self'); //open document in new tab 

                  })
                  .catch(error => console.log(error));

              //window.open(pdf); //open document in new tab 
              
              // window.open(pdf,'_self'); open document in same tab

              // window.navigator.msSaveBlob(pdf, "testfile");
            }));
      });
  }

  downloadAccountTransaction = (account) => this.memberService.getAllImages()
    .then((images) => printAccountTransaction(
      account, this.props.memberData, images.data.nnfo, images.data.pdflogo
    ));


  downloadMemberCertificate = () => {
    const memberId = this.props.memberData.memNo;
    return this.memberService.getMemberQRCode(memberId)
      .then((resp) => {
        const base64QRCode = `data:image/jpeg;base64,${resp.data.base64QRString}`;
        return this.memberService.getAllImages().then((images) =>
          printCertificate(
            this.props.memberData, images.data.nnfo, base64QRCode,
            images.data.pdfSign, images.data.pdflogo
          )
        );
      });
  }

  render() {
    return (
      <div>
          {/*Automatic download of Membership Documents*/}
      
          </div>
    );
  }
}


const mapStateToProps = state => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberData: state.ProfileReducer.memberData,
  profilePicture: state.ProfileReducer.profilePicture,
  dedicatedStatus: state.ProfileReducer.dedicatedStatus,
  dedicatedActivities: state.ProfileReducer.dedicatedStatusActivities,
  loyaltyProgram: state.ProfileReducer.loyaltyProgram,
  accountHistory: state.ProfileReducer.accountHistory,
  licenceStatus: state.LicenceReducer.firearmLicenceStatus,
  assesmentCompletion: state.nLearningManagementReducer.assesmentCompletion,
  memberData2: state.ProfileReducer.memberData2,
  personalAssisstant: state.ProfileReducer.personalAssisstant,
  daysLeft: DateTime.fromISO(state.ProfileReducer.memberData.expiryDate).diffNow('days').days,
});

const initialiseMemberActions = dispatch => (memberService, dedicatedStatusService) => {
  const action = MemberActions(memberService, dedicatedStatusService);
  return {
    fetchMemberData: (id) => dispatch(action.fetchMemberData(id)),
    fetchMemberProfilePicture: (id) => dispatch(action.fetchMemberProfilePicture(id)),
    fetchMemberDedicatedStatus: (id) => dispatch(action.fetchMemberDedicatedStatus(id)),
    fetchMemberLoyaltyDays: (id) => dispatch(action.fetchMemberLoyaltyDays(id)),
    fetchMemberAccountHistory: (id) => dispatch(action.fetchMemberAccountHistory(id)),
    fetchMemberDetailsByEmail: (mail) => dispatch(action.fetchMemberDetailsByEmail(mail))
  };
};

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchFirearmLicenceStatus: (id) => dispatch(action.fetchFirearmLicenceStatus(id)),
    fetchMemberLicenseReminders: (id) => dispatch(action.fetchMemberLicenseReminders(id))
  };
};

const initLookupActions = dispatch => lookupService => {
  const actions = LookupActions(lookupService);
  return {
    fetchFirearmActions: () => dispatch(actions.fetchFirearmActions()),
    fetchSections: () => dispatch(actions.fetchSections()),
  };
};

const initDedicatedStatusActions = dispatch => dedicatedStatusService => {
  const actions = DedicatedStatusActions(dedicatedStatusService);
  return {
    fetchDedicatedStatusList: data => dispatch(actions.fetchDedicatedStatusList(data))
  };
};

const mapDispatchToProps = dispatch => ({
  memberActions: initialiseMemberActions(dispatch),
  licenceActions: initialiseLicenceActions(dispatch),
  lookupActions: initLookupActions(dispatch),
  dedicatedStatusActions: initDedicatedStatusActions(dispatch)
});

export default connect(mapStateToProps ,mapDispatchToProps)(withStyles(styles, { withTheme: true })(WhatsAppDocuments));
