import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { isMobile } from "react-device-detect";
const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  },
  dialog: {
    backgroundImage: "url(https://portal.narfo.co.za/Images/notification.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "500px 500px",
    backgroundPosition:'center',
    backgroundColor:'transparent',
    boxShadow: 'none',
    zIndex:'1000',
  },
  dialogMobile: {
    backgroundImage: "url(https://portal.narfo.co.za/Images/notification.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "300px 300px",
    backgroundPosition:'center',
    backgroundColor:'transparent',
    boxShadow: 'none',
    zIndex:'1000',
  },
  dialogContent:{
    position: "absolute",
    top: "50%",
    left: "50%",
    textSlign: "justify",
    textJustify: "inter-word",
    transform: "translate(-50%, -50%)",
    color:"white",
  }
});

class DialogNotification extends Component {
  constructor(props) {
    super(props);
    this.classes = styles();
    this.state = {
        open:props.show,
    }
  }

  handleClose = () => {
    this.setState({open:false});
    this.props.handleCloseErrorDialog();
  };

  render() {
    const { classes } = this.props;
    const showCustomizedDialogOnMobile = this.props.message?.length < 134; // if it's less then show customized dialog
    return(
      <React.Fragment>
       <Dialog
          open={this.props.show}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: isMobile ? showCustomizedDialogOnMobile ? classes.dialogMobile :null : classes.dialog }}
        >
          <DialogTitle hidden id="alert-dialog-title">Error</DialogTitle>
          <DialogContent style={ isMobile ? showCustomizedDialogOnMobile ? {height:"300px",width:"300px"} : null : {height:"500px",width:"500px"}}>
            <DialogContentText id="alert-dialog-description">
             <div class="container">
              <div class={isMobile ? showCustomizedDialogOnMobile ? classes.dialogContent:"" :classes.dialogContent}>
               
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                <Icon>warning</Icon>
                </Grid>
                <Grid item xs style={{marginTop:'3px'}}>
                  {/* <Typography>{this.props.message}</Typography> */}
                  <Typography dangerouslySetInnerHTML={{__html: this.props.message}} ></Typography>
                </Grid>
              </Grid>
              </div>
            </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{color:'white'}} onClick={this.handleClose} variant="contained" color="primary">
             {"Close"}
            </Button>
          </DialogActions>
        </Dialog>
        
      </React.Fragment>
    );
  }
}

export default (withStyles(styles)(DialogNotification));
