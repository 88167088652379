import React, { Component } from 'react';
import { connect } from 'react-redux';
import Container from '../../hoc/Container';
import { Overview } from '../../components';
import { Typography } from '@material-ui/core';
import MembershipReports from '../../components/Reports/MembershipReports';
import DedicatedStatusReports from '../../components/Reports/DedicatedStatusReports';
import ActivitiesReports from '../../components/Reports/ActivitiesReports';
import LicenseReports from '../../components/Reports/LicenseReports';
class ReportsDownload extends Component {
  constructor(props) {
    super(props);
    
  }

  componentDidMount() {

  }

  
  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Reports Download">
     <React.Fragment>
        <Overview>
          <Typography align="justify">
            Use this reports section to download all the reports available on NARFO.</Typography>
        </Overview>
      </React.Fragment>

      <MembershipReports />

      <DedicatedStatusReports/>

      <ActivitiesReports/>

      <LicenseReports/>
      </Container>
    );
  }
}

export default connect(null,null)(ReportsDownload);