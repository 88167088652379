import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_LOGGED_IN_MEMBER } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import MembersAreaHeader from "../membersAreaPage/MembersAreaHeader";
import TabPane from "../membersAreaPage/TabPane";

const DateDifference = (datePart, fromDate, todate) => {
  datePart = datePart.toLowerCase();
  var diff = todate - fromDate;
  var divideBy = { w: 604800000, d: 86400000, h: 3600000, m: 60000, s: 1000 };

  return Math.floor(diff / divideBy[datePart]);
};

export const membershipExpired = date => {
  var daysToExpiry = DateDifference(
    "d",
    new Date(Date.now()),
    new Date(date) //this.props.memberDetails.expiryDate
  );

  return daysToExpiry >= 0 ? false : true;
};

class MembersArea extends Component {
  constructor(props) {
    super(props);
    this.loadMemberArea = this.loadMemberArea.bind(this);
    this.state = {
      loading: true
    };

    //Redirect the user to the main admin page when trying to access this page 
    this.props.history.push("/admin/MainAdmin");

    // if (membershipExpired(this.props.memberDetails.expiryDate)) {
    //   this.props.history.push("/PendingMemberView");
    // }
  }

  componentDidMount() {
    axios
      .get(BASE + GET_LOGGED_IN_MEMBER + this.props.member.memberNumber,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.props.onGetLoggedInMemberChange(res.data.member);
        this.props.onMemberActiveStatusChange(res.data.dedicatedStatus);
        this.setState({
          loading: false
        });
      });
  }

  loadMemberArea() {
    return (
      <div
        className="container "
        style={{
          maxWidth: "2180px",
          marginTop: "80px",
          marginBottom: "20px"
        }}
      >
        {!this.state.loading ? (
          <div className="row">
            <div className="col-12 p-0">
              <MembersAreaHeader
                memberDetails={this.props.getLoggedInMember}
                adminView={false}
              />
            </div>
          </div>
        ) : null}
        <div className="row pl-0">
          <div className="col-12 p-0">
            <TabPane />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.loadMemberArea()}</div>;
  }
}

const mapStateToProps = state => {
  return {
    member: state.loginReducer.userProfileDetails,
    getLoggedInMember: state.MemberViewReducer.getLoggedInMember,
    memberDetails: state.loginReducer.userProfileDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetLoggedInMemberChange: newGetLoggedInMember =>
      dispatch({
        type: actionTypes.UPDATE_GET_LOGGED_IN_MEMBER,
        getLoggedInMember: newGetLoggedInMember
      }),
    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersArea);
