import React, { Component } from "react";
import { connect } from "react-redux";
import { DateTime } from "luxon";
import { withStyles } from "@material-ui/core/styles";
import JWT from "jwt-decode";
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import {
  fireArmsControlActConfig,
  fireArmsUseStorageConfig,
  criminalOffenseConfig,
  declareCodeOfConductConfig,
  declareDisciplinaryCodeConfig,
  declareValidInformationConfig,
  declareElectronicPolicyConfig,
  declareValidElectronicTrueConfig,
  associationAffiliationConfig,
  genderConfig,
  provinceConfig,
  ethnicityConfig,
  titleConfig
} from "../../components/Auth/Signup/controls";
import MemberActions from "../../store/Profile/actions";
import MemberService from "../../services/member.service";
import Container from "../../hoc/Container";
import { checkValidity, updateObject} from '../../shared/utility';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from "@material-ui/core/";
import { MappedInput } from "../../components";
import {
  editableTextInputConfig
} from '../../shared/constants';
import API_URL from "../../services/apiUrl";
import {EditableGridLabel, SubtitleBar,Overview} from "../../components/index"

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(2),
  },
  formHeading: {
    ...theme.typography.h1,
    //   fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    borderBottom: "1px solid",
    marginTop: theme.spacing(4),
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: "0",
    textAlign: "justify",
  },
  errorPadding: {
    padding: theme.spacing(4),
  },
  submitErrorPadding: {
    padding: theme.spacing(4),
  },
  mobileMargin: {
    marginTop: "25px",
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
});

class ProfileCompletion extends Component {
  constructor(props) {
    super(props);
    this.memberService = MemberService(this.props.token);
    this.state = {
      //For declarations
      declarationControls: this.initDeclarationControls(),
      electronicControls: this.initElectronicControls(),
      associationControls : this.initAssociationControls(),
      submitError: "",
      nextError: "",
      errorMessage: "",
      loading:false,
      isDisabled:true,

      //For Profile
      myDetails: {},
      editDetails: true,
      controls: this.initControls(),
      formHasErrors: true,
    };

    axios.get(`${API_URL}/Member/getMemByMemNo/${this.props.memNo}`,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
      }).then(res => {

        if(res.data.status !== 5 && res.data.status !== 8) 
           this.props.history.push('/member/my-profile');

          let member = {
            nickName: res.data.nickname,
            firstName: res.data.firstname,
            surname: res.data.surname,
            occupation: res.data.occupation,
            identityNumber: res.data.idno,
            cellNumber: res.data.cellNo,
            emailAddress: res.data.email,
            streetAddress: res.data.physicalAddress,
            postalCode: res.data.postalCode,
            city: res.data.city,
            province: res.data.province,
            suburb: res.data.suburb,
          };

          this.setState({myDetails:member});


      });
  }

  initDeclarationControls = () => ({
    fireArmsControlAct: { ...fireArmsControlActConfig },
    fireArmsUseStorage: { ...fireArmsUseStorageConfig },
    criminalOffense: { ...criminalOffenseConfig },
    declareCodeOfConduct: { ...declareCodeOfConductConfig },
    declareDisciplinaryCode: { ...declareDisciplinaryCodeConfig },
    declareValidInfo: { ...declareValidInformationConfig },
    
  });

  inputChangeAssociationHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.associationControls[controlName].config.validate,
      this.state.associationControls[controlName].config.elementConfig.type
    );

    const updatedControls = updateObject(this.state.associationControls, {
      [controlName]: updateObject(this.state.associationControls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.associationControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });

    this.setState({associationControls:updatedControls});
  }

  inputChangeOtherControlsHandler = (event, controlName) => {

    console.log("Change " , event.target.value)
    console.log("Change Name" , controlName)
    const validity = checkValidity(
      event.target.value, 
      this.state.controls[controlName].config.validate,
      this.state.controls[controlName].config.elementConfig.type
    );

    const updatedControls = updateObject(this.state.controls, {
      [controlName]: updateObject(this.state.controls[controlName], {
        value: event.target.value,
        config: updateObject(this.state.controls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });

    this.setState({controls:updatedControls});
  }

  inputChangeDeclarationHandler = (event, controlName) => {

    //Radio buttons works but they don't keep the checkbox checked
    const validity = checkValidity(
      event.target.value, 
      this.state.declarationControls[controlName].config.validate,
      this.state.declarationControls[controlName].config.elementConfig.type
    );

    //For radio buttons get value from event.target but for checkboxes get value from toggled input 
    let radioButtons = ['fireArmsControlAct','fireArmsUseStorage','criminalOffense'];

    const updatedControls = updateObject(this.state.declarationControls, {
      [controlName]: updateObject(this.state.declarationControls[controlName], {
        // value: this.state.declarationControls[controlName].value != '' ? !this.state.declarationControls[controlName].value : true ,
        value: radioButtons.includes(controlName) ? event.target.value : this.state.declarationControls[controlName].value != '' ? !this.state.declarationControls[controlName].value : true,
        config: updateObject(this.state.declarationControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });

    //check if isDisabled 
    let isDisabled = this.state.electronicControls.declareElectronicPolicy.value == true && 
                     this.state.electronicControls.declareValidElectronicTrue.value == true &&
                     updatedControls.declareValidInfo?.value == true &&
                     updatedControls.declareDisciplinaryCode?.value == true &&
                     updatedControls.declareCodeOfConduct?.value == true &&
                     updatedControls.fireArmsControlAct.value.length > 3 &&
                     updatedControls.fireArmsUseStorage.value.length > 3 &&
                     updatedControls.criminalOffense.value == 'false' && updatedControls.criminalOffense.value.length > 3

    this.setState({declarationControls:updatedControls, isDisabled : !isDisabled});
  }

  initElectronicControls = () => ({
    declareElectronicPolicy: { ...declareElectronicPolicyConfig },
    declareValidElectronicTrue: { ...declareValidElectronicTrueConfig },
  });
  
  initAssociationControls = () => ({
    associationAffiliation: {...associationAffiliationConfig}
  });

  inputChangeElectronicHandler = (event, controlName) => {
    const validity = checkValidity(
      event.target.value, 
      this.state.electronicControls[controlName].config.validate,
      this.state.electronicControls[controlName].config.elementConfig.type
    );

    const updatedControls = updateObject(this.state.electronicControls, {
      [controlName]: updateObject(this.state.electronicControls[controlName], {
        value: this.state.electronicControls[controlName].value != '' ? !this.state.electronicControls[controlName].value : true ,
        config: updateObject(this.state.electronicControls[controlName].config, {
          valid: validity.isValid,
          errors: validity.errors,
          touched: true
        })
      })
    });

    //check if isDisabled 
    let isDisabled = updatedControls.declareElectronicPolicy?.value == true && 
                     updatedControls.declareValidElectronicTrue?.value == true &&
                     this.state.declarationControls?.declareValidInfo?.value == true &&
                     this.state.declarationControls?.declareDisciplinaryCode?.value == true &&
                     this.state.declarationControls?.declareCodeOfConduct?.value == true &&
                     this.state.declarationControls?.fireArmsControlAct.value.length > 3 &&
                     this.state.declarationControls?.fireArmsUseStorage.value.length > 3 &&
                     this.state.declarationControls?.criminalOffense.value == 'false' && this.state.declarationControls?.criminalOffense.value.length > 3

    this.setState({electronicControls:updatedControls, isDisabled : !isDisabled});
  }

  editableGridLabel = (title, name, isMobile) => (
    <EditableGridLabel
      title={title}
      value={this.state.myDetails[name]}
      options={this.state.controls[name]}
      edit={true}
      onChange={this.handleLabelChange(name)}
      isMobile={isMobile}
    />
  );

  handleLabelChange = controlName => event => {

    const newValue = event.target.value;
    const validity = checkValidity (newValue, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };
    Object.keys(controls).forEach(key => {
      if(!controls[key].config.valid) {
        formHasErrors = true;
      }
    });
    this.setState({
      controls,
      myDetails: {
        ...this.state.myDetails,
        [controlName]: newValue
      },
      formHasErrors,
    });
  };

  initControls = () => ({
    nickName: {...editableTextInputConfig},
    firstName: {...editableTextInputConfig, editable: false},
    surname: {...editableTextInputConfig, editable: false},
    // sex: {...editableTextInputConfig},
    // ethnicity: {...editableTextInputConfig},
    identityNumber: {...editableTextInputConfig, editable: false},
    cellNumber: {...editableTextInputConfig},
    emailAddress: {...editableTextInputConfig,editable: false},
    streetAddress: {...editableTextInputConfig},
    postalCode: {...editableTextInputConfig},
    city: {...editableTextInputConfig},
    // province: {...editableTextInputConfig},
    suburb: {...editableTextInputConfig},
    associationAffiliation: {...associationAffiliationConfig},
    
    title: {...titleConfig},
    ethnicity: {...ethnicityConfig},
    province: {...provinceConfig},
    gender: {...genderConfig},
  });

  handleSaveChanges = () =>{

    let payload = {
        //For declarations 
        MemNo: this.props.memNo,
        FireArmsControl: this.state.declarationControls.fireArmsControlAct.value == 'true',
        UseStorage: this.state.declarationControls.fireArmsUseStorage.value == 'true',
        Criminal: this.state.declarationControls.criminalOffense.value == 'true',
        CodeOfConduct: this.state.declarationControls.declareCodeOfConduct.value,
        DisciplinaryConduct: this.state.declarationControls.declareDisciplinaryCode.value,
        CorrectInfo: this.state.declarationControls.declareValidInfo.value,
        ElectronicComms: this.state.electronicControls.declareElectronicPolicy.value,
        DeclarationAcceptance: this.state.electronicControls.declareValidElectronicTrue.value,

        memNoNavigation: {
          title : this.state.controls.title.value, //dropdown
          currentAssociation: this.state.associationControls.associationAffiliation.value,
          firstname: this.state.myDetails.firstName,
          surname: this.state.myDetails.surname,
          nickname: this.state.myDetails.nickName,
          cellNo: this.state.myDetails.cellNumber,
          idno: this.state.myDetails.identityNumber,
          ethinicity: this.state.controls.ethnicity.value, //dropdown
          
          physicalAddress: this.state.myDetails.streetAddress,
          sex: this.state.controls.gender.value, // Dropdown
          province: this.state.controls.province.value, //dropdown
          suburb: this.state.myDetails.suburb,
          city: this.state.myDetails.city,
          postalCode: this.state.myDetails.postalCode,

        }
    }

    const requiredFields = this.checkRequired(payload.memNoNavigation);
    
    if(!requiredFields) {
      const message = 'Please complete all of the fields before submitting the application';
      this.setState({submitError:message});
      return;
    }
    
    this.setState({loading:true, isDisabled:true});

    axios.post(`${API_URL}/Member/completeProfile`, payload)
    .then(_ => {
        this.props.history.push('/member/my-profile');
      })
      .catch(err => {
        console.error(err.response);
        this.setState({loading:false, isDisabled:false});
        alert("Error saving declarations please logout and login")
      });
  }

  checkRequired = regInfo => {
    for (var obj in regInfo) {
      if (regInfo[obj] === '' || regInfo[obj] === '-') {
        return false;
      }
    }

    return true;
  };

  render() {
    const { classes } = this.props;
    const declarationElementArray = [];
    const electronicElementArray = [];
    const associationElementArray = [];
    const provinceElementArray = [];
    const dropdownsArray = [];
    
    let loadingBlock = null;
    let signupRedirect = null;
    const isMobile = localStorage.getItem('isMobile') === 'true';

    for (let key in this.state.declarationControls) {
      declarationElementArray.push({
        id: key,
        config: this.state.declarationControls[key].config,
        value: this.state.declarationControls[key].value,
      });
    }

    for (let key in this.state.electronicControls) {
      electronicElementArray.push({
        id: key,
        config: this.state.electronicControls[key].config,
        value: this.state.electronicControls[key].value,
        message: this.state.electronicControls[key].message,
      });
    }

    for (let key in this.state.associationControls) {
      associationElementArray.push({
        id: key,
        config: this.state.associationControls[key].config,
        value: this.state.associationControls[key].value,
        message: this.state.associationControls[key].message,
      });
    }

    for (let key in this.state.controls) {
      let _keys = ['gender','ethnicity', 'title'];

      if(_keys.includes(key))
          dropdownsArray.push({
            id: key,
            config: this.state.controls[key].config,
            value: this.state.controls[key].value,
            message: this.state.controls[key].message,
          });

      if(key == 'province')
        provinceElementArray.push({
          id: key,
          config: this.state.controls[key].config,
          value: this.state.controls[key].value,
          message: this.state.controls[key].message,
        });
    }

    let additionalDieldsForm = dropdownsArray.map(formElement => (

      <Grid
        key={formElement.id}
        item
        xs={3}
        sm={3}
      >
      {
        <FormControl
          // key={formElement.id}
          variant="standard"
          margin="normal"
          style={{marginLeft:"20px"}}
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}

            changed={(event) => this.inputChangeOtherControlsHandler(event, formElement.id)} 
          />

        </FormControl>
      }
      </Grid>
    ));

    let ProvinceForm = provinceElementArray.map(formElement => (

      <Grid
        key={formElement.id}
        item
        xs={3}
        sm={3}
      >
      {
        <FormControl
          // key={formElement.id}
          variant="standard"
          margin="normal"
          style={{marginLeft:"20px"}}
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}

            changed={(event) => this.inputChangeOtherControlsHandler(event, formElement.id)} 
          />

        </FormControl>
      }
      </Grid>
    ));

    let declarationForm = declarationElementArray.map((formElement) => (
      <Grid
        key={formElement.id}
        item
        xs={12}
        sm={10}
        md={formElement.config.width === "full" ? 10 : 4}
      >
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) =>
              this.inputChangeDeclarationHandler(event, formElement.id)
            }
          />
          <FormHelperText error={!formElement.config.value}>
            {formElement.config.errors
              ? formElement.config.errors[0]
              : "Input is invalid"}
          </FormHelperText>
        </FormControl>
      </Grid>
    ));

    if (this.state.loading) {
        loadingBlock = 
        <Grid container justify="center">
          <CircularProgress />
        </Grid>;
      } 
  
      const submitError = (
        <Typography
          variant="h6"
          color="error"
          className={classes.submitErrorPadding}
        >
          {this.state.submitError}
        </Typography>
      );
  
      if (this.state.signupSuccess) {
        signupRedirect = <Redirect to={this.props.signupRedirectPath} />;
      }

    let electronicForm = electronicElementArray.map((formElement) => (
      <React.Fragment key={formElement.id}>
        <Grid item>
          <p className={classes.paragraph}>{formElement.message}</p>
        </Grid>
        <Grid
          key={formElement.id}
          item
          xs={12}
          sm={10}
          md={formElement.config.width === "full" ? 10 : 4}
        >
          <FormControl
            key={formElement.id}
            variant="standard"
            margin="normal"
            fullWidth
          >
            <MappedInput
              elementConfig={formElement.config.elementConfig}
              elementType={formElement.config.elementType}
              value={formElement.value}
              invalid={!formElement.config.valid}
              shouldValidate={formElement.config.validate}
              touched={formElement.config.touched}
              changed={(event) =>
                this.inputChangeElectronicHandler(event, formElement.id)
              }
            />
            <FormHelperText error={!formElement.config.value}>
              {formElement.config.errors
                ? formElement.config.errors[0]
                : "Input is invalid"}
            </FormHelperText>
          </FormControl>
        </Grid>
      </React.Fragment>
    ));

    let associationForm = associationElementArray.map(formElement => (
      <Grid
        key={formElement.id}
        item
        xs={12}
        sm={10}
        md={formElement.config.width === 'full' ? 10 : 4}
      >
      {
        <FormControl
          key={formElement.id}
          variant="standard"
          margin="normal"
          fullWidth
        >
          <MappedInput
            elementConfig={formElement.config.elementConfig}
            elementType={formElement.config.elementType}
            value={formElement.value}
            invalid={!formElement.config.valid}
            shouldValidate={formElement.config.validate}
            touched={formElement.config.touched}
            changed={(event) => this.inputChangeAssociationHandler(event, formElement.id)} 
          />
          <FormHelperText
            error={!formElement.config.value}
          >
            {formElement.config.errors ? formElement.config.errors[0] : 'Input is invalid'}
          </FormHelperText>
        </FormControl>
      }
      </Grid>
    ));

    return (
      <Container>
        <Grid item container direction="row">
        <Grid item xs={12}>
          <Overview>
          <Typography style={{marginTop:'-20px'}} align="justify">
          You have signed up as a NARFO member through one of our partners. To activate your profile, you need to supply some basic information and you are all set to go.
            <br/>
            <br/>
            <br/>
          </Typography>
          </Overview>

          <Typography className={classes.formHeading} variant="subtitle1">
            Member Details
          </Typography>
        </Grid>

          <Grid item xs={12}>
          <div className="py-2 px-4 bg-white-2" style={{marginTop:'10px',marginBottom:'10px'}}>
          <SubtitleBar variant="subtitle2" title="Personal Details"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Nick Name', 'nickName', isMobile)}
            {this.editableGridLabel('First Name', 'firstName', isMobile)}
            {this.editableGridLabel('Surname', 'surname', isMobile)}
            {this.editableGridLabel('Identification Number', 'identityNumber', isMobile)}
            {/* {this.editableGridLabel('Title', 'title', isMobile)} */}
            {/* {this.editableGridLabel('Sex', 'sex', isMobile)} */}
            {/* {this.editableGridLabel('Ethnicity', 'ethnicity', isMobile)} */}
            {/* {additionalDieldsForm} */}
          </Grid>
          <Grid container spacing={1} style={{marginTop:"40px"}}>
          {additionalDieldsForm}
          
          </Grid>
          <SubtitleBar variant="subtitle2" title="Contact Details"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Cell Number', 'cellNumber', isMobile)}
            {this.editableGridLabel('Email Address', 'emailAddress', isMobile)}
          </Grid>
          <SubtitleBar variant="subtitle2" title="Residential Address"/>
          <Grid container spacing={1}>
            {this.editableGridLabel('Street Address', 'streetAddress', isMobile)}
            {this.editableGridLabel('suburb', 'suburb', isMobile)}
            {this.editableGridLabel('Postal Code', 'postalCode', isMobile)}
            {this.editableGridLabel('City', 'city', isMobile)}
            {ProvinceForm}
          </Grid>
        </div>
        </Grid>
        </Grid>

        <Grid item container direction="row">
          <Grid item xs={12}>
            <Typography className={classes.formHeading} variant="subtitle1">
              Declarations
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            {declarationForm}
          </Grid>

          <Grid item xs={12} md={6}style={{ marginTop: "10px" }}>
            {associationForm}
            {electronicForm}
            {submitError}
            <Grid item xs={12}>
                <Grid item xs={3}>
                    {loadingBlock}
                </Grid>
                <Grid item xs={6}>
                    <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    disableElevation
                    disabled={this.state.isDisabled}
                    onClick={this?.handleSaveChanges}
                    className={classes.backButton}>
                    Save changes
                </Button>
            </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
});

const initialiseMemberActions =
  (dispatch) => (memberService, dedicatedStatusService) => {
    const action = MemberActions(memberService, dedicatedStatusService);
    return {
      fetchMemberData: (id) => dispatch(action.fetchMemberData(id)),
    };
  };

const mapDispatchToProps = (dispatch) => ({
  memberActions: initialiseMemberActions(dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ProfileCompletion));
