import * as Types from "./NewActivityActionTypes";

const newActivityState = {
    isNarfoEvent: true,
    activityEvent: null,
    memberNumber: null,
    fileInputOpen: false,
    eventType: null,
    eventDescription: '',
    activityType: null,
    date: null,
    score: 0,
    caliber: null,
    firearm: null,
    optics: null,
    association: 'NARFO',
    documents: [],

    eventTypes: [],
    uploadStatus: {
        loading: null,
        response: null,
        error: null
    },

    activityTypes: [],
    getActivityStatus: {
        loading: null,
        response: null,
        error: null
    },

    opticsList: [],
    getOpticsStatus: {
        loading: null,
        response: null,
        error: null
    },

    firearmTypes: [],
    getFirearmTypesStatus: {
        loading: null,
        response: null,
        error: null
    },

    associationsList: [],
    getAssociationsStatus: {
        loading: null,
        response: null,
        error: null
    },

    activityEventList: [],
    getActivityEventsStatus: {
        loading: null,
        response: null,
        error: null
    },

};

const reducer = (state = newActivityState, action) => {
    switch (action.type) {
        case Types.NEW_ACT_ACTIVITY_EVENT:
        case Types.NEW_ACT_RESET:
            return {
                ...state,
                isNarfoEvent: true,
                activityEvent: null,
                memberNumber: null,
                fileInputOpen: false,
                eventType: null,
                eventDescription: '',
                activityType: null,
                date: null,
                score: 0,
                caliber: null,
                firearm: null,
                optics: null,
                association: 'NARFO',
                documents: [],

            };
        case Types.NEW_ACT_ADD_FILE:
        case Types.NEW_ACT_REMOVE_FILE:
        case Types.NEW_ACT_SET_DATE:
        case Types.NEW_ACT_TOGGLE_NARFO_EVENT:
        case Types.NEW_ACT_SET_ASSOCIATION:
        case Types.NEW_ACT_SET_MEMBER_NUMBER:
        case Types.NEW_ACT_SET_ACTIVITY_EVENT:
        case Types.NEW_ACT_SET_EVENT_DESCRIPTION:
        case Types.NEW_ACT_SET_ACTIVITY_TYPE:
        case Types.NEW_ACT_SET_FIREARM_TYPE:
        case Types.NEW_ACT_SET_OPTICS:
        case Types.NEW_ACT_SET_CALIBER:
        case Types.NEW_ACT_SET_SCORE:
        case Types.NEW_ACT_UPLOAD_BEGIN:
        case Types.NEW_ACT_UPLOAD_FAILURE:
        case Types.NEW_ACT_UPLOAD_SUCCESS:
        case Types.NEW_ACT_GET_ACTIVITIES_BEGIN:
        case Types.NEW_ACT_GET_ACTIVITIES_FAILURE:
        case Types.NEW_ACT_GET_ACTIVITIES_SUCCESS:
        case Types.NEW_ACT_GET_OPTICS_BEGIN:
        case Types.NEW_ACT_GET_OPTICS_FAILURE:
        case Types.NEW_ACT_GET_OPTICS_SUCCESS:
        case Types.NEW_ACT_GET_FIREARM_TYPES_BEGIN:
        case Types.NEW_ACT_GET_FIREARM_TYPES_FAILURE:
        case Types.NEW_ACT_GET_FIREARM_TYPES_SUCCESS:
        case Types.NEW_ACT_GET_ASSOCIATIONS_BEGIN:
        case Types.NEW_ACT_GET_ASSOCIATIONS_FAILURE:
        case Types.NEW_ACT_GET_ASSOCIATIONS_SUCCESS:
        case Types.NEW_ACT_GET_ACTIVITY_EVENT_BEGIN:
        case Types.NEW_ACT_GET_ACTIVITY_EVENT_FAILURE:
        case Types.NEW_ACT_GET_ACTIVITY_EVENT_SUCCESS:
            return {
                ...state,
                ...action.newActivityState,
            };
        case Types.NEW_ACT_TOGGLE_FILE_INPUT:
            return {
                ...state,
                fileInputOpen: !state.fileInputOpen
            };
        default:
            return state;
    }
};

export default reducer;