import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';

import {
  Loader,
  Overview,
  SubtitleBar,
  ViewEndorsement,
} from '../index';
import {
  getStatusColor,
} from '../../shared/helpers';

const formatStatus = item =>
  <span className={getStatusColor(item)}>{item}</span>;

const styles = (theme) =>({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  pointer: {
    cursor: 'pointer'
  },
});

class MyEndorsements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      endorsements: props.endorsements,
      endorsement: {},
      loading: false,
      viewEndorsement: false,
    };
  }

  gridItem = (title, value, isMobile) => (
    <Grid
      item
      container
      xs={12}
      sm={4}
      md={4}
      spacing={1}
      direction="column"
      alignItems="flex-start"
    >
      <Grid item>
        <Typography variant={isMobile ? 'h6' : 'body2'} align="left">{value || ''}</Typography>
      </Grid>
      <Grid item>
        <Typography align="left">{title || ''}</Typography>
      </Grid>
    </Grid>
  )

  render() {
    const {classes} = this.props;

    const noDataSection = (
      <Typography variant="h4" color="error">
        You do not currently have any Endorsement applications to display
      </Typography>
    );

    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      this.state.loading && <Loader/> ||
      this.state.viewEndorsement && 
      <ViewEndorsement
        endorsement={this.state.endorsement}
        backCallback={() => this.setState({endorsement: {}, viewEndorsement: false})}
        downloadCallback={() => this.props.downloadMemberEndorsementCallback(this.state.endorsement)}
      /> ||
      <React.Fragment>
        <Overview>
          <Typography align="justify">
            You may apply for an endorsement to accompany your firearm details and motivate the use
             of the firearm. Once approved, you can print it out and add to it to your application.
          </Typography>
        </Overview>
        <SubtitleBar
          title="My Endorsements"
          apply
          callBack={this.props.addEndorsementCallback}
        />
        <Grid container justify="center">
          <Grid item container xs={12} md={12} spacing={4} alignItems="center">
            {this.props.endorsements.length === 0 ? <Grid item>{noDataSection}</Grid> : null}
            {this.props.endorsements.map((item, index) => (
              <Grid key={index} item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
                      <Grid item xs={12}>
                        <Typography variant="h4" color="primary">
                          {item.title}
                        </Typography>
                        <Divider/>
                      </Grid>
                      <Grid item container xs={10} spacing={4}>
                        {this.gridItem('Make', item.make, isMobile)}
                        {this.gridItem('Calibre', item.caliber, isMobile)}
                        {this.gridItem('Status', formatStatus(item.status), isMobile)}
                      </Grid>
                      <Grid item container xs={2} justify="center">
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.pointer}
                          onClick={() =>{
                            this.setState({loading: true});
                            this.setState({endorsement: this.state.endorsements[index]});
                            this.setState({
                              viewEndorsement: true,
                              loading: false
                            });
                          }}
                        >
                          VIEW
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.AuthReducer.token,
    memNo: state.AuthReducer.memNo,
    endorsements: state.nEndorsementReducer.endorsements
  }
};

MyEndorsements.propTypes = {
  addEndorsementCallback: PropTypes.func.isRequired,
};

MyEndorsements.defaultProps = {
  endorsements : [],
  addEndorsementCallback: () => null,
};

export default connect(mapStateToProps, null)(withStyles(styles)(MyEndorsements));