import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as PendingActivityActions from "./PendingActivitySubComponents/PendingActivityActions";
import * as NewActActions from "../../loggeddInUser/activitiesPage/NewActivityActions";
import { bindActionCreators } from "redux";
import ActivityInfo from "./PendingActivitySubComponents/ActivityInfo";
import ActivitySummary from "./PendingActivitySubComponents/ActivitySummary";
import UserSection from "./PendingActivitySubComponents/UserSection";
import ApprovalSection from "./PendingActivitySubComponents/ApprovalSection";
import GenericHeader from "../../../utils/GenericHeader";
import * as actionTypes from "../../../store/actions/actionTypes";

class ApprovePendingActivities extends React.Component {
  constructor(props) {
    super(props);
    if(!this.props.loggedIn){
      this.props.history.push("/");
    }
  }
  render() {
    return (
      <div className="container" style={{marginTop: "60px"}}>
        <GenericHeader 
          showBackButton={true} 
          backFunction={() => {this.props.history.push("PendingActivityApplication"); this.props.onActivityDescriptionChange('')}} 
          title="Activity"/>
        <UserSection user={this.props.pendingActivityState.member} />
        <ActivitySummary activity={this.props.pendingActivityState.activity} />
        <ActivityInfo activity={this.props.pendingActivityState.activity} isPartner={this.props.isPartner}/>
        <ApprovalSection
          activity={this.props.pendingActivityState.activity}
          approvalStatus={this.props.pendingActivityState.approvalStatus}
          approveActivity={this.props.PendingActivityActions.approvePending}
          backToList={() => {this.props.history.push("PendingActivityApplication")}}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    memberNumber: state.loginReducer.userProfileDetails.memberNumber,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    newActivityState: state.newActivityState,
    pendingActivityState: state.pendingActivityState
  };
};

const styles = theme => ({});

const mapActionsToProps = dispatch => ({
  PendingActivityActions: bindActionCreators(PendingActivityActions, dispatch),
  NewActActions: bindActionCreators(NewActActions, dispatch),
  onActivityDescriptionChange: ActDescription =>
    dispatch({ 
        type: actionTypes.UPDATE_SELECTED_ACTIVITY_DESCRIPTION, 
        selectedActivityDescription: ActDescription 
        })
});

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(ApprovePendingActivities));
