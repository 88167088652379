import {
  SET_COMMISSIONS,
  SET_BANKING_DETAILS,
} from './types';
import {
  getUniqueValuesfromObjectArray,
} from '../../shared/helpers';

const fetchSalesCommissions = service => (id, year, type) => dispatch => service
  .getSalesCommissions(id, year, type)
  .then((res) => {
    const comms = res.data.repsMemComm;
    const result = [];
    if(comms.length > 0) {
      const uniqueCommissionNumbers = getUniqueValuesfromObjectArray(comms, 'paymentTransNo');
      uniqueCommissionNumbers.forEach(commNumber => {
        const filteredCommissions = comms.filter(com => com.paymentTransNo === commNumber);
        result.push({
          commissions: filteredCommissions,
          commissionNumber: commNumber,
          paymentDate: filteredCommissions[0].paymentDate,
          amountTotal: filteredCommissions[0].commissionTotal,
          type: filteredCommissions[0].comType,
        });
      });
    }

    return result;
  })
  .then((res) => dispatch({ type: SET_COMMISSIONS, payload: res }))
  .catch(() => dispatch({ type: SET_COMMISSIONS, payload: [] }));

  //Fetch all sales commission for all the financial years
  const fetchAllSalesCommissions = service => (id, type) => dispatch => service
  .getAllSalesCommissions(id,type)
  .then((res) => {
    const comms = res.data.repsMemComm;
    const result = [];
    if(comms.length > 0) {
      const uniqueCommissionNumbers = getUniqueValuesfromObjectArray(comms, 'paymentTransNo');
      uniqueCommissionNumbers.forEach(commNumber => {
        const filteredCommissions = comms.filter(com => com.paymentTransNo === commNumber);
        result.push({
          commissions: filteredCommissions,
          commissionNumber: commNumber,
          paymentDate: filteredCommissions[0].paymentDate,
          amountTotal: filteredCommissions[0].commissionTotal,
          type: filteredCommissions[0].comType,
        });
      });
    }

    return result;
  })
  .then((res) => dispatch({ type: SET_COMMISSIONS, payload: res }))
  .catch(() => dispatch({ type: SET_COMMISSIONS, payload: [] }));

const fetchBankingDetails = service => id => dispatch => service
  .getUserRoles(id)
  .then((res) => {
    const userRoles = res.data || [];
    const repBankDetails = userRoles.find(bank =>
      bank.memType ? bank.memType.toLowerCase().includes('sale') : false);
    const partnerBankDetails = userRoles.find(bank =>
      bank.memType ? bank.memType.toLowerCase().includes('partner') : false);
    return { repDetails: repBankDetails || {}, partnerDetails: partnerBankDetails || {} };
  })
  .then((res) => dispatch({ type: SET_BANKING_DETAILS, payload: res }))
  .catch(() => dispatch({ 
    type: SET_BANKING_DETAILS,
    payload: { repDetails: {}, partnerDetails: {} } 
  }));

export default function(commissionService) {
  return {
    fetchSalesCommissions: fetchSalesCommissions(commissionService),
    fetchAllSalesCommissions: fetchAllSalesCommissions(commissionService),
    fetchBankingDetails: fetchBankingDetails(commissionService),
  };
}
