import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import CodeOfConduct from "./CodeOfConduct";
import Aims from "./Aims";
import DisciplinaryCodes from "./DiciplinaryCode";
import Policy from "./Policy";
import Partners from "../../storeLocator/StoreLocator";
import ContactUs from "./ContactUs";
import AboutContent from "./AboutContent";
import "./MainAbout.css"

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
});
class ScrollableTabsButtonAuto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className={classes.root}>
        {this.props.userDetails.isLoggedIn ? (
          <div>
            <AppBar
              position="static"
              color="default"
              style={{
                boxShadow:
                  "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
                borderBottom: "2px solid #e5e2e8",
                backgroundColor: "#fcfcfc",
                marginTop: "55px"
              }}
            >
              <h5 align="left" className="font-weight-bold p-3 mb-0">
                About
              </h5>
              <Tabs
                style={{ marginLeft: "7%" }}
                value={value}
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Overview" />
                <Tab label="Our Aims" />
                <Tab label="Code of Conduct" />
                <Tab label="Disciplinary Codes" />
                <Tab label="Privacy Policy" />
                <Tab label="Partners" />
                <Tab label="Contact Us" />
              </Tabs>
            </AppBar>
            {value === 0 && (
              <AboutContent isLoggedIn={this.props.userDetails.isLoggedIn} />
            )}
            {value === 1 && <Aims />}
            {value === 2 && <CodeOfConduct />}
            {value === 3 && <DisciplinaryCodes />}
            {value === 4 && <Policy />}
            {value === 5 && <Partners />}
            {value === 6 && <ContactUs />}
          </div>
        ) : (
            <div>
              <AppBar
                position="static"
                color="default"
                style={{
                  boxShadow:
                    "0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)",
                  borderBottom: "2px solid #e5e2e8",
                  backgroundColor: "#fcfcfc",
                  marginTop: "55px"
                }}
              >
                <h5 align="left" className="font-weight-bold p-3 mb-0">
                  About
              </h5>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Content" />
                  <Tab label="Our Aims" />
                  <Tab label="Code of Conduct" />
                  <Tab label="Partners" />
                  <Tab label="Contact Us" />
                </Tabs>
              </AppBar>
              {value === 0 && (
                <AboutContent isLoggedIn={this.props.userDetails.isLoggedIn} />
              )}
              {value === 1 && <Aims />}
              {value === 2 && <CodeOfConduct />}
              {value === 3 && <Partners />}
              {value === 4 && <ContactUs />}
            </div>
          )}
      </div>
    );
  }
}
ScrollableTabsButtonAuto.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ScrollableTabsButtonAuto));
