import API_URL from './apiUrl';
import axios from 'axios';

const fetchEventLookups = (headers) => () => axios.
  get(`${API_URL}/ActivityEventLookups`, {headers});

const fetchDisciplines = (headers) => () => axios.
  get(`${API_URL}/ActivityDisciplines`, {headers});

const fetchFirearmType = (headers) => () => axios.
  get(`${API_URL}/fireArmTypes/FireArmTypes`, {headers});

  const fetchFirearmTypeLookup = (headers) => () => axios.
  get(`${API_URL}/FireArmTypeLookups`, {headers});

const fetchOpticsLookup = (headers) => () => axios.
  get(`${API_URL}/OpticsLookups/get/`, {headers});

const fetchActivityType = (headers) => () => axios.
  get(`${API_URL}/ActivityLookups`, {headers});

const fetchSpecieType = (headers) => () => axios.
  get(`${API_URL}/SpecieLookups`, {headers});
  
const fetchFirearmActions = (headers) => () => axios.
  get(`${API_URL}/Actions`, {headers});

const fetchSections = (headers) => () => axios.
  get(`${API_URL}/SectionLookups`, {headers});

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    fetchEventLookups: fetchEventLookups(headers),
    fetchDisciplines: fetchDisciplines(headers),
    fetchFirearmType: fetchFirearmType(headers),
    fetchFirearmTypeLookup: fetchFirearmTypeLookup(headers),
    fetchOpticsLookup: fetchOpticsLookup(headers),
    fetchActivityType: fetchActivityType(headers),
    fetchSpecieType: fetchSpecieType(headers),
    fetchFirearmActions: fetchFirearmActions(headers),
    fetchSections: fetchSections(headers)
  };
}
