import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./chartStyles.css";
import {
  POPULATION_YEAR_DATA,
  POPULATION_MONTH_DATA,
  POPULATION_WEEK_DATA
} from "../../../../store/actions/lmsActionTypes";
import {
  WEEK_STATISTICS,
  MONTH_STATISTICS,
  YEAR_STATISTICS
} from "../../LMSAPIEndPoints";
export const Chart_Type = {
  WEEK: 1,
  MONTH: 2,
  YEAR: 3
}

export class GraphButtons extends Component {
  static propTypes = {
    prop: PropTypes
  }

  constructor(props) {
    super(props);

    this.state = {
      weekLoading: false,
      monthLoading: false,
      yearLoading: false
    }

    this.radioCollection = this.makeRadioCollection(5);
  }

  componentDidMount() {
    this.loadData(Chart_Type.WEEK)
  }

  makeRadioCollection = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  fetchDataCallback = (type, loading) => {
    this.props.loadData(type, loading)
  }

  findDataLength = (array) => {
    const data = array.find(element => element.key === this.props.id);
    if (data) {
      return data.data.length
    } else {
      return 0
    }
  }

  hasData = (type) => {
    switch (type) {
      case Chart_Type.WEEK:
        return this.findDataLength(this.props.weekData) > 0;
      case Chart_Type.MONTH:
        return this.findDataLength(this.props.monthData) > 0;
      case Chart_Type.YEAR:
        return this.findDataLength(this.props.yearData) > 0;
      default:
        return false;
    }
  }

  loadData = (type) => {
    this.fetchDataCallback(type, true);
    if (!this.hasData(type)) {
      this.fetchData(type, this.fetchDataCallback)
    } else {
      this.fetchDataCallback(type, false);
    }
  }

  render() {
    return (
      <div className="graph-buttons-container">
        <input type="radio" name={this.radioCollection} value="week" id={"week" + this.radioCollection} defaultChecked="checked" />
        <label className="graph-button" for={"week" + this.radioCollection} onClick={() => { this.loadData(Chart_Type.WEEK) }}> Week </label>
        <input type="radio" name={this.radioCollection} value="month" id={"month" + this.radioCollection} />
        <label className="graph-button" for={"month" + this.radioCollection} onClick={() => { this.loadData(Chart_Type.MONTH) }}> Month </label>
        <input type="radio" name={this.radioCollection} value="year" id={"year" + this.radioCollection} />
        <label className="graph-button" for={"year" + this.radioCollection} onClick={() => { this.loadData(Chart_Type.YEAR) }}> Year </label>
      </div>
    );
  }

  isLoading = (type, value) => {
    switch (type) {
      case Chart_Type.WEEK:
        this.setState({
          weekLoading: value
        })
        break;
      case Chart_Type.MONTH:
        this.setState({
          monthLoading: value
        })
        break;
      case Chart_Type.YEAR:
        this.setState({
          yearLoading: value
        })
        break;
      default:
        break;
    }
  }

  fetchData = (type, callback) => {
    this.isLoading(type, true);
    fetch(this.getUrl(type) + "/" + this.props.id)
      .then(res => res.json())
      .then(res => {
        this.pushData(type, res.data);
        this.isLoading(type, false);
        callback(type, false);
      })
  }

  pushData = (type, data) => {
    switch (type) {
      case Chart_Type.WEEK:
        this.props.onWeekDataChange(data, this.props.id);
        break;
      case Chart_Type.MONTH:
        this.props.onMonthDataChange(data, this.props.id);
        break;
      case Chart_Type.YEAR:
        this.props.onYearDataChange(data, this.props.id);
        break;
      default:
        break;
    }
  }

  getUrl = (type) => {
    switch (type) {
      case Chart_Type.WEEK:
        return WEEK_STATISTICS;
      case Chart_Type.MONTH:
        return MONTH_STATISTICS;
      case Chart_Type.YEAR:
        return YEAR_STATISTICS;
      default:
        return "";
    }
  }
}

const mapStateToProps = (state) => ({
  weekData: state.lmsStatistics.weekData,
  monthData: state.lmsStatistics.monthData,
  yearData: state.lmsStatistics.yearData
})

const mapDispatchToProps = dispatch => {
  return {
    onYearDataChange: (data, key) =>
      dispatch({
        type: POPULATION_YEAR_DATA,
        payload: data,
        key: key
      }),
    onMonthDataChange: (data, key) =>
      dispatch({
        type: POPULATION_MONTH_DATA,
        payload: data,
        key: key
      }),
    onWeekDataChange: (data, key) =>
      dispatch({
        type: POPULATION_WEEK_DATA,
        payload: data,
        key: key
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphButtons)
