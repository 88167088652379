import { CHECKOUT_DETAILS_ACTIONS } from "../actions/actionTypes";

const initialState = {
  payfastObj: {},
  payFastDetails: {
    PAY_FAST_URL: "",
    merchantId: "",
    merchantKey: "",
    amount: "",
    itemName: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_PAY_FAST_DETAILS:
      return {
        ...state,
        payfastObj: action.payfastObj
      };
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_PAY_FAST_URL:
      return {
        ...state,
        PAY_FAST_URL: action.PAY_FAST_URL
      };
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_MERCHANT_ID:
      return {
        ...state,
        merchantId: action.merchantId
      };
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_MERCHANT_KEY:
      return {
        ...state,
        merchantKey: action.merchantKey
      };
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_AMOUNT:
      return {
        ...state,
        amount: action.amount
      };
    case CHECKOUT_DETAILS_ACTIONS.UPDATE_ITEM_NAME:
      return {
        ...state,
        itemName: action.itemName
      };
    default:
      return state;
  }
};

export default reducer;
