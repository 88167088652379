import React from "react";
import { formatDate } from "../activitiesPage/ActivityDetails"
function DaysRecordMapper(props) {
    return(
        <div>
            {props.filteredDaysRecord.length > 0 ? (
                props.filteredDaysRecord.map((fdrLooper, index) => (
                    <div className="p-2 border-bottom font-weight-bold" key={index}>
                        <span>{fdrLooper.name}</span>
                        <div className="font-weight-bold">{props.dateDescription} <span>{formatDate(fdrLooper.date)}</span></div>
                        <div>Days Earned: <span>{fdrLooper.days}</span></div>
                    </div>
                ))
            ) : (<p className="text-warning font-weight-bold">Could not find any record matching given criteria</p>)}
        </div>
    )
}
export default DaysRecordMapper;