export const styles = () => ({
    rootStatic: {
      flexGrow: 1,
      paddingLeft: "10%",
      paddingRight: "10%",
      height: "80vh",
     
    },
    root: {
      backgroundColor: "white",
      width: "100%",
      height: "40vh",
     
    },
  
    container: {
        width: "100%",
        paddingRight: "15px",
        paddingLeft: "15px",
        marginRight: "-100px",
        marginLeft: "auto"
    },
    container2: {
      width: "100%",
      paddingLeft: "250px",
      
    },
    container1: {
      width: "100%",
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "-80px",
      marginLeft: "auto"
  },
  
    footer: {
      display: "flex",
      flexDirection: "row"
    },
  
    TextValidator: {
      marginLeft: "20%",
      marginRight: "20%",
      fullWidth: true,
      flex: "1px",
      margin: "5px"
    },

    grow_: {
      flex: "6px"
    },
  
    grow1: {
      flex: "4px"
    },
  
    select: {
      marginTop: "1%",
      margin: "5%",
      width: "30%"
    },
  
    select2: {
      width: "40%",
      marginLeft: "80%",
      marginTop: "1%",
      marginRight: "20%",
      align: "Right"   
    },
    icon: {
      margin: "0px",
      padding: "0px",
      align: "center",
      fontSize: "50px"
    },
    filterBy: {
        marginRight:"-78px"
    },
    BtnCs: {
        backgroundColor:"white",
    },
    RstBtn:{
      marginRight:"-1000px"
    },
    hr: {
        boxShadow: "px 2px 4px orange",
        borderBottom: "2px solid orange",
        margin: "0 auto",
        marginBottom: "20px"
        },
    hr1: {
        boxShadow: "px 2px 4px grey",
        borderBottom: "2px solid grey",
        margin: "0 auto",
        marginBottom: "10px",
        width:"100%"
     },
    forminline: {
      width:"100%",
      height:"100%"
    },
    title: {
      marginLeft: "100px"
    }
    
  });
  