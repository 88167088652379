import React, { Component } from "react";

export default class ActivitySummary extends Component {
  render() {
    if (this.props.activity)
      return (
        <div className="row p-3 border-bottom  font-weight-bold">
          <p className="col-12 m-0">{this.props.activity.activityType}</p>
          <p className="col-12 m-0">
            {this.props.activity.activityDate.substring(0, 10)}
          </p>
          <p className="col-12 m-0">{`Score: ${this.props.activity.score}`}</p>
          {this.props.activity.status === "Rejected" ? (
            <p className="col-12 m-0 text-danger">
              {this.props.activity.status}
            </p>
          ) : this.props.activity.status === "Pending" ? (
            <p className="col-12 text-warning">{this.props.activity.status}</p>
          ) : (
            <p className="col-12 text-success">{this.props.activity.status}</p>
          )}
        </div>
      );
    else return null;
  }
}
