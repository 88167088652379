import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ActivityView: {
    ActivityType: "",
    MemNo: "",
    ActivityDate: null,
    ActivityDescription: "",
    DateSubmitted: null,
    FirearmType: "",
    Caliber: "",
    PostalShoot: 0,
    Score: 0,
    MemNoNavigation: null
  },
  activityTypeFilter: "Activity Type",
  activityDateFilter: "2019-01-01",
  showActivityFilterBar: false,
  userActivities: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ADD_MEMBER_VIEW_DETAILS:
      const newActivityDetails = {
        ...state.ActivityView,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        ActivityView: newActivityDetails
      };
    case actionTypes.UPDATE_MEMBER_VIEW_ACTIVITY_TYPE_FILTER:
      return {
        ...state,
        activityTypeFilter: action.payload
      };
    case actionTypes.UPDATE_MEMBER_VIEW_ACTIVITY_DATE_FILTER:
      return {
        ...state,
        activityDateFilter: action.payload
      };
    case actionTypes.TOGGLE_MEMBER_VIEW_SHOW_ACTIVITY_FILTER_BAR:
      return {
        ...state,
        showActivityFilterBar: !state.showActivityFilterBar
      };
    case actionTypes.UPDATE_MEMBER_VIEW_ACTIVITIES:
      return {
        ...state,
        userActivities: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
