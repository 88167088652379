import React, { Component } from "react";
import Bar from "./Bar";
import { connect } from "react-redux";
import "./styles/dstyle.css";

class AdminMembers extends Component {
  constructor(props) {
    super(props);
  }
  toMemberCounts = () => {
    window.location = "/MemberCounts"
  }
  render() {
    const totalMembers = this.props.totalMembers.totalMembers;
    const dedicatedHunterMembers = this.props.membersDH.membersDedicatedHunter;
    const dedicatedSportShooterMembers = this.props.membersDSS.membersDSS;
    const nonDedicatedMembers =
      totalMembers - (dedicatedSportShooterMembers + dedicatedHunterMembers);
    return (
      <div>
        <div className="row mt-4">
          <div className="col-12 h2">Members</div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-12 ">
            <div className="bg-light" 
              onClick={() => this.toMemberCounts()}
              style={{ cursor: "pointer" }}
            > 
              <Bar percent={100} total={totalMembers} bColour="#4A90E2" />
            </div>
          </div>
          <div className="col-12 h5">Total Members</div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="bg-light">
              <Bar
                percent={(dedicatedSportShooterMembers / totalMembers) * 100}
                total={dedicatedSportShooterMembers}
                bColour="#27AA8C"
              />
            </div>
          </div>
          <div className="col-12 h5">Dedicated Sport Shooter Members</div>
        </div>
        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="bg-light">
              <Bar
                percent={(dedicatedHunterMembers / totalMembers) * 100}
                total={dedicatedHunterMembers}
                bColour="#7ED321"
              />
            </div>
          </div>
          <div className="col-12 h5">Dedicated Hunter Members</div>
        </div>

        <div className="row mt-2 mb-2">
          <div className="col-12">
            <div className="bg-light">
              <Bar
                percent={(nonDedicatedMembers / totalMembers) * 100}
                total={nonDedicatedMembers}
                bColour="#F5A623"
              />
            </div>
          </div>
          <div className="col-12 h5">Non Dedicated Members</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pendingA: state.dashBoardReducer.activities.pendingCount,//pendingA,
    pendingDS: state.dashBoardReducer.pendingDS,
    pendingAE: state.dashBoardReducer.pendingAE,
    pendings: state.dashBoardReducer.pendings,
    membersDSS: state.dashBoardReducer.membersDSS,
    membersDH: state.dashBoardReducer.membersDH,
    totalMembers: state.dashBoardReducer.totalMembers
  };
};

export default connect(mapStateToProps)(AdminMembers);
