import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import {
  BASE,
  GET_SPECIFIC_USER_ENDORSEMENTS_URL
} from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";

const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 2,
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    width: "25%",
    display: "inline-block",
    fontSize: 14,
    color: "black",
    align: "right"
  },
  states: {
    color: "#32CD32"
  }
};

class AdminEndorsementView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memNo: props.userProfileDetails.memberNumber
    };
  }


  componentDidMount() {
    axios
      .get(
        BASE +
        GET_SPECIFIC_USER_ENDORSEMENTS_URL +
        this.props.MemberDetails.memNo, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      }
      )
      .then(res => {
        this.props.onApplicationsChange(res.data);
      });
  }

  handleSubmit = e => {
    axios
      .get(
        BASE +
        GET_SPECIFIC_USER_ENDORSEMENTS_URL +
        this.props.MemberDetails.memNo, {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      }
      )
      .then(res => { });
    this.props.onCurrentAdminEndorsementTabChange("1");
    this.props.onAddEndorsementChange(this.props.title, "title");
  };

  handleChange = e => {
    this.props.onCurrentAdminEndorsementTabChange("1");
  };

  render() {
    const DisplayEndorsementList = (
      <div className="row">
        {this.props.applications.map(post => (
          <div key={post.id} className="border-bottom font-weight-bold col-12">
            <div
              className="row pt-3 pb-3"
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.onApplicationChange(post);
                this.handleChange();
              }}
            >
              <div className="col-12 m-0">{post.title}</div>
              <div className="col-12 m-0">
                {post.status === "Approved" ? (
                  <div className="text-success">Approved</div>
                ) : post.status === "Rejected" ? (
                  <div className="text-danger">Rejected</div>
                ) : (
                      <div className="text-warning">Pending</div>
                    )}
              </div>
              <div className="col-12 m-0">
                {post.approvalDate?.substring(0, 10)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );

    return (
      <div className={this.props.isPartner? "" :"container"}>
        <div className="row">
          <GenericHeader title="Endorsement Applications" headerColor={this.props.headerColor}/>
        </div>
        {this.props.applications.length === 0
          ? "No available endorsement applications currently"
          : DisplayEndorsementList}
      </div>
    );
  }
}

AdminEndorsementView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    MemberDetails: state.MemberViewReducer.MemberView,
    applications: state.endorsementReducer.applications,
    currentAdminEndorsementTab: state.adminPortalReducer.adminPortal.currentAdminEndorsementTab,
    userProfileDetails: state.loginReducer.userProfileDetails,
    memberState: state.loginReducer.memberState
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION,
        application: newApplication
      }),
    onApplicationsChange: newApplications =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATIONS,
        applications: newApplications
      }),
    onCurrentAdminEndorsementTabChange: newTabName =>
      dispatch({
        type:
          actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ADMIN_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADMIN_ENDORSEMENT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdminEndorsementView));
