import Axios from "axios";
import React, { Component } from "react";
import { BASE, FRONT } from "../../../../API/APIendpoint";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import * as actionTypes from "../../../../store/actions/actionTypes";
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import { Button } from "react-bootstrap";

toast.configure();
class ActivityInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityLink: "",
      docName: "",
      type: "",
      gallery: null,
      checked: false,
      rejectReason:'',
      rejectReasonUpdate:'',
      activityEvent: [],
      _activityDescription: this.props.activity.activityDescription
    };
  }
  componentDidMount() {
    Axios.get(BASE + "api/Activities/" + this.props.activity.activityId, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }).then(
      res => {
        this.setState({
          activityLink: res.data.link
        });
      }
    );
    Axios({
      url: `${BASE}api/ActivityEventLookups`,
      method: "get",
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` }
    })
      .then(res => {
        this.setState({
          activityEvent: res.data
        })
      })

      //Check if the activity has a gallery item 
      Axios({
        url: `${BASE}api/Gallery/HasGallleryItem?activityId=${this.props.activity.activityId}`,
        method: "get",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(response => {
         this.setState({gallery: response.data, checked: response.data.approved});
      });
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({checked: !this.state.checked});

    //send API to approve the gallery item
    let payload = {
      Id: this.state.gallery.id,
      ActivityId: this.props.activity.activityId,
      Approved: !this.state.checked
    }
    this.approveGallery(payload);

  };

  handleRejectionReason = () => {

    this.setState({rejectReasonUpdate: this.state.rejectReason})
    let payload = {
      Id: this.state.gallery.id,
      ActivityId: this.props.activity.activityId,
      rejectionReason: this.state.rejectReason
    }
    this.rejectionReasonForGallery(payload);

  };

  approveGallery =(payload)=>{
    Axios({
      url: `${BASE}api/Gallery/ApproveGallery`,
      method: "post",
      data: payload,
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    });
  }

  rejectionReasonForGallery =(payload)=>{
    Axios({
      url: `${BASE}api/Gallery/RejectGallery`,
      method: "post",
      data: payload,
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    });
  }

  updateActivityDescription = e => {
    e.preventDefault();
    Axios({
      url: `${BASE}api/Activities/${this.props.activity.activityId}/${e.currentTarget.value.split('~')[0]}`,
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
      .then(res => {
        toast.success(res.data.message)
        this.setState({
          _activityDescription: res.data.activity.activityDescription
        });
      });

  };
  render() {
    const info = (row1, row2) => (
      <div className="row mt-2 mb-1">
        <div className="col-12">{row1}</div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    if (this.props.activity)
      return (
        <div
          className="container"
          style={{ height: "550px", overflow: "auto" }}
        >
          {info("Member Number:", this.props.activity.memNo)}
          {info("Activity ID:", `Activity ${this.props.activity.activityId}`)}
          {info("Activity Date:", this.props.activity.activityDate.toString().split("T")[0])}
          {info("Activity Description:",
            <select
              className="form-control input-sm m-0"
              onChange={e => {this.updateActivityDescription(e);this.props.onActivityDescriptionChange(e.currentTarget.value)}}
            >
              <option>{this.state._activityDescription}</option>
              {this.state.activityEvent.map(
                    (act, i) => (
                      <option key={i} value={`${act.activityEvent}~${act.ranking}`}>
                        {act.activityEvent}`
                      </option>
                    )
                  )}
            </select>
          )}
          {info("Firearm Type:", this.props.activity.firearmType)}
          {info("Caliber:", this.props.activity.caliber)}
          {info("Association:", this.props.activity.association)}
          {this.state.activityLink === "NULL" || !this.state.activityLink ? (
            <div className="font-weight-bold text-danger">Null: </div>
          ) : (
              <a
                className="text-primary font-weight-bold col-4 pl-0 mb-1"
                href={FRONT + this.state.activityLink }
                target="_"
                blank
              >
                Document
              </a>
            )}
      <br/>

      {this.state.gallery && <>
        {
          this.state.checked ? <Typography variant="overline">Approved for Gallery </Typography>
                            : <Typography variant="overline">Approve Activity for Gallery: </Typography>
        }
        <Checkbox
          checked={this.state.checked}
          onClick={this.handleChange}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        /> <br/>

        {!this.state.checked &&
        <div className="row">
        <div className="col-9">
        <input
        className="form-control"
                    onChange={(event) => {
                      this.setState({
                        rejectReason: event.target.value
                      })
                    }}
                    placeholder="Provide a reason for gallery rejection"
                  />

        </div>
        <div className="col-3">
          {
            (this.state.rejectReason == this.state.rejectReasonUpdate ) && (this.state.rejectReason != '')
            ? <i style={{color:'green', marginTop:'10px'}}>updated</i>
            :<Button className="form-control btn-warning" onClick={()=> this.handleRejectionReason()} style={ this.state.rejectReason == this.state.rejectReasonUpdate? {display:'none'}:{display:''}}>Save</Button>
          }
        </div>
      </div>
          }
      </>}

        </div>
      );
    else return null;
  }
}
const mapDispatchToProps = dispatch => {
return {
    onActivityDescriptionChange: ActDescription =>
    dispatch({ 
        type: actionTypes.UPDATE_SELECTED_ACTIVITY_DESCRIPTION, 
        selectedActivityDescription: ActDescription 
        })
    };
  };
export default connect(
null,
mapDispatchToProps
)(ActivityInfo);
