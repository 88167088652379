import React, { Component } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import axios from 'axios';
import { BASE } from '../../../API/APIendpoint';

export class MapsContainer extends Component {

    constructor(props)
    {
        super(props);
    }

    componentDidMount(){
      const Geocoder = new this.props.google.maps.Geocoder();

      this.props.partners.forEach(element => {
          if(element.lat == null || element.long == null)
            Geocoder.geocode( { 'address': element.adress}, function(results, status) {
            if (status == 'OK') {
                const location = results[0]?.geometry?.location;

                let _address = {
                  "shootingRangeId": element.shootingRangeId,
                  "lat": location.lat(),
                  "long": location.lng()
                }

                axios.post(`${BASE}api/ShootingRanges/updateShootingRangeLatAndLong`,
                _address,
                  {headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}});

            } else {
              // alert("failed")
              console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
      });
    }

    state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
    };
    
    onMarkerClick = (props, marker, e) =>
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true
      });
   
    onMapClicked = (props) => {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null
        })
      }
    };
   
    render() {
      return (
        <Map google={this.props.google} onClick={this.onMapClicked}
        mapContainerStyle={{ width: "100vw", height: "100vh"}}
        initialCenter={{
            lat: -28.8166236,
            lng: 24.991639
          }}
        //   zoom={5.8}>
          zoom={this.props.zoom || 5.8}>
              {
                  this.props.partners.map(address =>{
                     return <Marker 
                          style={{cursor:'hand'}}
                          name={address.store ? address.store : address.shootingRange}
                          onClick={this.onMarkerClick}
                          position={{lat:  address.lat, lng: address.long}} />
                } )
              }

          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}>
              <div>
                <span>{this.state.selectedPlace.name}</span>
              </div>
          </InfoWindow>
          
        </Map>
      )
    }
  }
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyC_WhGsGoYjzZiuOCyy4R4nYGNziKWrD68")
})(MapsContainer)