import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  btnContainer: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    flex: 1
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: 20,
    backgroundColor: "black",
    color: "white",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50
  },
  icon: {
    margin: 0,
    padding: 0,
    align: "center",
    fontSize: 50
  }
});

class PaymentNewMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _paymentMethod: "",
      _amountPaid: 0,
      _member: this.props.memRenewal,
      _members: this.props.newRenewals.newRenewals,
      _pendingNRCount: this.props.newRenewals.pendingNRCount - 1,
      _transactionDate: "",
      _transactionRef: ""
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  renewalMembersUpdate = () => {
    this.state._member.status = 1
    this.state._members.forEach(element => {
      if(element.memNo === this.props.memRenewal.memNo) {
        element.status = 1
      }
    })
  }

  handleSubmit = async e => {
    e.preventDefault();
    let yearInt = parseInt(new Date().getFullYear(), 10) + 1;
    const accountInfo = {
      PaymentType: "Membership Fees " + yearInt,
      PaymentMethod: this.state._paymentMethod,
      AmountPaid: this.state._amountPaid,
      Email: this.props.memRenewal.email,
      CostCode: 1012,
      Paid: true,
      MemNo: this.props.memRenewal.memNo,
      TransactionNo: this.state._transactionRef,
      PaymentDate: this.state._transactionDate
    };
    Axios({
      url: BASE + "api/Member/ApproveNewMember/" + this.props.memRenewal.memNo + "/1",
      data: accountInfo,
      method: "post",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    this.renewalMembersUpdate();
    this.props.onMemberRenewalUpdate(this.state._pendingNRCount, this.state._members)
    this.props.onMemberRenewalObjectUpdate(this.state._member)
    this.props.history.push("/admin/MembershipRenewal");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div>
          <GenericHeader
            showBackButton
            backFunction={() => {
              this.props.history.push("/ApproveMembershipRenewal");
            }}
            title="Membership Payment"
          />
        </div>

        <form
          className={classes.container}
          autoComplete="off"
          onSubmit={e => this.handleSubmit(e)}
        >
          <TextField
            id="outlinedpaymentMethod"
            label="Payment Method"
            className={classes.textField}
            variant="outlined"
            onChange={e => this.setState({
              _paymentMethod: e.target.value
            })}
            required
          />
          <TextField
            id="outlinedamountPaid"
            label="Amount Paid"
            className={classes.textField}
            variant="outlined"
            onChange={e => this.setState({
              _amountPaid: e.target.value
            })}
            required
          />
          <TextField
            id="outlinedtransactionMethodPaid"
            label="Transaction Reference"
            className={classes.textField}
            variant="outlined"
            onChange={e => this.setState({
              _transactionRef: e.target.value
            })}
            required
          />
          <TextField
            id="date"
            label="Transaction Date"
            type="date"
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => this.setState({
              _transactionDate: e.target.value
            })}
          />
          <div className={classes.btnContainer}>
            <button
              type="submit"
              className="btn btn-warning form-control text-light"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PaymentNewMember.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    approveNewMemberState: state.approveNewMemberState,
    pendingMember: state.dashBoardReducer.membersPending,
    memRenewal: state.dashBoardReducer.membersRenewal,
    newRenewals: state.dashBoardReducer.newRenewals
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onMemberRenewalUpdate: (newPendingCount, newMemberList) =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.GET_ALL_RENEWAL_MEMEBRS_URL,
        newRenewals: {
          pendingNRCount: newPendingCount,
          newRenewals: newMemberList
        }
      }),
    onMemberRenewalObjectUpdate: memberObject =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.UPDATE_RENEWAL_MEMBERS,
        membersRenewal: memberObject
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentNewMember));
