import React, { Component } from "react";

export default class ShooterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dedicatedStatus: "No",
      proffessionalHunter: "No",
      assessments: null,
      error: "no errors"
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="col">
          <p className="col-12 mb-0">
            Do you currently hold a dedicated Shooter status with any other
            accreddited association?
          </p>
        </div>
        <div className="row form-check-inline">
          <div className="col">
            <label className="form-check-label  ml-5 mb-3">
              <input
                type="radio"
                className="form-check-input "
                value="Yes"
                checked={this.props.DSApplicationState.Ods === "Yes"}
                name="accreditation"
                onChange={e => {
                  this.setState({ dedicatedStatus: e.target.value });
                  this.props.setDSVariables("Ods", e.target.value);
                }}
              />
              Yes
            </label>
          </div>
          <label className="form-check-label ml-3 mb-3">
            <input
              type="radio"
              className="form-check-input "
              name="accreditation"
              value="No"
              checked={this.props.DSApplicationState.Ods === "No"}
              onChange={e => {
                this.setState({ dedicatedStatus: e.target.value });
                this.props.setDSVariables("Ods", e.target.value);
              }}
            />
            No
          </label>
        </div>
        {this.props.DSApplicationState.Ods === "Yes" && (
          <React.Fragment>
            <div className="form-group mr-2">
              <label htmlFor="appName" className="">
                Association Name
              </label>
              <input
                type="text"
                id="appName"
                value={this.props.DSApplicationState.OdsAssociationName}
                placeholder="Enter Association Name"
                className="form-control mr-lg-3 "
                onChange={e =>
                  this.props.setDSVariables(
                    "OdsAssociationName",
                    e.target.value
                  )
                }
              />
            </div>
            <div className="form-group mr-2">
              <label htmlFor="far" className="">
                Association FAR Number
              </label>
              <input
                type="text"
                id="far"
                value={this.props.DSApplicationState.OdsFarNo}
                placeholder="Enter FAR Number"
                className="form-control mr-lg-3 "
                onChange={e =>
                  this.props.setDSVariables("OdsFarNo", e.target.value)
                }
              />
            </div>
            <div className="form-group mr-2">
              <label htmlFor="dsNo" className="">
                Dedicated Status Number
              </label>
              <input
                type="text"
                id="dsNo"
                value={this.props.DSApplicationState.OdsDsNo}
                placeholder="Enter Status Number"
                className="form-control mr-lg-3 "
                onChange={e =>
                  this.props.setDSVariables("OdsDsNo", e.target.value)
                }
              />
            </div>
            <div className="form-group mr-2">
              <label htmlFor="dsDate" className="">
                Expiry Date of Membership
              </label>
              <input
                type="date"
                id="dsDate"
                value={this.props.DSApplicationState.OdsExpiryDate}
                className="form-control mr-lg-3 "
                onChange={e =>
                  this.props.setDSVariables("OdsExpiryDate", e.target.value)
                }
              />
            </div>
            <div className="form-group mr-2">
              <label htmlFor="dsDocs" className="">
                Document(s)
              </label>
              <input
                id="dsDocs"
                type="file"
                className="form-control mr-lg-3 "
                onChange={e =>
                  this.props.setDSVariables("OdsAttachment", e.target.files[0])
                }
              />
            </div>
          </React.Fragment>
        )}
        <div className="col">
          <p className="col-12 mb-0">
            Have you completed the practical and theoretical assessment?
          </p>
        </div>
        <div className="row form-check-inline">
          <div className="col">
            <label className="form-check-label ml-5">
              <input
                type="radio"
                className="form-check-input"
                value="Yes"
                checked={this.props.DSApplicationState.Assessments === "Yes"}
                name="assessmentComplete"
                onChange={e =>
                  this.props.setDSVariables("Assessments", e.target.value)
                }
              />
              Yes
            </label>
          </div>
          <label className="form-check-label ml-3">
            <input
              type="radio"
              className="form-check-input"
              name="assessmentComplete"
              checked={this.props.DSApplicationState.Assessments === "No"}
              value="No"
              onChange={e =>
                this.props.setDSVariables("Assessments", e.target.value)
              }
            />
            No
          </label>
        </div>
      </React.Fragment>
    );
  }
}
