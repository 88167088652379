import { Button, Typography } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconLeft from "@material-ui/icons/KeyboardArrowLeft";
import axios from "axios";
import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import request from "superagent";
import { BASE, GET_ACTIVITIESFILES_UPLOAD, POST_NEW_ACTIVITY_URL } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import NewActivityHeader from "./NewActivityHeader";

const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 1
  },
  select: {
    marginLeft: "20%",
    marginRight: "20%",
    flex: 1,
    margin: 5
  },
  button: {
    margin: 30,
    padding: 0,
    borderRadius: 20,
    backgroundColor: "black",
    color: "white",
    display: "inline-block",
    flex: 1,
    width: 50,
    height: 50,
    fontSize: 20
  },
  icon: {
    margin: 0,
    padding: 0,
    fontSize: 50
  },
  fileSelect: {
    marginLeft: "40%",
    marginRight: "40%",
    color: "green"
  },
  dropzone: {
    height: 80,
    width: 140,
    backgroundColor: "#f2f2f2",
    marginLeft: "auto",
    marginRight: "auto"
  }
});
class NewActivityPage2 extends Component {
  makePostRequest = newActivity => {
    axios.post(BASE + POST_NEW_ACTIVITY_URL,{ newActivity },{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}});
    this.props.onMembersAreaChange("0", "currentActivityTab");
  };

  onDrop = files => {
    const req = request.post(BASE + GET_ACTIVITIESFILES_UPLOAD);

    let state = "Uploading";

    let Component = this;
    files.forEach(file => {
      state = "Uploading";
      Component.props.onAddActivityDetailsChange(state, "FileUploadStatus");
      req.attach(file.name, file).on("progress", function(e) {});
    });

    req.then(res => {
      if (res.statusCode === 200) {
        Component.props.onAddActivityDetailsChange("Done", "FileUploadStatus");
      } else {
        Component.props.onAddActivityDetailsChange(
          "Upload Error",
          "FileUploadStatus"
        );
      }
    });
  };

  render() {
    const { classes, newActDetails } = this.props;
    const firearmTypes = ["9mm", "Colt 45", "Desert Eagle"];
    const optics = [
      "Optics",
      "Fiber",
      "Transision lenses",
      "Tested",
      "Sunglasses"
    ];
    return (
      <div>
        <NewActivityHeader />
        <form className={classes.container} noValidate autoComplete="off">
          <Select
            className={classes.select}
            native
            value={newActDetails.FirearmType}
            onChange={event =>
              this.props.onAddActivityDetailsChange(
                event.target.value,
                "FirearmType"
              )
            }
            input={
              <OutlinedInput
                name="Firearm Type"
                id="outlined-age-native-simple"
              />
            }
          >
            <option value="">Firearm Type</option>
            {firearmTypes.map((gun, index) => (
              <option key={index} value={gun}>
                {gun}
              </option>
            ))}
          </Select>
          <Select
            className={classes.select}
            native
            value={newActDetails.PostalShoot}
            onChange={event =>
              this.props.onAddActivityDetailsChange(
                event.target.value,
                "PostalShoot"
              )
            }
            input={
              <OutlinedInput
                name="Optics"
                id="outlined-age-native-simple"
              />
            }
          >
            <option value="">Optics</option>
            {optics.map((optic, index) => (
              <option key={index} value={optic}>
                {optic}
              </option>
            ))}
          </Select>
          <TextField
            id="outlined-name"
            label="Caliber"
            className={classes.textField}
            value={newActDetails.Caliber}
            onChange={event =>
              this.props.onAddActivityDetailsChange(
                event.target.value,
                "Caliber"
              )
            }
            variant="outlined"
          />
          <Typography align="center">Upload Document(s):</Typography>

          <div className={classes.dropzone}>
            <Dropzone onDrop={this.onDrop}>
              {({ getRootProps, getInputProps, isDragActive }) => {
                return (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <p>Drop files here...</p>
                    ) : (
                      <p>click to select files to upload.</p>
                    )}
                  </div>
                );
              }}
            </Dropzone>
          </div>
          <Typography
            className={classes.textField}
            align="center"
            onClick={this.handleState}
          >
            Upload status: {newActDetails.FileUploadStatus}
          </Typography>
          <Typography className={classes.textField} align="center">
            Your activity submissions is subject to approval
          </Typography>
          <Typography className={classes.textField} align="center">
            Once your activity has been approved it will show in the "My
            Profile" page under the activity section
          </Typography>
          <div className={classes.grow1} />
          <div className={classes.footer}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() =>
                this.props.onMembersAreaChange("1", "currentActivityTab")
              }
            >
              <IconLeft className={classes.icon} />
            </Button>
            <div className={classes.grow} />
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => this.makePostRequest(newActDetails)}
            >
              Add
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.membersArea.currentActivityTab,
    newActDetails: state.activitiesReducer.addActivityDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddActivityDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADD_ACTIVITY_DETAILS,
        varValue: value,
        varName: variableName
      }),
    onMembersAreaChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewActivityPage2));
