import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline, Grid, Typography,} from "@material-ui/core";
import { ValidatorForm } from "react-form-validator-core";
import { BASE, NEW_PASSWORD_URL } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import { Form } from "react-bootstrap";
import JWT from "jwt-decode";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { toast } from 'react-toastify';
toast.configure();
const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    flex: 6,
    height: "100vh"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

class ResetRedirect extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      applyClicked: false,
      newPassword: null,
      newPasswordConfirmation: null,
      showPasswordNew: false,
      showPasswordConfirm: false,
      _invalidNewPassword: "",
      _invalidConfirmPassword: "",
      validatePasswordTester: /(?=^.{6,15}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
    };
  }
  
  handleSubmit = () => {
    var decode = JWT(this.props.snippedUrl);
    const user = {
      email: decode.email,
      password: this.props.resetPassDetails.password,
      confirmPassword: this.props.resetPassDetails.confirmPassword
    };

    const newPasswordURL = BASE + NEW_PASSWORD_URL;
    if (user.password === user.confirmPassword) {
      axios({
        url: newPasswordURL,
        data: user,
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      })
      .then(res => {
        if (res.data.status === "Password changed") {
          toast.success("Password Reset Success!");
          setTimeout(() => {
            this.props.history.push("/index.html")
            window.location.reload();
          }, 2000);
        } else {
          toast.error(`${user.email} does not exist!`);
        }
      });
    } else {
      toast.error("Password do not match");
    }
  }
  handleClickShowPasswordNew = () => {
    this.setState(state => ({ showPasswordNew: !state.showPasswordNew }));
  };
  handleClickShowPasswordConfirm = () => {
    this.setState(state => ({ showPasswordConfirm: !state.showPasswordConfirm }));
  };
  changePasswordConfirm = async () => {
    if(!(this.state.validatePasswordTester.test(this.props.resetPassDetails.password)))
      this.setState({_invalidNewPassword: "Password expects 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-15 characters"});
    if(!(this.state.validatePasswordTester.test(this.props.resetPassDetails.confirmPassword)))
      this.setState({_invalidConfirmPassword: "Password expects 1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-15 characters"});
    else {
      this.handleSubmit();
    }
  }
  render() {
    {/* <Grid container spacing={3} className={classes.main} style={{ marginTop: "80px" }}> */}
    const { classes } = this.props;
    return (
<div className="container text-center" style={{ marginTop: "80px" }}>

<Grid container justify="center">

    <div className={classes.paper}>
    <Grid item >
        {/* <CssBaseline /> */}
        <h1 className={"heading1"} align="center">Reset Password</h1>
        <h3 className={"heading"}>
          <center>Please choose a new password</center>
        </h3>
        <br />
        <label>Enter New Password</label>
        <Input
          type="password"
          className="form-control"
          type={this.state.showPasswordNew ? "text" : "password"}
          id="password"
          title="1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-15 characters" required
          onChange={event =>
            this.props.onResetPassDetailsChange(
              event.target.value,
              "password"
            )
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPasswordNew}
              >
                {this.state.showPasswordNew ? (
                  <Visibility />
                ) : (
                    <VisibilityOff />
                  )}
              </IconButton>
            </InputAdornment>
          }
        />
        {!(this.state.validatePasswordTester.test(this.props.resetPassDetails.password)) ? 
          <div className="text-danger">{this.state._invalidNewPassword}</div> : null}
        <label>Confirm Password</label>
        <Input
          type="password"
          className="form-control"
          type={this.state.showPasswordConfirm ? "text" : "password"}
          id="confirmPassword"
          title="1 small-case letter, 1 Capital letter, 1 digit, 1 special character and the length should be between 6-15 characters" required
          onChange={event =>
            this.props.onResetPassDetailsChange(
              event.target.value,
              "confirmPassword"
            )
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPasswordConfirm}
              >
                {this.state.showPasswordConfirm ? (
                  <Visibility />
                ) : (
                    <VisibilityOff />
                  )}
              </IconButton>
            </InputAdornment>
          }
        />
        {!(this.state.validatePasswordTester.test(this.props.resetPassDetails.confirmPassword)) ? 
          <div className="text-danger">{this.state._invalidConfirmPassword}</div> : null}
        <div className="text-center">
          <button className="btn btn-warning mt-3 mb-3" onClick={() => this.changePasswordConfirm()}>
            SUBMIT
          </button>
        </div>
      
    </Grid>
</div>
</Grid>

  </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    resetPassDetails: state.loginReducer.resetPassDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetPassDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_RESET_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(ResetRedirect)));
