import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core/';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    paddingTop: '100px'
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    paddingBottom: '8px',
    textAlign: 'justify'
  },
  offlineButton: {
    marginTop: theme.spacing(4),
  },
});

class Cancel extends Component {
  handleSubmit = () => {
    this.props.history.push('/login');
    window.location.reload();
  };
  render() {
    const {classes} = this.props; 
    return (
      <React.Fragment>
        <Grid item container direction="column" alignItems="center" justifyContent="center" className={classes.root}>
          <Grid item container direction="column" alignItems="center" justifyContent="center"  xs={11}>
            <Typography className={classes.paragraph}>
              You cancelled the payment process.
            </Typography>
            <Typography className={classes.paragraph}>
              You may login and try paying again below
            </Typography>
            <Button
              size="small"
              disableElevation
              variant="contained"
              color="secondary" 
              className={classes.offlineButton}
              onClick={this.handleSubmit}
            >
              Login and Continue
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}
Cancel.propTypes = {
  classes: PropTypes.object.isRequired
};


export default withStyles(styles)(withRouter(Cancel));