import SwapHoriz from "@material-ui/icons/SwapHoriz";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Component } from "react";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { BASE } from "../../API/APIendpoint";
import { STATUS } from "../../store/constantFile/ConstFile";

class DedicatedTimeline extends Component {
  componentDidMount() {
    axios({
      url: BASE + "api/Timelines/post/Individual",
      data: { Dsno: this.state.dsno },
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
    .then(res => {
      this.setState({ timelines: res.data.timelines.reverse() });
    })
    .catch(function(error) {
      if (error.status === 400) {
        alert(error.response.data.message);
      }
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      dsno: props.status.dsno,
      timelines: []
    };
  }
  getColor(type) {
    switch (type) {
      case STATUS.Approved: {
        return "#4BB543";
      }
      case STATUS.Pending: {
        return "#FFCC00";
      }
      case STATUS.Declined: {
        return "#CC3300";
      }
      case STATUS.Terminated: {
        return "#CC3300";
      }
      default:
    }
  }

  render() {
    return (
      <div className="bg-light">
        <VerticalTimeline>
          {this.state.timelines.map((timeline, i) => (
            <VerticalTimelineElement
              key={i}
              className="vertical-timeline-element--work"
              date=""
              iconStyle={{
                background: this.getColor(timeline.status),
                color: "#fff"
              }}
              icon={<SwapHoriz />}
            >
              {
                <h5
                  className="vertical-timeline-element-title"
                  style={{ color: this.getColor(timeline.status) }}
                >
                  {timeline.status}
                </h5>
              }
              <h8 className="vertical-timeline-element-subtitle text-black-50">
                {timeline.date.split("T", 1)}
              </h8>
              <p style={{ color: this.getColor(timeline.status) }}>
                {timeline.reason}
              </p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    );
  }
}

export default DedicatedTimeline;
