import React from "react";

function FilterButtons(props) {
  let unclickedButton =
    "w3-button w3-white w3-hover-orange w3-block w3-border w3-border-black w3-round-large";
  let clickedButton =
    "w3-button w3-orange w3-hover-orange w3-block w3-border w3-border-orange w3-round-large";
  let unclickedMainButton =
    "w3-button w3-white w3-hover-blue w3-block w3-border w3-border-black w3-round-large";
  let clickedMainButton =
    "w3-button w3-blue w3-hover-blue w3-block w3-border w3-border-orange w3-round-large";
  return (
    <div className="bg-light">
      <div>
        <div>
          <div className="mb-2" style={{ marginLeft: "33%" }}>
            Filter By:
          </div>
          <div className="filterButtons mb-2">
            <button
              className={
                props.generalButton === true
                  ? clickedButton
                  : unclickedButton
              }
              style={{ width: "100px" }}
              onClick={props.generalDocumentFilter}
            >
              General
            </button>
            <button
              className={
                props.narfoButton === true
                  ? clickedButton
                  : unclickedButton
              }
              style={{ width: "100px", marginLeft: "20px" }}
              onClick={props.narfoDocumentFilter}
            >
              Narfo
            </button>
            <button
              className={
                props.dsButton === true
                  ? clickedButton
                  : unclickedButton
              }
              style={{ width: "140px", marginLeft: "20px" }}
              onClick={props.dsDocumentFilter}
            >
              DedicatedStatus
            </button>
          </div>
        </div>
        <div className="filterButtons">
          <button
            className={
              props.memberButton === true ? clickedButton : unclickedButton
            }
            style={{ width: "100px", marginRight: "20px" }}
            onClick={props.memberDocumentFilter}
          >
            Member
          </button>
          <button
            className={
              props.huntingButton === true ? clickedButton : unclickedButton
            }
            style={{ width: "100px" }}
            onClick={props.huntingDocumentFilter}
          >
            Hunting
          </button>
          <button
            className={
              props.targetButton === true ? clickedButton : unclickedButton
            }
            style={{ width: "140px", marginLeft: "20px" }}
            onClick={props.targetDocumentFilter}
          >
            SportShooting
          </button>
        </div>
        <div className="filterButtons mt-4">
          <button
            className={
              props.resetAllButton === true ? clickedMainButton : unclickedMainButton
            }
            style={{ width: "180px" }}
            onClick={props.resertAll}
          >
            Reset All
          </button>
          <button
            className={
              props.filterButton === true ? clickedMainButton : unclickedMainButton
            }
            style={{ width: "180px", marginLeft: "20px" }}
            onClick={props.applyFilter}
          >
            Apply Filter
          </button>
        </div>
      </div>
      <hr
        style={{
          boxShadow: "0px 2px 4px orange",
          borderBottom: "2px solid orange",
          margin: "0 auto",
          marginBottom: "10px",
          marginTop: "10px"
        }}
      />
    </div>
  );
}
export default FilterButtons;
