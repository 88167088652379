import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../../API/APIendpoint";
import GenericHeader from "../../../utils/GenericHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactExport from "react-export-excel";
import { YearPicker } from 'react-dropdown-date';
import FadeLoader from "react-spinners/FadeLoader";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

toast.configure();
class DSNonComplianceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nonCompliancelist: [],
      nonCompliancelistPrior: [],
      priorYearList: false,
      selectedYear: "",
      isYearlyRecordsLoaded: false,
      loading: false
    };
  }

  getCurrenYearList = () => {
    this.setState({ loading: true });
    Axios({
      url: `${BASE}api/DedicatedStatus/DHYearlyReport`,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      this.setState({isYearlyRecordsLoaded : true});
      this.setState({ loading: false });
      res.data.forEach(element => {
        this.state.nonCompliancelist.push({
          name: element.firstName,
          surname: element.surname,
          idNo: element.idNo,
          dstype: element.dsType,
          remarks: element.remarks
        })
      });
    })
  }

  getPriorYearNonComplianceList = _date => {
    Axios({
      url: `${BASE}api/DedicatedStatus/GetNonComplianceListByDate/${_date}`,
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      if(res.data.recordsFound){
        this.setState({
          nonCompliancelistPrior: res.data.dsNonCompliances
        })
        this.setState({priorYearList: true});
        toast.success(res.data.message)
      } else {
        toast.warning(res.data.message);
      }
    })
    setInterval(() => {
      this.setState({
        priorYearList: false,
        nonCompliancelistPrior: []
      });
    }, 8000);
  }
  
  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "80px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading files. Please wait ...</p>
          </div>
        </div>
      );
    }
    return (
        <div className="container" style={{ marginTop: "60px" }}>
          <GenericHeader 
            title="Dedicate Status Non-Compliance List"
          />
          <div className="container"  style={{ paddingLeft: "16px" }}>
              <button onClick ={ () => this.getCurrenYearList()} >Get Annual List</button>
          </div>
          { this.state.isYearlyRecordsLoaded ? 
          <table className="mt-1 ml-3" style={{ width: "100%" }}>
          <tbody>
            <tr >
              <td style={{ width: "10%" }}><div>Current Year Non Compliance List</div></td>
              <td style={{ width: "10%" }}>{this.state.priorYearList?<div>{`${this.state.selectedYear} Non Compliance List`}</div>:<div>Non Compliance History (Choose year)</div>}</td>
            </tr>
            <tr>
              <td style={{ width: "4%" }}>
                <ExcelFile>
                  <ExcelSheet data={this.state.nonCompliancelist} name="DS Non-Compliance List">
                    <ExcelColumn label="Name" value="name"/>
                    <ExcelColumn label="Surname" value="surname"/>
                    <ExcelColumn label="ID Number" value="idNo"/>
                    <ExcelColumn label="Type Of Competency" value=""/>
                    <ExcelColumn label="Type Of Authorisation" value="dstype"/>
                    <ExcelColumn label="Type Of Disciplinary Transgression" value=""/>
                    <ExcelColumn label="Result Of Disciplinary Transgression" value=""/>
                    <ExcelColumn label="Remarks" value="remarks"/>
                  </ExcelSheet>
                </ExcelFile>
              </td>
              <td style={{ width: "13%" }}>
                {this.state.priorYearList ? 
                  <ExcelFile>
                    <ExcelSheet data={this.state.nonCompliancelistPrior} name="DS Non-Compliance List">
                      <ExcelColumn label="Name" value="name"/>
                      <ExcelColumn label="Surname" value="surname"/>
                      <ExcelColumn label="ID Number" value="idNo"/>
                      <ExcelColumn label="Type Of Competency" value=""/>
                      <ExcelColumn label="Type Of Authorisation" value="dstype"/>
                      <ExcelColumn label="Type Of Disciplinary Transgression" value=""/>
                      <ExcelColumn label="Result Of Disciplinary Transgression" value=""/>
                      <ExcelColumn label="Remarks" value="remarks"/>
                    </ExcelSheet>
                  </ExcelFile> :
                  <YearPicker
                    defaultValue={'select year'}
                    reverse
                    onChange={year => {this.getPriorYearNonComplianceList(year);
                                        this.setState({selectedYear: year});
                                      }}
                  />}
              </td>
            </tr>
          </tbody>
      </table> :
       null
      } 
        </div>
    );
  }
}
export default DSNonComplianceList;
