import React, { Component } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Forward from "@material-ui/icons/Forward";
import { COURSE_MATERIAL } from "../LMSAPIEndPoints";

export default class BulkMoveToFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseMaterialId: 0,
      courseModuleId: 0,
      isMoving: false,
      moveModuleMessage: ''
    }

    this.count = 0;
  }

  moveToFolder = (id, name) => {
    const data = {
      courseMaterialId: id,
      courseModuleId: this.state.courseModuleId
    }
    this.setState({
      moveModuleMessage: "moving " + name + "...",
      isMoving: true
    })
    fetch(COURSE_MATERIAL + '/moveToModule/' + data.courseMaterialId + '/' + data.courseModuleId,
      {
        method: 'POST'
      })
      .then(res => res.json())
      .then(res => {
        this.setState({
          moveModuleMessage: res.message
        })
        this.count += 1;
        setTimeout(() => {
          if (this.count === this.props.idArray.length) {
            this.props.refresh();
            this.props.onClick(0);
          }
        }, 1500);
      })
      .catch(error => {
        this.setState({
          moveModuleMessage: error.message ? error.message : 'Failed! Try Again'
        })
        this.count += 1;
        setTimeout(() => {
          if (this.count === this.props.idArray.length) {
            this.props.onClick(0);
          }
        }, 1500);
      })
  }

  moveAll = () => {
    this.props.idArray.forEach(elem => {
      this.moveToFolder(elem.id, elem.name);
    })
  }

  render() {
    return (
      <div className="popup-menu-container">
        <Forward className="nocursor" />
        {this.state.isMoving ?
          <label>{this.state.moveModuleMessage}</label> :
          <select name="folders"
            onChange={(event) => {
              this.setState({
                courseModuleId: event.target.value
              })
            }}>
            <option>Select Module</option>
            {(this.props.folders ? this.props.folders : []).map((folder) =>
              <option value={folder.courseModuleId}>{folder.name}</option>
            )}
          </select>
        }
        <span className={this.state.isMoving ? 'hide' : ''} >
          <Check onClick={() => {
            this.moveAll();
          }} />
          <Clear onClick={() => {
            this.props.onClick(5);
          }} />
        </span>
      </div>
    )
  }
}
