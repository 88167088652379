import {
  UPDATE_ASSESMENT_COMPLETION,
  UPDATE_COURSES,
  UPDATE_COURSE,
  UPDATE_IS_ADD_NEW_COURSE,
  UPDATE_QUIZZES,
  UPDATE_CURRENT_LMS_TAB,
  UPDATE_ASSESMENTS,
  UPDATE_ASSESMENT,
  UPDATE_LESSONS,
  UPDATE_LESSON,
  UPDATE_ADMIN_PAGE_ITERATOR,
  UPDATE_CURRENT_QUESTION,
  UPDATE_QUIZ,
  UPDATE_OPTIONS,
  UPDATE_QUESTION_TYPE,
  UPDATE_SELECTED_ANSWER,
  UPDATE_CURRENT_QUIZ_INDEX,
  UPDATE_SCORE,
  UPDATE_NEXT,
  UPDATE_PREVIOUS,
  UPDATE_SECTION_TITLE,
  UPDATE_RESULTS_RECORD,
  UPDATE_MEMBER_COURSES,
  UPDATE_QUIZ_COMPLETION,
  UPDATE_CURRENT_ASSESSMENT_TAB,
  UPDATE_CERTIFICATES,
  UPDATE_COURSE_MODULE_NAME,
  TRACK_CURRENT_MODULE_ID,
  UPDATE_PROGRESS_ARRAY
} from './actionTypes';

const INITIAL_STATE = {
  courses: [],
  lessons: [],
  lesson: {},
  assesments: [],
  assesment: {},
  course: {},
  quizzes: [],
  resultsRecord: [],
  isAddNewCourse: false,
  currLmsTab: 0,
  lmsAdminPageIterator: 0,
  currentQuestion: [],
  quiz: {},
  options: [],
  questionType: 'singleChoice',
  selectedAnswer: '',
  currentQuizIndex: 0,
  score: 0,
  next: 'Next',
  previous: 'Previous',
  sectionTitle: 'No title',
  memberCourses: [],
  quizCompletion: [],
  currAssessmentTab: 0,
  certificates: [],
  assesmentCompletion: {},
  courseModuleName: '',
  moduleId: '',
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_CURRENT_ASSESSMENT_TAB:
    return {
      ...state,
      currAssessmentTab: action.currAssessmentTab
    };
  case UPDATE_QUIZ_COMPLETION:
    return {
      ...state,
      quizCompletion: action.quizCompletion
    };
  case UPDATE_MEMBER_COURSES:
    return {
      ...state,
      memberCourses: action.memberCourses
    };
  case UPDATE_RESULTS_RECORD:
    return {
      ...state,
      resultsRecord: action.resultsRecord
    };
  case UPDATE_SECTION_TITLE:
    return {
      ...state,
      sectionTitle: action.sectionTitle
    };
  case UPDATE_ADMIN_PAGE_ITERATOR:
    return {
      ...state,
      lmsAdminPageIterator: action.lmsAdminPageIterator
    };
  case UPDATE_NEXT:
    return {
      ...state,
      next: action.next
    };
  case UPDATE_PREVIOUS:
    return {
      ...state,
      previous: action.previous
    };
  case UPDATE_CURRENT_QUESTION:
    return {
      ...state,
      currentQuestion: action.currentQuestion
    };

  case UPDATE_QUIZ:
    return {
      ...state,
      quiz: action.quiz
    };
  case UPDATE_OPTIONS:
    return {
      ...state,
      options: action.options
    };
  case UPDATE_QUESTION_TYPE:
    return {
      ...state,
      questionType: action.questionType
    };
  case UPDATE_SELECTED_ANSWER:
    return {
      ...state,
      selectedAnswer: action.selectedAnswer
    };
  case UPDATE_CURRENT_QUIZ_INDEX:
    return {
      ...state,
      currentQuizIndex: action.currentQuizIndex
    };
  case UPDATE_SCORE:
    return {
      ...state,
      score: action.score
    };
  case UPDATE_COURSE:
    return {
      ...state,
      course: action.course
    };
  case UPDATE_COURSES:
    return {
      ...state,
      courses: action.courses
    };
  case UPDATE_QUIZZES:
    return {
      ...state,
      quizzes: action.quizzes
    };
  case UPDATE_IS_ADD_NEW_COURSE:
    return {
      ...state,
      isAddNewCourse: action.isAddNewCourse
    };
  case UPDATE_CURRENT_LMS_TAB:
    return {
      ...state,
      currLmsTab: action.currLmsTab
    };
  case UPDATE_LESSONS:
    return {
      ...state,
      lessons: action.lessons
    };
  case UPDATE_LESSON:
    return {
      ...state,
      lesson: action.lesson
    };

  case UPDATE_ASSESMENTS:
    return {
      ...state,
      assesments: action.assesments
    };

  case UPDATE_ASSESMENT:
    return {
      ...state,
      assesment: action.assesment
    };

  case UPDATE_ASSESMENT_COMPLETION:
    return {
      ...state,
      assesmentCompletion: action.assesmentCompletion
    };

  case UPDATE_CERTIFICATES:
    return {
      ...state,
      certificates: action.certificates
    };

  case UPDATE_COURSE_MODULE_NAME:
    return {
      ...state,
      courseModuleName: action.courseModuleName
    };

  case TRACK_CURRENT_MODULE_ID:
    return {
      ...state,
      moduleId: action.moduleId
    };

  case UPDATE_PROGRESS_ARRAY:
    return {
      ...state,
      progressArr: action.progressArr
    };

		

  default:
    return state;
  }
};

export default reducer;
