import React, { Component } from "react";
import {
  BASE,
  GET_DOWNLOADS_URL,
} from "../../API/APIendpoint";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import axios from "axios";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

class test extends Component {
  componentDidMount() {
    axios.get(BASE + GET_DOWNLOADS_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      this.props.onDownloadsChange(res.data, "files");
    });
  }
  render() {
    return (
      <div>
        <GenericHeader
          title="Documents"
        />
        <ListItem divider>
          <ListItemText />
          <p>{this.props.downloadId}</p>
          <p> {this.props.id}</p>
          <p> {this.props.discr}</p>
        </ListItem>
      </div>
    );
  }
}
test.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    document: state.documentReducer.document,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDownloadsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_DOWNLOADS,
        varValue: value,
        varName: vname
      }),
    onViewPdfChange: payload =>
      dispatch({ type: actionTypes.UPDATE_DOWNLOADS, payload: payload })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(test);