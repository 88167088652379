import * as actionTypes from "../actions/actionTypes";

const initialState = {
  MemberView: {},
  getLoggedInMember: {},
  activityTypeFilter: "Activity Type",
  activityDateFilter: "2019-01-01",
  showFilterBar: false,
  Search: "",
  MemberList: [],
  ActivityList: [],
  activeStatus: {},
  currAccountViewTab: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_GET_LOGGED_IN_MEMBER:
      return {
        ...state,
        getLoggedInMember: action.getLoggedInMember
      };
    case actionTypes.UPDATE_ACTIVE_STATUS:
      return {
        ...state,
        activeStatus: action.activeStatus
      };
    case actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB:
      return {
        ...state,
        currAccountViewTab: action.currAccountViewTab
      };

    case actionTypes.UPDATE_MEMBER_VIEW:
      return {
        ...state,
        MemberView: action.MemberView
      };

    case actionTypes.UPDATE_MEMBER_VIEW_LIST:
      return {
        ...state,
        MemberList: action.payload
      };
    case actionTypes.TOGGLE_SHOW_FILTER_BAR:
      return {
        ...state,
        showFilterBar: !state.showFilterBar
      };

    case actionTypes.SEARCH:
      return {
        ...state,
        Search: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
