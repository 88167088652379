import { SET_ENDORSEMENTS } from './actionTypes';

const INITIAL_STATE = {
  endorsements: [],
};

export default (state = INITIAL_STATE, {type, payload }) => {
  switch(type) {
  
  case SET_ENDORSEMENTS:
    return {
      ...state,
      endorsements: payload
    };
  default:
    return state;
  }
};