import React, { Component } from "react";
import { connect } from "react-redux";
import * as constFile from "../../../store/constantFile/ConstFile";
import LicenseView from "./LicenseView";
import LicenseViewCustom from "./LicenseViewCustom";

class LicenseViewDetails extends Component {
  
  ActionSelect() { // to do -- update this file to point to Display certain bits 
    if (this.props.newlicenceDetails.Custom === constFile.CTRUE ) {
      return <LicenseViewCustom/>;
    } else if (this.props.newlicenceDetails.Custom === constFile.CFALSE) {
      return <LicenseView/>;
    }
  }
  render() {
    return (
      <div>
      {this.ActionSelect()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currActivityTab: state.membersAreaReducer.currentActivityTab,
    newlicenceDetails: state.licensesReducer.licence
  };
};

export default connect(
  mapStateToProps,
  null
)(LicenseViewDetails);
