import React from 'react';
import {
  CircularProgress,
  Container
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: '100px',
    paddingTop: '10px',
    textAlign: 'center',
  }
}));

const Loader = () => (
  <Container className={useStyles().root}>
    <CircularProgress color="primary" />
  </Container>
);

export default Loader;
