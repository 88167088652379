import React, { Component } from "react";

class Aims extends Component {
  render() {
    return (
      <div className="container text-center mt-2 pl-5 pr-5">
        <div>
          <p>
            In this section you can find additional details of NARFO, what our aims are, who our partners are,
            the code of conduct we need our members to sunscribe to, and our general contact form.
          </p>
        </div>
        <h3 align="center" className="font-weight-bold p-3 mb-0">
          Our Aims
        </h3>
        <div className="row bg-light">
          <p>
            The National Association of Responsible Firearm Owners (NARFO) is
            committed to work with its clients and members, authorities and
            government departments, other associations, anti-firearm community,
            national and international bodies to promote the use of legal firearms in
            a responsible manner for hunting, sport shooting and self-defence or any 
            other legal application.
            Our aim is to educate and create awareness on the application of
            firearms for the intended use.
          </p>
        </div>
        <div className="row">
          <p>
            The National Association of Responsible Firearm Owners (NARFO) has
            a partner program with the Wildman and Outdoor Group to afford members a
            broader range of benefits and a customer experience though the full
            value chain of owning a firearm. We also have selected partnership 
            programs with other service providers. See our parner page for more details.
            This includes access to training facilities for competencies, assistance with 
            your application process, enjoy the benefits of the My Bonus scheme and the
            discounts, opt in to the RifleSure insurance product or you can
            finance your dream firearm through Peerfin. 
          </p>
        </div>
        <div className="row bg-light">
          <p>
            NARFO is an Accredited Hunting and Sports Shooting Association in
            terms of the Firearms Control Act 60 of 2000 and can award it's members 
            dedicated hunters or sports persons status and we can endorse your motivations.
            We are a non politcal firearm owner association that strives to promote and protect the
            right of legal possession, safe and responsible use of firearms by
            any law abiding South African citizen for the purpose of
            self-protection, sport shooting, hunting or recreational use,
            conducting of business or being a collector or manufacturer.
          </p>
        </div>

        <div className="row">
          <p>
            The Executive team has been involved in the industry for the last 30
            years and with more than 50 consultants within the partner program and Wildman Group.
            You have access to experts and a wealth of knowledge to engage with
            you through the full value chain of owning a firearm. Contact Any
            partner or Wildman today and speak to a representative.
          </p>
        </div>
        <div className="row bg-light">
          <p>
            The NARFO also has international affiliation with the following
            Associations: NFA – The Canadian National Firearm Association NRA –
            National Rifle Association of America SCI – Safari Club
            International
          </p>
        </div>
        <p />
      </div>
    );
  }
}
export default Aims;
