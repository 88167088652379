import React from 'react';
import PropTypes from 'prop-types';
import {
  IconLabelButton,
  GenericTable,
  SubtitleBar
} from '../index';
import { Divider, Grid } from '@material-ui/core';
import { getStatusColor, label, formatISODateToLocaleString} from '../../shared/helpers';
import API_URL from '../../services/apiUrl';

const formatStatus = item => 
  <span className={getStatusColor(item)}>{item}</span>;

  const columns = [
    { field: 'firstname', headerName: 'Name'},
    { field: 'surname', headerName: 'Surname'},
    { field: 'score', headerName: 'Score'},
    { field: 'rank', headerName: 'Rank', format: (item) => `#${item}`},
  ];

function ViewActivityRanking(props) {
  const ref = React.createRef();
  const isMobile = localStorage.getItem('isMobile') === 'true';
  const ranks = props.myActivitiesRanking?.find(t => t.activityDescription == props.ranking).singleRankings;

  return (
    <React.Fragment>
      <SubtitleBar
        title={props.ranking + " rankings"}
        back
        onBack={props.backCallback}
      />
      {/* <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2"> */}
      <GenericTable 
            columns={columns}
            rows={ranks}
          />
      {/* </div> */}
    </React.Fragment>
  );
}

ViewActivityRanking.defaultProps = {
  backCallback: () => null,
  ranking: {},
};

ViewActivityRanking.propTypes = {
  backCallback: PropTypes.func.isRequired,
  ranking: PropTypes.object.isRequired,
};

export default ViewActivityRanking;

