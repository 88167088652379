export const UPDATE_ASSESMENT_COMPLETION = 'UPDATE_ASSESMENT_COMPLETION';
export const UPDATE_COURSES = 'UPDATE_COURSES';
export const UPDATE_COURSE = 'UPDATE_COURSE';
export const UPDATE_IS_ADD_NEW_COURSE = 'UPDATE_IS_ADD_NEW_COURSE';
export const UPDATE_QUIZZES = 'UPDATE_QUIZZES';
export const UPDATE_CURRENT_LMS_TAB = 'UPDATE_CURRENT_LMS_TAB';
export const UPDATE_ASSESMENTS = 'UPDATE_ASSESMENTS';
export const UPDATE_ASSESMENT = 'UPDATE_ASSESMENT';
export const UPDATE_LESSONS = 'UPDATE_LESSONS';
export const UPDATE_LESSON = 'UPDATE_LESSON';
export const UPDATE_ADMIN_PAGE_ITERATOR = 'UPDATE_ADMIN_PAGE_ITERATOR';
export const UPDATE_CURRENT_QUESTION = 'UPDATE_CURRENT_QUESTION';
export const UPDATE_QUIZ = 'UPDATE_QUIZ';
export const UPDATE_OPTIONS = 'UPDATE_OPTIONS';
export const UPDATE_QUESTION_TYPE = 'UPDATE_QUESTION_TYPE';
export const UPDATE_SELECTED_ANSWER = 'UPDATE_SELECTED_ANSWER';
export const UPDATE_CURRENT_QUIZ_INDEX = 'UPDATE_CURRENT_QUIZ_INDEX';
export const UPDATE_SCORE = 'UPDATE_SCORE';
export const UPDATE_NEXT = 'UPDATE_NEXT';
export const UPDATE_PREVIOUS = 'UPDATE_PREVIOUS';
export const UPDATE_SECTION_TITLE = 'UPDATE_SECTION_TITLE';
export const UPDATE_RESULTS_RECORD = 'UPDATE_RESULTS_RECORD';
export const UPDATE_MEMBER_COURSES = 'UPDATE_MEMBER_COURSES';
export const UPDATE_QUIZ_COMPLETION = 'UPDATE_QUIZ_COMPLETION';
export const UPDATE_CURRENT_ASSESSMENT_TAB = 'UPDATE_CURRENT_ASSESSMENT_TAB';
export const UPDATE_CERTIFICATES = 'UPDATE_CERTIFICATES';
export const UPDATE_COURSE_MODULE_NAME = 'UPDATE_COURSE_MODULE_NAME';
export const TRACK_CURRENT_MODULE_ID = 'TRACK_CURRENT_MODULE_ID';
export const UPDATE_PROGRESS_ARRAY = 'UPDATE_PROGRESS_ARRAY';