import * as EndPoints from "./APIendpoint";
import Axios from "axios";

export const getActivitiesAPI = async () => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.GET_ACTIVITY_TYPE_LIST,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig,);
}

export const getFirearmAPI = async () => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.GET_FIREARMTYPE_LIST,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig);
}

export const getOpticsAPI = async () => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.GET_OPTICS_LIST,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig, );
}

export const getAssociationsAPI = async () => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.GET_ACTIVITYDISC_LIST,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig);
}

export const getActivityEventsAPI = async () => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.ACTIVITY_EVENT_LIST,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig);
}

export const uploadActivityAPI = async (data) => {
    const requestConfig = {
        url: EndPoints.BASE + EndPoints.ACT_UPLOAD,
        method: 'post',
        data,
        headers: {
            'Content-type': 'multipart/form-data',
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
    }
    return await Axios(requestConfig);
}