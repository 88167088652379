import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Toolbar, IconButton } from "@material-ui/core";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon
} from "@material-ui/icons";
import { styles } from "./styles/styles";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { Link } from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import "./styles/headerIcons.css";
import { withRouter } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import editImage from "./icon-images/edit-icon.png";
import vid from "./icon-images/vid.jpg";
import doc from "./icon-images/doc.jpg";
import pic from "./icon-images/pic.jpg";
import bank from "./icon-images/bank.jpg";
import changePassword from "./icon-images/JPRS-banner-icon.png";

class GenericHeader extends Component {
  showBackButton = show => {
    return show ? (
      <IconButton
        onClick={() => this.props.backFunction()}
        style={{ width: "45px", height: "50px" }}
      >
        <ArrowBack />
      </IconButton>
    ) : null;
  };
  showAddButton = show => {
    return show ? (
      <IconButton onClick={() => this.props.addFunction()}>
        <AddIcon />
      </IconButton>
    ) : null;
  };
  showRecyleButton = show => {
    return show ? (
      <label
        onClick={() => this.props.recycle()}
        style={{
          cursor: "pointer",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        Recycle
      </label>
    ) : null;
  };
  showTotalLoyaltyDays = show => {
    return show ? (
      <Typography 
        variant="h6"
        style={{
          fontWeight: "bold",
        }}
      >
        {`Total Days: ${this.props.totalDays}`}
      </Typography>
    ) : null;
  };
  showMemberNumberDays = show => {
    return show ? (
      <Typography 
        variant="h6"
        style={{
          fontWeight: "bold",
        }}
      >
        {`Total: ${this.props.totalMembers}`}
      </Typography>
    ) : null;
  };
  showDeleteForeverButton = show => {
    return show ? (
      <i
        className="material-icons pl-2 pr-2"
        onClick={() => this.props.deleteForever()}
        style={{ cursor: "pointer" }}
      >
        delete_forever
      </i>
    ) : null;
  };
  showFilledAddButton = show => {
    return show ? (
      <Icon onClick={() => this.props.addFunction()}>
        <i className="material-icons" style={{ cursor: "pointer" }}>
          add_circle
        </i>
      </Icon>
    ) : null;
  };

  showAddButtonCircle = show => {
    return show ? (
      <IconButton>
        <Link to="/AddArticle">
          <AddIcon />
        </Link>
      </IconButton>
    ) : null;
  };

  showChangePassword = show => {
    return show ? (
      <img 
        src={changePassword}
        alt="C-P" 
        style={{ cursor: "pointer" }}
        onClick={() => this.props.changePasswordFunction()} 
      />
    ) : null;
  };

  showDeleteButton = show => {
    return show ? (
      <IconButton onClick={() => this.props.DeleteFunction()}>
        <DeleteForeverIcon />
      </IconButton>
    ) : null;
  };

  showFilterButton = show => {
    return show ? (
      <IconButton
        className="pl-2 pr-2"
        onClick={() => this.props.onFilterClick()}
      >
        <FilterListIcon />
      </IconButton>
    ) : null;
  };
  showDownloadButton = (show, text) => {
    return show ? (
      <div
        className="row btn btn-outline-dark font-weight-bold  mr-auto ml-auto"
        id="download-button"
        onClick={() => this.props.downloadFunction()}
        style={{ cursor: "pointer" }}
      >
        {text ? text : ""}
      </div>
    ) : null;
  };
  showDownloadButton2 = (show, text) => {
    return show ? (
      <div
        className="row btn btn-outline-dark font-weight-bold  mr-auto ml-4"
        id="download-button"
        onClick={() => this.props.downloadFunction2()}
        style={{ cursor: "pointer" }}
      >
        {text ? text : ""}
      </div>
    ) : null;
  };
  showFolderIcon = (show, text) => {
    return show ? (
      <img 
        className="view overlay zoom"
        src={doc} 
        alt="E-D"
        style={{ cursor: "pointer" }}
        onClick={() => this.props.uploadDocument()}
      />
    ) : null;
  };
  showBankIcon = (show, text) => {
    return show ? (
      <img 
        className="view overlay zoom"
        src={bank} 
        alt="E-D"
        style={{ cursor: "pointer", marginLeft: "10px", marginRight: "10px" }}
        onClick={() => this.props.viewBankDetails()}
      />
    ) : null;
  };
  showVideoIcon = (show, text) => {
    return show ? (
      <img 
        className="view overlay zoom"
        src={vid} 
        alt="E-D"
        style={{ cursor: "pointer" ,marginLeft: "10px",marginRight: "10px" }}
        onClick={() => this.props.uploadVideo()}
      />
    ) : null;
  };

  showSelectIcon = (show, text) => {
    return show ? (
      <label
        onClick={() => this.props.select()}
        style={{
          cursor: "pointer",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        Select
      </label>
    ) : null;
  };

  showTrashIcon = (show, text) => {
    return show ? (
      <label
        onClick={() => this.props.trashed()}
        style={{
          cursor: "pointer",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        Trash
      </label>
    ) : null;
  };

  showDeleteIcon = (show, text) => {
    return show ? (
      <label
        onClick={() => this.props.delete()}
        style={{
          cursor: "pointer",
          paddingTop: "10px",
          paddingLeft: "10px",
          paddingRight: "10px"
        }}
      >
        Delete
      </label>
    ) : null;
  };

  showUndo = (show, text) => {
    return show ? (
      <label
        className="p-3"
        onClick={() => this.props.undo()}
        style={{ cursor: "pointer" }}
      >
        Undo
      </label>
    ) : null;
  };

  showImageIcon = (show, text) => {
    return show ? (
      <img 
        className="view overlay zoom"
        src={pic} 
        alt="E-D"
        style={{ cursor: "pointer" }}
        onClick={() => this.props.uploadPicture()}
      />
    ) : null;
  };
  showSearchButton = show => {
    return show ? (
      <IconButton onClick={() => this.props.searchFunction()}>
        <SearchIcon />
      </IconButton>
    ) : null;
  };
  showUploadButton = show => {
    return show ? (
      <i
        className="material-icons pl-2 pr-2 pt-1"
        onClick={() => this.props.uploadFunction()}
        style={{ cursor: "pointer" }}
      >
        publish
      </i>
    ) : null;
  };
  showEditButton = show => {
    return show ? (
      <img 
        className="view overlay zoom"
        src={editImage} 
        alt="E-D"
        // style={{ cursor: "pointer" }}
        onClick={() => this.props.editFunction()} 
      />
    ) : null;
  };

  showEditMemberButton = show => {
    return show ? (
      <Icon onClick={() => this.props.editFunction()}>
      <i className="material-icons" style={{ cursor: "pointer" }}>
        edit
      </i>
    </Icon>

    ) : null;
  };

  showTimelineButton = show => {
    return show ? (
      <IconButton onClick={() => this.props.timelineFunction()}>
        <i className="material-icons">timeline</i>
      </IconButton>
    ) : null;
  };
  render() {
    const { classes } = this.props;
    return (
      <div className="col-12 p-0 pt-2">
        
        <Toolbar className={ this.props.hideLine ? "" : `${ !this.props.subHeader ? classes.root : ''} p-0`}>
          {this.showBackButton(this.props.showBackButton)}
          <Typography variant="h6" className={classes.title} id="header-title">
            {this.props.title}
          </Typography>
          <div className={classes.grow} />
          {this.showDownloadButton(
            this.props.showDownloadButton,
            this.props.text
          )}
          {this.showDownloadButton2(
            this.props.showDownloadButton2,
            this.props.text2
          )}
          {this.showTrashIcon(this.props.showTrashIcon)}
          {this.showFilterButton(this.props.showFilterButton)}
          {this.showAddButton(this.props.showAddButton)}
          {this.showAddButtonCircle(this.props.showAddButtonCircle)}
          {this.showFolderIcon(this.props.showFolderIcon)}
          {this.showVideoIcon(this.props.showVideoIcon)}
          {this.showUploadButton(this.props.showUploadButton)}
          {this.showSelectIcon(this.props.showSelectIcon)}
          {this.showUndo(this.props.showUndo)}
          {this.showDeleteIcon(this.props.showDeleteIcon)}
          {this.showImageIcon(this.props.showImageIcon)}
          {this.showTotalLoyaltyDays(this.props.showTotalLoyaltyDays)}
          {this.showMemberNumberDays(this.props.showMemberNumberDays)}
          {this.showChangePassword(this.props.showChangePassword)}
          {this.showBankIcon(this.props.showBankIcon)}
          {this.showSearchButton(this.props.showSearchButton)}
          {this.showEditButton(this.props.showEditButton)}
          {this.showTimelineButton(this.props.showTimelineButton)}
          {this.showEditMemberButton(this.props.showEditMemberButton)}
          {this.showDeleteButton(this.props.showDeleteButton)}
          {this.showRecyleButton(this.props.showRecyleButton)}
          {this.showDeleteForeverButton(this.props.showDeleteForeverButton)}
          {this.showFilledAddButton(this.props.showFilledAddButton)}
        </Toolbar>
        
        {!this.props.subHeader && <hr
          style={{
            boxShadow: "0px 2px 4px " + (this.props.headerColor ? this.props.headerColor : " orange"),
            borderBottom: "2px solid " + (this.props.headerColor ? this.props.headerColor : " orange"),
            margin: "0 auto",
            marginBottom: "10px"
          }}
        />}

      </div>
    );
  }
}

GenericHeader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(withRouter(GenericHeader));
