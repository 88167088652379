import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import jsPDF from "jspdf";
const styles = theme => ({});
class PaymentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: ""
    }
    this.receiptDownload = React.createRef();
  }
  componentDidMount() {
    this.getDate();
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
  };
  receiptDownloader = () => {
    this.receiptDownload.current.click();
  };
  getDate = () => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let today = new Date();
    let date =
      today.getDate() +
      " " +
      months[parseInt(today.getMonth())] +
      " " +
      today.getFullYear();
    this.setState({ date });
  };
  render() {
    const currAccount = this.props.accountList.filter(
      element => element.memNo === this.props.memberDetails.memberNumber &&
                 element.invoiceNumber === this.props.accountDetails.invoiceNo
    )[0]
    const payViewInfo = (name, value) => (
      <tr>
        <td>{name}</td>
        <td className="font-weight-bold">{`: ${value}`}</td>
      </tr>
    );
    const print = async () => {
      let pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(this.props.base64Images.nnfo, "JPEG", 32, 30, 40, 30);
      pdf.setTextColor(126, 126, 126);
      pdf.setFont("times");
      pdf.setFontSize(10);
      pdf.text(25, 65, "NARFO Pty (Ltd) 2012/086864/07");
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("times");
      pdf.setFontSize(14);
      pdf.setFontStyle("bold");
      pdf.text(122, 35, "NARFO");
      pdf.setFontSize(12);
      pdf.text(87, 43, "National Association for Responsible Firearm Owners");
      pdf.setFontStyle("normal");
      pdf.setFontSize(11);
      pdf.text(137, 60, "14 Phesante Close, Graanendal");
      pdf.text(137, 66, "Durbanville");
      pdf.text(137, 72, "7551");
      pdf.text(137, 80, "email: info@narfo.co.za");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontStyle("bold");
      pdf.setFontSize(20);
      pdf.text(89, 80, "Receipt");
      pdf.line(18, 84, 190, 84);
      pdf.setFontSize(12);
      pdf.text(18, 91, "Member No:");
      pdf.setFontStyle("normal");
      pdf.text(43, 91, `${this.props.memberDetails.memberNumber}`);
      pdf.setFontStyle("bold");
      pdf.text(150, 91, "Receipt No:");
      pdf.setFontStyle("normal");
      pdf.text(173, 91, `${this.props.accountDetails.recieptNo}`);
      pdf.line(18, 95, 190, 95);
      pdf.line(18, 96, 190, 96);
      pdf.setFontSize(11);
      pdf.text(22, 102, `${this.props.memberDetails.firstname.toUpperCase()} ${this.props.memberDetails.surname.toUpperCase()}`);
      pdf.text(22, 107, this.props.memberDetails.physicalAddress.toUpperCase());
      pdf.text(22, 112, this.props.memberDetails.city.toUpperCase());
      pdf.text(22, 117, this.props.memberDetails.province.toUpperCase());
      pdf.text(22, 122, this.props.memberDetails.postalCode.toUpperCase())
      pdf.setFontStyle("bold");
      pdf.text(22, 130, "Payment Date");
      pdf.text(50, 130, "Invoice No");
      pdf.text(80, 130, "Year");
      pdf.text(95, 130, "Payment Method");
      pdf.text(135, 130, "Transaction No");
      pdf.text(170, 130, "Amount");
      pdf.line(18, 133, 190, 133);
      pdf.setFontStyle("normal");
      pdf.text(22, 139, `${this.props.accountDetails.paymentDate.substring(0, 10)}`);
      pdf.text(50, 139, `${this.props.accountDetails.invoiceNo}`);
      pdf.text(80, 139, `${currAccount.year}`);
      pdf.text(95, 139, `${this.props.accountDetails.paymentMethod}`);
      pdf.text(135, 139, `${currAccount.transactionNo}`);
      pdf.text(170, 139, `R ${this.props.accountDetails.amountPaid}`);
      pdf.line(18, 143, 190, 143);
      pdf.setFontStyle("bold");
      pdf.text(140, 148, "Amount Paid");
      pdf.text(170, 148, `R ${this.props.accountDetails.amountPaid}`);
      pdf.text(22, 155, "Thank you for your payment");
      pdf.addImage(this.props.base64Images.pdflogo, "PNG", 145, 155, 40, 40);
      pdf.line(18, 270, 190, 270);
      pdf.save("NARFO Fee Receipt.pdf");
    }
    return (
      <div className="container">
        <div>
          <GenericHeader
            showBackButton
            backFunction={() => {
              this.props.onAddAccountDetailsChange(0, "currentAccountTab");
            }}
            title={this.props.accountDetails.paymentType + " Reciept"}
            showDownloadButton
            text="Receipt"
            downloadFunction={this.receiptDownloader}
          />
          <button
            onClick={print}
            ref={this.receiptDownload}
            style={{ display: "none" }}
          >
            down
          </button>
        </div>
        <div className="container">
          <div className="d-flex justify-content-center">
            <table style={{width:"100%"}} className="text-left">
              <tbody>
                {payViewInfo("Reciept No", this.props.accountDetails.recieptNo)}
                {payViewInfo("Invoice No", this.props.accountDetails.invoiceNo)}
                {payViewInfo(
                  "Payment Date",
                  this.props.accountDetails.paymentDate === null
                    ? "-"
                    : this.props.accountDetails.paymentDate.substring(0, 10)
                )}
                {payViewInfo("Year", currAccount.year)}
                {payViewInfo("Payment Method", this.props.accountDetails.paymentMethod)}
                {payViewInfo("Amount Paid", `R ${this.props.accountDetails.amountPaid}`)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

PaymentView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    accountDetails: state.accountReducer.account,
    memberDetails: state.loginReducer.memberObject,
    accountList: state.accountReducer.accountList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentView));
