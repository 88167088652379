import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  MyActivities,
  TabGroup,
  AddActivity,
  ActivityRanking,
  ActivityMatrix,
  FireArmTypes
} from '../../components';
import Container from '../../hoc/Container';
import ActivityActions from '../../store/Activities/actions';
import LookupActions from '../../store/Lookup/actions';
import ActivityService from '../../services/activities.service';
import LookupService from '../../services/lookup.service';
import MemberService from '../../services/member.service';
import DedicatedService from '../../services/dedicatedstatus.service';
import { printActivities } from '../../shared/pdfHelpers';
import { MEMBER_STATUS } from '../../shared/constants';
import { getMemberStatusFromInt } from '../../shared/helpers';
import SITE_URL from '../../services/siteURL';
import data from './countries.json';

export class Activities extends Component {
  constructor(props) {
    super(props);
    this.activityService = ActivityService(this.props.token);
    this.lookupService = LookupService(this.props.token);
    this.memberService = MemberService(this.props.token);
    this.dedicatedService = DedicatedService(this.props.token);
    this.activityActions = this.props.activityActions(this.activityService);
    this.lookupActions = this.props.lookupActions(this.lookupService);

    this.state = {
      loading: false,
      addActivity: false,
      countries : [],
      provinces : [],
      spiciesSorted : this.props.specieType.sort((a, b) =>
        a.specie > b.specie ? 1 : b.specie > a.specie ? -1 : 0
      )
    };
  }

  componentDidMount() {
    let _countries = data.countries.map(item => ({
      value: item.country,
      label: item.country,
      states: item.states
    }));

    this.setState({loading:true, countries: _countries});
    this.activityActions.fetchActivities(this.props.memNo)
      .finally(() => this.setState({loading:false}));
    this.lookupActions.fetchEventLookups();
    this.lookupActions.fetchDisciplines();
    this.lookupActions.fetchFirearmType();
    this.lookupActions.fetchFirearmTypeLookup();
    this.lookupActions.fetchOpticsLookup();
    this.lookupActions.fetchActivityType();
    this.lookupActions.fetchSpecieType();
    this.activityActions.fetchMatrix(this.props.memNo);
    this.activityActions.fetchRanking(this.props.memNo);

  }

  downloadActivities = (activities,identifier) => this.dedicatedService
    .getApprovedDedicatedStatus(this.props.memNo)
    .then((res) => {
      let dsHunter = res.data
        .find(ds => ds.dstype.toLowerCase().includes('hunter') &&
          ds.status === MEMBER_STATUS.APPROVED) || {};
      let dsSports = res.data
        .find(ds => ds.dstype.toLowerCase().includes('sport') &&
          ds.status === MEMBER_STATUS.APPROVED) || {};
      return this.memberService.getAllImages()
        .then((images) => printActivities(
          activities,
          dsHunter.dsno,
          dsSports.dsno,
          identifier,
          images.data.narfo,
          { 
            ...this.props.memberDetails,
            status: getMemberStatusFromInt(this.props.memberDetails.status)
          },
        ));
    });

  submitActivity = (activityData) => {
    const formData = new FormData();
    formData.append('ActivityType', activityData.activityType);
    formData.append('MemNo', this.props.memNo);
    formData.append('ActivityDate', activityData.activityDate.toISOString());
    formData.append('FirearmType', activityData.firearmType);
    formData.append('FireArmTypeLookups', activityData.firearmTypeLookup);
    formData.append('Caliber', activityData.caliber);
    formData.append('Score', activityData.score.toString());
    formData.append('Optics', activityData.optics);
    formData.append('ActivityEvent', activityData.activityEvent);
    formData.append('Association', activityData.association);
    formData.append('BaseURL', SITE_URL );
    formData.append('ActivityDescription', activityData.activityDescription);
    formData.append('ActivityNotes', activityData.activityNotes);

    formData.append('Country', activityData.country);
    formData.append('Province', activityData.province);
    formData.append('FarmName', activityData.farmName);
    formData.append('Specie', activityData.specie);
    formData.append('AddToGallery', activityData.addToGallery);


    activityData.attachments.forEach((file, i) => {
      formData.append(`attachment${i + 1}`, file.result);
    });

    this.activityService.submitActivity(formData)
      .then(() => this.activityActions.fetchActivities(this.props.memNo));
  };

  onViewMyActivities = () => this.setState({addActivity: false});

  tabViews = (isMobile)  => [
    {
      label: isMobile ? 'Activities' : 'My Activities',
      view: this.state.addActivity && <AddActivity
        activityEvent={this.props.eventLookup.map(e => ({
          value: e.activityEvent,
          label: e.activityEvent,
          ranking: e.ranking,
          fireArmTypeLookupID : e.fireArmTypeLookupID,
        }))}
        firearmTypes={this.props.firearmTypes.map(f => ({
          value: f.fireArmType1,
          label: f.fireArmType1,
          fireArmTypeId : f.fireArmID,
        }))}
        firearmTypeLookup={this.props.firearmTypeLookup.map(f => ({
          value: f.fireArmType,
          label: f.fireArmType,
          fireArmID :f.fireArmID,
          fireArmTypeId:f.fireArmTypeId,
          
        }))}
        activityDisciplines={this.props.disciplineTypes.map(d => ({
          value: d.discipline,
          label: d.discipline,
        }))}
        opticsLookup={this.props.opticsLookup.map(o => ({
          value: o.optics,
          label: o.optics,
        }))}
        activityTypeLookup={this.props.activityType.map(o => ({
          value: o.activity,
          label: o.activity,
          activityLookupId:o.activityLookupId
        }))}

        countryLookup={this.state.countries}

        specieLookup={this.state.spiciesSorted.map(s => ({
          value: s.specie,
          label: s.specie,
          specieLookupId: s.specieLookupId
        }))}

        submitActivity={(activity) => this.submitActivity(activity)}
        backCallback={this.onViewMyActivities}
      /> ||
      <MyActivities
        activities={this.props.activities}
        downloadActivityCallback={this.downloadActivities}
        addActivityCallback={() => this.setState({addActivity: true})}
        getActivityCallback={this.activityService.getActivityByID}
        disciplineFilterItems={{
          eventTypes: this.props.eventLookup.map(e => ({
            value: e.activityEvent,
            label: e.activityEvent,
          })),
          disciplineTypes: this.props.disciplineTypes.map(d => ({
            value: d.discipline,
            label: d.discipline,
          })),
        }}
      />
    },
    {
      label: isMobile ? 'Ranking' : 'Activity Ranking',
      view: <ActivityRanking activityRanking = {this.props.activityRanking} 
      activityEvent = {this.props.eventLookup.map(e => ({
        value: e.activityEvent,
        label: e.activityEvent,
        ranking: e.ranking,
        association: "Narfo"
      }))}

      activityDiscipline = {this.props.disciplineTypes.map(e => ({
        value: e.discipline,
        label: e.discipline,
        association: 'Other'
      }))}

      token = {this.props.token}
      memNo={this.props.memNo} 
      />
    },
    {
      label: isMobile ? 'Matrix' : 'Activity Matrix',
      view: <ActivityMatrix activityMatrix={this.props.activityMatrix}/>
    },
    {
      label: isMobile ? 'Target' : <div>Target Download</div>,
      view: <FireArmTypes firearmTypesList={this.props.firearmTypes}/>
    },
  ];

  
  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <Container title="Activities">
        <TabGroup items={this.tabViews(isMobile)} loading={this.state.loading}/>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
	token: state.AuthReducer.token,
	memNo: state.AuthReducer.memNo,
	activities: state.nActivitiesReducer.activities,
	eventLookup: state.nLookupReduder.eventLookup,
	firearmTypes: state.nLookupReduder.firearmTypes,
	firearmTypeLookup: state.nLookupReduder.firearmTypeLookup,
	disciplineTypes: state.nLookupReduder.disciplines,
	opticsLookup: state.nLookupReduder.opticsLookup,
	activityType: state.nLookupReduder.activityType,
	specieType: state.nLookupReduder.specieType,
	memberDetails: state.ProfileReducer.memberData,
	activityMatrix: state.nActivitiesReducer.activityMatrix,
	activityRanking: state.nActivitiesReducer.activityRanking
});

const initActivityActions = dispatch => activityService => {
  const actions = ActivityActions(activityService);
  return {
    fetchActivities: (id) => dispatch(actions.fetchActivities(id)),
    fetchMatrix: (id) => dispatch(actions.fetchMatrix(id)),
    fetchRanking: (id) => dispatch(actions.fetchRanking(id)),
  };
};

const initLookupActions = dispatch => lookupService => {
  const actions = LookupActions(lookupService);
  return {
    fetchEventLookups: () => dispatch(actions.fetchEventLookups()),
    fetchDisciplines: () => dispatch(actions.fetchDisciplines()),
    fetchFirearmType: () => dispatch(actions.fetchFirearmType()),
    fetchFirearmTypeLookup: () => dispatch(actions.fetchFirearmTypeLookup()),
    fetchOpticsLookup: () => dispatch(actions.fetchOpticsLookup()),
    fetchActivityType: () => dispatch(actions.fetchActivityType()),
    fetchSpecieType: () => dispatch(actions.fetchSpecieType()),
    
  };
};


const mapDispatchToProps = dispatch => ({
  activityActions: initActivityActions(dispatch),
  lookupActions: initLookupActions(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Activities);
