import React, { PureComponent } from "react";
import { QuestionTypes } from "./utils";

export default class QuestionOption extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: this.props.description,
      score: this.props.score,
      index: this.props.index,
      options: this.props.options,
      numberOfOptions: this.props.numberOfOptions
    };
  }

  componentDidUpdate() {
    this.setState({
      description: this.props.description,
      score: this.props.score,
      index: this.props.index,
      options: this.props.options,
      numberOfOptions: this.props.numberOfOptions
    });
  }

  render() {
    return this.getOption(this.props.questionType);
  }

  getOption = questionType => {
    switch (questionType) {
      case QuestionTypes.SINGLE_CHOICE:
        return this.radioOption();
      case QuestionTypes.MULTIPLE_CHOICE:
        return this.checkOption();
      case QuestionTypes.MATCH_SORT_NUMBERS:
        return this.numbersSortOption();
      case QuestionTypes.MATCH_SORT_LETTERS:
        return this.lettersSortOption();
      default:
        return null;
    }
  };

  radioOption = () => {
    return (
      <div className="d-flex w-100 mb-1 option">
        <input
          className="mr-2"
          key={this.state.index}
          name={"single"}
          type={"radio"}
          defaultChecked={this.state.score > 0}
          onChange={event => {
            if (this.props.radioFunction) {
              this.props.radioFunction(event, this.state.index);
            }
          }}
        />
        {this.state.description}
      </div>
    );
  };

  checkOption = () => {
    return (
      <div className="d-flex option w-100 mb-1">
        <input
          className="mr-1 d-inline-block"
          key={this.state.index}
          type={"checkbox"}
          value={this.state.score}
          checked={this.state.score > 0}
          onChange={event => {
            this.props.checkFunction(event, this.state.index);
          }}
        />
        {this.props.description}
      </div>
    );
  };

  numbersSortOption = () => {
    return this.sortOptions();
  };

  lettersSortOption = () => {
    return this.sortOptions(64, false);
  };

  sortOptions = (offset = 0, isNumbers = true) => {
    const choices = Array.from(
      new Array(this.state.numberOfOptions + 1),
      (v, k) => k + offset
    );
    return (
      <div className="d-flex option w-100 mb-1">
        <select
          className="mr-2 w-3"
          value={this.state.score}
          onChange={event => {
            if (this.props.numbersSortOptionFunction) {
              this.props.numbersSortOptionFunction(event, this.state.index);
            }
          }}
        >
          {choices.map(c => (
            <option value={c}>{isNumbers?c:String.fromCharCode(c)}</option>
          ))}
        </select>
        {this.state.description}
      </div>
    );
  }
}
