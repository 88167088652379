import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
  Typography,
  Grid 
} from '@material-ui/core';
import LicenceService from '../../services/licence.service';
import LicenceActions from '../../store/Licence/actions';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import {
  editableTextUnvalidatedInputConfig,
} from '../../shared/constants';
import {
  EditableGridLabel,
  IconLabelButton,
  Loader
} from '../index';
import { checkValidity, updateObject } from '../../shared/utility';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) =>({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  backButton: {
    margin: theme.spacing(2),
  },
  nextButton: {
    margin: theme.spacing(2),
  },
  stepperRoot: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  stepperLabel: {
    '& .MuiStepLabel-alternativeLabel':{
      fontSize: theme.typography.nav.fontSize,
      marginTop: '0',
    }
  },
  formHeading: {
    ...theme.typography.h6,
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    borderBottom: '1px solid',
    marginTop: theme.spacing(4)
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  errorPadding: {
    padding: theme.spacing(4)
  },
  submitErrorPadding: {
    padding: theme.spacing(4)
  },
  mobileMargin: {
    marginTop: '25px',
    [theme.breakpoints.up('md')]: {
      marginTop: 0
    }
  }
});

class AddLicenseApplication extends Component {
  constructor(props) {
    super(props);
    this.licenceService = LicenceService(this.props.token);
    this.licenceActions = this.props.licenceActions(this.licenceService);
    this.state = {
      showError:false,
      errorMessage:"",
      controls: this.initControls(),
      _memNo: this.props.memNo,
      licenseApplicationRecord: {
        referenceNumber:'',
        serialNumber:'',
        idorInstNumber:''
      },
      loading: false,
      formHasErrors: true,
      isDisabled:false
    };
  }

  initControls = () => ({
      referenceNumber: {...editableTextUnvalidatedInputConfig,},
      serialNumber: {...editableTextUnvalidatedInputConfig},
      idorInstNumber: {...editableTextUnvalidatedInputConfig},
  })

  handleRadioChange = event => {
    const value = event.target.value;
    this.setState({ 
      selectedRadio: value,
      controls: this.initControls()
    });
  }

  handleLabelChange = controlName => event => {
    let value = event;
    if(event?.target) {
      value = event.target.value;
    }

    let tempApp = {...this.state.licenseApplicationRecord,
      [controlName]: value,
    }

    this.isValidForm(tempApp);

    const validity = checkValidity(value === '-' ? '' : value, this.state.controls[controlName].config.validate);
    const updatedControls = updateObject(this.state.controls[controlName], {
      config: updateObject(this.state.controls[controlName].config, {
        valid: validity.isValid,
        errors: validity.errors,
        touched: true,
      })
    });
    let formHasErrors = false;
    const controls = {
      ...this.state.controls,
      [controlName]: updatedControls,
    };

    this.setState({
      controls,
      licenseApplicationRecord: {
        ...this.state.licenseApplicationRecord,
        [controlName]: value,
      },
      formHasErrors
    });
  };

  label = (title, name, fullWidth) => (
    <Grid item xs={12} md={fullWidth ? 12 : 6} lg={fullWidth ? 12 : 4}>
      <EditableGridLabel
        stretch
        title={title}
        value={this.state.licenseApplicationRecord[name]}
        options={this.state.controls[name]}
        edit={true}
        onChange={this.handleLabelChange(name)} 
      />
    </Grid>
  )

  handleSubmit = async () => {
    this.setState({ loading: true });
    let memNo = this.props.memNo; 

    const licenseApplicationRecord = {memNumber:memNo,...this.state.licenseApplicationRecord};

    await this.props.addLicenseApplicationCallback(licenseApplicationRecord)
      .then((res) => {
        this.licenceActions.fetchMemberLicenseApplications(memNo)
        this.setState({errorMessage:""});
        this.props.backCallback(res.data);
      })
      .catch(error =>{
        this.setState({errorMessage:"Could not find existing application with SAPS, please contact your DFO where your application was submitted"});
        console.log("error adding new application", error)
      });
      this.setState({ loading: false });
  }

  isValidForm =(tempApp) => {
    let value = false;
    //Check all the combinations required 
    if (tempApp.referenceNumber.length > 2 && tempApp.idorInstNumber.length > 2)
      value = true;
    else if (tempApp.serialNumber.length > 2 && tempApp.idorInstNumber.length > 2)
      value = true;
    else if (tempApp.serialNumber.length > 2 && tempApp.referenceNumber.length > 2)
     value = true;
    else
      value = false;
    
      this.setState({isDisabled:value});
  }

  
  handleCloseErrorDialog = () => {
    this.setState({openErrorDialog:false,errorMessage:''});
  };
  render() {
    const {classes} = this.props;
    const submitError = (
      <Typography
        variant="h6"
        color="error"
        className={classes.submitErrorPadding}
      >
        {this.state.errorMessage}
      </Typography>
    );

    return (
      <React.Fragment>
        <SubtitleBar 
          variant="subtitle1"
          title="Firearm application details"
          back
          onBack={this.props.backCallback}
        />

        <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
          <Grid container spacing={4}>
            <Grid item xs={12} container spacing={4}>
              {this.label('ID Number or Institution  Number', 'idorInstNumber')}

              {this.label('Reference Number', 'referenceNumber')}

              {this.label('Serial Number', 'serialNumber')}
            </Grid>
          </Grid>
        </div>
        {submitError}
        <div className="mt-4">
          {!this.state.loading && <IconLabelButton 
            full 
            disabled={!this.state.isDisabled}
            actionText="Submit" 
            color="primary" 
            callBack={this.handleSubmit}
          />}
          {this.state.loading && <Loader />}

        </div>
      </React.Fragment>
    );
  }
}

const initialiseLicenceActions = dispatch => (licenceService) => {
  const action = LicenceActions(licenceService);
  return {
    fetchMemberLicenseApplications: (id) => dispatch(action.fetchMemberLicenseApplications(id))
  };
};

const mapStateToProps = state => ({
  firearmLicenseApplications: state.LicenceReducer.firearmLicenseApplications,
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo
});

const mapDispatchToProps = dispatch => ({
  licenceActions: initialiseLicenceActions(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddLicenseApplication));
