import React, { Component } from 'react';
import { connect } from 'react-redux';
import ViewSubscriptions from '../../components/Subscriptions/ViewSubscriptions';
import Container from '../../hoc/Container';
import SubscriptionService from '../../services/subscription.service';

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.SubscriptionService = SubscriptionService(this.props.token);
  	this.state = {
    loading : true,
		subscriptionList: [],
    providers:[]}
  }

  componentDidMount() {

    this.SubscriptionService.GetllSubscription().then(resp =>{
      //Set type 
      const providers = [...new Set(resp.data.subscriptions.map(item => item.type))];
      this.setState({subscriptionList : resp.data.subscriptions.reverse(), providers: providers, loading : false});
    })
    .catch(ex =>{
      console.log(ex);
      this.setState({loading : false});
    })
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return(
      <Container title="Subscriptions">
       {!this.state.loading && <ViewSubscriptions 
          subscriptionList={this.state.subscriptionList}
          memNo={this.props.memNo}
          isMobile={isMobile}
          history = {this.props.history}
          providers = {this.state.providers}
        />  }
      </Container>
    );
  }
}

export default connect(null,null)(Subscriptions);