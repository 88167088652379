import {
  SET_ENDORSEMENTS
} from './actionTypes';

const fetchEndorsements = service => id => dispatch => service
  .getMemberEndorsements(id)
  .then((res) => dispatch({
    type: SET_ENDORSEMENTS,
    payload: res.data.map(endorsement => ({
      ...endorsement
    })),
  }));
  
export default function(endorsementService) {
  return {
    fetchEndorsements: fetchEndorsements(endorsementService)
  };
}