import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar,
  Overview,
  IconLabelButton
} from '../index';
import { Grid, Typography, Button, Icon, Link } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import Loader from '../Loader/Loader';
import ViewDedicatedStatusDetails from './ViewDedicatedStatusDetails';
import { UPDATE_DEDICATED_STATUS_RECORD } from '../../store/DedicatedStatus/types';
import DedicatedStatusApplications from './DedicatedStatusApplications';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const columns = [
  { field: 'applicationDate', headerName: 'Date' },
  { field: 'dstype', headerName: localStorage.getItem('isMobile') === 'true' ? 'Type' : 'Application Type' },
  { field: 'status', headerName: localStorage.getItem('isMobile') === 'true' ? 'Status' : 'Application Status' }
];

class MyDedicatedStatusDetails extends Component {
  constructor(props) {
    super(props);
    this.dedicatedStatusService = this.props.dedicatedStatusService;
    this.state = {
      loading: false,
      viewDStatus: false,
      dsApplications: false,
      _dedicatedType: '',
	  sportShooterCount: this.props.sportShooterCount,
	  hunterCount: this.props.hunterCount,
	  _dedicatedStatusList: this.props.dedicatedStatusList,
    rejectedApplications: this.props.dedicatedStatusList?.filter(ds => ds.status === "Declined")
    };
  }

  loadDSCallBack = () =>{
    this.dedicatedStatusService.getAllDeducatedStatuses({memNo: this.props.memNo})
        .then((resp) => {
            let props = this.getDerivedStateFromProps(resp.data.dedicated);
            this.setState({...this.state,_dedicatedStatusList: props._dedicatedStatusList,
                            sportShooterCount : props.sportShooterCount, hunterCount: props.hunterCount})
        });
  }
  
  reApplayCallBack = (data) =>{
    this.setState({dsApplications: true, _dedicatedType: data})
  }
  getDerivedStateFromProps = (dedicatedStatusList)=>{
    let __dedicatedStatusList = [];
    let __sportShooterCount = 0;
    let __hunterCount = 0;
    __dedicatedStatusList = dedicatedStatusList;
    if(__dedicatedStatusList.length !== 0) {
      __dedicatedStatusList.map((item) => {
        if(item.dstype === 'Dedicated Sports Person') {
          __sportShooterCount = __sportShooterCount + 1;
        }
        if(item.dstype === 'Dedicated Hunter') {
          __hunterCount = __hunterCount + 1;
        }
        item.applicationDate = new Date(item.applicationDate.split('T')[0]).toLocaleDateString();

        if(localStorage.getItem('isMobile') === 'true') {
          if(item.dstype === 'Dedicated Sports Person') {
            item.dstype = 'DSS';
          }
          if(item.dstype === 'Dedicated Hunter') {
            item.dstype = 'DH';
          }
        }
      });
    } 
        
    return { 
      _dedicatedStatusList: __dedicatedStatusList,
      sportShooterCount: __sportShooterCount,
      hunterCount: __hunterCount
    };
  }
  
  applicationRequiredCheck = (shooter, hunter) => (
    <div className="pl-8">
      <Typography variant="h6">
        {shooter === 0 && hunter === 0 ? 
          'You currently have no applications.' : 
          shooter === 0 && hunter > 0 ?
            'You currently do not have a sports shooter application' :
            shooter > 0 && hunter === 0 ?
              'You currently do not have a dedicated hunter application' :
              null}
      </Typography>
      <Typography>
        Apply for:
      </Typography>
      <Grid container direction="row" className="pt-8">
        {shooter === 0 ?
          <IconLabelButton 
            dsApplications
            actionText="Dedicated sports shooter"
            callBack={() => this.setState({
              dsApplications: true,
              _dedicatedType: 'Dedicated Sports Person'
            })}
            icon={<CheckCircleOutlineIcon />}
            textMobileAlt={true}
            titleAlt="DSS"
          /> : null}
        {shooter > 0 || hunter > 0 ?
          null : 
          <Button disabled={true}>
            or
          </Button>}
        {hunter === 0 ?
          <IconLabelButton 
            dsApplications
            actionText="Dedicated Hunter"
            callBack={() => this.setState({
              dsApplications: true,
              _dedicatedType: 'Dedicated Hunter'
            })}
            icon={<CheckCircleOutlineIcon />}
            textMobileAlt={true}
            titleAlt="DH"
          /> : null}
      </Grid>
    </div>
  )

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const assessmentTarget = 'https://portal.narfo.co.za//uploads/2020/05/NARFO%20Dedicated%20Assessment%20Target%20A4-Dedicated%20Status.pdf';

    const dsDocument = 'https://portal.narfo.co.za//uploads/2020/05/NARFO%20Dedicated%20Status%20Process%20(D)-Dedicated%20Status.pdf';

    return(
      this.state.loading && <Loader /> ||
        this.state.viewDStatus && !this.state.dsApplications && <ViewDedicatedStatusDetails 
          goBack={() => this.setState({ viewDStatus: false })}
          // reApplayCallBack={()=> this.setState({dsApplications: true})}
          reApplayCallBack={this.reApplayCallBack}
        /> ||
        this.state.dsApplications && <DedicatedStatusApplications 
          goBack={() => this.setState({ dsApplications: false })}
          loadDSCallBack={this.loadDSCallBack}
          dedicatedType={this.state._dedicatedType}
          addNewApplicationCallBack={this.props.addNewApplicationCallBack}
          memNo={this.props.memNo}
          history = {this.props.history}
          rejectedApplications = {this.state.rejectedApplications}
        /> ||
      <React.Fragment>
        <Overview>
          <Typography align="justify">
            Before applying for your dedicated status, please read through the NARFO Dedicated Status Process (D) and the NARFO Declaration of Dedicated Status (K) document from the downloads section in your member profile.
            You can only be awarded a dedicated status in two ways, either through Recognition of Prior Learning (RPL) or to follow the NARFO process of completing a practical and theoretical assessment.  
            Based on the sucessful completion and submission, your application will be considered and is subject to approval.
            Your application will not be considered if you merely want to own more firearms. Becoming a dedicated member means you actively want to participate in hunting or sportshooting and must submit proof of this to maintain your status. Failing to do so could result in the termination of your status.
          </Typography>
          <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2 mt-4">

          
          <Grid container spacing={3} style={{marginTop:'20px'}}>
            
            <Grid item sm={3} xs={12}>
              <Button href={assessmentTarget} target="_blank" variant="outlined" color="primary">
              Assessment Target
              </Button>
            </Grid>

            <Grid item sm={3} xs={12}>
              <Button href={dsDocument} target="_blank" variant="outlined" color="primary">
                Dedicated Document
              </Button>
              </Grid>
            </Grid>


            <SubtitleBar 
              variant="subtitle2"
              title={isMobile ? 'Status Apllication' : 'Dedicated Status Applications'}
            />

            {this.state.sportShooterCount > 0 && this.state.hunterCount > 0 ? null : 
              (this.applicationRequiredCheck(
                this.state.sportShooterCount,
                this.state.hunterCount
              ))}

            {this.state._dedicatedStatusList.length !== 0 ?
              <GenericTable 
                columns={columns}
                rows={this.state._dedicatedStatusList}
                view
                onViewCallBack={item => {
                  this.setState({ loading: true });
                  this.props.onDedicatedRecordUpdate(this.state._dedicatedStatusList.filter(element =>
                    element.dedicatedId === item.dedicatedId)[0]);
                  this.setState({ 
                    viewDStatus:true, 
                    loading: false 
                  });
                }}
              /> : null}
          </div>
        </Overview>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onDedicatedRecordUpdate: record => 
    dispatch({ 
      type: UPDATE_DEDICATED_STATUS_RECORD, 
      payload: record 
    })
});

export default connect(null, mapDispatchToProps)(MyDedicatedStatusDetails);