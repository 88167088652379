import axios from "axios";
import * as Types from "./storelocatorActionTypes";
import { BASE, GET_STORE_LIST } from "../../API/APIendpoint";

const getBranchesAPI = () => {
  const config = {
    url: BASE + GET_STORE_LIST,
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
    },
    method: "get"
  };
  return axios(config);
};

export const SL_GET_BRANCHES_START = "SL_GET_BRANCHES_START",
  SL_GET_BRANCHES_FAILED = "SL_GET_BRANCHES_FAILED",
  SL_GET_BRANCHES_SUCCESS = "SL_GET_BRANCHES_SUCCESS",
  SL_UPDATE_SEARCH_STRING = "SL_UPDATE_SEARCH_STRING",
  SL_UPDATE_SEARCH_URL = "SL_UPDATE_SEARCH_URL",
  SL_UPDATE_BRANCHES = "SL_UPDATE_BRANCHES";

export const getBranchesBeginAC = () => {
  return {
    type: Types.SL_GET_BRANCHES_START,
    storeLocatorState: {
      loading: true,
      error: null
    }
  };
};

export const getBranchesFailedAC = error => {
  return {
    type: Types.SL_GET_BRANCHES_FAILED,
    storeLocatorState: {
      loading: false,
      error
    }
  };
};

export const getBranchesSuccessAC = branches => {
  return {
    type: Types.SL_GET_BRANCHES_SUCCESS,
    storeLocatorState: {
      loading: false,
      error: null,
      branches
    }
  };
};

export const getBranches = () => async dispatch => {
  dispatch(getBranchesBeginAC());
  try {
    const response = await getBranchesAPI();
    dispatch(getBranchesSuccessAC(response.data));
  } catch (error) {
    dispatch(getBranchesFailedAC(error));
  }
};

export const updateSearchString = (url, city) => {
  return {
    type: Types.SL_UPDATE_SEARCH_STRING,
    storeLocatorState: {
      url,
      city
    }
  };
};
