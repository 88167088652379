import * as actionTypes from "../actions/actionTypes";

const initialState = {
  account: {
    paymentType: "",
    recieptNo: "",
    invoiceNo: "",
    paymentDate: "",
    year: "",
    paymentMethod: "",
    amountPaid: "",
    email: "",
    currentAccountTab: ""
  },
  PaymentObject: {},
  recentAccountEntry: {}, 
  accountList: [],
  memViewAccountDet: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ACCOUNT:
      const newAccount = {
        ...state.account,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        account: newAccount
      };

    case actionTypes.UPDATE_RECENT_ACCOUNT_ENTRY:
      return {
        ...state,
        recentAccountEntry: action.recentAccountEntry
      };
    case actionTypes.UPDATE_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload
      };
    case actionTypes.MEM_VIEW_ACCT_DET:
      return {
        ...state,
        memViewAccountDet: action.memViewAccountDet
      };
    case actionTypes.MEMBER_PAYMENT_ADD:
      return {
        ...state,
        PaymentObject: action.PaymentObject
      };
    default:
      return state;
  }
};

export default reducer;
