import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as actionTypes from "../../store/actions/actionTypes";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GenericHeader from "../../utils/GenericHeader";
import Axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE, NEWS_BY_ARTICLE_URL, ARTICLE_URL } from "../../API/APIendpoint";
import "w3-css/w3.css";

class AdminNewsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      editCheck: false,
      fileSelectorHeader: false,
      loading: false,
      selectedArticles: [],
      filter: false,
      undo: false,
      typeFilter: false,
      sortType: "",
      filterButton: false,
      latestAddedButton: false,
      oldestAddedButton: false,
      resetAllButton: false,
      applyFilter: false
    };
    this.toggleCheck = this.toggleCheck.bind(this);
    this.updateSelectedArticle = this.updateSelectedArticle.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.editCheck = this.editCheck.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    Axios.get(BASE + ARTICLE_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const article = res.data;
      this.props.onArticlesChange(article);
      this.setState({ loading: false, uploadedFile: article });
    });
  }
  toggleCheck = () => {
    this.setState({
      checked: !this.state.checked
    });
  };
  editCheck = () => {
    this.setState({
      editCheck: !this.state.editCheck
    });
  };
  updateSelectedArticle = event => {
    const id = parseInt(event.target.value);
    if (event.target.checked && !this.state.selectedArticles.includes(id)) {
      let newSelected = this.state.selectedArticles.concat([id]);
      this.setState({
        selectedArticles: newSelected
      });
    }
    if (!event.target.checked && this.state.selectedArticles.includes(id)) {
      let newSelected = this.state.selectedArticles.filter(
        item => parseInt(item) !== parseInt(id)
      );
      this.setState({
        selectedArticles: newSelected
      });
    }
  };
  handleDelete = () => {
    Axios.post(BASE + "api/NewsArticles/deleteNews", this.state.selectedArticles,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        Axios.get(BASE + ARTICLE_URL,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
          const article = res.data;
          this.props.onArticlesChange(article);
          this.setState({ undo: true, selectItems: false });
        });
      })
      .catch(function (error) { });
  };
  handleUndo = async () => {
    await Axios.post(
      BASE + "api/NewsArticles/undoDeleteNews",
      this.state.selectedArticles,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
    )
      .then(res => {
        Axios.get(BASE + ARTICLE_URL,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
          const article = res.data;
          this.props.onArticlesChange(article);
          this.setState({
            fileSelectorHeader: false,
            undo: false,
            selectItems: false
          });
        });
      })
      .catch();
  };
  onDescendingSort = () => {
    this.setState({
      latestAddedButton: true,
      oldestAddedButton: false,
      sortType: "descending"
    });
  };
  onAscendingSort = () => {
    this.setState({
      oldestAddedButton: true,
      latestAddedButton: false,
      sortType: "ascending"
    });
  };
  resetFilterType = () => {
    this.setState({
      oldestAddedButton: false,
      latestAddedButton: false,
      resetAllButton: false,
      filterButton: false
    });
  };
  applyFilter(e) {
    e.preventDefault();
    Axios.get(BASE + "api/NewsArticles/" + this.state.sortType,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => {
        this.props.onArticlesChange(res.data);
        this.setState({
          filter: false,
          fileSelectorHeader: false
        });
      })
      .catch(function (error) { });
    this.resetFilterType();
  }
  handleEdit = async () => {
    let selectedArticle = this.state.selectedArticles[0];
    let config = {
      method: "POST",
      url: BASE + NEWS_BY_ARTICLE_URL + selectedArticle,
      data: {
        title: this.props.oneArticle.title,
        content: this.props.oneArticle.content
      },
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
    };
    try {
      await Axios(config);
    } catch (e) { }
  };
  headerChange() {
    if (this.state.fileSelectorHeader === true) {
      if (this.state.undo === true) {
        return (
          <GenericHeader
            showBackButton
            title="News"
            backFunction={() => {
              this.setState({
                fileSelectorHeader: false,
                undo: false,
                selectItems: false
              });
            }}
            showUndo
            undo={this.handleUndo}
          />
        );
      }
      if (this.state.selectItems === true) {
        return (
          <GenericHeader
            title="News"
            showBackButton
            showDeleteIcon
            backFunction={() => {
              this.setState({
                fileSelectorHeader: false,
                selectItems: false,
                undo: false
              });
            }}
            delete={this.handleDelete}
          />
        );
      }
      if (this.state.filter === true) {
        return (
          <div>
            <GenericHeader
              title="News"
              showBackButton
              backFunction={() => {
                this.setState({ fileSelectorHeader: false });
              }}
              onFilterClick={() =>
                this.setState({ filter: true, fileSelectorHeader: true })
              }
              select={() =>
                this.setState({ selectItems: true, fileSelectorHeader: true })
              }
            />
            {this.filterButtons()}
          </div>
        );
      }
    } else {
      return (
        <GenericHeader
          title="News"
          showFilterButton
          showAddButtonCircle
          showSelectIcon
          onFilterClick={() =>
            this.setState({
              filter: true,
              fileSelectorHeader: true,
              selectItems: false
            })
          }
          select={() =>
            this.setState({ selectItems: true, fileSelectorHeader: true })
          }
        />
      );
    }
  }
  resertAll = () => {
    this.setState({ loading: true });
    Axios.get(BASE + ARTICLE_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const article = res.data;
      this.props.onArticlesChange(article);
      this.setState({
        loading: false,
        fileSelectorHeader: false,
        filter: false,
        resetAllButton: false,
        applyFilter: false,
        oldestAddedButton: false,
        latestAddedButton: false,
        filterButton: false
      });
    });
  };
  filterButtons() {
    let unclickedButton =
      "w3-button w3-white w3-hover-orange w3-block w3-border w3-border-black w3-round-large";
    let clickedButton =
      "w3-button w3-orange w3-hover-orange w3-block w3-border w3-border-orange w3-round-large";
    return (
      <div className="container">
        <div className="mb-2" style={{ marginLeft: "28%" }}>
          Sort By:
        </div>
        <div className="filterButtons">
          <button
            className={
              this.state.latestAddedButton === true
                ? clickedButton
                : unclickedButton
            }
            style={{ width: "190px" }}
            onClick={this.onDescendingSort}
          >
            Latest added
          </button>
          <button
            className={
              this.state.oldestAddedButton === true
                ? clickedButton
                : unclickedButton
            }
            style={{ width: "190px", marginLeft: "20px" }}
            onClick={this.onAscendingSort}
          >
            Oldest added
          </button>
        </div>
        <div className="filterButtons mt-4">
          <button
            className={
              this.state.resetAllButton === true
                ? clickedButton
                : unclickedButton
            }
            style={{ width: "190px" }}
            onClick={this.resertAll}
          >
            Reset All
          </button>
          <button
            className={
              this.state.filterButton === true ? clickedButton : unclickedButton
            }
            style={{ width: "190px", marginLeft: "20px" }}
            onClick={e => this.applyFilter(e)}
          >
            Apply Filter
          </button>
        </div>
        <hr
          style={{
            boxShadow: "0px 2px 4px orange",
            borderBottom: "2px solid orange",
            margin: "0 auto",
            marginBottom: "10px",
            marginTop: "10px"
          }}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-12 d-flex justify-content-center">
            <FadeLoader color="orange" margin="auto" />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <p className="font-weight-bold">Loading news. Please wait ...</p>
          </div>
        </div>
      );
    }
    return (
      <div className="container">
        {this.headerChange()}
        {this.props.articleList.map((article, index) => {
          return (
            <div className="border-bottom" key={index}>
              {this.state.selectItems === true ? (
                <label className="row checkLabel pl-3">
                  <input
                    value={article.newsArticleId}
                    className="col-1"
                    type="checkbox"
                    onChange={e => this.updateSelectedArticle(e)}
                  />
                  <div className="label-text align-self-center" />
                  <div className="col-10">
                    <div className="font-weight-bold">{article.title}</div>
                    <div className="col-sm-12">
                      <iframe
                        title="article"
                        srcdoc={article.content.substring(0, 2000)}
                        style={{ border: "0px" }}
                        width="119%"
                        height="110"
                        border="0"
                        scrolling="yes"
                      />
                    </div>
                  </div>
                </label>
              ) : (
                  <Link
                    className="checkLabel"
                    to={`/EditNews/${index}`}
                    value={article.newsArticleId}
                  >
                    <div className="font-weight-bold">{article.title}</div>
                    <div className="col-sm-12">
                      <iframe
                        title="article"
                        srcdoc={article.content.substring(0, 2000)}
                        style={{ border: "0px" }}
                        width="101%"
                        height="130"
                        border="0"
                        scrolling="yes"
                      />
                    </div>
                  </Link>
                )}
            </div>
          );
        })}
      </div>
    );
  }
}
AdminNewsHome.propTypes = {
  oneArticle: PropTypes.object.isRequired,
  articleList:PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.article,
    articleList: state.articlesReducer.articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      }),
    onArticlesChange: newArticles =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLES,
        articles: newArticles
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminNewsHome);
