import { Button, Card, CardActions, CardContent, Input, Paper } from "@material-ui/core";
import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_ACTIVITIESFILES, GET_ALL_ACTIVITIES_URL, GET_MEMBER_PP_ID_URL, UPDATE_ACTIVITY_APPROVE_STATUS, UPDATE_ACTIVITY_REJECT_STATUS } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import { formatDate, notfound, status } from "../../loggeddInUser/activitiesPage/ActivityDetails";
import backIcon from "../../loggeddInUser/activitiesPage/icons/back_icon.png";
import "./styles/styles.css";

const noDocuments = "no documents";

class ActivityDetails extends Component {
  state = {
    showReasonInputBox: false,
    isApproved: false,
    isRejected: false,
    reason: "",
    approve: false,
    reject: false
  };

  refreshActivity = () => {
    const { onDataChange } = this.props;
    axios
      .get(BASE + GET_ALL_ACTIVITIES_URL + this.props.activityId,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.data)
      .then(data => {
        onDataChange(data, "viewedActivity");
      })
      .catch(error => {
        return error;
      });
  };
  updateApproveStatus = id => {
    const { onDataChange } = this.props;
    const { refreshActivity } = this;
    onDataChange("Approving", "updateText");
    onDataChange("Reject", "rejectText");
    axios
      .post(BASE + UPDATE_ACTIVITY_APPROVE_STATUS + id,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.data)
      .then(res => {
        onDataChange("Approved", "updateText");
        refreshActivity();
      })
      .catch(error => onDataChange("Failed! Approve again", "updateText"));
  };

  updateRejectStatus = id => {
    const { onDataChange } = this.props;
    const { refreshActivity } = this;
    onDataChange("Approve", "updateText");
    onDataChange("Rejecting", "rejectText");
    axios
      .post(BASE + UPDATE_ACTIVITY_REJECT_STATUS + id,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.data)
      .then(data => {
        onDataChange("Rejected", "rejectText");
        refreshActivity();
      })
      .catch(error => onDataChange("Failed! Reject again", "rejectText"));
  };

  handleSubmit = e => {
    this.props.onAdminPortalChange("0");
  };

  getProfilePicture = memberNumber => {
    const { onDataChange } = this.props;
    onDataChange("loading", "profilePicture");
    axios
      .get(BASE + GET_MEMBER_PP_ID_URL + memberNumber,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(function(response) {
        return response.data;
      })
      .then(data => {
        onDataChange(data, "profilePicture");
      })
      .catch(function(error) {
        onDataChange(null, "profilePicture");
      });
  };

  getActivityDocuments = activityId => {
    const { onDataChange } = this.props;
    onDataChange("loading", "activityDocuments");
    axios
      .get(BASE + GET_ACTIVITIESFILES + activityId,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(function(response) {
        return response.data;
      })
      .then(data => {
        onDataChange(
          data.map(data => ({
            name: data.name,
            link: data.link
          })),
          "activityDocuments"
        );
      })
      .catch(function(error) {
        onDataChange([], "activityDocuments");
      });
  };
  componentWillMount() {
    this.refreshActivity();
    this.getProfilePicture(this.props.currentMember.memNo);
    this.getActivityDocuments(this.props.activityId, this.props.onDataChange);
  }

  onApprovalOrRejection = decision => {
    if (decision === "approve") {
      this.setState({
        approve: true,
        reject: false
      });
    } else if (decision === "reject") {
      this.setState({
        approve: false,
        reject: true
      });
    }
  };

  componentWillUnmount() {
    this.props.onResetViewMembers();
  }

  render() {
    return (
      <div className={this.props.isPartner? "" :"container"}>
        <div
          className={`admin-activity-details-root  ${
            this.state.reject ? "on-reject" : ""
          }`}
        >
          <div className="admin-activity-details-header">
            <span
              onClick={() => {
                this.props.onCurrActivityTabChange(0);
              }}
            >
              <img alt="back_button" src={backIcon} />
            </span>
            <span>Activity </span>
          </div>

          <div className="admin-activity-details-status">
            <span>{this.props.activity.activityType}</span> <br />
            <span> {status(this.props.activity.status)} </span> <br />
            <span> {formatDate(this.props.activity.activityDate)} </span>
          </div>

          <hr className="orange-line" />
          <div
            className={`admin-activity-details-activity-info ${
              this.state.reject ? "on-reject" : ""
            } `}
          >
            <div>
              <span>Member Number:</span> <br />
              <span className="information">
                {this.props.currentMember.memNo
                  ? this.props.currentMember.memNo
                  : notfound}
              </span>
            </div>
            <div>
              <span>Activity ID:</span> <br />
              <span className="information">
                {this.props.activity.activityId}
              </span>
            </div>
            <div>
              <span>Activity Description:</span> <br />
              <span className="information">
                {this.props.activity.activityDescription}
              </span>
            </div>
            <div>
              <span>Firearm Type:</span> <br />
              <span className="information">
                {this.props.activity.firearmType}
              </span>
            </div>
            <div>
              <span>Caliber:</span> <br />
              <span className="information">{this.props.activity.caliber}</span>
            </div>
            <div>
              <span>Association:</span> <br />
              <span className="information">
                {this.props.activity.association
                  ? this.props.activity.association
                  : notfound}
              </span>
            </div>
            <div>
              <span id="score">Documents:</span> <br />
              {this.props.documents === "loading"
                ? "Loading..."
                : this.props.documents.map((doc, index) => (
                    <span key={index} className="information">
                      {doc.name === noDocuments ? (
                        noDocuments
                      ) : (
                        <a
                          href={doc.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {doc.name}
                        </a>
                      )}{" "}
                      <br />
                    </span>
                  ))}
            </div>
          </div>
          <div className="row">
            <Paper anchor="bottom" className="col-12 "elevation={0}>
              <div className="col-12 border-bottom-0 border-warning rounded">
                <div className="row">
                  <div className="col-6 pl-0 p-1">
                    <button
                      className="btn btn-outline-success form-control"
                      onClick={() => {
                        this.onApprovalOrRejection("approve");
                        this.updateApproveStatus(
                          this.props.activity.activityId
                        );
                        this.forceUpdate();
                        this.setState({
                          showReasonInputBox: false,
                          isRejected: false,
                          isApproved: true
                        });
                      }}
                    >
                      Approve
                    </button>
                  </div>

                  <div className="col-6 pr-0 p-1">
                    <button
                      className="btn btn-outline-danger form-control"
                      onClick={() => {
                        this.setState({
                          showReasonInputBox: true,
                          isRejected: true,
                          isApproved: false
                        });
                      }}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              </div>
              {this.state.showReasonInputBox ? (
                <Card>
                  <CardContent>
                    <Input
                      id="filled-multiline-flexible"
                      placeholder="Please provide a reason"
                      multiline
                      value={this.state.reason}
                      onChange={event =>
                        this.setState({ reason: event.target.value })
                      }
                      fullWidth
                      disableUnderline
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      onClick={() => {
                        this.updateRejectStatus(this.props.activity.activityId);
                        this.setState({
                          showReasonInputBox: false,
                          isRejected: true,
                          isApproved: false
                        });
                      }}
                    >
                      Apply
                    </Button>
                  </CardActions>
                </Card>
              ) : null}
            </Paper>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    activity: state.membersAreaReducer.membersArea.viewedActivity,
    names: state.membersAreaReducer.membersArea.memberName,
    members: state.MemberViewReducer.MemberList,
    currentMember: state.MemberViewReducer.MemberView,
    documents: state.membersAreaReducer.membersArea.activityDocuments,
    profilePic: state.membersAreaReducer.membersArea.profilePicture,
    updateText: state.membersAreaReducer.membersArea.updateText,
    rejectText: state.membersAreaReducer.membersArea.rejectText
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrActivityTabChange: newCurrActivityTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2,
        currActivityTab: newCurrActivityTab
      }),
    onDataChange: (data, name) => {
      return dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: data,
        varName: name
      });
    },
    onResetViewMembers: () => {
      return dispatch({
        type: actionTypes.RESET_MEMBERS_VIEWED_ACTIVITY
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityDetails);
