import React, { Fragment } from "react";
import PropType from "prop-types";
import { connect } from "react-redux";
import GenericHeader from "../../../utils/GenericHeader";
import { withRouter } from "react-router-dom";
import "./styles/styles.css";
import CourseStatistics from "./Statistics/CourseStatistics";
import CourseMaterialModules from "./Material/CourseMaterialModules";
import CourseAssessmentModule from "./Assessment/CourseAssessmentModules";
import CourseMaterialEditor from "./CourseMaterialEditor";
import QuizEditor from "../QuizComponents/QuizEditor";
import {
  fetchCourseModules
} from "./Material/CourseMaterialActions";
import axios from "axios";
import {BASE } from "../../../API/APIendpoint";
class LMSNavigator extends React.Component {
  state = {
    component: 1,
    material: null,
    openEditor: false,
    editorType: 0
  };

  componentDidMount() {
    this.props.fetchCourseModules();
  }

  handleChangeTab = tab => {
    this.setState({
      component: tab,
      material: null,
      openEditor: false
    })
  };

  componentLoader = () => {
    switch (this.state.component) {
      case 0:
        return <CourseStatistics />;
      case 1:
        return <CourseMaterialModules openEditor={this.openEditor} />;
      case 2:
        return <CourseAssessmentModule openEditor={this.openEditor} />;
      default:
        return <CourseStatistics />;
    }
  };
  material

  openEditor = (value, material, type) => {
    if(value === true) {
      axios({
        url: `${BASE}api/CourseMaterial/${material.courseMaterialId}`,
        method: "get",
        headers: { 
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        this.setState({
          openEditor: value,
          material: res.data,
          editorType: type
        })
      })
    } else {
      this.setState({
        openEditor: value,
        material: material,
        editorType: type
      })
    }
  }

  changeComponent = (component) => {
    this.setState({
      component: component
    });
  };

  component = (id, name) => {
    return (
      <div
        className={
          `${this.state.component === id ? 'active' : ''}`
        }
        onClick={() => this.changeComponent(id)}
      >
        {name}
      </div>
    );
  }

  switchEditor = () => {
    switch (this.state.editorType) {
      case 1:
        return <CourseMaterialEditor
          openEditor={this.openEditor}
          material={this.state.material}
          refresh={this.props.fetchCourseModules}
        />;
      case 2:
        return <QuizEditor
          openEditor={this.openEditor}
          material={this.state.material}
          refresh={this.props.fetchCourseModules}
        />;
      default:
        return (
          <Fragment>
            Editor not chosen
          <button onClick={() => {
              this.setState({
                openEditor: false
              })
            }}>Go Back</button>
          </Fragment>);
    }
  }

  render() {
    return (
      this.state.openEditor ?
        this.switchEditor() :
        <div className="container">
          <div className="course-mananagement-body">
            <GenericHeader
              title="Course Management"
            />
            <div className="course-management-tab-container">
              <div className="course-management-tab">
                {this.component(0, 'Course Statistics')}
                {this.component(1, 'Course Material Modules')}
                {this.component(2, 'Course Assessment Modules')}
            </div>
              <hr />
            </div>
            <div className="row">
              <div className="col-12 pt-3">
                {this.componentLoader()}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

LMSNavigator.propTypes = {
  fetchCourseModules: PropType.func.isRequired
}

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails
  };
};

export default connect(
  mapStateToProps,
  { fetchCourseModules }
)(withRouter(LMSNavigator));
