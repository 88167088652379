import React from 'react';
import {DateTime} from 'luxon';
import {
  editableSelectConfig,
  editableCheckBoxInputConfig
} from './constants';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});

export const checkValidity = (value, rules, inputType = 'text') => {
  let isValid = true;
  let errors = [];

  // passthrough for fields without validation
  if (!rules) {
    return { isValid, errors };
  }

  if (rules.required) {
    if (rules.maxDate || rules.minDate) {
      isValid = value && isValid;
    } else {
      isValid = value.trim() !== '' && isValid;
    }
    if (!isValid) {
      errors.push('Field is required');
    }
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
    if (!isValid) {
      errors.push(`Field must have ${rules.minLength} or more characters`);
    }
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    if (!isValid) {
      errors.push(`Field must have a maximum of ${rules.maxLength} characters`);
    }
  }

  //check if the entered value is an email or just a username, and not a password, validate for each
  if (/@/.test(value)) {
    if (inputType !== 'password') {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
      if (!isValid) {
        errors.push('Email format is incorrect');
      }
    }
  }

  if (rules.maxDate || rules.minDate) {
    const valueDateTime = DateTime.fromJSDate(new Date(value));
    isValid = valueDateTime.isValid && isValid;
    if (!isValid) {
      errors.push('date invalid');
    }
  }

  if (rules.maxDate) {
    const maxDateTime = DateTime.fromJSDate(rules.maxDate);
    if (!maxDateTime.isValid) {
      errors.push('maximum date invalid');
      isValid = false;
    } else if(isValid) {
      const valueDateTime = DateTime.fromJSDate(new Date(value));
      const dateDiff = valueDateTime.diff(maxDateTime, 'days').toObject();
      isValid = Math.round(dateDiff.days) <= 0 && isValid;
      if (!isValid){
        errors.push(`date should be less than ${maxDateTime.toLocaleString({ locale: 'en-gb' })}`);
      }
    }
  }

  if (rules.minDate) {
    const minDateTime = DateTime.fromJSDate(rules.minDate);
    if (!minDateTime.isValid) {
      errors.push('minimum date invalid');
      isValid = false;
    } else if(isValid) {
      const valueDateTime = DateTime.fromJSDate(new Date(value));
      const dateDiff = minDateTime.diff(valueDateTime, 'days').toObject();
      isValid = Math.round(dateDiff.days) <= 0 && isValid;
      if (!isValid){
        errors.push(`date should be more than ${minDateTime.toLocaleString({ locale: 'en-gb' })}`);
      }
    }
  }

  return { isValid, errors };
};

export const initSelect = (items) => ({
  ...editableSelectConfig,
  config: {
    ...editableSelectConfig.config,
    elementConfig: {
      ...editableSelectConfig.config.elementConfig,
      options: items,
    },
  }
});

export const initCheckbox = content => ({
  ...editableCheckBoxInputConfig,
  config: {
    ...editableCheckBoxInputConfig.config,
    elementConfig: {
      ...editableCheckBoxInputConfig.config.elementConfig,
      accept: {
        value: 'true', 
        label: content
      },
    },
  }
});

export function showOption(questionType, description, index,
  totalOptions, questionId, score, actualScore) {
  switch (questionType) {
  case 0:
    return (
      <div className="d-flex w-100 mb-2">
        <input
          className="d-inline-block mr-2 align-self-center"
          key={questionId * 10 + index}
          name={`${questionId}single`}
          type={'radio'}
          onChange={() => {
            score['scoreIndex'] = index;
          }}
        />
        <label
          className="align-self-center mb-0"
        >{description}</label>
      </div>
    );
  case 1:
    var arr = score['scoreArrayIndex'];
    var numOfOptions = 0;
    arr.forEach((a, i) => {
      if (a === 1) {
        if (actualScore === numOfOptions) {
          arr[i] = 0;
        }
        numOfOptions += 1;
      }
    });

    return (
      <div className="d-flex w-100 mb-2">
        {(actualScore <= numOfOptions && arr[index] !== 1) ?
          <div style={{
            minWidth: '22.5px',
            height: '22.5px',
            border: '1px dashed'
          }}
          className="d-inline-block mr-2 align-self-center">
          </div> :
          <input
            className="d-inline-block mr-2 align-self-center"
            style={{
              minWidth: '22.5px'
            }}
            key={questionId * 10 + index}
            type={'checkbox'}
            onChange={(e) => {
              if (e.target.checked) {
                arr[index] = 1;
              } else {
                arr[index] = 0;
              }
            }}
          />
        }
        <label
          className="align-self-center mb-0"
        >{description}</label>
      </div>
    );
  case 2:
    return sortOption();
  case 3:
    return sortOption(64, false);
  default:
    return null;
  }

  function sortOption(offset = 0, isNumbers = true) {
    const choices = Array.from(new Array(totalOptions + 1),
      (v, k) => k + offset);
    return (
      <div className="d-flex w-100 mb-2">
        <select
          className="mr-2 w-3"
          key={questionId * 10 + index}
          onChange={(e) => {
            let arr = score['scoreArrayValues'];
            arr[index] = parseInt(e.target.value);
          }}
        >
          {choices.map((c, key) => <option value={c} key={key}>{isNumbers ? c : String.fromCharCode(c)}</option>)}
        </select>
        <label
          className="align-self-center mb-0"
        >{description}</label>
      </div>
    );
  }
}

export function calculateScore(questionType, trueScores, scored) {
  switch (questionType) {
  case 0:
    if (trueScores[scored] === 1) {
      return 1;
    } else {
      return 0;
    }
  case 1:
    var score = 0;
    trueScores.forEach((val, index) => {
      if (val === scored[index]) {
        score += 1;
      }
    });
    return score;
  case 2:
  case 3:
    var sum = 0;
    scored.forEach((val, index) => {
      if (index === 0) {
        sum = val;
      }
      else {
        if (index % 2 === 1) {
          sum -= val;
        }
        else {
          sum *= val;
        }
      }
    });
    return sum;
  default:
    return null;
  }
}

export const initSignupSelect = (items, selectConfig) => ({
  ...selectConfig,
  config: {
    ...selectConfig.config,
    elementConfig: {
      ...selectConfig.config.elementConfig,
      options: items,
    },
  }
});

export function convertToArrayOfObjects(data) {

  var collection = data.slice(); // make a copy
  
  collection = collection?.map(function (e) {
      var obj = {value:e, label:e};
  
      return obj;
  });

  return collection;
}