import React, { Component } from "react";
import axios from "axios";
import { BASE, ChangePassword_URL } from "../../API/APIendpoint";
import "./profile.css";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import UserDetails from "../loggeddInUser/userProfile/UserDetails";
import { isMobile } from "react-device-detect";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from 'react-toastify';
toast.configure();
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MemNo: this.props.memberDetails.memberNumber,
      Password: this.props.memberDetails.password,
      newPassword: null,
      newPasswordConfirmation: null,
      userDetails: false,
      showPasswordCurrent: false,
      showPasswordNew: false,
      showPasswordConfirm: false
    };
    this.changePasswordConfirm = this.changePasswordConfirm.bind(this);
  }
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleClickShowPasswordCurrent = () => {
    this.setState(state => ({ showPasswordCurrent: !state.showPasswordCurrent }));
  };
  handleClickShowPasswordNew = () => {
    this.setState(state => ({ showPasswordNew: !state.showPasswordNew }));
  };
  handleClickShowPasswordConfirm = () => {
    this.setState(state => ({ showPasswordConfirm: !state.showPasswordConfirm }));
  };
  changePasswordConfirm = async () => {
    const {
      newPassword,
      currentPassword,
      newPasswordConfirmation,

    } = this.state;

    if (!currentPassword || !newPassword || !newPasswordConfirmation) {
      alert("You need to enter your password")
    }

    else if (newPassword.length < 8) {
      alert("Your new password must be atleast 8 charecters")
    }
    else if (newPassword !== newPasswordConfirmation) {
      alert("Passwords don't match");

    }

    else {
      const JSONbody = {
          memberNumber: this.state.MemNo,
          currentPassword: this.state.currentPassword,
          newPassword: this.state.newPassword
      }
      await axios({
        url: BASE + ChangePassword_URL,
        data: JSONbody,
        method: "post",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
        if(res.data.message != "password updated"){
          toast.error(res.data.message);
        } else {
          toast.success(res.data.message);
        }
      })
      await this.setState({
        userDetails: true
      });
    }
  }
  render() {
    var width1 = "";
    var width2 = "";
    if ( isMobile ) {
      width1 = "20%"
      width2 = "20%"
    } else {
      width1 = "29%"
      width2 = "40%"
    }
    const info = (row1, row2) => (
      <tbody>
        <tr >
          <td style={{width: width1}}>{row1}</td>
          {isMobile ? null : <td style={{width: "48%"}} /> }
          <td className="font-weight-bold" style={{width: width2}}>{row2}</td>
        </tr>
      </tbody>
    );
    if(this.state.userDetails !== true){
      return (
        < div className="container">
          <div className="row ml-1 mr-1">
            <GenericHeader
              title="Change Password"
              showBackButton
              backFunction={() => {
                this.setState({
                  userDetails: true
                });
              }}
            />
          </div>
          <table className="ml-4 mr-4">
              {info("Current Password", 
                <Input
                  type="password"
                  className="form-control"
                  type={this.state.showPasswordCurrent ? "text" : "password"}
                  id="currentPassword"
                  onInput={a => {
                    this.setState({
                      currentPassword: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPasswordCurrent}
                      >
                        {this.state.showPasswordCurrent ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
              />
              )}
              {info("New Password", 
                <Input
                  type="password"
                  className="form-control"
                  type={this.state.showPasswordNew ? "text" : "password"}
                  id="newPassword"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required
                  onInput={a => {
                    this.setState({
                      newPassword: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPasswordNew}
                      >
                        {this.state.showPasswordNew ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
              {info("Confirm Password", 
                <Input
                  type="password"
                  className="form-control"
                  type={this.state.showPasswordConfirm ? "text" : "password"}
                  id="newPasswordConfirmation"
                  name="password"
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required
                  onInput={a => {
                    this.setState({
                      newPasswordConfirmation: a.target.value
                    });
                  }}
                  onChange={this.onChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPasswordConfirm}
                      >
                        {this.state.showPasswordConfirm ? (
                          <Visibility />
                        ) : (
                            <VisibilityOff />
                          )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
          </table>
          <div className="formButton placeAtEnd mr-4">
            <button className="btn btn-warning mt-3" onClick={this.changePasswordConfirm}>
              Submit
              </button> <br />
          </div>
        </div >
      );
    } else if(this.state.userDetails === true) {
      return(
        <UserDetails />
      )
    }
  }
}
const mapStateToProps = state => {
  return {
    memberDetails: state.loginReducer.memberObject,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onMemberDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);