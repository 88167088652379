import React from "react";
import ContactUs from "./ContactUs";
import StoreLocator from "../../storeLocator/StoreLocator";
import Aims from "./Aims";
import CodeOfConduct from "./CodeOfConduct";
import DiciplinaryCode from "./DiciplinaryCode";
import Policy from "./Policy";

function AboutContent(props) {
  return (
    <div>
      <Aims />
      <CodeOfConduct />
      {props.isLoggedIn ? <DiciplinaryCode /> : null}
      <StoreLocator />
      {props.isLoggedIn ? <Policy /> : null}
      <div id="ContactUs">
        <ContactUs />
      </div>
    </div>
  );
}

export default AboutContent;
