import React, { Component, Fragment } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import { COURSE_MODULE } from "../LMSAPIEndPoints";

export default class AddModule extends Component {

  constructor(props) {
    super(props);
    this.state = {
      addModuleStatus: 'Add module',
      addModule: false,
      addModuleText: null
    }
  }

  addCourseModule = (name, type) => {
    const data = {
      "Index": 0,
      "Name": name,
      "Type": type
    }
    this.setState({
      addModuleStatus: "Creating module...",
      addModule: true
    })
    fetch(COURSE_MODULE,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(res => res.json())
      .then(res => {
        this.props.fetchCourseModules();
        this.setState({
          addModuleStatus: res.message
        })
        this.apiTimeOuts();
      })
      .catch(error => {
        this.setState({
          addModuleStatus: error.message ? error.message : 'Failed! Try Again'
        })
        this.apiTimeOuts();
      })
  }

  apiTimeOuts = () => {
    setTimeout(() => {
      this.setState({
        addModuleStatus: null,
        addModuleText: null
      })
      this.setState({ addModule: false });
    }, 2000);
  }

  buttons = () => {
    return (
      <Fragment>
        <Add id="svgStatic" />
        <input type="text" placeholder="Enter module name"
          onChange={(e) => { this.setState({ addModuleText: e.target.value }) }} />
        <Check
          style={{ visibility: this.state.addModuleText ? "visible" : "hidden" }}
          onClick={() => {
            this.addCourseModule(this.state.addModuleText, this.props.type);
          }} />
        <Clear onClick={() => { this.props.onClick(0); }} />
      </Fragment>
    );
  }
  render() {
    if (!this.state.addModule) {
      return this.buttons()
    }
    return <div>{this.state.addModuleStatus}</div>
  }
}
