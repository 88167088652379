import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
const styles = {
  card: {
    minWidth: 500
  },
  root: {
    flex: 1,
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: 20,
    paddingLeft: 20,
    color: "black"
  },
  content: {
    display: "inline-block",
    fontSize: 15,
    color: "black"
  }
};
class AccountsViewAll extends Component {
  DateDifference = (datePart, fromDate, todate) => {
    datePart = datePart.toLowerCase();
    var diff = todate - fromDate;
    var divideBy = { w: 604800000, d: 86400000, h: 3600000, m: 60000, s: 1000 };

    return Math.floor(diff / divideBy[datePart]);

   
  };
  memberStatus = () => {
    var daysToExpiry = this.DateDifference(
      "d",
      new Date(Date.now()),
      new Date(this.props.MemberV.expiryDate.substring(0, 10))
    );
    return daysToExpiry >= 0 ? (
      <div className="text-success">Approved</div>
    ) : (
      <div className="text-warning">Pending</div>
    );
  };
  isPaymentStillValid = () => {
    return (
      this.props.recentAccountEntry.memNo !== undefined &&
      this.DateDifference(
        "w",
        new Date(this.props.recentAccountEntry.paymentDate.substring(0, 10)),
        new Date(Date.now())
      ) < 52
    );
  };
  render() {
    console.log("AccountsViewAll ", this.props)
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <div className={this.props.isPartner ? "" : "container"}>
        <div className="row">
          <GenericHeader
            title="Accounts Details"
            showBackButton
            backFunction={() => {
              this.props.onCurrAccountViewTabChange(0, "currentAccountTab");
            }}
          />
        </div>
        {info(
          "Invoice Date: ",
          this.props.memPaymentDetails.invoiceDate.substring(0, 10)
        )}
        {info("Invoice Number: ", this.props.memPaymentDetails.invoiceNumber)}
        {info("Payment Method: ", this.props.memPaymentDetails.paymentMethod)}
        {info("Receipt Number: ", this.props.memPaymentDetails.recieptNumber)}
        {info("Year: ", this.props.memPaymentDetails.year)}
        {info("Amount: ", `R ${this.props.memPaymentDetails.amountPaid}`)}
        {info("Transaction Number: ", this.props.memPaymentDetails.transactionNo)}
      </div>
    );
  }
}
AccountsViewAll.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    recentAccountEntry: state.accountReducer.recentAccountEntry,
    memPaymentDetails: state.accountReducer.PaymentObject
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCurrAccountViewTabChange: newCurrAccountViewTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB,
        currAccountViewTab: newCurrAccountViewTab
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),

    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AccountsViewAll));
