import React, { Component } from "react";
import Delete from "@material-ui/icons/Delete";
import Forward from "@material-ui/icons/Forward";

export default class BulkMainMenu extends Component {
  render() {
    return (
      <div className="bulk-menu">
        {this.props.numberOfSelection + ' ' +
          this.props.selectedComponentsType + " selected"}
        <Delete onClick={() => {
          this.props.onClick(2);
        }}/>
        {this.props.isParents ? null :
          <Forward onClick={() => {
            this.props.onClick(6);
          }}/>
        }
      </div>
    )
  }
}
