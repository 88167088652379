export const APPROVE_MEMBER_ERROR = "APPROVE_MEMBER_ERROR",
    APPROVE_MEMBER_BEGIN = "APPROVE_MEMBER_BEGIN",
    APPROVE_MEMBER_SUCCESS = "APPROVE_MEMBER_SUCCESS",
    UPDATE_ACCOUNT_BEGIN = "UPDATE_ACCOUNT_BEGIN",
    UPDATE_ACCOUNT_ERROR = "UPDATE_ACCOUNT_ERROR",
    UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS",
    SET_RECEIPT_NO = "SET_RECEIPT_NO",
    SET_INVOICE_NO = "SET_INVOICE_NO",
    SET_PAYMENT_DATE = "SET_PAYMENT_DATE",
    SET_YEAR = "SET_YEAR",
    SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
    SET_AMOUNT = "SET_AMOUNT";
