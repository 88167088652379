import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import { FRONT } from "../../../API/APIendpoint";
import axios from "axios";
import { BASE,FIREARMS_GUARDIAN_NEW_APPLICATION} from "../../../API/APIendpoint";
import {
  useTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Alert, AlertTitle } from '@material-ui/lab';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useStylesFirearmOwners } from "../../../stylesNew/FirearmOwnersStyles";
import { useStylesFirearmsGuardians } from "../../../stylesNew/FirearmsGuardianStyles";
import StaticPageHeader from "../../components/StaticPageHeader";
import Button from "@material-ui/core/Button";
import FirearmsGuardianImage from "../../../assetsNew/firearms-guardian.png";
import insuresense from "../../../assetsNew/insuresense.png";
import FirearmsGuardianImageSmall from "../../../assetsNew/firearms-guardian-small.png";
import coverPremium from "../../../assetsNew/premium.jpg";
import GenericImage from "../../../assetsNew/genric-logo.png";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { toast } from 'react-toastify';
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Divider from "@material-ui/core/Divider";
import GoToTop from "../../components/GoToTop";
import ListItemWithIcon from "./ListItemWithIcon";
import ListItemWithLists from "./ListItemWithLists";
function getSteps() {
  return ['Step 1', 'Step 2'];
}


const firstAgreementText = ("I hereby request and authorise Insuresense Consultants (Pty) Ltd or its agent/s to draw against my bank account as indicated herein each month / year and to debit my account with the amount equivalent to the service and administration fee due by me to Insuresense Consultants (Pty) Ltd for membership of Insuresense Consultants (Pty) Ltd, until cancelled by me in writing. In the event of an increase of service fees, Insuresense Consultants (Pty) Ltd or its agent/s have my authority to deduct from my account all such fees in payment as indicated herein. All such debits against my account" + 
"shall be treated as though I have signed and done them personally. If, in the event of payment from my bank account, such payment day falls on a Sunday or recognized South African public holiday, the payment day will automatically be the preceding or following ordinary business day. I further authorize that, if there are insufficient funds in my nominated bank account, the debit order system tracks my account and re-present the instruction for payment as soon as sufficient funds are available in my account.");
const secondAgreementText = ("I, the undersigned hereby apply for a policy in accordance with all applicable terms and conditions. The application form is completed in my presence and signed by me. I acknowledge that payment of premiums on the due dates is my responsibility. I warrant that all information given in this application form whether in my handwriting or not is true and complete. I understand and accept the implications of this application and I accept that this application together with the applicable terms and conditions represent the agreement between myself and GENRIC Insurance Company Limted.");
const thirdAgreementText = ("Declaration and informed Consent in terms of the Protection of Personal Information Act 4, of 2013 (POPIA). We at GENRIC Insurance Company Limited (GENRIC) and Insuresense Consultants (Pty) Ltd (Insuresense) respect your right to privacy. We need to collect and process some of your personal information in terms of various Privacy and Data Management laws and are bound by the terms and provisions of the Protection of Personal Information Act, regarding the acquisition, usage, retention, transmission and deletion of your personal information." +
"All information obtained or acquired from /about you shall remain confidential unless you provide written consent, or unless we are required by law to disclose such information. Your personal information collected is for the primary purpose of providing you with insurance cover and for all other activities and processes incidental to and relevant to this purpose. As this information forms the basis of our assessment and terms, we offer you, it must be correct, complete, and up to date. We will always comply with all relevant regulations in dealing with your information " +
" and keep it secure and confidential at all times. Your information shall be kept confidential; however, we shall disclose it to certain third parties as required and other insurers for the specific purpose of insurance and to reduce and prevent any form of fraudulent activity. We may disclose your personal information to our service providers who are involved in the delivery of products or services to you. We have agreements in place to ensure that they comply with the privacy requirements as required by the Protection of Personal Information Act. When we contract with third parties " +
" we impose appropriate security, privacy and confidentiality obligations on them to ensure that personal information that we remain responsible for, is kept secure. We will ensure that anyone to whom we pass your personal information agrees to treat your information with the same level of protection as we are obliged to do. We may also disclose your information: • Where we have a duty or a right to disclose in terms of law or industry codes; • Where we believe it is necessary to protect our rights. You have the right to ask us to update, correct or delete your personal information." +
" Should you decide not to accept the proposal, the information collected, will be de-identified and only used for statistical and research purposes. Should you decide to cancel this insurance contract you further consent to GENRIC and Insuresense, in retaining the information in line with the legally permitted retention period, for statistical and reporting purposes only. Our Privacy Notice and POPIA Policy provides the details of how we deal with the personal information of our clients, and it is available on our website at the following address: https://www.genric.co.za. " + 
" I hereby voluntary consent to GENRIC processing my Personal Information. I understand the purposes for which my Personal Information is required and for which it will be used. I give GENRIC permission to process my Personal Information as provided above.");

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText("#64724F"),
    backgroundColor: "#64724F",
    "&:hover": {
      backgroundColor: "#64724F",
    },
    borderRadius: "7px",
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  topSpacing: {
    marginTop: "30px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const scroll = () => {
  const section = document.querySelector( '#divApplication' );
  section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
};

function FirearmsGuardian(props) {
  const classesFirearmsGuardian = useStylesFirearmsGuardians();
  const classesFirearmOwners = useStylesFirearmOwners();
  const theme = useTheme();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const steps = getSteps();
  const [isCompleted, setIsCompleted] = React.useState(false);


  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const [country, setCountry] = useState("South Africa");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [idNo, setIdNo] = useState("");
  const [cellNumber, setCellNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  
  const [accountHolder, setAccountHolder] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("Current(Cheque)");

  const [contractAmount, setContractAmount] = useState("Firearms Guardian - Option 1 : R135.00 p/m");
  const [debitOrderDate, setDebitOrderDate] = useState("1st");
  
  const [firstAgreement, setFirstAgreement] = useState(false);
  const [secondAgreement, setSecondAgreement] = useState(false);
  const [thirdAgreement, setThirdAgreement] = useState(false);
  const [promoCode, setPromoCode] = useState("");


  const handleCountrySelect=(e)=>{
    setCountry(e.target.value)
  }

  const handleContractAmountChange=(e)=>{
    setContractAmount(e.target.value)
  }  
  
  const handleDebitOrderDateChange=(e)=>{
    setDebitOrderDate(e.target.value)
  }

  const handleAccountType=(e)=>{
    setAccountType(e.target.value)
  }

  const activateButton = () => {
    setIsActive(true);
  }

  const deactivateButton = () => {
  setIsActive(false);
}

  const resetApplication=()=>{
    handleBack();
    setAccountHolder(" ")
    setAccountNumber("")
    setAccountType("")
    setAddress1("")
    setAddress2( "")
    setFirstAgreement( false)
    setSecondAgreement( false)
    setThirdAgreement( false)
    setBankName( "")
    setCellNumber( "")
    setCity( "")
    setContractAmount( "")
    setCountry("South Africa")
    setDebitOrderDate( "")
    setEmail( "")
    setIdNo( "")
    setName( "")
    setPostalCode( "")
    setPromoCode( false)
    setProvince( "")
    setLastName( "")
    
  }

  const handleNext = (activeStep) => {

    //Step 1 checks - Do checks before moving on to the next step

    //Do control checks  here 
    if((activeStep + 1) >= steps.length)
    {
      
      let application = {
          "email": email,
          "cellNo": cellNumber,
          "idNo": idNo,
          "surname": lastname,
          "name": name,
          "memno": localStorage.getItem("memNo"),
          "accountHolder": accountHolder,
          "bankName": bankName,
          "accountNumber": accountNumber,
          "accountType": accountType,
          "contractType": contractAmount,
          "debitDate": debitOrderDate,
          "promoCode": promoCode,
          "agreementOne": firstAgreement,
          "agreementTwo": secondAgreement,
          "agreementThree": thirdAgreement,
          "addressLine1": address1,
          "addressLine2": address2,
          "city": city,
          "province": province,
          "postalCode": postalCode,
          "country": country
      }

      //Check if All fields have values 
      if(!fieldValidation(application))
        {
          toast.error("Fields marked with * are required.");
          return;
        }

      axios.post(BASE+ FIREARMS_GUARDIAN_NEW_APPLICATION ,application).then(res => {
        setIsCompleted(true)
        resetApplication();
      })
      .catch(error =>{
        console.log("Error sending application",error);
        toast.error("Error sending application." + error.message);
      });

    }
    else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const fieldValidation =(application) =>{
    if(application.email.length == 0) return false;
    if(application.cellNo.length == 0) return false;
    if(application.idNo.length == 0) return false;
    if(application.surname.length == 0) return false;
    if(application.name.length == 0) return false;
    if(application.accountHolder.length == 0) return false;
    if(application.bankName.length == 0) return false;
    if(application.accountNumber.length == 0) return false;
    if(application.addressLine1.length == 0) return false;
    if(application.city.length == 0) return false;
    if(application.province.length == 0) return false;
    if(application.postalCode.length == 0) return false;

    return true;
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
      isCompleted ?
      <Grid container direction="column" style={{marginBottom:'-45px'}}>
      <GoToTop />
      <div >
        {matches && <StaticPageHeader tile="Home" align="left"/>}
      </div>
      <img
        className={
          matches
            ? classesFirearmsGuardian.imgMobile
            : classesFirearmsGuardian.wrap
        }
        alt="Firearms Guardian"
        align={matches ? "right" : "center"}
        src={FirearmsGuardianImage}
      />

      <Grid
        className={
          matches
            ? classesFirearmsGuardian.wrapMobile
            : classesFirearmsGuardian.wrap
        }
      >
        <p>
          <Alert severity="success">
            <AlertTitle>Thank you for signing up</AlertTitle>
            <p style={{fontSize:'12px'}}>
              Please check your email for the registration details.<br/>
              Remember that Your Guardian policy will only become active once the first debit order is received.</p>
          </Alert>

        </p>

                
        <Grid align={"center"} style={{marginBottom: "50px"}}>

        <Button
              
              onClick={(e) => {
                window.location = "/";
                e.preventDefault();
              }}
              size="large"
              style={{
                borderColor: "#64724F",
                color: "black",
              }}
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
        </Grid>
      </Grid>

    </Grid>
      :
    <Grid container direction="column" style={{marginBottom:'-45px'}}>
      <GoToTop />
      <div >
        {matches && <StaticPageHeader tile="Home" align="left"/>}
      </div>
      <img
        className={
          matches
            ? classesFirearmsGuardian.imgMobile
            : classesFirearmsGuardian.wrap
        }
        alt="Firearms Guardian"
        align={matches ? "right" : "center"}
        src={FirearmsGuardianImage}
      />

      <Grid
        className={
          matches
            ? classesFirearmOwners.firearmOwnersContainerMobile
            : classesFirearmOwners.firearmOwnersContainer
        }
      >
        <h2
          className={
            matches
              ? classesFirearmsGuardian.headerMobile
              : classesFirearmsGuardian.header
          }
        >
          {" "}
          What is Firearms Guardian?
        </h2>

        <p className={classesFirearmsGuardian.textColor}>
          Have you ever thought of the legal support you might need as a result
          of using your legally owned firearm in cases such as defending
          yourself, your family or property by using your firearm?
        </p>
        <br />
        <p className={classesFirearmsGuardian.textColor}>
          Or as a result of an accident on the shooting range or a hunting trip?
        </p>
        <br />
        <p className={classesFirearmsGuardian.textColor}>
          Firearms Guardian is there to protect you as a{" "}
          <strong>lawfully armed citizen</strong> in South Africa, to provide
          you with some piece of mind when using your licenced firearm for
          self-defence, sport shooting or hunting.
        </p>
        <br />
        <p className={classesFirearmsGuardian.textColor}>
          <strong>Expert legal and liability cover</strong> when you need it
          most.
        </p>
        <br />
        <p className={classesFirearmsGuardian.textColor}>
          Firearms Guardian is a ground-breaking legal protection insurance
          package for lawfully armed citizens as a result of any Firearms
          Control Act related prosecution, whether for self-defence, accidental
          discharge, hunting accidents and more.{" "}
        </p>
        <br />

        <p className={classesFirearmsGuardian.textColor}>
          Firearms Guardian provides exactly that from as little as R135.00 p/m.
          Depending on the option that you choose, you’ll get comprehensive
          legal cover up to R300 000 per case and liability cover up to R300 000
          per annum.
        </p>

        <br />
        <p className={classesFirearmsGuardian.textColor}>
          Firearms Guardian has <strong>got you covered! 24/7/365</strong>
        </p>
      </Grid>

      <Grid
        className={
          matches
            ? classesFirearmsGuardian.videoSectionMobile
            : classesFirearmsGuardian.videoSection
        }
      >
        <h2
          className={
            matches
              ? classesFirearmsGuardian.headerMobile
              : classesFirearmsGuardian.subHeader
          }
        >
          {" "}
          WATCH: Why you should join Firearms Guardian?{" "}
        </h2>
        <br />
        <iframe
          className={classesFirearmsGuardian.centerContent}
          width={matches ? "100%" : "680"}
          align="center"
          height={matches ? "315" : "415"}
          src="https://www.youtube.com/embed/lEtalePK3vY"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Grid>

      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          className={
            matches
              ? classesFirearmsGuardian.offersAndLegalMobile
              : classesFirearmsGuardian.offersAndLegal
          }
        >
          <Grid
            item
            spacing={3}
            style={{ paddingRight: "30px" }}
            xs={matches ? 12 : 6}
            align={matches ? "center" : "right"}
          >
            <h2>
              Firearms Guardian offers comprehensive legal & liability
              protection:
            </h2>

            <ColorButton
              align="center"
              variant="contained"
              color="primary"
              disableRipple
              className={classes.margin}
              onClick={()=> scroll()}
            >
              Join Today!
            </ColorButton>
          </Grid>

          <Grid
            item
            spacing={3}
            xs={matches ? 12 : 6}
            align={matches ? "center" : "left"}
          >
            <List component="nav" aria-label="main mailbox folders">
              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Comprehensive and powerful Legal Protection and Liability Cover for you and 
your immediate family"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Cover includes firearm self-defence, accidental discharge, hunting accidents, 
sport shooting and more, as well as Firearms Control Act related prosecution"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Legal assistance and representation up to R300 000 per case/per claim"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Liability cover for firearm damages up to R300 000 per annum"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Lawyers who are available 24 hours"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Legal advice and assistance by over 2 500 top practicing Lawyers nationwide"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Legal representation in the High and Magistrate’s Courts nationwide"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Lawyers are utilised in their areas of expertise"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="All Lawyers have more than 10 years practical experience"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Only practicing attorneys and advocates are used"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="We do not use paralegals"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Bail payments up to R3 000 per incident"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="A 3-month waiting period applies to liability claims and legal representation in 
Court"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="You are entitled to legal advice from policy inception"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="Cases existing at policy inception excluded"
                />
              </ListItem>

              <ListItem style={{ textAlign: "left", padding: "0px" }}>
                <ListItemIcon>
                  <Brightness1Icon
                    style={{ color: "white", fontSize: "10px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  style={{ marginLeft: "-30px" }}
                  primary="No excess or hidden costs"
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

      <Grid
        className={
          matches
            ? classesFirearmsGuardian.protectSectionMobile
            : classesFirearmsGuardian.protectSection
        }
      >
        <h2
          className={
            matches
              ? classesFirearmsGuardian.protectHeaderMobile
              : classesFirearmsGuardian.protectHeader
          }
        >
          Protecting you in order to Protect your Family{" "}
        </h2>
        <br />
      </Grid>

      <Grid
        className={
          matches
            ? classesFirearmOwners.firearmOwnersContainerMobile
            : classesFirearmOwners.firearmOwnersContainer
        }
      >
        <h3
          className={
            matches
              ? classesFirearmsGuardian.coverHeaderMobile
              : classesFirearmsGuardian.coverHeader
          }
        >
          COVER AND BENEFITS
        </h3>

        <p className={classesFirearmsGuardian.coverText}>
          The benefits covered are limited to incidents relating to the
          Insured’s ownership, possession and/or use of the his/her licensed
          firearm.
        </p>

        <div className={classes.topSpacing}>
          <Accordion
            className={classesFirearmsGuardian.textColor}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                1. Legal Assistance Cover
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You are covered for legal expenses in respect of legal services,
                including legal advice, legal representation and litigation
                services, pertaining to the following and subject to the policy
                terms and conditions:
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <strong style={{fontStyle:'italic'}}>General</strong>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
              Administrative assistance related to a firearm licence, Civil claims instituted by and against the Insured and criminal prosecution of the Insured, regarding the matters as set out below.
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <strong style={{fontStyle:'italic'}}>Firearm Related Matters</strong>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <strong >1.1 Administrative Problems</strong>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Unfit declarations to possess a firearm"/>
                <ListItemWithIcon text="Appeals against a declined firearm licence application"/>
                <ListItemWithIcon text="Problems with furnishing of licence by SAPS"/>
              </List>
            </AccordionDetails>


            <AccordionDetails>
              <Typography>
                <strong>1.2 Criminal Prosecution / Defence</strong>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <span style={{fontStyle:'italic'}}>General</span>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Murder"/>
                <ListItemWithIcon text="Attempted murder"/>
                <ListItemWithIcon text="Culpable Homicide"/>
                <ListItemWithIcon text="Assault"/>
                <ListItemWithIcon text="Assault with intention to cause grievous bodily harm"/>
                <ListItemWithIcon text="Hunting related prosecutions"/>
                <ListItemWithIcon text="Injury/loss/damage to persons or property resulting from the use of a firearm"/>
                <ListItemWithIcon text="Shooting accidents on hunting trips/sport shooting events/ firearm training sessions"/>
                <ListItemWithIcon text="Bail applications"/>
                <ListItemWithIcon text="Bail payments"/>
                <ListItemWithIcon text="Appeals in respect of all firearm related prosecutions"/>
              </List>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <span style={{fontStyle:'italic'}}>Specific transgressions in terms of the Firearms Control Act 60 of 2000</span>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Unlawful possession of a firearm"/>
                <ListItemWithIcon text="Where the Insured is aware that somebody else possesses a firearm illegally and to fail to report this to the police"/>
                <ListItemWithIcon text="Causing bodily injury to a person or damage to property by negligently using a firearm"/>
                <ListItemWithIcon text="Discharging a firearm in a manner likely to injure or endanger the safety or property of any person"/>
                <ListItemWithIcon text="Having control over a loaded firearm in circumstances where it creates a risk to the safety or property of another and not to take reasonable precautions to avoid the danger"/>
                <ListItemWithIcon text="Giving control of a firearm to a person whom the Insured knows, or ought to have known, to be mentally ill, or to be under the influence of a substance which has an intoxicating or a narcotic effect"/>
                <ListItemWithIcon text="Point a firearm at another person without good cause"/>
                <ListItemWithIcon text="Discharging a firearm in a built-up area or public place"/>
                <ListItemWithIcon text="Failure to lock away a firearm which the Insured has in his / her possession in a prescribed safe, strong-room or device for the safekeeping"/>
                <ListItemWithIcon text="Loose a firearm owing to a failure to lock it away in a safe, strong-room or safekeeping device, or owing to failure to take reasonable steps to prevent its loss or owing to failure to keep the keys to the safe, strong-room or device in safe custody"/>
                <ListItemWithIcon text="Giving possession of a firearm to or ammunition to a person that is not allowed to possess such firearm or ammunition"/>
                <ListItemWithIcon text="Failure to report a lost, stolen or destroyed firearm"/>
                <ListItemWithIcon text="Carrying or storing of a firearm in a firearm-free zone"/>
                <ListItemWithIcon text="Deactivating of a firearm"/>
                <ListItemWithIcon text="Carrying of a firearm in a public place"/>
                <ListItemWithIcon text="Allowing any person to use his / her firearm while not under his / her immediate supervision, where it is safe to use the firearm and for a lawful purpose"/>
                <ListItemWithIcon text="Using of a firearm where it is not safe to do so"/>
                <ListItemWithIcon text="Notification of change in address"/>
                <ListItemWithIcon text="Notification of any change with regard to any information which was submitted in respect of the application for the issue of a licence"/>
                <ListItemWithIcon text="Disposing of a firearm after cancellation of a licence"/>
                <ListItemWithIcon text="Lost, stolen or defaced licences"/>
                <ListItemWithIcon text="Production of licences and firearms for inspection"/>
                <ListItemWithIcon text="Failure to comply with conditions of a licence"/>
                <ListItemWithIcon text="Failure to comply with any provision, direction or requirement of a notice issued under the Firearms Control Act"/>
                <ListItemWithIcon text="Unlawful possession of ammunition"/>
                <ListItemWithIcon text="Possessing excessive number of cartridges"/>
                <ListItemWithIcon text="Possessing more than the allowed number of primers"/>
              </List>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <strong>1.3 Civil Law Matters Related to the Use of a Firearm</strong>
              </Typography>
            </AccordionDetails>


            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Disputes relating to the purchase of firearms"/>
                <ListItemWithIcon text="Estate Firearms"/>
                <ListItemWithIcon text="Damages consequent to the death or injury to a third party which occurs in connection with your use of a firearm"/>
                <ListItemWithIcon text="Loss of or damage to property, which occurs in connection with your use of a firearm"/>
                <ListItemWithIcon text="Civil claims arising out of unlawful or unlicensed hunting, but with a legal firearm belonging to the insured"/>
                <ListItemWithIcon text="Shooting accidents on hunting trips or sport shooting events or firearm training sessions"/>
                <ListItemWithIcon text="Problems with hunting safari packages"/>
                <ListItemWithIcon text="Damage to hunting equipment"/>
                <ListItemWithIcon text="Damage to hunting facilities"/>
                <ListItemWithIcon text="Problems with Taxidermists"/>
                <ListItemWithIcon text="Damage or loss of trophy at an arranged hunt or shoot / shooting expedition"/>
                <ListItemWithIcon text="Matters related to practicing at shooting range"/>
                <ListItemWithIcon text="Matters related to sport shooting events"/>
                <ListItemWithIcon text="Matters related to firearm training sessions"/>
              </List>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <span style={{fontStyle:'italic'}}>General Civil Law Matters</span>
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
              The Insured is further covered for the legal expenses in respect of the following civil law matters in addition to the above-mentioned matters:
              </Typography>
            </AccordionDetails>

            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Drafting of legal opinions"/>
                <ListItemWithIcon text="Unlawful arrest by SAPS and Metro Police"/>
                <ListItemWithIcon text="Malpractice by doctors"/>
                <ListItemWithIcon text="Negligence in hospitals"/>
                <ListItemWithIcon text="Disputes with medical aid companies"/>
                <ListItemWithIcon text="Consumer related legal problems"/>
                <ListItemWithIcon text="Guarantees and Warrantees"/>
                <ListItemWithIcon text="Defective goods"/>
                <ListItemWithIcon text="Problems with insurance companies"/>
                <ListItemWithIcon text="Personal and bodily injuries"/>
                <ListItemWithIcon text="Problems with the neighbours"/>
                <ListItemWithIcon text="Protection orders"/>
              </List>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
                <span style={{fontStyle:'italic'}}>Motor Related Matters – limited to hunting expeditions, sport shooting events and firearm training sessions.</span>
              </Typography>
            </AccordionDetails>


            <AccordionDetails>
              <List>
                <ListItemWithIcon text="Criminal prosecution relating to Motor Vehicle Accidents"/>
                <ListItemWithIcon text="Drunken driving"/>
                <ListItemWithIcon text="Reckless or Negligent driving"/>
                <ListItemWithIcon text="Culpable Homicide as a result of an accident"/>
                <ListItemWithIcon text="RAF (Road Accident Fund) Claims"/>
              </List>
            </AccordionDetails>

            <Divider />
          </Accordion>

          <Accordion
            className={classesFirearmsGuardian.coverAccordion}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                2. Firearm Liability Cover
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <p>
              You are covered for damages (monetary valued claim) that you shall become liable to pay consequent to the death of, or bodily injury to, a third party, or loss or damage to tangible movable or immovable property, which occurs in connection with your use of the firearm of which you are the licenced owner and which results in a claim or claims.
              </p>
            </AccordionDetails>
            <AccordionDetails>
              <p>
              All uses of a are covered, including:
              </p>
            </AccordionDetails>
            <AccordionDetails>
            <List style={{marginTop:'-20px'}}>
              <ListItemWithIcon text="Hunting and organized sport shooting event accidents"/>

              <ListItemWithIcon text="Accidental discharge"/>

              <ListItemWithIcon text="In Self-defence."/>
              </List>
            </AccordionDetails>
            <Divider />
          </Accordion>

          <Accordion
            className={classesFirearmsGuardian.coverAccordion}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                3. Additional Value-added Services: (Costs for the account of
                the Insured)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              <List>
                  <ListItemWithIcon text="Conveyancing – Insuresense will negotiate a discount on attorney’s transfer fees of up to 40% when one of the conveyancers on our network is used by the transacting parties."/>

                  <ListItemWithIcon text="Winding up of deceased estates – Insuresense will negotiate a discount of up to 50% on executor’s fees."/>
              </List>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            className={classesFirearmsGuardian.coverAccordion}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Exclusions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
              <strong> In respect of the Legal Assistance Cover as well as the Liability Cover, this Policy further excludes:</strong>
              </Typography>
                
            </AccordionDetails>
            <AccordionDetails>
            <List>
              <ListItemWithIcon text="
                  Claims instituted after 31 days from the date of the occurrence of an Insured Matter or the occurrence of the cause or grounds of action in respect of that Insured Matter, or after 31 days from the date on which the Insured became aware of such occurrence or a claim against the Insured that falls within the ambit of an Insured Matter."/>

              <ListItemWithIcon text="All matters not specifically included in the definition of Insured Matters."/>
                  
              <ListItemWithIcon text="Insured Matters that occurred outside the borders of Republic of South Africa."/>
              
              <ListItemWithIcon text="This policy of insurance and all legal actions, whether actual or contemplated, brought       against the Insurer and/or Insuresense by the Insured."/>

              <ListItemWithIcon text="All legal actions, whether actual or contemplated, instituted by the Beneficiaries against the Insured by the Beneficiaries."/>

              </List>
            </AccordionDetails>
            <AccordionDetails>
              <Typography>
              <strong>In respect of the Legal Assistance Cover, this Policy further excludes:</strong>
              </Typography>
                
            </AccordionDetails>
            <AccordionDetails>
              <List>
                  <ListItemWithIcon text="The payment of any fine, penalty, tax, levy, any witness fee, any costs pertaining to expert witnesses and expert witness reports, travelling or subsistence costs, any payment or reimbursement of money to the Insured of whatever nature, including costs and expenses in respect of services under this policy provided on a contingency fee basis."/>
                  
                  <ListItemWithIcon text="Indemnification of the Insured against payment of claims or damages awarded against the Insured in legal proceedings."/>
                  
                  <ListItemWithIcon text="Any order in legal proceedings, made in respect of legal expenses, in favour of the Insured’s opponent in such proceedings."/>
                  
                  <ListItemWithIcon text="Litigation and representation services regarding events, proceedings or circumstances which had commenced or occurred or where the cause or grounds of action in respect of those events or circumstances arose prior to inception of the policy or within the 3-month Waiting Period as well as agreements that were entered into by the Insured prior to the inception date of this policy or within the 3-month Waiting Period."/>
                  
                  <ListItemWithIcon text="Matters related to the Insured’s business trade or profession and that are based on the generation of income other than income derived as an employee."/>
                 
                  <ListItemWithIcon text="Matters related to the professional sporting activities of the Insured."/>
                  
                  <ListItemWithIcon text="Legal Services that are purely administrative in nature."/>
                  
                  <ListItemWithIcon text="Any matters relating to:"/>

                  <ListItemWithLists text="Use of an unlicenced firearm."/>
                  
                  <ListItemWithLists text="Firearm licence applications submitted to the SAPS prior to the inception of this policy."/>
                  
                  <ListItemWithLists text="Loss of or damage to any vehicle as a result of an accident."/>
                  
                  <ListItemWithLists text="Events that are, in the sole opinion of Insuresense, trivial and / or should be adjudicated in the small claims Court."/>
                  
                  <ListItemWithLists text="Protection orders regarding disputes between the Insured and immediate family members, his / her spouse or life-partner."/>
              </List>
            </AccordionDetails>

            <AccordionDetails>
              <Typography>
              <strong>In respect of the Firearm Liability Cover, this Policy further excludes any loss or damage caused by:</strong>
              </Typography>
                
            </AccordionDetails>

            <AccordionDetails>
              <List>
            <ListItemWithIcon text="The use of the firearm whilst under the influence of alcohol or drugs."/>

           
            <ListItemWithIcon text="The use of an unlicensed firearm."/>
           
            <ListItemWithIcon text="Any deliberate/intentional unlawful use of a firearm."/>
           
            <ListItemWithIcon text="The playful use of a firearm."/>
           
            <ListItemWithIcon text="Pointing of a firearm at another person or property without good cause."/>
           
            <ListItemWithIcon text="The use of the Insured’s firearm by another person."/>
           
            <ListItemWithIcon text="Use of a firearm whilst the Insured acts as a security officer."/>
           
            <ListItemWithIcon text="Events, proceedings or circumstances, which forms the basis of a claim, which had commenced or occurred or where the cause or grounds of action in respect of those events or circumstances arose prior to inception of the policy or within the 3-month Waiting Period."/>
              </List>
            </AccordionDetails>
           
            </Accordion>
          <Divider />

          <Grid
            item
            align={"right"}
            className={classesFirearmsGuardian.accordionAfter}
          >
            <h5 align="center">
              For inquiries send us an email to
              <Link
                style={{ color: "#64724F" }}
                to="#"
                onClick={(e) => {
                  window.location = "mailto:guardian@narfo.co.za";
                  e.preventDefault();
                }}
              >
                {" "}
                guardian@narfo.co.za.
              </Link>
            </h5>

            <Button
              onClick={(e) => {
                window.location = "mailto:guardian@narfo.co.za";
                e.preventDefault();
              }}
              size="large"
              style={{
                borderColor: "#64724F",
                color: "black",
                marginTop: "20px",
              }}
              variant="outlined"
              color="primary"
            >
              Talk to us
            </Button>
          </Grid>
        </div>
      </Grid>

      {/* Premium information starts */}
      <Grid container spacing={4} style={{paddingTop:'30px'}}>
        <Grid item xs={matches ? 12 : 4}  className={
              matches
                ? classesFirearmsGuardian.coverPremiumMobile
                : classesFirearmsGuardian.coverImageContainer
            }>
        <img
            className={classesFirearmsGuardian.coverImage}
            alt="Firearms Guardian cover"
            width={matches ? "100%": null}
            height={matches ? "100%" : "500px"}
            src={coverPremium}
          />
        </Grid>
        
        <Grid style={{padding:"40px"}} item xs={matches ? 12 : 4}className={classesFirearmsGuardian.coverPremiumOptions}>
        <h3>Option 1:</h3> <br/>
        <h3>Monthly premium:</h3>
        <h1 style={{fontWeight:900}}>R135.00 p/m</h1><br/>

        <h4><strong>This includes:</strong></h4>
        <h5><strong>R100 000</strong> legal cover</h5>
        <h5 style={{marginBottom:'20px'}}><strong>R100 000</strong> liability cover</h5>

        <ColorButton
              align="center"
              variant="contained"
              color="primary"
              disableRipple
              onClick={()=> scroll()}
              className={classes.margin}
            >
              Click here to join
        </ColorButton>
   
        <h5 style={{marginTop:'20px'}} >Our premiums are reviewed annually.</h5>
        </Grid>

        <Grid style={{padding:"40px"}} item xs={matches ? 12 : 4}className={classesFirearmsGuardian.coverPremiumOptions}>
        <h3>Option 2:</h3> <br/>
        <h3>Monthly premium:</h3>
        <h1 style={{fontWeight:900}}>R245.00 p/m</h1><br/>

        <h4><strong>This includes:</strong></h4>
        <h5><strong>R300 000</strong> legal cover</h5>
        <h5 style={{marginBottom:'20px'}}><strong>R300 000</strong> liability cover</h5>

        <ColorButton
              align="center"
              variant="contained"
              color="primary"
              disableRipple
              className={classes.margin}
              onClick={()=> scroll()}
            >
              Click here to join
        </ColorButton>
   
        <h5 style={{marginTop:'20px'}} >Our premiums are reviewed annually.</h5>
        </Grid>
      </Grid>
      {/* Premium info ends  */}
      <img
        className={
          matches
            ? classesFirearmsGuardian.imgMobile
            : classesFirearmsGuardian.joinSectionImage
        }
        alt="Firearms Guardian"
        align={matches ? "right" : "center"}
        src={FirearmsGuardianImageSmall}
      />
      <Grid
        className={
          matches
            ? classesFirearmOwners.firearmOwnersContainerMobile
            : classesFirearmOwners.firearmOwnersContainer
        }
      >
        <h3
          className={
            matches
              ? classesFirearmsGuardian.coverHeaderMobile
              : classesFirearmsGuardian.coverHeader
          }
        >
          Join today!
        </h3>

        <h6>
        We understand how busy you are so we have made it as simple as possible to join <strong>Firearms Guardian.</strong>
        </h6>
        <br/>
        <h4 className={classesFirearmsGuardian.joinText} id="divApplication">
        *Policyholders are registered immediately after the application form is completed and submitted. The policyholder will get confirmation via email or SMS.
        <br/>
        <br/>
        Your Guardian policy will only become active once the first debit order is received.
        </h4>



      {/* Step 1 start */}
      <div hidden ={activeStep == 1} >
        <div className={classesFirearmsGuardian.formSection}>
          <h6 className={classesFirearmsGuardian.setp1Section}><strong>Step 1: Personal Details</strong> <span style={{color:'#D3D3D3'}}> | Step 2 </span> </h6>
          <p style={{fontSize:"12px",color:'#525A64'}}>Electronic debit order agreement</p>
          </div>
          <Divider/>

      <Grid container hidden ={activeStep == 1} spacing={6} id="step1">
        <Grid item xs={6}>
           
        <TextField
          
          label="Name"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          placeholder="name"
          fullWidth
          margin="normal"
          onChange={e => {setName(e.target.value)}}
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Surname"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setLastName(e.target.value)}}
          placeholder="Surname"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="ID number"
          variant="outlined" 
          required
          type="number"
          style={{ margin: 8 }}
          onChange={e => {setIdNo(e.target.value)}}
          placeholder="ID number"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Cell Number"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setCellNumber(e.target.value)}}
          placeholder="Cell number"
          fullWidth
          type="number"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={matches ? 12 : 6}> 
        <TextField
          id="standard-full-width"
          label="Email"
          type="email"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setEmail(e.target.value)}}
          placeholder="Email"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={12}> 
        <TextField
          id="standard-full-width"
          label="Address"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setAddress1(e.target.value)}}
          placeholder="Street Address"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={12}> 
        <TextField
          id="standard-full-width"
          label="Address Line 2"
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setAddress2(e.target.value)}}
          placeholder="Address Line 2"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="City"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setCity(e.target.value)}}
          placeholder="City"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Province"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setProvince(e.target.value)}}
          placeholder="Province"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Postal Code"
          required
          type="number"
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setPostalCode(e.target.value)}}
          placeholder="ZIP or Postal Code"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>
        
        <Grid item xs={6}> 
        <TextField
          id="outlined-select-currency-native"
          select
          fullWidth
          label="Country"
          required
          value={country}
          onChange={handleCountrySelect}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          style={{ margin: 8 }}
        >
          <option value='Afghanistan'>Afghanistan</option>
          <option value='Åland Islands'>Åland Islands</option>
          <option value='Albania'>Albania</option>
          <option value='Algeria'>Algeria</option>
          <option value='American Samoa'>American Samoa</option>
          <option value='Andorra'>Andorra</option>
          <option value='Angola'>Angola</option>
          <option value='Anguilla'>Anguilla</option>
          <option value='Antarctica'>Antarctica</option>
          <option value='Antigua and Barbuda'>Antigua and Barbuda</option>
          <option value='Argentina'>Argentina</option>
          <option value='Armenia'>Armenia</option>
          <option value='Aruba'>Aruba</option>
          <option value='Australia'>Australia</option>
          <option value='Austria'>Austria</option>
          <option value='Azerbaijan'>Azerbaijan</option>
          <option value='Bahamas'>Bahamas</option>
          <option value='Bahrain'>Bahrain</option>
          <option value='Bangladesh'>Bangladesh</option>
          <option value='Barbados'>Barbados</option>
          <option value='Belarus'>Belarus</option>
          <option value='Belgium'>Belgium</option>
          <option value='Belize'>Belize</option>
          <option value='Benin'>Benin</option>
          <option value='Bermuda'>Bermuda</option>
          <option value='Bhutan'>Bhutan</option>
          <option value='Bolivia'>Bolivia</option>
          <option value='Bonaire, Sint Eustatius and Saba'>Bonaire, Sint Eustatius and Saba</option>
          <option value='Bosnia and Herzegovina'>Bosnia and Herzegovina</option>
          <option value='Botswana'>Botswana</option>
          <option value='Bouvet Island'>Bouvet Island</option>
          <option value='Brazil'>Brazil</option>
          <option value='British Indian Ocean Territory'>British Indian Ocean Territory</option>
          <option value='Brunei Darussalam'>Brunei Darussalam</option>
          <option value='Bulgaria'>Bulgaria</option>
          <option value='Burkina Faso'>Burkina Faso</option>
          <option value='Burundi'>Burundi</option>
          <option value='Cambodia'>Cambodia</option>
          <option value='Cameroon'>Cameroon</option>
          <option value='Canada'>Canada</option>
          <option value='Cape Verde'>Cape Verde</option>
          <option value='Cayman Islands'>Cayman Islands</option>
          <option value='Central African Republic'>Central African Republic</option>
          <option value='Chad'>Chad</option>
          <option value='Chile'>Chile</option>
          <option value='China'>China</option>
          <option value='Christmas Island'>Christmas Island</option>
          <option value='Cocos Islands'>Cocos Islands</option>
          <option value='Colombia'>Colombia</option>
          <option value='Comoros'>Comoros</option>
          <option value='Congo, Democratic Republic of the'>Congo, Democratic Republic of the</option>
          <option value='Congo, Republic of the'>Congo, Republic of the</option>
          <option value='Cook Islands'>Cook Islands</option>
          <option value='Costa Rica'>Costa Rica</option>
          <option value='Côte d&#039;Ivoire'>Côte d&#039;Ivoire</option>
          <option value='Croatia'>Croatia</option>
          <option value='Cuba'>Cuba</option>
          <option value='Curaçao'>Curaçao</option>
          <option value='Cyprus'>Cyprus</option>
          <option value='Czech Republic'>Czech Republic</option>
          <option value='Denmark'>Denmark</option>
          <option value='Djibouti'>Djibouti</option>
          <option value='Dominica'>Dominica</option>
          <option value='Dominican Republic'>Dominican Republic</option>
          <option value='Ecuador'>Ecuador</option>
          <option value='Egypt'>Egypt</option>
          <option value='El Salvador'>El Salvador</option>
          <option value='Equatorial Guinea'>Equatorial Guinea</option>
          <option value='Eritrea'>Eritrea</option>
          <option value='Estonia'>Estonia</option>
          <option value='Eswatini (Swaziland)'>Eswatini (Swaziland)</option>
          <option value='Ethiopia'>Ethiopia</option>
          <option value='Falkland Islands'>Falkland Islands</option>
          <option value='Faroe Islands'>Faroe Islands</option>
          <option value='Fiji'>Fiji</option>
          <option value='Finland'>Finland</option>
          <option value='France'>France</option>
          <option value='French Guiana'>French Guiana</option>
          <option value='French Polynesia'>French Polynesia</option>
          <option value='French Southern Territories'>French Southern Territories</option>
          <option value='Gabon'>Gabon</option>
          <option value='Gambia'>Gambia</option>
          <option value='Georgia'>Georgia</option>
          <option value='Germany'>Germany</option>
          <option value='Ghana'>Ghana</option>
          <option value='Gibraltar'>Gibraltar</option>
          <option value='Greece'>Greece</option>
          <option value='Greenland'>Greenland</option>
          <option value='Grenada'>Grenada</option>
          <option value='Guadeloupe'>Guadeloupe</option>
          <option value='Guam'>Guam</option>
          <option value='Guatemala'>Guatemala</option>
          <option value='Guernsey'>Guernsey</option>
          <option value='Guinea'>Guinea</option>
          <option value='Guinea-Bissau'>Guinea-Bissau</option>
          <option value='Guyana'>Guyana</option>
          <option value='Haiti'>Haiti</option>
          <option value='Heard and McDonald Islands'>Heard and McDonald Islands</option>
          <option value='Holy See'>Holy See</option>
          <option value='Honduras'>Honduras</option>
          <option value='Hong Kong'>Hong Kong</option>
          <option value='Hungary'>Hungary</option>
          <option value='Iceland'>Iceland</option>
          <option value='India'>India</option>
          <option value='Indonesia'>Indonesia</option>
          <option value='Iran'>Iran</option>
          <option value='Iraq'>Iraq</option>
          <option value='Ireland'>Ireland</option>
          <option value='Isle of Man'>Isle of Man</option>
          <option value='Israel'>Israel</option>
          <option value='Italy'>Italy</option>
          <option value='Jamaica'>Jamaica</option>
          <option value='Japan'>Japan</option>
          <option value='Jersey'>Jersey</option>
          <option value='Jordan'>Jordan</option>
          <option value='Kazakhstan'>Kazakhstan</option>
          <option value='Kenya'>Kenya</option>
          <option value='Kiribati'>Kiribati</option>
          <option value='Kuwait'>Kuwait</option>
          <option value='Kyrgyzstan'>Kyrgyzstan</option>
          <option value='Lao People&#039;s Democratic Republic'>Lao People&#039;s Democratic Republic</option>
          <option value='Latvia'>Latvia</option>
          <option value='Lebanon'>Lebanon</option>
          <option value='Lesotho'>Lesotho</option>
          <option value='Liberia'>Liberia</option>
          <option value='Libya'>Libya</option>
          <option value='Liechtenstein'>Liechtenstein</option>
          <option value='Lithuania'>Lithuania</option>
          <option value='Luxembourg'>Luxembourg</option>
          <option value='Macau'>Macau</option>
          <option value='Macedonia'>Macedonia</option>
          <option value='Madagascar'>Madagascar</option>
          <option value='Malawi'>Malawi</option>
          <option value='Malaysia'>Malaysia</option>
          <option value='Maldives'>Maldives</option>
          <option value='Mali'>Mali</option>
          <option value='Malta'>Malta</option>
          <option value='Marshall Islands'>Marshall Islands</option>
          <option value='Martinique'>Martinique</option>
          <option value='Mauritania'>Mauritania</option>
          <option value='Mauritius'>Mauritius</option>
          <option value='Mayotte'>Mayotte</option>
          <option value='Mexico'>Mexico</option>
          <option value='Micronesia'>Micronesia</option>
          <option value='Moldova'>Moldova</option>
          <option value='Monaco'>Monaco</option>
          <option value='Mongolia'>Mongolia</option>
          <option value='Montenegro'>Montenegro</option>
          <option value='Montserrat'>Montserrat</option>
          <option value='Morocco'>Morocco</option>
          <option value='Mozambique'>Mozambique</option>
          <option value='Myanmar'>Myanmar</option>
          <option value='Namibia'>Namibia</option>
          <option value='Nauru'>Nauru</option>
          <option value='Nepal'>Nepal</option>
          <option value='Netherlands'>Netherlands</option>
          <option value='New Caledonia'>New Caledonia</option>
          <option value='New Zealand'>New Zealand</option>
          <option value='Nicaragua'>Nicaragua</option>
          <option value='Niger'>Niger</option>
          <option value='Nigeria'>Nigeria</option>
          <option value='Niue'>Niue</option>
          <option value='Norfolk Island'>Norfolk Island</option>
          <option value='North Korea'>North Korea</option>
          <option value='Northern Mariana Islands'>Northern Mariana Islands</option>
          <option value='Norway'>Norway</option>
          <option value='Oman'>Oman</option>
          <option value='Pakistan'>Pakistan</option>
          <option value='Palau'>Palau</option>
          <option value='Palestine, State of'>Palestine, State of</option>
          <option value='Panama'>Panama</option>
          <option value='Papua New Guinea'>Papua New Guinea</option>
          <option value='Paraguay'>Paraguay</option>
          <option value='Peru'>Peru</option>
          <option value='Philippines'>Philippines</option>
          <option value='Pitcairn'>Pitcairn</option>
          <option value='Poland'>Poland</option>
          <option value='Portugal'>Portugal</option>
          <option value='Puerto Rico'>Puerto Rico</option>
          <option value='Qatar'>Qatar</option>
          <option value='Réunion'>Réunion</option>
          <option value='Romania'>Romania</option>
          <option value='Russia'>Russia</option>
          <option value='Rwanda'>Rwanda</option>
          <option value='Saint Barthélemy'>Saint Barthélemy</option>
          <option value='Saint Helena'>Saint Helena</option>
          <option value='Saint Kitts and Nevis'>Saint Kitts and Nevis</option>
          <option value='Saint Lucia'>Saint Lucia</option>
          <option value='Saint Martin'>Saint Martin</option>
          <option value='Saint Pierre and Miquelon'>Saint Pierre and Miquelon</option>
          <option value='Saint Vincent and the Grenadines'>Saint Vincent and the Grenadines</option>
          <option value='Samoa'>Samoa</option>
          <option value='San Marino'>San Marino</option>
          <option value='Sao Tome and Principe'>Sao Tome and Principe</option>
          <option value='Saudi Arabia'>Saudi Arabia</option>
          <option value='Senegal'>Senegal</option>
          <option value='Serbia'>Serbia</option>
          <option value='Seychelles'>Seychelles</option>
          <option value='Sierra Leone'>Sierra Leone</option>
          <option value='Singapore'>Singapore</option>
          <option value='Sint Maarten'>Sint Maarten</option>
          <option value='Slovakia'>Slovakia</option>
          <option value='Slovenia'>Slovenia</option>
          <option value='Solomon Islands'>Solomon Islands</option>
          <option value='Somalia'>Somalia</option>
          <option value='South Africa' selected='selected'>South Africa</option>
          <option value='South Georgia'>South Georgia</option>
          <option value='South Korea'>South Korea</option>
          <option value='South Sudan'>South Sudan</option>
          <option value='Spain'>Spain</option>
          <option value='Sri Lanka'>Sri Lanka</option>
          <option value='Sudan'>Sudan</option>
          <option value='Suriname'>Suriname</option>
          <option value='Svalbard and Jan Mayen Islands'>Svalbard and Jan Mayen Islands</option>
          <option value='Sweden'>Sweden</option>
          <option value='Switzerland'>Switzerland</option>
          <option value='Syria'>Syria</option>
          <option value='Taiwan'>Taiwan</option>
          <option value='Tajikistan'>Tajikistan</option>
          <option value='Tanzania'>Tanzania</option>
          <option value='Thailand'>Thailand</option>
          <option value='Timor-Leste'>Timor-Leste</option>
          <option value='Togo'>Togo</option>
          <option value='Tokelau'>Tokelau</option>
          <option value='Tonga'>Tonga</option>
          <option value='Trinidad and Tobago'>Trinidad and Tobago</option>
          <option value='Tunisia'>Tunisia</option>
          <option value='Turkey'>Turkey</option>
          <option value='Turkmenistan'>Turkmenistan</option>
          <option value='Turks and Caicos Islands'>Turks and Caicos Islands</option>
          <option value='Tuvalu'>Tuvalu</option>
          <option value='Uganda'>Uganda</option>
          <option value='Ukraine'>Ukraine</option>
          <option value='United Arab Emirates'>United Arab Emirates</option>
          <option value='United Kingdom'>United Kingdom</option>
          <option value='United States'>United States</option>
          <option value='Uruguay'>Uruguay</option>
          <option value='US Minor Outlying Islands'>US Minor Outlying Islands</option>
          <option value='Uzbekistan'>Uzbekistan</option>
          <option value='Vanuatu'>Vanuatu</option>
          <option value='Venezuela'>Venezuela</option>
          <option value='Vietnam'>Vietnam</option>
          <option value='Virgin Islands, British'>Virgin Islands, British</option>
          <option value='Virgin Islands, U.S.'>Virgin Islands, U.S.</option>
          <option value='Wallis and Futuna'>Wallis and Futuna</option>
          <option value='Western Sahara'>Western Sahara</option>
          <option value='Yemen'>Yemen</option>
          <option value='Zambia'>Zambia</option>
          <option value='Zimbabwe'>Zimbabwe</option>
        </TextField>
        </Grid>

      </Grid>
      </div>
      {/* Step 1 end */}

    {/* Step 2 beginin */}
     <div hidden ={activeStep == 0}>
      <h6 className={classesFirearmsGuardian.formSection}><span style={{color:'#D3D3D3'}}>Step 1 | </span><strong> Step 2: Bank Account Details</strong> </h6>
          <p style={{fontSize:"12px",color:'#525A64'}}>Please provide your bank account information.</p>
          <p style={{fontSize:"12px",color:'#525A64'}}><strong>PLEASE NOTE: NARFO</strong>  does not store any of your banking details for the purposes of signing up for this insurance product.
          <br/>
          All payments and debits are done through <strong> Insuresense Consultants (Pty) Ltd</strong> or its agent/s
          </p>
      <Grid container hidden ={activeStep == 0} spacing={6} id="step2"> 
      <Grid item xs={6}> 
        <TextField
          inputRef={ accountHolder.length > 3 ? null :  (input => input && input.focus())}
          autoFocus = {activeStep == 0 ? false : true}
          id="standard-full-width"
          label="Account Holder"
          variant="outlined" 
          required
          style={{ margin: 8 }}
          onChange={e => {setAccountHolder(e.target.value)}}
          placeholder="Account Holder"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>
        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Bank Name"
          required
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setBankName(e.target.value)}}
          placeholder="Bank Name"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="standard-full-width"
          label="Account Number"
          variant="outlined" 
          required
          type='number'
          style={{ margin: 8 }}
          onChange={e => {setAccountNumber(e.target.value)}}
          placeholder="Account Number"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>

        <Grid item xs={6}> 
        <TextField
          id="outlined-select-currency-native"
          select
          fullWidth
          label="Account Type"
          required
          value={accountType}
          onChange={handleAccountType}
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          style={{ margin: 8 }}
        >
          <option value='Current(Cheque)'>Current(Cheque)</option>
          <option value='Savings'>Savings</option>
          <option value='Transmission'>Transmission</option>
        
        </TextField>
        </Grid>

      <Grid item xs={12} style={{ margin: 8 }}> 
        <p style={{fontSize:"14px",color:'#525A64'}}><strong>Agreement</strong></p>
        <Divider/>
      </Grid>

      <Grid item xs={matches ? 12 : 6}> 
        <TextField
          id="outlined-select-currency-nativebhgh"
          select
          fullWidth
          label="Contract Amount"
          required
          value={contractAmount}
          onChange={handleContractAmountChange}
          SelectProps={{
            native: true,
          }}
          helperText="I/we agree that the first payment instruction will be issued and delivered on:"
          variant="outlined"
          style={{ margin: 8 }}
        >
          <option value="Firearms Guardian - Option 1 : R135.00 p/m">Firearms Guardian - Option 1 : R135.00 p/m</option>
          <option value="Firearms Guardian - Option 2 : R245.00 p/m">Firearms Guardian - Option 2 : R245.00 p/m</option>
        
        </TextField>
      </Grid>

      <Grid item xs={matches ? 12 : 6}> 
        <TextField
          id="outlined-select-currency-native"
          select
          fullWidth
          label="Debit Date (Your 1st debit order will be your policy inception)"
          required
          value={debitOrderDate}
          onChange={handleDebitOrderDateChange}
          SelectProps={{
            native: true,
          }}
          helperText="Your Guardian policy will only become active once the first debit order is received."
          variant="outlined"
          style={{ margin: 8 }}
        >
          <option value="1st" selected="selected">1st</option>
          <option value="7th">7th</option>
          <option value="15th">15th</option>
          <option value="25th">25th</option>
          <option value="Last day">Last day</option>
        
        </TextField>
      </Grid>


      <Grid item xs={12} style={{ margin: 8 }}> 
          <p className={classesFirearmsGuardian.textColor}><strong>{firstAgreementText}</strong> </p>
        <FormControlLabel
            value={firstAgreement}
            control={<Checkbox color="primary" checked={firstAgreement} onChange={()=> {setFirstAgreement(!firstAgreement)}}/>}
            label="Agree"
            labelPlacement="end"
          />
      </Grid>
      
      <Grid item xs={12} style={{ marginLeft: 8 }}> 
          <p className={classesFirearmsGuardian.textColor}><strong>{secondAgreementText} </strong> </p>
        <FormControlLabel
            value={secondAgreement}
            control={<Checkbox color="primary" checked={secondAgreement} onChange={()=> {setSecondAgreement(!secondAgreement)}}/>}
            label="Agree"
            labelPlacement="end"
          />
      </Grid>

      <Grid item xs={12} style={{ marginLeft: 8 }}> 
         <p className={classesFirearmsGuardian.textColor}><strong>{thirdAgreementText}</strong> </p>
        <FormControlLabel
            value={thirdAgreement}
            control={<Checkbox color="primary" checked={thirdAgreement} onChange={()=> {setThirdAgreement(!thirdAgreement)}} />}
            label="Agree"
            labelPlacement="end"
          />
      </Grid>

      <Grid item xs={matches ? 12 : 6}> 
        <TextField
          id="standard-full-width"
          label="Promo Code"
          variant="outlined" 
          style={{ margin: 8 }}
          onChange={e => {setPromoCode(e.target.value)}}
          placeholder="If no promo code, leave blank"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        </Grid>


        <Grid item xs={12} style={{ marginLeft: 8,marginBottom:10}}> 
        <ReCAPTCHA
          sitekey={FRONT === "http://localhost:3000/" ? 
                            props.recatchaKey.localHostKey : FRONT === "http://qaportal.narfo.co.za/" ?
                            props.recatchaKey.narfoQAKey : props.recatchaKey.narfoProductionKey}
          onChange={activateButton}
          onExpired={deactivateButton}
        />
        </Grid>

        
      </Grid> 
      </div>
      {/* Step 2 end */}
      <Divider />
      <Grid container spacing={6} style={{marginTop:'20px'}}>
      <Grid item xs={6}> 
        <ColorButton
              onClick={handleBack}
              disabled={activeStep === 0} 
              fullWidth
              align="center"
              variant="contained"
              color="primary"
              disableRipple
              className={classes.margin}
            >
              Previous
        </ColorButton>
        </Grid>

        <Grid item xs={6}> 

        <ColorButton
              onClick={()=>handleNext(activeStep)}
              fullWidth
              type='submit'
              align="center"
              variant="contained"
              color="primary"
              disableRipple
              className={classes.margin}
              disabled={ activeStep == 1 ? !isActive : false}
            >
              {activeStep === steps.length - 1 ? 'I accept the agreement' : 'Next'}
        </ColorButton>

        </Grid>
                
        
      </Grid>
      </Grid>

      {/* Insurense banner */}
      <Grid container spacing={4} className={matches ? classesFirearmsGuardian.insuresenseMobile : classesFirearmsGuardian.insuresense}>
        <Grid item xs={matches ? 12 : 4} >
        <img
        alt="insuresense image"
        style={{marginTop:'-30px'}}
        align={matches ? "center" : "right"}
        src={insuresense}
      />
        </Grid>
        
        <Grid style={{padding:"20px"}} item xs={matches ? 12 : 4}>
            Insuresense Consultants (Pty) Ltd is an authorised financial services provider FSP 47115
        </Grid>

        <Grid style={{padding:"20px"}} item xs={matches ? 12 : 4}>
        Tel: <a style={{color:'blue'}} href="tel:0126652500">012 665 2500</a> | Mobile: <a style={{color:'blue'}} href="tel:0823779172">082 377 9172</a><br/>
        1524 Swart Renoster Street |  The Wilds  |  De Villebois Road  |  Pretoriuspark  |  Pretoria
        </Grid>
      </Grid>
      {/* Insurense banna ends  */}

      {/* Generic banner */}
      <Grid container spacing={4} className={matches ? classesFirearmsGuardian.genericSectionMobile : classesFirearmsGuardian.genericSection}>
        <Grid item xs={matches ? 12 : 4} >
        <img
        alt="insuresense image"
        width={matches ? "60%" :"100"}
        style={ matches ? {marginLeft:'17%'}: {marginTop:'-30px'}}
        align={matches ? "center" : "right"}
        src={GenericImage}
      />
        </Grid>
        
        <Grid style={{padding:"10px"}} item xs={matches ? 12 : 6}>
          Firearms Guardian is a Short-Term insurance product, administered by Insuresense Consultants (Pty) Ltd, an Authorised Financial Services Provider (FSP 47115), Underwritten by GENRIC Insurance Company Limited (GENRIC), (FSP 43638), an Authorised Financial Services Provider and Licenses non-life Insurer.
        </Grid>
      </Grid>
      {/* Generic banna ends  */}
    </Grid>
  );
}

const mapStatetoProps = state => ({
  recatchaKey: state.RecaptchaKeyReducer
});


export default connect(mapStatetoProps, null)(withRouter(FirearmsGuardian));