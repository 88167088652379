
export const QuestionTypes = {
  SINGLE_CHOICE: 0,
  MULTIPLE_CHOICE: 1,
  MATCH_SORT_NUMBERS: 2,
  MATCH_SORT_LETTERS: 3
}

export function getInstruction(typeOfQuestion) {
  switch (typeOfQuestion) {
    case QuestionTypes.SINGLE_CHOICE:
      return 'Select radio button to mark option as an answer';
    case QuestionTypes.MULTIPLE_CHOICE:
      return 'Select check box to mark option as an answer';
    case QuestionTypes.MATCH_SORT_NUMBERS:
      return 'Select numbers to order the options';
    case QuestionTypes.MATCH_SORT_LETTERS:
      return 'Select letters to order the options'
    default:
      return '';
  }
}

export function getTotalScore(options, questionType) {
  switch (questionType) {
    case QuestionTypes.SINGLE_CHOICE:
    case QuestionTypes.MULTIPLE_CHOICE:
      return options.reduce((a, b) => {
        if (b.score > 0) {
          return a + b.score;
        } else {
          return a;
        }
      }, 0);
    case QuestionTypes.MATCH_SORT_NUMBERS:
    case QuestionTypes.MATCH_SORT_LETTERS:
      return msNumbersScoreAlgorithm(options);
    default:
      return 0;
  }
}

function msNumbersScoreAlgorithm(options) {
  let sum = 0;
  options.forEach((opt, key) => {
    if (key === 0) {
      sum = opt.score;
    }
    else {
      if (key % 2 === 1) {
        sum -= opt.score;
      }
      else {
        sum *= opt.score;
      }
    }
  });
  return sum;
}

export function isValid(questionType, options) {
  switch (questionType) {
    case QuestionTypes.SINGLE_CHOICE:
    case QuestionTypes.MULTIPLE_CHOICE:
      if (options.find(val => val.score === 1)) {
        return true;
      }
      break;
    case QuestionTypes.MATCH_SORT_NUMBERS:
      return matchSortValidate(options, 1);
    case QuestionTypes.MATCH_SORT_LETTERS:
      return matchSortValidate(options, 65);
    default:
      break;
  }
  return false;

  function matchSortValidate(options, offset) {
    let duplicates = new Array(options.length).fill(0);
    options.forEach(val => {
      duplicates[val.score - offset] += 1;
    });
    const sum = duplicates.reduce((a, b) => {
      return a + b;
    }, 0);
    if (sum === options.length) {
      return true;
    }
    return false;
  }
}
