import React, { Component } from 'react';

export default class extends Component {
    render() {
        return (
            <div className="container">

                <React.Fragment>
                    <div className="col">
                        <p className="col-12 mb-0">Do you currently hold a dedicated hunter status with any other accreddited association?</p>
                    </div>
                    <div className="row form-check-inline">
                        <div className="col">
                            <label className="form-check-label ml-5 mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    value="Yes"
                                    checked={this.props.DSApplicationState.Ods === "Yes"}
                                    name="accreditation"
                                    onChange={(e) => {
                                        this.setState({ dedicatedStatus: e.target.value });
                                        this.props.setDSVariables("Ods", e.target.value);
                                    }}
                                />
                                Yes
                        </label>
                        </div>
                        <label className="form-check-label ml-3 mb-3">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="accreditation"
                                value="No"
                                checked={this.props.DSApplicationState.Ods === "No"}
                                onChange={(e) => {
                                    this.setState({ dedicatedStatus: e.target.value })
                                    this.props.setDSVariables("Ods", e.target.value);
                                }}
                            />
                            No
                    </label>
                    </div>
                    {this.props.DSApplicationState.Ods === "Yes" &&
                        <React.Fragment>
                            <div className="form-group mr-2">
                                <label htmlFor="appName" className="">
                                    Association Name
                            </label>
                                <input
                                    type="text"
                                    id="appName"
                                    value={this.props.DSApplicationState.OdsAssociationName}
                                    placeholder="Enter Association Name"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("OdsAssociationName", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="far" className="">
                                    Association FAR Number
                            </label>
                                <input
                                    type="text"
                                    id="far"
                                    value={this.props.DSApplicationState.OdsFarNo}
                                    placeholder="Enter FAR Number"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("OdsFarNo", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="dsNo" className="">
                                    Dedicated Status Number
                            </label>
                                <input
                                    type="text"
                                    id="dsNo"
                                    value={this.props.DSApplicationState.OdsDsNo}
                                    placeholder="Enter Status Number"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("OdsDsNo", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="dsDate" className="">
                                    Expiry Date of Membership
                            </label>
                                <input
                                    type="date"
                                    id="dsDate"
                                    value={this.props.DSApplicationState.OdsExpiryDate}
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("OdsExpiryDate", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="dsDocs" className="">
                                    Document(s)
                            </label>
                                <input
                                    id="dsDocs"
                                    type="file"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("OdsAttachment", e.target.files[0])}
                                />
                            </div>
                        </React.Fragment>
                    }

                    <div className="col mb-2">
                        <p className="col-12 ">Are you or have you been registered as a Proffesional Hunter?</p>
                    </div>
                    <div className="row form-check-inline mb-3">
                        <div className="col">
                            <label className="form-check-label ml-5 -mb-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    value="Yes"
                                    name="dhReg"
                                    checked={this.props.DSApplicationState.Ph === "Yes"}
                                    onChange={(e) => {
                                        this.setState({ proffessionalHunter: e.target.value });
                                        this.props.setDSVariables("Ph", e.target.value);
                                    }}
                                />
                                Yes
                        </label>
                        </div>
                        <label className="form-check-label ml-3">
                            <input
                                type="radio"
                                className="form-check-input"
                                name="dhReg"
                                value="No"
                                checked={this.props.DSApplicationState.Ph === "No"}
                                onChange={(e) => {
                                    this.setState({ proffessionalHunter: e.target.value })
                                    this.props.setDSVariables("Ph", e.target.value);
                                }}
                            />
                            No
                    </label>
                    </div>
                    {this.props.DSApplicationState.Ph === "Yes" &&
                        <React.Fragment>
                            <div className="form-group mr-2">
                                <label htmlFor="regNo" className="">
                                    Registration Number
                            </label>
                                <input
                                    type="text"
                                    required
                                    id="regNo"
                                    value={this.props.DSApplicationState.PhRegNo}
                                    className="form-control mr-lg-3"
                                    placeholder="Enter Registration Number"
                                    onChange={(e) => this.props.setDSVariables("PhRegNo", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="assoc" className="">
                                    Association
                            </label>
                                <input
                                    id="assoc"
                                    type="text"
                                    value={this.props.DSApplicationState.PhAssociation}
                                    className="form-control mr-lg-3"
                                    placeholder="Enter Association Name"
                                    onChange={(e) => this.props.setDSVariables("PhAssociation", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="proviince" className="">
                                    Province in which you May Operate
                            </label>
                                <input
                                    type="text"
                                    id="proviince"
                                    value={this.props.DSApplicationState.PhProvince}
                                    placeholder="Enter Province"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("PhProvince", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="phDate" className="">
                                    Expiry Date of your Permit
                            </label>
                                <input
                                    type="date"
                                    value={this.props.DSApplicationState.PhExpiryDate}
                                    id="phDate"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("PhExpiryDate", e.target.value)}
                                />
                            </div>
                            <div className="form-group mr-2">
                                <label htmlFor="phDocs" className="">
                                    Document(s)
                            </label>
                                <input
                                    id="phDocs"
                                    type="file"
                                    className="form-control mr-lg-3 "
                                    onChange={(e) => this.props.setDSVariables("PhAttachment", e.target.files[0])}
                                />
                            </div>
                        </React.Fragment>
                    }
                    <React.Fragment>
                        <div className="col">
                            <p className="col-12 mb-0">
                                Have you completed the practical and theoretical assessment?
            </p>
                        </div>
                        <div className="row form-check-inline">
                            <div className="col">
                                <label className="form-check-label ml-5">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        value="Yes"
                                        checked={this.props.DSApplicationState.Assessments === "Yes"}
                                        name="assessmentComplete"
                                        onChange={e =>
                                            this.props.setDSVariables("Assessments", e.target.value)
                                        }
                                    />
                Yes
              </label>
                            </div>
                            <label className="form-check-label ml-3">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    name="assessmentComplete"
                                    checked={this.props.DSApplicationState.Assessments === "No"}
                                    value="No"
                                    onChange={e =>
                                        this.props.setDSVariables("Assessments", e.target.value)
                                    }
                                />
              No
            </label>
                        </div>
                    </React.Fragment>
                </React.Fragment>
            </div>
        )
    }
}
