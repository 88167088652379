import * as actyionTypes from "../actions/actionTypes";

const initialState = {
  dedicatedStatus: {
    Title: "Dedicated Status",
    Page: "Home",
    Application: [],
    DedicatedStatusApplication: {},
    rejectType: ""
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actyionTypes.REDIRECT_TO_DS_LIST:
      return {
        ...state,
        ...action.dedicatedStatusReducer
      };
    case actyionTypes.UPDATE_DEDICATED_STATUS:
      const newDedicatedStatus = {
        ...state.dedicatedStatus,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        dedicatedStatus: newDedicatedStatus
      };
    case actyionTypes.REJECT_DS_TYPE:
      return {
        ...state,
      rejectType: !action.rejectType?null:action.rejectType
      }
    default:
      return state;
  }
};

export default reducer;
