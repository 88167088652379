import React, { Component } from 'react';
import { SET_FIREARM_TYPES_LOOKUP, SET_TARGET_FIREARM_TYPES} from '../../store/FireArmType/actionType';
import { connect } from 'react-redux';
import FireArmAction from '../../store/FireArmType/actions';
import FireArmTypeService from '../../services/firearmType.service';
import { targetDownsLoadLevels } from '../../shared/constants';
import {
  SubtitleBar,
  Loader
} from '../../components/index';
import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FireArmTypeLookup from './WPFireArmTypeLookup';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  pointer: {
    cursor: 'pointer'
  }
});

class WPFireArmTypes extends Component {
  constructor(props) {
    super(props);
    this.fireArmTypeService = FireArmTypeService();
    this.fireArmLookUpActions_ = this.props._fireArmLookUpActions(this.fireArmTypeService);
    this.state = {
      fireArmTypes: props.fireArmTypes,
      isFireArmTypeLookup: false,
      activatyLookups: this.props.activatyLookups,
      isLoader: false
    };
  }

  componentDidMount() {
    this.props.isFirearmTypes(this.state.fireArmTypes);
    this.fireArmLookUpActions_.fetchFireArmTypes(this.state.fireArmTypes);
  }

  render() {
    const { classes } = this.props;
    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      <React.Fragment>
        {this.props.level === targetDownsLoadLevels.FIREARM_TYPES && (<SubtitleBar targetsDescription title={this.props.level}></SubtitleBar>)}
        {this.state.isLoader && (<Loader />)}
        <Grid container justify="center">
          {this.props.level === targetDownsLoadLevels.FIREARM_TYPES && (<Grid item container xs={12} md={12} spacing={4} alignItems="center">
            {this.props.fireArmTypes.length === 0 ? <Grid item>No firearm types available</Grid> : null}
            {this.props.fireArmTypes.map((item, index) => (
              <Grid key={index} item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
                      <Grid item xs={10}>
                        <Typography variant={isMobile?"h6":"h5"} style={{color:"#4F36A6"}}>
                          {item.fireArmType1}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.pointer}
                          onClick={() => {
                            this.setState({isLoader:true});
                            this.fireArmLookUpActions_.fetchFireArmTypesLookUpById(item.fireArmID).then(() => this.setState({ isFireArmTypeLookup: true, isLoader:false }));
                          }}
                        >
                          VIEW
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>)}
        </Grid>
        {this.state.isFireArmTypeLookup && (<FireArmTypeLookup />)}
      </React.Fragment>
    );
  }
}

const initialiseFireArmLookUpActions = dispatch => (fireArmTypeService) => {
  const action = FireArmAction(fireArmTypeService);
  return {
    fetchFireArmTypesLookUpById: (id) => dispatch(action.fetchFireArmTypesLookUpById(id)),
    fetchFireArmTypes: (firearmTypes) => dispatch(action.fetchFireArmTypes(firearmTypes)),
  };
};


const mapStateToProps = state => ({
  activatyLookups: state.fireArmTypeTargets.activatyLookups,
  fireArmTypes: state.fireArmTypeTargets.fireArmTypes,
  level: state.fireArmTypeTargets.level,
  fireArmTypesLookups: state.fireArmTypeTargets.fireArmTypesLookups
});

const mapDispatchToProps = dispatch => ({
  onFireArmLookUp: (fireArmLookUp) =>
    dispatch({
      type: SET_FIREARM_TYPES_LOOKUP,
      payload: fireArmLookUp,
    }),
  _fireArmLookUpActions: initialiseFireArmLookUpActions(dispatch),
  isFirearmTypes: (fireArmTypes) =>
    dispatch({
      type: SET_TARGET_FIREARM_TYPES,
      payload: fireArmTypes
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WPFireArmTypes));