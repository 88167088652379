import React, { Component } from "react";
import axios from "axios";
import { BASE } from "../../API/APIendpoint";

class DedicatedHunterMembers extends Component {
  constructor() {
    super();
    this.state = {
      dhDetails: [{}]
      // dhDetails2: []
    };
  }
  componentWillMount() {
    axios
      .get(BASE +"/api/Member/all/DHMembers",{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(response => {
        this.setState({
          dhDetails: response.data
        });
      });
  }

  render() {
    return (
      <div>
        <ul>
          {this.state.dhDetails.map((details, index) => (
            <li key={index}>
              {details.result
                ? `${details.result.value.members.memNo}  
                ${details.result.value.members.firstname}  
                ${details.result.value.members.surname}`
                : null}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default DedicatedHunterMembers;
