import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { BASE, GET_MEMBER } from "../../../API/APIendpoint";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import Folder from "@material-ui/icons/Folder";
import FolderOpen from "@material-ui/icons/FolderOpen";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import HighlightOff from "@material-ui/icons/HighlightOff";
import HelpOutline from "@material-ui/icons/HelpOutline";
import GenericHeader from "../../../utils/GenericHeader";
import { MEMBER_ASSESSMENT,QUESTION } from "../LMSAPIEndPoints";
import { DateFind } from "../../loggeddInUser/membershipCard/MainMembershipCard";
import jsPDF from "jspdf";
import Axios from "axios";
export class Lms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: {},
      member: [],
      courseResult: 0,
      certificates: [],
      PDFSign: "",
      pdflogo: "",
      nnfo: "",
      courseTitle: "",
      date: ""
    };
  }
  componentDidMount() {
    this.getDate();
    fetch(MEMBER_ASSESSMENT + "/" + this.props.userDetails.memberNumber,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(res => {
        this.props.onQuizCompletionChange(res.data);
        let certificates = {};
        this.props.assesmentList.map(ass => {
          return certificates[`${ass.assesmentId}`] = [];
        });
        this.props.lessonList.map(lesson => {
          let arr = certificates[`${lesson.assesmnetId}`];
          const score = res.data.find(
            d => d.courseMaterialId === lesson.pkCourseId
          );
          arr.push(parseInt(score ? score.completion : 0));
          return certificates[`${lesson.assesmnetId}`] = arr;
        });
        this.props.onCertificatesUpdate(certificates);
      });
  }
  componentWillMount() {
    fetch(BASE + GET_MEMBER + this.props.userDetails.memberNumber, {
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(json => {
        this.setState({
          member: json.members
        });
      });
  }
  getMemberResultForThisCourse = id => {
    const quiz = this.props.quizCompletion.find(q => q.courseMaterialId === id);
    return quiz ? quiz.completion : 0;
  };
  handleClick = assesment => {
    var open = this.state.open;
    open[`${assesment.assesmentId}isOpen`] = !open[
      `${assesment.assesmentId}isOpen`
    ];
    this.setState({ isOpen: open });
    this.props.onAssesmentChange(assesment);
  };
  displayLessons = assesmnetId => {
    if (this.props.sectionTitle === "Course Learning Material") {
      var list = this.props.lessonList.filter(lesson => {
        return lesson.assesmnetId === assesmnetId;
      });
      return (
        <div className="row ml-4">
          {list.map((lesson, index) => (
            <div
              key={index}
              className="col-12 h7 p-1 border-bottom"
              style={{ cursor: "pointer" }}
              onMouseDown={() => {
                this.props.onLessonChange(lesson);
                this.props.onCurrentLMSTabChange(2);
              }}
            >
              {lesson.title} Course Material
            </div>
          ))}
        </div>
      );
    } else if (this.props.sectionTitle === "Course Assessment Quizzes") {
      var secondList = this.props.lessonList.filter(lesson => {
        return lesson.assesmnetId === assesmnetId;
      });
      return (
        <div className="row ml-4">
          {secondList.map((lesson, index) => (
            <div key={index} className="col-12 h7 p-1 border-bottom">
              <div className="row pr-2">
                <div className="col-2 p-0">
                  {this.getMemberResultForThisCourse(lesson.pkCourseId) >=
                  70 ? (
                    <CheckCircleOutline
                      style={{
                        width: "45px",
                        height: "30px",
                        color: "green"
                      }}
                    />
                  ) : this.getMemberResultForThisCourse(lesson.pkCourseId) <
                    70 ? (
                    <HighlightOff
                      style={{
                        width: "45px",
                        height: "30px",
                        color: "red"
                      }}
                    />
                  ) : (
                    <HelpOutline
                      style={{
                        width: "45px",
                        height: "30px",
                        color: "orange"
                      }}
                    />
                  )}
                </div>
                <div className="col-7 col-sm-7 p-0">{lesson.title} Quiz</div>
                <div className="col-3 col-sm-3 p-0 text-right">
                  {this.getMemberResultForThisCourse(lesson.pkCourseId) >=
                  70 ? (
                    <div className="text-success text-center">
                      {this.getMemberResultForThisCourse(lesson.pkCourseId) +
                        "%"}
                    </div>
                  ) : (
                    <Fragment>
                      <div className="text-success d-inline-block text-center col-xs-6 font-weight-bold text-danger">
                        {this.getMemberResultForThisCourse(lesson.pkCourseId) +
                          "%"}
                      </div>
                      <div
                        className=" bg-dark d-inline-block text-white text-center p-2 rounded col-xs-6 ml-1"
                        onClick={() => {
                          fetch(QUESTION + "/" + lesson.pkCourseId)
                            .then(res => res.json())
                            .then(res => {
                              const questions = Array.from(res.data);
                              this.props.onQuizzesChange(questions);
                              this.props.onLessonChange(lesson);
                              this.props.onCurrentLMSTabChange(3);
                              Axios.get(BASE + "api/CourseMaterial/getModuleID/" + lesson.pkCourseId,{
                                headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
                                .then(res => {
                                  this.props.onModuleIdTrack(res.data.moduleId);
                                  Axios.get(BASE + "api/CourseModule/getModuleName/" + res.data.moduleId,{
                                    headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
                                  .then(resp => this.props.onCourseModeleNameUpdate(resp.data.moduleName))
                                })
                            })
                            .catch(error => {
                              this.props.onQuizzesChange([]);
                            });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Take Quiz
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
    }
  };
  hasCertificate = courseId => {
    const scores = this.props.certificates[`${courseId}`];

    try {
      const sum = scores.reduce((a, b) => {
        return a + b;
      }, 0);

      return sum / scores.length >= 70;
    } catch (error) {
      return false;
    }
  };
  getDate = () => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    let today = new Date();
    let date =
      today.getDate() +
      " " +
      months[parseInt(today.getMonth())] +
      " " +
      today.getFullYear();
    this.setState({ date });
  };
  render() {
    let list = this.props.assesmentList;
    if (this.props.sectionTitle === "Course Learning Material") {
      list = list.filter(l => l.type === 2);
    } else {
      list = list.filter(l => l.type === 1);
    }
    list = list.filter(l => l.hasChildren);
    const print = () => {
      let member = this.state.member;
      const pdf = new jsPDF('p', 'mm', [270, 220]);
      pdf.addImage(this.props.base64Images.nnfo, "JPEG", 85, 12);
      pdf.setTextColor(0, 0, 0);
      pdf.setFont("times");
      pdf.setFontStyle("bold");
      pdf.setFontSize(14);
      pdf.text(53, 60, "National Association of Responsible Firearm Owners");
      pdf.setTextColor(0, 102, 51);
      pdf.setFontSize(28);
      pdf.text(27, 80, "CERTIFICATE OF ACHIEVEMENT");
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(20);
      pdf.text(83, 95, "This certifies that");
      pdf.setTextColor(0, 0, 255);
      pdf.setFontSize(14);
      pdf.text(70, 110, "Member Names:");
      pdf.text(70, 115, "ID No:");
      pdf.setFontStyle("bold");
      pdf.text(
        120,
        110,
        member.firstname + " " + member.surname
      );
      pdf.text(120, 115, member.idno);
      pdf.setTextColor(0, 0, 0);
      pdf.setFontSize(20);
      pdf.text(35, 135, "Has successfully completed the following course");
      pdf.setTextColor(0, 51, 102);
      pdf.setFontSize(30);
      this.state.courseTitle === "Dedicated Hunter Assessment Modules"
        ? pdf.text(65, 150, this.state.courseTitle.substring(9, 27))
        : pdf.text(45, 150, this.state.courseTitle.substring(9, 34));
      pdf.addImage(this.props.base64Images.pdfSign, "JPEG", 26, 165, 60, 45);
      pdf.addImage(this.props.base64Images.pdflogo, "JPEG", 135, 165, 50, 50);
      pdf.setFontSize(8);
      pdf.setTextColor(0, 0, 0);
      pdf.text(36, 210, "Printed on:");
      pdf.text(50, 210, DateFind(this.state.date));
      pdf.setTextColor(126, 126, 126);
      pdf.text(30, 220, "14 Phesante Close, Graanendal, Durbanville, 7551");
      pdf.text(30, 225, "http://www.narfo.co.za");
      pdf.setTextColor(0, 0, 0);
      pdf.text(140, 225, "NARFO Pty (Ltd) 2012/086864/07");
      pdf.save(
        this.state.courseTitle === "Dedicated Hunter Assessment Modules"
          ? "HunterAssesement.pdf"
          : "SportShooterAssessment.pdf"
      );
    };
    return (
      <div>
        <div className="container">
          <GenericHeader
            title={this.props.sectionTitle}
            showBackButton
            backFunction={() => this.props.onCurrentLMSTabChange(0)}
          />
        </div>
        <div className="container">
          <div className="col-12 align-center  pt-2 pb-2">
          {this.props.sectionTitle !== "Course Assessment Certificates" && this.props.sectionTitle !== "Course Learning Material" ?
          <div className="container">
            <h3>General Course Disclaimer</h3>
            <div>
              <p className="mb-0">By completing this selected quizzes, I declare the following:</p>
              <p>I confirm that I as the registered member, am completing the online assessment in my personal capacity with 
              no assistance from any third party. I acknowledge that it is an offence to provide false information and 
              accept that should this be the case, my results could be revoked and I stand to be disqualified from taking 
              this course again. I further acknowledge that this course is an integral part of obtaining my dedicated 
              status and as a result of my assessment being revoked, I also stand the chance of losing my dedicated status.</p>
            </div> 
          </div> : null}
            <div className="container">
              {this.props.sectionTitle === "Course Assessment Certificates"
                ? this.props.assesmentList.map((assesment, index) => (
                    <div key={index}>
                      {this.hasCertificate(assesment.assesmentId) ? (
                        <div
                          className="row border-bottom p-2 mb-2"
                          style={{ cursor: "pointer" }}
                          onMouseDown={() =>
                            this.setState({ courseTitle: assesment.title })
                          }
                          onClick={print}
                        >
                          <div
                            className="col-7 col-md-10  h6"
                            style={{ cursor: "pointer" }}
                          >
                            {assesment.title}
                          </div>

                          <div className="col-5 col-md-2">
                            <div className=" bg-dark text-white text-center p-2 rounded">
                              Certificate
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                : list.map((assesment, index) => (
                    <div className="row" key={index}>
                      <div
                        className="col-12  h6 p-2 border-bottom"
                        style={{ cursor: "pointer" }}
                        onMouseDown={() => this.handleClick(assesment)}
                      >
                        {this.state.open[`${assesment.assesmentId}isOpen`] ? (
                          <FolderOpen
                            style={{
                              paddingBottom: "4px",
                              marginRight: "2px"
                            }}
                          />
                        ) : (
                          <Folder
                            style={{
                              paddingBottom: "4px",
                              marginRight: "2px"
                            }}
                          />
                        )}{" "}
                        {this.props.sectionTitle === "Course Learning Material"
                          ? assesment.title
                          : assesment.title.replace("Course", " ") + " Quizzes"}
                      </div>
                      {this.state.open[`${assesment.assesmentId}isOpen`] && (
                        <div className="col-12  ">
                          {this.displayLessons(assesment.assesmentId)}
                        </div>
                      )}
                    </div>
                  ))}
            </div>
          </div>
        </div>
        <div className="row">
          {this.props.sectionTitle === "Course Assesment Quizzes" && (
            <div className="col-12">
              <p>
                By completing the following assessments, you will be able to
                apply to become a dedicated sports person or hunter in terms of
                the Firearms Control Act 60 of 2000. After the theoretical
                assessments and practical assessments have been successfully
                completed, you may submit your application to become a dedicated
                sport shooter or hunter.
              </p>
              <p>
                The courses are designed for firearm owners who are already in
                possession of a firearm competency and own a firearm for sport
                shooting or hunting activities. A pass rate of 70% is required.
              </p>
              <p>
                At the end of each lesson, mark it as “Complete” and proceed to
                the assessment questions.
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    assesmentCompletion: state.lmsReducer.assesmentCompletion,
    quizzesList: state.lmsReducer.quizzes,
    memberCourses: state.lmsReducer.memberCourses,
    lessonList: state.lmsReducer.lessons,
    sectionTitle: state.lmsReducer.sectionTitle,
    assesmentList: state.lmsReducer.assesments,
    currentLMSpage: state.lmsReducer.currLmsTab,
    userDetails: state.loginReducer.userProfileDetails,
    quizCompletion: state.lmsReducer.quizCompletion,
    certificates: state.lmsReducer.certificates
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onLessonChange: newLesson =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_LESSON,
        lesson: newLesson
      }),
    onQuizzesChange: newQuizzes =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZZES,
        quizzes: newQuizzes
      }),
    onSectionTitleChange: newSectionTitle =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_SECTION_TITLE,
        sectionTitle: newSectionTitle
      }),
    onAssesmentsChange: newAssesments =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENTS,
        assesments: newAssesments
      }),
    onAssesmentChange: newAssesment =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_ASSESMENT,
        assesment: newAssesment
      }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
        currLmsTab: newCurrentLmsTab
      }),
    onQuizCompletionChange: newQuizCompletion =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_QUIZ_COMPLETION,
        quizCompletion: newQuizCompletion
      }),
    onCertificatesUpdate: certificates =>
      dispatch({
        type: LMS_ACTIONS.UPDATE_CERTIFICATES,
        certificates: certificates
      }),
    onCourseModeleNameUpdate: newCourseName =>
      dispatch({
        type:LMS_ACTIONS.UPDATE_COURSE_MODULE_NAME,
        courseModuleName: newCourseName
      }),
    onModuleIdTrack: newModuleId =>
    dispatch({
      type:LMS_ACTIONS.TRACK_CURRENT_MODULE_ID,
      moduleId: newModuleId
    })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lms);
