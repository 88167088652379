import {
  SET_EVENT_LOOKUP,
  SET_DISCIPLINES,
  SET_FIREARM_TYPES,
  SET_FIREARM_TYPES_LOOKUP,
  SET_OPTICS_LOOKUP,
  SET_ACTIVITY_TYPE,
  SET_ACTION_TYPE,
  SET_SECTION_LOOKUP,
  SET_PURPOSE_LOOKUP,
  SET_SPECIE_TYPE
} from './types';

const fetchEventLookups = service => () => dispatch => service
  .fetchEventLookups()
  .then((res) => dispatch({ type: SET_EVENT_LOOKUP, payload: res.data }))
  .catch(() => dispatch({ type: SET_EVENT_LOOKUP, payload: [] }));

const fetchDisciplines = service => () => dispatch => service
  .fetchDisciplines()
  .then((res) => dispatch({ type: SET_DISCIPLINES, payload: res.data }))
  .catch(() => dispatch({ type: SET_DISCIPLINES, payload: [] }));

const fetchFirearmType = service => () => dispatch => service
  .fetchFirearmType()
  .then((res) => dispatch({ type: SET_FIREARM_TYPES, payload: res.data }))
  .catch(() => dispatch({ type: SET_FIREARM_TYPES, payload: [] }));
  
  const fetchFirearmTypeLookup = service => () => dispatch => service
  .fetchFirearmTypeLookup()
  .then((res) => dispatch({ type: SET_FIREARM_TYPES_LOOKUP, payload: res.data }))
  .catch(() => dispatch({ type: SET_FIREARM_TYPES_LOOKUP, payload: [] }));

  
const fetchOpticsLookup = service => () => dispatch => service
  .fetchOpticsLookup()
  .then((res) => dispatch({ type: SET_OPTICS_LOOKUP, payload: res.data }))
  .catch(() => dispatch({ type: SET_OPTICS_LOOKUP, payload: [] }));

const fetchActivityType = service => () => dispatch => service
  .fetchActivityType()
  .then((res) => dispatch({ type: SET_ACTIVITY_TYPE, payload: res.data }))
  .catch(() => dispatch({ type: SET_ACTIVITY_TYPE, payload: [] }));

const fetchSpecieType = service => () => dispatch => service
  .fetchSpecieType()
  .then((res) => dispatch({ type: SET_SPECIE_TYPE, payload: res.data }))
  .catch(() => dispatch({ type: SET_SPECIE_TYPE, payload: [] }));

const fetchFirearmActions = service => () => dispatch => service
  .fetchFirearmActions()
  .then((res) => dispatch({ type: SET_ACTION_TYPE, payload: res.data }))
  .catch(() => dispatch({ type: SET_ACTION_TYPE, payload: [] }));

const fetchSections = service => () => dispatch => service
  .fetchSections()
  .then((res) => dispatch({ type: SET_SECTION_LOOKUP, payload: res.data }))
  .catch(() => dispatch({ type: SET_SECTION_LOOKUP, payload: [] }));

const fetchEndorsementPurposes = service => () => dispatch => service
  .fetchEndorsementPurposes()
  .then((res) => dispatch({ type: SET_PURPOSE_LOOKUP, payload: res.data }))
  .catch(() => dispatch({ type: SET_PURPOSE_LOOKUP, payload: [] }));

export default function(lookupService) {
  return {
    fetchEventLookups: fetchEventLookups(lookupService),
    fetchDisciplines: fetchDisciplines(lookupService),
    fetchFirearmType: fetchFirearmType(lookupService),
    fetchFirearmTypeLookup: fetchFirearmTypeLookup(lookupService),
    fetchOpticsLookup: fetchOpticsLookup(lookupService),
    fetchActivityType: fetchActivityType(lookupService),
    fetchSpecieType: fetchSpecieType(lookupService),
    fetchFirearmActions: fetchFirearmActions(lookupService),
    fetchSections: fetchSections(lookupService),
    fetchEndorsementPurposes: fetchEndorsementPurposes(lookupService)
  };
}
