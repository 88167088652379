import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import axios from "axios";
import JWT from "jwt-decode";
import PropTypes from "prop-types";
import React from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { BASE, GET_LOGGED_IN_MEMBER, LOGIN_URL } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";

class MainLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      members: [],
      occupation: "",
      isChecked: false,
      redirect: false,
      isValid: false,
      showPassword: false,
      passwordIsMasked: true,
      isErrorLogin: false
    };

    this.handleChecked = this.handleChecked.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    window.history.forward(1);
    this.props.onResetPassDetailsChange("", "email");
    this.props.onResetPassDetailsChange("", "identityNumber");
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  handleSubmit() {
    this.setState({
      isErrorLogin: false
    })
    const user = {
      email: this.props.loginDetails.email,
      userName: this.props.loginDetails.userName,
      password: this.props.loginDetails.password
    };

    const loginURL = BASE + LOGIN_URL;
    axios.post(loginURL, user).then(res => {
      if (this.state.isChecked) {
        localStorage.setItem("tokenKey", res.data.token);
      } else {
        sessionStorage.setItem("tokenKey", res.data.token);
      }
      if (res.status !== 200) {
        this.setState({
          isErrorLogin: true
        })
      }
      var decode = JWT(res.data.token);
      this.props.onUserDetailsChange(decode.email, "email");
      this.props.onUserDetailsChange(decode.sub, "username");
      this.props.onUserDetailsChange(decode.azp, "cellNo");
      this.props.onUserDetailsChange(decode.acr, "physicalAddress");
      this.props.onUserDetailsChange(decode.sid, "city");
      this.props.onUserDetailsChange(decode.nonce, "postalCode");
      this.props.onUserDetailsChange(decode.amr, "memberNumber");
      this.props.onUserDetailsChange(decode.nbf, "occupation");
      this.props.onUserDetailsChange(decode.prn, "expiryDate");
      this.props.onUserDetailsChange(decode.nameid === "True", "adminLevel");
      this.props.onUserDetailsChange(true, "isLoggedIn");
      this.props.history.push("/MembersArea");
      axios.get(BASE + "api/LoyaltyDaysAdjust/getLoyaltyDays/" + res.data.memNo,{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
        .then(resp => {
          this.props.onLoyaltyDaysGet(resp.data.daysList)
          this.props.onLoyaltyDaysArrayRecordGet(resp.data.daysHistory.result.value.daysRecordObject)
          this.props.onLoyaltyDaysRecordGet(resp.data.daysAdded.value.daysObject)
        })
      axios
        .get(
          BASE +
          GET_LOGGED_IN_MEMBER +
          this.props.userProfileDetails.memberNumber,
          {
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}
        )
        .then(res => {
          this.props.onGetLoggedInMemberChange(res.data.member);
          this.props.onMemberActiveStatusChange(res.data.dedicatedStatus);

          sessionStorage.setItem("member", JSON.stringify(res.data.member));
          sessionStorage.setItem(
            "activeStatus",
            JSON.stringify(res.data.dedicatedStatus)
          );
          sessionStorage.setItem("reload", "true");
        })
    })
    // .then(
    //   axios({
    //     url: BASE + "api/AutomatedEmail/SendLoginEamil",
    //     method: "post",
    //     data: {
    //       toEmail: this.props.memberDetails.email,
    //       firstname: this.props.memberDetails.firstname,
    //       surname: this.props.memberDetails.surname
    //     }
    //   })
    // )
    //   .catch(error => {
    //     this.setState({
    //       isErrorLogin: true
    //     })
    //   })
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  render() {
    return (
      <div style={{ marginTop: "60px" }}>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/Home">
                <p className="text-dark">Home</p>
              </Link>
            </li>
            <li
              className="breadcrumb-item active text-dark font-weight-bold"
              aria-current="page"
            >
              Login
            </li>
          </ol>
        </nav>
        <div className="container mt-5">
          <div className="row mt-5" />
          <div className="row mt-5" />
          <div className="container col-lg-6 col-sm-8">
              <ValidatorForm ref="form">
                <div className="form-group font-weight-bold">
                  <label htmlFor="email">Login</label>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Username or E-mail</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={event =>
                      this.props.onLoginDetailsChange(
                        event.target.value,
                        "email"
                      )
                    }
                    name="email"
                    value={this.props.loginDetails.email}
                    validators={["required", "isEmail"]}
                    errormessages={[
                      "this field is required",
                      "email is not valid"
                    ]}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <Input
                    className="form-control"
                    id="outlined-full-width"
                    fullWidth
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    onChange={event =>
                      this.props.onLoginDetailsChange(
                        event.target.value,
                        "password"
                      )
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={this.handleClickShowPassword}
                        >
                          {this.state.showPassword ? (
                            <Visibility />
                          ) : (
                              <VisibilityOff />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
                {this.state.isErrorLogin ?
                  <div className="text-danger text-center font-weight-bold mb-2">
                    Failed to login your details are incorrect
                  </div> : null
                }
                <div className="form-group">
                  <div className="text-center ">
                    <button
                      type="button"
                      className="btn btn-warning text-white"
                      onClick={this.handleSubmit}
                    >
                      Log In
                    </button>
                  </div>
                </div>
                <div className="form-group text-black text-center">
                  <Link to="/Login/ResetPassword" color="primary" >
                    <h5 className="text-body" style={{ cursor: "pointer"}}>
                      Forgot your password?
                    </h5>
                    <hr />
                  </Link>
                </div>
              </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}

MainLogin.propTypes = {
  userProfileDetails: PropTypes.object.isRequired,
  loginDetails: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    loginDetails: state.loginReducer.loginDetails,
    memberDetails: state.MemberViewReducer.getLoggedInMember
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onResetPassDetailsChange: (value, vname) => dispatch({ type: actionTypes.UPDATE_RESET_DETAILS, varValue: value, varName: vname }),
    onLoyaltyDaysRecordGet: record =>
        dispatch({ 
            type: actionTypes.GET_MEM_LOYALTY_RECORD, 
            loyaltyRecord: record 
        }),
    onLoyaltyDaysArrayRecordGet: aRecord =>
        dispatch({ 
            type: actionTypes.GET_LOYALTY_DAYS_ARRAY, 
            loyaltyRecordArray: aRecord 
        }),
    onGetLoggedInMemberChange: newGetLoggedInMember =>
      dispatch({
        type: actionTypes.UPDATE_GET_LOGGED_IN_MEMBER,
        getLoggedInMember: newGetLoggedInMember
      }),
    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onLoginDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        varValue: value,
        varName: vname
      }),
    onMainDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MAIN_DETAILS,
        varValue: value,
        varName: vname
      }),
    onLoyaltyDaysGet: loyaltyDaysObject =>
      dispatch({
        type:actionTypes.GET_LOYALTY_DAYS,
        loyaltyDaysObject: loyaltyDaysObject
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MainLogin));
