import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, GET_ACCOUNT_LIST } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";

const styles = {
  root: {
    backgroundColor: "white",
    width: "100%",
    height: "60%",
    overflow: "auto"
  },
  paper: {
    backgroundColor: "black",
    position: "absolute",
    bottom: "0"
  },
  button: {
    backgroundColor: "black",
    flex: 1,
    color: "white",
    marginLeft: "45%",
    marginRight: "45%",
    marginTop: "20px",
    marginBottom: "20px"
  },
  iframe: {
    width: "200vh",
    height: "90vh",
    overflow: "auto"
  }
};

class Payfast extends Component {
  componentDidMount() {
    axios.get(BASE + GET_ACCOUNT_LIST).then(res => {
      var account = res.data;
      this.props.onUserAccountChange(account);
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <iframe
          className={classes.iframe}
          src="https://bit.ly/2tOe05l"
          title="Icons iframe"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>
    );
  }
}
Payfast.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    accountDetails: state.accountReducer.account,
    accountList: state.accountReducer.accountList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUserAccountChange: newUserAccountArray =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT_LIST,
        payload: newUserAccountArray
      }),
    onAddAccountDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_ACCOUNT,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Payfast));
