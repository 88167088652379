import {
  SET_TARGET_FIREARM_TYPES,
  SET_FIREARM_TYPES_LOOKUP,
  SET_ACTIVITY_LOOKUP,
  SET_EVENT_RULES,
  SET_EVENT_NAME
} from './actionType';

import { targetDownsLoadLevels } from '../../shared/constants';

const INITIAL_STATE = {
  fireArmTypes: [],
  fireArmTypesLookups: [],
  activatyLookups: [],
  eventRules: [],
  downloads: [],
  level: '',
  eventName: '',
};

export default (state = INITIAL_STATE, { type, payload}) => {
  switch (type) {
  
  case SET_TARGET_FIREARM_TYPES:
    return { ...state, fireArmTypes: payload, level: targetDownsLoadLevels.FIREARM_TYPES};

  case SET_FIREARM_TYPES_LOOKUP:
    return { ...state, fireArmTypesLookups: payload, level: targetDownsLoadLevels.FIREARM_LOOK_UP};

  case SET_ACTIVITY_LOOKUP:
    return { ...state, activatyLookups: payload, level: targetDownsLoadLevels.ACTIVITY_LOOK_UP};

  case SET_EVENT_RULES:
    return { ...state, eventRules: payload, level: targetDownsLoadLevels.EVENT_RULES};
  
  case SET_EVENT_NAME:
    return {...state, eventName: payload}

  default:
    return state;
  }
};