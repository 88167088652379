import * as actionTypes from "../actions//actionTypes";

const initialState = {
  licence: {
    Firearm: "",
    Make: "",
    Model: "",
    Caliber: "",
    SerialNumber: "",
    LicenseName:"",
    LicenseType: "",
    Description:"",
    LicenseNo: "",
    Code:"",
    Section: "",
    FirstIssueDate:"",
    IssueDate: "",
    ExpiryDate: "",
    show:"",
    Issued:"",
    Custom:"",
    MemNo:""
  },
  LicenceList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LICENCE:
      const newLicence = {
        ...state.licence,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        licence: newLicence
      };
    case actionTypes.UPDATE_LICENCE_LIST:
      return {
        ...state,
        LicenceList: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
