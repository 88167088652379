import React, { Component } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { ValidatorForm } from "react-form-validator-core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import { BASE } from "../../../API/APIendpoint";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
const styles = theme => ({
  root: {
    height: "100%"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    textAlign: "center"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    align: "center"
  },
  footer: {
    display: "comtent",
    flexDirection: "row"
  },
  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    fullWidth: true,
    flex: "1",
    margin: "5",
    instantValidate: true
  },
  textField: {
    marginTop: "1%",
    marginLeft: "30%",
    marginRight: "30%",
    flex: 1,
    margin: 5,
    width: "40%"
  },
  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#FFFFFF",
    borderColor: "#000000",
    "&:hover": {
      backgroundColor: "#F5A623",
      color: "White",
      borderColor: "Black"
    },
    width: "20%",
    color: "Black",
    align: "Left",
    marginLeft: "10%",
    marginRight: "30%"
  },
  select: {
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%",
    flex: "1",
    margin: "5"
  }
});
class DropDownShootingRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShootingRange_List: [],
      DelKey: "",
      isValid: false,
      showValid: true
    };
  }
  handleChange = name => event => {
    this.props.onSectionChange(event.target.value, [name]);
    this.setState({ showValid: true });
    this.checkNotEmpty();
  };
  checkNotEmpty = () => {
    let V = true;
    if (
      this.props.Selections.ShootingRange === "" ||
      this.props.Selections.ShootingRange === null ||
      this.props.Selections.ShootingRange === undefined ||
      this.props.Selections.SRAddress === "" ||
      this.props.Selections.SRAddress === null ||
      this.props.Selections.SRAddress === undefined
    ) {
      V = false;
    }
    this.setState({
      isValid: V
    });
  };
  submitSection() {
    if (
      this.state.isValid &&
      !(this.props.Selections.ShootingRange === "") &&
      !(this.props.Selections.SRAddress === "")
    ) {
      fetch(BASE + "api/ShootingRanges", {
        method: "POST",
        body: JSON.stringify({
          ShootingRange: this.props.Selections.ShootingRange,
          Adress: this.props.Selections.SRAddress
        }),
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(() => {
        fetch(BASE + "api/ShootingRanges", {
          headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
        })
          .then(res => res.json())
          .then(data => {
            this.setState({
              ShootingRange_List: data
            });
            
          });
      });
    }
     this.props.onSectionChange(" ", "ShootingRange");
     this.props.onSectionChange(" ", "SRAddress");
    this.setState({ isValid: false });
    this.forceUpdate();
  }
  DeleteSection() {
    Axios.delete(
      BASE + "api/ShootingRanges/" + this.props.Selections.DeleteSRangeKey, {
      headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
    }
    ).then(() =>
      Axios.get(BASE + "api/ShootingRanges", {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
        .then(data => {this.setState({
          ShootingRange_List: data.data
        })}
        )
    );
    this.componentDidMount();
  }
  DeleteKey = e => {
    this.setState({
      DelKey: [e.target.value]
    });
    this.props.onSectionChange(e.target.value, "DeleteSRangeKey");
  };
  componentDidMount() {
    this.props.onSectionChange("", "ShootingRange");
    this.props.onSectionChange("", "SRAddress");
    this.setState({ showValid: false });
    fetch(BASE + "api/ShootingRanges",
      {
        headers: { 'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`, }
      })
      .then(res => res.json())
      .then(data => {
        this.setState({
          ShootingRange_List: data
        });
      });
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (this.state.showValid) {
        if (!value) {
          return false;
        }
        if (value.length < 0) {
          return false;
        }
      }
      return true;
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.container} noValidate autoComplete="off">
          <h1 className="row d-none d-md-block" align="Middle">
            Shooting Range
          </h1>
          <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit={() => this.handleSubmit()}
          >
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.Selections.ShootingRange}
              label="ShootingRange"
              onInput={this.handleChange("ShootingRange")}
              name="ShootingRange"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the ShootingRange Name",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <TextValidator
              id="OutlinedSection"
              className={classes.textField}
              value={this.props.Selections.SRAddress}
              label="Address"
              onInput={this.handleChange("SRAddress")}
              name="SRAddress"
              validators={["isEmpty", "required"]}
              errormessages={[
                "Please Enter the ShootingRange Name/ Address",
                "This field is required"
              ]}
              margin="normal"
              variant="outlined"
            />
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                this.submitSection();
              }}
            >
              Add to Drop Downs
            </Button>
            <Select
              className={classes.textField}
              native
              value={this.props.Selections.DeleteSRangeKey}
              onChange={this.DeleteKey}
              input={
                <OutlinedInput
                  name="ShootingRange_List"
                  id="ShootingRange"
                  labelWidth={0}
                />
              }
            >
              {this.state.ShootingRange_List.map(activityType => (
                <option
                  key={activityType.shootingRangeId}
                  value={activityType.shootingRangeId}
                >
                  {activityType.shootingRange} : {activityType.adress}
                </option>
              ))}
            </Select>

            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                this.DeleteSection();
              }}
            >
              Remove Shooting Range
            </Button>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}
DropDownShootingRange.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  return {
    Selections: state.DropDownReducer.Options
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSectionChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_OPTIONS,
        varValue: value,
        varName: vname
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DropDownShootingRange));
