import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";

const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    borderBottom: "1.8px solid #8c8b8a"
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontWeight: "bold",
    marginLeft: 12,
    marginRight: 20
  }
});

function EndorsementHeaderApplication(props) {
  return (
    <div className="row">
      <GenericHeader
        title="Endorsements"
        backFunction={() => {
          props.onCurrentEndorsementTabChange("0");
        }}
      />
    </div>
  );
}

EndorsementHeaderApplication.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    newActDetails: state.endorsementReducer.addEndorsementDetails,
    application_post: state.endorsementReducer.application_post
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION_POST,
        application: newApplication
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EndorsementHeaderApplication));
