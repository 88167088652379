import React, { Component } from 'react';
import { SET_ACTIVITY_LOOKUP, SET_TARGET_FIREARM_TYPES } from '../../store/FireArmType/actionType';
import { connect } from 'react-redux';
import FireArmAction from '../../store/FireArmType/actions';
import FireArmTypeService from './../../services/firearmType.service';
import { targetDownsLoadLevels } from '../../shared/constants';
import {
  SubtitleBar,
  Loader
} from '../index';
import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import ActivityEventLookup from './ActivityEventLookup';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  pointer: {
    cursor: 'pointer'
  },
});

class FireArmTypeLookup extends Component {
  constructor(props) {
    super(props);
    this.fireArmTypeService = FireArmTypeService();
    this.activityLookUpActions_ = this.props._activityLookUpActions(this.fireArmTypeService);
    this.state = {
      fireArmLookups: this.props.fireArmTypesLookups,
      isActivityLookup: false,
      isGoBack: false,
      isLoader: false
    };
  }

  goBack = () => {
    this.props.isFirearmTypes(this.props.fireArmTypes);
  };

  render() {
    const { classes } = this.props;
    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      <React.Fragment>
        {this.props.level === targetDownsLoadLevels.FIREARM_LOOK_UP && (<SubtitleBar title={this.props.level} back onBack={this.goBack}></SubtitleBar>)}
        {this.state.isLoader && (<Loader />)}
        <Grid container justify="center">
          {this.props.level === targetDownsLoadLevels.FIREARM_LOOK_UP && (<Grid item container xs={12} md={12} spacing={4} alignItems="center">
            {this.props.fireArmTypesLookups.length === 0 ? <Grid item>No firearm types lookup available</Grid> : null}
            {this.props.fireArmTypesLookups.map((item, index) => (
              <Grid key={index} item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={4}>
                      <Grid item xs={10}>
                        <Typography variant={isMobile?"h6":"h5"} style={{color:"#4F36A6"}}>
                          {item.fireArmType}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="body1"
                          color="primary"
                          className={classes.pointer}
                          onClick={() => {
                            this.setState({isLoader:true});
                            this.activityLookUpActions_.fetchActivityLookupByFireArmTypeID(item.fireArmTypeId).then(() => this.setState({ isActivityLookup: true, isLoader:false}));
                          }}
                        >
                          VIEW
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>)}
        </Grid>
        {this.state.isActivityLookup && (<ActivityEventLookup></ActivityEventLookup>)}
      </React.Fragment>
    );
  }
}

const initialiseActivityEventLookUpActions = dispatch => (fireArmTypeService) => {
  const action = FireArmAction(fireArmTypeService);
  return {
    fetchActivityLookupByFireArmTypeID: (id) => dispatch(action.fetchActivityLookupByFireArmTypeID(id)),
  };
};

const mapStateToProps = state => ({
  level: state.fireArmTypeTargets.level,
  fireArmTypesLookups: state.fireArmTypeTargets.fireArmTypesLookups,
  activatyLookups: state.fireArmTypeTargets.activatyLookups,
  fireArmTypes: state.fireArmTypeTargets.fireArmTypes,
});

const mapDispatchToProps = dispatch => ({
  onActivityEventLookUp: (fireArmLookUp) =>
    dispatch({
      type: SET_ACTIVITY_LOOKUP,
      payload: fireArmLookUp,
    }),
  _activityLookUpActions: initialiseActivityEventLookUpActions(dispatch),
  isFirearmTypes: (fireArmTypes) =>
    dispatch({
      type: SET_TARGET_FIREARM_TYPES,
      payload: fireArmTypes
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FireArmTypeLookup));