import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ViewLicence: {
    Firearm: "",
    Make: "",
    Model: "",
    Caliber: "",
    SerialNumber: "",
    LicenseName: "",
    LicenseType: "",
    LicenseNo: "",
    Code: "",
    Section: "",
    FirstIssueDate: "",
    IssueDate: "",
    ExpiryDate: "",
    show: "",
    Issued: "",
    Custom: "",
    MemNo: ""
  },
  LicenceList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_VIEW_LICENCE:
      const newLicence = {
        ...state.ViewLicence,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        ViewLicence: newLicence
      };
    case actionTypes.UPDATE_VIEW_LICENCE_LIST:
      return {
        ...state,
        LicenceList: action.payload
      };
    default:
      return state;
  }
};

export default reducer;
