import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar
} from '../index';
import { Grid, Typography, Button } from '@material-ui/core';
import GenericTable from '../GenericTable/GenericTable';
import { withStyles } from '@material-ui/core/styles';
import Interweave from 'interweave';
import SingleEmail from './SingleEmail';

const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  }
});

const columns = [
  { field: 'dateSent', headerName: 'Date sent' },
  { field: 'subject', headerName: 'Subject'}
];

class ViewEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        emailList: props.emailList,
      viewEmails: true,
      selectedEmail: null
    };

  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;

    return(
      <React.Fragment>
         {
          this.state.viewEmails ?<>
            {/* <SubtitleBar variant="subtitle1" title={''} /> */}
              {this.props.emailList.length !== 0 ?
                <GenericTable 
                  columns={columns}
                  rows={this.props.emailList}
                  view
                  onViewCallBack={item => {
                    this.setState({ 
                      viewEmails: !this.state.viewEmails,
                      selectedEmail : item
                    });
                  }}
                /> : null}</>
                : 
                <SingleEmail 
                  email={this.state.selectedEmail}
                  goBack={() => this.setState({ viewEmails: true })}
                />
         }

      </React.Fragment>
    );
  }
}


export default connect(null, null)(withStyles(styles)(ViewEmail));