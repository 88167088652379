import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  root: {
    width: "100%",
    flexGrow: 1,
    borderBottom: "1.8px solid #8c8b8a"
  },
  grow: {
    flexGrow: 1
  },
  title: {
    fontWeight: "bold",
    marginLeft: "5%",
  }
});

function NewActivityHeader(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Toolbar>
        
        <Typography variant="h5" className={classes.title}>
          Activity
        </Typography>
        <div className={classes.grow} />
        <IconButton>
          <SearchIcon />
        </IconButton>
      </Toolbar>
    </div>
  );
}

const mapStateToProps = state => {
  return {
      currActivityTab: state.membersAreaReducer.membersArea.currentActivityTab,
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onMembersAreaChange: (value,variableName) => dispatch({type: 'UPDATE_MEMBERS_AREA', varValue:value, varName:variableName}),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewActivityHeader));
