import API_URL from './apiUrl';
import axios from 'axios';

const getEmailHistory = headers => () => axios({
	url: `${API_URL}/AutomatedEmail/GetBulkEmails`,
	method: "get",
	headers: headers
  });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getEmailHistory: getEmailHistory(headers)
  };
}
