import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { BASE, FRONT, GET_MEMBER } from "../../../API/APIendpoint";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import "../membershipCard/resizer.css";
import { isMobile } from "react-device-detect";
const Cryptr = require("cryptr");
const styles = {
  card: {
    minWidth: "500px"
  },
  root: {
    flex: 2,
    flexDirection: "row",
    borderBottom: "1.5px solid #D8D8D8"
  },
  title: {
    display: "inline-block",
    fontSize: 15,
    width: "20%",
    paddingRight: "20px",
    color: "black"
  },
  content: {
    width: "20%",
    display: "inline-block",
    fontSize: 14,
    color: "black"
  },
  content2: {}
};
class EndorrsementApplicationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Posts: [],
      Certificate: [],
      encryptedMemString: "",
      qrCodeWithMemDet: ""
    };
  }
  componentDidMount() {
    const cryptr = new Cryptr("ewfWE@#%$rfdsefgdsf");
    axios
      .get(BASE + "api/Member/getMemQRCode/" + cryptr.encrypt(this.props.userDetails.memberNumber),{
        headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(resp => {
        const qrFinal = "data:image/jpeg;base64," + resp.data.base64QRString
        this.setState({
          qrCodeWithMemDet: qrFinal
        })
      });
    this.setState({
      encryptedMemString:
        FRONT +
        "?" +
        cryptr.encrypt(this.props.userDetails.memberNumber)
    });
  }
  handleSubmit = e => {
    this.props.onCurrentEndorsementTabChange("5");
    this.props.onAddEndorsementChange(this.props.title, "title");
  };
  DateFind(dated) {
    if (!dated) {
      return dated;
    }
    var date = new Date(Date.parse(dated));
    var currentDate = date;
    var dater = currentDate.getDate();
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    var month = currentDate.getMonth();
    var year = currentDate.getFullYear();
    var monthDateYear = dater + " " + months[month] + " " + year;

    return monthDateYear;
  }
  getStatusCode(dedicates) {
    let code;
    for (let dedicated of dedicates) {
      if (dedicated.status === "Approved") {
        switch (dedicated.dstype) {
          case "Hunting":
            if (code === 2) code = 3;
            else code = 1;
            break;
          case "Sport Shooting":
            if (code === 1) code = 3;
            else code = 2;
            break;
          default:
            code = -1;
            break;
        }
      }
    }
    return code;
  }
  getUserStatuses(dedicates) {
    let ds_status = null;
    if (!dedicates) {
      return ds_status;
    } else {
      var statusCode = this.getStatusCode(dedicates);
      switch (statusCode) {
        case 1:
          ds_status = "Dedicated Hunter";
          break;
        case 2:
          ds_status = "Dedicated Sports Person";
          break;
        case 3:
          ds_status = "Hunting and Sport Shooting";
          break;
        default:
          ds_status = "no dedicated status";
          break;
      }
      return ds_status;
    }
  }
  getDedicatedStatus(dedicates, Action) {
    if (!dedicates) {
      return "no dedicated status";
    } else {
      for (let dedicated of dedicates) {
        if (dedicated.dstype === Action && dedicated.status === "Approved") {
          return dedicated.dsno;
        }
      }
      return "no dedicated status";
    }
  }
  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    const endorsementViewInfo = (name, value) => (
      <tr>
        <td>{name}</td>
        <td className="font-weight-bold" style={{width: "45%"}}>{`: ${value}`}</td>
      </tr>
    );
    const endorsementViewInfo2 = (name, value) => (
      <tr>
        <td>{name}</td>
        {value === "Approved" ?
          <td className="font-weight-bold text-success" style={{width: "45%"}}>{`: ${value}`}</td> :
        value === "Rejected" ?
          <td className="font-weight-bold text-danger" style={{width: "45%"}}>{`: ${value}`}</td> :
          <td className="font-weight-bold text-warning" style={{width: "45%"}}>{`: ${value}`}</td>}
      </tr>
    );
    const endorsementViewInfo3 = (name, value) => (
      <tr>
        <td>{name}</td>
        {!value ?
          <td className="font-weight-bold" style={{width: "45%"}}>: null</td> :
          <td className="font-weight-bold" style={{width: "45%"}}>{`: ${value}`}</td>}
      </tr>
    );
    const endorsementViewInfo4 = (name, value) => (
      <tr>
        {name === "Approved" ? <td>Approval Date</td> : <td>Applied Date</td>}
        <td className="font-weight-bold" style={{width: "45%"}}>{`: ${value}`}</td>
      </tr>
    );
    const print = async () => {
        await fetch(BASE + GET_MEMBER + this.props.userDetails.memberNumber,{
          headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
          .then(res => res.json())
          .then(json => {
            let pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(this.state.qrCodeWithMemDet, "JPEG", 70, 240, 40, 35);
            pdf.addImage(this.props.base64Images.nnfo, "JPEG", 80, 10);
            pdf.setFont("times");
            pdf.setFontSize(14);
            pdf.setFontStyle("bold");
            pdf.text(
              50,
              55,
              "National Association of Responsible Firearm Owners"
            );
            pdf.setFontType("normal");
            pdf.text(90, 65, "South Africa");
            pdf.setFontStyle("bold");
            pdf.setFontSize(20);
            pdf.text(65, 75, "Endorsement Of Motivation");
            pdf.setFontSize(12);
            pdf.setFontType("normal");
            pdf.text(
              20,
              85,
              "I, the undersigned, Johann Scheffer, ID: 6509285076083, in my capacity as executive and appointed"
            );
            pdf.text(
              20,
              90,
              "responsible person of the association, hereby solemnly declare that"
            );
            pdf.text(
              20,
              100,
              "We have examined the motivation of the application for the following firearm for the purpose of"
            );
            pdf.text(20, 105, this.props.application.applicationType)

            pdf.setFontStyle("bold");
            pdf.text(
              20,
              110,
              this.props.application.fireArmType +
                "-" +
                this.props.application.actionType +
                "-" +
                this.props.application.make +
                "-" +
                this.props.application.model +
                "-" +
                this.props.application.caliber +
                "-" +
                this.props.application.serialNumber
            );
            pdf.setFontType("normal");
            pdf.text(
              20,
              115,
              "applied for under " +
                this.props.application.section +
                " of the Firearms Control Act and based on the information of the"
            );
            pdf.text(
              20,
              120,
              "motivation we find the firearm suitable for the stated use"
            );
            pdf.text(20, 130, "Member Names:");
            pdf.text(20, 135, "Member Number:");
            pdf.text(20, 140, "ID Number:");
            pdf.text(20, 145, "Dedicated Status:");
            pdf.setFontStyle("bold");
            pdf.text(
              60,
              130,
              json.members.firstname + " " + json.members.surname
            );
            pdf.text(60, 135, this.props.userDetails.memberNumber);
            pdf.text(60, 140, json.members.idno);
            pdf.text(60, 145, "Dedicated Type");
            pdf.text(105, 145, "Status Number");
            pdf.setFontType("normal");
            pdf.text(60, 150, "Dedicated Hunter");
            pdf.text(60, 155, "Dedicated Sports Person");
            pdf.setFontStyle("normal");
            pdf.text(
              105,
              150,
              this.getDedicatedStatus(
                json.members.dedicatedStatus,
                "Dedicated Hunter"
              )
            );
            pdf.text(
              105,
              155,
              this.getDedicatedStatus(
                json.members.dedicatedStatus,
                "Dedicated Sports Person"
              )
            );
            pdf.text(20, 160, "Endorsement Number:");
            pdf.setFontStyle("bold");
            pdf.text(60, 160, this.props.application.endorsementNo);
            pdf.setFontStyle("normal");
            pdf.text(20, 170, "who is a member of the association since");
            pdf.setFontStyle("bold");
            pdf.text(91, 170, this.DateFind(json.members.inceptionDate));
            pdf.setFontStyle("normal");
            pdf.text(20, 175, "and whose membership is valid until");
            pdf.setFontStyle("bold");
            pdf.text(83, 175, this.DateFind(json.members.expiryDate));
            pdf.addImage(this.props.base64Images.pdfSign, "JPEG", 42, 176, 40, 25);
            pdf.addImage(this.props.base64Images.pdflogo, "JPEG", 125, 180, 55, 55);
            pdf.text(128, 240, "Printed On:");
            pdf.text(152, 240, this.DateFind(new Date()));
            pdf.text(40, 205, "Johann Heinrich Scheffer");
            pdf.text(45, 210, "Executive Member");
            pdf.text(37, 215, "Approved on:");
            pdf.text(
              63,
              215,
              this.DateFind(this.props.application.approvalDate)
            );
            pdf.setFontSize(9);
            pdf.text(35, 220, "I declare that judgement was made based");
            pdf.text(35, 225, "on collective experience of the executive");
            pdf.text(35, 230, "team and the metarial facts provided in the");
            pdf.text(35, 235, "motivation submitted by the applicant");
            pdf.addImage(this.props.base64Images.signature2, "JPEG", 25, 240, 30, 30);
            pdf.rect(135, 267, 55, 11);
            pdf.text(138, 252, "Hunting Association :");
            pdf.text(142, 257, "Sport Shooting :");
            pdf.setFontType("normal");
            pdf.text(168, 252, "FAR-1300135");
            pdf.text(165, 257, "FAR-1300134");
            pdf.setFontSize(11);
            pdf.text(140, 274, "SAPS Accredited Association");
            pdf.setTextColor(126, 126, 126);
            pdf.text(
              20,
              280,
              "14 Phesante Close, Graanendal, Durbanville, 7551"
            );
            pdf.text(20, 285, "http://www.narfo.co.za");
            pdf.setTextColor(0, 0, 0);
            pdf.text(135, 285, "NARFO Pty (Ltd) 2012/086864/07");
            this.props.application.applicationType === "Hunting"
              ? pdf.save("Narfo Hunting Endorsement.pdf")
              : pdf.save("Narfo Sport Shooting Endorsement.pdf");
          });
    };
    return (
      <div>
        <div className="container">
          {this.props.application.status === "Approved" ? (
            <GenericHeader
              title="Endorsements"
              showBackButton
              backFunction={() => {
                this.props.onCurrentEndorsementTabChange("0");
              }}
              showDownloadButton
              text="Certificate"
              downloadFunction={print}
            />
          ) : (
            <GenericHeader
              title="Endorsements"
              showBackButton
              backFunction={() => {
                this.props.onCurrentEndorsementTabChange("0");
              }}
            />
          )}
        </div>
        {!isMobile ?
          <div className="container">
            <div className="container">
              <table style={{width:"100%"}}>
                <tbody>
                  {endorsementViewInfo("Application Title", this.props.application.title)}
                  {endorsementViewInfo("Endorsement No", this.props.application.endorsementNo)}
                  {endorsementViewInfo(
                    "Endorsement Type",
                    this.props.application.applicationType
                  )}
                  {endorsementViewInfo("Type of Firearm", this.props.application.fireArmType)}
                  {endorsementViewInfo("Action Type", this.props.application.actionType)}
                  {endorsementViewInfo("Make", this.props.application.make)}
                  {endorsementViewInfo("Serial Number", this.props.application.serialNumber)}
                  {endorsementViewInfo("Model", this.props.application.model)}
                  {endorsementViewInfo("Section", this.props.application.section)}
                  {endorsementViewInfo("Motivation", this.props.application.motivation)}
                  {endorsementViewInfo2("Status", this.props.application.status)}
                  {endorsementViewInfo3("Rejection Reason", this.props.application.reason)}  
                  {endorsementViewInfo4(this.props.application.status, this.props.application.approvalDate.substring(0, 10))} 
                </tbody>
              </table>
            </div>
          </div> :
          <div className="container">
            <div className="col-12">
              {info("Application Title:", this.props.application.title)}
              {info("Endorsement No:", this.props.application.endorsementNo)}
              {info(
                "Endorsement Type:",
                this.props.application.applicationType
              )}
              {info("Type of Firearm:", this.props.application.fireArmType)}
              {info("Make:", this.props.application.make)}
              {info("Serial Number:", this.props.application.serialNumber)}
              {info("Model:", this.props.application.model)}
              {info("Section:", this.props.application.section)}
              {info("Motivation:", this.props.application.motivation)}
              <div className="row  mt-2 mb-1">
                <div className="col-12">Status:</div>
                <div className="col-12 font-weight-bold">
                  {this.props.application.status === "Approved" ? (
                    <div className="text-success">Approved</div>
                  ) : this.props.application.status === "Rejected" ? (
                    <div className="text-danger">Rejected</div>
                  ) : (
                    <div className="text-warning">Pending</div>
                  )}
                </div>
              </div>
              <div className="row  mt-2 mb-1">
                <div className="col-12">Rejection Reason:</div>
                <div className="col-12 font-weight-bold">
                  {!this.props.application.reason ? (
                    <div className="text-success">"null"</div>
                  ) : (
                    this.props.application.reason
                  )}
                </div>
              </div>
              <div className="row  mt-2 mb-1">
                <div className="col-12">
                  {this.props.application.status === "Approved"
                    ? "Approval Date: "
                    : "Applied Date: "}
                </div>
                <div className="col-12 font-weight-bold">
                  {this.props.application.approvalDate.substring(0, 10)}
                </div>
              </div>
            </div>
          </div>}
      </div>
    );
  }
}

EndorrsementApplicationView.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    base64Images: state.Base64ImagesReducer.base64Images,
    application: state.endorsementReducer.application,
    certificate: state.endorsementReducer.certificate,
    MemberDetails: state.MemberViewReducer.MemberView,
    currActivityTab: state.currentEndorsementTab,
    userDetails: state.loginReducer.userProfileDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onCertificateChange: viewCertificate =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CERTIFICATE,
        certificate: viewCertificate
      }),
    onApplicationChange: newApplication =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_APPLICATION,
        application: newApplication
      }),
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_USER_ENDORSEMENT,
        varValue: value,
        varName: variableName
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EndorrsementApplicationView));
