/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
  memNo: null,
  email: null,
  error: null,
  loading: false,
  signupRedirectPath: '/login',
  signupSuccess: null,
  salesReps: [],
  partners: [],
};

const signupStart = (state, action) => updateObject(
  state,
  {
    error: null,
    loading: true,
  }
);

const signupSuccess = (state, action) => updateObject (
  state,
  {
    memNo: action.memNo,
    email: action.email,
    error: null,
    loading: false,
    signupSuccess: true,
  }
);

const signupFail = (state, action) => updateObject(
  state,
  {
    error: action.error,
    loading: false,
    signupSuccess: false,
  }
);

const setSignupRedirectPath = (state, action) => updateObject(
  state, 
  { 
    signupRedirectPath: action.path 
  }
);

const setSalesRep = (state, action) => updateObject(
  state,
  {
    salesReps: action.salesReps
  }
);

const setPartners = (state, action) => updateObject(
  state,
  {
    partners: action.partners
  }
);

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SIGNUP_START: return signupStart(state, action);
  case actionTypes.SIGNUP_SUCCESS: return signupSuccess(state, action);
  case actionTypes.SIGNUP_FAIL: return signupFail(state, action);
  case actionTypes.SET_SIGNUP_REDIRECT_PATH: return setSignupRedirectPath(state, action);
  case actionTypes.SET_SALES_REPRESENTATIVES: return setSalesRep(state, action);
  case actionTypes.SET_PARTNERS: return setPartners(state, action);
  default:
    return state;
  }
};

export default reducer;