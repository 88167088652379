import React, { Component } from "react";
import GenericHeader from "../../../utils/GenericHeader";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import axios from "axios";
import { BASE } from "../../../API/APIendpoint";
import "jspdf-autotable";
import { YearPicker } from 'react-dropdown-date';
import { isMobile } from "react-device-detect";
import { toast } from 'react-toastify';
toast.configure();
class CommissionSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monthlyRepCommission: [],
            _finYr: "",
            _commType: ""
        };
    }
    componentDidMount() {
        if(this.props.userDetails.adminLevel && window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1) !== "MembersArea") {
                this.props.onMemberStateChange(true, "isAdminMemView")
        } else {
            this.props.onMemberStateChange(false, "isAdminMemView")
        }
        this.props.onAddAccountDetailsChange(0, "currentAccountTab");
        this.props.onCurrentLMSTabChange(0);
        this.props.onCurrentEndorsementTabChange("0");
        this.props.onCurrActivityTabChange("0", "currentActivityTab");
        this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
        axios({
            url: `${BASE}api/admin/getuserrole/${this.props.memberDetails.memberNumber}`,
            method: "get",
            headers: { 
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` 
            } 
        }).then(res => {
            let bankingDetailsObject = {};
            let bankingDetailsObjectPartner = {};
            res.data.filter(element => {
                if(element.memType === "Sales"){
                    bankingDetailsObject = {
                        AdminId: element.adminId,
                        MemType: element.memType,
                        BankName: element.bankName,
                        BranchCode: element.branchCode,
                        AccountNo: element.accountNo,
                        AccountType: element.accountType,
                    }
                }
                if(element.memType === "Partner"){
                    this.props.onPartnerStatusUpdate(true);
                    bankingDetailsObjectPartner = {
                        AdminId: element.adminId,
                        MemType: element.memType,
                        BankName: element.bankName,
                        BranchCode: element.branchCode,
                        AccountNo: element.accountNo,
                        AccountType: element.accountType,
                    }
                }
            })
            this.props.onBankingDetailsget(bankingDetailsObject, bankingDetailsObjectPartner);
        })
    }
    getFilteredCommissions = e => {
        e.preventDefault();
        let _memNo = this.props.userDetails.adminLevel && window.location.href.substring(
            window.location.href.lastIndexOf("/") + 1) !== "MembersArea"?
                this.props.MemberV.memNo:
                this.props.memberDetails.memberNumber
        axios({
            url: `${BASE}api/commission/GetCommissionGrByComNo/${_memNo}/${this.state._finYr}`,
            method: "get",
            headers: { 
                'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}` 
            } 
        }).then(res => {
            if(res.data.repsMemComm.length !== 0) {
                toast.success(res.data.message);
                this.props.onBankingDetailsget(res.data.repsMemComm);
                this.props.onMothlyCommissionGet(res.data.repsMemComm);
                this.props.onCommissionTabChange("1");
            } else {
                toast.warning(res.data.message);
            }
        });
    }
    render() {
        return(
            <div className="container">
                <GenericHeader 
                    title="Commission"
                    showDownloadButton
                    text="Bank"
                    downloadFunction={() => this.props.onCommissionTabChange("2")}
                />
                <div>
                    {!isMobile ?
                    <div className="row">
                        <div className="font-weight-bold col-3 mt-2 ml-2">Choose Year</div>
                        <div className="col-4 mt-3 text-centre">
                            <YearPicker
                                defaultValue={'select year'}
                                reverse
                                onChange={(year) => {
                                    this.setState({
                                        _finYr: year
                                    });
                                }}
                            />
                        </div>
                        <div className="col-4 placeAtEnd">
                            <button className="btn btn-warning mt-3" 
                                onClick={e => {
                                    this.getFilteredCommissions(e); 
                                }}
                            >
                                GET COMMISSIONS
                            </button>
                        </div>
                    </div> :
                    <div>
                        <div className="row">
                            <div className="font-weight-bold col-8 mt-2 ml-2">Choose Year</div>
                            <div className="col-3 mt-2 text-centre">
                                <YearPicker
                                    defaultValue={'select year'}
                                    reverse
                                    onChange={(year) => {
                                        this.setState({
                                            _finYr: year
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="placeAtEnd">
                            <button className="btn btn-warning mt-3" 
                                onClick={e => {
                                    this.getFilteredCommissions(e); 
                                }}
                            >
                                GET COMMISSIONS
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
return {
        isAdminMemView: state.loginReducer.memberState.isAdminMemView,
        memberDetails: state.loginReducer.memberObject,
        memLoyaltyRecord: state.LoyaltyDaysReducer.loyaltyRecord,
        MemberV: state.MemberViewReducer.MemberView,
        userDetails: state.loginReducer.userProfileDetails
    };
};
const mapDispatchToProps = dispatch => {
return {
    onBankingDetailsget: (BankDetailsObject, bankingDetailsObjectPartner) =>
        dispatch({ 
            type: actionTypes.BANKING_DETAILS_UPDATE, 
            bankingDetails: [
                BankDetailsObject,
                bankingDetailsObjectPartner
            ]
        }),
    onPartnerStatusUpdate: status =>
        dispatch({ 
            type: actionTypes.IS_PARTNER_UPDATE, 
            isPartner: status
        }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value
      }),
    onMothlyCommissionGet: mCom =>
        dispatch({ 
            type: actionTypes.MONTHLY_COMMISSION, 
            monthlyCommission: mCom 
        }),
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onLoyaltyDaysTabChange: pageNum =>
        dispatch({ 
            type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
            currentLoyaltyDaysTab: pageNum 
        }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
        dispatch({
          type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
          currLmsTab: newCurrentLmsTab
        }),
    onCurrentEndorsementTabChange: newTabName =>
        dispatch({
          type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
          currTab: newTabName
        }),
    onCurrActivityTabChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: vname
        }),
    onCurrentLicenseTabChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: variableName
        }),
    onAddAccountDetailsChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          varValue: value,
          varName: variableName
        }),
    };
};
export default connect(
mapStateToProps,
mapDispatchToProps
)(CommissionSelect);