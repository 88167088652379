import React, { Component } from "react";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";
import { COURSE_MATERIAL } from "../LMSAPIEndPoints";

export default class AddMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      createMaterialMessage: '',
      onCreate: false,
      name: ''
    }
  }

  createCourseMaterial = () => {
    const data = {
      Name: this.state.name,
      Content: "put your content here",
      CourseModuleId: this.props.parentId
    }
    this.setState({
      addModuleStatus: "Creating material...",
      onCreate: true
    })
    fetch(COURSE_MATERIAL,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    )
      .then(res => res.json())
      .then(res => {
        this.setState({
          createMaterialMessage: res.message
        })
        setTimeout(() => {
          this.props.refresh();
          this.props.onClick(0);
        }, 1500);
      })
      .catch(error => {
        this.setState({
          createMaterialMessage: error.message ? error.message : 'Failed! Try Again'
        })
        setTimeout(() => {
          this.props.onClick(0);
        }, 1500);
      })
  }

  componentDidMount() {
  }

  render() {
    return (
      <div className="popup-menu-container">
        <Add className="nocursor" />
        {this.state.onCreate ?
          <label>{this.state.createMaterialMessage}</label> :
          <input type="text" placeholder="Enter name"
            onChange={(e) => {
              this.setState({
                name: e.target.value
              })
            }} />
        }
        <span className={this.state.onCreate ? 'hide' : ''} >
          <Check onClick={() => {
            this.createCourseMaterial();
          }} />
          <Clear onClick={() => {
            this.props.onClick(0);
          }} />
        </span>
      </div>
    )
  }
}
