import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import AddFirearmDetails from "./AddFirearmDetails";
import AddLicenseDetails from "./AddLicenseDetails";

const buttonStyles = {
  backgroundColor: "#FFFFFF",
  borderColor: "#000000",
  display: "inline-block",
  "&:hover": {
    backgroundColor: "#F5A623",
    color: "White",
    border: "none"
  },
  "&:active": {
    backgroundColor: "#F5A623"
  },
  width: "20vw",
  maxWidth: "300px",
  padding: "10px",
  color: "Black",
  marginTop: "5%",
  borderRadius: "5px",
  boxShadow: " 0 0px 5px grey",
  fontSize: "16px",
  fontWeight: 600,
  border: "1.5px solid black"
};
const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column"
  },
  footer: {
    display: "flex",
    flexDirection: "row"
  },
  textField: {
    marginLeft: "20%",
    marginRight: "20%",

    flex: 1,
    margin: 5
  },
  grow: {
    flex: 6
  },
  grow1: {
    flex: 4
  },
  select: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    width: "60%",
    flex: 1,
    margin: 5
  },
  buttonDiv: {
    textAlign: "center",
    margin: "0 18.5%"
  },
  buttonLeft: {
    float: "left",
    marginRight: "1%",
    marginLeft: "3%",
    margin: theme.spacing.unit,
    ...buttonStyles
  },
  buttonRight: {
    float: "right",
    marginLeft: "1%",
    marginRight: "3%",
    margin: theme.spacing.unit,
    ...buttonStyles
  },
  orangeBg: {
    ...buttonStyles,
    backgroundColor: "#F5A623",
    color: "White",
    border: "none",
    marginLeft: "1%",
    marginRight: "3%",
    margin: theme.spacing.unit
  }
});

class LicenseAddNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fireClicked: false,
      customClicked: false
    };
  }

  handleChange = name => event => {
    this.props.onLicenceChange(event.target.value, name);
    this.setState({
      fireClicked: true,
      customClicked: false
    });
  };
  handleBChange = name => event => {
    this.props.onLicenceChange(event.target.value, name);
    this.setState({
      fireClicked: false,
      customClicked: true
    });
  };
  ActionSelect() {
    if (this.props.newlicenceDetails.show === "Firearm Licence") {
      return <AddFirearmDetails />;
    } else if (this.props.newlicenceDetails.show === "Custom Licence") {
      return <AddLicenseDetails />;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div className="activity-header-container">
          <GenericHeader
            title="License Reminders"
            showBackButton
            backFunction={() => {
              this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
            }}
            showFilledAddButton
            addFunction={() => {
              this.props.onCurrentLicenseTabChange("4", "currentLicenseTab");
            }}
          />
        </div>
        <form
          className={classes.container}
          noValidate
          autoComplete="off"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          {/* {place buttons here that shall triger the various tabs} */}
          <div className={classes.buttonDiv}>
            <button
              variant="contained"
              className={
                this.state.fireClicked ? classes.orangeBg : classes.buttonLeft
              }
              value="Firearm Licence"
              onClick={this.handleChange("show")}
            >
              Firearm
            </button>

            <button
              variant="contained"
              className={
                this.state.customClicked
                  ? classes.orangeBg
                  : classes.buttonRight
              }
              value="Custom Licence"
              onClick={this.handleBChange("show")}
            >
              Custom
            </button>
          </div>
        </form>
        {this.ActionSelect()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currLicenseTab: state.membersAreaReducer.membersArea.currentLicenseTab,
    newlicenceDetails: state.licensesReducer.licence
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentLicenseTabChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: variableName
      }),
    onLicenceChange: (value, variableName) =>
      dispatch({
        type: actionTypes.UPDATE_LICENCE,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(LicenseAddNew));
