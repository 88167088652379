import {
  SET_TARGET_FIREARM_TYPES,
  SET_FIREARM_TYPES_LOOKUP,
  SET_ACTIVITY_LOOKUP,
  SET_EVENT_RULES,
} from './actionType';


const fetchFireArmTypes = service => () => dispatch => service.getFireArmTypes()
  .then(response => dispatch({ type: SET_TARGET_FIREARM_TYPES, payload: response.data }))
  .catch(() => dispatch({ type: SET_TARGET_FIREARM_TYPES, payload: [] }));

const fetchFireArmTypesLookUpById = service => id => dispatch => service.getFireArmTypesLookUpById(id)
  .then(response => dispatch({ type: SET_FIREARM_TYPES_LOOKUP, payload: response.data }))
  .catch(() => dispatch({ type: SET_FIREARM_TYPES_LOOKUP, payload: [] }));

const fetchActivityLookupByFireArmTypeID = service => id => dispatch => service.getActivityLookupByFireArmTypeID(id)
  .then(response => dispatch({ type: SET_ACTIVITY_LOOKUP, payload: response.data }))
  .catch(() => dispatch({ type: SET_ACTIVITY_LOOKUP, payload: [] }));

const fetchEventByActivityID  = service => id => dispatch => service.getEventByActivityID (id)
  .then(response => dispatch({ type: SET_EVENT_RULES, payload: response.data }))
  .catch(() => dispatch({ type: SET_EVENT_RULES, payload: [] }));

export default function(fireArmTypeService) {
  return {
    fetchFireArmTypes: fetchFireArmTypes(fireArmTypeService),
    fetchFireArmTypesLookUpById: fetchFireArmTypesLookUpById(fireArmTypeService),
    fetchActivityLookupByFireArmTypeID: fetchActivityLookupByFireArmTypeID(fireArmTypeService),
    fetchEventByActivityID: fetchEventByActivityID(fireArmTypeService),
  };
}