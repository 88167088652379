
import React, { Component } from 'react';
import {
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';

import GenericTable from '../GenericTable/GenericTable';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import { ProductionKeywords, QAKeywords } from './EventLookUpWords';
import {FRONT} from '../../../API/APIendpoint'
const columns = [
  { field: 'name', headerName: 'Application' },
  { field: 'date', headerName: localStorage.getItem('isMobile') === 'true' ? 'Date' : 'Approval Date'},
  { field: 'days', headerName: localStorage.getItem('isMobile') === 'true' ? 'Days' : 'Days Earned'},
];

class ProfileLoyaltyProgramme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysEarned: [
        {title: localStorage.getItem('isMobile') === 'true' ? 'Course Days' : 'Course Days Earned', days: props.LoyaltyDays.daysEarned.courseDays || 0, keywords: ['course','Quiz']},
        {title: localStorage.getItem('isMobile') === 'true' ? 'Endorsement Days' : 'Endorsement Days Earned', days: props.LoyaltyDays.daysEarned.endorsementDays || 0, keywords: ['endorsement']},
        {
          title: localStorage.getItem('isMobile') === 'true' ? 'Activity Days' :'Activity Days Earned',
          days: props.LoyaltyDays.daysEarned.generalActivityDays || 0,
          keywords: window.location.hostname == 'www.narfo.co.za' ? ProductionKeywords : QAKeywords,
          shouldExclude: false,
        },
        {title: localStorage.getItem('isMobile') === 'true' ? 'Dedicated Status Days' :'Dedicated Status Days Earned', days: props.LoyaltyDays.daysEarned.dedicatedStatusDays || 0,
         keywords: window.location.hostname == 'www.narfo.co.za' ? ['course','Quiz', 'activity', 'endorsement',...ProductionKeywords] : ['course','Quiz', 'activity', 'endorsement',...QAKeywords],
         shouldExclude :true,
      },
      ],
      filteredLoyaltyHistory: [],
      showLoyaltyHistory: false,
      title: '',
    };
    console.log("env is " + window.location.hostname)
  }

  filterLoyaltyHistory = (keywords, title, shouldExclude) => {
    const regex = new RegExp( keywords.join( '|' ), 'i');

    const filteredResult = this.props.LoyaltyDays.loyaltyHistory
      .filter(({name}) => shouldExclude ? !regex.test(name) : regex.test(name));
    
    const sortedResult = filteredResult.sort(
      (a, b) =>  new Date(b.date).valueOf() - new Date(a.date).valueOf());
      
    sortedResult.forEach(x => 
      x.date = x.date
    );
    this.setState({
      filteredLoyaltyHistory: sortedResult,
      showLoyaltyHistory: true,
      title
    });
  }

  daysEarned = ({title, days, keywords, shouldExclude}) => (
    <Grid container className="mb-5" key={title}>
      <Grid item xs={days > 0 ? 10 : 12 }><Typography variant="body2" align="left">{title}</Typography></Grid>
      { days > 0 && 
        <Grid item xs={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => this.filterLoyaltyHistory(keywords, title, shouldExclude)}
          > View
          </Button>
        </Grid>
      }
      <Grid item xs={12}><Typography variant={localStorage.getItem('isMobile') === 'true' ? 'h6' : 'body1'} align="left">{ days || 0 } days</Typography></Grid>
    </Grid>
  );

  displayLoyaltyHistory = (rows) => (
    <React.Fragment>
      <SubtitleBar
        variant="subtitle2"
        title={this.state.title} back
        onBack={() => this.setState({showLoyaltyHistory: false})}
      />
      <GenericTable columns={columns} rows={rows}/>
    </React.Fragment>
  );

  diplayEarnedDays = (totalDays) => (
    <React.Fragment>
      <SubtitleBar variant="subtitle1" title="Loyalty Programme" />
      <div className="pl-3" align="right">
        <Typography variant="h4">Total Days Earned</Typography>
        <Typography variant={localStorage.getItem('isMobile') === 'true' ? 'h6' : 'body1'} align="left">{totalDays || 0} days</Typography>
      </div>
      <div className="inner-shadow border-radius-2 p-4 bg-white-2 mt-5 mb-5">
        {this.state.daysEarned.map(this.daysEarned)}
      </div>
    </React.Fragment>
  );

  render() {
    const totalDays = this.state.daysEarned.reduce((a, b) => a + b.days, 0);
    return (
      <div className="pb-4">
        {
          this.state.showLoyaltyHistory ?
            this.displayLoyaltyHistory(this.state.filteredLoyaltyHistory) :
            this.diplayEarnedDays(totalDays)
        }
      </div>
    );
  }
}

ProfileLoyaltyProgramme.defaultProps = {
  daysEarned: {},
  loyaltyHistory: [],
};

ProfileLoyaltyProgramme.propTypes = {
  daysEarned: PropTypes.shape({
    courseDays: PropTypes.number,
    endorsementDays: PropTypes.number,
    generalActivityDays: PropTypes.number,
    dedicatedStatusDays: PropTypes.number,
  }).isRequired,
  loyaltyHistory: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      date: PropTypes.string,
      days: PropTypes.number,
    })
  ).isRequired,
};

export default ProfileLoyaltyProgramme;
