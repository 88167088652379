import React, { Component } from 'react';
import LicenseRemindersDetails from '../../components/LicenseReminders/LicenseRemindersDetails';
import LicenseApplicationDetails from '../../components/LicenseApplications/LicenseApplicationDetails';
import Container from '../../hoc/Container';
import { connect } from 'react-redux';
import LicenseRemindersServices from '../../services/licence.service';
import { printLicenseReminders } from '../../shared/pdfHelpers';
import DedicatedService from '../../services/dedicatedstatus.service';
import MemberService from '../../services/member.service';
import { MEMBER_STATUS } from '../../shared/constants';
import { getMemberStatusFromInt } from '../../shared/helpers';
import {TabGroup} from '../../components'
class LicenseRemiders extends Component {
  constructor(props) {
    super(props);
    this.licenseRemindersService = LicenseRemindersServices(this.props.token);
    this.dedicatedService = DedicatedService(this.props.token);
    this.memberService = MemberService(this.props.token);
    this.state = {
      _firearmLicence: []
    };
  }

  downloadLicenseReminders = (licenseReminders, identifier) => this.dedicatedService
    .getApprovedDedicatedStatus(this.props.memNo)
    .then((res) => {
      let dsHunter = res.data
        .find(ds => ds.dstype.toLowerCase().includes('hunter') &&
          ds.status === MEMBER_STATUS.APPROVED) || {};
      let dsSports = res.data
        .find(ds => ds.dstype.toLowerCase().includes('sport') &&
          ds.status === MEMBER_STATUS.APPROVED) || {};
      return this.memberService.getAllImages()
        .then((images) => printLicenseReminders(
          licenseReminders,
          dsHunter.dsno,
          dsSports.dsno,
          identifier,
          images.data.narfo,
          { 
            ...this.props.memberDetails,
            status: getMemberStatusFromInt(this.props.memberDetails.status)
          },
        ));
    });

    tabViews = (isMobile)  => [
      {
        label: isMobile ? 'Reminders' : 'License reminders',
        view: <LicenseRemindersDetails
          getFirearmLicence={this.licenseRemindersService.getFirearmLicence}
          updateLicenseReminderCallback={this.licenseRemindersService.updateLicenseReminderCallback}
          deleteLicenseReminderCallback={this.licenseRemindersService.deleteLicenseReminderCallback}
          addLicenseReminderCallback={this.licenseRemindersService.addLicenseReminderCallback}
          downloadLicenseRemindersCallback={this.downloadLicenseReminders}
          isMobile={isMobile}
        />
      },
      {
        label: isMobile ? 'Applications' : 'License application tracking',
        view: <LicenseApplicationDetails
          getFirearmsLicenceApplication={this.licenseRemindersService.getLicenseApplicationsByMemNo(this.props.memNo)}
          deleteLicenseApplicationCallback={this.licenseRemindersService.deleteLicenseApplicationCallback}
          addLicenseApplicationCallback={this.licenseRemindersService.postLicenseApplication}
          isMobile={isMobile}
        />
      },
    ];

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      <Container title="License Portal">
        <TabGroup items={this.tabViews(isMobile)} loading={this.state.loading}/>

      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AuthReducer.token,
  memNo: state.AuthReducer.memNo,
  memberDetails: state.ProfileReducer.memberData,
});

export default connect(mapStateToProps)(LicenseRemiders);