import axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../../API/APIendpoint";
import "./MainAbout.css";
import { toast } from 'react-toastify';
toast.configure();
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      Name: "",
      Email: "",
      Subject: "",
      Message: "",
      _invalidName: "",
      _invalidEmail: "",
      _invalidSubject: "",
      _invalidMessage: "",
      validEmailTester: /^[a-zA-Z0-9]+@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$/
    }
  }
  checkForm = e => {
    e.preventDefault();
    if(this.state.Name.length === 0)
        this.setState({_invalidName: "Please enter name"})
    if(!(this.state.validEmailTester.test(this.state.Email)))
      this.setState({_invalidEmail: "Please enter valid email"})
    if(this.state.Subject.length=== 0)
      this.setState({_invalidSubject: "Please enter Subject"})
    if(this.state.Message.length === 0)
      this.setState({_invalidMessage: "Please enter Message"})
    if(this.state.Name.length !== 0 && this.state.validEmailTester.test(this.state.Email) &&
       this.state.Subject !== 0 && !this.state.Message !== 0){
      this.handleSubmit();
    }
  }
  handleSubmit = () => {
    const details = {
      fromEmail: this.state.Email,
      SenderName: this.state.Name,
      Subject: this.state.Subject,
      Content: this.state.Message
    };
    axios({
      url: `${BASE}api/AutomatedEmail/post/ContactUsQueryMailToAdmin`,
      data: details,
      method: "post"
    })
    .then(res => {
      if (res.data.status === "success") {
        this.setState({
          submitted: true
        })
      } else {
        toast.warning("Something went wrong please try again");
      }
    });
  }
  render() {
    return(
      <div className="container mb-3">
        {!this.state.submitted ?
        <div>
          <h3 align="center" className="font-weight-bold p-3 mb-0">
          Contact Us
          </h3>
          <h5 align="center" className="font-weight-bold p-3 mb-0">
            The responsibility lies with you
          </h5>
          <h6 className="text-secondary mt-1">Enter Your Name</h6>
          <input
            type="text"
            className="form-control"
            onInput={a => {
              this.setState({
                Name: a.target.value
              });
            }}
          />
          {this.state.Name.length === 0 ? 
            <div className="text-danger">{this.state._invalidName}</div> : null}
          <h6 className="text-secondary mt-1">Enter Your Email</h6>
          <input
            type="text"
            className="form-control"
            onInput={a => {
              this.setState({
                Email: a.target.value
              });
            }}
          />
          {!(this.state.validEmailTester.test(this.state.Email)) ? 
            <div className="text-danger">{this.state._invalidEmail}</div> : null}
          <h6 className="text-secondary mt-1">Enter Subject</h6>
          <input
            type="text"
            className="form-control"
            onInput={a => {
              this.setState({
                Subject: a.target.value
              });
            }}
          />
          {this.state.Subject.length === 0 ? 
            <div className="text-danger">{this.state._invalidSubject}</div> : null}
          <h6 className="text-secondary mt-1">Enter Your Message</h6>
          <textarea
            className="form-control"
            id="motivation"
            placeholder="Type in your message"
            onInput={a => {
              this.setState({
                Message: a.target.value
              });
            }}
            rows="6"
          />
          {this.state.Message.length === 0 ? 
            <div className="text-danger">{this.state._invalidMessage}</div> : null}
          <div className="placeAtEnd">
            <button className="btn btn-warning mt-3" onClick={e => this.checkForm(e)}>
              SUBMIT
            </button>
          </div>
        </div> :
        <div className="font-weight-bold text-success" 
          align="center" 
          display="flex"
          style={{ marginTop: "60px", fontSize: "20px"}}
        >
          Thank You for subitting a request. Someone from our team will be in contact soon.
        </div>}
      </div>
    );
  }
}
export default ContactUs;
