import * as actionTypes from "../actions/actionTypes";

const initialState = {
  base64Images: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BASE64_IMAGES:
      if(!action.base64Images){
        return state;
      } else {
        return {
          ...state,
          base64Images: action.base64Images
        };
      }
    default:
      return state;
  }
};

export default reducer;
