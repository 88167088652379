import * as actionTypes from "../actions/actionTypes";

const initialState = {
  adminPortal: {
    currentActivityTab: "0",
    currentLicenseTab: "0",
    currLmsTab: "0",
    currentAdminEndorsementTab: "0",
    preview: "",
    userEndorsementList: [],
    src: ""
  },
  currActivityTab:0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB2:
      return {
        ...state,
        currActivityTab: action.currActivityTab
      };
    case actionTypes.UPDATE_CURRENT_ADMIN_ACTIVITY_TAB:
      return {
        ...state,
        currentActivityTab: action.currTab
      };
    case actionTypes.UPDATE_CURRENT_ACTIVITY_TAB:
      return {
        ...state,
        adminPortal: action.currTab
      };

    case actionTypes.UPDATE_ADMIN_PORTAL:
      const newAdminPortal = {
        ...state.adminPortal,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        adminPortal: newAdminPortal
      };
    default:
      return state;
  }
};

export default reducer;
