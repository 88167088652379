import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ValidatorForm } from "react-form-validator-core";
import EndorsementHeaderApplication from "./EndorsementHeaderApplication";
import * as actionTypes from "../../../store/actions/actionTypes";
import { BASE, GET_FIREARMTYPE_LIST } from "../../../API/APIendpoint";
import Axios from "axios";
class EndorsementApplicationFirearm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      FirearmType_List: [],
      _Actions: [],
      isValid: false,
      errorState: false
    };
  }

  componentDidMount() {
    Axios({
      url: BASE + "api/Actions",
      method: "get",
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    }).then(res => {
      this.setState({
        FirearmType_List: res.data.fireArmTypes,
        _Actions: res.data.actions
      });
    })
  }

  handleChange = name => event => {
    this.props.onAddEndorsementDetailsChange(event.target.value, [name]);
  };

  handleChange(event) {
    this.setState({ value: event.target.value });
  }
  handleSubmit = () => {
    if (this.props.newActDetails.FirearmType !== "" && this.props.newActDetails.Caliber !== "" && this.props.newActDetails.Model !== "" && this.props.newActDetails.Make !== "" && this.props.newActDetails.SerialNumber !== "") {
      this.props.onCurrentEndorsementTabChange("3");
    } else {
      this.setState({ errorState: true })
    }

  }
  componentWillMount() {
    // custom rule will have name 'isPasswordMatch'
    ValidatorForm.addValidationRule("isEmpty", value => {
      if (value.length <= 1) {
        return false;
      }
      return true;
    });
  }

  render() {
    return (
      <div className="container">
        <EndorsementHeaderApplication />
        <form>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="firearm" align="left">
                  Firearm Type
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <select
                  className="form-control"
                  native
                  name="branches"
                  label="Number of questions"
                  align="left"
                  value={this.props.newActDetails.FirearmType}
                  onChange={this.handleChange("FirearmType")}
                >
                  <option>Select Firearm</option>
                  {this.state.FirearmType_List.map(firearmType => (
                    <option
                      key={firearmType.fireArmTypeId}
                      value={firearmType.fireArmType}
                    >
                      {firearmType.type}
                    </option>
                  ))}
                </select>
                {this.state.errorState === true && this.props.newActDetails.FirearmType === "" ? <p className="text-danger m-0">Choose Firearm Type</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="firearm" align="left">
                  Action Types
                </label>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-12">
                <select
                  className="form-control"
                  native
                  name="branches"
                  label="Number of questions"
                  align="left"
                  value={this.props.newActDetails.ActionType}
                  onChange={this.handleChange("ActionType")}
                >
                <option>Select Actions</option>
                  {this.state._Actions.map((act, key) => (
                    <option
                      key={key}
                      value={act.action1}
                    >
                      {act.action1}
                    </option>
                  ))}
                </select>
                {this.state.errorState === true && this.props.newActDetails.ActionType === "" ? <p className="text-danger m-0">Choose Action Type</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="caliber" align="left">
                  Caliber
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="caliber"
                  onChange={this.handleChange("Caliber")}
                  value={this.props.newActDetails.Caliber}
                />
                {this.state.errorState && this.props.newActDetails.Caliber === "" ? <p className="text-danger m-0">this field is required</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="make" align="left">
                  Make
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="make"
                  value={this.props.newActDetails.Make}
                  onChange={this.handleChange("Make")}
                />
                {this.state.errorState && this.props.newActDetails.Make === "" ? <p className="text-danger m-0">this field is required</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="model" align="left">
                  Model
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="model"
                  value={this.props.newActDetails.Model}
                  onChange={this.handleChange("Model")}
                />
                {this.state.errorState && this.props.newActDetails.Model === "" ? <p className="text-danger m-0">this field is required</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-3">
            <div className="row">
              <div className="col-12">
                <label for="serialnumber" align="left">
                  Serial Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="serialnumber"
                  value={this.props.newActDetails.SerialNumber}
                  onChange={this.handleChange("SerialNumber")}
                />
                {this.state.errorState && this.props.newActDetails.SerialNumber === "" ? <p className="text-danger m-0">Enter Serial Number</p> : null}
              </div>
            </div>
          </div>
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5" />
          <div className="form-group mt-5">
            <div>
              <button
                type="button"
                className="btn btn-outline-dark text-black float-left"
                onClick={() => this.props.onCurrentEndorsementTabChange("1")}
              >
                Previous
              </button>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-warning text-white float-right"
                onClick={this.handleSubmit}
              >
                Next
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

EndorsementApplicationFirearm.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    currentEndorsementTab: state.endorsementReducer.currentEndorsementTab,
    newActDetails: state.endorsementReducer.addEndorsementDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrentEndorsementTabChange: newTabName =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
        currTab: newTabName
      }),
    onAddEndorsementDetailsChange: (value, variableName) =>
      dispatch({
        type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_ADD_ENDORSEMENT_DETAILS,
        varValue: value,
        varName: variableName
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndorsementApplicationFirearm);
