import API_URL from './apiUrl';
import axios from 'axios';

const getAllNewsLetter = headers => () => axios({
	url: `${API_URL}/NewsArticles/GetAllNewsByTheLatest`,
	method: "get",
	headers: headers
  });

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getAllNewsLetter: getAllNewsLetter(headers)
  };
}
