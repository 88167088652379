import React, { Component } from "react";
import Buttons from "./Buttons";

export default class Motivation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendedActivities: null,
      activityNotAttendanceMotivation: null,
      applicationMotivation: null,
      error: ""
    };
    this.validate = this.validate.bind(this);
    this.next = this.next.bind(this);
  }

  validate() {
    let valid = true;
    if (!this.state.attendedActivities && !this.state.applicationMotivation)
      valid = false;
    if (
      this.state.attendedActivities === true &&
      !this.state.activityNotAttendanceMotivation
    )
      valid = false;
    return valid;
  }

  next() {
    if (this.validate()) {
    }
  }
  render() {
    return (
      <div>
        <div className="container bg-light">
          <div className="row m-0 p-0">
            <p className="col-12 mb-0">
              {`Have you attended any ${
                this.props.dsType === "Dedicated Sports Person"
                  ? "sport shooting"
                  : "hunting"
              } \
                             related activities, either on your own or any \
                         activity of NARFO or any other association?`}
            </p>
          </div>
          <div className="row form-check-inline mb-3">
            <div className="col">
              <label className="form-check-label ml-5 ">
                <input
                  type="radio"
                  className="form-check-input"
                  value="Yes"
                  name="huntingRelatedActivity"
                  checked={
                    this.props.DSApplicationState.Participation === "Yes"
                  }
                  onChange={e => {
                    this.setState({ attendedActivities: e.target.value });
                    this.props.setDSVariables("Participation", e.target.value);
                  }}
                />
                Yes
              </label>
            </div>
            <label className="form-check-label ml-3">
              <input
                type="radio"
                className="form-check-input"
                name="huntingRelatedActivity"
                value="No"
                checked={this.props.DSApplicationState.Participation === "No"}
                onChange={e => {
                  this.setState({ attendedActivities: e.target.value });
                  this.props.setDSVariables("Participation", e.target.value);
                }}
              />
              No
            </label>
          </div>
          {this.props.DSApplicationState.Participation === "No" && (
            <div className="row">
              <div className="form-group  w-100">
                <label htmlFor="usr" className="ml-3">
                  Please provide the details, if the answer is no, please
                  motivate and elaborate
                </label>
                <textarea
                  className="form-control mr-lg-3"
                  cols="38"
                  rows="4"
                  value={this.props.DSApplicationState.NonParticipationReason}
                  placeholder="Please provide a reason"
                  onChange={e =>
                    this.props.setDSVariables(
                      "NonParticipationReason",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="form-group  w-100">
              <label htmlFor="usr" className=" ml-4">
                {`Motivate why you are applying for \
                                 a ${
                                   this.props.dsType === "Dedicated Sports Person"
                                     ? "sport shooting"
                                     : "dedicated hunting"
                                 } \
                                 status`}
              </label>
              <textarea
                className="form-control mr-lg-3"
                cols="38"
                rows="4"
                value={this.props.DSApplicationState.Motivation}
                placeholder="Please provide a reason"
                onChange={e =>
                  this.props.setDSVariables("Motivation", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="row">
          <p className="col-12">{this.props.error}</p>
        </div>
        <Buttons
          changeStep={this.props.changeStep}
          currentStep={this.props.currentStep}
        />
      </div>
    );
  }
}
