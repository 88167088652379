import React, { Component} from "react";
import { Editor } from "@tinymce/tinymce-react";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { COURSE_MATERIAL } from "../LMSAPIEndPoints";
import "./styles/materialEditor.css";
import axios from "axios";

class CourseMaterialEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.material.name,
      content: this.props.material.content,
      saveMessageStatus: 'Save',
      save: false
    };
  }

  onSaveCourseMaterial = () => {
    const material = this.props.material;
    const data = {
      CourseMaterialId: material.courseMaterialId,
      Name: this.state.title,
      Content: this.state.content,
      CourseModuleId: material.courseModuleId,
      Index: material.index
    }
    this.setState({
      saveMessageStatus: <div className="text-warning d-inline-block flex-grow-1">Saving...</div>,
      save: true
    })
    axios({
      url: COURSE_MATERIAL,
      data: data,
      method: "post",
      headers: {
        "Content-type": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
      }
    })
      .then(res => {
        this.props.refresh();
        this.setState({
          saveMessageStatus: <div className="text-success d-inline-block flex-grow-1">{res.message}</div>
        });
        setTimeout(() => {
          this.props.openEditor(false);
        }, 1500);
      })
      .catch(error => {
        this.setState({
          saveMessageStatus: <div className="text-danger d-inline-block flex-grow-1">Failed to save! {error.message ? error.message : 'Try again'}</div>
        })
        setTimeout(() => {
          this.setState({
            save: false
          })
        }, 2000);
      })
  }

  render() {
    return (
      <div className="material-editor-root">
        <div className="material-editor-header">
          <div className="material-editor-header-back-button"
            onClick={() => { this.props.openEditor(false) }}>
            <KeyboardArrowLeft
              style={{ width: "50px", height: "35px" }}
            />
          </div>
          {this.state.save ?
            this.state.saveMessageStatus :
            <div className="input-container">
              <div className="material-editor-header-input">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add title"
                  defaultValue={this.props.material.name}
                  onChange={(e) => {
                    this.setState({
                      title: e.target.value
                    })
                  }}
                />
              </div>
              <div className="material-editor-header-save-button">
                <button
                  className="btn btn-outline-success form-control"
                  onClick={() => {
                    this.onSaveCourseMaterial();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          }
        </div>
        <div className="material-editor-header-editor">
          <Editor
            initialValue={this.state.content}
            init={{
              plugins: "link image code",
              toolbar:
                "undo redo | bold italic | alignleft aligncenter alignright | image | link | cut | copy | paste | font",
              height: "750px",
              file_picker_types: 'image',
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');
                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                }
                input.click();
              }
            }}
            apiKey="kmpgx6hmv3ix0abz8fn9sly4r5vf2fzw0tw2ubm88mhydjba"
            onChange={(e) => {
              this.setState({
                content: e.target.getContent()
              })
            }}
          />
        </div>
      </div>
    );
  }
}

export default CourseMaterialEditor;
