import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { LMS_ACTIONS } from "../../../store/actions/actionTypes";
import Radio from "@material-ui/core/Radio";
import "../../editMemberDetails/profile.css";
class CommissionPaymentDetails extends Component {
  constructor(props){
    super(props);
  }
  componentWillMount() {
    this.props.onSalesBankingDetailsViewChange({_viewSalesBankDetails: true, _viewPartnerBankingDetails: false});
    this.props.onAddAccountDetailsChange(0, "currentAccountTab");
    this.props.onCurrentLMSTabChange(0);
    this.props.onCurrentEndorsementTabChange("0");
    this.props.onCurrActivityTabChange("0", "currentActivityTab");
    this.props.onCurrentLicenseTabChange("0", "currentLicenseTab");
    this.props.onEditObjectGet(this.props.bankingDetails.filter(element => element.MemType === "Sales")[0]);
    
  }
  render() {
    const _bankingDetailsSales = this.props.bankingDetails.filter(element => element.MemType === "Sales")[0]; 
    const _bankingDetailsPartner = this.props.bankingDetails.filter(element => element.MemType === "Partner")[0]; 
    const _monthlyCommission = this.props.monthlyCommission.monthlyCommission;
    const _bankingDetailsObject = this.props.bankDetailsViewObj;
    var width1 = "";
    var width2 = "";
    if (isMobile) {
      width1 = "35%"
      width2 = "22%"
    } else {
      width1 = "29%"
      width2 = "40%"
    }
    const info = (row1, row2) => (
      <tbody>
        <tr>
          <td  style={{ width: width1 }}>{row1}</td>
          {isMobile ? null : <td style={{ width: "48%" }} />}
          <td className="font-weight-bold" style={{ width: width2 }}>{`: ${row2}`}</td>
        </tr>
      </tbody>
    );
    return (
        <div className="container mb-2">
            <div className="row ml-1 mr-1">
            {this.props.isAdmin ?
              <GenericHeader
                title="Payment Details"
                showBackButton
                backFunction={() => {
                  this.props.onMothlyCommissionGet(_monthlyCommission);
                  this.props.onCommissionTabChange("0");
                }}
              /> :
              <GenericHeader
              title="Payment Details"
              showBackButton
              backFunction={() => {
                this.props.onMothlyCommissionGet(_monthlyCommission);
                this.props.onCommissionTabChange("0");
              }}
              showEditButton
              editFunction={() => this.props.onCommissionTabChange("3")}
            />}
            </div>
            {this.props.isPartner ?
              <div>
              <div className="ml-4">
              <div>Choose Bank</div>
                <Radio
                  checked={_bankingDetailsObject._viewSalesBankDetails}
                  onChange={() => {this.props.onSalesBankingDetailsViewChange({_viewSalesBankDetails: true, _viewPartnerBankingDetails: false});
                                  this.props.onEditObjectGet(_bankingDetailsSales);}}
                />
                Rep Banking Details
                <Radio
                  checked={_bankingDetailsObject._viewPartnerBankingDetails}
                  onChange={() => {this.props.onSalesBankingDetailsViewChange({_viewPartnerBankingDetails: true, _viewSalesBankDetails: false})
                                  this.props.onEditObjectGet(_bankingDetailsPartner);}}
                />
                Partner Banking Details
              </div>
              <table className="ml-4" style={{ width: "80%" }}>
                  {info("Bank Name", this.props.bankDetailsViewObj._viewSalesBankDetails?_bankingDetailsSales.BankName:_bankingDetailsPartner.BankName)}
                  {info("Account Number", this.props.bankDetailsViewObj._viewSalesBankDetails?_bankingDetailsSales.AccountNo:_bankingDetailsPartner.AccountNo)}
                  {info("Banch Code", this.props.bankDetailsViewObj._viewSalesBankDetails?_bankingDetailsSales.BranchCode:_bankingDetailsPartner.BranchCode)}
                  {info("Account Type", this.props.bankDetailsViewObj._viewSalesBankDetails?_bankingDetailsSales.AccountType:_bankingDetailsPartner.AccountType)}
              </table>
            </div> :
            <table className="ml-4" style={{ width: "80%" }}>
              {info("Bank Name", _bankingDetailsSales.BankName)}
              {info("Account Number", _bankingDetailsSales.AccountNo)}
              {info("Banch Code", _bankingDetailsSales.BranchCode)}
              {info("Account Type", _bankingDetailsSales.AccountType)}
            </table>}
        </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    bankDetailsViewObj: state.CommissionRecordReducer.bankDetailsViewObj,
    isAdminMemView: state.loginReducer.memberState.isAdminMemView,
    isAdmin: state.loginReducer.memberState.isAdmin,
    bankingDetails: state.CommissionRecordReducer.bankingDetails,
    monthlyCommission: state.CommissionRecordReducer,
    memberDetails: state.loginReducer.memberObject,
    isPartner: state.CommissionRecordReducer.isPartner
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onSalesBankingDetailsViewChange: BankingDetails =>
        dispatch({ 
            type: actionTypes.UPDATE_BANKING_DETAILS_VIEW, 
            bankDetailsViewObj: BankingDetails 
        }),
    onMothlyCommissionGet: mCom =>
        dispatch({ 
            type: actionTypes.MONTHLY_COMMISSION, 
            monthlyCommission: mCom 
        }),
    onEditObjectGet: editObject =>
        dispatch({
          type: actionTypes.BANKING_DETAILS_EDIT,
          bankEditObject: editObject
        }),
    onAddAccountDetailsChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_ACCOUNT,
          varValue: value,
          varName: variableName
        }),
    onCommissionTabChange: newComTab =>
        dispatch({ 
            type: actionTypes.UPDATE_COMMISSION_TAB, 
            currentCommissionTab: newComTab 
        }),
    onLoyaltyDaysTabChange: pageNum =>
        dispatch({ 
            type: actionTypes.UPDATE_LOYALTY_DAYS_TAB, 
            currentLoyaltyDaysTab: pageNum 
        }),
    onCurrentLMSTabChange: newCurrentLmsTab =>
        dispatch({
          type: LMS_ACTIONS.UPDATE_CURRENT_LMS_TAB,
          currLmsTab: newCurrentLmsTab
        }),
    onCurrentEndorsementTabChange: newTabName =>
        dispatch({
          type: actionTypes.ENDORSEMENT_ACTIONS.UPDATE_CURRENT_ENDORSEMENT_TAB,
          currTab: newTabName
        }),
    onCurrActivityTabChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: vname
        }),
    onCurrentLicenseTabChange: (value, variableName) =>
        dispatch({
          type: actionTypes.UPDATE_MEMBERS_AREA,
          varValue: value,
          varName: variableName
        })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CommissionPaymentDetails));
