import * as actionTypes from "../actions/actionTypes";

const initialState = {
  _application: {},
  pendingAE: {},
  pendingDS: {
    pendingDS: [],
    dsCount: 0
  },
  membersDSS: {},
  membersDH: {},
  membersPending: {},
  membersRenewal: {},
  totalMembers: {},
  pendings: [],
  activities: {
    pendingCount: 0,
    activities: []
  },
  newMembers: {
    pendingNMCount: 0,
    newMembers: []
  },
  newRenewals: {
    pendingNRCount: 0,
    newRenewals: []
  },
  endorsements: {
    pendingEndorsementCount: 0,
    pendingEndorsements: [],
    allEndorsements: [],
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING_ENDORSEMENTS: //remove this
      return {
        ...state,
        endorsements: action.endorsements  //wrong
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_ACTIVITY_OBJECT:
      return {
        ...state,
        activities: action.activities
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_DS_APPLICATIONS:
      return {
        ...state,
        _application: action._pending
      };
    case actionTypes.DASHBOARD_ACTIONS.GET_ALL_ENDORSEMENTS_URL:
      return {
        ...state,
        endorsements: action.endorsements
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS:
      return {
        ...state,
        membersPending: action.membersPending
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_RENEWAL_MEMBERS:
      return {
        ...state,
        membersRenewal: action.membersRenewal
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_DH_MEMBERS:
      return {
        ...state,
        membersDH: action.membersDH
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_DSS_MEMBERS:
      return {
        ...state,
        membersDSS: action.membersDSS
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_AE_PENDINGS:
      return {
        ...state,
        pendingAE: action.pendingAE
      };
    case actionTypes.DASHBOARD_ACTIONS.GET_ACTIVITIES:
      return {
        ...state,
        activities: action.activities
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_DS_PENDINGS:
      return {
        ...state,
        pendingDS: action.pendingDS
        // pendingDS: !action.pendingDS ? null : action.pendingDS
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING:
      return {
        ...state,
        pending: action.pending
      };
    case actionTypes.DASHBOARD_ACTIONS.UPDATE_TOTAL_MEMBERS:
      return {
        ...state,
        totalMembers: action.totalMembers
      };
    case actionTypes.DASHBOARD_ACTIONS.GET_ALL_RENEWAL_MEMEBRS_URL:
      return {
        ...state,
        newRenewals: action.newRenewals
      };
    case actionTypes.DASHBOARD_ACTIONS.GET_ALL_PENDING_MEMBERS_URL:
      return {
        ...state,
        newMembers: action.newMembers
      };
    default:
      return state;
  }
};

export default reducer;
