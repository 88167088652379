import * as actionTypes from "../actions//actionTypes";

const initialState = {
  membersArea: {
    memberName: "",
    dedicatedHunterStatus: "",
    dedicatedSportShooterStatus: "",
    currentActivityTab: "0",
    currentLicenseTab: "0",
    currLmsTab: "0",
    src: "",
    activityDocuments: [],
    profilePicture: null,
    updateText: "Approve",
    rejectText: "Reject",
    viewedActivity: {
      activityType: "",
      activityDate: "",
      status: "",
      activityId: "",
      activityDescription: "",
      firearmType: "",
      caliber: "",
      association: ""
    }
  }
};

const reducer = (state = initialState, action) => {
  let newMembersArea;
  switch (action.type) {
    case actionTypes.UPDATE_MEMBERS_AREA:
      newMembersArea = {
        ...state.membersArea,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        membersArea: newMembersArea
      };
    case actionTypes.RESET_MEMBERS_VIEWED_ACTIVITY:
      newMembersArea = {
        ...state.membersArea,
        viewedActivity: {
          activityType: "",
          activityDate: "",
          status: "",
          activityId: "",
          activityDescription: "",
          firearmType: "",
          caliber: "",
          association: ""
        },
        profilePicture: null,
        updateText: "Approve",
        rejectText: "Reject"
      };
      return {
        ...state,
        membersArea: newMembersArea
      };
    default:
      return state;
  }
};

export default reducer;
