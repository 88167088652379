export  const activityStyles = {
    card: {
      minWidth: 500
    },
    
    root: {
      flex: 1,
      flexDirection: "row",
      borderBottom: "1.5px solid #D8D8D8"
    },

    title: {
      display: "inline-block",
      fontSize: 15,
      width: "20%",
      paddingRight: 20,
      paddingLeft: 20,
      color: "black"
    },

    content: {
      display: "inline-block",
      fontSize: 14,
      color: "black"
    }
  };

 export const activityDetailsStyles = {
    card: {
      minWidth: 500,
      margin: 10
    },

    mainCard: {
      minWidth: 500,
      margin: 10,
      backgroundColor: "#d1d1d1"
    },

    root: {
      flex: 1,
      flexDirection: "row",
      borderBottom: "1px solid #D8D8D8"
    },

    title: {
      display: "inline-block",
      fontSize: 15,
      width: "20%",
      paddingRight: 20,
      paddingLeft: 20,
      color: "black"
    },

    content: {
      display: "inline-block",
      fontSize: 14,
      color: "black"
    },

    statusChange: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly"
    },

    paper: {
      padding: 1,
      backgroundColor: "#d8d8d8"
    },

    cardActions: {
      flexDirection: "row-reverse"
    },

    isApproved: {
      backgroundColor: "#6BC20A",
      color:"white"
    },

    isRejected: {
      backgroundColor: "#D0021B",
      color:"white"
    }
  };

 export const mainActivitiesStyles = {
    root: {
      backgroundColor: "white",
      width: "100%",
      height: "100%",
      overflow: "auto"
    }
  };