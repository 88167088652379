import { withStyles } from "@material-ui/core/styles";
import DateRange from "@material-ui/icons/DateRange";
import Axios from "axios";
import React, { Component } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { BASE } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import { toast } from "react-toastify";
import { getCurrentDate } from "../adminPortal/Approvals/ApproveDashboardEndors";
import EditUserDetails from "./EditUserDetails";
toast.configure();
const styles = {};
const UserStatus = Object.freeze({
  1: "Approved",
  2: "Pending",
  3: "Rejected",
  4: "Renewing",
});
class ViewUserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ExpiryDate: "",
      salesPerson: "",
      memberStatus: "",
      rejectionReason: null,
      rejectionDate: getCurrentDate(),
      _memStatus: UserStatus[this.props.MemberV.status],
      isEdit: false,
    };
  }
  componentWillMount() {

    this.props.onMemberStateChange(true, "isAdminMemView");
    this.props.onCommissionTabChange("0");
    Axios.get(
      BASE + "api/admin/getsalesPerson/" + this.props.MemberV.salesReps,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
        },
      }
    ).then((res) => this.setState({ salesPerson: res.data }));

  }
  updateUserDate = (e) => {
    const MemNo = this.props.MemberV.memNo;
    Axios({
      url: BASE + "api/Member/expiryDate/" + MemNo,
      data: {
        expiryDate: e.currentTarget.value,
      },
      method: "post",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
      },
    }).then((res) => {
      toast.success(res.data.message);
      this.setState({
        ExpiryDate: res.data.expiryDate,
      });
    });
  };
  updateMemberStatus = (e) => {
    e.preventDefault();
    const info = {
      memNo: this.props.MemberV.memNo,
      status: this.state.memberStatus,
      rejectionReason: this.state.rejectionReason,
      rejectionDate: this.state.rejectionDate,
    };
    Axios({
      url: `${BASE}api/MemberStatus`,
      data: info,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
      },
    }).then((res) => {
      toast.success(res.data.message);
      this.setState({
        ExpiryDate: res.data.updatedMember.expiryDate.split("T")[0],
        _memStatus: UserStatus[res.data.updatedMember.status],
        memberStatus: "",
      });
    });
  };

  updateMemberDetails = (userData) => {
    Axios({
      url: `${BASE}api/Member/adminUpdateMemberDetails`,
      data: userData,
      method: "post",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("tokenKey")}`,
      },
    }).then((res) => {
      toast.success(res.data.message);

      this.setState({ isEdit: false });
    });
  };

  backFunction = () => {
    this.setState({ isEdit: false });
  };

  editFunction = () => {
    this.setState({ isEdit: true });
  };

  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <div className={this.props.isPartner ? "mt-2 mb-1" : "container mb-3"}>
        {!this.state.isEdit ? (
          <>
            <GenericHeader
              title="Member Details"
              showEditMemberButton= {!this.props.isPartner}
              editFunction={this.editFunction}
              headerColor={this.props.headerColor} 
            />

            {info("Username:", this.props.MemberV.username)}
            {info("Member Number:", this.props.MemberV.memNo)}
            {info("ID Number:", this.props.MemberV.idno)}
            {info("First Names:", this.props.MemberV.firstname)}
            {info("Last Name:", this.props.MemberV.surname)}
            {info("Email:", this.props.MemberV.email)}
            {info("Cell Number:", this.props.MemberV.cellNo)}
            {info("Address:", this.props.MemberV.physicalAddress)}
            {info("Suburb:", this.props.MemberV.suburb)}
            {info("City:", this.props.MemberV.city)}
            {info("Province:", this.props.MemberV.province)}
            {info("Postal Code:", this.props.MemberV.postalCode)}
            {info("Gender:", this.props.MemberV.sex)}
            {info(
              "Sales Rep:",
              this.state.salesPerson
                ? this.state.salesPerson.firstname +
                    " " +
                    this.state.salesPerson.surname
                : "None"
            )}
            {info("Store:", this.props.MemberV.store)}
            {info("Ethnicity:", this.props.MemberV.ethinicity)}
            {info("Status:", this.state._memStatus)}
            {info("Type:", this.props.MemberV.memType)}
            {info("Inceptive Date:", this.props.MemberV.inceptionDate)}
            {info(
              "Expiry Date:",
              this.state.ExpiryDate === ""
                ? this.props.MemberV.expiryDate.substring(0, 10)
                : this.state.ExpiryDate.substring(0, 10)
            )}

            {this.props?.MemberV?.partnerExpiryDate !== null && this.props?.MemberV?.partnerExpiryDate && 
            info(this.props.MemberV?.store + " Expiry Date:",this.props.MemberV?.partnerExpiryDate?.substring(0, 10))}

            {!this.props.isPartner && (
              <>
                <div className="mt-2">Update date</div>
                <InputGroup className="mb-3 pl-0">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <DateRange />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="input"
                    type="date"
                    required
                    onChange={this.updateUserDate}
                  />
                </InputGroup>
                <div> Update Status</div>
                <div className="border p-4">
                  {info(
                    "Member Status",
                    <select
                      className="form-control input-sm m-0"
                      aria-describedby="emailHelp"
                      onInput={(a) => {
                        this.setState({
                          memberStatus: a.target.value,
                        });
                      }}
                    >
                      <option>Select New Status</option>
                      <option value="1">Approved</option>
                      <option value="2">Pending</option>
                      <option value="3">Rejected</option>
                      <option value="4">Renewing</option>
                    </select>
                  )}
                  {this.state.memberStatus === "3" ? (
                    <div>
                      {info(
                        "Rejection Reason:",
                        <input
                          type="text"
                          className="form-control"
                          onInput={(a) => {
                            this.setState({
                              rejectionReason: a.target.value,
                            });
                          }}
                        />
                      )}
                      <div className="mt-2">Rejection Date</div>
                      <InputGroup className="mb-3 pl-0">
                        <InputGroup.Prepend>
                          <InputGroup.Text>
                            <DateRange />
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          as="input"
                          type="date"
                          required
                          onInput={(a) => {
                            this.setState({
                              rejectionDate: a.target.value,
                            });
                          }}
                        />
                      </InputGroup>
                    </div>
                  ) : null}
                  {this.state.memberStatus !== "" ? (
                    <div className="placeAtEnd">
                      <button
                        className="btn btn-warning mt-3"
                        onClick={(e) => this.updateMemberStatus(e)}
                      >
                        UPDATE
                      </button>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="row">
              <GenericHeader
                title="Edit Member Details"
                showBackButton
                backFunction={() => this.backFunction()}
              />
            </div>

            <div className="row">
              <EditUserDetails updateMemberDetails={this.updateMemberDetails} />
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCommissionTabChange: (newComTab) =>
      dispatch({
        type: actionTypes.UPDATE_COMMISSION_TAB,
        currentCommissionTab: newComTab,
      }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value,
      }),
    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname,
      }),

    onMemberViewChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        varValue: value,
        varName: vname,
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ViewUserDetails));
