import React, { Component } from "react";
import "../styles/dstyle.css";
import defaultImg from "../images/dProfile.jpg";
import defaultImgGl from "../images/gl.jpg";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import GenericHeader from "../../../utils/GenericHeader";
import { styles } from "../../../utils/styles/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import * as ApproveEndorsementActions from "../Approvals/ApproveNewEndorsements/ApproveNewEndorsementsActions";
import Axios from "axios";
import { BASE } from "../../../API/APIendpoint";
import FadeLoader from "react-spinners/FadeLoader";

class PendingEndorsementApplication extends Component {
  constructor(props) {
    super(props);
    // this.findMember = this.findMember.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      members:[],
      loading: true
    }
  }

  findMember = MemNo => {
    return this.props.memberList.filter(
      endorsementApplications => endorsementApplications.memNo === MemNo
    );

  };

  componentDidMount() {
    this.props.endorsements.forEach(endorsement => {
      Axios({
        url: BASE + `api/Member/FindMemberByMemNo/${endorsement.memNo}`,
        method: "get",
        headers: {
          "Content-type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
        }
      }).then(res => {
            this.setState({members : [...this.state.members,res.data] });
        })
        
      this.setState({ loading: false});

    });

  }
  
  handleSubmit = (member, endorsement) => {
    this.props.onMemberViewChange(member);
    this.props.onNewMemberChange(member);
    this.props.ApproveEndorsementActions.setCurrentEndorsement(endorsement);
    this.props.history.push("/admin/ApproveEndorsementDashboard");
  };

  render() {
    const { endorsements } = this.props;
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="mb-4">
          <GenericHeader
            title="Endorsement Applications"
            showBackButton
            backFunction={() => {
              this.props.history.push("/admin/MainAdmin");
            }}
          />
        </div>

        {endorsements.length === 0 ? (
          <div className="text-warning font-weight-bold">There are currently no pending endorsements...</div>
        ) : (
          this.state.loading ?
          <div className="row" style={{ marginTop: "80px" }}>
            <div className="col-12 d-flex justify-content-center">
              <FadeLoader color="orange" margin="auto" />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <p className="font-weight-bold">
                Loading the endorsements. Please wait ...
              </p>
            </div>
          </div>
      :
          <ul className="ml-0 pl-1">
            {endorsements.map((e, index) => {
              let member = this.state.members.filter(mem => mem.memNo == e.memNo)[0];
              if(!member)
                return null;

              return (
                <div
                  key={index}
                  className="row h-4 mb-2 border-bottom pb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    this.handleSubmit(member, e);
                  }}
                >
                  <div className="row pl-2">
                    <div className="col-4 my-auto">
                      <div className="h6 mb-0 pr-2">
                        {member
                          ? `${member.firstname.toString().substring(0, 1)}`
                          : null}
                      </div>
                      <img src={member.sex === "Male" ? defaultImg : defaultImgGl} alt="Gun" className="avatar" style={{ width: "100px", height: "100px" }} />
                    </div>
                    <div className="col-8 pl-1 pt-3 " align="left">
                      <div className="h6">
                        {member ? `${member.firstname} ${member.surname}` : null}
                      </div>
                      <div className="h6">
                        {member ? `${member.idno}` : null}
                      </div>
                      <div className="h6">
                        {member ? `${member.email}` : null}
                      </div>
                      <div className="h6">
                        {member ? `Expiry Date: ${member.expiryDate.substring(0, 10)}` : null}
                      </div>
                      <div className="text-warning">
                        Pending Endorsement Approval
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    loggedIn: state.loginReducer.userProfileDetails.isLoggedIn,
    memberList: state.dashBoardReducer.totalMembers.members,
    endorsements: state.dashBoardReducer.endorsements.pendingEndorsements
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ApproveEndorsementActions: bindActionCreators(ApproveEndorsementActions, dispatch),
    onMemberViewChange: newMemberView =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW,
        MemberView: newMemberView
      }),
    onNewMemberChange: membersPending =>
      dispatch({
        type: actionTypes.DASHBOARD_ACTIONS.UPDATE_PENDING_MEMBERS,
        membersPending: membersPending
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PendingEndorsementApplication)));
