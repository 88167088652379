import React, { useState} from 'react';
import { FilterList } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import {
  EditableGridLabel,
  IconLabelButton
} from '../index';
import PropTypes from 'prop-types';

const hasError = (elements) => {
  let formHasError = false;
  elements.forEach(element => {
    if(!element.options.config.valid) {
      formHasError = true;
    }
  });
  return formHasError;
};

const generateResults = (elements) => {
  const results = {};
  elements.forEach(elem => results[elem.name] = elem.value);
  return results;
};

const Filter = (props) => {
  const { callBack,elements} = props;
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <div className="floating-container">
      <IconLabelButton
        disabled={openFilter}
        icon={<FilterList/>} actionText="Filter"
        callBack={() => setOpenFilter(true)}
      />
      <div className={`floating-content ${ openFilter ? '' : 'hide' }`}>
        <Grid container>
          { elements.map((element, index) =>
            <EditableGridLabel
              id={element.id}
              key={index}
              title={element.title}
              value={element.value}
              edit
              stretch
              options={element.options}
              onChange={element.onChange}
              direction="column-reverse"
            />)
          }
        </Grid>
        <Grid container>
          <Grid item xs={12} container justify="center" className="pt-4" spacing={2}>
            <Grid item xs={12} container justify="center">
              <IconLabelButton
                disabled={hasError(elements)}
                full
                color="primary" actionText="Search"
                callBack={() => callBack(generateResults(elements),setOpenFilter(false))}
              />
            </Grid>
            <Grid item xs={6} container justify="center">
              <IconLabelButton
                color="secondary"
                actionText="Clear"
                full
                callBack={() =>callBack(null)
                }

              />
            </Grid>
            <Grid item xs={6} container justify="center">
              <IconLabelButton 
                color="secondary" 
                actionText="Close" 
                full
                callBack={() => setOpenFilter(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Filter.defaultProps = {
  elements: [],
  callBack: () => Promise.resolve(null),
};

Filter.propTypes = {
  elements: PropTypes.array.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default Filter;
