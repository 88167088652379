import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { targetDownsLoadLevels } from '../../shared/constants';
import { connect } from 'react-redux';
import {
  SubtitleBar
} from '../../components/index';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider
} from '@material-ui/core';
import SITE_URL from '../../services/siteURL';
import { SET_ACTIVITY_LOOKUP } from '../../store/FireArmType/actionType';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const styles = (theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.common.white2.main,
    boxShadow: 'inset 0px 2px 6px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    marginBottom: "3em"
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingLeft: '0',
    textAlign: 'justify'
  },
  thumbNail: {
    cursor: 'pointer',
    paddingRight: "2em"
  },
  img: {
    width: "170px",
    height: "200px"
  },
  imageTextPadding: {
    paddingTop: "2em"
  }
});

class ViewEventRules extends Component {

  constructor(props) {
    super(props);
  }

  goBack = () => {
    this.props.isActivityLookup(this.props.activatyLookups);
  };

  render() {
    const { classes } = this.props;
    const isMobile = localStorage.getItem('isMobile') === 'true';

    return (
      <React.Fragment>
        {this.props.level === targetDownsLoadLevels.EVENT_RULES && (<SubtitleBar title={this.props.level} back onBack={this.goBack}></SubtitleBar>)}
        <Grid container justify="center">
          {this.props.level === targetDownsLoadLevels.EVENT_RULES && (<Grid item container xs={12} md={12} spacing={4} alignItems="center">
            {this.props.eventRules.length === 0 ? <Grid item>No event rules available</Grid> : null}
            {this.props.eventRules.map((item, index) => (
              <Grid key={index} item xs={12}>
                <Card className={classes.root}>
                  <CardContent>
                    <Grid item container direction="row" spacing={4}>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Event
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {this.props.eventName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Firearm Type
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.firearmType}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Sport Shooting
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.sportshooting === false ? 'No' : 'Yes'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Distance
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.distance}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Shots
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.shots}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Target
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container direction="column">
                          <Typography>
                            {GetFilename(SITE_URL + item.target)}
                          </Typography>
                          <Typography className={classes.imageTextPadding}>Press image to download <ArrowForwardIcon /></Typography>
                        </Grid>
                      </Grid>
                      <Grid container justify="flex-end" className={classes.thumbNail}>
                        <Typography>
                          <a
                            href={SITE_URL + item.target}
                            target="_"
                            blank="true"
                          >
                            <img className={classes.img} alt="image unavailable" src={SITE_URL + item.thumbNail} />
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Purpose
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.purpose}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Rules
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.rules}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          className={classes.formHeading}
                          variant="h6"
                        >
                          Scoring
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Typography
                        >
                          {item.scoring}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>)}
        </Grid>
      </React.Fragment>
    );
  }
}

function GetFilename(url) {
  if (url) {
    return url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
  }
  return '';
}

const mapStateToProps = state => ({
  level: state.fireArmTypeTargets.level,
  fireArmTypesLookups: state.fireArmTypeTargets.fireArmTypesLookups,
  activatyLookups: state.fireArmTypeTargets.activatyLookups,
  eventRules: state.fireArmTypeTargets.eventRules,
  eventName: state.fireArmTypeTargets.eventName
});

const mapDispatchToProps = dispatch => ({
  isActivityLookup: (activityLookup) =>
    dispatch({
      type: SET_ACTIVITY_LOOKUP,
      payload: activityLookup
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ViewEventRules));