import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import { connect } from 'react-redux';
import axios from 'axios';
import {BASE,RESET_URL} from '../../API/APIendpoint';
import * as actionTypes from "../../store/actions/actionTypes";
import {
	Card,
	CardContent,
	Divider,
	Grid,
	Typography
  } from '@material-ui/core';

class ResetResponse extends Component {
  render() {
    return (
		<Grid 
			container 
			style={{ marginTop: '5em' }}
			direction='column'
			alignContent='center'
		>
			<Grid item>
				<Typography variant='h5'>Password Reset</Typography>
			</Grid>
			<Grid item>
				<Typography variant='h5'>
					An email has been sent to 
					<div className={"mail-color"}>{this.props.resetPassDetails.email}</div> 
					to reset password
				</Typography>
			</Grid>
		</Grid>
    )
  }
}

const mapStateToProps = state => {
  return {
      resetPassDetails: state.loginReducer.resetPassDetails
  };
}

const mapDispatchToProps = dispatch => {
  return {
      onResetPassDetailsChange: (value,vname) => dispatch({type: actionTypes.UPDATE_RESET_DETAILS, varValue:value, varName:vname})
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetResponse);