import React from "react";
import "./styles/dstyle.css";

const Bar = ({ percent, total, bColour }) => {
  const _percent = isNaN(percent) ? 0 : percent;
  const _total = isNaN(total) ? 0 : total;
  return (
    <div
      className="col-12 text-right p-2"
      style={Object.assign(
        { width: `${_percent}%` },
        { background: `${bColour}` }
      )}
    >
      {_total}
    </div>
  );
};

export default Bar;
// Show pending and approved members, have a button to link to all those members who are pending/approved
