import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import * as actionTypes from "../../store/actions/actionTypes";
import { withRouter } from "react-router-dom";
import "./customList.css";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360
  },
  button: {
    margin: "0px"
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: theme.typography.fontWeightRegular
  },
  list: {
    margin: 0
  }
});

class CustomList extends Component {
  logoutMember = () => {
    this.props.onUserDetailsChange("", "email");
    this.props.onUserDetailsChange("", "username");
    this.props.onUserDetailsChange("", "cellNo");
    this.props.onUserDetailsChange("", "physicalAddress");
    this.props.onUserDetailsChange("", "memberNumber");
    this.props.onUserDetailsChange("", "expiryDate");
    this.props.onLoginDetailsChange("", "email");
    this.props.onLoginDetailsChange("", "password");
    sessionStorage.removeItem("member");
    this.props.onMemberActiveStatusChange({});
    sessionStorage.removeItem("activeStatus");
    sessionStorage.removeItem("tokenKey");
    localStorage.removeItem("tokenKey");
    this.props.onUserDetailsChange(false, "isLoggedIn");
    this.props.onUserDetailsChange(false, "adminLevel");
    this.props.history.push("/");
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <List className={classes.list}>
          <div className="bg-light">
            <Link className={classes.link} to={"/"}>
              <ListItem key="Back" button className={classes.button}>
                <ArrowBack />
                {this.props.heading}
              </ListItem>
            </Link>
          </div>
          {this.props.listOfButtons.map((text, index) => (
            <div className="border-bottom" key={index}>
              {text === "Logout" ? (
                <input
                  type="button"
                  value={text}
                  id="mybutton"
                  name="test"
                  onClick={this.logoutMember}
                  color="inherit"
                  key={index}
                  className="primary-btn col-12 logoutButton text-left pt-2 pb-2"
                  style={{ paddingLeft: "13px" }}
                />
              ) : (
                <Link
                  key={"/admin/" + text}
                  className={classes.link}
                  to={"/admin/" + text.trim().replace(new RegExp(" ", "g"), "")}
                >
                  <ListItem key={index} button className={classes.button}>
                    {text}
                  </ListItem>
                </Link>
              )}
            </div>
          ))}
        </List>
      </div>
    );
  }
}

CustomList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberActiveStatusChange: newActiveStatus =>
      dispatch({
        type: actionTypes.UPDATE_ACTIVE_STATUS,
        activeStatus: newActiveStatus
      }),

    onUserDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_USER_DETAILS,
        varValue: value,
        varName: vname
      }),
    onLoginDetailsChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_LOGIN_DETAILS,
        varValue: value,
        varName: vname
      })
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(CustomList)));
