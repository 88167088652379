import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {DateTime} from 'luxon';

import {
  formatISODateToLocaleString,
  getDedicatedStatusNumberFromMember,
  todaysDate, 
  DateFind
} from './helpers';

export const printCard = (memberData, dedHunterNo, dedStatusNo, narfoLogo, wildmanImage, qrCode) => {
  let pdf = new jsPDF('p', 'mm', 'a4');
  pdf.line(55, 70, 165, 70);
  pdf.line(55, 125, 55, 70);
  pdf.line(165, 125, 165, 70);
  pdf.addImage(narfoLogo, 'JPEG', 67, 76, 20, 15);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(5);
  pdf.text('Responsible Firearm Ownership', 64, 95);
  pdf.text('info@narfo.co.za | www.narfo.co.za', 62.5, 97);
  pdf.setTextColor(0, 0, 0);
  
  pdf.setFont("style","bold");
  pdf.text(`Date: ${todaysDate.toLocaleString()}`, 69, 99);
  pdf.setFontSize(11);
  pdf.text('Membership Card', 94, 80);
  pdf.setFont("style","normal");
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(8);
  pdf.text(`${memberData.firstname} ${memberData.surname}`, 94, 85);
  pdf.setFontSize(5);
  pdf.text('Identity Number:', 96, 90);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.idno, 109, 90);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Number:', 96, 93);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.memNo, 110, 93);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Inception Date:', 96, 96);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.inceptionDate.split('T')[0], 115, 96);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Expiry Date:', 96, 99);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.expiryDate.split('T')[0],  113, 99);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Membership Type:', 96, 102);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.memType, 111, 102);
  let yOffset = 0;
  if (dedHunterNo) {
    pdf.setTextColor(126, 126, 126);
    pdf.text('Dedicated Hunter Status Number:', 96, 105);
    pdf.setTextColor(50, 205, 50);
    pdf.text(dedHunterNo, 123, 105);
    yOffset = 3;
  }
  if (dedStatusNo) {
    pdf.setTextColor(126, 126, 126);
    pdf.text('Dedicated Sports Shooter Status Number:', 96, 105 + yOffset);
    pdf.setTextColor(50, 205, 50);
    pdf.text(dedStatusNo, 129.5, 105 + yOffset);
  }
  pdf.setTextColor(126, 126, 126);
  pdf.text('Accreditation:Hunting - FAR: 1300135  |Sports Shooting:-FAR: 1300134', 84, 120);
  pdf.line(55, 125, 165, 125);
  pdf.addImage(qrCode, 'JPEG', 139, 78, 23, 18);
  pdf.line(165, 183, 165, 128);
  pdf.line(55, 128, 165, 128);
  pdf.line(55, 183, 55, 128);
  pdf.addImage(wildmanImage, 'JPEG', 55.4, 128.5, 109.1, 54);
  pdf.line(55, 183, 165, 183);
  pdf.save('NARFO Membership Card.pdf');
};

export const printCardWhatsAPP = (memberData, dedHunterNo, dedStatusNo, narfoLogo, wildmanImage, qrCode) => {
  let pdf = new jsPDF('p', 'mm', 'a4');
  pdf.line(55, 70, 165, 70);
  pdf.line(55, 125, 55, 70);
  pdf.line(165, 125, 165, 70);
  pdf.addImage(narfoLogo, 'JPEG', 67, 76, 20, 15);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(5);
  pdf.text('Responsible Firearm Ownership', 64, 95);
  pdf.text('info@narfo.co.za | www.narfo.co.za', 62.5, 97);
  pdf.setTextColor(0, 0, 0);
  
  pdf.setFont("style","bold");
  pdf.text(`Date: ${todaysDate.toLocaleString()}`, 69, 99);
  pdf.setFontSize(11);
  pdf.text('Membership Card', 94, 80);
  pdf.setFont("style","normal");
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(8);
  pdf.text(`${memberData.firstname} ${memberData.surname}`, 94, 85);
  pdf.setFontSize(5);
  pdf.text('Identity Number:', 96, 90);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.idno, 109, 90);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Number:', 96, 93);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.memNo, 110, 93);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Inception Date:', 96, 96);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.inceptionDate.split('T')[0], 115, 96);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Member Expiry Date:', 96, 99);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.expiryDate.split('T')[0],  113, 99);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Membership Type:', 96, 102);
  pdf.setTextColor(50, 205, 50);
  pdf.text(memberData.memType, 111, 102);
  let yOffset = 0;
  if (dedHunterNo) {
    pdf.setTextColor(126, 126, 126);
    pdf.text('Dedicated Hunter Status Number:', 96, 105);
    pdf.setTextColor(50, 205, 50);
    pdf.text(dedHunterNo, 123, 105);
    yOffset = 3;
  }
  if (dedStatusNo) {
    pdf.setTextColor(126, 126, 126);
    pdf.text('Dedicated Sports Shooter Status Number:', 96, 105 + yOffset);
    pdf.setTextColor(50, 205, 50);
    pdf.text(dedStatusNo, 129.5, 105 + yOffset);
  }
  pdf.setTextColor(126, 126, 126);
  pdf.text('Accreditation:Hunting - FAR: 1300135  |Sports Shooting:-FAR: 1300134', 84, 120);
  pdf.line(55, 125, 165, 125);
  pdf.addImage(qrCode, 'JPEG', 139, 78, 23, 18);
  pdf.line(165, 183, 165, 128);
  pdf.line(55, 128, 165, 128);
  pdf.line(55, 183, 55, 128);
  pdf.addImage(wildmanImage, 'JPEG', 55.4, 128.5, 109.1, 54);
  pdf.line(55, 183, 165, 183);
  // pdf.html('NARFO Membership Card.pdf');
  pdf.output('blob');
};

export const printCertificate = (memberData, narfoLogo, qrCode, signature, stamp) => {

  let pdf = new jsPDF('p', 'mm', 'a4');
  let isActiveDH = {active : false}; //variable to check is dedicated status is active or not 
  let isActiveDSS = {active :false}; //variable to check is dedicated status is active or not 
  
  //check dedicated status 
  if(memberData.dedicatedStatus?.length > 0)
  {
      //check if there's an active status 
      memberData.dedicatedStatus.forEach(ds => {
        if(ds.status == "Approved" && ds.dstype == "Dedicated Hunter")
              isActiveDH = {active: true, dsNo: ds.dsno};
        else if(ds.status == "Approved" && ds.dstype == "Dedicated Sports Person")
              isActiveDSS = {active: true, dsNo: ds.dsno};
      });

  }

  pdf.rect(2, 2, 205, 294);
  pdf.addImage(qrCode, 'JPEG', 15, 255, 40, 35);
  pdf.addImage(narfoLogo, 'JPEG', 10, 12);
  pdf.setTextColor(126, 126, 126);
  pdf.setFont('times');
  pdf.setFontSize(10);
  pdf.text('NARFO Pty (Ltd) 2012/086864/07', 10, 60);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('times');
  pdf.setFontSize(14);
  pdf.text('National Association of Responsible Firearm Owners', 80, 18);
  pdf.setFontSize(10);
  pdf.text('of South Africa', 120, 25);
  pdf.line(70, 30, 150, 30);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(10);
  
  pdf.setFont("style","italic");
  pdf.text('SAPS Accredited Association', 85, 37);
  pdf.setFont("style","normal");
  pdf.text('Hunting Association', 70, 45);
  pdf.setTextColor(0, 0, 0);
  pdf.text('FAR-1300135', 110, 45);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Sport Shooting', 70, 50);
  pdf.setTextColor(0, 0, 0);
  pdf.text('FAR-1300134', 110, 50);
  pdf.text('http://www.narfo.co.za', 70, 55);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Email:', 110, 55);
  pdf.setTextColor(0, 0, 0);
  pdf.text('info@narfo.co.za', 120, 55);
  pdf.line(70, 60, 150, 60);
  pdf.setFontSize(8);
  pdf.setTextColor(126, 126, 126);
  pdf.text('14 Phesante Close, Graanendal', 156, 32);
  pdf.text('Durbanville', 156, 38);
  pdf.text('7551', 156, 44);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text('Certificate of Membership', 66, 70);
  pdf.line(10, 75, 200, 75);
  pdf.setFont("style","normal");
  pdf.setFontSize(14);
  pdf.text('To whom it may concern', 10, 85);
  pdf.text('This is to certify that', 10, 95);
  pdf.text('Name:', 10, 105);
  pdf.text('Address:', 10, 115);
  pdf.text('ID No:', 10, 125);
  pdf.text('Membership Number:', 10, 135);
  pdf.text('Membership Type:', 10, 145);
  pdf.text('Dedicated Hunter:', 10, 155);
  pdf.text('Dedicated Sports Shooter:', 10, 165);
  pdf.text(
    'has been member in good standing of the association since',
    10,
    175
  );
  pdf.text('Current membership is valid until', 10, 185);
  pdf.setFont("style","bold");
  pdf.text(40, 105, memberData.firstname + ' ' + memberData.surname);
  pdf.text(
    `${memberData.physicalAddress}, ${memberData.suburb}, ${memberData.city}, ${memberData.postalCode}`,
    40, 115
  );

  pdf.text(memberData.idno, 40, 125);
  pdf.text(memberData.memNo, 60, 135);
  pdf.text(memberData.memType, 60, 145);
  pdf.text(isActiveDH.active ? isActiveDH.dsNo + " (Active)" : "Not Active", 60, 155);
  pdf.text(isActiveDSS.active ? isActiveDSS.dsNo + " (Active)" : "Not Active", 70, 165);
  pdf.text(memberData.inceptionDate.split('T')[0], 130, 175, );
  pdf.text(memberData.expiryDate.split('T')[0], 80, 185);
  pdf.addImage(signature, 'JPEG', 17, 190, 60, 45);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","normal");
  pdf.text('Executive Member', 28, 250);
  pdf.addImage(stamp, 'JPEG', 115, 178);
  pdf.text(136, 250, formatISODateToLocaleString(todaysDate));
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(10);
  pdf.text(
    'This document only provides proof of membership and cannot be used to provide proof of',
    55,
    275
  );
  pdf.text(
    'dedicated hunter or sports person status. These can only be verified by the approved dedicated',
    55,
    280
  );
  pdf.text(
    'certificates which complies stipulations of Section 16(2) of the Firearms Control Act 60 of 2000',
    55,
    285
  );
  pdf.setFontSize(18);
  pdf.setFont("style","bold");
  pdf.setTextColor(31, 78, 121);
  pdf.text('Firearm Ownership is a Fulltime Responsibility', 55, 295);
  pdf.save('NARFO Proof Of Membership.pdf');
};

export const printPCOASACertificate = (memberData, partnerLogo, qrCode, signature, stamp) => {

  let pdf = new jsPDF('p', 'mm', 'a4');
  pdf.rect(2, 2, 205, 294);
  pdf.rect(5, 5, 199, 289);
  pdf.addImage(qrCode, 'JPEG', 15, 255, 40, 35);
  pdf.addImage(partnerLogo, 'JPEG', 80, 40);
  pdf.setTextColor(126, 126, 126);
  pdf.setFont('times');
  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('times');
  pdf.setFontSize(30);
  pdf.text('Professional Culling Operators', 45, 18);
  pdf.text('Association of South Africa', 50, 30);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(15);
  pdf.setFont("style","italic");
  pdf.text('https://www.pcoasa.co.za  -  admin@pcoasa.co.za', 50, 40);

  pdf.line(10, 85, 200, 85);
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text('Certificate of Membership', 70, 95);

  pdf.line(10, 100, 200, 100);
  pdf.setFont("style","normal");
  pdf.setFontSize(14);
  pdf.text('HEREWITH IS CERTIFIED THAT', 70, 110);

  pdf.setFontSize(18);
  pdf.setFont("style","bold");
  pdf.text(15, 120, memberData.firstname + ' ' + memberData.surname);
  
  pdf.setFont("style","normal");
  pdf.setFontSize(16);
   pdf.text(memberData.idno, 15, 135);
  pdf.text(
    `${memberData.physicalAddress}, ${memberData.suburb}, ${memberData.city}, ${memberData.postalCode}`,
    15, 145
  );

  pdf.setFontSize(14);
  pdf.text('Has been an active member since:', 15, 165);
  pdf.setFont("style","bold")
  pdf.text(memberData.inceptionDate.split('T')[0], 90, 165, );
  pdf.setFont("style","italic");
  pdf.setFontSize(12);
  pdf.text('AND IS REGISTERED AS AN INDUSTRY CULLER WHEREBY HE / SHE HAS COMPLIED', 15, 180);
  pdf.text('WITH ALL REQUIREMENTS NEEDED FOR MEMBERSHIP APPROVAL AND', 15, 190);
  pdf.text('COMMITTED TO ADHERE TO ALL REQUIREMENTS CONTAINED IN THE CONSTITUTION', 15, 200);
  pdf.text('AND RULES OF PCOASA', 15, 210);

  pdf.addImage(signature, 'JPEG', 17, 211, 50, 35);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(12);
  pdf.text('Duly Authorised - PCOASA Secratary',17, 250);

  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(12);
  pdf.text('Printed Date:',120, 230);
  pdf.text(155, 230, formatISODateToLocaleString(todaysDate));

  pdf.setFont("style","normal");
  pdf.setFontSize(12);
  pdf.text('Expiry Date:',120, 240);
  pdf.text(memberData.expiryDate.split('T')[0], 155, 240);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(10);
  pdf.text(
    'This document provides proof of membership and professional culler status and connot be used',55, 265);
  pdf.text(
    ' to verify dedicated status. These can only be verified by the approved dedicated',55, 270);
  pdf.text(
    'certificates which complies stipulations of Section 16(2) of the Firearms Control Act 60 of 2000.',55,275);
    pdf.text(
      'Scan the QR code to verify membersip status',55,280);
  pdf.setFontSize(18);
  pdf.setFont("style","bold");
  pdf.setTextColor(31, 78, 121);
  pdf.text('Sustainable Utilisation - Concervation', 75, 290);
  pdf.save('PCOASA Proof Of Membership.pdf');
};

export const printGOSACertificate = (memberData, partnerLogo, membershipType) => {
  let pdf = new jsPDF('L', 'mm', 'a4');
  let imageWidth = 297; // mm
  let imageHeight = 210; // mm
  let membername = (memberData.firstname + ' ' + memberData.surname)
 
  pdf.addImage(partnerLogo, 'PNG', 0, 0, imageWidth, imageHeight);
  pdf.setTextColor(126, 126, 126);
  pdf.setFont('times');
  pdf.setFontSize(10);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('times');

  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(15);
  pdf.setFont("style","italic");
  pdf.text('https://www.gosaonline.co.za', 120, 40);
  
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text(membername,  70, 95);
 
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(8);
  pdf.setFont("style","italic");
  pdf.text('Has been an active member since:', 180, 110);
  pdf.setFont("style","bold")
  pdf.text(memberData.inceptionDate.split('T')[0], 220, 110, );
 
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(10);
  pdf.setFont("style","italic");
  pdf.text('Duly Authorised',60, 165);
  
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(12);
  pdf.text('Printed Date:',190, 175);
  pdf.text(formatISODateToLocaleString(todaysDate), 220, 175);
 
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(15);
  pdf.text(memberData.memNo, 65, 120, );
  
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(15);
  pdf.text(memberData.partnerExpiryDate.split('T')[0], 200, 120);
  

  pdf.save(`GOSA ${membershipType} Proof Of Membership.pdf`);
};

export const printAccountTransaction = (account, memberDetails, narfoLogo, pdflogo) => {
  let pdf = new jsPDF('p', 'mm', 'a4');
  pdf.addImage(narfoLogo, 'JPEG', 32, 30, 40, 30);
  pdf.setTextColor(126, 126, 126);
  pdf.setFont('times');
  pdf.setFontSize(10);
  pdf.text('NARFO Pty (Ltd) 2012/086864/07', 25, 65);
  pdf.text('VAT No: 4700300611', 25, 70);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('times');
  pdf.setFontSize(14);
  
  pdf.setFont("style","bold");
  pdf.text('NARFO', 122, 35);
  pdf.setFontSize(12);
  pdf.text('National Association for Responsible Firearm Owners', 87, 43);
  pdf.setFont("style","normal");
  pdf.setFontSize(11);
  pdf.text('14 Phesante Close, Graanendal', 137, 60);
  pdf.text('Durbanville', 137, 66);
  pdf.text('7551', 137, 72);
  pdf.text('email: info@narfo.co.za', 137, 80);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text('Invoice', 89, 80);
  pdf.line(18, 84, 190, 84);
  pdf.setFontSize(12);
  pdf.text('Member No:', 18, 91);
  pdf.setFont("style","normal");
  pdf.text(account.memNo ,43, 91);
  pdf.setFont("style","bold");
  pdf.text('Invoice No:', 150, 91);
  pdf.setFont("style","normal");
  pdf.text(account.invoiceNumber.toString() , 173, 91);
  pdf.line(18, 95, 190, 95);
  pdf.line(18, 96, 190, 96);
  pdf.setFontSize(11);
  pdf.text( `${memberDetails.firstname.toUpperCase()} ${memberDetails.surname.toUpperCase()}`, 22, 102);
  pdf.text(memberDetails.physicalAddress.toUpperCase(), 22, 107);
  pdf.text(memberDetails.city.toUpperCase(), 22, 112);
  pdf.text(memberDetails.province.toUpperCase(), 22, 117);
  pdf.text(memberDetails.postalCode.toUpperCase(), 22, 122);
  pdf.setFont("style","bold");
  pdf.text('Payment Date', 22, 130);
  pdf.text('Description', 50, 130);
  pdf.text('Payment Method', 95, 130);
  pdf.text('Transaction No', 135, 130);
  pdf.text('Amount', 170, 130);
  pdf.line(18, 133, 190, 133);
  pdf.setFont("style","normal");
  pdf.text(formatISODateToLocaleString(account.paymentDate.split('T')[0]), 22, 139);
  pdf.text(account.paymentType, 50, 139);
  // pdf.text(account.year, 80, 139);
  pdf.text(account.paymentMethod, 95, 139);
  pdf.text(account.transactionNo, 135, 139);
  pdf.text(`R ${account.amountPaid}`, 170, 139);

  //Newline item
  pdf.text(formatISODateToLocaleString(account.paymentDate.split('T')[0]), 22, 145);
  pdf.text("Wildland Subscription", 50, 145);
  pdf.text('-', 95, 145);
  pdf.text('-', 135, 145);
  pdf.text(`R ${0}`, 170, 145);

  

  pdf.line(18, 149, 190, 148);

  pdf.setFont("style","bold");
  pdf.text(135, 154, 'Amount(Exc VAT)');  
  pdf.text(`R ${ (Math.round((account.amountPaid - account.vatAmount) * 100) / 100).toFixed(2)}`, 170, 154);

  pdf.text(135, 160, 'VAT @ ' + (account.vatPercentage * 100) + '%' );  
  pdf.text(`R ${account.vatAmount}`, 170, 160);

  pdf.line(170, 163, 190, 163);

  pdf.text(135, 168, 'Total Paid');  
  pdf.text(`R ${account.amountPaid}`, 170, 168);     

  pdf.line(170, 170, 190, 170);
  pdf.text('Thank you for your payment', 22, 168);
  pdf.addImage(pdflogo, 'PNG', 145, 175, 40, 40);
  pdf.line(18, 270, 190, 270);
  pdf.save('NARFO Fee Receipt.pdf');
};

const printLandscapeHeader = (identifier, narfoLogo, memberDetails, dedHunterNo, dedSportsNo) => {
  const pdf = new jsPDF('l', 'mm', [310, 210]);
  pdf.autoTable({
    html: identifier, 
    horizontalPageBreak: true,
  });
  
  pdf.addImage(narfoLogo, 'JPEG', 20, 22);
  pdf.setTextColor(126, 126, 126);
  pdf.setFont('times');
  pdf.setFontSize(10);
  pdf.text('NARFO Pty (Ltd) 2012/086864/07', 15, 60);
  
  pdf.setFontSize(14);
  pdf.text('National Association of Responsible Firearm Owners', 100, 18);
  pdf.text('of South Africa', 140, 25);
  pdf.line(70, 30, 295, 30);
  pdf.setTextColor(126, 126, 126);
  pdf.setFontSize(10);
  pdf.setFont("style","italic");
  pdf.text('SAPS Accredited Association', 90, 37);
  pdf.setFont("style","normal");
  pdf.text('Hunting Association', 75, 45);
  pdf.setTextColor(0, 0, 0);
  pdf.text('FAR-1300135', 115, 45);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Sport Shooting', 75, 51);
  pdf.setTextColor(0, 0, 0);
  pdf.text('FAR-1300134', 115, 51);
  pdf.text('http://www.narfo.co.za', 75, 57);
  pdf.setTextColor(126, 126, 126);
  pdf.text('Email:', 115, 57);
  pdf.setTextColor(0, 0, 0);
  pdf.text('info@narfo.co.za', 125, 57);
  pdf.setFontSize(8);
  pdf.setTextColor(126, 126, 126);
  pdf.text('14 Phesante Close, Graanendal', 164, 37);
  pdf.text('Durbanville', 164, 41);
  pdf.text('7551', 164, 45);
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(11);
  pdf.text(`${memberDetails.firstname} ${memberDetails.surname}` ,220, 37);
  pdf.text(220, 41, `Identity Number: ${memberDetails.idno}`);
  pdf.text(220, 45, `Member Number: ${memberDetails.memNo.toUpperCase()}`);
  pdf.text(220, 49, `Member Status: ${memberDetails.status}`);
  pdf.line(70, 62, 295, 62);
  let yOffset = 0;
  if(dedHunterNo) {
    pdf.text(`Dedicated Hunter Number: ${dedHunterNo}`, 220, 53);
    yOffset = 4;
  }
  if(dedSportsNo) {
    pdf.text(`Dedicated Sports Person Number: ${dedSportsNo}`, 220, 53 + yOffset);
  }
  return pdf;
};

export const printSalesCommission = (commission, memberDetails, narfoLogo, identifier) => {
  const firstCommission = commission.commissions[0];
  const totalCommission = commission.commissions.reduce((total, v) => total + v.comission, 0);

  const pdf = printLandscapeHeader(identifier, narfoLogo, memberDetails);
  const finalY = pdf.previousAutoTable.finalY;
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(14);
  pdf.setFontSize(12);
  pdf.text('Bank Name', 20, 70);
  
  pdf.setFont("style","bold");
  pdf.text(firstCommission.bankName, 70, 70);
  pdf.setFont("style","normal");
  pdf.text('Account Number', 20, 75);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.accountNo, 70, 75);
  pdf.setFont("style","normal");
  pdf.text('Payment Method', 20, 80);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.paymentMethod, 70, 80);
  pdf.setFont("style","normal");
  pdf.text('Account Type', 20, 85);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.accountType, 70, 85);
  pdf.setFont("style","normal");
  pdf.text('Transaction Number', 20, 90);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.paymentTransNo, 70, 90);
  pdf.setFont("style","normal");
  pdf.setFontSize(14);
  pdf.text('Payment Date', 235, 90);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.paymentDate.split('T')[0], 268, 90);
  pdf.setTextColor(0, 0, 0);
  pdf.setFont('times');
  
  pdf.setFont("style","normal");
  pdf.setFont('times');
  
  pdf.setFontSize(14);
  pdf.text('Branch', 120, 70);
  pdf.setFont("style","bold");
  pdf.text(firstCommission.branch , 140, 70);
  pdf.setFont("style","normal");
  pdf.text(firstCommission.comType, 225, 70);
  pdf.setFont("style","bold");
  pdf.text(`R${totalCommission}`, 275, 70);
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(20);
  pdf.text('Commission Remmitance', 120, 90);
  pdf.autoTable({
    startY: finalY + 80,
    head: [
      [
        'Date Joined',
        'Member Number',
        'Firstname',
        'Surname',
        'Commission'
      ]
    ],
    body: commission.commissions.map((v) => [
      v.inceptionDate ? v.inceptionDate.split('T')[0] : 'null',
      v.memNo || 'null',
      v.firstname || 'null',
      v.surname || 'null',
      v.comission ? `R${v.comission}` : 'null',
    ]),
    footStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0] },
    showFoot: 'lastPage',
    foot: [
      [
        '',
        '',
        '',
        '',
        `R${totalCommission}`
      ]
    ]
  });
  pdf.save('Commission.pdf');
};

export const printActivities = (activities, dedHunterNo, dedSportsNo, identifier, narfoLogo, memberDetails) => {
  const pdf = printLandscapeHeader(identifier, narfoLogo, memberDetails, dedHunterNo, dedSportsNo);
  const finalY = pdf.previousAutoTable.finalY;
  console.log("JJJ",activities)
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text('Member Activities', 130, 70);
  pdf.setFont("style","bold");
  pdf.autoTable({
    startY: finalY + 60,
    head: [
      [
        'Activity Type',
        'Description',
        'Fiream Type',
        'Activity Date',
        'Caliber',
        'Status'
      ]
    ],
    body: activities.map((v) => [
      v.activityType || 'null',
      v.activityDescription || 'null',
      v.firearmType || 'null',
      v.activityDate ? v.activityDate.substring(0, 10) : 'null',
      v.caliber ? v.caliber.toString() : 'N/A',
      v.status || 'null',
    ]),
  });
  pdf.save('Activities.pdf');
};

export const printEndorsement = (memberData, endorsementData, narfoLogo, qrCode, signature, stamp, commAuthSign) => {
  let pdf = new jsPDF('p', 'mm', 'a4');
  
  pdf.setFont('times');

  pdf.addImage(qrCode, 'JPEG', 70, 240, 40, 35);
  pdf.addImage(narfoLogo, 'JPEG', 80, 10);
  pdf.addImage(stamp, 'JPEG', 125, 180, 55, 55);
  pdf.addImage(commAuthSign, 'JPEG', 25, 240, 30, 30);
  pdf.addImage(signature, 'JPEG', 42, 176, 40, 25);

  pdf.setFontSize(14);
  pdf.setFont("style","bold");
  pdf.setFont('times');
  pdf.text('National Association of Responsible Firearm Owners', 50, 55,);

  pdf.setFont("style","normal");
  pdf.text('South Africa',90, 65);

  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text('Endorsement Of Motivation', 65, 75);

  pdf.setFontSize(12);
  pdf.setFont("style","normal");
  pdf.text(
    'I, the undersigned, Johann Scheffer, ID: 6509285076083, in my capacity as executive and appointed',
    20,
    85
  );
  pdf.text('responsible person of the association, hereby solemnly declare that', 20, 90,);
  pdf.text('We have examined the motivation of the application for the following firearm for the purpose of', 20, 100);
  pdf.text(endorsementData.applicationType, 20, 105);

  pdf.setFont("style","bold");
  pdf.text(
    endorsementData.fireArmType +
    '-' +
    endorsementData.actionType +
    '-' +
    endorsementData.make +
    '-' +
    endorsementData.model +
    '-' +
    endorsementData.caliber +
    '-' +
    endorsementData.serialNumber,
    20,
    110
  );

  pdf.setFont("style","normal");
  pdf.text(
    20,
    115,
    'applied for under ' +
    endorsementData.section +
    ' of the Firearms Control Act and based on the information of the'
  );
  pdf.text(
    20,
    120,
    'motivation we find the firearm suitable for the stated use'
  );
  pdf.text(20, 130, 'Member Names:');
  pdf.text(20, 135, 'Member Number:');
  pdf.text(20, 140, 'ID Number:');
  pdf.text(20, 145, 'Dedicated Status:');

  pdf.setFont("style","bold");
  pdf.text(memberData.firstname + ' ' + memberData.surname, 60, 130);
  pdf.text(60, 135, memberData.memNo);
  pdf.text(60, 140, memberData.idno);
  pdf.text(60, 145, 'Dedicated Type');
  pdf.text(105, 145, 'Status Number');

  pdf.setFont("style","normal");
  pdf.text(60, 150, 'Dedicated Hunter');
  pdf.text(60, 155, 'Dedicated Sports Person');

  pdf.setFont("style","normal");
  pdf.text(
    getDedicatedStatusNumberFromMember(
      memberData.dedicatedStatus,
      'Dedicated Hunter'
    ),
    105,
    150
  );
  pdf.text(
    getDedicatedStatusNumberFromMember(
      memberData.dedicatedStatus,
      'Dedicated Sports Person'
    ),
    105,
    155  
  );
  pdf.text(20, 160, 'Endorsement Number:');

  pdf.setFont("style","bold");
  pdf.text(60, 160, endorsementData.endorsementNo);

  pdf.setFont("style","normal");
  pdf.text(20, 170, 'who is a member of the association since');

  pdf.setFont("style","bold");
  pdf.text(91, 170, formatISODateToLocaleString(memberData.inceptionDate.split('T')[0]));

  pdf.setFont("style","normal");
  pdf.text(20, 175, 'and whose membership is valid until');

  pdf.setFont("style","bold");
  pdf.text(83, 175, formatISODateToLocaleString(memberData.expiryDate.split('T')[0]));
  pdf.text(128, 240, 'Printed On:');
  const todaysDate = DateTime.now();
  pdf.text(152, 240, formatISODateToLocaleString(todaysDate));
  pdf.text(40, 205, 'Johann Heinrich Scheffer');
  pdf.text(45, 210, 'Executive Member');
  pdf.text(37, 215, 'Approved on:');
  pdf.text(63, 215, formatISODateToLocaleString(endorsementData.approvalDate.split('T')[0]));
  pdf.setFontSize(9);
  pdf.text(35, 220, 'I declare that judgement was made based');
  pdf.text(35, 225, 'on collective experience of the executive');
  pdf.text(35, 230, 'team and the material facts provided in the');
  pdf.text(35, 235, 'motivation submitted by the applicant');
  
  pdf.rect(135, 267, 55, 11);
  pdf.text(138, 252, 'Hunting Association :');
  pdf.text(142, 257, 'Sport Shooting :');

  pdf.setFont("style","normal");
  pdf.text(168, 252, 'FAR-1300135');
  pdf.text(165, 257, 'FAR-1300134');

  pdf.setFontSize(11);
  pdf.text(140, 274, 'SAPS Accredited Association');

  pdf.setTextColor(126, 126, 126);
  pdf.text('14 Phesante Close, Graanendal, Durbanville, 7551', 20, 280);
  pdf.text('http://www.narfo.co.za' ,20, 285);

  pdf.setTextColor(0, 0, 0);
  pdf.text('NARFO Pty (Ltd) 2012/086864/07', 135, 285);

  endorsementData.applicationType === 'Hunting'
    ? pdf.save('Narfo Hunting Endorsement.pdf')
    : pdf.save('Narfo Sport Shooting Endorsement.pdf');
};

export const printLicenseReminders = (licenseReminders, dedHunterNo, dedSportsNo, identifier, narfoLogo, memberDetails) => {
  const pdf = printLandscapeHeader(identifier, narfoLogo, memberDetails, dedHunterNo, dedSportsNo);
  const finalY = pdf.previousAutoTable.finalY;
  
  pdf.setFont("style","bold");   
  pdf.setFontSize(20);
  pdf.text(115, 70, 'Member Firearm Licenses');
  pdf.autoTable({
    startY: finalY + 60,
    head: [
      [
        'License Number',
        'Serial Number',
        'Firearm',
        'Caliber',
        'Make',
        'Model',
        'Section',
        'Issue Date',
        'Expiry Date'
      ]
    ],
    body: licenseReminders.map(a => [
      a.licenseNo || 'null',
      a.serialNumber || 'null',
      a.firearm || 'null',
      a.caliber || 'null',
      a.make || 'null',
      a.model || 'null',
      a.section || 'null',
      a.issueDate ? a.issueDate.substring(0, 10) : 'null',
      a.expiryDate ? a.expiryDate.substring(0, 10) : 'null'
    ])
  });
  pdf.save('Licenses.pdf');
};

export const printDedicatedStatus = (memberData, dedicatedStatus, narfoLogo, qrCode, signature, stamp, commAuthSign,dsSportsShooter,dsHunter) => {
  let pdf = new jsPDF('p', 'mm', [300, 220]);

  // pdf.setLineWidth(2);
  pdf.rect(2, 2, 216, 296);

  pdf.addImage(qrCode, 'JPEG', 70, 240, 40, 35);
  pdf.addImage(narfoLogo, 'JPEG', 80, 10);
  pdf.addImage(signature, 'PNG', 40, 175, 40, 25);
  pdf.addImage(stamp, 'PNG', 140, 180, 45, 45);
  pdf.addImage(commAuthSign, 'PNG', 30, 240, 30, 30);

  pdf.setFont('times');
  pdf.setFontSize(14);
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  pdf.text(
    30,
    55,
    'National Association of Responsible Firearm Owners'
  );
  pdf.setFont("style","normal");
  pdf.text(90, 65, 'South Africa');
  pdf.setFont("style","bold");
  pdf.setFontSize(20);
  dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype === 'DH'
    ? pdf.text(80, 75, 'Dedicated Hunter')
    : pdf.text(70, 75, 'Dedicated Sport Person');
  pdf.setFontSize(12);
  pdf.setFont("style","normal");
  pdf.text(
    20,
    85,
    'I, the undersigned, Johann Scheffer, ID: 6509285076083, duly appointed by the board of NARFO in'
  );

  pdf.text(
    20,
    92,
    'terms of resolution RL-2018-002, past on 18/04/2018 in my capacity as the Chairman of the association,'
  );
  pdf.text(
    78,
    100,
    'hereby solemnly declare that'
  );
  pdf.setFont("style","bold");
  pdf.setFontSize(24);
  pdf.setTextColor(255, 0, 0);
  dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype === 'DH'
    ? pdf.text(60, 110, 'Dedicated Hunter Status')
    : pdf.text(51, 110, 'Dedicated Sport Person Status');
  pdf.setFont("style","normal");
  pdf.setFontSize(12);
  pdf.setTextColor(0, 0, 0);
  dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype ===  'DH'
    ? pdf.text(65, 117, 'Dedicated Hunter No:')
    : pdf.text(60, 117, 'Dedicated Sport Person No:');
  pdf.setFont("style","bold");
  dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype === 'DH'
    ? pdf.text(105, 117, dedicatedStatus.dsno)
    : pdf.text(110, 117, dedicatedStatus.dsno);

    dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype === 'DH'
    ? pdf.addImage(dsHunter, 'PNG', 5, 105,60, 45)
    : pdf.addImage(dsSportsShooter, 'PNG', 5, 105,60, 45);

  pdf.setFont("style","normal");
  pdf.text(76, 125, 'has been awarded to:');
  pdf.text(60, 130, 'Member Names:');
  pdf.text(60, 135, 'Member Number:');
  pdf.text(60, 140, 'ID Number:');
  pdf.setFont("style","bold");
  pdf.text(
    95,
    130,
    memberData.firstname + ' ' + memberData.surname
  );
  pdf.text(95, 135, dedicatedStatus.memNo);
  pdf.text(95, 140, memberData.idno);
  pdf.setFont("style","bold");
  pdf.setFont("style","normal");
  pdf.setFontSize(10);
  pdf.text(
    18,
    150,
    'who is a member in good standing of the association at the date of print and as per the FCA 60 of 2000 Regulation 4(1)(e)(i)'
  );
  pdf.text(
    18,
    155,
    'and complied with the provisions in terms of, Regulation 4(1)(b)(i)(ii).'
  );
  pdf.text(
    18,
    163,
    'The validity of this certifcate is subject to the maintenance of the awarded status in terms of the provisions set out in'
  );
  pdf.text(
    18,
    168,
    'Annexure (D) of the National Association of Responsible Firearm Owners Rules and Regulations guide in line with the'
  );
  pdf.text(
    18,
    173,
    'provisions of Regualtion 4(1)(c)(ii) of the Firearms Control Act 60 of 2000'
  );
  
  pdf.text(40, 200, 'Johann Heinrich Scheffer');
  pdf.text(45, 205, 'Executive Member');
  pdf.text(25, 210, '(Executive Member and Responsible Person)');
  pdf.setFontSize(9);
  pdf.text(
    20,
    215,
    'Duly appointed by the board in terms of resolution RL-2017-001'
  );
  pdf.text(
    20,
    220,
    'passed on 23/01/2017. The deponent acknowledges that he'
  );
  pdf.text(
    20,
    225,
    'understands and knows the contents of this solemn declaration'
  );
  pdf.text(
    20,
    230,
    'and that he has no objection in taking the declaration and that he'
  );
  pdf.text(
    20,
    235,
    'considers the declaration binding to his conscience'
  );
  pdf.setFont("style","bold");
  pdf.text(138, 230, 'Status awarded on:');
  pdf.text(148, 235, 'Printed:');
  pdf.text(165, 230, DateFind(dedicatedStatus.approvalDate));
  pdf.text(160, 235, DateFind(new Date()));
  pdf.rect(135, 265, 55, 11);
  pdf.text(138, 252, 'Hunting Association :');
  pdf.text(142, 257, 'Sport Shooting :');
  pdf.setFont("style","normal");
  pdf.text(168, 252, 'FAR-1300135');
  pdf.text(165, 257, 'FAR-1300134');
  pdf.setFontSize(11);
  pdf.text(140, 272, 'SAPS Accredited Association');
  pdf.setTextColor(126, 126, 126);
  pdf.text(20, 280, '14 Phesante Close, Graanendal, Durbanville, 7551');
  pdf.text(20, 285, 'http://www.narfo.co.za');
  pdf.setTextColor(0, 0, 0);
  pdf.text(135, 285, 'NARFO Pty (Ltd) 2012/086864/07');
  dedicatedStatus.dstype === 'Dedicated Hunter' || dedicatedStatus.dstype === 'DH'
    ? pdf.save('Narfo Dedicated Hunter Status.pdf')
    : pdf.save('Narfo Dedicated Sport Shooter Status.pdf');
};

export const printCourseCertificate = (memberData, courseTitle, narfoLogo, signature, stamp) => {
  let member = memberData;
  const pdf = new jsPDF('p', 'mm', [270, 220]);
  

  pdf.addImage(narfoLogo, 'JPEG', 85, 12);
  pdf.addImage(signature, 'JPEG', 26, 165, 60, 45);
  pdf.addImage(stamp, 'JPEG', 135, 165, 50, 50);
			
  pdf.setTextColor(0, 0, 0);
  pdf.setFont("style","bold");
  pdf.setFontSize(14);
  pdf.text(53, 60, 'National Association of Responsible Firearm Owners');
  pdf.setTextColor(0, 102, 51);
  pdf.setFontSize(28);
  pdf.text(27, 80, 'CERTIFICATE OF ACHIEVEMENT');
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(20);
  pdf.text(83, 95, 'This certifies that');
  pdf.setTextColor(0, 0, 255);
  pdf.setFontSize(14);
  pdf.text(70, 110, 'Member Names:');
  pdf.text(70, 115, 'ID No:');
  pdf.setFont("style","bold");
  pdf.text(
    120,
    110,
    member.firstname + ' ' + member.surname
  );
  pdf.text(120, 115, member.idno);
  pdf.setTextColor(0, 0, 0);
  pdf.setFontSize(20);
  pdf.text(35, 135, 'Has successfully completed the following course');
  pdf.setTextColor(0, 51, 102);
  pdf.setFontSize(30);
  courseTitle === 'Dedicated Hunter Assessment Modules'
    ? pdf.text(65, 150, courseTitle.substring(9, 27))
    : pdf.text(45, 150, courseTitle.substring(9, 34));
      
  pdf.setFontSize(8);
  pdf.setTextColor(0, 0, 0);
  pdf.text(36, 210, 'Printed on:');
  pdf.text(50, 210, DateFind(new Date));
  pdf.setTextColor(126, 126, 126);
  pdf.text(30, 220, '14 Phesante Close, Graanendal, Durbanville, 7551');
  pdf.text(30, 225, 'http://www.narfo.co.za');
  pdf.setTextColor(0, 0, 0);
  pdf.text(140, 225, 'NARFO Pty (Ltd) 2012/086864/07');
  pdf.save(
    courseTitle === 'Dedicated Hunter Assessment Modules'
      ? 'HunterAssesement.pdf'
      : 'SportShooterAssessment.pdf'
  );
};