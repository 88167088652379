import * as actionTypes from "../actions/actionTypes";

const initialState = {
  mainDetails: {
    memberNumber: "",
    isVisitor: false,
    isMember: false,
    isAdmin: false
  },
  showAdminHamburger: true,
  toggleHamburger: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_HAMBURGER:
      return {
        ...state,
        toggleHamburger: action.toggleHamburger
      };
    case actionTypes.UPDATE_MAIN_DETAILS:
      const newMainDetails = {
        ...state.mainDetails,
        [action.varName]: action.varValue
      };
      return {
        ...state,
        mainDetails: newMainDetails
      };
    case actionTypes.TOGGLE_SHOW_ADMIN_HAMBURGER:
      return {
        ...state,
        showAdminHamburger: state.showAdminHamburger
      };
    default:
      return state;
  }
};

export default reducer;
