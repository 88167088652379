import * as actionTypes from "../actions/actionTypes";

const initialState = {
    aboutUsToggler: 0,
    fireararmOwwners: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ABOUT_US_CONTENT:
      return {
        ...state,
        aboutUsToggler: action.aboutUsToggler
      };
    case actionTypes.UPDATE_FIREARM_OWNERS_CONTENT:
      return {
          ...state,
          fireararmOwwners: action.fireararmOwwners
      };
    default:
      return state;
  }
};

export default reducer;