import {
  UPDATE_DEDICATED_STATUS_LIST,
  UPDATE_DEDICATED_STATUS_LIST_ERROR,
  UPDATE_DEDICATED_STATUS_RECORD
} from './types';

const INITIAL_STATE = {
  dedicatedStatusList: [],
  erroMessage: '',
  dedicatedStatusRecord: {}
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPDATE_DEDICATED_STATUS_LIST:
    return {
      ...state,
      dedicatedStatusList: action.payload,
      erroMessage: ''
    };
    
  case UPDATE_DEDICATED_STATUS_LIST_ERROR:
    return {
      ...state,
      dedicatedStatusList: [],
      erroMessage: action.payload
    };
  case UPDATE_DEDICATED_STATUS_RECORD:
    return {
      ...state,
      dedicatedStatusRecord: action.payload
    };
  default:
    return state;
  }
};

export default reducer;