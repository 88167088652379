import React, { Component } from "react";
import { approvalStatus } from "./PendingActivityConstants";
import { connect } from "react-redux";
import Axios from "axios";
import { BASE } from "../../../../API/APIendpoint";
import { Link } from "react-router-dom";
import { Button, Container, Grid, Paper, Typography} from '@material-ui/core';
import { DASHBOARD_ACTIONS } from "../../../../store/actions/actionTypes";
class ApprovalSection extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showTextArea: false,
      rejectReason: null,
      pendingCount: 0,
      _activitiesArray: this.props.activitiesArray
    }


    console.log(navigator.userAgent);
    
  }
  updatePending = () => {
    let _updatedActivities = [...this.state._activitiesArray];
    
    _updatedActivities.forEach(element => {
      if(element.activityId === this.props.activity.activityId) {
        element.status = "Approved" 
      }      
    })
    return _updatedActivities;
  }

  initialButtons = () => {
    return (
      <div className="d-flex w-100 justify-content-center align-items-center p-1">
        <div className="w-50 d-flex justify-content-center">
          
            <button
              className="btn btn-success btn-outline-success border-dark text-dark w-75"
              onClick={() =>{
                const emailParam = {
                  toEmail: this.props.user.email,
                  Subject: "Activity Approved",
                  Body: "",
                  Firstname: this.props.user.firstname,
                  Surname: this.props.user.surname,
                  ApplicationStatus: "Approved",
                  Reason: null
                }
                this.props.approveActivity(
                  this.props.activity.activityId,
                  approvalStatus.approve,
                  "it is legitimate",
                  emailParam
                )
                Axios.post(BASE + "api/Member/extendMembershipDays/" + this.props.user.email + "/" + this.props.loyaltyDaysObject.generalActivity + "/generalActivity",{
                  headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
                  .then(Axios({
                    url: BASE + "api/AutomatedEmail/post/SendLoyaltyEmail/" + this.props.pendingActivityState.activity.activityDescription + "/" + this.props.loyaltyDaysObject.generalActivity,
                    method: "post",
                    data: emailParam,
                    headers: {
                      'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                    }
                  }))
                this.props.onActivityObjectUpdate(
                    0,
                    this.updatePending()
                  )
                this.props.backToList()
              }}
            >
              Approve
            </button>
          
        </div>
        <div className="w-50 d-flex justify-content-center">
            <button
              className="btn btn-danger border-dark text-dark w-40"
              onClick={() =>
                this.setState({
                  showTextArea: true
                })
              }
            >
              Reject
            </button>
        </div>
      </div>
    );
  }

  rejectButtons = () => {
    return (
      <div className="d-flex w-100 justify-content-center align-items-center p-1">
      <div className="w-50 d-flex justify-content-center">

          <button
            className="btn btn-outline-danger border-dark text-dark w-75"
            onClick={() => {
              this.setState({
                showTextArea: false
              })
              const emailParam = {
                toEmail: this.props.user.email,
                Subject: "Activity Rejected",
                Body: "",
                Firstname: this.props.user.firstname,
                Surname: this.props.user.surname,
                ApplicationStatus: "Rejected",
                Reason: this.state.rejectReason
              }
              this.props.approveActivity(
                this.props.activity.activityId,
                approvalStatus.reject,
                this.state.rejectReason,
                emailParam
              )

              this.props.backToList()
            }}
          >
            Reject
          </button>
      </div>
        <div className="w-50 d-flex justify-content-center">
          <button
            className="btn btn-outline-success border-dark text-dark w-40"
            onClick={() =>{
              this.setState({
                showTextArea: false
              })
            }
            }
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="row p-3 border mt-2 rounded-top fixed-bottom bg-light">
        {this.props.loading ? "Loading please wait..." :
          <div className="d-flex flex-column flex-wrap w-100">
            {!this.state.showTextArea ?
              this.initialButtons() :
              <>
                <div className="d-flex justify-content-center w-100 p-3">
                  <textarea
                    style={{
                      width: "75%",
                      height: "100px"
                    }}
                    onChange={(event) => {
                      this.setState({
                        rejectReason: event.target.value
                      })
                    }}
                    placeholder="Provide a reason"
                  />
                </div>
                {this.rejectButtons()}

              </>
            }
          </div>
        }
        {/* <Link to="/Admin/MainAdmin">Back</Link> */}
        {/* <Button onClick={()=> this.props.backToList()}>Back to list </Button> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    pendingCount: state.dashBoardReducer.activities.pendingCount,
    activitiesArray: state.dashBoardReducer.activities.activities,
    loading: state.pendingActivityState.approvalStatus.loading,
    user: state.pendingActivityState.member,
    pendingActivityState: state.pendingActivityState,
    loyaltyDaysObject: state.LoyaltyDaysReducer.loyaltyDaysObject
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onActivityObjectUpdate: (pendingCount, activities) =>
      dispatch({
        type: DASHBOARD_ACTIONS.UPDATE_ACTIVITY_OBJECT,
        activities: {
          pendingCount: pendingCount,
          activities: activities
        }
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApprovalSection);
