import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';

import {NavBar} from '../../containers/index';
import NavBar_ from '../../../components/navBar/NavBar';
import Aux from '../AuxHelper/AuxHelper';
import MainAdmin from '../../../components/adminPortal/MainAdmin';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../../materialUI/NarfoTheme';
import CommissionsService from '../../services/commissions.service';
import { SET_SALES_REPS } from '../../store/SalesCommissions/types';
import HuntingGallery from '../../containers/Gallery/HuntingGallery'
import TargetDownloads from '../../containers/TargetDownloads/TargetDownloads';
import ShootingRangeLocations from '../../containers/ShootingRangeLocations/ShootingRangeLocations';
import PartnersLocation from '../../containers/PartnersLocation/PartnersLocation';
import FirearmsGuardian from '../../containers/FirearmsGuardian/FirearmsGuardian';
import { Alert } from 'react-bootstrap';
import GosaForm from '../../containers/WordPressForms/GosaForm';
import PcoasaForm from '../../containers/WordPressForms/PcoasaForm';
import PartnerForm from '../../containers/WordPressForms/PartnerForm';

const drawerWidth = 240;
const styles = (theme) => ({
  drawerMargin: {
    flexShrink: 0,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,

    },
  },
  drawerClosed: {
    flexShrink: 0,
    marginLeft: 0,
    minHeight: '60vh'
  },
});

class Layout extends Component {
  constructor(props) {
	super(props);
	this.commissionService = CommissionsService(this.props.token)
  }

  componentDidMount() {
	this.commissionService.getSalesReps()
	  .then(res => {
		  this.props.onSalesRepsGet(res.data)
	    })
  }

  render () {
    const {classes} = this.props;
    let path = this.props.location.pathname;

    return (
      <Aux>
		{
		  path.includes('/hunting-gallery') || path.includes('/wp-gosa-member-application') || path.includes('/online-member-application') || path.includes('/wp-pcoasa-member-application') ||  path.includes('/wp-target-downloads') || path.includes('/wp-shooting-ranges') || path.includes('/wp-partner-locations') || path.includes('/wp-firearms-guardian')? 

		path === '/hunting-gallery' && <React.Fragment>
			<HuntingGallery/>
		  </React.Fragment>
		  
	||

	path === '/wp-gosa-member-application' && <React.Fragment>
			<GosaForm/>
		</React.Fragment>

||

path === '/online-member-application' && <React.Fragment>
		<PartnerForm/>
	</React.Fragment>

||

path === '/wp-pcoasa-member-application' && <React.Fragment>
		<PcoasaForm/>
	</React.Fragment>


  ||

	path === '/wp-firearms-guardian' && <React.Fragment>
	<FirearmsGuardian/>
	</React.Fragment>
||
		path === '/wp-target-downloads' && <React.Fragment>
				<TargetDownloads/>
				</React.Fragment>
	||
		path === '/wp-shooting-ranges' && <React.Fragment>
				<ShootingRangeLocations/>
				</React.Fragment>
	||
	path === '/wp-partner-locations' && <React.Fragment>
			<PartnersLocation/>
			</React.Fragment>
		  :
		  <> 
		  {path.includes('/MainAdmin') ?
		<React.Fragment>
			<ThemeProvider theme={theme}>
				<NavBar_ />
			</ThemeProvider>
			<MainAdmin />
		</React.Fragment> :
		<React.Fragment>
		  {path.includes('admin/') ?
		    <ThemeProvider theme={theme}>
			  <NavBar_ />
		    </ThemeProvider> :
			<NavBar 
			  isAuth={this.props.isAuthenticated}
			  memberData={this.props.memberData}
			  profilePicture={this.props.profilePicture}
			  isMemberExpired={this.props.isMemberExpired}
			/>
		  }
		  
		  <main className={this.props.isAuthenticated ? path.includes('admin/') ? classes.drawerClosed : classes.drawerMargin : classes.drawerClosed}>
		    {this.props.children}
		  </main>
		</React.Fragment>}
		  </>
		}
		
      </Aux>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.AuthReducer.token !== null,
  memberData: state.ProfileReducer.memberData,
  profilePicture: state.ProfileReducer.profilePicture,
  isMemberExpired: state.ProfileReducer.isMemberExpired
});

const mapDispatchToProps = dispatch => ({
	onSalesRepsGet: salesReps => 
		dispatch({
		type: SET_SALES_REPS,
		payload: salesReps
	})
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Layout)));