import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import { connect } from "react-redux";
import { toast } from 'react-toastify';

class NotificationItem extends Component {
    constructor(props){
        super(props);
    }

    render() {
      return (
        <div className="container">
          
            <div className="row">
              <GenericHeader 
                subHeader
                title={this.props.notification.title} 
                showBackButton
                backFunction={() => this.props.backFunction()}
              />
            </div>
            <div>
            <div className="col-12" style={{marginLeft:'15px'}}>
              
                {this.props.notification.content} 
            </div>

            <div className="col-12" align="right" style={{marginTop:'15px'}}>
                <button onClick={()=>{this.props.handleDelete(this.props.notification)}} className="btn-danger" style={{marginRight:"5px"}}>delete</button>
            </div>
            
            </div>

        </div>
      );
    }
}


export default connect(
  null,
  null
)(NotificationItem);