import React, { Component } from "react";
import * as actionTypes from "../../../store/actions/actionTypes";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import GenericHeader from "../../../utils/GenericHeader";
import Axios from "axios";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE, ARTICLE_LATEST } from "../../../API/APIendpoint";
import "w3-css/w3.css";

class ViewAllNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      loading: false,
      articleNumber: this.props.match.params.number,
      article: null
    };
    this.toggleCheck = this.toggleCheck.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    Axios.get(BASE + ARTICLE_LATEST,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}}).then(res => {
      const data = res.data;
      this.props.onArticlesChange(data);
      this.setState({ loading: false, uploadedFile: data });
    });
  }
  toggleCheck = () => {
    this.setState({
      checked: !this.state.checked
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="col-12 d-flex justify-content-center">
          <FadeLoader color="orange" margin="auto" />
        </div>

      );
    }
    if (this.state.article) {
      const article = this.state.article
      return (
        <div className="container" style={{ marginTop: "80px" }}>
          <GenericHeader
            showBackButton
            title="News"
            backFunction={() => {
              this.props.history.push(`/ViewAllNews`);
              this.setState({
                article: null
              })
            }}
          />

          <div className="" key={0}>
            <div className="col-10">
              <div className="font-weight-bold ">{article.title}</div>
              <div className="col-sm-12">
                <iframe
                  title="article"
                  srcDoc={article.content}
                  style={{ border: "0px" }}
                  width="100%"
                  height="10000px"
                  border="0"
                  scrolling="no"
                />
              </div>
            </div>

          </div>
        </div>

      )
    }
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <GenericHeader
          title="News"
        />
        {this.props.articleList.map((article, index) => {
          return (
            <div className="documents-list-item" key={index} onClick={() => {
              this.setState({
                article: article
              })
            }}>
              <div className="col-10">
                <div className="font-weight-bold ">{article.title}</div>
                <div className="col-sm-12">
                  <iframe
                    title="article"
                    srcdoc={article.content.substring(0, 500).concat("...")}
                    style={{ border: "0px" }}
                    width="100%"
                    height="10%"
                    border="0"
                    scrolling="no"
                  />
                </div>
              </div>

            </div>
          );
        })}
      </div>
    );
  }
}
ViewAllNews.propTypes = {
  classes: PropTypes.object
};

const mapStateToProps = state => {
  return {
    oneArticle: state.articlesReducer.article,
    articleList: state.articlesReducer.articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onArticleChange: newArticle =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLE,
        article: newArticle
      }),
    onArticlesChange: newArticles =>
      dispatch({
        type: actionTypes.UPDATE_ARTICLES,
        articles: newArticles
      })
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAllNews);
