import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from "../../store/actions/actionTypes";
import "./styles.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { BASE, FRONT } from '../../API/APIendpoint';
import { 
	Button
  } from '@material-ui/core';
import { toast } from 'react-toastify';
toast.configure();
class ForgotEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isActive: false,
            emailRetrieved: false,
            _invalidIdNumber: "",
            Email: ""
        };
    }
    componentDidMount(){
        this.props.onResetPassDetailsChange("", "identityNumber");
    }
    checkIdAndGetEmail = e => {
        e.preventDefault();
        if(this.props.resetPassDetails.identityNumber.length === 0 || this.props.resetPassDetails.identityNumber.length > 13)
            this.setState({_invalidIdNumber: "Please enter a valid identity number"})
        else {
            axios({
                url: `${BASE}api/Member/getEmailAddressByID/${this.props.resetPassDetails.identityNumber}`,
                method: "get",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
                }
            }).then(res => {
                if(res.data.status === true){
                    toast.success(res.data.message);
                    this.setState({
                        emailRetrieved: true,
                        Email: res.data.email
                    })
                    this.props.onResetPassDetailsChange(res.data.email, "email")
                    setTimeout(() => {
                        this.props.history.push("/Login/ResetPassword")
                      }, 3000);
                } else {
                    toast.error(res.data.message)
                }
            })
        }
      }
    activateButton = () => {
        this.setState({isActive: true})
    }
    deactivateButton = () => {
        this.setState({isActive: false})
    }
    render() {
        return (
            <div className="container text-center" style={{ marginTop: "80px" }}>
                {this.state.emailRetrieved === false ?
                <div>
                    <h1>Get Your Email</h1>
                    <br />
                    <h3 className="heading mb-2">
                        <p>Before we give you your email, we need to varify that<br />
                        you are the owner of the email sent.
                        </p>
                    </h3>
                    <h6 className="text-secondary mt-1">Enter Your Identity Number</h6>
                    <div class="container mb-2">
                        <div class="center">
                            <input
                                type="text"
                                className="form-control"
                                onInput={a => {
                                    this.setState({
                                        Email: a.target.value
                                    });
                                    this.props.onResetPassDetailsChange(a.target.value, "identityNumber")
                                }}
                            />
                        </div>
                    </div>
                    <div className="text-center">
					<Button 
						variant='' 
						className="mt-3 mb-3" 
						variant="contained" 
						color="primary"
						disabled={!this.state.isActive}
						onClick={e => this.checkIdAndGetEmail(e)}
					>
						SUBMIT
					</Button>
				</div>
                    <div className="placeAtCenter">
                        <ReCAPTCHA
                            sitekey={FRONT === "http://localhost:3000/" ? 
                                this.props.recatchaKey.localHostKey : FRONT === "http://qaportal.narfo.co.za/" ?
                                    this.props.recatchaKey.narfoQAKey : this.props.recatchaKey.narfoProductionKey}
                            onChange={this.activateButton}
                            onExpired={this.deactivateButton}
                        />
                    </div>
                </div> :
                <div className="font-weight-bold text-success" 
                    align="center" 
                    display="flex"
                    style={{ marginTop: "60px", fontSize: "20px"}}
                >
                    Verification successful, your email address is <br/>
                    {this.props.resetPassDetails.email}
                </div>}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        resetPassDetails: state.loginReducer.resetPassDetails,
        recatchaKey: state.RecaptchaKeyReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        onResetPassDetailsChange: (value, vname) => dispatch({ type: actionTypes.UPDATE_RESET_DETAILS, varValue: value, varName: vname })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((ForgotEmail));