import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

export default class CustomModal extends Component {
  showCloseButton = () => {
    if (this.props.disableCloseButton) {
      return (
        <Modal.Header>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
      );
    } else {
      return (
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
      );
    }
  }
  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        dialogClassName="modal-90w pt-5"
        aria-labelledby="modal dialog"
        backdrop="static"
        keyboard={false}
      >
        {this.showCloseButton()}
        <Modal.Body>
          <p>
            {!this.props.error
              ? `${this.props.message}...`
              : `Error: ${this.props.error}`}
          </p>
        </Modal.Body>
      </Modal>
    );
  }
}
