/**TO-DO
 * Update Receipt Number
 * Update Pending Status
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";

export class Payment extends Component {
  render() {
    const info = (row1, row2) => (
      <div className="row  mt-2 mb-1">
        <div className="col-12">{row1} </div>
        <div className="col-12 font-weight-bold">{row2}</div>
      </div>
    );
    return (
      <div className="container">
        <div className="row">
          <GenericHeader
            title="Payment"
            showBackButton
            backFunction={() => this.props.onCurrAccountViewTabChange(0)}
          />
        </div>

        {info("Member No:", this.props.MemberV.memNo)}
        {info("Receipt Number:", this.props.recentAccountEntry.recieptNumber)}
        {info("Invoice Number:", this.props.recentAccountEntry.invoiceNumber)}
        {info(
          "Payment Date:",
          this.props.recentAccountEntry.paymentDate.substring(0, 10)
        )}
        {info("Year:", this.props.recentAccountEntry.year)}
        {info("Payment Method:", this.props.recentAccountEntry.paymentMethod)}
        {info("Amount Paid:", `R ${this.props.recentAccountEntry.amountPaid}`)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userDetails: state.loginReducer.userProfileDetails,
    MemberV: state.MemberViewReducer.MemberView,
    recentAccountEntry: state.accountReducer.recentAccountEntry
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCurrAccountViewTabChange: newCurrAccountViewTab =>
      dispatch({
        type: actionTypes.UPDATE_CURRENT_ACCOUNT_VIEW_TAB,
        currAccountViewTab: newCurrAccountViewTab
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payment);
