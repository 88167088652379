import React from "react";
import Axios from "axios";
import { BASE, ARTICLE_URL } from "../../../API/APIendpoint";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Link } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null,
      articleNumber: this.props.match.params.number
    };
  }

  async componentWillMount() {
    const response = await Axios.get(BASE + ARTICLE_URL,{
      headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}});
    const data = await response.data;
    const article = await data.filter(
      article => parseInt(article.newsArticleId) === parseInt(this.state.articleNumber)
    )[0];

    this.setState({
      article
    });
  }

  render() {
    const { article } = this.state;
    if (!article)
      return (
        <div className="d-flex justify-content-center mt-5">
          <FadeLoader color="orange" margin="auto" />
        </div>
      );
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <div className="row justify-content-between border-bottom  border-gray">
          <h4 className="arrow">
            <Link to="/Home">
              {
                <button className="btn btn-black mb-1">
                  <KeyboardArrowLeft />
                </button>
              }
            </Link>
            <h3 className="title"> {article.title}</h3>
          </h4>
        </div>
        <div className="content">
          <iframe
            title="article"
            srcDoc={article.content}
            style={{ border: "0px" }}
            width="100%"
            height="3750px"
            border="0"
            scrolling="no"
          />
        </div>
      </div>
    );
  }
}
export default Articles;
