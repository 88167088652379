import Axios from "axios";
import React, { Component } from "react";
import { BASE } from "../../API/APIendpoint";
import GenericHeader from "../../utils/GenericHeader";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Interweave from "interweave";
import { Editor } from "@tinymce/tinymce-react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

class EmailNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailList:[],
      loading: false,
      subject:"",
      body:'',
    };

    this.onTitleChange = this.onTitleChange.bind(this);
    this.onContentChange = this.onContentChange.bind(this);
  }


  onTitleChange(e) {
    e.preventDefault();
    this.setState({ subject: e.target.value });
  }

  onContentChange(e) {
    e.preventDefault();
    this.setState({ body: e.target.getContent()});
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <GenericHeader
            subHeader
            title={"Create new Email"}
            showBackButton
            backFunction={() => this.props.backFunction()}
          />
        </div>

        <div className="col-12 pt-1">
              <div className="row">
                <div
                  className="col-12 pt-3"
                  style={{ width: "95ch", marginBottom: "20px" }}
                >
                  <TextField
                    className="form-control"
                    id="emailSubject"
                    label="Email Subject"
                    defaultValue=" "
                    variant="outlined"
                    onChange={(e) => this.onTitleChange(e)}
                  />

                </div>

                <div className="col-12 pt-3" style={{ width: "95ch" }}>
                  <span style={{ font: "small-caption" }}>Email body</span>
                  <Editor
                    placeholder="123abc"
                    init={{
                      plugins: "link image code",
                      toolbar:
                        "undo redo | bold italic | alignleft aligncenter alignright | image | link | cut | copy | paste | font",
                      height: "550px",
                    }}
                    apiKey="kmpgx6hmv3ix0abz8fn9sly4r5vf2fzw0tw2ubm88mhydjba"
                    onChange={(e) => this.onContentChange(e)}
                  />
                </div>
              </div>

              <div className="col-12 pt-3" align="right">
              <Button onClick={() => this.props.backFunction()} color="success">
                Cancel
              </Button>
              <Button
                onClick={()=>this.props.handleSubmit({subject:this.state.subject,body:this.state.body,recipient : this.state.recipient})}
                color="primary"
                disabled={
                  this.state.subject.length > 2 && this.state.body.length > 2
                    ? false
                    : true
                }
              >
                Save
              </Button>
              </div>
        </div>
      </div>
    );
  }
}

export default connect(null, null)(EmailNew);
