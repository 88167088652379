import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./../../homePage/newsFeed/ArticleStyle.css";
class ManualPay extends Component {
  render(props) {
    return (
      <div className="manually-payment" style={{ marginTop: "60px" }}>
        <p className="first-message">
          You have chosen to do a manual payment for your membership.
        </p>
        <p>
          Once you have made the payment, please email the proof of payment to us at info@narfo.co.za. 
          Once we receive your proof of payment we will
          activate your account.
        </p>
        <p>
          Bank Details: FNB,  Acc No - 62833054117,  Branch Code - 250655. When paying
          please ensure you use your [Identity No] as reference
        </p>
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-6 col-sm-12 col-xs-12 ">
            <Link to={"/admin/MembersArea"}>
              <button className=" btn btn-warning form-control text-dark border border-dark">
                Back to Account
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default ManualPay;