import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubtitleBar from '../SubtitleBar/SubtitleBar';
import { 
  SET_FIREARM_LICENCE_APPLICATION_LIST
} from '../../store/Licence/types';
import { Grid, Typography } from '@material-ui/core';
import {
  GenericTable,
  IconLabelButton,
  Loader,
} from '../index';
import {
  checkValidity,
  updateObject,
  initSelect
} from '../../shared/utility';
import {
  editableTextInputConfig
} from '../../shared/constants';
import PropType from 'prop-types';
import EditableGridLabel from '../EditableGridLabel/EditableGridLabel';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';


const columns = [
  { field: 'statusDate', headerName: 'Status Date' },
  { field: 'status', headerName: 'Status' },
  { field: 'statusDescription', headerName: 'Status Description' },
];

toast.configure();
class ViewLicenseApplications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenseApplication: props.licenseApplication,
      controls: this.initControls()
    };
  }

    editableGridLable = (title, name, stretch, isMobile) => (
      <EditableGridLabel 
        title={title}
        value={this.state.licenseApplication[name] ?? ""}
        options={this.state.controls[name]}
        edit={false}
        stretch={stretch}
        isMobile={isMobile, false, isMobile}
      />
    );

    initControls = () => ({
      referenceNumber: {...editableTextInputConfig},
      serialNumber: {...editableTextInputConfig},
      applicationType: {...editableTextInputConfig},
      make: {...editableTextInputConfig},
      applicationNumber: {...editableTextInputConfig},
      idorInstNumber: {...editableTextInputConfig},
      caliber: {...editableTextInputConfig},
      statusDate: {...editableTextInputConfig},
      status: {...editableTextInputConfig},
      statusDescription: {...editableTextInputConfig},
      nextStep: {...editableTextInputConfig},
    });

    handleSubmit = () => {
      const data = {
        firearm: this.state.licenseReminder.firearm,
        serialNumber: this.state.licenseReminder.serialNumber,
        issued: this.state.licenseReminder.issued,
        make: this.state.licenseReminder.make,
        licenseNo: this.state.licenseReminder.licenseNo,
        expiryDate: this.state.licenseReminder.expiryDate,
        caliber: this.state.licenseReminder.caliber,
        section: this.state.licenseReminder.section,
        description: this.state.licenseReminder.description
      };
      this.setState({ loading: true, editDetails: false });
      this.props.updateLicenseReminderCallback(data)
        .then(res => this.props.onLicenseRecordUpdate(res.data))
        .finally(() => this.setState({loading: false}));
    }

    render() {

      const isMobile = localStorage.getItem('isMobile') === 'true';
      return  (
        <React.Fragment>
          <SubtitleBar 
            variant="subtitle1"
            title="Details"
            removeApplication={true}
            back
            onBack={this.props.backCallback}
            deleteLicenseApplicationCallback={() => {
              this.props.deleteLicenseApplicationCallback(this.state.licenseApplication.referenceNumber)
                .then(res => {
                  if(res.data.isFound){
                    this.props.onLicenseApplicationRecordListUpdate(
                      this.props.firearmLicenseApplications.filter(element => element.referenceNumber !== this.state.licenseApplication.referenceNumber)
                    ); 
                    toast.success(res.data.message);
                  } else {
                    toast.error(res.data.message);
                  }
                })
                .finally(this.props.backCallback(this.state.licenseApplication));
            }}
          />
          
          {this.props.licenseApplication && <div className="inner-shadow border-radius-2 py-2 px-4 bg-white-2">
            <Grid container spacing={4}>
              {this.editableGridLable('Reference Number', 'referenceNumber', false, isMobile)}
              {this.editableGridLable('Serial Number', 'serialNumber', false, isMobile)}
              {this.editableGridLable('Application Type', 'applicationType', false, isMobile)}
              {this.editableGridLable('Application Number', 'applicationNumber',false, isMobile)}
              {this.editableGridLable('Make', 'make', false, isMobile)}
              {this.editableGridLable('ID/Inst Number', 'idorInstNumber', false, isMobile)}
              {this.editableGridLable('Calibre', 'calibre', false, isMobile)}
              {this.editableGridLable('Status Date', 'statusDate', false, isMobile)}
              {this.editableGridLable('Status', 'status', true, isMobile)}
              {this.editableGridLable('Status Description', 'statusDescription', true, isMobile)}
              {this.editableGridLable('Next Step', 'nextStep', true, isMobile)}
            </Grid>
            
          </div>}
          

          {this.state.loading && <Loader color="primary" />}
          
          <div className="mt-4">
              <Typography variant="h5">Status timeline</Typography>
              <div >
              {this.props.licenseApplication.licenseApplicationStatusTimelines?.length !== 0 ?
              <GenericTable 
                columns={columns}
                rows={this.props.licenseApplication.licenseApplicationStatusTimelines}
              /> : null}
              </div>
          </div>

        </React.Fragment>
      );
    }
}

const mapStateToProps = (state) => ({
  firearmLicenseApplications: state.LicenceReducer.firearmLicenseApplications,
});

const mapDispatchToProps = dispatch => ({
  onLicenseApplicationRecordListUpdate: newList => dispatch({ type: SET_FIREARM_LICENCE_APPLICATION_LIST, payload: newList })
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewLicenseApplications);