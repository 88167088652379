import React, { Component } from 'react'
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import Button from '@material-ui/core/Button';
import {BASE,GET_SECTION_LIST,POST_SECTION,DELETE_SECTION} from "../../../API/APIendpoint";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { ValidatorForm } from "react-form-validator-core";
import { TextValidator } from "react-material-ui-form-validator";

const styles = theme => ({
  root: {
    height: "100%"
  },
  center: {
    margin: "auto",
    width: 50,
    padding: 10
  },
  aligner: {
    textAlign: "center"
  },

  container: {
    display: "flex",
    flexDirection: "column",
    align:"center"
  },

  footer: {
    display: "comtent",
    flexDirection: "row"
  },

  TextValidator: {
    marginLeft: "20%",
    marginRight: "20%",
    marginTop: "1%",
    fullWidth: true,
    flex: "1",
    margin: "5",
    instantValidate: true
  },

  textField: {
      marginTop: "1%",
      marginLeft: "30%",
      marginRight: "30%",
      width:"40%",
      flex: 1,
      margin: 5
    },

    button:{
      margin: theme.spacing.unit,
      backgroundColor: "#FFFFFF",
      borderColor:"#000000",
      '&:hover': {
          backgroundColor: "#F5A623",
          color:'White',
          borderColor:'Black'
        },
      width: "20%",
      color:'Black',
      align:"Left",
      marginLeft: "10%",
      marginRight: "30%",
    },

  select: {
    marginLeft: "5%",
    marginRight: "5%",
    marginTop: "1%",
    flex: "1",
    margin: "5"
  }
});




class DropDownSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
          Section_List: [],
          DelKey:"",
          isValid:false          
        };
      }
    handleChange = name => event =>{
        this.props.onSectionChange(event.target.value,[name])
      this.checkNotEmpty()
    };

    checkNotEmpty = () => {
      let V = true;
        if (this.props.Selections.Section === "" ||
          this.props.Selections.Section === null || 
          this.props.Selections.Section === undefined ||
          !this.props.Selections.Section.startsWith("Section") ) 
          {V = false;}
      this.setState({
        isValid: V
      });
    }

    submitSection()
    {
      if (this.state.isValid && !(this.props.Selections.Section === "")){
        fetch(BASE + POST_SECTION, {
            method: "POST",
            body: JSON.stringify({
                Section:this.props.Selections.Section
            }),
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`
            }
          }).then(()=>{
            fetch(BASE + GET_SECTION_LIST,{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(res => res.json())
            .then(json => {
              this.setState({
                Section_List: json
              });
          });

          });
        }this.componentDidMount()
     
    };
    DeleteSection()
    {
        fetch(BASE + DELETE_SECTION +this.props.Selections.DeleteKey, {
            method: "POST",
            headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}
          }).then(()=>{
            fetch(BASE + GET_SECTION_LIST,{
              headers: {'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
            .then(res => res.json())
            .then(data => {
              this.setState({
                Section_List: data
              });
          });
          });     
    };

    DeleteKey = (e) =>
    {
        this.setState({
            DelKey : [e.target.value]
        })
        this.props.onSectionChange(e.target.value,"DeleteKey")
    }

    componentDidMount()
    {
      this.props.onSectionChange("Section","Section")
      fetch(BASE + GET_SECTION_LIST,
        {
          headers: {
            'Content-Type': 'application/json'
            ,'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,}})
      .then(res => res.json())
      .then(json => {
        this.setState({
          Section_List: json
        });
    });

        // custom rule will have name ateast 4 characters required
        ValidatorForm.addValidationRule("isEmpty", value => {
          if (!value) {
            return false;
          }
          if(!value.startsWith("Section")){
            return false;
          }
          if (value.length < 0) {
            return false;
          }
          return true;
        });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.container} noValidate autoComplete="off">
        <h1 className="row d-none d-md-block" align="Middle" >Section</h1>
        <ValidatorForm
            ref="form"
            align="center"
            autoComplete="off"
            onSubmit={this.handleSubmit}
          >
          <TextValidator
            id="OutlinedSection"
            className={classes.textField}
            value={this.props.Selections.Section}
            onChange={this.handleChange("Section")}
            name="Section"
            validators={["isEmpty", "required"]}
            errormessages={[
              "Please Enter a Section e.g Section 36 ",
              "This field is required"
            ]}
            margin="normal"
            variant="outlined"
          />          

          <Button variant="contained" 
          className={classes.button}
          onClick={()=>{this.submitSection()}}
          >
            Add to Drop Downs
            </Button>
        <Select
            className={classes.textField}
            native
            value={this.props.Selections.DeleteKey}
            onChange={this.DeleteKey}
            input={
              <OutlinedInput labelWidth={0} name="Section_List" id="Section" />
            }
          >
            {this.state.Section_List.map((activityType) => (
              <option 
                    key={activityType.sectionLookUpId} 
                    value={activityType.sectionLookUpId}
                    >
                    {activityType.section}
              </option>
            ))}
          </Select>
          <Button variant="contained" 
          className={classes.button}
          onClick={()=>{this.DeleteSection()}}
          >
            Remove Selection 
            </Button>
        </ValidatorForm>

          </div>
      </div>
    )
  }
}

DropDownSection.propTypes = {
    classes: PropTypes.object.isRequired
  };

const mapStateToProps = state => {
    return {
      memDetails: state.registrationReducer.signupDetails,
      Selections: state.DropDownReducer.Options,
      newlicenceDetails: state.licensesReducer.licence
    };
  };
  

  
  const mapDispatchToProps = dispatch => {
    return {
      onSectionChange: (value, vname) =>
        dispatch({
          type: actionTypes.UPDATE_OPTIONS,
          varValue: value,
          varName: vname
        })
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(DropDownSection));