import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { formatDate, status } from "./ActivityDetails";
function Activity(props) {
  return (
    <div
      className="p-1 border-bottom font-weight-bold"
      onClick={() => {
        props.onActivityParametersChange(
          props.activity.memNo,
          props.activity.activityId
        );
        props.onAnyPropertyChange("3", "currentActivityTab");
      }}
      style={{ cursor: "pointer" }}
      raised="false"
    >
      <span>{props.activity.activityDescription}</span> 
      <div>{props.activity.activityType}</div> 
      <div className="font-weight-bold">Activity Date: <span>{formatDate(props.activity.activityDate)}</span></div>
      <div>Firearm Type: <span>{props.activity.firearmType}</span></div>
      <span>{status(props.activity.status)}</span>
    </div >
  );
}
const mapDispatchToProps = dispatch => {
  return {
    onAnyPropertyChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBERS_AREA,
        varValue: value,
        varName: vname
      }),
    onActivityParametersChange: (memberNumber, activityId) => {
      return dispatch({
        type: actionTypes.UPDATE_ACTIVITY_PARAMETERS,
        activityMemberNumber: memberNumber,
        activityID: activityId
      });
    }
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Activity);
