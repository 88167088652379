import * as Types from "./FilterActionTypes";

const activityFilterState = {
    filterCategory: null, //'Hunting' or 'Sport Shooting'
    startDate: null,
    endDate: null,
    unfilteredActivities: null,
};

const reducer = (state = activityFilterState, action) => {
    switch (action.type) {
        case Types.RESET_ACTIVITY_FILTER:
            return {
                ...state,
                ...activityFilterState
            }
        case Types.SET_FILTER_CATEGOTY:
        case Types.SET_FILTER_START_DATE:
        case Types.SET_FILTER_END_DATE:
            return {
                ...state,
                ...action.activityFilterState,
            };
        default:
            return state;
    }
};

export default reducer;