import {
  MenuItem, Select,
  TextField} from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { BASE, GET_ALL_MEMBERS_DEDICATED, GET_USER_BY_FILFTER, GET_USER_BY_STATUS } from "../../API/APIendpoint";
import * as actionTypes from "../../store/actions/actionTypes";
import GenericHeader from "../../utils/GenericHeader";
import MemberCard from "./MemberCard";
import { viewAllMemberStyles } from "./styles/styles";

const userStatus = Object.freeze({ "Approved": 1, "Pending": 2, "Rejected": 3, "Renewing": 4 })
class ViewAllMember extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searchText: "",
      MemberList: [],
      filtertext: "All"
    }

  }
  onChangeText = (event) => {
    this.setState({ searchText: event });
  }

  onchangeFilter(event) {
    this.setState({ filtertext: event });
    axios.post(BASE + GET_USER_BY_STATUS + "/" + userStatus[event],
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
      }).then(res => {
        this.setState({ MemberList: res.data })
        this.props.onMemberViewListChange(res.data);
      });
  }

  componentDidMount() {
    axios.get(BASE + GET_ALL_MEMBERS_DEDICATED,
      {
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
        }
      }).then(res => {
        const MemberList = res.data;
        this.props.onMemberViewListChange(MemberList);
      });
    this.props.onMemberStateChange(true, "isAdmin");
  }

  resetFilters = () => {
    this.props.onAdminActivityTypeFilterChange("Activity Type");
    this.props.onAdminActivityStatusFilterChange("Status");
  };

  showFilterBar = () => {
    this.setState({ searchToggle: !this.state.searchToggle })
  }

  getFilteredMembers = (event) => {
    if (event.key == "Enter" || event.key == 13) {
      axios.post(BASE + GET_USER_BY_FILFTER + "/" + this.state.searchText,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${sessionStorage.getItem("tokenKey")}`,
          }
        }).then(res => {
          this.setState({ MemberList: res.data })
          this.props.onMemberViewListChange(res.data);
        });
        this.props.onMemberStateChange(true, "isAdminMemView");
    }

  };
  render() {
    let MemberV = this.props.MemberList;
    let lastLetter = "";
    let currentLetter;
    let displayLetter = false;
    MemberV.sort((a, b) => (a.firstname > b.firstname ? 1 : -1));
    return (
      <div className="container" style={{ marginTop: "60px" }}>
        <GenericHeader
          title="All Members"
          showSearchButton
          searchFunction={() => this.showFilterBar()}
        />

        <div className="d-flex mt-1 justify-content-between">
          <div className="d-flex w-25 flex-column">
            <div>User status</div>
            <Select
              autoWidth
              value={this.state.filtertext}
              onChange={event =>
                this.onchangeFilter(event.target.value)
              }
              defaultValue="Activity Type"
              inputProps={{
                name: "Activity Type",
                id: "ActivityTypeSelector"
              }}
            >
              <MenuItem value="Activity Type">
                <em>All</em>
              </MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Renewing">Renewing</MenuItem>
            </Select>
          </div>
          <TextField
            id="outlinedFirearm"
            placeholder="Search by Surname,Email, MemNo or ID No"
            className="w-25"
            value={this.state.searchText}
            onChange={event => this.onChangeText(event.target.value)}
            onKeyDown={(event) => this.getFilteredMembers(event)}
            variant="outlined"
          />
        </div>
        {Array.isArray(MemberV) && MemberV.length === 0 ? (
          <div className="d-flex justify-content-center mt-5">
            <FadeLoader color="orange" margin="auto" />
          </div>
        ) : (
            <div className="container">
              {this.state.MemberList.map((MemberMap, index) => {
                currentLetter = MemberMap.firstname
                  .trim()
                  .toLowerCase()
                  .substring(0, 1);

                if (currentLetter !== lastLetter) {
                  displayLetter = MemberMap.firstname
                    .trim()
                    .toUpperCase()
                    .substring(0, 1);
                } else {
                  displayLetter = "";
                }

                lastLetter = currentLetter;
                return (
                  <MemberCard
                    key={index}
                    MemberArray={MemberMap}
                    firstLetter={displayLetter}
                  />
                );
              })}
            </div>
          )}
      </div>
    );
  }
}
ViewAllMember.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    userProfileDetails: state.loginReducer.userProfileDetails,
    MemberDetails: state.MemberViewReducer.MemberView,
    MemberList: state.MemberViewReducer.MemberList,
    showActFilterBar: state.MemberViewReducer.showFilterBar,
    MemSearch: state.MemberViewReducer.Search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMemberViewListChange: newUserMemberArray =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_VIEW_LIST,
        payload: newUserMemberArray
      }),
    onMemberStateChange: (value, vname) =>
      dispatch({
        type: actionTypes.UPDATE_MEMBER_STATE,
        varName: vname,
        varValue: value
      }),

    onShowFilterBarToggle: () =>
      dispatch({ type: actionTypes.TOGGLE_SHOW_FILTER_BAR }),

    onAdminActivityTypeFilterChange: newAdminActivityTypeFilter =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_TYPE_FILTER,
        payload: newAdminActivityTypeFilter
      }),
    onAdminActivityStatusFilterChange: newAdminActivityStatusFilter =>
      dispatch({
        type: actionTypes.ACTIVITY_ACTIONS.UPDATE_ADMIN_ACTIVITY_STATUS_FILTER,
        payload: newAdminActivityStatusFilter
      })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(viewAllMemberStyles)(ViewAllMember));
