/* eslint-disable no-unused-vars */
import * as actionTypes from './actionTypes';
import { updateObject } from '../../../shared/utility';

const initialState = {
  token: null,
  memNo: null,
  error: null,
  loading: false,
  authRedirectPath: '/member/my-profile'
};

const authStart = (state, action) => updateObject( 
  state, 
  { 
    error: null, 
    loading: true 
  }
);

const authSuccess = (state, action) => updateObject (
  state, 
  {
    token: action.token,
    memNo: action.memNo,
    error: null,
    loading: false
  }
);

const authFail = (state, action) => updateObject( 
  state, 
  {
    error: action.error,
    loading: false
  }
);

const authLogout = (state, action) => updateObject(
  state, 
  { 
    token: null, 
    memNo: null 
  }
);

const setAuthRedirectPath = (state, action) => updateObject(
  state, 
  { 
    authRedirectPath: action.path 
  }
);

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.AUTH_START: return authStart(state, action);
  case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
  case actionTypes.AUTH_FAIL: return authFail(state, action);
  case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
  case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
  default:
    return state;
  }
};

export default reducer;