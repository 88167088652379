import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography
} from '@material-ui/core';

import {
  Loader,
  Overview,
  SubtitleBar,
} from '../index';
import {valid} from '../../shared/helpers';
import {updateObject} from '../../shared/utility';
import {editableSelectConfig} from '../../shared/constants';
import GenericTable from '../GenericTable/GenericTable';
import SITE_URL from '../../services/siteURL';

const columns = [
  {field: 'name', headerName: 'File Name'},
  {field: 'fileType', headerName:'Document Type'},
  {field: 'discription', headerName: 'Description'}
];

const columnsMobile = [
  {field: 'name', headerName: 'File Name'},
  {field: 'discription', headerName: 'Description'}
];

const initDocumentTypeFilter = (value, items) => ({
  title: 'Document Type',
  value: value,
  id: 'select-documentType',
  name: 'documentType',
  options: {
    ...editableSelectConfig,
    config: {
      ...editableSelectConfig.config,
      ...valid(value, editableSelectConfig.config.validate),
      elementConfig: {
        ...editableSelectConfig.config.elementConfig,
        options: items,
      },
      touched: true
    }
  }
});
class MyDocuments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterValue: {
        type: '-'
      },
      fileItems: [],
      documents: props.documents,
      document: {},
      loading: props.loading,
    };
  }

  handleLink = (item) => {

    const downloadLink = SITE_URL[SITE_URL.length -1] === '/' ? `${SITE_URL}${item.link}` : `${SITE_URL}/${item.link}`;
    window.open(downloadLink);    
  }

  updateFilterValue = (obj) => {
    this.setState({
      filterValue: updateObject(this.state.filterValue, obj),
    });
  }

  filterOptions = (type) => {
    let fileItems = [];
    fileItems = [...this.props.documentFilterItems.documentTypes];
    const elements = [
      {
        ...initDocumentTypeFilter(type, fileItems),
        onChange: (e) => this.updateFilterValue({type: e.target.value}),
      }
    ];
    
    return {
      elements,
      callBack: this.filterDocuments,
    };
  };

  filterDocuments = (filter) => {
    let documents = this.props.documents;
    if (filter.documentType && filter.documentType !== '-') {
      
      documents = documents.filter(
        a => a.fileType ? 
          a.fileType.toLowerCase() === filter.documentType.toLowerCase() : false
      );
    }
    
    this.setState({documents});
  };


  render() {
    const noDataSection = (
      <Typography variant="h4" color="error">
        There are no documents to display at this time
      </Typography>
    );
    const isMobile = localStorage.getItem('isMobile') === 'true';
    return (
      this.state.loading && <Loader/> ||
      <React.Fragment>
        <table id="auto-table" className="d-none"></table>
        <Overview>
          <Typography>
            All relevant documentation can found in this section. Use the filter button 
            to search for a specific type of document. You can also download all of the
            NARFO targets in this section.
          </Typography>
        </Overview>
        <SubtitleBar
          title={isMobile ? 'Documents' : 'NARFO Documents'}
          filter
          filterOptions={this.filterOptions(
            this.state.filterValue.type,
          )}
        />
        {this.props.documents.length === 0 ? <Grid item>{noDataSection}</Grid> : null}
        <GenericTable
          columns={isMobile ? columnsMobile : columns}
          rows={this.state.documents}
          view
          onViewCallBack={this.handleLink}
        />
      </React.Fragment>
    );
  }
}

MyDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
  documentFilterItems: PropTypes.shape({
    documentTypes: PropTypes.array.isRequired,
  }).isRequired,
};

MyDocuments.defaultProps = {
  documents: [],
  disciplineFilterItems: {
    documentTypes: [],
  },
};

export default MyDocuments;