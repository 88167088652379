import {
  SET_ACTIVITIES, 
  SET_ACTIVITY_MATRIX,
  SET_ACTIVITY_RANKING
} from './types';

const fetchActivities = service => id => dispatch => service
  .getMemberActivities(id)
  .then((res) => dispatch({
    type: SET_ACTIVITIES,
    payload: res.data.map(activity => ({
      ...activity,
      score: parseInt(activity.score),
    })),
  }));

const fetchMatrix = service => id => dispatch => service
  .getActivityMatrix(id)
  .then((res) => dispatch({
    type: SET_ACTIVITY_MATRIX,
    payload: res.data.map(matrixItem => ({
      ...matrixItem
    })),
  }));

const fetchRanking = service => id => dispatch => service
  .getActivityRanking(id)
  .then((res) => dispatch({
    type: SET_ACTIVITY_RANKING,
    payload: res.data,
  }));

export default function(activityService) {
  return {
    fetchActivities: fetchActivities(activityService),
    fetchMatrix: fetchMatrix(activityService),
    fetchRanking : fetchRanking(activityService),
  };
}
