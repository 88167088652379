import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SubtitleBar
} from '../index';
import { Grid, Typography, Button } from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const columns = [
  { field: 'thumbnail', headerName: 'Thumbnail'},
];

const styles = (theme) => ({
  thumbNail: {
    cursor: 'pointer',
  },
  img: {
    width: "170px",
    height: "200px",
    borderRadius: "5px",
    padding: "0px",
  },
  imageTextPadding: {
    paddingTop: "2em"
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class ViewSubscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionList: props.subscriptionList,
    };
  }

  updateSubscriptionList=(value)=>{

    if(this.props.subscriptionList?.length > 0 && value != '')
    {
      let filteredSubscriptions = this.props.subscriptionList.filter(item => item.type == value);
      this.setState({subscriptionList : filteredSubscriptions});
    }
    else
    {
      this.setState({subscriptionList : this.props.subscriptionList});
    }
  }

  render() {
    const isMobile = localStorage.getItem('isMobile') === 'true';
    const { classes } = this.props;
    return(
      <React.Fragment>
        <>
          <div >
            <SubtitleBar dropdownSelect callBack={this.updateSubscriptionList} providers={this.props.providers} variant="subtitle2" title={'Subscription List'}/>
            {
            this.state.subscriptionList.length !== 0 ?
              
              <>
                <Grid container item xs={12} spacing={3}>
                {
                  this.state.subscriptionList.map(item =>{
                  return (
                  <Grid item xs={isMobile ?  12 : 3}  spacing={1}>
                    <Paper className={classes.paper}>
                    <Typography>
                    <a
                      href={item.link}
                      target="_"
                      blank="true"
                      className = "spacing"
                    >
                      <img className={classes.img} alt="image unavailable" src={item.thumbnail} />
                    </a>
                    <br/>

                    <Typography>Date: {item.date}</Typography>
                  </Typography>
                    </Paper>
                  </Grid>
                    )
                  })
                }
                
                </Grid>
            </>
              : null}
              
            </div>
        </>
      </React.Fragment>
    );
  }
}


export default connect(null, null)(withStyles(styles)(ViewSubscriptions));