import API_URL from './apiUrl';
import axios from 'axios';

const getDocuments = (headers) => () => axios.
  get(`${API_URL}/downloads/all`, {headers});

export default function(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  return {
    getDocuments: getDocuments(headers)
  };
}